import React from "react";
import { Link } from "react-router-dom";
import * as moment from "moment";

export const handleValidDate = (date) => {
  const date1 = moment(new Date(date)).format("YYYY-MM-DD");
  return date1;
};

const NotesId = (cell) => {
  return (
    <React.Fragment>
      {/*<Link to="/apps-cases-details" className="fw-medium link-primary">*/}
      <Link to="#" className="fw-medium link-primary">
        {cell.value}
      </Link>
    </React.Fragment>
  );
};

const Title = (cell) => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const Client = (cell) => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const AssignedTo = (cell) => {
  // console.log(cell.value[0] && cell.value[0].role)
  return (
    <React.Fragment>
      {cell.value[0] !== undefined
        ? `${cell.value[0].user_details.first_name} ${cell.value[0].user_details.last_name}`
        : cell.value}
    </React.Fragment>
  );
};

const CreateDate = (cell) => {
  return <React.Fragment>{handleValidDate(cell.value)}</React.Fragment>;
};

const DueDate = (cell) => {
  return <React.Fragment>{cell.value}</React.Fragment>;
};

const Status = (cell) => {
  return (
    <React.Fragment>
      {cell.value === "In Progress" ? (
        <span className="badge badge-soft-warning text-uppercase">{cell.value}</span>
      ) : cell.value === "Open" ? (
        <span className="badge badge-soft-info text-uppercase">{cell.value}</span>
      ) : cell.value === "Pending" ? (
        <span className="badge badge-soft-success text-uppercase">{cell.value}</span>
      ) : cell.value === "Closed" ? (
        <span className="badge badge-soft-danger text-uppercase">{cell.value}</span>
      ) : null}
    </React.Fragment>
  );
};

const Priority = (cell) => {
  return (
    <React.Fragment>
      {cell.value === "Medium" ? (
        <span className="badge bg-warning text-uppercase">{cell.value}</span>
      ) : cell.value === "High" ? (
        <span className="badge bg-danger text-uppercase">{cell.value}</span>
      ) : cell.value === "Low" ? (
        <span className="badge bg-success text-uppercase">{cell.value}</span>
      ) : cell.value === "Normal" ? (
        <span className="badge bg-info text-uppercase">{cell.value}</span>
      ) : cell.value === "Urgent" ? (
        <span style={{ backgroundColor: "red" }} className="badge  text-uppercase">
          {cell.value}
        </span>
      ) : null}
    </React.Fragment>
  );
};

export { NotesId as CasesId, Title, Client, AssignedTo, CreateDate, DueDate, Status, Priority };
