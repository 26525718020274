import {
  GET_TEAM,
  GET_TEAMS_LIST,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  UPDATE_TEAM,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAIL,
  DELETE_TEAM,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_FAIL,
  ADD_NEW_TEAM,
  ADD_NEW_TEAM_SUCCESS,
  ADD_NEW_TEAM_FAIL,
  RESET_TEAM_FLAG,
} from "./actionType";

const INIT_STATE = {
  teamsList: null,
};

const Teams = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_TEAMS_LIST:
          return {
            ...state,
            teamsList: action.payload.data.teams,
            page: action.payload.data.page,
            isTeamCreated: false,
            isTeamSuccess: true,
          };
        case GET_TEAM:
          return {
            ...state,
            team: action.payload.data,
            isTeamSuccess: true,
            isTeamCreated: false,
          };
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_TEAMS_LIST:
        case GET_TEAM:
          return {
            ...state,
            error: action.payload.error,
            isTeamCreated: false,
            isTeamSuccess: false,
          };
        default:
          return { ...state };
      }
    // case GET_TEAMS_LIST: {
    //  return {
    //    ...state,
    //    isTeamCreated: false,
    //  }
    // }
    case ADD_NEW_TEAM_SUCCESS:
      return {
        ...state,
        isTeamCreated: true,
        isTeamAdd: true,
        isCaseAddFail: false,
      };
    case ADD_NEW_TEAM_FAIL:
      return {
        ...state,
        error: action.payload,
        isTeamAdd: false,
        isTeamAddFail: true,
      };
    case UPDATE_TEAM_SUCCESS:
      return {
        ...state,
        isTeamUpdate: true,
        isTeamUpdateFail: false,
      };
    case UPDATE_TEAM_FAIL:
      return {
        ...state,
        error: action.payload,
        isTeamUpdate: true,
        isTeamUpdateFail: true,
      };
    case DELETE_TEAM_FAIL:
      return {
        ...state,
        error: action.payload,
        isTeamDelete: false,
        isTeamDeleteFail: true,
      };
    case DELETE_TEAM_SUCCESS:
      return {
        ...state,
        teamsList: state.teamsList.filter((team) => team.id.toString() !== action.payload.team.toString()),
        isTeamDelete: true,
        isTeamDeleteFail: false,
      };
    case RESET_TEAM_FLAG:
      return {
        ...state,
        isTeamAdd: false,
        isTeamAddFail: false,
        isTeamCreated: false,
        isTeamDelete: false,
        isTeamDeleteFail: false,
        isTeamUpdate: false,
        isTeamUpdateFail: false,
        errors: false,
      };
    default:
      return { ...state };
  }
};

export default Teams;
