import React from "react";
import UpdateCaseForm from "../FormComponent/CreateCase";

const CreateCase = () => {
  document.title = "Create Lead | Velzon - React Admin & Dashboard Template";

  return (
    // <div className="page-content">
    <UpdateCaseForm title="Update Case" updateCase />
    // </div>
  );
};

export default CreateCase;
