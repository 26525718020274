export const CREATE_ORGANIZAION = "CREATE_ORGANIZAION";
export const UPDATE_ORGANIZAION = "UPDATE_ORGANIZAION";
export const CREATE_ORGANIZAION_SUCCESSFUL = "CREATE_ORGANIZAION_SUCCESSFUL";
export const CREATE_ORGANIZAION_ERROR = "CREATE_ORGANIZAION_ERROR";
export const CREATE_ORGANIZAION_FAILED = "CREATE_ORGANIZAION_FAILED";
export const CREATE_ORGANIZAION_FLAG = "CREATE_ORGANIZAION_FLAG";

export const CREATE_ORGANIZAION_ADDRESS = "CREATE_ORGANIZAION_ADDRESS";
export const UPDATE_ORGANIZAION_ADDRESS = "UPDATE_ORGANIZAION_ADDRESS";
export const UPDATE_ORG_ADDRESS_SUCCESSFUL = "UPDATE_ORG_ADDRESS_SUCCESSFUL";

export const UPDATE_ORGANIZATION_SUCCESSFUL = "UPDATE_ORGANIZATION_SUCCESSFUL";
export const UPDATE_ORGANIZATION_ERROR = "UPDATE_ORGANIZATION_ERROR";
