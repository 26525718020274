import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useParams } from "react-router-dom";
import { resetCaseFlag, resetNoteFlag } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
// import NotesTab from "./Tabs/NotesTab";
// import CasesTab from "./Tabs/CasesTab";
// import ActivitiesTab from "./Tabs/ActivitiesTab";
// import DocumentsTab from "./Tabs/DocumentsTab";
// import CasesList from "./CasesList";
// import NotesList from "./NotesList";
import MsgToast from "../../../Components/Common/MsgToast";
import { getCase, getCaseNotes, getClientDetails } from "../../../helpers/backend_helper.js";
import Loader from "../../../Components/Common/Loader";
import NotesList from "../../clients/NotesList";
// import EmptyPage from "./EmptyPage";

const DetailsPage = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [notes, setNotes] = useState([]);
  const [caseData, setCasedata] = useState(null);
  const [client, setClient] = useState(null);
  const [casesLength, setDeleteDatasLength] = useState("");
  const [notesLength, setNotesLength] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();
  const dispatch = useDispatch();

  const { isCaseDeleteFail, isNoteDelete, isCaseDelete, isNoteDeleteFail } = useSelector((state) => ({
    isNoteDelete: state.Notes.isNoteDelete,
    isNoteDeleteFail: state.Notes.isNoteDeleteFail,
    isCaseDelete: state.Cases.isCaseDelete,
    isCaseDeleteFail: state.Cases.isCaseDeleteFail,
  }));

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetCaseFlag());
    }, 3000);
  }, [isCaseDelete]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetNoteFlag());
    }, 3000);
  }, [isNoteDelete]);

  useEffect(() => {
    setIsLoading(true);
    const fetchClientDetails = async () => {
      try {
        let res = await getCaseNotes(id);

        if (res.status === 200) {
          setCasedata(res.case);
          setNotes(res.notes);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };

    fetchClientDetails();
  }, [isNoteDelete, isCaseDelete]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  document.title = "Client details page | Simplynp";

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading ? <Loader /> : null}

        {/* {!isLoading && !client && <EmptyPage />} */}

        {caseData && (
          <Container fluid>
            <div className="profile-foreground position-relative mx-n4 mt-n4">
              <div className="profile-wid-bg"></div>
            </div>
            <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
              <Row className="g-4">
                <Col>
                  <div className="p-2">
                    <h3 className="text-white mb-1">{caseData && `${caseData.title || ""} `}</h3>
                    <p className="text-white-75">
                      {" "}
                      {`priority:`} {(caseData && caseData.priority) || ""}
                    </p>
                    <div className="hstack text-white-50 gap-1">
                      <div className="me-2">
                        {`status: `} {(caseData && caseData.status) || ""}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <Row>
              <Col lg={12}>
                <div>
                  <div className="d-flex">
                    <Nav pills className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
                      <NavItem>
                        <NavLink
                          href="#overview"
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => {
                            toggleTab("1");
                          }}
                        >
                          <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
                          <span className="d-none d-md-inline-block">Overview</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <div className="flex-shrink-0">
                      <Link to={`/updatecase/${caseData && caseData.id}`} className="btn btn-success">
                        <i className="ri-edit-box-line align-bottom"></i> Edit case
                      </Link>
                    </div>
                  </div>

                  <TabContent activeTab={activeTab} className="pt-4">
                    <TabPane tabId="1">
                      <Row>
                        <Col xxl={3}>
                          <Card>
                            <CardBody>
                              <div className="table-responsive">
                                <Table className="table-borderless mb-0">
                                  <tbody>
                                    <tr>
                                      <th className="ps-0" scope="row">
                                        case type :
                                      </th>
                                      <td className="text-muted">{caseData && `${caseData.case_type || ""}  `}</td>
                                    </tr>
                                    <tr>
                                      <th className="ps-0" scope="row">
                                        created by :
                                      </th>
                                      <td className="text-muted">
                                        {` ${(caseData && caseData.created_by.user_details.first_name) || ""}
                                        ${(caseData && caseData.created_by.user_details.last_name) || ""}`}
                                      </td>
                                    </tr>

                                    <tr>
                                      <th className="ps-0" scope="row">
                                        CLient :
                                      </th>

                                      <td className="text-muted">
                                        <Link to={`/clients-details-page/${caseData && caseData.client.id}`}>
                                          {` ${(caseData && caseData.client.first_name) || ""}
                                        ${(caseData && caseData.client.last_name) || ""}`}
                                        </Link>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className="ps-0" scope="row">
                                        due date :
                                      </th>
                                      <td className="text-muted"> {(caseData && caseData.due_date) || ""}</td>
                                    </tr>
                                    <tr>
                                      <th className="ps-0" scope="row">
                                        Created on
                                      </th>
                                      <td className="text-muted">
                                        {(caseData && caseData.created_on?.split("T")[0]) || ""}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th className="ps-0" scope="row">
                                        Description
                                      </th>
                                      <td className="text-muted">{(caseData && caseData.description) || ""}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </CardBody>
                          </Card>

                          <Card>
                            <CardBody>
                              <div className="d-flex align-items-center mb-4">
                                <div className="flex-grow-1">
                                  <h5 className="card-title mb-0">Notes ({notes && notes.length})</h5>
                                </div>
                              </div>
                              <div>
                                <NotesList notes={notes} />
                              </div>
                              {/* <Button
                                onClick={() => toggleTab("5")}
                                color="dark"
                                outline
                                className="btn btn-ghost-dark"
                              >
                                view all
                              </Button> */}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
                {isNoteDeleteFail ? (
                  <MsgToast
                    msg="an error occured while deleting note, please try again"
                    color="danger"
                    icon="ri-checkbox-circle-line"
                  />
                ) : null}
                {isCaseDelete ? (
                  <MsgToast msg="Cases deleted Successfully" color="success" icon="ri-checkbox-circle-line" />
                ) : null}
                {isNoteDelete ? (
                  <MsgToast msg="Note deleted Successfully" color="success" icon="ri-checkbox-circle-line" />
                ) : null}
                {isCaseDeleteFail ? (
                  <MsgToast
                    msg="an error occured while deleting case, please try again"
                    color="danger"
                    icon="ri-checkbox-circle-line"
                  />
                ) : null}
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
};

export default DetailsPage;
