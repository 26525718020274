import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Label, Row } from "reactstrap";
import MsgToast from "../../../../Components/Common/MsgToast";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";

import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import industryOptions from "../../../../common/data/industry.json";

import { FilePond } from "react-filepond";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import TextAreaField from "../../../../Components/Common/Fields/TextAreaField";
import TextField from "../../../../Components/Common/Fields/TextField.js";
import NumberField from "../../../../Components/Common/NumberField";
import countries from "../../../../common/data/countries.json";
import {
  addNewOrganizations,
  getOrganizations,
  getUsersList as onGetUsers,
  resetCrmFlag,
  updateOrganizations,
} from "../../../../store/actions";
import { getUsers } from "../../../../helpers/backend_helper";
import IntNumberInput from "../../../../Components/Common/Fields/IntNumberInput";

const CreateOrganizationForm = (props) => {
  const dispatch = useDispatch();
  const { id: org_pk } = useParams();

  const [org, setOrg] = useState({});
  const [files, setFiles] = useState([]);
  const [assignedUser, setAssignedUser] = useState(null);

  const statusOptions = [
    // { id: 1, name: "status", label: "Select A Status...", value: "" },
    { name: "active", label: "Active", value: "active" },
    { name: "inactive", label: "Inactive", value: "inactive" },
  ];

  const typeOptions = [
    // { id: 1, name: "status", label: "Select A Status...", value: "" },
    { name: "prospect", label: "Prospect", value: "prospect" },
    { name: "customer-direct", label: "Customer(Direct)", value: "customer-direct" },
    { name: "customer-channel", label: "Customer(Channel)", value: "customer-channel" },
    { name: "customer-reseller", label: "Customer(Reseller)", value: "customer-reseller" },
  ];

  const ownershipOptions = [
    // { id: 1, name: "status", label: "Select A Status...", value: "" },
    { name: "private", label: "Private", value: "private" },
    { name: "Public", label: "Public", value: "public" },
    { name: "subsidiary", label: "Subsidiary", value: "subsidiary" },
    { name: "other", label: "Other", value: "other" },
  ];

  let {
    organizations,
    contactList,
    usersList,
    loading,
    success,
    error,
    errors,
    isOrganizationSuccess,
    isOrganizationsAdd,
    isOrganizationsAddFail,
    isOrganizationsUpdate,
    isOrganizationsUpdateFail,
  } = useSelector((state) => ({
    success: state.Crm.success,
    error: state.Crm.error,
    errors: state.Crm.errors,
    usersList: state.Users.usersList,
    contactList: state.Crm.crmcontacts,
    loading: state.Crm.loading,
    organizations: state.Crm.organizations,
    isOrganizationsSuccess: state.Crm.isOrganizationsSuccess,
    isOrganizationsAdd: state.Crm.isOrganizationsAdd,
    isOrganizationsUpdate: state.Crm.isOrganizationsUpdate,
    isOrganizationsAddFail: state.Crm.isOrganizationsAddFail,
    isOrganizationsUpdateFail: state.Crm.isOrganizationsUpdateFail,
  }));

  const loadUsersOptions = (inputValue) => {
    return new Promise((resolve) => {
      const orgList = getUsers({ search: inputValue }).then(({ users: { users } }) => users);
      resolve(orgList);
    });
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetCrmFlag());
    }, 3000);
  }, [
    dispatch,
    success,
    isOrganizationSuccess,
    isOrganizationsAdd,
    isOrganizationsAddFail,
    isOrganizationsUpdate,
    isOrganizationsUpdateFail,
  ]);

  /*  Get the Contacts Of the Oganization and Create Labels*/
  useEffect(() => {
    if (!organizations) {
      dispatch(getOrganizations({ id: org_pk }));
      return;
    }

    if (org_pk) {
      const org = organizations?.find((org) => org.id.toString() === org_pk);
      setOrg(org);
      setAssignedUser(org?.assigned_to);
    }

    if (!usersList) dispatch(onGetUsers());
  }, [dispatch, organizations, usersList, org_pk]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // ...(props.updateOrg ? id : (org && org.id) || ""),
      account_name: (org && org.account_name) || "",
      organization_number: (org && org.organization_number) || "",
      phone: (org && org.phone) || "",
      fax: (org && org.fax) || "",
      website: (org && org.website) || null,
      description: (org && org.description) || "",
      ownership: (org && org.ownership) || "",
      type: (org && org.type) || "",
      industry: (org && org.industry) || "",
      employees: (org && org.employees) || "",
      annual_revenue: (org && org.annual_revenue) || "",

      /*TODO billing and shippping address should be displayed based on condition
       * eg. Billing address same as Shipping address? If yes, display one address card
       * else display both billing and shipping card on page
       */

      billing_address_line: (org && org.billing_address_line) || "",
      billing_street: (org && org.billing_street) || "",
      billing_city: (org && org.billing_city) || "",
      billing_state: (org && org.billing_state) || "",
      billing_postcode: (org && org.billing_postcode) || "",
      billing_country: (org && org.billing_country) || "",

      status: (org && org.status) || "active",
      priority: (org && org.priority) || "",
      locations: (org && org.locations) || "",
    },

    validationSchema: Yup.object({
      account_name: Yup.string().required("Please enter account name"),
      phone: Yup.string().notRequired("Please enter organization phone number"),
      status: Yup.string().notRequired("Please enter status"),
      type: Yup.string().notRequired("Please enter type"),

      organization_number: Yup.mixed().notRequired("Please select orgaization number"),
      ownership: Yup.string().notRequired("Please enter ownership type"),
      employees: Yup.string().notRequired("Please enter number of employees"),
      annual_revenue: Yup.string().notRequired("Please enter organization annual revenue"),

      priority: Yup.string().notRequired("Please enter priority"),
      locations: Yup.string().notRequired("Please enter number of locations"),
      industry: Yup.string().notRequired("Please enter the industry"),

      billing_address_line: Yup.string().notRequired("Please enter address line"),
      billing_street: Yup.string().notRequired("Please enter organization street"),
      billing_city: Yup.string().notRequired("Please enter organization city"),
      billing_state: Yup.string().notRequired("Please enter organization state"),
      billing_postcode: Yup.string().notRequired("Please enter organization post Code"),
      billing_country: Yup.string().notRequired("Please enter organization country"),
      website: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Website should match: http://example.com"
        )
        .nullable()
        .notRequired("Please enter organization website"),

      description: Yup.string().notRequired("Please enter organization description"),
      fax: Yup.string().notRequired("Please enter organization fax mu,ber"),
      opportunity_amount: Yup.string().notRequired("Please enter amount"),
    }),

    onSubmit: (values) => {
      if (props.updateOrg || org_pk) {
        const u_values = { ...values, id: org_pk };
        dispatch(updateOrganizations(u_values));
      } else {
        dispatch(addNewOrganizations(values));
      }
      // reset();
    },
  });

  const reset = () => {
    validation.setValues({});
    setAssignedUser(null);
    validation.resetForm();
  };

  const handleAssignObject = (labels, field, stateCallback) => {
    const assigned = labels.map((item) => item.value);
    const stringified = JSON.stringify(assigned);
    validation.setFieldValue(field, stringified);
    stateCallback(assigned);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Create organization" pageTitle="organizations " />

        <Row>
          <Col lg={8}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onReset={(e) => {
                e.preventDefault();
                reset();
                return false;
              }}
            >
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Business Information</h5>
                </CardHeader>
                <CardBody>
                  <Col>
                    <Row>
                      <TextField
                        md="12"
                        mb="mb-3"
                        value={validation.values.account_name || ""}
                        onChange={validation.handleChange}
                        validation={validation}
                        onBlur={validation.handleBlur}
                        label="Account Name"
                        name="account_name"
                        placeholder="Enter Account Name"
                        //errorMessage={error}
                      />
                    </Row>
                    <Row>
                      <IntNumberInput
                        mb={"mb-3"}
                        md="6"
                        value={validation.values.phone || ""}
                        onChange={validation.handleChange}
                        validation={validation}
                        onBlur={validation.handleBlur}
                        label="Phone"
                        placeholder="Enter Organizations Phone Here"
                        name="phone"
                        //errorMessage={client_errors}
                      />

                      <TextField
                        mb={"mb-3"}
                        md="6"
                        value={validation.values.organization_number || ""}
                        onChange={validation.handleChange}
                        validation={validation}
                        onBlur={validation.handleBlur}
                        label="Organization Number"
                        placeholder="Enter Organization number Here"
                        name="organization_number"
                      />
                    </Row>
                    <Row>
                      <IntNumberInput
                        md="6"
                        mb="mb-3"
                        onChange={validation.handleChange}
                        validation={validation}
                        onBlur={validation.handleBlur}
                        placeholder="Enter Fax Here"
                        value={validation.values.fax || ""}
                        label="Fax"
                        name="fax"
                        //errorMessage={client_errors}
                      />

                      <TextField
                        mb={"mb-3"}
                        name="website"
                        value={validation.values.website || ""}
                        onChange={validation.handleChange}
                        validation={validation}
                        onBlur={validation.handleBlur}
                        placeholder="Enter Organization's Website"
                        label="Organization Website"
                      />
                    </Row>
                    <Row>
                      <TextAreaField
                        mb="mb-3"
                        md="12"
                        value={validation.values.description || ""}
                        onChange={validation.handleChange}
                        validation={validation}
                        onBlur={validation.handleBlur}
                        placeholder="Enter Organization's Description Here"
                        label="Description"
                        name="description"
                      />
                    </Row>
                  </Col>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Contact Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label htmlFor="choices-lead-input" className="form-label">
                          Industry
                        </Label>

                        <Select
                          name="industry"
                          onChange={(e) => validation.setFieldValue("industry", e.value)}
                          options={industryOptions}
                          placeholder="select an industry..."
                          id="industry-input"
                          value={industryOptions.find((g) => g.value === validation.values.industry)}
                          invalid={validation.touched.industry && validation.errors.industry ? true : false}
                          classNamePrefix="select2-selection form-select"
                        />
                        {validation.touched.industry && validation.errors.industry ? (
                          <FormFeedback type="invalid">{validation.errors.industry}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col>
                      <div className="mb-3">
                        <Label htmlFor="choices-lead-input" className="form-label">
                          Ownership
                        </Label>

                        <Select
                          name="ownership"
                          onChange={(e) => validation.setFieldValue("ownership", e.value)}
                          options={ownershipOptions}
                          placeholder="select an ownership type..."
                          id="ownership-input"
                          value={ownershipOptions.find((g) => g.value === validation.values.ownership)}
                          invalid={validation.touched.ownership && validation.errors.ownership ? true : false}
                          classNamePrefix="select2-selection form-select"
                        />
                        {validation.touched.ownership && validation.errors.ownership ? (
                          <FormFeedback type="invalid">{validation.errors.ownership}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label htmlFor="choices-lead-input" className="form-label">
                          Type
                        </Label>

                        <Select
                          name="type"
                          onChange={(e) => validation.setFieldValue("type", e.value)}
                          options={typeOptions}
                          placeholder="select a type..."
                          id="type-input"
                          value={typeOptions.find((g) => g.value === validation.values.type)}
                          invalid={validation.touched.type && validation.errors.type ? true : false}
                          classNamePrefix="select2-selection form-select"
                        />
                        {validation.touched.type && validation.errors.type ? (
                          <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <NumberField
                      mb="mb-3"
                      md="6"
                      value={validation.values.employees || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      label="Number of Employees"
                      placeholder="Enter Number of employees here"
                      name="employees"
                    />
                  </Row>

                  <Row>
                    <TextField
                      mb={"mb-3"}
                      name="annual_revenue"
                      value={validation.values.annual_revenue || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter annual revenue here"
                      label="Annual Revenue"
                    />
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Billing Address Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="mb-3 col-md-6">
                      <Label htmlFor="choices-text-input" className="form-label">
                        Country
                      </Label>
                      <Select
                        className={`${
                          validation.touched.billing_country && validation.errors.billing_country
                            ? "form-control is-invalid"
                            : ""
                        } `}
                        name="billing_country"
                        defaultValue={countries.find((c) => c.value === validation.values.billing_country)}
                        key={countries.find((c) => c.value === validation.values.billing_country)}
                        id="choices-text-input"
                        onChange={(e) => validation.setFieldValue("billing_country", e.value)}
                        options={countries}
                      />
                      {validation.touched.billing_country && validation.errors.billing_country ? (
                        <FormFeedback type="invalid">{validation.errors.billing_country}</FormFeedback>
                      ) : null}
                    </div>

                    <TextField
                      mb="mb-3"
                      md="6"
                      value={validation.values.billing_postcode || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      label="Postcode"
                      placeholder="Enter Post Code Here"
                      name="billing_postcode"
                    />
                  </Row>

                  <Row>
                    <TextField
                      mb="mb-3"
                      md="6"
                      value={validation.values.billing_address_line || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      label="Address"
                      placeholder="Enter Address Line Here"
                      name="billing_address_line"
                    />
                    <TextField
                      mb="mb-3"
                      md="6"
                      value={validation.values.billing_street || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      label="Street"
                      placeholder="Enter Street Here"
                      name="billing_street"
                    />
                  </Row>

                  <Row>
                    <TextField
                      mb="mb-3"
                      md="6"
                      value={validation.values.billing_state || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter Your State"
                      label="State"
                      name="billing_state"
                    />
                    <TextField
                      mb="mb-3"
                      md="6"
                      value={validation.values.billing_city || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter Your City of Billing Here"
                      label="City"
                      name="billing_city"
                    />
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Additional Information</h5>
                </CardHeader>
                <CardBody>
                  <Col>
                    <Row>
                      <TextField
                        md="6"
                        mb={"mb-3"}
                        value={validation.values.priority || ""}
                        onChange={validation.handleChange}
                        validation={validation}
                        onBlur={validation.handleBlur}
                        label="Priority"
                        name="priority"
                        placeholder="Enter Account Priority"
                        // errorMessage={error}
                      />
                      <TextField
                        mb={"mb-3"}
                        md="6"
                        value={validation.values.locations || ""}
                        onChange={validation.handleChange}
                        validation={validation}
                        onBlur={validation.handleBlur}
                        label="Number of Locations"
                        placeholder="Enter number of locations Here"
                        name="locations"
                      />
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="choices-lead-input" className="form-label">
                            Status
                          </Label>

                          <Select
                            name="type"
                            onChange={(e) => validation.setFieldValue("status", e.value)}
                            options={statusOptions}
                            placeholder="select a status..."
                            id="status-input"
                            value={statusOptions.find((g) => g.value === validation.values.status)}
                            invalid={validation.touched.status && validation.errors.status ? true : false}
                            classNamePrefix="select2-selection form-select"
                          />
                          {validation.touched.status && validation.errors.status ? (
                            <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col></Col>
                    </Row>
                  </Col>
                </CardBody>
              </Card>

              <div className="text-end mb-3">
                <button onClick={(e) => validation.handleReset(e)} className="btn btn-danger w-sm me-1">
                  Clear
                </button>
                <button type="submit" className="btn btn-success w-sm">
                  {/* disabled={validation.isSubmitting} */}
                  Submit
                </button>
              </div>
            </Form>
          </Col>

          <Col lg={4}>
            <Card className="">
              <CardHeader>
                <h5 className="card-title mb-0">Assign To User(s) </h5>
              </CardHeader>
              <CardBody>
                <p className="text-muted mb-0">Assign Here</p>
                <AsyncSelect
                  className={`${
                    validation.touched.assigned_to && validation.errors.assigned_to ? "form-control is-invalid" : ""
                  } `}
                  id="choices-assigned-input"
                  value={assignedUser}
                  key={assignedUser}
                  onBlur={validation.handleBlur}
                  name="assigned_to"
                  placeholder="Select a to Assign"
                  isMulti={true}
                  onChange={(e) => {
                    validation.setFieldValue("assigned_to", JSON.stringify(e.map((c) => c?.id)));
                    setAssignedUser(e);
                  }}
                  isSearchable
                  isClearable
                  loadOptions={loadUsersOptions}
                  getOptionLabel={(option) => `${option?.user_details.first_name} ${option?.user_details.last_name}`}
                  getOptionValue={(option) => option?.id}
                  defaultOptions={usersList}
                />
                {validation.touched.assigned_to && validation.errors.assigned_to ? (
                  <FormFeedback type="invalid">{validation.errors.assigned_to}</FormFeedback>
                ) : null}
              </CardBody>
            </Card>

            <Card className="">
              <CardHeader>
                <h5 className="card-title mb-0"> Attachment</h5>
              </CardHeader>
              <CardBody>
                <div>
                  <p className="text-muted">Add Attached files here.</p>
                  <FilePond
                    files={files}
                    onupdatefiles={setFiles}
                    allowMultiple={true}
                    maxFiles={1}
                    name="lead_attachment"
                    // value={validation.values.case_attachment}
                    className="filepond filepond-input-multiple"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {isOrganizationsAdd ? (
          <MsgToast msg="Organization Added Successfully" color="success" icon="ri-checkbox-circle-line" />
        ) : null}
        {isOrganizationsAddFail ? (
          <MsgToast msg="Organization Added Failed" color="danger" icon="ri-close-circle-line" />
        ) : null}
        {isOrganizationsUpdate ? (
          <MsgToast msg="Organization Updated Successfully" color="success" icon="ri-checkbox-circle-line" />
        ) : null}
        {isOrganizationsUpdateFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
        {/* {error ? (
          <MsgToast msg="some error occured, check below and try again" color="danger" icon="ri-error-warning-line" />
        ) : null} */}
        {/* {Object.keys(validation.errors).length > 0 ? (
          <MsgToast
            msg={"You forgot some required fields"}
            color="danger"
            icon="ri-error-warning-line"
          />
        ) : null} */}
      </Container>
    </div>
  );
};

export default CreateOrganizationForm;
