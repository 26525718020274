import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Row,
  TabContent,
  Table,
  TabPane,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from "reactstrap";
import classnames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

//Images
import profileBg from "../../../assets/images/profile-bg.jpg";
import avatar1 from "../../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../../assets/images/users/avatar-5.jpg";
import avatar6 from "../../../assets/images/users/avatar-6.jpg";
import avatar7 from "../../../assets/images/users/avatar-7.jpg";
import avatar8 from "../../../assets/images/users/avatar-8.jpg";

import smallImage2 from "../../../assets/images/small/img-2.jpg";
import smallImage3 from "../../../assets/images/small/img-3.jpg";
import smallImage4 from "../../../assets/images/small/img-4.jpg";
import smallImage5 from "../../../assets/images/small/img-5.jpg";
import smallImage6 from "../../../assets/images/small/img-6.jpg";
import smallImage7 from "../../../assets/images/small/img-7.jpg";
import smallImage9 from "../../../assets/images/small/img-9.jpg";

const ContactDetails = ({ contact }) => {
  const projects = [
    {
      id: 1,
      title: "Chat App Update",
      updatedTime: "2 year Ago",
      badgeText: "Inprogress",
      badgeClass: "warning",
      member: [
        {
          id: 1,
          img: avatar1,
        },
        {
          id: 2,
          img: avatar3,
        },
      ],
      cardBorderColor: "warning",
      memberName: [
        {
          id: 1,
          memberText: "J",
        },
      ],
    },
    {
      id: 2,
      title: "ABC Project Customization",
      updatedTime: "2 month Ago",
      badgeText: "Progress",
      badgeClass: "primary",
      member: [
        {
          id: 1,
          img: avatar8,
        },
        {
          id: 2,
          img: avatar7,
        },
        {
          id: 3,
          img: avatar6,
        },
      ],
      cardBorderColor: "success",
      memberName: [
        {
          id: 1,
          memberText: "2+",
        },
      ],
    },
    {
      id: 3,
      title: "Client - Frank Hook",
      updatedTime: "1 hr Ago",
      badgeText: "New",
      badgeClass: "info",
      member: [
        {
          id: 1,
          img: avatar4,
        },
        {
          id: 2,
          img: avatar3,
        },
      ],
      cardBorderColor: "info",
      memberName: [
        {
          id: 1,
          memberText: "M",
        },
      ],
    },
    {
      id: 4,
      title: "Velzon Project",
      updatedTime: "11 hr Ago",
      badgeText: "Completed",
      badgeClass: "success",
      member: [
        {
          id: 1,
          img: avatar7,
        },
        {
          id: 2,
          img: avatar5,
        },
      ],
      cardBorderColor: "primary",
    },
    {
      id: 5,
      title: "Brand Logo Design",
      updatedTime: "10 min Ago",
      badgeText: "New",
      badgeClass: "info",
      member: [
        {
          id: 1,
          img: avatar7,
        },
        {
          id: 2,
          img: avatar6,
        },
      ],
      cardBorderColor: "danger",
      memberName: [
        {
          id: 1,
          memberText: "E",
        },
      ],
    },
    {
      id: 6,
      title: "Chat App",
      updatedTime: "8 hr Ago",
      badgeText: "Inprogress",
      badgeClass: "warning",
      member: [
        {
          id: 1,
          img: avatar3,
        },
        {
          id: 2,
          img: avatar8,
        },
      ],
      memberName: [
        {
          id: 1,
          memberText: "R",
        },
      ],
      cardBorderColor: "primary",
    },
    {
      id: 7,
      title: "Project Update",
      updatedTime: "48 min Ago",
      badgeText: "Inprogress",
      badgeClass: "warning",
      member: [
        {
          id: 1,
          img: avatar6,
        },
        {
          id: 2,
          img: avatar5,
        },
        {
          id: 3,
          img: avatar4,
        },
      ],
      cardBorderColor: "warning",
    },
    {
      id: 8,
      title: "Client - Jennifer",
      updatedTime: "30 min Ago",
      badgeText: "Process",
      badgeClass: "primary",
      member: [
        {
          id: 1,
          img: avatar1,
        },
      ],
      cardBorderColor: "success",
    },
    {
      id: 9,
      title: "Business Template -UI/UX design",
      updatedTime: "7 month Ago",
      badgeText: "Completed",
      badgeClass: "success",
      member: [
        {
          id: 1,
          img: avatar2,
        },
        {
          id: 2,
          img: avatar3,
        },
        {
          id: 3,
          img: avatar4,
        },
      ],
      cardBorderColor: "info",
      memberName: [
        {
          id: 1,
          memberText: "2+",
        },
      ],
    },
    {
      id: 10,
      title: "Update Project",
      updatedTime: "1 month Ago",
      badgeText: "New",
      badgeClass: "info",
      member: [
        {
          id: 1,
          img: avatar7,
        },
      ],
      memberName: [
        {
          id: 1,
          memberText: "A",
        },
      ],
      cardBorderColor: "success",
    },
    {
      id: 11,
      title: "Bank Management System",
      updatedTime: "10 month Ago",
      badgeText: "Completed",
      badgeClass: "success",
      member: [
        {
          id: 1,
          img: avatar7,
        },
        {
          id: 2,
          img: avatar6,
        },
        {
          id: 3,
          img: avatar5,
        },
      ],
      cardBorderColor: "danger",
      memberName: [
        {
          id: 1,
          memberText: "2+",
        },
      ],
    },
    {
      id: 12,
      title: "PSD to HTML Convert",
      updatedTime: "29 min Ago",
      badgeText: "New",
      badgeClass: "info",
      member: [
        {
          id: 1,
          img: avatar7,
        },
      ],
      cardBorderColor: "primary",
    },
  ];

  const document = [
    {
      id: 1,
      icon: "ri-file-zip-fill",
      iconBackgroundClass: "primary",
      fileName: "Artboard-documents.zip",
      fileType: "Zip File",
      fileSize: "4.57 MB",
      updatedDate: "12 Dec 2021",
    },
    {
      id: 2,
      icon: "ri-file-pdf-fill",
      iconBackgroundClass: "danger",
      fileName: "Bank Management System",
      fileType: "PDF File",
      fileSize: "8.89 MB",
      updatedDate: "24 Nov 2021",
    },
    {
      id: 3,
      icon: "ri-video-line",
      iconBackgroundClass: "secondary",
      fileName: "Tour-video.mp4",
      fileType: "MP4 File",
      fileSize: "14.62 MB",
      updatedDate: "19 Nov 2021",
    },
    {
      id: 4,
      icon: "ri-file-excel-fill",
      iconBackgroundClass: "secondary",
      fileName: "Tour-video.mp4",
      fileType: "XSL File",
      fileSize: "2.38 KB",
      updatedDate: "14 Nov 2021",
    },
    {
      id: 5,
      icon: "ri-folder-line",
      iconBackgroundClass: "info",
      fileName: "Project Screenshots Collection",
      fileType: "Folder File",
      fileSize: "87.24 MB",
      updatedDate: "08 Nov 2021",
    },
    {
      id: 6,
      icon: "ri-image-2-fill",
      iconBackgroundClass: "danger",
      fileName: "Velzon-logo.png",
      fileType: "PNG File",
      fileSize: "879 KB",
      updatedDate: "02 Nov 2021",
    },
  ];
  SwiperCore.use([Autoplay]);

  const [activeTab, setActiveTab] = useState("1");
  const [activityTab, setActivityTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleActivityTab = (tab) => {
    if (activityTab !== tab) {
      setActivityTab(tab);
    }
  };

  document.title = "Contact Details | SimplyNP";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="profile-foreground position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg">
              <img src={profileBg} alt="" className="profile-wid-img" />
            </div>
          </div>
          <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
            <Row className="g-4">
              <div className="col-auto">
                <div className="avatar-lg">
                  <img src={avatar1} alt="user-img" className="img-thumbnail rounded-circle" />
                </div>
              </div>

              <Col>
                <div className="p-2">
                  <h3 className="text-white mb-1">Anna Adame</h3>
                  <p className="text-white-75">Owner & Founder</p>
                  <div className="hstack text-white-50 gap-1">
                    <div className="me-2">
                      <i className="ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle"></i>
                      California, United States
                    </div>
                    <div>
                      <i className="ri-building-line me-1 text-white-75 fs-16 align-middle"></i>
                      Themesbrand
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <Row>
            <Col lg={12}>
              <div>
                <div className="d-flex">
                  <Nav pills className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
                    <NavItem>
                      <NavLink
                        href="#overview-tab"
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggleTab("1");
                        }}
                      >
                        <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">Overview</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#activities"
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggleTab("2");
                        }}
                      >
                        <i className="ri-list-unordered d-inline-block d-md-none"></i>{" "}
                        <span className="d-none d-md-inline-block">Documents</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <div className="flex-shrink-0">
                    <Link to="/pages-profile-settings" className="btn btn-success">
                      <i className="ri-edit-box-line align-bottom"></i> Edit Profile
                    </Link>
                  </div>
                </div>

                <TabContent activeTab={activeTab} className="pt-4">
                  <TabPane tabId="1">
                    <Row>
                      <Col xxl={3}>
                        <Card>
                          <CardBody>
                            <h5 className="card-title mb-5">Complete Your Profile</h5>
                            <Progress
                              value={30}
                              color="danger"
                              className="animated-progess custom-progress progress-label"
                            >
                              <div className="label">30%</div>{" "}
                            </Progress>
                          </CardBody>
                        </Card>

                        <Card>
                          <CardBody>
                            <h5 className="card-title mb-4">Portfolio</h5>
                            <div className="d-flex flex-column gap-2">
                              <div>
                                <Link to="#" className="avatar-xs d-inline-block">
                                  <span className="avatar-title rounded-circle fs-16 bg-primary text-light">
                                    <i className="ri-linkedin-fill"></i>
                                  </span>
                                </Link>
                                <div className="d-inline-block ms-4 align-self-center">
                                  <span className="text-muted fs-14">daveadame@velzon.com</span>
                                </div>
                              </div>
                              <div>
                                <Link to="#" className="avatar-xs d-inline-block">
                                  <span className="avatar-title rounded-circle fs-16 bg-primary">
                                    <i className="ri-facebook-fill"></i>
                                  </span>
                                </Link>
                                <div className="d-inline-block ms-4 align-self-center">
                                  <span className="text-muted fs-14">daveadame@velzon.com</span>
                                </div>
                              </div>
                              <div>
                                <Link to="#" className="avatar-xs d-inline-block">
                                  <span className="avatar-title rounded-circle fs-16 bg-primary">
                                    <i className="ri-twitter-fill"></i>
                                  </span>
                                </Link>
                                <div className="d-inline-block ms-4 align-self-center">
                                  <span className="text-muted fs-14">daveadame@velzon.com</span>
                                </div>
                              </div>
                              <div>
                                <Link to="#" className="avatar-xs d-inline-block">
                                  <span className="avatar-title rounded-circle fs-16 bg-dark">
                                    <i className="ri-global-line"></i>
                                  </span>
                                </Link>
                                <div className="d-inline-block ms-4 align-self-center">
                                  <span className="text-muted fs-14">daveadame@velzon.com</span>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>

                        {/*                                                <Card>
                                                    <CardBody>
                                                        <h5 className="card-title mb-4">Skills</h5>
                                                        <div className="d-flex flex-wrap gap-2 fs-15">
                                                            <Link to="#"
                                                                className="badge badge-soft-primary">Photoshop</Link>
                                                            <Link to="#"
                                                                className="badge badge-soft-primary">illustrator</Link>
                                                            <Link to="#"
                                                                className="badge badge-soft-primary">HTML</Link>
                                                            <Link to="#"
                                                                className="badge badge-soft-primary">CSS</Link>
                                                            <Link to="#"
                                                                className="badge badge-soft-primary">Javascript</Link>
                                                            <Link to="#"
                                                                className="badge badge-soft-primary">Php</Link>
                                                            <Link to="#"
                                                                className="badge badge-soft-primary">Python</Link>
                                                        </div>
                                                    </CardBody>
                                                </Card>*/}

                        <Card>
                          <CardBody>
                            <div className="d-flex align-items-center mb-4">
                              <div className="flex-grow-1">
                                <h5 className="card-title mb-0">Assigned To</h5>
                              </div>
                            </div>
                            <div>
                              <div className="d-flex align-items-center py-3">
                                <div className="avatar-xs flex-shrink-0 me-3">
                                  <img src={avatar3} alt="" className="img-fluid rounded-circle" />
                                </div>
                                <div className="flex-grow-1">
                                  <div>
                                    <h5 className="fs-14 mb-1">Esther James</h5>
                                    <p className="fs-13 text-muted mb-0">Frontend Developer</p>
                                  </div>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                  <button type="button" className="btn btn-sm btn-outline-success">
                                    <i className="ri-user-add-line align-middle"></i>
                                  </button>
                                </div>
                              </div>
                              <div className="d-flex align-items-center py-3">
                                <div className="avatar-xs flex-shrink-0 me-3">
                                  <img src={avatar4} alt="" className="img-fluid rounded-circle" />
                                </div>
                                <div className="flex-grow-1">
                                  <div>
                                    <h5 className="fs-14 mb-1">Jacqueline Steve</h5>
                                    <p className="fs-13 text-muted mb-0">UI/UX Designer</p>
                                  </div>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                  <button type="button" className="btn btn-sm btn-outline-success">
                                    <i className="ri-user-add-line align-middle"></i>
                                  </button>
                                </div>
                              </div>
                              <div className="d-flex align-items-center py-3">
                                <div className="avatar-xs flex-shrink-0 me-3">
                                  <img src={avatar5} alt="" className="img-fluid rounded-circle" />
                                </div>
                                <div className="flex-grow-1">
                                  <div>
                                    <h5 className="fs-14 mb-1">George Whalen</h5>
                                    <p className="fs-13 text-muted mb-0">Backend Developer</p>
                                  </div>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                  <button type="button" className="btn btn-sm btn-outline-success">
                                    <i className="ri-user-add-line align-middle"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xxl={9}>
                        <Card>
                          <CardBody>
                            <h5 className="card-title mb-3">Description</h5>
                            <p>
                              Hi I'm Anna Adame, It will be as simple as Occidental; in fact, it will be Occidental. To
                              an English person, it will seem like simplified English, as a skeptical Cambridge friend
                              of mine told me what Occidental is European languages are members of the same family.
                            </p>
                            <p>
                              You always want to make sure that your fonts work well together and try to limit the
                              number of fonts you use to three or less. Experiment and play around with the fonts that
                              you already have in the software you’re working with reputable font websites. This may be
                              the most commonly encountered tip I received from the designers I spoke with. They highly
                              encourage that you use different fonts in one design, but do not over-exaggerate and go
                              overboard.
                            </p>
                            <Row>
                              <Col xs={6} md={4}>
                                <div className="d-flex mt-4">
                                  <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                    <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                      <i className="ri-user-2-fill"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1 overflow-hidden">
                                    <p className="mb-1">Designation :</p>
                                    <h6 className="text-truncate mb-0">Lead Designer / Developer</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xs={6} md={4}>
                                <div className="d-flex mt-4">
                                  <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                    <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                      <i className="ri-global-line"></i>
                                    </div>
                                  </div>
                                  <div className="flex-grow-1 overflow-hidden">
                                    <p className="mb-1">Website :</p>
                                    <Link to="#" className="fw-semibold">
                                      www.velzon.com
                                    </Link>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>

                        <Row>
                          <Col xxl={4}>
                            <Card className="card-height-100">
                              <CardBody>
                                <h5 className="card-title mb-3">Perfonal Info</h5>
                                <div className="table-responsive">
                                  <Table className="table-borderless mb-0">
                                    <tbody>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          Salutation :
                                        </th>
                                        <td className="text-muted">Anna Adame</td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          First Name :
                                        </th>
                                        <td className="text-muted">+(1) 987 6543</td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          Last Name :
                                        </th>
                                        <td className="text-muted">daveadame@velzon.com</td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          DOB :
                                        </th>
                                        <td className="text-muted">California, United States</td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          Title :
                                        </th>
                                        <td className="text-muted">California, United States</td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          Department :
                                        </th>
                                        <td className="text-muted">California, United States</td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          Organization
                                        </th>
                                        <td className="text-muted">Xacent Global</td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xxl={4}>
                            <Card className="card-height-100">
                              <CardBody>
                                <h5 className="card-title mb-3">Contact Info</h5>
                                <div className="table-responsive">
                                  <Table className="table-borderless mb-0">
                                    <tbody>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          Primary Email :
                                        </th>
                                        <td className="text-muted">California, United States</td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          Mobile Number :
                                        </th>
                                        <td className="text-muted">California, United States</td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          Secondary Email :
                                        </th>
                                        <td className="text-muted">California, United States</td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          Secondary Number :
                                        </th>
                                        <td className="text-muted">California, United States</td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xxl={4}>
                            <Card className="card-height-100">
                              <CardBody>
                                <h5 className="card-title mb-3">Address</h5>
                                <div className="table-responsive">
                                  <Table className="table-borderless mb-0">
                                    <tbody>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          Address Line :
                                        </th>
                                        <td className="text-muted">Anna Adame</td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          Street :
                                        </th>
                                        <td className="text-muted">+(1) 987 6543</td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          City :
                                        </th>
                                        <td className="text-muted">daveadame@velzon.com</td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          State :
                                        </th>
                                        <td className="text-muted">California, United States</td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          Country
                                        </th>
                                        <td className="text-muted">24 Nov 2021</td>
                                      </tr>
                                      <tr>
                                        <th className="ps-0" scope="row">
                                          Pin Code
                                        </th>
                                        <td className="text-muted">24 Nov 2021</td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tabId="2">
                    <Card>
                      <CardBody>
                        <div className="d-flex align-items-center mb-4">
                          <h5 className="card-title flex-grow-1 mb-0">Documents</h5>
                          <div className="flex-shrink-0">
                            <Input className="form-control d-none" type="file" id="formFile" />
                            <Label htmlFor="formFile" className="btn btn-danger">
                              <i className="ri-upload-2-fill me-1 align-bottom"></i> Upload File
                            </Label>
                          </div>
                        </div>
                        <Row>
                          <Col lg={12}>
                            <div className="table-responsive">
                              <Table className="table-borderless align-middle mb-0">
                                <thead className="table-light">
                                  <tr>
                                    <th scope="col">File Name</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Size</th>
                                    <th scope="col">Upload Date</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(document || []).map((item, key) => (
                                    <tr key={key}>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <div className="avatar-sm">
                                            <div
                                              className={`avatar-title bg-soft-${item.iconBackgroundClass} text-${item.iconBackgroundClass} rounded fs-20`}
                                            >
                                              <i className={item.icon}></i>
                                            </div>
                                          </div>
                                          <div className="ms-3 flex-grow-1">
                                            <h6 className="fs-15 mb-0">
                                              <Link to="#">{item.fileName}</Link>
                                            </h6>
                                          </div>
                                        </div>
                                      </td>
                                      <td>{item.fileType}</td>
                                      <td>{item.fileSize}</td>
                                      <td>{item.updatedDate}</td>
                                      <td>
                                        <UncontrolledDropdown direction="start">
                                          <DropdownToggle
                                            tag="a"
                                            className="btn btn-light btn-icon"
                                            id="dropdownMenuLink15"
                                            role="button"
                                          >
                                            <i className="ri-equalizer-fill"></i>
                                          </DropdownToggle>
                                          <DropdownMenu>
                                            <DropdownItem>
                                              <i className="ri-eye-fill me-2 align-middle text-muted" />
                                              View
                                            </DropdownItem>
                                            <DropdownItem>
                                              <i className="ri-download-2-fill me-2 align-middle text-muted" />
                                              Download
                                            </DropdownItem>
                                            <DropdownItem divider />
                                            <DropdownItem>
                                              <i className="ri-delete-bin-5-line me-2 align-middle text-muted" />
                                              Delete
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                            <div className="text-center mt-3">
                              <Link to="#" className="text-success ">
                                <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                                Load more{" "}
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContactDetails;
