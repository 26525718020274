import React, { useRef, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Form, Input, Label, Row, FormFeedback, CardHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import { handleValidDate } from "../../../cases/ListView/CaseCol";
import MsgToast from "../../../../Components/Common/MsgToast";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import PreviewCardHeader from "../../../../Components/Common/PreviewCardHeader";
import { Link } from "react-router-dom";
import SectionTitle from "../../../../Components/Common/SectionTitle";
import { FilePond, registerPlugin } from "react-filepond";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";

import {
  addNewLead,
  getUsersList as onGetUsers,
  getContacts as onGetContacts,
  getTeamsList as onGetTeams,
  resetCreateLeadError,
  resetCrmFlag,
  updateLead,
  getProfile,
} from "../../../../store/actions";
import Select from "react-select";
import countries from "../../../../common/data/countries.json";
import industryOptions from "../../../../common/data/industry.json";
import sourceOptions from "../../../../common/data/leadSource.json";
import statusOptions from "../../../../common/data/leadStatus.json";
import TextField from "../../../../Components/Common/Fields/TextField.js";
import TextAreaField from "../../../../Components/Common/Fields/TextAreaField.js";
import NumberField from "../../../../Components/Common/NumberField";
import SelectField from "../../../../Components/Common/Fields/SelectField";
import MultiSelectField from "../../../../Components/Common/Fields/MultiSelectField";

import DateField from "../../../../Components/Common/Fields/DateField";

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const CreateLeadForm = (props) => {
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
  const [lead, setLead] = useState({});
  const { id } = useParams();
  const selectRefs = useRef([]);

  function handleAcceptedFiles(files) {
    let newFiles = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    return newFiles;
    // setselectedFiles(files);
  }

  let {
    isLeadsUpdate,
    isLeadsUpdateFail,
    crmusers,
    contactList,
    teamsList,
    leadList,
    loading,
    isLeadsAddFail,
    success,
    error,
    isLeadsAdd,
    isLeadCreated,
    isLeadsSuccess,
    isLeadsDelete,
  } = useSelector((state) => ({
    isLeadCreated: state.Crm.isLeadCreated,
    isLeadsSuccess: state.Crm.isLeadsSuccess,
    loading: state.Crm.loading,
    leadList: state.Crm.leadList,
    error: state.Crm.error,
    // teamsList: state.Teams.teamsList,
    // crmusers: state.Users.usersList,
    teamsList: state.Profile.teamsList,
    crmusers: state.Profile.usersList,
    contactList: state.Crm.crmcontacts,

    isLeadsAdd: state.Crm.isLeadsAdd,
    isLeadsAddFail: state.Crm.isLeadsAddFail,
    isLeadsUpdate: state.Crm.isLeadsUpdate,
    isLeadsUpdateFail: state.Crm.isLeadsUpdateFail,
  }));

  const [contactsLabels, setContactLabels] = useState([]);
  const [userLabels, setUserLabels] = useState([]);
  const [teamsLabels, setTeamsLabels] = useState([]);

  useEffect(() => {
    dispatch(onGetContacts());
    if (!crmusers || !teamsList) {
      dispatch(getProfile());
    }
  }, [dispatch, teamsList, crmusers]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetCrmFlag());
    }, 3000);
  }, [
    dispatch,
    isLeadCreated,
    error,
    isLeadsSuccess,
    isLeadsAdd,
    isLeadsAddFail,
    success,
    isLeadsUpdate,
    isLeadsUpdateFail,
  ]);

  //////////////////////////////////////////////////
  //// Setting Labels
  useEffect(() => {
    if (props.updateLead) {
      // if (leadList && leadList.open_leads?.open_leads?.length) {
      if (leadList) {
        // leadList.open_leads.open_leads.forEach((lead) => {
        let lead = leadList.find((lead) => lead.id.toString() === id);
        setLead(lead);
      }
    }

    if (contactList) {
      const contactOptions = contactList.map((c) => {
        return { label: `${c.first_name} ${c.last_name}`, value: c.id };
      });
      setContactLabels(contactOptions);
    }

    if (crmusers) {
      const userslabels = crmusers.map((user) => {
        return { label: `${user.user_details.first_name} ${user.user_details.last_name}`, value: user.id };
      });
      setUserLabels(userslabels);
    }

    if (teamsList) {
      const teamlabels = teamsList.map((team) => {
        return { label: team.name, value: team.id };
      });
      setTeamsLabels(teamlabels);
    }
  }, [id, props.updateLead, contactList, leadList, crmusers, teamsList]);

  const [assignedContactsState, setAssignedContactsState] = useState(null);
  const [assignedUserState, setAssignedUserState] = useState(null);
  const [assignTeamState, setAssignTeamState] = useState(null);
  useEffect(() => {
    // let assignedContactLabels =
    //   lead &&
    //   // Object.values(lead).length &&
    //   lead.contacts.length &&
    //   lead.contacts.map((contact) => {
    //     return { label: `${contact.first_name} ${contact.last_name}`, value: contact.id };
    //   });
    // setAssignedContactsState(assignedContactLabels);

    let assignedUsersLabels = null;
    if (lead && lead.assigned_to && lead.assigned_to.length) {
      assignedUsersLabels = lead.assigned_to.map((user) => {
        return { label: `${user.user_details.first_name} ${user.user_details.last_name}`, value: user.id };
      });
      setAssignedUserState(assignedUsersLabels);
    }

    let assignedTeamLabel = null;
    if (lead && lead.teams && lead.teams.length) {
      assignedTeamLabel = lead.teams.map((team) => {
        return { label: `${team.name}`, value: team.id };
      });
      setAssignTeamState(assignedTeamLabel);
    }
  }, [lead]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // org: lead && lead.org ? lead.org.toString() : "",
      id: (lead && lead.id) || "",
      salutation: (lead && lead.salutation) || "",
      title: (lead && lead.title) || "",
      first_name: (lead && lead.first_name) || "",
      last_name: (lead && lead.last_name) || "",
      email: (lead && lead.email) || "",
      mobile: (lead && lead.phone) || "",
      telephone: (lead && lead.telephone) || "",
      fax: (lead && lead.fax) || "",
      status: (lead && lead.status) || "",
      source: (lead && lead.source) || "",
      address_line: (lead && lead.address_line) || "",
      street: (lead && lead.street) || "",
      city: (lead && lead.city) || "",
      state: (lead && lead.state) || "",
      postcode: (lead && lead.postcode) || "",
      country: (lead && lead.country) || "",
      website: (lead && lead.website) || "",
      organization_name: (lead && lead.organization_name) || "",
      annual_revenue: (lead && lead.annual_revenue) || "",
      created_on: (lead && lead.created_on) || "",
      tags: (lead && lead.tags) || "",
      industry: (lead && lead.industry) || "",
      description: (lead && lead.description) || "",
      teams: (lead && lead.teams && JSON.stringify(lead?.teams?.map((e) => e.id))) || [],
      assigned_to: (lead && JSON.stringify(lead?.assigned_to?.map((e) => e.id))) || [],
      // enquiry_type: (lead && lead.enquiry_type) || "",
      // tags: (lead && lead.tags) || "",

      // company: lead && lead.company ? lead.company.toString() : "",
      // organization: (lead && lead.organization) || "",
      // probability: (lead && lead.probability) || "",
      // message: (lead && lead.message) || "",
      // close_date: (lead && lead.close_date) || "",
      // contacts: (lead && lead.contacts) || "",
      // contacts: (lead && lead.contacts && JSON.stringify(lead?.contacts?.map((e) => e.id))) || [],

      // teams: (lead && lead.teams) || "",

      // assigned_to: (assignedUserLabelState) || "",
    },

    validationSchema: Yup.object({
      salutation: Yup.string().notRequired("Please Enter Salutation"),
      title: Yup.string().required("Please Enter title"),
      first_name: Yup.string().notRequired("Please Enter Your first name"),
      last_name: Yup.string().required("Please Enter Your last name"),
      email: Yup.string().email("Please enter a valid email").notRequired("Please Enter Your email"),
      mobile: Yup.string().notRequired("Please Enter Your mobile number"),
      telephone: Yup.string().notRequired("Please Enter Your telephone number"),
      fax: Yup.string().notRequired("Please Enter Your fax number"),
      status: Yup.string().required("Please Enter Your status"),
      source: Yup.string().notRequired("Please Enter the source"),
      address_line: Yup.string().notRequired("Please Enter address_line"),
      street: Yup.string().notRequired("Please Enter Your street"),
      city: Yup.string().notRequired("Please Enter Your city"),
      state: Yup.string().notRequired("Please Enter Your state"),
      postcode: Yup.string().notRequired("Please Enter Your postcode"),
      country: Yup.string().notRequired("Please Enter Your country"),
      website: Yup.string().notRequired("Please Enter Your website"),
      organization_name: Yup.string().notRequired("Please Enter Your organization name"),
      annual_revenue: Yup.string().notRequired("Please Enter Annual Revenue"),
      tags: Yup.mixed().notRequired("Please Enter tags"),
      description: Yup.string().notRequired("Please Enter description"),
      industry: Yup.string().notRequired("Please Enter Your industry"),
      teams: Yup.mixed().notRequired("Please Enter Your teams"),
      assigned_to: Yup.mixed().notRequired("Please Enter Your assigned_to"),
      lead_attachment: Yup.string().notRequired(""),
      // postCode: Yup.string().required("Please Enter Your postCode"),
      // contacts: Yup.mixed().notRequired("Please Enter Your contacts"),
      // close_date: Yup.string().required("Please Enter The Closed Date"),
      // message: Yup.string().notRequired("Please Enter Your message"),
      // org: Yup.string().notRequired("Please Enter Your Org"),
      // enquiry_type: Yup.string().notRequired("Please Enter enquiry"),
      // created_on: Yup.string().required("Please Enter Your country"),

      // company: Yup.string().notRequired("Please Enter Your company"),
      // organization: Yup.string().required("Please Enter Your organization"),
      // probability: Yup.string().required("Please Enter Your probability"),
    }),

    onSubmit: (values) => {
      //
      // const formData = new FormData();

      // for (let v in values) {
      //   formData.set(v, values[v]);
      // }
      // // let newFiles = handleAcceptedFiles(files);
      // if (files && files.length) {
      //   formData.set("lead_attachment", files[0].file);
      // }

      // dispatch a add case

      //

      if (props.updateLead || id) {
        dispatch(updateLead(values));
      } else {
        dispatch(addNewLead(values));
      }

      reset();
    },
  });

  const reset = () => {
    setLead({});
    setAssignedUserState(null);
    setAssignedContactsState(null);
    setAssignTeamState(null);
    validation.resetForm();
  };

  const handleAssignObject = (labels, field, setStateCallback) => {
    const assigned = labels.map((item) => item.value);
    const stringified = JSON.stringify(assigned);
    validation.setFieldValue(field, stringified);
    setStateCallback(labels);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Create Lead" pageTitle="Leads " />

        <Row>
          <Col lg={8}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();

                return false;
              }}
              // onReset={(e) => {
              //   e.preventDefault();
              //   reset();
              // }}
            >
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Personal Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.salutation || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter Lead Salutation"
                      label="Salutation"
                      name="salutation"
                      errorMessage={error.errors}
                    />

                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.organization_name || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter Organization Name"
                      label="Organization Name"
                      name="organization_name"
                    />
                  </Row>

                  <Row>
                    <TextField
                      md="6"
                      mb={"mb-3"}
                      value={validation.values.first_name || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter First Name"
                      label="First name"
                      name="first_name"
                    />

                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.last_name || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Last Name"
                      label="Last name"
                      name="last_name"
                    />
                  </Row>

                  <Row>
                    <TextField
                      mb={"mb-3"}
                      md="6"
                      value={validation.values.mobile || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter Mobile Number"
                      label="Mobile"
                      name="mobile"
                    />

                    <TextField
                      mb={"mb-3"}
                      md="6"
                      value={validation.values.telephone || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter Telephone Number"
                      label="Telephone"
                      name="telephone"
                    />
                  </Row>

                  <Row>
                    <TextField
                      mb={"mb-3"}
                      md="6"
                      value={validation.values.fax || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter Fax Number"
                      label="Fax"
                      name="fax"
                    />

                    <TextField
                      mb={"mb-3"}
                      md="6"
                      value={validation.values.email || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter email.."
                      label="Email"
                      name="email"
                    />
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Lead Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <TextField
                        // md="6"
                        mb="mb-3"
                        value={validation.values.title || ""}
                        onChange={validation.handleChange}
                        validation={validation}
                        onBlur={validation.handleBlur}
                        placeholder="Enter Lead Title"
                        label="Title"
                        name="title"
                        errorMessage={error.errors}
                      />
                    </Col>

                    <Col md="6">
                      <TextField
                        mb="mb-3"
                        // md="8"
                        value={validation.values.website || ""}
                        onChange={validation.handleChange}
                        validation={validation}
                        onBlur={validation.handleBlur}
                        placeholder="Enter Website"
                        label="Website"
                        name="website"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="gender-input">
                          Source
                        </Label>
                        <Select
                          name="source"
                          onChange={(e) => validation.setFieldValue("source", e.value)}
                          options={sourceOptions}
                          placeholder="select a source..."
                          id="source-input"
                          value={sourceOptions.find((g) => g.value === validation.values.source)}
                          invalid={validation.touched.source && validation.errors.source ? true : false}
                          classNamePrefix="select2-selection form-select"
                          ref={(s) => (selectRefs.current[2] = s)}
                        />
                        {validation.touched.source && validation.errors.source ? (
                          <FormFeedback type="invalid">{validation.errors.source}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col md="6">
                      <TextField
                        mb="mb-3"
                        // md="8"
                        value={validation.values.annual_revenue || ""}
                        onChange={validation.handleChange}
                        validation={validation}
                        onBlur={validation.handleBlur}
                        label="Annual Revenue"
                        placeholder="Enter Annual Revenue"
                        name="annual_revenue"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="gender-input">
                          Status
                        </Label>
                        <Select
                          name="status"
                          onChange={(e) => validation.setFieldValue("status", e.value)}
                          options={statusOptions}
                          placeholder="select a status..."
                          id="status-input"
                          value={statusOptions.find((g) => g.value === validation.values.status)}
                          invalid={validation.touched.status && validation.errors.status ? true : false}
                          classNamePrefix="select2-selection form-select"
                          ref={(s) => (selectRefs.current[3] = s)}
                        />
                        {validation.touched.status && validation.errors.status ? (
                          <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="gender-input">
                          Industry
                        </Label>
                        <Select
                          name="industry"
                          onChange={(e) => validation.setFieldValue("industry", e.value)}
                          options={industryOptions}
                          placeholder="select an industry..."
                          id="industry-input"
                          value={industryOptions.find((g) => g.value === validation.values.industry)}
                          invalid={validation.touched.industry && validation.errors.industry ? true : false}
                          classNamePrefix="select2-selection form-select"
                          ref={(s) => (selectRefs.current[1] = s)}
                        />
                        {validation.touched.industry && validation.errors.industry ? (
                          <FormFeedback type="invalid">{validation.errors.industry}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    {/* <Col md="6"> */}
                    {/* </Col> */}
                  </Row>
                  <TextAreaField
                    mb={"mb-3"}
                    // md="8"
                    value={validation.values.description || ""}
                    onChange={validation.handleChange}
                    validation={validation}
                    onBlur={validation.handleBlur}
                    placeholder="Enter Description"
                    label="Description"
                    name="description"
                  />
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Address Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.address_line || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter Address Line"
                      label="Address"
                      name="address_line"
                    />
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.street || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter Street Name"
                      label="Street"
                      name="street"
                    />
                  </Row>

                  <Row>
                    <Col>
                      <div className="mb-3 md-6">
                        <Label htmlFor="choices-lead-input" className="form-label">
                          Country
                        </Label>
                        <Select
                          className={`${
                            validation.touched.country && validation.errors.country ? "form-control is-invalid" : ""
                          } `}
                          id="choices-team-input"
                          defaultValue={lead && countries.find((c) => c.value === lead.country)}
                          key={lead && lead.country}
                          name="country"
                          // onChange={(e) => console.log(e)}
                          onChange={(e) => validation.setFieldValue("country", e.value)}
                          options={countries}
                          ref={(s) => (selectRefs.current[4] = s)}
                        />
                        {validation.touched.country && validation.errors.country ? (
                          <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.city || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Select A City"
                      label="City"
                      name="city"
                    />
                  </Row>

                  <Row>
                    <TextField
                      mb="mb-3"
                      md="6"
                      value={validation.values.state || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Select State"
                      label="State"
                      name="state"
                    />

                    <TextField
                      mb="mb-3"
                      md="6"
                      value={validation.values.postcode || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter Post Code"
                      label="Post Code"
                      name="postcode"
                    />
                  </Row>
                </CardBody>
              </Card>

              <div className="text-end mb-3">
                <button type="reset" className="btn btn-danger w-sm me-1">
                  Clear
                </button>
                <button type="submit" className="btn btn-success w-sm">
                  Submit
                </button>
              </div>
            </Form>
          </Col>

          <Col lg={4}>
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Tags</h5>
              </CardHeader>
              <CardBody>
                <div className="hstack gap-3 align-items-start">
                  <div className="flex-grow-1">
                    <input className="form-control" placeholder="Enter tags" type="text" />
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Assigned to</h5>
              </CardHeader>
              <CardBody>
                <div className="mb-3">
                  <Label htmlFor="assigned_to-input" className="form-label">
                    To a User(s)
                  </Label>
                  <Select
                    id="assinged_to-input"
                    placeholder="Select a User..."
                    value={assignedUserState}
                    key={assignedUserState}
                    onChange={(e) => {
                      handleAssignObject(e, "assigned_to", setAssignedUserState);
                    }}
                    isMulti
                    ref={(s) => (selectRefs.current[5] = s)}
                    options={userLabels}
                    name="assigned_to"
                    classNamePrefix="select2-selection form-select"
                  />
                </div>

                <div className="mb-3">
                  <Label className="form-label" htmlFor="gender-input">
                    Teams
                  </Label>
                  <Select
                    value={assignTeamState}
                    key={assignTeamState}
                    onChange={(teams) => {
                      handleAssignObject(teams, "teams", setAssignTeamState);
                    }}
                    onBlur={validation.handleBlur}
                    label="teams"
                    placeholder="Select Lead teams..."
                    name="teams"
                    ref={(s) => (selectRefs.current[6] = s)}
                    options={teamsLabels}
                    isMulti={true}
                    classNamePrefix="select2-selection form-select"
                  />
                </div>
              </CardBody>
            </Card>

            <Card className="">
              <CardHeader>
                <h5 className="card-title mb-0"> Lead Attachment</h5>
              </CardHeader>
              <CardBody>
                <div>
                  <p className="text-muted">Add Attached files here.</p>
                  <FilePond
                    files={files}
                    onupdatefiles={setFiles}
                    allowMultiple={true}
                    maxFiles={1}
                    name="lead_attachment"
                    // value={validation.values.case_attachment}
                    className="filepond filepond-input-multiple"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {isLeadCreated ? (
          <MsgToast msg="Lead Added Successfully" color="success" icon="ri-checkbox-circle-line" />
        ) : null}
        {isLeadsAddFail ? (
          <MsgToast
            msg={
              error.error &&
              error.errors &&
              error.errors.account_name &&
              error.errors.account_name.length &&
              error.errors.account_name[0]
            }
            color="danger"
            icon="ri-error-warning-line"
          />
        ) : null}
        {error.error && error.errors && error.errors.title && error.errors.title.length ? (
          <MsgToast
            msg={
              error.error && error.errors && error.errors.title && error.errors.title.length && error.errors.title[0]
            }
            color="danger"
            icon="ri-error-warning-line"
          />
        ) : null}

        {isLeadsUpdate ? (
          <MsgToast msg="Lead Updated Successfully" color="success" icon="ri-checkbox-circle-line" />
        ) : null}
        {error.error && error.errors && error.errors.phone && error.errors.phone.length ? (
          <MsgToast msg={error.errors.phone[0]} color="danger" icon="ri-error-warning-line" />
        ) : null}
        {validation.isSubmitting && Object.keys(validation.errors).length > 0 ? (
          <MsgToast msg={"You forgot some required fields"} color="danger" icon="ri-error-warning-line" />
        ) : null}
      </Container>
    </div>
  );
};

export default CreateLeadForm;
