import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { createReport, deleteReport, runReports, updateReport, getReportsList } from "../../helpers/backend_helper";
import {
  createReportError,
  createReportSuccess,
  deleteReportError,
  deleteReportSuccess,
  getReportListError,
  getReportListSuccess,
  runReportError,
  runReportSuccess,
  updateReportError,
  updateReportSuccess,
} from "./action";
import { CREATE_REPORT, DELETE_REPORT, GET_REPORTS_LIST, RUN_REPORTS, UPDATE_REPORT } from "./actionType";

function* runReportWorker({ payload }) {
  try {
    const response = yield call(runReports, payload);
    yield put(runReportSuccess(response));
  } catch (error) {
    yield put(runReportError(error));
  }
}

function* createReportWorker({ payload }) {
  try {
    const response = yield call(createReport, payload);
    yield put(createReportSuccess(response));
  } catch (error) {
    yield put(createReportError(error));
  }
}

function* updateReportWorker({ payload }) {
  try {
    const response = yield call(updateReport, payload.id, payload);
    yield put(updateReportSuccess(response));
  } catch (error) {
    yield put(updateReportError(error));
  }
}

function* getReportsListWorker({ params }) {
  try {
    const response = yield call(getReportsList, params);
    yield put(getReportListSuccess(response));
  } catch (error) {
    yield put(getReportListError(error));
  }
}

function* deleteReportWorker({ payload }) {
  try {
    const response = yield call(deleteReport, payload);
    yield put(deleteReportSuccess(response));
  } catch (error) {
    yield put(deleteReportError(error));
  }
}

export function* watchGetReportList() {
  yield takeEvery(GET_REPORTS_LIST, getReportsListWorker);
}

export function* watchRunReports() {
  yield takeEvery(RUN_REPORTS, runReportWorker);
}

export function* watchCreateReports() {
  yield takeEvery(CREATE_REPORT, createReportWorker);
}

export function* watchUpdateReports() {
  yield takeEvery(UPDATE_REPORT, updateReportWorker);
}

export function* watchDeleteReport() {
  yield takeEvery(DELETE_REPORT, deleteReportWorker);
}

function* ReportsSaga() {
  yield all([
    fork(watchRunReports),
    fork(watchUpdateReports),
    fork(watchDeleteReport),
    fork(watchGetReportList),
    fork(watchCreateReports),
  ]);
}

export default ReportsSaga;
