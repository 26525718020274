import React, { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";

import { useProfile } from "../Components/Hooks/UserHooks";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../store/actions";
import Loader from "../Components/Common/Loader";
import { Spinner } from "reactstrap";
import Offlinepage from "../apps/auth/Offlinepage";

const SubscriptionActive = (props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { loading, isActive } = useProfile();

  //const { org } = useSelector((state) => ({
  //org: state.Profile.org,
  //}));

  //useEffect(() => {
  //dispatch(getProfile());
  //}, [dispatch]);

  if (!loading && isActive === "active") {
    return <>{props.children}</>;
  } else if (!loading && isActive && isActive !== "active") {
    return <Redirect to={{ pathname: "/subscription-inactive", state: { from: pathname } }} />;
    //return <Offlinepage />;
  } else if (!isActive) {
    return <></>;
  } else {
    return <Redirect to={{ pathname: "/login", state: { from: pathname } }} />;
  }
};

export default SubscriptionActive;
