import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

// Import Images
import { debounce } from "lodash";
import VolunteerFilter from "./VolunteerFilter";

import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";

//Import actions
import {
  deleteVolunteer as onDeleteVolunteer,
  getVolunteersList as onGetVolunteers,
  resetVolunteerFlag,
} from "../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";

// Formik

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Components/Common/Loader";
import MsgToast from "../../Components/Common/MsgToast";

const VolunteersList = () => {
  const dispatch = useDispatch();
  const {
    fetchingVolunteerList,
    volunteersList,
    isVolunteerSuccess,
    isVolunteerDelete,
    isVolunteerDeleteFail,
    error,
    page,
  } = useSelector((state) => ({
    isVolunteerCreated: state.Volunteers.isVolunteersCreated,
    isVolunteerSuccess: state.Volunteers.isVolunteerSuccess,
    volunteersList: state.Volunteers.volunteersList,
    error: state.Volunteers.error,
    page: state.Volunteers.page,
    isVolunteerDelete: state.Volunteers.isVolunteerDelete,
    isVolunteerDeleteFail: state.Volunteers.isVolunteerDeleteFail,
    fetchingVolunteerList: state.Volunteers.fetchingVolunteerList,
  }));

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetVolunteerFlag());
    }, 3000);
  }, [dispatch, isVolunteerSuccess, error, isVolunteerDelete, isVolunteerDeleteFail]);

  //delete volunteer
  const [volunteer, setVolunteer] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [sortBy, setsortBy] = useState(null);
  const [toggleFilterCanvas, setToggleFilterCanvas] = useState(false);

  useEffect(() => {
    if (!volunteersList) dispatch(onGetVolunteers());
  }, [dispatch, volunteersList]);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(onGetVolunteers({ offset, limit }));
    },
    [dispatch]
  );

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return (
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="checkAll" value="option1" />
            </div>
          );
        },
      },
      {
        Header: "Full Name",
        accessor: "first_name",
        filterable: false,
        Cell: (volunteer) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {
                  volunteer.row.original.profile_pic ? (
                    <img src={volunteer.row.original.profile_pic} alt="" className="avatar-xxs rounded-circle" />
                  ) : (
                    <div className="flex-shrink-0 avatar-xs me-2">
                      <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                        {volunteer.row.original.first_name.charAt(0)}
                        {volunteer.row.original.last_name.charAt(0)}
                      </div>
                    </div>
                  )
                  // <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
                }
              </div>
              <div className="flex-grow-1 ms-2 name">
                {volunteer.row.original.first_name} {volunteer.row.original.last_name}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: false,
      },
      {
        Header: "Mobile Number",
        accessor: "phone_number",
        filterable: false,
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: (cellProps) => {
          const status = cellProps.row.original?.status;
          return (
            <React.Fragment>
              {status === "active" ? (
                <span className="badge badge-soft-success text-uppercase">{status}</span>
              ) : status === "in-active" ? (
                <span className="badge badge-soft-danger text-uppercase">{status}</span>
              ) : null}
            </React.Fragment>
          );
        },
      },
      {
        Header: "Date Of Birth",
        accessor: "date_of_birth",
      },
      {
        Header: "Actions",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-3 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link to={"/volunteer-update/" + cellProps.row.original.id} className="text-muted d-inline-block">
                  <i className="ri-edit-2-line fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item edit cursor-pointer" title="Delete">
                <div
                  onClick={() => {
                    const volunteerData = cellProps.row.original;
                    onClickDelete(volunteerData);
                  }}
                  className="text-muted d-inline-block"
                >
                  <i className="ri-delete-bin-fill fs-16"></i>
                </div>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const handlesortBy = (sortBy) => {
    setsortBy(sortBy);
  };

  const sortbyname = [
    {
      options: [
        { label: "Owner", value: "Owner" },
        { label: "Company", value: "Company" },
        { label: "Location", value: "Location" },
      ],
    },
  ];

  const toggleInfo = () => setToggleFilterCanvas(!toggleFilterCanvas);

  // Delete Data
  const handleDeleteVolunteer = () => {
    if (volunteer) {
      dispatch(onDeleteVolunteer(volunteer));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (volunteer) => {
    setVolunteer(volunteer);
    setDeleteModal(true);
  };

  const handler = useCallback(
    debounce((value) => {
      dispatch(onGetVolunteers({ search: value }));
    }, 1500),
    []
  );

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    handler(value);
  };

  document.title = "Volunteers | SimplyNP";
  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteVolunteer}
          onCloseClick={() => setDeleteModal(false)}
        />
        <Container fluid>
          <BreadCrumb title="List Volunteers" pageTitle="Volunteers" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-1">
                      <Link to="/volunteer-new">
                        <button className="btn btn-info add-btn">
                          <i className="ri-add-fill me-1 align-bottom"></i> Add Volunteer
                        </button>
                      </Link>
                    </div>
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                        <button
                          className="btn btn-soft-danger"
                          // onClick="deleteMultiple()"
                        >
                          <i className="ri-delete-bin-2-line"></i>
                        </button>
                        <button type="button" className="btn btn-info" onClick={toggleInfo}>
                          <i className="ri-filter-2-line me-1 align-bottom"></i> Filters
                        </button>
                        <button className="btn btn-soft-success">Import</button>

                        <UncontrolledDropdown>
                          <DropdownToggle href="#" className="btn btn-soft-info" tag="button">
                            <i className="ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" href="#">
                              All
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Week
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Month
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Year
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col>
              <Card id="volunteerList">
                <CardHeader>
                  <Row className="g-3">
                    <Col md={4}>
                      <div className="search-box">
                        <Input
                          onChange={(e) => {
                            handleFieldSearch(e);
                          }}
                          type="text"
                          className="form-control search"
                          placeholder="Search for volunteer..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    <div className="col-md-auto ms-auto">
                      <div className="d-flex align-items-center gap-2">
                        <span className="text-muted">Sort by: </span>
                        <Select
                          className="mb-0"
                          value={sortBy}
                          onChange={() => {
                            handlesortBy();
                          }}
                          options={sortbyname}
                          id="choices-single-default"
                        ></Select>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    {volunteersList ? (
                      <TableContainer
                        columns={columns}
                        data={volunteersList || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        customFetchData={fetchData}
                        customTotalSize={page?.count}
                        customPageSize={10}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        // handleVolunteerClick={handleVolunteerClicks}
                      />
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>

                  {isVolunteerDelete ? (
                    <MsgToast msg="Volunteer Deleted Successfully" color="success" icon="ri-checkbox-circle-line" />
                  ) : null}

                  {isVolunteerDeleteFail ? <MsgToast msg={error} color="danger" icon="ri-close-circle-line" /> : null}

                  <ToastContainer limit={1} closeButton={false} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <VolunteerFilter show={toggleFilterCanvas} onCloseClick={() => setToggleFilterCanvas(false)} />
    </React.Fragment>
  );
};

export default VolunteersList;
