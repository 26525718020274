import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  PROFILE_CHANGE_PASSWORD_SUCCESS,
  PROFILE_CHANGE_PASSWORD_FAIL,
  EDIT_PROFILE_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  user: null,
};

const Profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state };
      break;

    case PROFILE_SUCCESS:
      state = {
        ...state,
        success: true,
        //user: { ...state.user, ...action.payload.user },
        user: action.payload?.data?.user,
        org: action.payload?.data?.org,
      };
      break;
    case EDIT_PROFILE_SUCCESS:
      state = {
        ...state,
        editProfileSuccess: true,
        editProfileError: false,
        //user: { ...state.user, ...action.payload.user },
        //user: action.payload.data.user,
        //org: action.payload.data.org,
      };
      break;
    case PROFILE_ERROR:
      state = {
        ...state,
        error: action.payload,
        editProfileError: true,
        editProfileSuccess: false,
      };
      break;
    case PROFILE_CHANGE_PASSWORD_SUCCESS:
      state = {
        ...state,
        passwordChangeSuccess: true,
        passwordChangeFail: null,
      };
      break;

    case PROFILE_CHANGE_PASSWORD_FAIL:
      state = {
        ...state,
        passwordChangeSuccess: false,
        passwordChangeFail: action.payload,
      };
      break;
    case RESET_PROFILE_FLAG:
      state = {
        ...state,
        success: null,
        passwordChangeSuccess: false,
        passwordChangeFail: false,
        editProfileSuccess: false,
        editProfileFail: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Profile;
