import {
  GET_VOLUNTEER,
  GET_VOLUNTEERS_LIST,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  UPDATE_VOLUNTEER,
  UPDATE_VOLUNTEER_SUCCESS,
  UPDATE_VOLUNTEER_FAIL,
  DELETE_VOLUNTEER,
  DELETE_VOLUNTEER_SUCCESS,
  DELETE_VOLUNTEER_FAIL,
  ADD_NEW_VOLUNTEER,
  ADD_NEW_VOLUNTEER_SUCCESS,
  ADD_NEW_VOLUNTEER_FAIL,
  RESET_VOLUNTEER_FLAG,
} from "./actionType";

const INIT_STATE = {
  volunteersList: null,
};

const Volunteers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_VOLUNTEERS_LIST:
          return {
            ...state,
            volunteersList: action.payload.data.volunteersList,
            page: action.payload.data.page,
            isVolunteerCreated: false,
            isVolunteerSuccess: true,
            fetchingVolunteerList: false,
            volunteerListSuccess: true,
          };
        case GET_VOLUNTEER:
          return {
            ...state,
            volunteer: action.payload.data,
            isVolunteerSuccess: true,
            isVolunteerCreated: false,
          };
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_VOLUNTEERS_LIST:
        case GET_VOLUNTEER:
          return {
            ...state,
            error: action.payload.error,
            isVolunteerCreated: false,
            isVolunteerSuccess: false,
          };
        case ADD_NEW_VOLUNTEER:
          return {
            ...state,
            error: action.payload.error,
            isVolunteerCreated: false,
            isVolunteerSuccess: false,
            isVolunteerAddFail: true,
            loading: false,
          };
        default:
          return { ...state };
      }
    case ADD_NEW_VOLUNTEER:
      return {
        ...state,
        loading: true,
      };
    case GET_VOLUNTEERS_LIST: {
      return {
        ...state,
        fetchingVolunteerList: true,
        volunteerListSuccess: false,
      };
    }

    case ADD_NEW_VOLUNTEER_SUCCESS:
      return {
        ...state,
        isVolunteerCreated: true,
        isVolunteerAdd: true,
        isCaseAddFail: false,
        loading: false,
        error: false,
      };
    case ADD_NEW_VOLUNTEER_FAIL:
      return {
        ...state,
        error: action.payload,
        isVolunteerAdd: false,
        isVolunteerAddFail: true,
        loading: false,
      };
    case UPDATE_VOLUNTEER_SUCCESS:
      return {
        ...state,
        isVolunteerUpdate: true,
        isVolunteerUpdateFail: false,
        loading: false,
      };
    case UPDATE_VOLUNTEER_FAIL:
      return {
        ...state,
        error: action.payload,
        isVolunteerUpdate: false,
        isVolunteerUpdateFail: true,
        loading: false,
      };
    case DELETE_VOLUNTEER_FAIL:
      return {
        ...state,
        error: action.payload,
        isVolunteerDelete: false,
        isVolunteerDeleteFail: true,
        loading: false,
      };
    case DELETE_VOLUNTEER_SUCCESS:
      return {
        ...state,
        volunteersList: state.volunteersList.filter(
          (volunteer) => volunteer.id.toString() !== action.payload.volunteer.toString()
        ),
        isVolunteerDelete: true,
        isVolunteerDeleteFail: false,
        loading: false,
      };
    case RESET_VOLUNTEER_FLAG:
      return {
        ...state,
        isVolunteerAdd: false,
        isVolunteerAddFail: false,
        isVolunteerCreated: false,
        isVolunteerDelete: false,
        isVolunteerDeleteFail: false,
        isVolunteerUpdate: false,
        isVolunteerUpdateFail: false,
        errors: false,
        error: false,
      };
    default:
      return { ...state };
  }
};

export default Volunteers;
