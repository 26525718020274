import { loadStripe } from "@stripe/stripe-js";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Input, Label, Modal, ModalBody, Row, Spinner } from "reactstrap";
import { getSNPStripeKeyAndProducts } from "../../../../helpers/backend_helper";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../../../store/actions";

const plans = [
  {
    id: 1,
    key: "snp_standard",
    type: "Standard",
    features: "All SimplyNP Features",
    purpose: "Good for Startups",
    planIcon: "ri-book-mark-line",
    support: "Email Support",
    Storage: "10Gb",
    User: 10,
    DonorContacts: 500,
    MembershipContacts: 500,
    Customers: 500,
    supportClass: "danger",
    supportClassSymbol: "close",
    storageClass: "danger",
    storageClassSymbol: "close",
    domainClass: "danger",
    domainClassSymbol: "close",
    ribbon: false,
    planButtonClassname: "soft-success",
    lookup_keys: ["snp_standard", "snp_standard_yearly"],
    icon: "ri-book-mark-line",
    btntxt: "Get started",
    PRICE_ID: "price_1NADsmELOzUraBEwKUiyCfnV",
  },
  {
    id: 2,
    type: "Pro",
    lookup_keys: ["snp_pro", "snp_pro_yearly"],
    key: "snp_pro",
    features: "All SimplyNP Features",
    purpose: "Good for Growing Organizations",
    planIcon: "ri-stack-line",
    support: "Email and Phone Support",
    Storage: "25Gb",
    User: 25,
    DonorContacts: 1000,
    MembershipContacts: 1000,
    supportClass: "success",
    supportClassSymbol: "checkbox",
    storageClass: "success",
    storageClassSymbol: "checkbox",
    domainClass: "success",
    domainClassSymbol: "checkbox",
    ribbon: true,
    planButtonClassname: "soft-success",
    icon: "ri-stack-line",
    btntxt: "Get started",
    PRICE_ID: "price_1NADsmELOzUraBEwpIHkM3S1",
  },
  {
    id: 3,
    key: "snp_premium",
    features: "All SimplyNP Features",
    type: "Premium",
    purpose: "Recommended for Larger Organizations",
    planIcon: "ri-medal-line",
    support: "Priority Email and Phone Support",
    Storage: "50Gb",
    User: 50,
    DonorContacts: 5000,
    MembershipContacts: 5000,
    supportClass: "success",
    supportClassSymbol: "checkbox",
    lookup_keys: ["snp_premium", "snp_premium_yearly"],
    storageClass: "danger",
    storageClassSymbol: "close",
    domainClass: "danger",
    domainClassSymbol: "close",
    ribbon: false,
    planButtonClassname: "success",
    icon: "ri-medal-line",
    btntxt: "Get started",
    PRICE_ID: "price_1NADsmELOzUraBEwJMYhcXbD",
  },
];

const AccountsPlans = ({ history }) => {
  const [showModal, setShowModal] = React.useState(false);
  const [currentPlanId, setCurrentPlanId] = useState("");
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [planType, setPlanType] = useState("monthly");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState();

  const dispatch = useDispatch();

  const [stripePromise, setStripePromise] = useState(null);

  const { org } = useSelector((state) => ({
    org: state.Profile.org,
  }));

  useEffect(() => {
    if (!org) dispatch(getProfile());
    setSelectedPlanId(org?.subscription?.lookup_key);
  }, [dispatch, org, alert]);

  useEffect(() => {
    setLoading(true);
    getSNPStripeKeyAndProducts()
      .then(({ publishable_key, products: plans }) => {
        if (publishable_key) setStripePromise(loadStripe(publishable_key));
        const products_list = plans;
        const keyNames = ["SimplyNP Premium", "Simply-NP Pro", "SimplyNP Standard"];
        const products = products_list.filter((product) => {
          return keyNames.includes(product.product_name);
        });
        //console.log("PRODUCTS: ", products);
        const prices = [];
        products.forEach((p) => p.prices.forEach((pp) => prices.push(pp)));
        //console.log("PRICES: ", prices);
        setProducts(prices);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const toggle = useCallback(() => {
    if (showModal) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  }, [showModal]);

  const goToUpgradePlans = useCallback(
    (price2, interval) => {
      if (
        products.filter((p) => p.interval === interval).find((price) => price2.lookup_keys.includes(price.lookup_key))
          ?.lookup_key === selectedPlanId
      ) {
        setAlert({
          status: true,
          title: "Sorry!",
          message: "Choose a different plan",
          class: "ri-error-warning-line ri-3xl",
          textClass: "text-primary",
        });
      } else {
        history.push(
          `/checkout/plan-update/${
            products
              .filter((p) => p.interval === interval)
              .find((price) => price2.lookup_keys.includes(price.lookup_key))?.lookup_key
          }`
        );
      }
    },
    [history, products, selectedPlanId]
  );

  const toggleModal = (planId) => {
    setSelectedPlanId(planId);

    toggle();
  };

  return (
    <div className="page-content">
      <Modal isOpen={loading} centered toggle={() => null}>
        <ModalBody>
          <div className="text-center pt-4 pb-2">
            <div className="mb-4">
              <div className="avatar-lg mx-auto my-2">
                <div className={`avatar-title bg-light text-primary display-1 rounded-circle`}>
                  <span className="d-flex align-items-center">
                    <span className={`spinner-border spinner-border-lg flex-shrink-0`} role="status">
                      <span className="visually-hidden">Loading...</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <h5>Loading</h5>
            <p className="text-muted">Loading...</p>
          </div>
        </ModalBody>
      </Modal>
      {/***/}

      <Modal
        isOpen={alert?.status}
        centered
        toggle={alert?.back_url ? () => null : () => setAlert({ ...alert, status: !alert?.status })}
      >
        <ModalBody>
          <div className="text-center pt-4 pb-2">
            <div className="mb-4">
              <div className="avatar-lg mx-auto my-2">
                <div className={`avatar-title bg-light ${alert?.textClass} display-1 rounded-circle`}>
                  <span className="d-flex align-items-center">
                    <span className={`${alert?.class} flex-shrink-0`} role="status">
                      <span className="visually-hidden">Loading...</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <h5>{alert?.title}</h5>
            <p className="text-muted">{alert?.message}</p>
            <button
              hidden={!alert?.back_url}
              className="btn btn-soft-primary mt-2"
              onClick={() => (window.location = alert?.back_url)}
            >
              Go Back
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/***/}
      <Row className="justify-content-center">
        <Row className="justify-content-center">
          <Row className="justify-content-center mt-5">
            <Col lg={5}>
              <div className="text-center mb-2 pb-2">
                <h4 className="fw-semibold fs-22">Change Plan</h4>
                <p className="text-muted mb-2 fs-15">
                  Simple pricing. No hidden fees. Advanced features for you business.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center py-3 mx-auto">
            <Col md={4} className="mx-auto">
              <div
                className="btn-group d-flex align-items-center justify-content-center "
                role="group"
                aria-label="Basic radio toggle button group"
              >
                <Input
                  type="radio"
                  className="btn-check"
                  name="btnradio"
                  id="btnradio1"
                  autoComplete="off"
                  defaultChecked
                  onChange={() => setPlanType("monthly")}
                />
                <Label className="btn btn-outline-primary" htmlFor="btnradio1">
                  Monthly
                </Label>

                <Input
                  type="radio"
                  className="btn-check"
                  name="btnradio"
                  id="btnradio2"
                  onChange={() => setPlanType("yearly")}
                  autoComplete="off"
                />
                <Label className="btn btn-outline-primary" htmlFor="btnradio2">
                  Yearly
                </Label>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col>
              {planType === "monthly" ? (
                <Row>
                  {(plans || []).map((price2, key) => {
                    return (
                      <Col lg={4} key={key}>
                        <Card className="pricing-box ribbon-box right">
                          {price2.ribbon === true ? (
                            <div className="ribbon-two ribbon-two-success">
                              <span>Popular</span>
                            </div>
                          ) : (
                            ""
                          )}
                          <CardBody className="p-4 m-2">
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1">
                                <h5 className="mb-1 fw-semibold">{price2.type}</h5>
                              </div>
                              <div className="avatar-sm">
                                <div className="avatar-title bg-light rounded-circle text-primary">
                                  <i className={"fs-20 " + price2.icon}></i>
                                </div>
                              </div>
                            </div>
                            <p className="text-muted mb-0">{price2.purpose}</p>
                            <div className="pt-4">
                              <h4>
                                {
                                  products
                                    .filter((p) => p.interval === "month")
                                    .find((price) => price2.lookup_keys.includes(price.lookup_key))?.amount_readable
                                }
                              </h4>
                            </div>
                            <hr className="my-4 text-muted" />
                            <div>
                              <ul className="list-unstyled text-muted vstack gap-3">
                                <li>
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 text-success me-1">
                                      <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                      <b>{price2.features}</b>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 text-success me-1">
                                      <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                      Upto <b>{price2.Storage}</b> Storage
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 text-success me-1">
                                      <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                      Upto <b>{price2.User}</b> Users
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 text-success me-1">
                                      <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                      Upto <b>{price2.DonorContacts}</b> Donor Contacts
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 text-success me-1">
                                      <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                      Upto <b>{price2.MembershipContacts}</b> Membership Contacts
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 text-success me-1">
                                      <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                      <b>{price2.support}</b>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <div onClick={() => toggleModal(price2.PRICE_ID)} className="mt-4" disabled={true}>
                                <Link
                                  to="#"
                                  onClick={() => {
                                    goToUpgradePlans(price2, "month");
                                  }}
                                  className={`btn btn-${price2.planButtonClassname} w-100 waves-effect waves-light`}
                                >
                                  {products
                                    .filter((p) => p.interval === "month")
                                    .find((price) => price2.lookup_keys.includes(price.lookup_key))?.lookup_key ===
                                  selectedPlanId
                                    ? "CURRENT PLAN"
                                    : price2.btntxt}
                                </Link>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              ) : planType === "yearly" ? (
                <Row>
                  {(plans || []).map((price2, key) => {
                    return (
                      <Col lg={4} key={key}>
                        <Card className="pricing-box ribbon-box right">
                          {price2.ribbon === true ? (
                            <div className="ribbon-two ribbon-two-success">
                              <span>Popular</span>
                            </div>
                          ) : (
                            ""
                          )}
                          <CardBody className="p-4 m-2">
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1">
                                <h5 className="mb-1 fw-semibold">{price2.type}</h5>
                              </div>
                              <div className="avatar-sm">
                                <div className="avatar-title bg-light rounded-circle text-primary">
                                  <i className={"fs-20 " + price2.icon}></i>
                                </div>
                              </div>
                            </div>
                            <p className="text-muted mb-0">{price2.purpose}</p>
                            <div className="pt-4">
                              <h4>
                                {
                                  products
                                    .filter((p) => p.interval === "year")
                                    .find((price) => price2.lookup_keys.includes(price.lookup_key))?.amount_readable
                                }
                              </h4>
                            </div>
                            <hr className="my-4 text-muted" />
                            <div>
                              <ul className="list-unstyled text-muted vstack gap-3">
                                <li>
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 text-success me-1">
                                      <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                      <b>{price2.features}</b>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 text-success me-1">
                                      <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                      Upto <b>{price2.Storage}</b> Storage
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 text-success me-1">
                                      <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                      Upto <b>{price2.User}</b> Users
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 text-success me-1">
                                      <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                      Upto <b>{price2.DonorContacts}</b> Donor Contacts
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 text-success me-1">
                                      <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                      Upto <b>{price2.MembershipContacts}</b> Membership Contacts
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 text-success me-1">
                                      <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                    </div>
                                    <div className="flex-grow-1">
                                      <b>{price2.support}</b>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <Link
                                to="#"
                                onClick={() => {
                                  goToUpgradePlans(price2, "year");
                                }}
                                //to={`/checkout/plan-update/${
                                //products
                                //.filter((p) => p.interval === "year")
                                //.find((price) => price2.lookup_keys.includes(price.lookup_key))?.lookup_key
                                //}`}
                                className={`btn btn-${price2.planButtonClassname} w-100 waves-effect waves-light`}
                              >
                                {products
                                  .filter((p) => p.interval === "year")
                                  .find((price) => price2.lookup_keys.includes(price.lookup_key))?.lookup_key ===
                                selectedPlanId
                                  ? "CURRENT PLAN"
                                  : price2.btntxt}
                              </Link>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              ) : null}
            </Col>
          </Row>
        </Row>
      </Row>
    </div>
  );
};

export default AccountsPlans;
