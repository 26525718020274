import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import MsgToast from "../../../Components/Common/MsgToast";
import Select from "react-select";

import List from "list.js";
// Import CreateUserForm
import CreateUserForm from "./FormComponents/CreateLeadForm";
import { getLeads, deleteLead as onDeleteLead, resetCrmFlag } from "../../../store/actions";
import { useSelector, useDispatch } from "react-redux";

import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { handleValidDate } from "../../cases/ListView/CaseCol";
import { debounce } from "lodash";

const LeadsList = () => {
  const dispatch = useDispatch();
  const [lead, setLead] = useState({});
  const [sortBy, setSortBy] = useState(null);

  const [modalList, setModalList] = useState(false);
  const [search, setSearch] = useState("");

  const toggleList = () => {
    setModalList(!modalList);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const toggleDelete = () => {
    setDeleteModal(!deleteModal);
  };

  const { leadList, isLeadsDelete, isLeadsSuccess, error } = useSelector((state) => ({
    leadList: state.Crm.leadList,
    isLeadsDelete: state.Crm.isLeadsDelete,
    isLeadsSuccess: state.Crm.isLeadsSuccess,
    error: state.Crm.error,
  }));

  const handleContactClick = () => null;
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return (
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="checkAll" value="option1" />
            </div>
          );
        },
      },
      {
        Header: "Title",
        accessor: "title",
        filterable: true,
      },
      {
        Header: "Organization",
        accessor: "organization_name",
        filterable: true,
      },
      {
        Header: "Lead Source",
        accessor: "source",
        filterable: false,
      },
      {
        Header: "Contact Name",
        accessor: "first_name",
        filterable: false,
      },
      // {
      //   Header: "Assigned To",
      //   accessor: "assigned_to",
      //   filterable: false,
      // },
      {
        Header: "Status",
        accessor: "status",
        filterable: false,
      },
      // {
      //   Header: "Tags",
      //   accessor: "tags",
      //   filterable: false,
      // },
      {
        Header: "Created On",
        filterable: true,
        accessor: "created_on",
        Cell: (cellProps) => {
          return handleValidDate(cellProps.row.original.created_on);
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Call">
                <Link to="#" className="text-muted d-inline-block">
                  <i className="ri-phone-line fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item edit" title="Message">
                <Link to="#" className="text-muted d-inline-block">
                  <i className="ri-question-answer-line fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle href="#" className="btn-soft-secondary btn-sm dropdown" tag="button">
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    {/* <Link to="/donor-contacts-details">
                      <DropdownItem
                        className="dropdown-item"
                        // href="/donor-contact-details"
                        // onClick={() => { const contactData = cellProps.row.original; setInfo(contactData); }}
                      >
                        <i className="ri-eye-fill align-bottom me-2 text-muted"></i> View
                      </DropdownItem>
                    </Link> */}
                    <Link to={`/apps-crm-leads/${cellProps.row.original.id}`}>
                      <DropdownItem
                        className="dropdown-item edit-item-btn"
                        // onClick={() => {
                        //   const contactData = cellProps.row.original;
                        //   handleContactClick(contactData);
                        // }}
                      >
                        <i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Edit
                      </DropdownItem>
                    </Link>
                    <DropdownItem
                      className="dropdown-item remove-item-btn"
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        onClickDelete(contactData);
                      }}
                    >
                      <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const handlesortBy = (sortBy) => {
    setSortBy(sortBy);
  };

  const sortbyname = [
    {
      options: [
        { label: "Owner", value: "Owner" },
        { label: "Company", value: "Company" },
        { label: "Location", value: "Location" },
      ],
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetCrmFlag());
    }, 3000);
  }, [dispatch, isLeadsDelete, isLeadsSuccess]);

  // Get Data
  useEffect(() => {
    dispatch(getLeads());
  }, [dispatch]);

  const handleDeleteLead = (item) => {
    if (lead) {
      dispatch(onDeleteLead(lead));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (lead) => {
    setLead(lead);
    setDeleteModal(true);
  };
  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(getLeads());
    },
    [dispatch]
  );

  const handler = useCallback(
    debounce((value) => {
      dispatch(getLeads({ search: value }));
      // dispatch(getNotesList({ limit: 5 }));
    }, 3000),
    []
  );

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    setSearch(value);
    handler(value);
  };
  document.title = "Leads | Velzon - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
        <BreadCrumb title="Leads" pageTitle="Leads" />

        <DeleteModal show={deleteModal} onDeleteClick={handleDeleteLead} onCloseClick={() => setDeleteModal(false)} />

        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-1">
                      <Link to="/apps-crm-leads/create-lead">
                        <button className="btn btn-info add-btn">
                          <i className="ri-add-fill me-1 align-bottom"></i> Add Lead
                        </button>
                      </Link>
                    </div>
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                        <button
                          className="btn btn-soft-danger"
                          // onClick="deleteMultiple()"
                        >
                          <i className="ri-delete-bin-2-line"></i>
                        </button>
                        <button className="btn btn-danger">
                          <i className="ri-filter-2-line me-1 align-bottom"></i> Filters
                        </button>
                        <button className="btn btn-soft-success">Import</button>

                        <UncontrolledDropdown>
                          <DropdownToggle href="#" className="btn btn-soft-info" tag="button">
                            <i className="ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" href="#">
                              All
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Week
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Month
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Year
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col xxl={12}>
              <Card>
                <CardHeader>
                  <Row className="g-3">
                    <Col md={4}>
                      <div className="search-box">
                        <Input
                          onChange={(e) => {
                            handleFieldSearch(e);
                          }}
                          type="text"
                          className="form-control search"
                          placeholder="Search for lead..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    <div className="col-md-auto ms-auto">
                      <div className="d-flex align-items-center gap-2">
                        <span className="text-muted">Sort by: </span>
                        <Select
                          className="mb-0"
                          value={sortBy}
                          onChange={() => {
                            handlesortBy();
                          }}
                          options={sortbyname}
                          id="choices-single-default"
                        ></Select>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    {isLeadsSuccess && leadList && leadList.length ? (
                      <TableContainer
                        columns={columns}
                        data={leadList || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        customPageSize={10}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3 z-50"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        customFetchData={fetchData}

                        // handleContactClick={handleContactClicks}
                      />
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>
                </CardBody>

                {isLeadsDelete ? (
                  <MsgToast msg="Lead deleted successfully" color="success" icon="ri-error-warning-line" />
                ) : null}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Add Modal */}
      <Modal
        isOpen={modalList}
        toggle={() => {
          toggleList();
        }}
        centered
      >
        <ModalHeader className="bg-light p-3">
          Add User
          <Button
            type="button"
            onClick={() => {
              setModalList(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <form>
          <ModalBody>
            <CreateUserForm />
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button type="button" className="btn btn-light" onClick={() => setModalList(false)}>
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                Add Customer
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
    </React.Fragment>
  );
};

export default LeadsList;
