import axios from "axios";
import { api } from "../config";
import { getAuthBearer } from "./jwt-token-access/auth-token-header";

// default
const instance = axios.create();
instance.defaults.baseURL = api.API_URL;
instance.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors
instance.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  async (error) => {
    return Promise.reject(error.response);
  }
);

instance.interceptors.request.use(
  (config) => {
    const { token, org: orgID } = getAuthBearer();

    if (token) config.headers["X-Snp-Auth"] = `Bearer ${token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  //instance.defaults.headers.common["Authorization"] = "Bearer " + token;
  instance.defaults.headers.common["X-Snp-Auth"] = "Bearer " + token;
  instance.defaults.headers["X-Snp-Auth"] = "Bearer " + token;
};

class EAPIClient {
  get = (url, params) => {
    let response;
    let paramKeys = [];

    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = instance.get(`${url}?${queryString}`, params);
    } else {
      response = instance.get(`${url}`, params);
    }

    return response;
  };
  create = (url, data) => {
    return instance.post(url, data);
  };
  update = (url, data) => {
    return instance.put(url, data);
  };
  delete = (url, config) => {
    return instance.delete(url, { ...config });
  };
}

export default EAPIClient;
