import {
  CLIENT_STATS,
  CLIENT_STATS_SUCCESS,
  GET_CASES_STATS,
  GET_CASES_STATS_SUCCESS,
  GET_SERVICES_STATS,
  GET_SERVICES_STATS_SUCCESS,
  GET_DONATIONS_ORG_STATS,
  GET_DONATIONS_ORG_STATS_SUCCESS,
  GET_DONATIONS_CONTACTS_STATS,
  GET_DONATIONS_CONTACTS_STATS_SUCCESS,
  GET_DONATIONS_CAMPAIGNS_STATS,
  GET_DONATIONS_CAMPAIGNS_STATS_SUCCESS,
  GET_DEMOGRAPHIC_REPORTS_GROUPBY_SUCCESS,
  GET_DEMOGRAPHIC_REPORTS_GROUPBY,
  GET_DONATIONS_OGS_BY_PATTERN_STATS,
  GET_DONATIONS_OGS_BY_PATTERN_STATS_SUCCESS,
} from "./actionType";

export const getClientStats = (params) => ({
  type: CLIENT_STATS,
  payload: { params },
});

export const getCasesStats = (params) => ({
  type: GET_CASES_STATS,
  payload: { params },
});

export const getClientStatsSuccess = (data) => ({
  type: CLIENT_STATS_SUCCESS,
  payload: data,
});

export const getCasesStatsSuccess = (data) => ({
  type: GET_CASES_STATS_SUCCESS,
  payload: { data },
});

export const getServicesStats = (params) => ({
  type: GET_SERVICES_STATS,
  payload: { params },
});

export const getServicesStatsSuccess = (data) => ({
  type: GET_SERVICES_STATS_SUCCESS,
  payload: data,
});

export const getDonationsOrgStats = (params) => ({
  type: GET_DONATIONS_ORG_STATS,
  payload: { params },
});

export const getDonationsOrgStatsSuccess = (data) => ({
  type: GET_DONATIONS_ORG_STATS_SUCCESS,
  payload: data,
});

export const getDonationsContactsStats = (params) => ({
  type: GET_DONATIONS_CONTACTS_STATS,
  payload: { params },
});

export const getDonationsContactsStatsSuccess = (data) => ({
  type: GET_DONATIONS_CONTACTS_STATS_SUCCESS,
  payload: data,
});

export const getDonationsCampaignsStats = (params) => ({
  type: GET_DONATIONS_CAMPAIGNS_STATS,
  payload: { params },
});

export const getDonationsCampaignsStatsSuccess = (data) => ({
  type: GET_DONATIONS_CAMPAIGNS_STATS_SUCCESS,
  payload: data,
});

export const getDemographicsReportsGroupBy = (params) => ({
  type: GET_DEMOGRAPHIC_REPORTS_GROUPBY,
  payload: { params },
});

export const getDemographicsReportsGroupBySuccess = (data) => ({
  type: GET_DEMOGRAPHIC_REPORTS_GROUPBY_SUCCESS,
  payload: data,
});

export const getDonationOrgsByPattern = (params) => ({
  type: GET_DONATIONS_OGS_BY_PATTERN_STATS,
  payload: { params },
});

export const getDonationOrgsByPatternSuccess = (data) => ({
  type: GET_DONATIONS_OGS_BY_PATTERN_STATS_SUCCESS,
  payload: data,
});
