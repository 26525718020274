import {
  GET_DONATION,
  GET_DONATIONS_LIST,
  RESET_DONATION_FLAG,
  CREATE_DONATION,
  CREATE_DONATION_SUCCESS,
  DONATION_ERROR,
  DELETE_DONATION,
  DELETE_DONATION_SUCCESS,
  DONATION_LIST_SUCCESS,
  GET_DONATION_SUCCESS,
  UPDATE_DONATION,
  UPDATE_DONATION_SUCCESS,
} from "./actionType";

export const getDonation = (client, done) => ({
  type: GET_DONATION,
  payload: client,
  done: done,
});

export const getDonationSuccess = (data) => ({
  type: GET_DONATION_SUCCESS,
  payload: data,
});

export const getDonationsList = (data) => ({
  type: GET_DONATIONS_LIST,
  payload: data,
});

export const createDonation = (data) => ({
  type: CREATE_DONATION,
  payload: data,
});

export const updateDonation = (data) => ({
  type: UPDATE_DONATION,
  payload: data,
});
export const updateDonationSuccess = (data) => ({
  type: UPDATE_DONATION_SUCCESS,
  payload: data,
});

export const createDonationSuccess = (data) => ({
  type: CREATE_DONATION_SUCCESS,
  payload: data,
});

export const getDonationsListsuccess = (data) => ({
  type: DONATION_LIST_SUCCESS,
  payload: data,
});

export const deleteDonation = (data) => ({
  type: DELETE_DONATION,
  payload: data,
});
export const deleteDonationSuccess = (id) => ({
  type: DELETE_DONATION_SUCCESS,
  payload: id,
});

export const donationError = (error) => ({
  type: DONATION_ERROR,
  payload: error,
});

export const resetDonationFlag = () => ({
  type: RESET_DONATION_FLAG,
});

export const getDonationsSuccess = (data) => ({
  type: DONATION_LIST_SUCCESS,
  payload: data,
});
