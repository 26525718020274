import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import Select from "react-select";
// Import FilePond styles
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";

import { ToastContainer } from "react-toastify";
import MsgToast from "../../../../Components/Common/MsgToast";

import TextField from "../../../../Components/Common/Fields/TextField.js";

import { approvedOptions, statusOptions, yesOrNoOptions } from "../../../../common/data/common";
import { getMembershipSiteConfig, updateMembershipSiteConfig, resetMembershipFlag } from "../../../../store/actions";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const MembershipSiteConfig = () => {
  const dispatch = useDispatch();

  const {
    error,
    loading,
    siteconfig,
    isSiteConfigSuccess,
    isSiteConfigUpdate,
    isSiteConfigUpdateFail,
    isSiteConfigUpdateSuccess,
  } = useSelector((state) => ({
    error: state.Membership.error,
    loading: state.Membership.loading,
    siteconfig: state.Membership.siteconfig,
    isSiteConfigSuccess: state.Membership.isSiteConfigSuccess,
    isSiteConfigUpdate: state.Membership.isSiteConfigUpdate,
    isSiteConfigUpdateFail: state.Membership.isSiteConfigUpdateFail,
    isSiteConfigUpdateSuccess: state.Membership.isSiteConfigUpdateSuccess,
  }));

  const [membership_errors, setMembershipSiteErrors] = useState(null);
  const [banner, setBanner] = useState([]);
  const [brandLogo, setBrandLogo] = useState([]);

  useEffect(() => {
    if (error) {
      setMembershipSiteErrors(error?.errors?.membership_errors);
    }

    setTimeout(() => {
      dispatch(resetMembershipFlag());
    }, 3000);
  }, [error, dispatch, siteconfig, isSiteConfigUpdate, isSiteConfigUpdateFail, isSiteConfigUpdateSuccess]);

  useEffect(() => {
    if (!siteconfig) dispatch(getMembershipSiteConfig());
  }, [dispatch, siteconfig]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: siteconfig?.id || "",

      banner: siteconfig?.banner || "",
      brand_logo: siteconfig?.brand_logo || "",
      site_big_text: siteconfig?.site_big_text || "",
      site_small_text: siteconfig?.site_small_text || "",

      show_groups: siteconfig?.show_groups || false,
      show_events: siteconfig?.show_events || false,
      show_members: siteconfig?.show_members || false,
      show_forums: siteconfig?.show_forums || false,
      show_news: siteconfig?.show_news || false,

      description: siteconfig?.description || "",
    },

    validationSchema: Yup.object({
      banner: Yup.mixed(),
      brand_logo: Yup.mixed(),
      site_small_text: Yup.string().max(255),
      site_big_text: Yup.string().max(1000),
      show_groups: Yup.boolean(),
      show_events: Yup.boolean(),
      show_members: Yup.boolean(),
      show_forums: Yup.boolean(),
      show_news: Yup.boolean(),
    }),

    onSubmit: (values) => {
      const siteFormData = new FormData();

      for (let v in values) {
        if (v === "banner" || v === "logo" || v === "brand_logo") continue;
        siteFormData.set(v, values[v]);
      }
      if (banner[0]) siteFormData.set("banner", banner[0]?.file);
      if (brandLogo[0]) siteFormData.set("brand_logo", brandLogo[0]?.file);

      dispatch(updateMembershipSiteConfig(siteFormData));

      setMembershipSiteErrors(null);
      validation.handleReset();
    },
  });

  document.title = "Configure Membership Website - Membership Management | SimplyNP";

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Membership Site" pageTitle="Memberships " />
        <Form
          id="membership-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row mb={3}>
            <Col lg={8}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Membership Site</h5>
                </CardHeader>
                <CardBody>
                  <Row className="mb-3">
                    <TextField
                      mb="mb-3"
                      value={validation.values.site_small_text}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter last name"
                      label="Membership Site Short Text"
                      name="site_small_text"
                      errorMessage={null}
                    />
                  </Row>

                  <Row className="mb-3">
                    <div>
                      <Label>Membership Big Text</Label>
                      <Input
                        type="textarea"
                        className="form-control"
                        name="site_big_text"
                        value={validation.values.site_big_text}
                        onChange={validation.handleChange}
                        invalid={validation.touched.site_big_text && validation.errors.site_big_text ? true : false}
                        rows="10"
                      />
                      {validation.touched.site_big_text && validation.errors.site_big_text ? (
                        <FormFeedback type="invalid">{validation.errors.site_big_text}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Site Banner</h5>
                </CardHeader>
                <CardBody>
                  <div>
                    <p className="text-muted">Add Site Banner Here</p>
                    <FilePond
                      files={banner}
                      onupdatefiles={setBanner}
                      acceptedFileTypes={["image/jpg", "image/jpeg", "image/png", "image/bmp"]}
                      allowMultiple={false}
                      maxFiles={1}
                      name="banner"
                      allowDrop
                      server={{
                        load: `http://localhost:8000${siteconfig?.banner}`,
                      }}
                      // value={validation.values.case_attachment}
                      className="filepond filepond-input-single"
                    />
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Site/Brand Logo</h5>
                </CardHeader>
                <CardBody>
                  <div>
                    <p className="text-muted">Add Site Logo Here</p>
                    <FilePond
                      files={brandLogo}
                      onupdatefiles={setBrandLogo}
                      allowMultiple={false}
                      acceptedFileTypes={["image/jpg", "image/jpeg", "image/png", "image/bmp"]}
                      server={{
                        load: `http://localhost:8000${siteconfig?.brand_logo}`,
                      }}
                      maxFiles={1}
                      name="logo"
                      allowDrop
                      // value={validation.values.case_attachment}
                      className="filepond filepond-input-single"
                    />
                  </div>
                </CardBody>
              </Card>

              {/*
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Other Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div>
                      <Label>Membership Description</Label>
                      <Input
                        type="textarea"
                        className="form-control"
                        name="description"
                        value={validation.values.description}
                        onChange={validation.handleChange}
                        invalid={validation.touched.description && validation.errors.description ? true : false}
                        rows="10"
                      />
                      {validation.touched.description && validation.errors.description ? (
                        <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                </CardBody>
              </Card>
            */}
            </Col>

            <Col lg={4}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Assign</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label htmlFor="show_members-input" className="form-label">
                      Show Members
                    </Label>
                    <Select
                      id="show_members-input"
                      placeholder="Show Members..."
                      name="show_members"
                      isClearable
                      options={yesOrNoOptions}
                      defaultValue={yesOrNoOptions[1]}
                      value={yesOrNoOptions.find((s) => s.value === validation.values.show_members)}
                      onChange={(e) => {
                        validation.setFieldValue("show_members", e?.value);
                      }}
                      classNamePrefix="select2-selection form-select"
                    />
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="show_events-input" className="form-label">
                      Show Events
                    </Label>
                    <Select
                      id="show_events-input"
                      placeholder="Show Events..."
                      name="show_events"
                      isClearable
                      options={yesOrNoOptions}
                      defaultValue={yesOrNoOptions[1]}
                      value={yesOrNoOptions.find((s) => s.value === validation.values.show_events)}
                      onChange={(e) => {
                        validation.setFieldValue("show_events", e?.value);
                      }}
                      classNamePrefix="select2-selection form-select"
                    />
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="show_groups-input" className="form-label">
                      Show Groups
                    </Label>
                    <Select
                      id="show_groups-input"
                      placeholder="Show Groups..."
                      name="show_groups"
                      isClearable
                      options={yesOrNoOptions}
                      defaultValue={yesOrNoOptions[1]}
                      value={yesOrNoOptions.find((s) => s.value === validation.values.show_groups)}
                      onChange={(e) => {
                        validation.setFieldValue("show_groups", e?.value);
                      }}
                      classNamePrefix="select2-selection form-select"
                    />
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="show_forums-input" className="form-label">
                      Show Forums
                    </Label>
                    <Select
                      id="show_forums-input"
                      placeholder="Show Forums..."
                      name="show_forums"
                      isClearable
                      options={yesOrNoOptions}
                      defaultValue={yesOrNoOptions[1]}
                      value={yesOrNoOptions.find((s) => s.value === validation.values.show_forums)}
                      onChange={(e) => {
                        validation.setFieldValue("show_forums", e?.value);
                      }}
                      classNamePrefix="select2-selection form-select"
                    />
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="show_news-input" className="form-label">
                      Show Forums
                    </Label>
                    <Select
                      id="show_news-input"
                      placeholder="Show News..."
                      name="show_news"
                      isClearable
                      options={yesOrNoOptions}
                      defaultValue={yesOrNoOptions[1]}
                      value={yesOrNoOptions.find((s) => s.value === validation.values.show_news)}
                      onChange={(e) => {
                        validation.setFieldValue("show_news", e?.value);
                      }}
                      classNamePrefix="select2-selection form-select"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={8}>
              <div className="d-flex">
                <div className="mb-3 text-end flex-1">
                  <button type="reset" className="btn btn-danger w-sm me-1">
                    Clear
                  </button>
                  <button type="submit" className="btn btn-success w-sm">
                    {loading ? "Saving..." : "Save"}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Form>

        {isSiteConfigUpdateSuccess && (
          <MsgToast msg="Site Configuration Updated successfully" color="success" icon="ri-checkbox-circle-line" />
        )}

        {isSiteConfigUpdateFail && (
          <MsgToast msg="Site Configuration Update failed" color="danger" icon="ri-error-warning-line" />
        )}

        <ToastContainer limit={1} closeButton={false} />
      </Container>
    </div>
  );
};

export default MembershipSiteConfig;
