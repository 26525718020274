import React from "react";
import { Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import NotesData from "./NotesData";

const ListView = () => {
  document.title = "Notes | SimplyNP - Notes Management";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Notes List" pageTitle="Notes" />
          <NotesData />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ListView;
