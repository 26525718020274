import React, { useEffect, useRef, useState } from "react";
import { Button, Input, DropdownMenu, Dropdown, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import FeatherIcon from "feather-icons-react";
import PersonalInfo from "../PersonalInfo";

import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import "react-perfect-scrollbar/dist/css/styles.css";
import MessagesList from "./../Components/MessagesList";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../../store/actions";

const ChatInbox = ({
  receiver,
  chat,
  chats,
  receiverId,
  chatSocket,
  currentRoomId,
  currentRoomMessages,
  Chat_Box_Username,
}) => {
  const dispatch = useDispatch();

  const [isInfoDetails, setIsInfoDetails] = useState(false);
  const [Chat_Box_Image, setChat_Box_Image] = useState(avatar2);
  const [curMessage, setcurMessage] = useState("");
  const [search_Menu, setsearch_Menu] = useState(false);
  const [settings_Menu, setsettings_Menu] = useState(false);

  const [currentUser, setCurrentUser] = useState();
  const [currentUserId, setCurrentUserId] = useState();

  const { authUser } = useSelector((state) => ({
    authUser: state.Profile.user,
  }));

  //Toggle Chat Box Menus
  const toggleSearch = () => {
    setsearch_Menu(!search_Menu);
  };

  //Info details offcanvas
  const toggleInfo = () => {
    setIsInfoDetails(!isInfoDetails);
  };

  useEffect(() => {
    if (!authUser) dispatch(getProfile());
    const obj = authUser;
    if (obj) {
      let name = obj.user_details.first_name;

      setCurrentUser(name);
      setCurrentUserId(+obj.user_details.id);
    }
  }, [dispatch, authUser]);

  const addMessage = (roomId, sender) => {
    if (!roomId || !currentUserId || !receiverId) return;

    const message = {
      id: Math.floor(Math.random() * 100),
      room_id: roomId,
      sender_name: currentUser,
      receiverId: "chat_" + receiverId,
      currentSender: currentUser,
      message: curMessage,
      createdAt: new Date(),
      type: "new_message",
      sender: currentUserId,
      reciever: receiverId,
    };
    setcurMessage("");
    chatSocket.send(JSON.stringify(message));
    scrollToBottom();
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(scrollToBottom, [currentRoomMessages, chats]);

  const onKeyPress = (e) => {
    const { key, value } = e;
    if (key === "Enter") {
      e.preventDefault();

      setcurMessage(value);
      addMessage(currentRoomId, currentUser);
    }
  };

  return (
    <div className="user-chat w-100 overflow-hidden">
      <div className="chat-content d-lg-flex">
        <div className="w-100 overflow-hidden position-relative">
          <div className="position-relative">
            <div className="p-3 user-chat-topbar">
              <Row className="align-items-center">
                <Col sm={4} xs={8}>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 d-block d-lg-none me-3">
                      <Link to="#" className="user-chat-remove fs-18 p-1">
                        <i className="ri-arrow-left-s-line align-bottom"></i>
                      </Link>
                    </div>
                    <div className="flex-grow-1 overflow-hidden">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                          <span className="user-status"></span>
                        </div>
                        <div className="flex-grow-1 overflow-hidden">
                          <h5 className="text-truncate mb-0 fs-16">
                            <a
                              className="text-reset username"
                              data-bs-toggle="offcanvas"
                              href="#userProfileCanvasExample"
                              aria-controls="userProfileCanvasExample"
                            >
                              {Chat_Box_Username}
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={8} xs={4}>
                  <ul className="list-inline user-chat-nav text-end mb-0">
                    <li className="list-inline-item m-0">
                      <Dropdown isOpen={search_Menu} toggle={toggleSearch}>
                        <DropdownMenu className="p-0 dropdown-menu-end dropdown-menu-lg">
                          <div className="p-2">
                            <div className="search-box">
                              <Input
                                type="text"
                                className="form-control bg-light border-light"
                                placeholder="Search here..."
                                id="searchMessage"
                              />
                              <i className="ri-search-2-line search-icon"></i>
                            </div>
                          </div>
                        </DropdownMenu>
                      </Dropdown>
                    </li>

                    <li className="list-inline-item d-none d-lg-inline-block m-0">
                      <button type="button" className="btn btn-ghost-secondary btn-icon" onClick={toggleInfo}>
                        <FeatherIcon icon="info" className="icon-sm" />
                      </button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>

            <MessagesList
              currentRoomMessages={currentRoomMessages}
              currentUserId={currentUserId}
              messagesEndRef={messagesEndRef}
            />

            <div className="chat-input-section p-3 p-lg-4">
              <form id="chatinput-form">
                <Row className="g-0 align-items-center">
                  <div className="col-auto">
                    <div className="chat-input-links me-2">
                      <div className="links-list-item">
                        <button type="button" className="btn btn-link text-decoration-none emoji-btn" id="emoji-btn">
                          <i className="bx bx-smile align-middle"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    <div className="chat-input-feedback">Please Enter a Message</div>
                    <input
                      type="text"
                      value={curMessage}
                      onKeyPress={onKeyPress}
                      onChange={(e) => setcurMessage(e.target.value)}
                      className="form-control chat-input bg-light border-light"
                      id="chat-input"
                      placeholder="Type your message..."
                    />
                  </div>
                  <div className="col-auto">
                    <div className="chat-input-links ms-2">
                      <div className="links-list-item">
                        <Button
                          type="button"
                          color="success"
                          onClick={() => addMessage(currentRoomId, currentUser.name)}
                          className="chat-send waves-effect waves-light"
                        >
                          <i className="ri-send-plane-2-fill align-bottom"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Row>
              </form>
            </div>

            <div className="replyCard">
              <Card className="mb-0">
                <CardBody className="py-3">
                  <div className="replymessage-block mb-0 d-flex align-items-start">
                    <div className="flex-grow-1">
                      <p className="mb-0"></p>
                    </div>
                    <div className="flex-shrink-0">
                      <button type="button" id="close_toggle" className="btn btn-sm btn-link mt-n2 me-n3 fs-18">
                        <i className="bx bx-x align-middle"></i>
                      </button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>

      <PersonalInfo
        show={isInfoDetails}
        onCloseClick={() => setIsInfoDetails(false)}
        currentuser={Chat_Box_Username}
        cuurentiseImg={Chat_Box_Image}
        chat={chat}
        receiver={receiver}
      />
    </div>
  );
};

export default ChatInbox;
