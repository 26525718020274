const selectColumnsStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    padding: 0,
    border: 0,
    // boxShadow: "none",
    boxShadow: state.isFocused ? "0 0 0 2px rgba(0, 150, 255, 0.5)" : baseStyles.boxShadow,
    borderColor: "none",
  }),
  //   placeholder: (baseStyles) => ({
  //     ...baseStyles,
  //     content: "Select a column...",
  //     backgroundColor: 'blue'
  //   }),
  dropdownIndicator: (baseStyles, state) => ({
    ...baseStyles,
    display: "none",
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    // backgroundColor: 'red',
    border: "1px solid #ccc",
    borderRadius: "2.5px",
    height: "35px",
    // margin: "5px",
    width: "100%",
    // boxShadow: state.isFocused ? "0 0 0 2px rgba(0, 150, 255, 0.5)" : baseStyles.boxShadow,
    paddingLeft: "20px",
    ":focus": {
      paddingLeft: "10px",
    },
  }),
  indicatorSeparator: (baseStyles, state) => ({ ...baseStyles, display: "none" }),
  clearIndicator: (baseStyles, state) => ({ ...baseStyles, display: "none" }),
  menu: (baseStyles, state) => ({
    ...baseStyles,
    marginTop: "0",
    position: "absolute",
    top: 40,
    left: 0,
    right: 0,
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    cursor: "pointer",
  }),
  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    width: "100%",
    alignItems: "center",
    color: state.selectProps.menuIsOpen ? "#555" : "#999",
    flexDirection: "column-reverse",
    margin: "0 0 0 0",
    padding: "0 0 0 0",
    "> div": {
      width: "100%",
      marginRight: 0,
      marginLeft: 0,
      paddingRight: 0,
      paddingLeft: 0,
    },
  }),
  multiValue: (baseStyles, state) => ({
    ...baseStyles,
    display: "flex",
    width: "100%",
    padding: "2.5px",
    marginTop: "2.5px",
    border: "1px solid #ccc",
    backgroundColor: "rgba(0,0,0,0.05)",
  }),
  multiValueLabel: (baseStyles, state) => ({
    ...baseStyles,
    // backgroundColor: "yellow",
    width: "100%",
  }),
  //   // clearIndicator
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: 0,
    height: "auto",
    borderRadius: "4px",
    borderWidth: "1px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: "wrap",
    flexDirection: "column-reverse",
    alignItems: "flex-start",
    padding: "2px 8px",
    width: "100%",
  }),
  multiValue: (provided) => ({
    ...provided,
    marginTop: 2,
    marginBottom: 2,
    display: "flex",
    alignItems: "center",
    width: "100%",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    width: "100%",
    flex: 1, // Expand label to fill more space
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    paddingLeft: 4, // Adjust spacing for close button
  }),
  indicatorsContainer: () => ({
    display: "none",
  }),
  clearIndicator: () => ({
    display: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    display: "flex",
    justifyContent: "space-evenly",
  }),
};


export default selectColumnsStyles;
