import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, withRouter } from "react-router-dom";
import { CardBody, Col, Container, Input, Modal, ModalBody, ModalHeader, Progress, Row } from "reactstrap";
import TableContainer from "../../../../Components/Common/TableContainer";
import {
  createSNPStripeConnect,
  retrieveSNPConnectLoginLink,
  retrieveSNPConnectOnboardingLink,
} from "../../../../helpers/backend_helper";
import { getProfile } from "../../../../store/actions";
import { Status } from "../organization/components";

import { features } from "../../../Checkout/CheckoutForm";

const AccountsOverView = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [stripeConnectModal, setStripeConnectModal] = useState(false);
  const [plan, setPlan] = useState({});

  const { org } = useSelector((state) => ({
    org: state.Profile.org,
  }));

  useEffect(() => {
    if (!org) dispatch(getProfile());

    if (org) {
      for (let feature in features) {
        const foundPlan = features[feature].lookup_keys.includes(org?.subscription.lookup_key);
        if (foundPlan) {
          setPlan(features[feature]);
          break;
        }
      }
    }
  }, [dispatch, org]);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <Input type="checkbox" />;
        },
      },
      //{
      //Header: "Account ID",
      //accessor: "stripe_id",
      //filterable: false,
      //},
      {
        Header: "Account Type",
        accessor: "type",
        filterable: false,
      },
      {
        Header: "Payouts/Transfers Enabled",
        accessor: "payouts_enabled",
        filterable: false,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "Charges Enabled",
        accessor: "charges_enabled",
        filterable: false,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "Onboarding Completed",
        accessor: "details_submitted",
        filterable: false,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "Actions",
        accessor: "stripe_id",
        Cell: (cellProps) => {
          const done = cellProps.row.original?.details_submitted;
          const { cell } = cellProps;

          return !done ? (
            <button
              type="button"
              onClick={() => handleCompleteOnboarding({ id: cell?.value })}
              className="btn btn-sm btn-soft-primary"
            >
              Complete Onboarding
            </button>
          ) : (
            <>
              <button
                type="button"
                onClick={() => handleCompleteOnboarding({ id: cell?.value })}
                className="btn btn-sm btn-soft-primary mx-1"
              >
                Edit
              </button>
              <button
                type="button"
                onClick={() => handleCreateAccountLoginLink({ id: cell?.value })}
                className="btn btn-sm btn-soft-success mx-1"
              >
                Dashboard
              </button>
            </>
          );
        },
      },
    ],
    []
  );

  const handleCompleteOnboarding = async (data) => {
    // pop modal of stripe connected account aggrement
    try {
      const { onboarding_url } = await retrieveSNPConnectOnboardingLink(data);
      window.location = onboarding_url;
    } catch (error) {
      // launch error modal and dismiss
      console.error("ERROR: ", error);
    }
  };

  const handleCreateAccountLoginLink = async (data) => {
    try {
      const { login_link } = await retrieveSNPConnectLoginLink(data);
      window.open(login_link);
    } catch (error) {
      // launch error modal and dismiss
      console.error("ERROR: ", error);
    }
  };

  const handleCreateConnnectAccount = async () => {
    try {
      // lunch loader
      const { onboarding_url } = await createSNPStripeConnect();
      window.location = onboarding_url;
    } catch (error) {
      // launch error modal and dismiss
      console.error("ERROR: ", error);
    }
  };

  return (
    <React.Fragment>
      <Container>
        <Modal centered isOpen={stripeConnectModal} toggle={() => setStripeConnectModal(!stripeConnectModal)} size="lg">
          <ModalHeader toggle={() => setStripeConnectModal(false)}>Connect Account Terms of Service </ModalHeader>
          <hr className="my-0 text-muted" />
          <ModalBody>
            <Container className="text-center">
              <h5 className="text-center">
                By Registering your account, you hereby agree to our Service Agreement and the{" "}
                <a href="https://stripe.com/legal/connect-account" target="_blank" rel="noopener noreferrer">
                  Stripe Connected Account Service Agreement
                </a>
              </h5>

              <button
                className="btn btn-primary mt-2"
                onClick={() => {
                  setStripeConnectModal(false);
                  handleCreateConnnectAccount();
                }}
              >
                Register Your Account
              </button>
            </Container>
          </ModalBody>
        </Modal>

        <Col className="p-3 mb-3">
          {/* Top Row*/}
          <Row className="py-2">
            {/* Left Column */}
            <Row>
              <h3 className="">Current Plan: {plan?.name}</h3>
            </Row>

            <Col md={6}>
              <div className="mb-2 d-flex flex-column gap-1">
                <div className="mb-1 d-flex gap-1">
                  <span>Donor Contacts: </span>
                  <span>
                    {"  "}
                    {org?.no_of_contacts} of {org?.max_no_of_contacts}
                  </span>
                </div>
                <Progress value={org?.no_of_contacts} min={0} max={org?.max_no_of_contacts || 100} />
                <a href="/donor-contacts" className="cursor-pointer my-2">
                  <div>View All Donor-Contacts</div>
                </a>
              </div>

              <div className="mb-2 d-flex flex-column gap-1">
                <div className="mb-1 d-flex gap-1">
                  <span>Member Contacts: </span>
                  <span>
                    {"  "}
                    {org?.no_of_members} of {org?.max_no_of_members}
                  </span>
                </div>
                <Progress value={org?.no_of_members} min={0} max={org?.max_no_of_members || 100} />
                <a href="/members" className="cursor-pointer my-2">
                  <div>View All Member Contacts</div>
                </a>
              </div>

              <div className="mb-2 d-flex flex-column gap-1">
                <div className="mb-1 d-flex gap-1">
                  <span>Users: </span>
                  <span>
                    {"  "}
                    {org?.no_of_users} of {org?.max_no_of_users}
                  </span>
                </div>
                <Progress value={org?.no_of_users} min={0} max={org?.max_no_of_users || 100} />
                <a href="/settings-users" className="cursor-pointer my-2">
                  <div>View All Users</div>
                </a>
              </div>
            </Col>

            {/* Right Column */}
            <Col mg={6}>
              <CardBody className="px-4 mx-2">
                <div>
                  <ul className="list-unstyled text-muted vstack gap-3">
                    <li>
                      <div className="d-flex">
                        <div className="flex-shrink-0 text-success me-1">
                          <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                        </div>
                        <div className="flex-grow-1">
                          <b>{plan?.users}</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div className="flex-shrink-0 text-success me-1">
                          <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                        </div>
                        <div className="flex-grow-1">
                          <b>{plan?.storage}</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div className="flex-shrink-0 text-success me-1">
                          <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                        </div>
                        <div className="flex-grow-1">
                          <b>{plan?.donors}</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex">
                        <div className="flex-shrink-0 text-success me-1">
                          <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                        </div>
                        <div className="flex-grow-1">
                          <b>{plan?.membership}</b>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="mt-4">
                    <a
                      href="/accounts/plans"
                      className={`btn btn-${plan.planButtonClassname} w-100 waves-effect waves-light`}
                    >
                      Change Plan
                    </a>
                  </div>
                </div>
              </CardBody>
            </Col>
          </Row>

          {/* Bottom Row*/}
          <Row>
            <Col>
              <h3 className="">Connected Account</h3>
              {/* If we have connected accounts show them on a table*/}
              {!org?.connected_account?.length ? (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setStripeConnectModal(true);
                  }}
                >
                  Add an Account
                </button>
              ) : (
                <TableContainer
                  columns={columns}
                  data={org?.connected_account || []}
                  customPageSize={5}
                  customTotalSize={org?.connected_account?.length || 0}
                  customFetchData={() => {}}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(AccountsOverView);
