import React, { useEffect, useRef, useState } from "react";
import { Col, Input, Label, FormFeedback } from "reactstrap";
import Select from "react-select";

const MultiSelectField = ({ isClearOnSubmit, setIsClearOnSubmit, ...props }) => {
  const selectRef = useRef();

  const clearOnSubmit = () => {
    selectRef.current?.clearValue();
  };

  useEffect(() => {
    if (isClearOnSubmit) {
      clearOnSubmit();
      setIsClearOnSubmit(false);
    }
  }, [isClearOnSubmit]);

  return (
    <Col md={props.md}>
      <div className={props.mb}>
        <Label htmlFor={props.id} className="form-label">
          {props.label}
        </Label>

        <Select
          data-choices
          data-choices-search-false
          id={props.id}
          isClearable
          isSearchable
          ref={selectRef}
          defaultValue={props.defaultValue}
          key={props.defaultValue}
          name={props.name}
          placeholder={props.placeholder}
          className={`${
            (props.validation.touched[props.name] && props.validation.errors[props.name]) ||
            (props.errorMessage && props.errorMessage[props.name])
              ? "form-control is-invalid"
              : ""
          } `}
          isMulti
          onChange={(e) =>
            props.validation.setFieldValue(
              `${props.name}`,
              e.map((e) => e.value)
            )
          }
          options={props?.options}
          invalid={
            props.validation.touched[props.name] && props.validation.errors[props.name]
              ? true.toString()
              : false.toString() ||
                (props.errorMessage && props.errorMessage[props.name] && props.errorMessage[props.name].length)
              ? true.toString()
              : false.toString()
          }
        />

        {props.validation.touched[props.name] && props.validation.errors[props.name] ? (
          <FormFeedback type="invalid">{props.validation.errors[props.name]}</FormFeedback>
        ) : null}

        {props.errorMessage && props.errorMessage[props.name] ? (
          <FormFeedback type="invalid">
            <div>{props.errorMessage[props.name]}</div>
          </FormFeedback>
        ) : (
          ""
        )}
      </div>
    </Col>
  );
};

export default MultiSelectField;
