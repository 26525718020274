import React, { useCallback, useEffect, useMemo, useState } from "react";
import DeleteModal from "../../../Components/Common/DeleteModal";
import TableContainer from "../../../Components/Common/TableContainer";

//Import Flatepicker
import Flatpickr from "react-flatpickr";

import AsyncSelect from "react-select/async";
//redux
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import {
  addNewCampaign,
  deleteCampaign,
  getCampaignList,
  getUsersList as onGetUsers,
  resetCampaignFlag,
  updateCampaign,
} from "../../../store/actions";
import { CreateBy, DueDate, OrdersId, Priority, Project, Status } from "./CampaignListCol";

// Formik
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Components/Common/Loader";
import MsgToast from "../../../Components/Common/MsgToast";

import Select from "react-select";

// UTIL
import DateField from "../../../Components/Common/Fields/DateField";
import { getUsers } from "../../../helpers/backend_helper";
import Widgets from "./Widget";

const AllCampaigns = (props) => {
  const dispatch = useDispatch();

  const {
    campaignList,
    isCampaignCreated,
    isCampaignSuccess,
    usersList,
    error,
    page,
    stats,
    isCampaignAdd,
    isCampaignAddFail,
    isCampaignDelete,
    isCampaignDeleteFail,
    isCampaignUpdate,
    isCampaignUpdateFail,
  } = useSelector((state) => ({
    campaignList: state.Campaigns.campaignList,
    // clientsList: state.Clients.clientsList,
    // volunteerList: state.Volunteers.volunteerList,
    isCampaignCreated: state.Campaigns.isCampaignCreated,
    isCampaignSuccess: state.Campaigns.isCampaignSuccess,
    error: state.Campaigns.error,
    page: state.Campaigns.page,
    stats: state.Campaigns.stats,
    usersList: state.Users.usersList,
    isCampaignAdd: state.Campaigns.isCampaignAdd,
    isCampaignAddFail: state.Campaigns.isCampaignAddFail,
    isCampaignDelete: state.Campaigns.isCampaignDelete,
    isCampaignDeleteFail: state.Campaigns.isCampaignDeleteFail,
    isCampaignUpdate: state.Campaigns.isCampaignUpdate,
    isCampaignUpdateFail: state.Campaigns.isCampaignUpdateFail,
  }));

  const loadUsersOptions = (inputValue) => {
    return new Promise((resolve) => {
      const orgList = getUsers({ search: inputValue }).then(({ users: { users } }) => users);
      resolve(orgList);
    });
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetCampaignFlag());
    }, 3000);
  }, [
    dispatch,
    isCampaignSuccess,
    error,
    isCampaignAdd,
    isCampaignAddFail,
    isCampaignDelete,
    isCampaignDeleteFail,
    isCampaignUpdate,
    isCampaignUpdateFail,
  ]);

  useEffect(() => {
    if (!usersList) dispatch(onGetUsers());
    if (!campaignList) dispatch(getCampaignList());
  }, [dispatch, campaignList, usersList]);

  const [assignedUser, setAssignedUser] = useState(null);
  const [campaign, setCampaign] = useState([]);
  const [assignedUserLabelState, setAssignedUserLabelState] = useState(null);

  useEffect(() => {
    let assignedUsersLabels =
      campaign &&
      Object.values(campaign).length &&
      campaign.assigned_to.length &&
      campaign.assigned_to.map((user) => {
        return { label: `${user.user_details.first_name} ${user.user_details.last_name}`, value: user.id };
      });
    setAssignedUserLabelState(assignedUsersLabels);
  }, [campaign]);

  const [isEdit, setIsEdit] = useState(false);

  // Delete Campaign
  const [deleteModal, setDeleteModal] = useState(false);
  const [modal, setModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCampaign(null);
      setAssignedUser(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  // Delete Data
  const onClickDelete = (campaign) => {
    setCampaign(campaign);
    setDeleteModal(true);
  };

  // Delete Data
  const handleDeleteCampaign = () => {
    if (campaign) {
      dispatch(deleteCampaign(campaign));
      setDeleteModal(false);
    }
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (campaign && campaign.id) || "",
      title: (campaign && campaign.title) || "",
      // contact: (campaign && campaign.ontact) || "",
      type: (campaign && campaign.type) || "",
      due_date: (campaign && campaign.due_date) || "",
      status: (campaign && campaign.status) || "",
      priority: (campaign && campaign.priority) || "",
      assigned_to: (campaign && JSON.stringify(campaign.assigned_to?.map((a) => a.id))) || [],
      // clients: (campaign && JSON.stringify(campaign.clients?.map((a) => a.id))) || [],
      // volunteers: (campaign && JSON.stringify(campaign.volunteers?.map((a) => a.id))) || [],
      // teams: (campaign && JSON.stringify(campaign.team?.map((a) => a.id))) || [],
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Please enter a title"),
      // contact: Yup.string().required("Please enter a contact name"),
      type: Yup.string().notRequired("Please Enter Your Campaign"),
      due_date: Yup.string().required("Please Enter Due Date"),
      status: Yup.string().required("Please Enter Status"),
      // teams: Yup.mixed().required("Please Select A Team"),
      assigned_to: Yup.mixed().required("Please Assign Campaign "),
      // clients: Yup.mixed().notRequired("Please Enter Client Name"),
      // volunteers: Yup.mixed().notRequired("Please Enter Volunteer Name"),
      priority: Yup.string().required("Please Enter Priority"),
    }),

    onSubmit: (values) => {
      const campaign = values;
      // console.log(values);
      if (isEdit) {
        dispatch(updateCampaign(campaign));
      } else {
        dispatch(addNewCampaign(campaign));
        reset();
      }

      toggle();
    },
  });

  const reset = () => {
    validation.resetForm();
    setAssignedUserLabelState(null);
    // setTeamLabelState(null);
    // setClientLabelState(null);
    // setVolunteerLabelState(null);
  };

  // Update Data
  const handleCustomerClick = useCallback(
    (arg) => {
      const campaign = arg;

      setCampaign({
        id: campaign.id,
        title: campaign.title,
        // contact: campaign.contact,
        due_date: campaign.due_date,
        status: campaign.status,
        priority: campaign.priority,
        // clients: campaign.clients,
        assigned_to: campaign.assigned_to,
        // volunteers: campaign.volunteers,
        // teams: campaign.teams,
      });

      setAssignedUser(campaign?.assigned_to);

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  // Add Data
  const handleCampaignClicks = () => {
    setCampaign("");
    setIsEdit(false);
    toggle();
  };

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(getCampaignList({ offset, limit }));
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" />;
        },
      },
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
        Cell: (cellProps) => {
          return <OrdersId {...cellProps} />;
        },
      },
      {
        Header: "Title",
        accessor: "title",
        filterable: false,
        Cell: (cellProps) => {
          return <Project {...cellProps} />;
        },
      },
      {
        Header: "Created Date",
        accessor: "created_on",
        filterable: false,
        Cell: (cellProps) => {
          return <DueDate {...cellProps} />;
        },
      },
      {
        Header: "Due Date",
        accessor: "due_date",
        filterable: false,
        Cell: (cellProps) => {
          return <DueDate {...cellProps} />;
        },
      },
      {
        Header: "Created By",
        accessor: "created_by",
        filterable: false,
        Cell: (cellProps) => {
          return <CreateBy {...cellProps} />;
        },
      },
      // {
      //   Header: "Assigned To",
      //   accessor: "assigned_to",
      //   filterable: false,
      //   Cell: (cell) => {
      //     console.log(cell.row.original.assigned_to);

      //     const assigned = cell.value.map((item) => (item.img ? item.img : item));
      //     return (
      //       <React.Fragment>
      //         <div className="avatar-group">
      //           {assigned.map((item, index) => (
      //             <Link key={index} to="#" className="avatar-group-item">
      //               <img
      //                 src={process.env.REACT_APP_API_URL + "/images/users/" + item}
      //                 alt=""
      //                 className="rounded-circle avatar-xxs"
      //               />
      //             </Link>
      //           ))}
      //         </div>
      //       </React.Fragment>
      //     );
      //   },
      // },
      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "Priority",
        accessor: "priority",
        filterable: false,
        Cell: (cellProps) => {
          return <Priority {...cellProps} />;
        },
      },
      {
        Header: "Actions",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline mb-0">
              {/* <li className="list-inline-item edit" title="View">
                <Link to="/apps-campaigns-details">
                  <i className="ri-eye-fill fs-16 align-bottom me-2 text-muted"></i>
                </Link>
              </li> */}
              <li className="list-inline-item" title="Edit">
                <Link
                  to="#"
                  onClick={() => {
                    const campaignData = cellProps.row.original;
                    handleCustomerClick(campaignData);
                  }}
                >
                  <i className="ri-edit-2-fill fs-16 align-bottom me-2 text-muted"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Delete">
                <Link
                  to="#"
                  className="remove-item-btn"
                  onClick={() => {
                    const campaignData = cellProps.row.original;
                    onClickDelete(campaignData);
                  }}
                >
                  <i className="ri-delete-bin-fill fs-16 align-bottom me-2 text-muted"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleCustomerClick]
  );

  return (
    <React.Fragment>
      <Row>
        <Widgets stats={stats} />
      </Row>
      <DeleteModal show={deleteModal} onDeleteClick={handleDeleteCampaign} onCloseClick={() => setDeleteModal(false)} />
      <Row>
        <Col lg={12}>
          <div className="card" id="tasksList">
            <div className="card-header border-0">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 flex-grow-1">All Campaigns</h5>
                <div className="flex-shrink-0">
                  <button
                    className="btn btn-success add-btn me-1"
                    onClick={() => {
                      setIsEdit(false);
                      toggle();
                    }}
                  >
                    <i className="ri-add-line align-bottom me-1"></i> Create Campaign
                  </button>
                  <button className="btn btn-soft-danger">
                    <i className="ri-delete-bin-2-line"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body border border-dashed border-end-0 border-start-0">
              <form>
                <div className="row g-3">
                  <div className="col-xxl-5 col-sm-12">
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-control search bg-light border-light"
                        placeholder="Search for campaigns or something..."
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>

                  <div className="col-xxl-3 col-sm-4">
                    <Flatpickr
                      placeholder="Select date range"
                      className="form-control bg-light border-light"
                      options={{
                        mode: "range",
                        dateFormat: "d M, Y",
                      }}
                    />
                  </div>

                  <div className="col-xxl-3 col-sm-4">
                    <div className="input-light">
                      <select
                        className="form-control"
                        data-choices
                        data-choices-search-false
                        name="status"
                        id="idStatus"
                      >
                        <option value="">Status</option>
                        <option defaultValue="all">All</option>
                        <option value="New">New</option>
                        <option value="Pending">Pending</option>
                        <option value="Inprogress">Inprogress</option>
                        <option value="Completed">Completed</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-xxl-1 col-sm-4">
                    <button type="button" className="btn btn-primary w-100">
                      {" "}
                      <i className="ri-equalizer-fill me-1 align-bottom"></i>
                      Filters
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="card-body">
              {isCampaignSuccess ? (
                <TableContainer
                  columns={columns}
                  data={campaignList || []}
                  isGlobalFilter={false}
                  isAddUserList={false}
                  customFetchData={fetchData}
                  customTotalSize={page.count}
                  customPageSize={5}
                  className="custom-header-css"
                  divClass="table-responsive table-card mb-4"
                  tableClass="align-middle table-nowrap mb-0"
                  theadClass="table-light table-nowrap"
                  thClass="table-light text-muted"
                  handleCampaignClick={handleCampaignClicks}
                />
              ) : (
                <Loader error={error} />
              )}
              {/* <Loader error={error} /> */}
              {isCampaignAdd ? (
                <MsgToast msg="Campaign Added Successfully" color="success" icon="ri-checkbox-circle-line" />
              ) : null}
              {isCampaignAddFail ? (
                <MsgToast msg="Campaign Added Failed" color="danger" icon="ri-error-warning-line" />
              ) : null}
              {isCampaignDelete ? (
                <MsgToast msg="Campaign Deleted Successfully" color="success" icon="ri-checkbox-circle-line" />
              ) : null}
              {isCampaignDeleteFail ? (
                <MsgToast msg="Campaign Deleted Failed" color="danger" icon="ri-error-warning-line" />
              ) : null}
              {isCampaignUpdate ? (
                <MsgToast msg="Campaign Updated Successfully" color="success" icon="ri-checkbox-circle-line" />
              ) : null}
              {isCampaignUpdateFail ? (
                <MsgToast msg="Campaign Updated Failed" color="danger" icon="ri-error-warning-line" />
              ) : null}
              <ToastContainer limit={1} closeButton={false} />
            </div>
          </div>
        </Col>
      </Row>

      <Modal isOpen={modal} toggle={toggle} centered size="lg" className="border-0" modalClassName="modal fade zoomIn">
        <ModalHeader className="p-3 bg-soft-info" toggle={toggle}>
          {!!isEdit ? "Edit Campaign" : "Create Campaign"}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            console.log(validation.errors);
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody className="modal-body">
            <Row className="g-3">
              <Col lg={12}>
                <Label for="title-field" className="form-label">
                  Title
                </Label>
                <Input
                  name="title"
                  id="title-field"
                  className="form-control"
                  placeholder="Title"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.title || ""}
                  invalid={validation.touched.title && validation.errors.title ? true : false}
                />
                {validation.touched.title && validation.errors.title ? (
                  <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                ) : null}
              </Col>

              {/* <Col lg={12}>
                <Label for="client-field" className="form-label">
                  Client
                </Label>

                <Select
                  name="clients"
                  options={clientLabels}
                  isMulti
                  isClearable
                  // isDisabled={(location.state && massigned_to) || false}
                  defaultValue={assignedClientLabelState}
                  key={assignedClientLabelState}
                  onChange={(e) => validation.setFieldValue("clients", JSON.stringify(e.map((e) => e.value)))}
                  id="clients"
                  invalid={validation.touched.clients && validation.errors.clients ? true : false}
                  classNamePrefix="select2-selection form-select"
                />
                {validation.touched.client && validation.errors.client ? (
                  <FormFeedback type="invalid">{validation.errors.client}</FormFeedback>
                ) : null}
              </Col> */}

              {/* <Col lg={12}>
                <Label for="client-field" className="form-label">
                  Volunteer
                </Label>

                <Select
                  name="volunteers"
                  options={volunteerLabels}
                  isMulti
                  isClearable
                  // isDisabled={(location.state && massigned_to) || false}
                  defaultValue={assignedVolunteerLabelState}
                  key={assignedVolunteerLabelState}
                  onChange={(e) => validation.setFieldValue("volunteers", JSON.stringify(e.map((e) => e.value)))}
                  id="volunteers"
                  invalid={validation.touched.volunteers && validation.errors.volunteers ? true : false}
                  classNamePrefix="select2-selection form-select"
                />
                {validation.touched.volunteers && validation.errors.volunteers ? (
                  <FormFeedback type="invalid">{validation.errors.volunteers}</FormFeedback>
                ) : null}
              </Col> */}

              {/* <Col lg={12}>
                <Label className="form-label" htmlFor="team-input">
                  Team
                </Label>
                <Select
                  name="teams"
                  onChange={(e) => validation.setFieldValue("teams", JSON.stringify(e.map((e) => e.value)))}
                  defaultValue={assignedTeamLabelState}
                  key={assignedTeamLabelState}
                  isClearable
                  isMulti
                  options={teamLabels}
                  id="team"
                  invalid={validation.touched.teams && validation.errors.teams ? true : false}
                  classNamePrefix="select2-selection form-select"
                />
                {validation.touched.teams && validation.errors.teams ? (
                  <FormFeedback type="invalid">{validation.errors.teams}</FormFeedback>
                ) : null}
              </Col> */}

              <Col lg={12}>
                <Label className="form-label" htmlFor="assigned_to-client-input">
                  Assign To
                </Label>

                <AsyncSelect
                  className={`${
                    validation.touched.assigned_to && validation.errors.assigned_to ? "form-control is-invalid" : ""
                  } `}
                  isSearchable
                  isClearable
                  id="choices-team-input"
                  loadOptions={loadUsersOptions}
                  value={assignedUser}
                  getOptionLabel={(user) => `${user?.user_details?.first_name} ${user?.user_details?.last_name}`}
                  getOptionValue={(user) => `${user?.id}`}
                  options={usersList}
                  defaultOptions={usersList}
                  name="assigned_to"
                  isMulti={true}
                  onChange={(e) => {
                    setAssignedUser(e);
                    validation.setFieldValue("assigned_to", JSON.stringify(e.map((e) => e?.id)));
                  }}
                />

                {validation.touched.assigned_to && validation.errors.assigned_to ? (
                  <FormFeedback type="invalid">{validation.errors.assigned_to}</FormFeedback>
                ) : null}
              </Col>

              <DateField
                mb="mb-3"
                md="6"
                value={validation.values.due_date || ""}
                onChange={validation.handleChange}
                validation={validation}
                onBlur={validation.handleBlur}
                label="Due date"
                name="due_date"
              />

              <Col lg={6}>
                <Label for="ticket-status" className="form-label">
                  Status
                </Label>
                {/* <Input
                name="status"
                type="select"
                className="form-select"
                id="ticket-field"
                onChange={validation.handleChange("status")}
                onBlur={validation.handleBlur("status")}
                value={validation.values.status}
                >
                <option value="">Status</option>
                <option value="New">New</option>
                <option value="Pending">Pending</option>
                <option value="Completed">Completed</option>
                </Input> */}
                <Select
                  name="status"
                  id="ticket-status"
                  onBlur={validation.handleBlur}
                  value={
                    (validation.values.status && {
                      label: validation.values.status,
                      value: validation.values.status,
                    }) ||
                    ""
                  }
                  key={
                    (validation.values.status && {
                      label: validation.values.status,
                      value: validation.values.status,
                    }) ||
                    ""
                  }
                  placeholder="Select a status..."
                  onChange={(e) => validation.setFieldValue("status", e.value)}
                  options={[
                    { label: "Open", value: "Open" },
                    { label: "Pending", value: "Pending" },
                    { label: "Closed", value: "Closed" },
                  ]}
                />
                {validation.touched.status && validation.errors.status ? (
                  <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                ) : null}
              </Col>

              <Col lg={12}>
                <Label for="priority-field" className="form-label">
                  Priority
                </Label>
                {/* <Input
                name="priority"
                type="select"
                className="form-select"
                id="priority-field"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.priority || ""}
                >
                <option value="">Priority</option>
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
                </Input> */}

                <Select
                  name="priority"
                  id="ticket-priority"
                  onBlur={validation.handleBlur}
                  value={
                    (validation.values.priority && {
                      label: validation.values.priority,
                      value: validation.values.priority,
                    }) ||
                    ""
                  }
                  key={
                    (validation.values.priority && {
                      label: validation.values.priority,
                      value: validation.values.priority,
                    }) ||
                    ""
                  }
                  placeholder={"Select campaign priority..."}
                  onChange={(e) => validation.setFieldValue("priority", e.value)}
                  options={[
                    { label: "High", value: "High" },
                    { label: "Medium", value: "Medium" },
                    { label: "Low", value: "Low" },
                  ]}
                />
                {validation.touched.priority && validation.errors.priority ? (
                  <FormFeedback type="invalid">{validation.errors.priority}</FormFeedback>
                ) : null}
              </Col>
            </Row>
          </ModalBody>
          <div className="modal-footer">
            <div className="hstack gap-2 justify-content-end">
              <Button
                type="button"
                onClick={() => {
                  setModal(false);
                  // validation.resetForm();
                  // setAssignedUserLabelState(null);
                  // setClientLabelState(null);
                }}
                className="btn-light"
              >
                Close
              </Button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {!!isEdit ? "Update Campaign" : "Add Campaign"}
              </button>
            </div>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
export default AllCampaigns;
