export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_VOLUNTEERS_LIST = "GET_VOLUNTEERS_LIST";
export const GET_VOLUNTEER = "GET_VOLUNTEER";

export const ADD_NEW_VOLUNTEER = "ADD_NEW_VOLUNTEER";
export const ADD_NEW_VOLUNTEER_SUCCESS = "ADD_NEW_VOLUNTEER_SUCCESS";
export const ADD_NEW_VOLUNTEER_FAIL = "ADD_NEW_VOLUNTEER_FAIL";

export const UPDATE_VOLUNTEER = "UPDATE_VOLUNTEER";
export const UPDATE_VOLUNTEER_SUCCESS = "UPDATE_VOLUNTEER_SUCCESS";
export const UPDATE_VOLUNTEER_FAIL = "UPDATE_VOLUNTEER_FAIL";

export const DELETE_VOLUNTEER = "DELETE_VOLUNTEER";
export const DELETE_VOLUNTEER_SUCCESS = "DELETE_VOLUNTEER_SUCCESS";
export const DELETE_VOLUNTEER_FAIL = "DELETE_VOLUNTEER_FAIL";

export const RESET_VOLUNTEER_FLAG = "RESET_VOLUNTEER_FLAG";
