import PropTypes from "prop-types";
import React, { useState } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import { api } from "../../config";
//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../store/actions";
import axios from "axios";

// import images
// import profile from "../../assets/images/bg.png";
import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "./ParticlesAuth";

const Reactivateuser = (props) => {
  const dispatch = useDispatch();
  const [activationSuccess, setActivationSuccess] = useState("");
  const [invalidToken, setInvalidToken] = useState("");

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
    }),
    onSubmit: (values) => {
      try {
        const reActivateUserHandler = async () => {
          let url = `${api.API_URL}/auth/resend-activation-link/`;

          let res = await axios.post(url, { username: values.username });
          setActivationSuccess(res.message);
          // const res = await activateUser(url);
          // if (res.status == 400) {
          //   setInvalidToken(res.message);
          // }

          // if (res.status == 200) {
          //   setActivationSuccess(res.message);
          // }
        };

        reActivateUserHandler();
      } catch (err) {
        //console.log(err, "EROORRR");
      }
    },
  });

  const { forgetError, forgetSuccessMsg } = useSelector((state) => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));

  document.title = "Activate User Account | SimplyNP";
  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="20" />
                  </Link>
                </div>
                <p className="mt-3 fs-15 fw-medium">The better way to manage your NON-Profit</p>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Activate Account</h5>
                    <p className="text-muted">Activate Account with SimplyNP</p>

                    <lord-icon
                      src="https://cdn.lordicon.com/rhvddzym.json"
                      trigger="loop"
                      colors="primary:#0ab39c"
                      className="avatar-xl"
                      style={{ width: "120px", height: "120px" }}
                    ></lord-icon>
                  </div>

                  <div className="p-2">
                    {/* {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null} */}
                    {activationSuccess ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {activationSuccess}
                      </Alert>
                    ) : (
                      <Alert className="alert-borderless alert-warning text-center mb-2 mx-2" role="alert">
                        Enter your username and instructions will be sent to you!
                      </Alert>
                    )}
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-4">
                        <Label className="form-label">Username</Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Enter username"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={validation.touched.username && validation.errors.username ? true : false}
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.username}</div>
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="text-center mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          Send activation Link
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-4 text-center">
                <p className="mb-0">
                  Wait, I remember my password...{" "}
                  <Link to="/login" className="fw-semibold text-primary text-decoration-underline">
                    {" "}
                    Click here{" "}
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

Reactivateuser.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Reactivateuser);
