import React, { useCallback, useEffect, useMemo, useState } from "react";
import DeleteModal from "../../../Components/Common/DeleteModal";
import TableContainer from "../../../Components/Common/TableContainer";

//redux
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import {
  addNewTask,
  deleteTask,
  resetTaskFlag,
  updateTask,
  getTaskList as onGetTasks,
  getUsersList as onGetUsers,
  getTeamsList as onGetTeams,
  getClientsList as onGetClients,
} from "../../../store/actions";
import { CreateBy, DueDate, OrdersId, Priority, Project, Status } from "./TaskListCol";

// Formik
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Components/Common/Loader";
import MsgToast from "../../../Components/Common/MsgToast";
import { debounce } from "lodash";
import Select from "react-select";

// UTIL
import Widgets from "./Widgets";
import DateField from "../../../Components/Common/Fields/DateField";

const AllTasks = () => {
  const dispatch = useDispatch();

  const [task, setTask] = useState([]);
  const [assignedUser, setAssignedUser] = useState(null);
  const [assignedClient, setAssignedClient] = useState(null);
  const [assignedTeam, setAssignedTeam] = useState(null);

  const {
    taskList,
    teamsList,
    clientsList,
    isTaskCreated,
    isTaskSuccess,
    usersList,
    error,
    page,
    stats,
    isTaskAdd,
    isTaskAddFail,
    isTaskDelete,
    isTaskDeleteFail,
    isTaskUpdate,
    isTaskUpdateFail,
    isLoading,
  } = useSelector((state) => ({
    taskList: state.Tasks.taskList,
    clientsList: state.Clients.clientsList,
    teamsList: state.Teams.teamsList,
    usersList: state.Users.usersList,
    isTaskCreated: state.Tasks.isTaskCreated,
    isTaskSuccess: state.Tasks.isTaskSuccess,
    error: state.Tasks.error,
    page: state.Tasks.page,
    stats: state.Tasks.stats,
    isTaskAdd: state.Tasks.isTaskAdd,
    isTaskAddFail: state.Tasks.isTaskAddFail,
    isTaskDelete: state.Tasks.isTaskDelete,
    isTaskDeleteFail: state.Tasks.isTaskDeleteFail,
    isTaskUpdate: state.Tasks.isTaskUpdate,
    isTaskUpdateFail: state.Tasks.isTaskUpdateFail,
    isLoading: state.Tasks.isLoading,
  }));

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetTaskFlag());
    }, 3000);
  }, [
    dispatch,
    isTaskSuccess,
    error,
    isTaskAdd,
    isTaskAddFail,
    isTaskDelete,
    isTaskDeleteFail,
    isTaskUpdate,
    isTaskUpdateFail,
  ]);

  // Get Data
  useEffect(() => {
    if (!clientsList) dispatch(onGetClients());
    if (!taskList) dispatch(onGetTasks());
    if (!teamsList) dispatch(onGetTeams());
    if (!usersList) dispatch(onGetUsers());
  }, [dispatch, clientsList, taskList, teamsList, usersList]);

  useEffect(() => {
    const assignedUser = task?.assigned_to
    setAssignedUser(assignedUser);

    const assignedClients = task?.clients
    setAssignedClient(assignedClients);

    const assignedTeams = task?.teams
    setAssignedTeam(assignedTeams);
  }, [task]);

  const [isEdit, setIsEdit] = useState(false);

  // Delete Task
  const [deleteModal, setDeleteModal] = useState(false);
  const [modal, setModal] = useState(false);

  // Delete Data
  const onClickDelete = (task) => {
    setTask(task);
    setDeleteModal(true);
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (task && task.id) || "",
      title: (task && task.title) || "",
      // contact: (task && task.ontact) || "",
      type: (task && task.type) || "",
      due_date: (task && task.due_date) || "",
      status: (task && task.status) || "",
      priority: (task && task.priority) || "",
      assigned_to: (task && JSON.stringify(task.assigned_to?.map((a) => a.id))) || [],
      clients: (task && JSON.stringify(task.clients?.map((a) => a.id))) || [],
      teams: (task && JSON.stringify(task.team?.map((a) => a.id))) || [],
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Please enter a title"),
      // contact: Yup.string().required("Please enter a contact name"),
      type: Yup.string().notRequired("Please Enter Your Task"),
      due_date: Yup.string().required("Please Enter Due Date"),
      status: Yup.string().required("Please Enter Status"),
      teams: Yup.mixed().required("Please Select A Team"),
      assigned_to: Yup.mixed().required("Please Assign Task "),
      clients: Yup.mixed().notRequired("Please Enter Client Name"),
      priority: Yup.string().required("Please Enter Priority"),
    }),

    onSubmit: (values) => {
      const task = values;
      if (isEdit) {
        dispatch(updateTask(task));
      } else {
        dispatch(addNewTask(task));
        reset();
      }

      toggle();
    },
  });

  const reset = () => {
    validation.resetForm();
    setAssignedUser(null);
    setAssignedTeam(null);
    setAssignedClient(null);
  }; // Delete Data
  const handleDeleteTask = () => {
    if (task) {
      dispatch(deleteTask(task));
      setDeleteModal(false);
      reset();
    }
  };

  const toggle = useCallback(
    (isEdit) => {
      if (!isEdit) {
        setTask(null);
      }

      if (modal) {
        setModal(false);
      } else {
        setModal(true);
      }
    },
    [modal]
  );
  // Update Data
  const handleCustomerClick = useCallback(
    (arg) => {
      const task = arg;

      setTask({
        id: task.id,
        title: task.title,
        // contact: task.contact,
        due_date: task.due_date,
        status: task.status,
        priority: task.priority,
        assigned_to: task.assigned_to,
        clients: task.clients,
        teams: task.teams,
      });

      setIsEdit(true);
      toggle("isEdit");
    },
    [toggle]
  );

  // Add Data
  const handleTaskClicks = () => {
    setTask("");
    setIsEdit(false);
    toggle();
  };

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(onGetTasks({ offset, limit }));
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" />;
        },
      },
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
        Cell: (cellProps) => {
          return <OrdersId {...cellProps} />;
        },
      },
      {
        Header: "Title",
        accessor: "title",
        filterable: false,
        Cell: (cellProps) => {
          return <Project {...cellProps} />;
        },
      },
      {
        Header: "Created Date",
        accessor: "created_on",
        filterable: false,
        Cell: (cellProps) => {
          return <DueDate {...cellProps} />;
        },
      },
      {
        Header: "Due Date",
        accessor: "due_date",
        filterable: false,
        Cell: (cellProps) => {
          return <DueDate {...cellProps} />;
        },
      },
      {
        Header: "Created By",
        accessor: "created_by",
        filterable: false,
        Cell: (cellProps) => {
          return <CreateBy {...cellProps} />;
        },
      },

      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "Priority",
        accessor: "priority",
        filterable: false,
        Cell: (cellProps) => {
          return <Priority {...cellProps} />;
        },
      },
      {
        Header: "Actions",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline mb-0">
              <li className="list-inline-item" title="Edit">
                <Link
                  to="#"
                  onClick={() => {
                    const taskData = cellProps.row.original;
                    handleCustomerClick(taskData);
                  }}
                >
                  <i className="ri-edit-2-fill fs-16 align-bottom me-2 text-muted"></i>
                </Link>
              </li>
              <li className="list-inline-item" title="Delete">
                <Link
                  to="#"
                  className="remove-item-btn"
                  onClick={() => {
                    const taskData = cellProps.row.original;
                    onClickDelete(taskData);
                  }}
                >
                  <i className="ri-delete-bin-fill fs-16 align-bottom me-2 text-muted"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleCustomerClick]
  );

  const handler = useCallback(
    debounce((value) => {
      dispatch(onGetTasks({ search: value, limit: 10 }));
    }, 500),
    []
  );
  const handleFieldSearch = (e) => {
    let value = e.target.value;
    handler(value);
  };

  return (
    <React.Fragment>
      <Row>
        <Widgets stats={stats} />
      </Row>
      <DeleteModal show={deleteModal} onDeleteClick={handleDeleteTask} onCloseClick={() => setDeleteModal(false)} />
      <Row>
        <Col lg={12}>
          <div className="card" id="tasksList">
            <div className="card-header border-0">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 flex-grow-1">All Tasks</h5>
                <div className="flex-shrink-0">
                  <button
                    className="btn btn-success add-btn me-1"
                    onClick={() => {
                      setIsEdit(false);
                      toggle();
                    }}
                  >
                    <i className="ri-add-line align-bottom me-1"></i> Create Task
                  </button>
                  {/* <button className="btn btn-soft-danger">
                    <i className="ri-delete-bin-2-line"></i>
                  </button> */}
                </div>
              </div>
            </div>
            <div className="card-body border border-dashed border-end-0 border-start-0">
              <form>
                <div className="row g-3">
                  <div className="col-xxl-5 col-sm-12">
                    <div className="search-box">
                      <input
                        onChange={(e) => {
                          handleFieldSearch(e);
                        }}
                        type="text"
                        className="form-control search bg-light border-light"
                        placeholder="Search by task's title..."
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>

                  <div className="col-xxl-3 col-sm-4">
                    <Flatpickr
                      placeholder="Select date range"
                      className="form-control bg-light border-light"
                      options={{
                        mode: "range",
                        dateFormat: "d M, Y",
                      }}
                    />
                  </div>

                  <div className="col-xxl-3 col-sm-4">
                    <div className="input-light">
                      <select
                        className="form-control"
                        data-choices
                        data-choices-search-false
                        name="status"
                        id="idStatus"
                      >
                        <option value="">Status</option>
                        <option defaultValue="all">All</option>
                        <option value="New">New</option>
                        <option value="Pending">Pending</option>
                        <option value="Inprogress">Inprogress</option>
                        <option value="Completed">Completed</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-xxl-1 col-sm-4">
                    <button type="button" className="btn btn-primary w-100">
                      {" "}
                      <i className="ri-equalizer-fill me-1 align-bottom"></i>
                      Filters
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="card-body">
              {isTaskSuccess && taskList.length ? (
                <TableContainer
                  columns={columns}
                  data={taskList || []}
                  isGlobalFilter={false}
                  isAddUserList={false}
                  customFetchData={fetchData}
                  customTotalSize={page.count}
                  customPageSize={10}
                  className="custom-header-css"
                  divClass="table-responsive table-card mb-4"
                  tableClass="align-middle table-nowrap mb-0"
                  theadClass="table-light table-nowrap"
                  thClass="table-light text-muted"
                  handleTaskClick={handleTaskClicks}
                />
              ) : (
                "No data in table yet"
              )}
              {isLoading ? <Loader /> : null}

              {isTaskAdd ? (
                <MsgToast msg="Task Added Successfully" color="success" icon="ri-checkbox-circle-line" />
              ) : null}
              {isTaskAddFail ? <MsgToast msg="Task Added Failed" color="danger" icon="ri-error-warning-line" /> : null}
              {isTaskDelete ? (
                <MsgToast msg="Task Deleted Successfully" color="success" icon="ri-checkbox-circle-line" />
              ) : null}
              {isTaskDeleteFail ? (
                <MsgToast msg="Task Deleted Failed" color="danger" icon="ri-error-warning-line" />
              ) : null}
              {isTaskUpdate ? (
                <MsgToast msg="Task Updated Successfully" color="success" icon="ri-checkbox-circle-line" />
              ) : null}
              {isTaskUpdateFail ? (
                <MsgToast msg="Task Updated Failed" color="danger" icon="ri-error-warning-line" />
              ) : null}
              <ToastContainer limit={1} closeButton={false} />
            </div>
          </div>
        </Col>
      </Row>

      <Modal
        isOpen={modal}
        toggle={() => {
          setIsEdit(false);
          toggle();
        }}
        centered
        size="lg"
        className="border-0"
        modalClassName="modal fade zoomIn"
      >
        <ModalHeader
          className="p-3 bg-soft-info"
          toggle={() => {
            setIsEdit(false);
            toggle();
          }}
        >
          {!!isEdit ? "Edit Task" : "Create Task"}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody className="modal-body">
            <Row className="g-3">
              <Col lg={12}>
                <Label for="title-field" className="form-label">
                  Title
                </Label>
                <Input
                  name="title"
                  id="title-field"
                  className="form-control"
                  placeholder="Title"
                  type="text"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.title || ""}
                  invalid={validation.touched.title && validation.errors.title ? true : false}
                />
                {validation.touched.title && validation.errors.title ? (
                  <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                ) : null}
              </Col>

              <Col lg={12}>
                <Label for="client-field" className="form-label">
                  Client
                </Label>

                <Select
                  name="clients"
                  isMulti
                  isClearable
                  // isDisabled={(location.state && massigned_to) || false}
                  options={clientsList}
                  defaultValue={assignedClient}
                  getOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
                  getOptionValue={(option) => option?.id}
                  key={assignedClient}
                  onChange={(e) => validation.setFieldValue("clients", JSON.stringify(e?.map((e) => e?.id)))}
                  id="clients"
                  invalid={validation.touched.clients && validation.errors.clients ? true : false}
                  classNamePrefix="select2-selection form-select"
                />
                {validation.touched.clients && validation.errors.clients ? (
                  <FormFeedback type="invalid">{validation.errors.clients}</FormFeedback>
                ) : null}
              </Col>

              <Col lg={12}>
                <Label className="form-label" htmlFor="team-input">
                  Team
                </Label>
                <Select
                  name="teams"
                  onChange={(e) => validation.setFieldValue("teams", JSON.stringify(e?.map((e) => e?.id)))}
                  defaultValue={assignedTeam}
                  key={assignedTeam}
                  isClearable
                  getOptionLabel={(option) => `${option?.name}`}
                  getOptionValue={(option) => option?.id}
                  isMulti
                  options={teamsList}
                  id="team"
                  invalid={validation.touched.teams && validation.errors.teams ? true : false}
                  classNamePrefix="select2-selection form-select"
                />
                {validation.touched.teams && validation.errors.teams ? (
                  <FormFeedback type="invalid">{validation.errors.teams}</FormFeedback>
                ) : null}
              </Col>

              <Col lg={12}>
                <Label className="form-label" htmlFor="assigned_to-client-input">
                  Assign To
                </Label>
                <Select
                  name="assigned_to"
                  // onChange={(e) => validation.setFieldValue("assigned_to", e.value)}
                  options={usersList}
                  isMulti
                  isClearable
                  // isDisabled={(location.state && massigned_to) || false}
                  getOptionLabel={(option) => `${option?.user_details?.first_name} ${option?.user_details?.last_name}`}
                  getOptionValue={(option) => option?.id}
                  defaultValue={assignedUser}
                  key={assignedUser}
                  onChange={(e) => validation.setFieldValue("assigned_to", JSON.stringify(e?.map((e) => e?.id)))}
                  id="assigned_to"
                  invalid={validation.touched.assigned_to && validation.errors.assigned_to ? true : false}
                  classNamePrefix="select2-selection form-select"
                />
                {validation.touched.assigned_to && validation.errors.assigned_to ? (
                  <FormFeedback type="invalid">{validation.errors.assigned_to}</FormFeedback>
                ) : null}
              </Col>

              <Col lg={6}>
                <DateField
                  mb="mb-3"
                  value={validation.values.due_date || ""}
                  onChange={validation.handleChange}
                  validation={validation}
                  onBlur={validation.handleBlur}
                  label="Due date"
                  name="due_date"
                />
              </Col>

              <Col lg={6}>
                <Label for="ticket-status" className="form-label">
                  Status
                </Label>
                {/* <Input
                  name="status"
                  type="select"
                  className="form-select"
                  id="ticket-field"
                  onChange={validation.handleChange("status")}
                  onBlur={validation.handleBlur("status")}
                  value={validation.values.status}
                >
                  <option value="">Status</option>
                  <option value="New">New</option>
                  <option value="Pending">Pending</option>
                  <option value="Completed">Completed</option>
                </Input> */}
                <Select
                  name="status"
                  id="ticket-status"
                  onBlur={validation.handleBlur}
                  value={
                    (validation.values.status && {
                      label: validation.values.status,
                      value: validation.values.status,
                    }) ||
                    ""
                  }
                  key={
                    (validation.values.status && {
                      label: validation.values.status,
                      value: validation.values.status,
                    }) ||
                    ""
                  }
                  placeholder="Select a status..."
                  onChange={(e) => validation.setFieldValue("status", e.value)}
                  options={[
                    { label: "New", value: "New" },
                    { label: "Pending", value: "Pending" },
                    { label: "Completed", value: "Completed" },
                  ]}
                />
                {validation.touched.status && validation.errors.status ? (
                  <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                ) : null}
              </Col>

              <Col lg={12}>
                <Label for="priority-field" className="form-label">
                  Priority
                </Label>

                <Select
                  name="priority"
                  id="ticket-priority"
                  onBlur={validation.handleBlur}
                  value={
                    (validation.values.priority && {
                      label: validation.values.priority,
                      value: validation.values.priority,
                    }) ||
                    ""
                  }
                  key={
                    (validation.values.priority && {
                      label: validation.values.priority,
                      value: validation.values.priority,
                    }) ||
                    ""
                  }
                  placeholder={"Select task priority..."}
                  onChange={(e) => validation.setFieldValue("priority", e.value)}
                  options={[
                    { label: "High", value: "High" },
                    { label: "Medium", value: "Medium" },
                    { label: "Low", value: "Low" },
                  ]}
                />
                {validation.touched.priority && validation.errors.priority ? (
                  <FormFeedback type="invalid">{validation.errors.priority}</FormFeedback>
                ) : null}
              </Col>
            </Row>
          </ModalBody>
          <div className="modal-footer">
            <div className="hstack gap-2 justify-content-end">
              <Button
                type="button"
                onClick={() => {
                  setModal(false);
                }}
                className="btn-light"
              >
                Close
              </Button>
              <button type="submit" className="btn btn-success" id="add-btn">
                {!!isEdit ? "Update Task" : "Add Task"}
              </button>
            </div>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default AllTasks;
