import { useFormik } from "formik";
import React, { useState } from "react";
import Flatpickr from "react-flatpickr";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Col, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap";
import * as Yup from "yup";
import { statusOptions } from "../../../common/data/common";
import country from "../../../common/data/countries.json";
import DateField from "../../../Components/Common/Fields/DateField";
import { getMembers as onGetMembers } from "../../../store/actions";

const OffCanvasFilter = ({ show, onCloseClick }) => {
  const dispatch = useDispatch();
  const [selectCountry, setselectCountry] = useState(null);

  const handleselectCountry = (selectCountry) => {
    setselectCountry(selectCountry);
  };

  const [member, setMember] = useState(null);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      date_of_birth: "",
      status: "",
      mobile: "",
      phone: "",
      organization: "",
      city: "",
      country: "",
    },

    validationSchema: Yup.object({
      first_name: Yup.string().nullable(),
      last_name: Yup.string().nullable(),
      email: Yup.string().nullable(),
      date_of_birth: Yup.date().nullable(),
      status: Yup.string().nullable(),
      language: Yup.string().nullable(),
      mobile: Yup.string().nullable(),
      phone: Yup.string().nullable(),
      country: Yup.string().nullable(),
      organization: Yup.string().nullable(),
      city: Yup.string().nullable(),
    }),

    onSubmit: (values) => {
      dispatch(onGetMembers(values));
      onCloseClick();
      return;
    },

    onReset: () => {
      // we could make a new search if needed
      //dispatch(onGetMembers());
    },
  });

  return (
    <Offcanvas
      direction="end"
      className="offcanvas-end border-0"
      isOpen={show}
      id="offcanvasExample"
      toggle={onCloseClick}
    >
      <OffcanvasHeader
        className="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header-dark"
        toggle={onCloseClick}
      >
        <span className="m-0 me-2 text-white">Member Filters</span>
      </OffcanvasHeader>
      <PerfectScrollbar>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          onReset={() => {
            validation.handleReset();
          }}
          className="d-flex flex-column justify-content-end h-100"
        >
          <OffcanvasBody>
            <div className="mb-4">
              <Label htmlFor="name" className="form-label text-muted text-uppercase fw-semibold mb-3">
                Name
              </Label>
              <Row className="g-2 align-items-center">
                <div className="col-lg">
                  <Input
                    type="text"
                    id="first_name"
                    name="first_name"
                    className="form-control"
                    value={validation.values.first_name}
                    placeholder="Enter first name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    onFocus={validation.handleFocus}
                  />
                </div>
                <div className="col-lg">
                  <Input
                    type="text"
                    id="last_name"
                    name="last_name"
                    className="form-control"
                    placeholder="Enter last name"
                    value={validation.values.last_name}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    onFocus={validation.handleFocus}
                  />
                </div>
              </Row>
            </div>
            <div className="mb-4">
              <Label htmlFor="datepicker-range" className="form-label text-muted text-uppercase fw-semibold mb-3">
                Email
              </Label>
              <Input
                type="text"
                id="email"
                name="email"
                className="form-control"
                placeholder="Enter email"
                value={validation.values.email}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                onFocus={validation.handleFocus}
              />
            </div>
            <div className="mb-4">
              <DateField
                name="date_of_birth"
                label="Date of Birth"
                validation={validation}
                value={validation.values.date_of_birth}
                onChange={validation.handleChange}
              />
            </div>
            <div className="mb-4">
              <Label htmlFor="country-select" className="form-label text-muted text-uppercase fw-semibold mb-3">
                Country
              </Label>
              <Select
                className="mb-0"
                onChange={(e) => {
                  validation.setFieldValue("country", e.value);
                }}
                value={country.find((e) => e.value === validation.values.country) || ""}
                options={country}
                id="country-select"
              />
            </div>
            <div className="mb-4">
              <Label htmlFor="status-input" className="form-label text-muted text-uppercase fw-semibold mb-3">
                Status
              </Label>
              <Row className="g-2" id="status-input">
                <Col>
                  <Select
                    id="status-input"
                    name="status"
                    value={statusOptions.find((v) => validation.values.status === v.value) || ""}
                    options={statusOptions}
                    onChange={(e) => {
                      validation.setFieldValue("status", e.value);
                    }}
                  />
                </Col>
              </Row>
            </div>
            <div className="mb-4">
              <Label htmlFor="datepicker-range" className="form-label text-muted text-uppercase fw-semibold mb-3">
                Phone Number
              </Label>
              <Input
                type="text"
                id="phone"
                name="phone"
                className="form-control"
                placeholder="Enter phone"
                value={validation.values.phone}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                onFocus={validation.handleFocus}
              />
            </div>
            <div>
              <Row className="g-3"></Row>
            </div>
          </OffcanvasBody>

          <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
            <button type="reset" className="btn btn-light w-100">
              Clear Filters
            </button>
            <button type="submit" className="btn btn-success w-100">
              Filter
            </button>
          </div>
        </form>
      </PerfectScrollbar>
    </Offcanvas>
  );
};

export default OffCanvasFilter;
