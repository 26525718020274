import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_TASK_LIST, ADD_NEW_TASK, DELETE_TASK, UPDATE_TASK } from "./actionType";
import {
  TaskApiResponseSuccess,
  TaskApiResponseError,
  addTaskSuccess,
  addTaskFail,
  updateTaskSuccess,
  updateTaskFail,
  deleteTaskSuccess,
  deleteTaskFail,
  getTaskList as getTaskListAction,
} from "./action";

import { getTasks as getTaskListApi, createTasks, updateTask, deleteTask } from "../../helpers/backend_helper";

function* getTaskList({ payload: { params } }) {
  try {
    const response = yield call(getTaskListApi, params);
    yield put(
      TaskApiResponseSuccess(GET_TASK_LIST, {
        tasks: response.tasks,
        stats: response.task_stats,
        page: { offset: response.offset, count: response.tasks_count },
      })
    );
  } catch (error) {
    yield put(TaskApiResponseError(GET_TASK_LIST, error));
  }
}

function* onAddNewTask({ payload: task }) {
  try {
    const response = yield call(createTasks, task);
    yield put(addTaskSuccess(response));
    yield put(getTaskListAction());
  } catch (error) {
    yield put(addTaskFail(error));
  }
}

function* onDeleteTask({ payload: task }) {
  try {
    const response = yield call(deleteTask, task.id);
    yield put(deleteTaskSuccess({ task, ...response }));
    yield put(getTaskListAction());
  } catch (error) {
    yield put(deleteTaskFail(error));
  }
}

function* onUpdateTask({ payload: task }) {
  try {
    const response = yield call(updateTask, task.id, task);
    yield put(updateTaskSuccess(response));
    yield put(getTaskListAction());
  } catch (error) {
    yield put(updateTaskFail(error));
  }
}

export function* watchGetTaskList() {
  yield takeEvery(GET_TASK_LIST, getTaskList);
}

export function* watchAddNewTask() {
  yield takeEvery(ADD_NEW_TASK, onAddNewTask);
}

export function* watchUpdateTask() {
  yield takeEvery(UPDATE_TASK, onUpdateTask);
}

export function* watchDeleteTask() {
  yield takeEvery(DELETE_TASK, onDeleteTask);
}

function* taskSaga() {
  yield all([fork(watchGetTaskList), fork(watchAddNewTask), fork(watchUpdateTask), fork(watchDeleteTask)]);
}

export default taskSaga;
