import { debounce, isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

// Import Images
import AsyncSelect from "react-select/async";

import { handleValidDate } from "../../Utility";

import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";

//Import actions
import {
  addNewTeam as onAddNewTeam,
  getUsersList as onGetUsers,
  deleteTeam as onDeleteTeam,
  getTeamsList as onGetTeams,
  updateTeam as onUpdateTeam,
  resetTeamFlag,
} from "../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";

// Formik
import { useFormik } from "formik";
import * as Yup from "yup";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Components/Common/Loader";
import MsgToast from "../../Components/Common/MsgToast";
import { getUsers } from "../../helpers/backend_helper";

const TeamsList = () => {
  const dispatch = useDispatch();
  const {
    usersList,
    teamsList,
    page,
    isTeamCreated,
    isTeamSuccess,
    isTeamDelete,
    isTeamDeleteFail,
    isTeamUpdate,
    isTeamUpdateFail,
    error,
    isTeamAdd,
    isTeamAddFail,
  } = useSelector((state) => ({
    isTeamCreated: state.Teams.isTeamsCreated,
    isTeamSuccess: state.Teams.isTeamSuccess,
    usersList: state.Users.usersList,
    teamsList: state.Teams.teamsList,
    page: state.Teams.page,
    error: state.Teams.error,
    isTeamAdd: state.Teams.isTeamAdd,
    isTeamAddFail: state.Teams.isTeamAddFail,
    isTeamDelete: state.Teams.isTeamDelete,
    isTeamDeleteFail: state.Teams.isTeamDeleteFail,
    isTeamUpdate: state.Teams.isTeamUpdate,
    isTeamUpdateFail: state.Teams.isTeamUpdateFail,
  }));

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetTeamFlag());
    }, 3000);
  }, [
    dispatch,
    isTeamSuccess,
    error,
    isTeamAdd,
    isTeamAddFail,
    isTeamDelete,
    isTeamDeleteFail,
    isTeamUpdate,
    isTeamUpdateFail,
  ]);

  useEffect(() => {
    if (!usersList) dispatch(onGetUsers());
    if (!teamsList) dispatch(onGetTeams());

    if (!isEmpty(teamsList)) {
      setIsEdit(false);
    }
  }, [dispatch, usersList, teamsList]);

  const [isEdit, setIsEdit] = useState(false);
  const [team, setTeam] = useState({});

  const [teamLead, setTeamLead] = useState(null); // lead
  const [teamMembers, setTeamMembers] = useState([]); // members

  //delete team
  const [deleteModal, setDeleteModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [sortBy, setsortBy] = useState(null);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(onGetTeams({ offset, limit }));
    },
    [dispatch]
  );

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setTeam(null);
      setTeamLead(null);
      setTeamMembers([]);
      setIsEdit(false);
    } else {
      setModal(true);
    }
  }, [modal]);

  // Update Data
  const handleTeamClick = useCallback(
    (team) => {
      setTeam({
        id: team.id,
        // teamId: team.teamId,
        name: team.name,
        team_lead: team.team_lead,
        users: team.users,
        status: team.status,
        description: team.description,
      });

      setTeamLead(team.team_lead);
      setTeamMembers(team.users);

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  // Add Data
  const handleTeamClicks = () => {
    setTeam("");
    setIsEdit(false);
    toggle();
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return (
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="checkAll" value="option1" />
            </div>
          );
        },
      },

      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        Cell: (team) => (
          <>
            {team.row.original.status === "active" ? (
              <span className={"badge badge-soft-success me-1"}>Active</span>
            ) : (
              <span className={"badge badge-soft-danger me-1"}>In-Active</span>
            )}
          </>
        ),
      },
      {
        Header: "Team Name",
        accessor: "name",
        filterable: true,
      },
      {
        Header: "Team Lead",
        accessor: "team_lead",
        Cell: (cellProps) => {
          const tl = cellProps.row.original.team_lead?.user_details;
          return <>{`${tl?.first_name || ""} ${tl?.last_name || ""}`}</>;
        },
      },
      {
        Header: "Created On",
        accessor: "created_on",
        filterable: true,
        Cell: (cellProps) => {
          return <>{handleValidDate(cellProps.row.original.created_on)}</>;
        },
      },
      {
        Header: "Created By",
        accessor: "created_by",
        filterable: false,
        Cell: (cellProps) => {
          return (
            <>
              {/* {console.log(cellProps.row.original)} */}
              {cellProps.row.original.created_by.user_details.first_name}
            </>
          );
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              {/*<li className="list-inline-item edit" title="Message">
                <Link to="#" className="text-muted d-inline-block">
                  <i className="ri-question-answer-line fs-16"></i>
                </Link>
              </li>*/}
              <li
                className="list-inline-item edit"
                title="Message"
                onClick={() => {
                  const teamData = cellProps.row.original;
                  handleTeamClick(teamData);
                }}
              >
                <Link to="#" className="text-muted d-inline-block">
                  <i className="ri-edit-2-line ri-md align-middle me-1 text-muted"></i> Edit
                </Link>
              </li>
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle href="#" className="btn-soft-secondary btn-sm dropdown" tag="button">
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    {/*<DropdownItem
                      className="dropdown-item edit-item-btn"
                      href="#"
                      onClick={() => {
                        const teamData = cellProps.row.original;
                        handleTeamClick(teamData);
                      }}
                    >
                      <i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Edit
                    </DropdownItem>*/}
                    <DropdownItem
                      className="dropdown-item remove-item-btn"
                      href="#"
                      onClick={() => {
                        const teamData = cellProps.row.original;
                        onClickDelete(teamData);
                      }}
                    >
                      <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleTeamClick]
  );

  const handlesortBy = (sortBy) => {
    setsortBy(sortBy);
  };

  const sortbyname = [
    { label: "Owner", value: "Owner" },
    { label: "Company", value: "Company" },
    { label: "Location", value: "Location" },
  ];

  const statusOptions = [
    { label: "Active", value: "active" },
    { label: "In-Active", value: "in-active" },
  ];

  // Delete Data
  const handleDeleteTeam = () => {
    if (team) {
      dispatch(onDeleteTeam(team));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (team) => {
    setTeam(team);
    setDeleteModal(true);
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (team && team.id) || "",
      name: (team && team.name) || "",
      team_lead: (team && team?.team_lead?.id) || "",
      // team_members: (team && team.team_members) || [],
      assign_users: (team && JSON.stringify(team?.users?.map((t) => t.id))) || [],
      status: (team && team.status) || "active",
      description: (team && team.description) || "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter A First Name"),
      team_lead: Yup.mixed().notRequired("Please Assign Team to a Team"),

      assign_users: Yup.mixed().notRequired("Please Assign Team to a Team"),
      status: Yup.string().required("Define a status for the Team"),
      description: Yup.string().required("Please Enter Description"),
    }),

    onSubmit: (values) => {
      if (isEdit) {
        dispatch(onUpdateTeam(values));
      } else {
        dispatch(onAddNewTeam(values));
      }

      validation.resetForm();
      toggle();
    },
  });

  const handleAssignTeam = (userslabels) => {
    // setUsersLabels(userslabels);
    const assigned = userslabels.map((item) => item?.id);
    const assignedStringified = JSON.stringify(assigned);
    validation.setFieldValue("assign_users", assignedStringified);
    // setAssignTeam(assigned);
  };

  const loadUsersOptions = (inputValue) => {
    return new Promise((resolve) => {
      const orgList = getUsers({ search: inputValue }).then(({ users: { users } }) => {
        return users;
      });
      resolve(orgList);
    });
  };

  const handleFieldSearch = useCallback(
    debounce((value) => {
      dispatch(onGetTeams({ search: value }));
    }, 1500),
    []
  );

  document.title = "Teams | SimplyNP";
  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal show={deleteModal} onDeleteClick={handleDeleteTeam} onCloseClick={() => setDeleteModal(false)} />
        <Container fluid>
          <BreadCrumb title="List Teams" pageTitle="Teams" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-1">
                      <button
                        className="btn btn-info add-btn"
                        onClick={() => {
                          setModal(true);
                        }}
                      >
                        <i className="ri-add-fill me-1 align-bottom"></i> Add Team
                      </button>
                    </div>
                    <div className="flex-shrink-0">
                      {/*
                      <div className="hstack text-nowrap gap-2">
                        <button
                          className="btn btn-soft-danger"
                          // onClick="deleteMultiple()"
                        >
                          <i className="ri-delete-bin-2-line"></i>
                        </button>
                        <button className="btn btn-danger">
                          <i className="ri-filter-2-line me-1 align-bottom"></i> Filters
                        </button>
                        <button className="btn btn-soft-success">Import</button>

                        <UncontrolledDropdown>
                          <DropdownToggle href="#" className="btn btn-soft-info" tag="button">
                            <i className="ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" href="#">
                              All
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Week
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Month
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Year
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
      */}
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col>
              <Card id="teamList">
                <CardHeader>
                  <Row className="g-3">
                    <Col md={4}>
                      <div className="search-box">
                        <Input
                          type="text"
                          onChange={(e) => handleFieldSearch(e.target.value)}
                          className="form-control search"
                          placeholder="Search for team..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    <div className="col-md-auto ms-auto">
                      <div className="d-flex align-items-center gap-2">
                        <span className="text-muted">Sort by: </span>
                        <Select
                          className="mb-0"
                          value={sortBy}
                          onChange={() => {
                            handlesortBy();
                          }}
                          options={sortbyname}
                          id="choices-single-default"
                        ></Select>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    {isTeamSuccess && teamsList ? (
                      <TableContainer
                        columns={columns}
                        data={teamsList || []}
                        isGlobalFilter={false}
                        isAddTeamList={false}
                        customFetchData={fetchData}
                        customTotalSize={page.count}
                        customPageSize={5}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        handleTeamClick={handleTeamClicks}
                      />
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>

                  <Modal id="showModal" size="lg" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-soft-info p-3" toggle={toggle}>
                      {!!isEdit ? "Edit Team" : "Add Team"}
                    </ModalHeader>

                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <Input type="hidden" id="id-field" />
                        <Row className="g-3">
                          {/* Status */}
                          <Col lg={2}>
                            <div>
                              <Label htmlFor="status-field" className="form-label">
                                Status
                              </Label>
                              <Select
                                name="status"
                                id="status-field"
                                placeholder="status"
                                validate={{ required: { value: true } }}
                                // onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={validation.touched.status && validation.errors.status ? true : false}
                                // value={sortBy}
                                defaultValue={team && statusOptions.find((s) => s.value === team?.status)}
                                key={team && statusOptions.find((s) => s.value === team?.status)}
                                onChange={(e) => validation.setFieldValue("status", e.value)}
                                options={statusOptions}
                              ></Select>
                              {validation.touched.status && validation.errors.status ? (
                                <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col lg={10}>
                            <div>
                              <Label htmlFor="name-field" className="form-label">
                                Team Name
                              </Label>
                              <Input
                                name="name"
                                id="name-field"
                                className="form-control"
                                placeholder="Enter Team Name"
                                type="text"
                                validate={{ required: { value: true } }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={validation.touched.name && validation.errors.name ? true : false}
                              />
                              {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          {/* Status */}
                          <Col lg={12}>
                            <div>
                              <Label htmlFor="status-field" className="form-label font-size-13">
                                Team Lead
                              </Label>
                              <AsyncSelect
                                name="team_lead"
                                id="team_lead-field"
                                placeholder="Enter Team Lead"
                                className="mb-0"
                                isClearable
                                loadOptions={loadUsersOptions}
                                getOptionValue={(option) => option?.id}
                                getOptionLabel={(option) =>
                                  `${option?.user_details.first_name} ${option?.user_details.last_name}`
                                }
                                defaultValue={teamLead}
                                key={teamLead}
                                defaultOptions={usersList}
                                onChange={(e) => validation.setFieldValue("team_lead", e?.id)}
                                onBlur={validation.handleBlur}
                              />
                              {validation.touched.team_lead && validation.errors.team_lead ? (
                                <FormFeedback type="invalid">{validation.errors.team_lead}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          {/* Team Members */}
                          <Col lg={12}>
                            <div>
                              <Label htmlFor="team_members-field" className="form-label font-size-13">
                                Team Members
                              </Label>
                              <AsyncSelect
                                isMulti
                                onChange={(e) => {
                                  handleAssignTeam(e);
                                  setTeamMembers(e);
                                }}
                                isClearable
                                loadOptions={loadUsersOptions}
                                getOptionValue={(option) => option?.id}
                                getOptionLabel={(option) =>
                                  `${option?.user_details.first_name} ${option?.user_details.last_name}`
                                }
                                defaultValue={teamMembers}
                                value={teamMembers}
                                key={teamMembers}
                                name="team_members"
                                className="mb-0"
                                defaultOptions={usersList}
                                id="team_members-field"
                              />

                              {validation.touched.team_members && validation.errors.team_members ? (
                                <FormFeedback type="invalid">{validation.errors.team_members}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          {/* Description */}
                          <Col lg={12}>
                            <div>
                              <Label htmlFor="description-field" className="form-label">
                                Description
                              </Label>
                              <Input
                                name="description"
                                id="description-field"
                                className="form-control"
                                placeholder="Enter Team Description"
                                type="textarea"
                                rows="3"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                invalid={validation.touched.description && validation.errors.description ? true : false}
                              />
                              {validation.touched.description && validation.errors.description ? (
                                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          {/* Tags */}
                          {/*<Col lg={12}>
                            <div>
                              <Label
                                htmlFor="taginput-choices"
                                className="form-label font-size-13 text-muted"
                                >
                                Tags
                              </Label>
                              <Select
                                isMulti
                                value={tag}
                                onChange={(e) => handlestag(e)}
                                className="mb-0"
                                options={status}
                                id="taginput-choices"
                                >
                              </Select>

                              {validation.touched.status && validation.errors.status ? (
                                <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>*/}
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            {" "}
                            Close{" "}
                          </button>
                          <button type="submit" className="btn btn-success" id="add-btn">
                            {" "}
                            {!!isEdit ? "Update" : "Add Team"}{" "}
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  {isTeamCreated ? (
                    <MsgToast msg="Team Added Successfully" color="success" icon="ri-checkbox-circle-line" />
                  ) : null}
                  {isTeamAdd ? (
                    <MsgToast msg="Team Added Successfully" color="success" icon="ri-checkbox-circle-line" />
                  ) : null}
                  {isTeamAddFail ? (
                    <MsgToast msg="Team Added Failed" color="danger" icon="ri-error-warning-line" />
                  ) : null}
                  {isTeamDelete ? (
                    <MsgToast msg="Team Deleted Successfully" color="success" icon="ri-checkbox-circle-line" />
                  ) : null}
                  {isTeamDeleteFail ? (
                    <MsgToast msg="Team Deleted Failed" color="danger" icon="ri-error-warning-line" />
                  ) : null}
                  {isTeamUpdate ? (
                    <MsgToast msg="Team Updated Successfully" color="success" icon="ri-checkbox-circle-line" />
                  ) : null}
                  {isTeamUpdateFail ? (
                    <MsgToast msg="Team Updated Failed" color="danger" icon="ri-error-warning-line" />
                  ) : null}
                  <ToastContainer limit={1} closeButton={false} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TeamsList;
