import { getProfile } from "../backend_helper";

export const getAuthBearer = () => {
  const access_token = sessionStorage.getItem("__logged_in_user_data__");
  return { token: access_token };
};

export const getLoggedInUser = () => {
  const access_token = sessionStorage.getItem("__logged_in_user_data__");
  const status = sessionStorage.getItem("__subscription_status__");

  //const userId = authUser?.user_details?.id;
  //const user = authUser?.user_details; // class User(AbstractUser)
  //const user_details = authUser?.user?.user_detailss; // class Profile(models.Model)
  //const access_token = authUser?.access_token;
  //const org = authUser?.user?.org?.id;

  if (!access_token) return null;
  //if (!user && !access_token) return null;
  //return { user, token: access_token, org, userId, authUser };
  //return { user, token: access_token, org, userId, user_details };

  return { token: access_token, status };
};
