import { useState } from "react";
import { components } from "react-select";

export const MultiValueContainer = ({ children, ...props }) => (
  <components.MultiValueContainer {...props}>{children}</components.MultiValueContainer>
);

export const MultiValueLabel = ({ children, ...props }) => {
  const [state, setState] = useState(true);
  return (
    <components.MultiValueLabel {...props} onClick={() => console.log("")}>
      {/* {state ? "Good: " : "Bad: "} {children} */}
      {children}
    </components.MultiValueLabel>
  );
};

export const MultiValue = ({ children, ...props }) => (
  <components.MultiValue {...props}>{children}</components.MultiValue>
);

export const Placeholder = ({ children, ...props }) => (
  <components.Placeholder {...props}>
    <div style={{ paddingLeft: "10px" }}>{children}</div>
  </components.Placeholder>
);
