export const RUN_REPORTS = "RUN_REPORTS";
export const RUN_REPORTS_SUCCESS = "RUN_REPORTS_SUCCESS";
export const RUN_REPORTS_ERROR = "RUN_REPORTS_ERROR";

export const CREATE_REPORT = "CREATE_REPORT";
export const CREATE_REPORT_SUCCESS = "CREATE_REPORT_SUCCESS";
export const CREATE_REPORT_ERROR = "CREATE_REPORT_ERROR";

export const DELETE_REPORT = "DELETE_REPORT";
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS";
export const DELETE_REPORT_ERROR = "DELETE_REPORT_ERROR";

export const CLIENTS_REPORT_ERROR = "CLIENTS_REPORT_ERROR";

export const GET_REPORTS_LIST = "GET_REPORTS_LIST";
export const GET_REPORTS_LIST_SUCCESS = "GET_REPORTS_LIST_SUCCESS";
export const GET_REPORTS_LIST_ERROR = "GET_REPORTS_LIST_ERROR";

export const UPDATE_REPORT = "UPDATE_REPORT";
export const UPDATE_REPORT_SUCCESS = "UPDATE_REPORT_SUCCESS";
export const UPDATE_REPORT_ERROR = "UPDATE_REPORT_ERROR";

export const EXPORT_REPORT = "EXPORT_REPORT";
export const EXPORT_REPORT_SUCCESS = "EXPORT_REPORT_SUCCESS";
export const EXPORT_REPORT_ERROR = "EXPORT_REPORT_ERROR";

export const RESET_REPORT_FLAGS = "RESET_REPORT_FLAGS";