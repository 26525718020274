import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// import { GET_SERVICES_LIST, ADD_NEW_SERVICE, DELETE_SERVICE, UPDATE_SERVICE, GET_SERVICE } from "./actionType";

import { GET_SERVICES_LIST, ADD_NEW_SERVICE, DELETE_SERVICE, UPDATE_SERVICE, GET_SERVICE } from "./actionType";

import {
  serviceApiResponseSuccess,
  servicesApiResponseError,
  getService as getserviceAction,
  getServicesList as getServicesListAction,
  updateServiceSuccess,
  addNewServiceSuccess,
  updateServiceFail,
  deleteServiceSuccess,
  deleteServiceFail,
  addNewServiceFail,
} from "./action";

import {
  getServices as getServicesListApi,
  getService as getserviceApi,
  createService,
  updateService,
  deleteService,
} from "../../helpers/backend_helper";

function* getServicesList({ payload: params }) {
  try {
    const response = yield call(getServicesListApi, params);
    const { services } = response;
    yield put(
      serviceApiResponseSuccess(GET_SERVICES_LIST, {
        services,
        page: { offset: response.offset, count: response.services_count },
      })
    );
  } catch (error) {
    yield put(servicesApiResponseError(GET_SERVICES_LIST, error));
  }
}

function* getServiceWorker({ payload: service }) {
  try {
    const response = yield call(getserviceApi, service);
    yield put(serviceApiResponseSuccess(GET_SERVICE, response));
  } catch (error) {
    yield put(servicesApiResponseError(GET_SERVICE, error));
  }
}

function* addNewServiceWorker({ payload: service }) {
  try {
    const response = yield call(createService, service);

    if (response.status === 200) {
      yield put(addNewServiceSuccess(response));
      yield put(getServicesListAction());
    }
    if (response.status === 400) {
      yield put(addNewServiceFail(response));
    }
  } catch (error) {
    yield put(addNewServiceFail(error));
  }
}

function* updateServiceWorker({ payload: service }) {
  // service payload here is a FormData Object - hence "service.get("id")
  try {
    const response = yield call(updateService, service.id, service);

    if (response.status === 200) {
      yield put(updateServiceSuccess(response));
      yield put(getServicesListAction());
    }
    if (response.status === 400) {
      yield put(updateServiceFail(response));
    }
  } catch (error) {
    yield put(updateServiceFail(error));
  }
}

function* deleteServiceWorker({ payload: service }) {
  try {
    const response = yield call(deleteService, service.id);
    yield put(deleteServiceSuccess({ service, ...response }));
    yield put(getServicesListAction());
  } catch (error) {
    yield put(deleteServiceFail(error));
  }
}

export function* watchGetservice() {
  yield takeEvery(GET_SERVICE, getServiceWorker);
}

export function* watchGetServicesList() {
  yield takeEvery(GET_SERVICES_LIST, getServicesList);
}

export function* watchAddNewService() {
  yield takeEvery(ADD_NEW_SERVICE, addNewServiceWorker);
}

export function* watchUpdateService() {
  yield takeEvery(UPDATE_SERVICE, updateServiceWorker);
}

export function* watchDeleteService() {
  yield takeEvery(DELETE_SERVICE, deleteServiceWorker);
}

function* ServicesSaga() {
  yield all([
    fork(watchGetservice),
    fork(watchGetServicesList),
    fork(watchAddNewService),
    fork(watchUpdateService),
    fork(watchDeleteService),
  ]);
}

export default ServicesSaga;
