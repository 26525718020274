import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

// Import React FilePond
import { registerPlugin } from "react-filepond";
// Import FilePond styles
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";

import { useParams } from "react-router-dom";
import CheckoutForm from "./Payment";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getPaymentPublishableKey } from "../../../helpers/backend_helper";
import { getProfile } from "../../../store/actions";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const MembershipCheckout = (props) => {
  const dispatch = useDispatch();
  const { id: membership_pk } = useParams();

  const [membership, setMembership] = useState(null);

  const { membershipsList, org } = useSelector((state) => ({
    error: state.Membership.error,
    loading: state.Membership.loading,
    membershipsList: state.Membership.memberships,
    org: state.Profile.org,
  }));

  useEffect(() => {
    if (!org) dispatch(getProfile());

    if (membershipsList) {
      let c = membershipsList.find((c) => c.id.toString() === membership_pk);
      setMembership(c);
    }

    return () => {
      setMembership(null);
    };
  }, [membership_pk, dispatch, membershipsList, org]);

  const [stripePromise, setStripePromise] = useState(null);
  useEffect(() => {
    getPaymentPublishableKey().then(({ publishable_key }) => {
      if (publishable_key)
        setStripePromise(
          loadStripe(publishable_key, {
            //stripeAccount: org?.connected_account[0].stripe_id,
          })
        );
    });
  }, [org.connected_account]);

  document.title = "Membership Checkout - Membership Management | SimplyNP";

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Pay Membership" pageTitle="Memberships" />

        {/* CHECKOUT MODAL*/}
        {stripePromise && membership && (
          <Elements stripe={stripePromise}>
            <CheckoutForm membership={membership} />
          </Elements>
        )}
      </Container>
    </div>
  );
};

export default MembershipCheckout;
