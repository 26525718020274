import React from "react";
import ReactApexChart from "react-apexcharts";

function getChartColorsArray(colors) {
  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
      if (color.indexOf("#") !== -1) color = color.replace(" ", "");
      if (color) return color;
      else return newValue;
    } else {
      var val = value.split(",");
      if (val.length === 2) {
        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
}

const SimpleRadar = ({ data }) => {
  const dataColors = '["--vz-success"]';
  var chartRadarBasicColors = getChartColorsArray(dataColors);
  const series = [
    {
      name: "Series 1",
      data: data?.series || [],
    },
  ];
  var options = {
    chart: {
      height: 350,
      type: "radar",
      toolbar: {
        show: false,
      },
    },
    colors: chartRadarBasicColors,
    xaxis: {
      categories: data?.labels || [],
    },
  };
  return <ReactApexChart series={series} options={options} type="radar" height={365} />;
};
export { SimpleRadar };
