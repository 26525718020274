import React, { useEffect, useState } from "react";
import { Offcanvas, OffcanvasHeader, OffcanvasBody, Label, Form, Input, Row, Col } from "reactstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import actions
import { getDonationsList, getContacts as onGetContacts } from "../../../store/actions";
//redux
import * as Yup from "yup";
import DateField from "../../../Components/Common/Fields/DateField";
import countries from "../../../common/data/countries.json";
import TextField from "../../../Components/Common/Fields/TextField.js";
import SelectField from "../../../Components/Common/Fields/SelectField.js";
import AsyncSelect from "react-select/async";
import { getContacts } from "../../../helpers/backend_helper";

const CrmFilter = ({ show, onCloseClick }) => {
  const dispatch = useDispatch();

  const [selectCountry, setselectCountry] = useState(null);
  const [contact, setContact] = useState(null);

  const handleselectCountry = (selectCountry) => {
    setselectCountry(selectCountry);
  };
  let { data, updateSuccess, created, error, contactList, activeDonor, organizations } = useSelector((state) => ({
    data: state.Donations.data,
    updateSuccess: state.Donations.updateSuccess,
    created: state.Donations.created,
    contactList: state.Crm.crmcontacts,
    organizations: state.Crm.organizations,
    activeDonor: state.Crm.activeDonorCount,

    error: state.Crm.error,
  }));
  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {},

    onSubmit: (donations) => {
      dispatch(getDonationsList(donations));
    },
  });

  useEffect(() => {
    dispatch(onGetContacts());
  }, [dispatch]);

  const loadUsersOptions = (inputValue) => {
    return new Promise((resolve) => {
      const usersList = getContacts({ search: inputValue }).then(({ users }) => {
        const c = users.users;
        return c;
      });
      resolve(usersList);
    });
  };
  const NoOptionsMessage = () => {
    return <div>type to search...</div>;
  };

  return (
    <Offcanvas direction="end" isOpen={show} id="offcanvasExample" toggle={onCloseClick}>
      <OffcanvasHeader className="bg-light" toggle={onCloseClick}>
        Contacts Fliters
      </OffcanvasHeader>
      <PerfectScrollbar>
        <Form
          className="d-flex flex-column justify-content-end h-100"
          onSubmit={(e) => {
            e.preventDefault();
            form.handleSubmit();
            return false;
          }}
          onReset={() => {
            form.handleReset();
            form.handleSubmit();
          }}
        >
          <OffcanvasBody>
            <TextField
              md="12"
              mb="mb-3"
              value={form.values.amount || ""}
              onChange={form.handleChange}
              validation={form}
              onBlur={form.handleBlur}
              placeholder="Enter amount"
              label="Enter amount"
              name="amount"
            />

            <DateField
              mb="mb-3"
              md="12"
              value={form.values.created_on || ""}
              onChange={form.handleChange}
              validation={form}
              onBlur={form.handleBlur}
              label="Created on"
              name="created_on"
            />

            <Col md={12}>
              <Label className="form-label" htmlFor="product-title-input">
                Donor
              </Label>
              <div className="mb-3">
                <AsyncSelect
                  cacheOptions
                  id="assigned_to-input"
                  onBlur={form.handleBlur}
                  name="donor"
                  placeholder="select donor..."
                  isMulti={false}
                  isClearable
                  isSearchable
                  loadOptions={loadUsersOptions}
                  value={contact}
                  // noOptionsMessage={NoOptionsMessage}
                  defaultOptions={contactList}
                  getOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
                  getOptionValue={(option) => option?.id}
                  onChange={(e) => {
                    setContact(e);

                    form.setFieldValue("donor", e?.id || "");
                  }}
                />
              </div>
            </Col>

            <TextField
              md="12"
              mb="mb-3"
              value={form.values.cause || ""}
              onChange={form.handleChange}
              validation={form}
              onBlur={form.handleBlur}
              placeholder="Enter cause"
              label="cause"
              name="cause"
            />

            <TextField
              md="12"
              mb="mb-3"
              value={form.values.mobile || ""}
              onChange={form.handleChange}
              validation={form}
              onBlur={form.handleBlur}
              placeholder="Enter mobile"
              label="mobile"
              name="mobile"
            />

            <TextField
              md="12"
              mb="mb-3"
              value={form.values.city || ""}
              onChange={form.handleChange}
              validation={form}
              onBlur={form.handleBlur}
              placeholder="Enter last name"
              label="city"
              name="city"
            />
          </OffcanvasBody>
          <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
            <button type="reset" className="btn btn-light w-100" onClick={onCloseClick}>
              Clear Filter
            </button>
            <button type="submit" className="btn btn-success w-100" onClick={onCloseClick}>
              Filters
            </button>
          </div>
        </Form>
      </PerfectScrollbar>
    </Offcanvas>
  );
};

export default CrmFilter;
