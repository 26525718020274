import {
  ACTIVATE_USER,
  ACTIVATE_USER_SUCCESSFUL,
  ACTIVATE_USER_FAILED,
  RESET_ACTIVATE_FLAG,
  ACTIVATE_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  RESET_REGISTER_FLAG,
  REGISTRATION_ERROR,
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGIN_ERROR,
  RESET_LOGIN_FLAG,
  LOGIN_FAILED,
  GET_USER_ORG,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILED,
} from "./actionTypes";

/*REFRESH_TOKEN*/
export const refreshToken = () => ({
  type: REFRESH_TOKEN,
});

export const refreshTokenSuccess = (user) => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload: user,
});
export const refreshTokenFailed = (error) => ({
  type: REFRESH_TOKEN_FAILED,
  payload: error,
});

/* ACTIVATE ACTIONS BEGIN HERE */
export const activateUser = (user) => {
  return {
    type: ACTIVATE_USER,
    payload: user,
  };
};

export const activateUserSuccessful = (user) => {
  return {
    type: ACTIVATE_USER_SUCCESSFUL,
    payload: user,
  };
};

export const activateUserFailed = (error) => {
  return {
    type: ACTIVATE_USER_FAILED,
    payload: error,
  };
};

export const resetActivateUserFlag = () => {
  return {
    type: RESET_ACTIVATE_FLAG,
  };
};

export const activationError = (error) => {
  return {
    type: ACTIVATE_ERROR,
    payload: error,
  };
};

/* REGISTER ACTIONS BEGIN HERE */
export const registerUser = (user) => {
  return {
    type: REGISTER_USER,
    payload: { user },
  };
};

export const registerUserSuccessful = (user) => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  };
};

export const registerUserFailed = (error) => {
  return {
    type: REGISTER_USER_FAILED,
    payload: error,
  };
};

export const resetRegisterFlag = () => {
  return {
    type: RESET_REGISTER_FLAG,
  };
};

export const registrationError = (error) => {
  return {
    type: REGISTRATION_ERROR,
    payload: error,
  };
};

/* LOGIN ACTIONS BEGIN HERE */
export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const loginFailed = (error) => {
  return {
    type: LOGIN_FAILED,
    payload: error,
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
  };
};

export const apiError = (error) => {
  return {
    type: LOGIN_ERROR,
    payload: error,
  };
};

export const resetLoginFlag = () => {
  return {
    type: RESET_LOGIN_FLAG,
  };
};

export const getUserOrganisation = (history) => {
  return {
    type: GET_USER_ORG,
    payload: { history },
  };
};
