import React, { useEffect, useState } from "react";
import { Offcanvas, OffcanvasHeader, OffcanvasBody, Label, Form, Input, Row, Col } from "reactstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import AsyncSelect from "react-select/async";

//Import actions
import {
  addNewNote,
  deleteNote,
  getCasesList,
  getNotesList,
  getUsersList as onGetUsers,
  getClientsList as onGetClients,
  resetNoteFlag,
  updateNote,
} from "../../../store/actions";
//redux
import * as Yup from "yup";
import DateField from "../../../Components/Common/Fields/DateField";
import countries from "../../../common/data/countries.json";
import TextField from "../../../Components/Common/Fields/TextField.js";
import SelectField from "../../../Components/Common/Fields/SelectField.js";
import { getCase, getCases, getClients } from "../../../helpers/backend_helper";

const CrmFilter = ({ show, onCloseClick }) => {
  const dispatch = useDispatch();

  const [selectCountry, setselectCountry] = useState(null);
  const [caseObj, setCaseObj] = useState(null);
  const [client, setClient] = useState(null);

  const { clientsList, teamsList, casesList, users, isCaseUpdate, isCaseUpdateFail } = useSelector((state) => ({
    casesList: state.Cases.casesList,
    crmusers: state.Users.usersList,
    error: state.Cases.error,
    isCaseAdd: state.Cases.isCaseAdd,
    isCaseAddFail: state.Cases.isCaseAddFail,
    isCaseUpdate: state.Cases.isCaseUpdate,
    isCaseUpdateFail: state.Cases.isCaseUpdateFail,
    users: state.Users.usersList,
    teamsList: state.Teams.teamsList,
    clientsList: state.Clients.clientsList,
  }));

  useEffect(() => {
    dispatch(onGetClients());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCasesList({ limit: 10 }));

    //suppress depedency warning
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      country: selectCountry || "",
    },

    onSubmit: (note) => {
      dispatch(getNotesList(note));
    },
  });

  const loadCasesOptions = (inputValue) => {
    return new Promise((resolve) => {
      const casesList = getCases({ search: inputValue }).then((data) => {
        let { cases } = data;
        return cases;
      });
      resolve(casesList);
    });
  };

  const loadClientsOptions = (inputValue) => {
    return new Promise((resolve) => {
      const clientList = getClients({ search: inputValue }).then((data) => {
        let { client_obj_list } = data;
        const c = client_obj_list;
        return c;
      });
      resolve(clientList);
    });
  };

  const statusOptions = [
    { label: "Open", value: "Open" },
    { label: "Inprogress", value: "In Progress" },
    { label: "Completed", value: "Completed" },
  ];

  return (
    <Offcanvas direction="end" isOpen={show} id="offcanvasExample" toggle={onCloseClick}>
      <OffcanvasHeader className="bg-light" toggle={onCloseClick}>
        Notes Fliters
      </OffcanvasHeader>
      <PerfectScrollbar>
        <Form
          className="d-flex flex-column justify-content-end h-100"
          onSubmit={(e) => {
            e.preventDefault();
            form.handleSubmit();
            return false;
          }}
          onReset={() => {
            form.handleReset();
            form.handleSubmit();
          }}
        >
          <OffcanvasBody>
            <TextField
              md="12"
              mb="mb-3"
              value={form.values.title || ""}
              onChange={form.handleChange}
              validation={form}
              onBlur={form.handleBlur}
              placeholder="Enter title"
              label="Title"
              name="title"
            />
            <SelectField
              md="12"
              mb="mb-3"
              onChange={form.handleChange}
              validation={form}
              onBlur={form.handleBlur}
              placeholder="Select a priority..."
              label="Priority"
              name="priority"
              optional={true}
              options={[
                { label: "Urgent", value: "Urgent" },
                { label: "Normal", value: "Normal" },
                { label: "High", value: "High" },
                { label: "Medium", value: "Medium" },
                { label: "Low", value: "Low" },
              ]}
            />

            <SelectField
              md="12"
              mb="mb-3"
              onChange={form.handleChange}
              validation={form}
              onBlur={form.handleBlur}
              placeholder="Select a status..."
              label="Status"
              name="status"
              optional={true}
              options={statusOptions}
            />

            <Col md={12}>
              <Label className="form-label">case</Label>
              <div className="mb-3">
                <AsyncSelect
                  cacheOptions
                  id="assigned_to-input"
                  onBlur={form.handleBlur}
                  name="case"
                  placeholder="select case..."
                  isMulti={false}
                  isClearable
                  isSearchable
                  loadOptions={loadCasesOptions}
                  value={caseObj}
                  // noOptionsMessage={NoOptionsMessage}
                  defaultOptions={casesList}
                  getOptionLabel={(option) => `${option?.title}`}
                  // getOptionValue={(option) => option?.id}
                  onChange={(e) => {
                    setCaseObj(e);

                    form.setFieldValue("case", e?.id || "");
                  }}
                />
              </div>
            </Col>

            <Col md={12}>
              <Label className="form-label">client</Label>

              <div className="mb-3">
                <AsyncSelect
                  cacheOptions
                  id="assigned_to-input"
                  onBlur={form.handleBlur}
                  name="client"
                  placeholder="select client..."
                  isMulti={false}
                  isClearable
                  isSearchable
                  loadOptions={loadClientsOptions}
                  value={client}
                  // noOptionsMessage={NoOptionsMessage}
                  defaultOptions={clientsList}
                  getOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
                  getOptionValue={(option) => option?.id}
                  onChange={(e) => {
                    setClient(e);

                    form.setFieldValue("client", e?.id || "");
                  }}
                />
              </div>
            </Col>
          </OffcanvasBody>
          <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
            <button type="reset" className="btn btn-light w-100" onClick={onCloseClick}>
              Clear Filter
            </button>
            <button type="submit" className="btn btn-success w-100" onClick={onCloseClick}>
              Filters
            </button>
          </div>
        </Form>
      </PerfectScrollbar>
    </Offcanvas>
  );
};

export default CrmFilter;
