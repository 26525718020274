import React from "react";
import CountUp from "react-countup";
import { Card, CardBody, Col } from "reactstrap";

const Widgets = ({ stats }) => {
  const casesWidgets = [
    {
      id: 3,
      title: "Total Members",
      count: stats?.total_members,
      arrowIcon: "ri-arrow-up-line",
      percentage: "17.32 %",
      percentageClass: "success",
      icon: "ri-team-line ri-lg",
      decimals: 0,
      prefix: "",
      suffix: "",
    },

    {
      id: 1,
      title: "Total Active Members",
      count: stats?.total_active_members,
      arrowIcon: "ri-arrow-up-line",
      percentage: "17.32 %",
      percentageClass: "success",
      icon: "ri-user-follow-line ri-lg",
      decimals: 0,
      prefix: "",
      suffix: "",
    },

    // {
    //   id: 2,
    //   title: "Total In-Active Members",
    //   count: stats?.total_inactive_members,
    //   arrowIcon: "ri-arrow-up-line",
    //   percentage: "17.32 %",
    //   percentageClass: "success",
    //   icon: "ri-folder-user-line",
    //   decimals: 0,
    //   prefix: "",
    //   suffix: "",
    // },
    {
      id: 4,
      title: "Total Membership Types",
      count: stats?.membership_types,
      arrowIcon: "ri-arrow-up-line",
      percentage: "17.32 %",
      percentageClass: "success",
      icon: "ri-group-2-line ri-lg",
      decimals: 0,
      prefix: "",
      suffix: "",
    },

    {
      id: 5,
      title: "Estimated Total Sales",
      count: stats?.total_sales || 0,
      arrowIcon: "ri-arrow-up-line",
      percentage: "17.32 %",
      percentageClass: "success",
      icon: "ri-wallet-3-line ri-lg",
      decimals: 2,
      //prefix: "$ ",
      prefix: "$ ",
      suffix: "",
    },

    //{
    //id: 5,
    //title: "Pending Balance",
    //count: stats?.total_sales || 0,
    //arrowIcon: "ri-arrow-up-line",
    //percentage: "17.32 %",
    //percentageClass: "success",
    //icon: "ri-wallet-3-line ri-lg",
    //decimals: 0,
    ////prefix: "$ ",
    //prefix: `${stats?.currency} `.toUpperCase(),
    //suffix: "",
    //},

    //{
    //id: 4,
    //title: "Available Balance",
    //count: stats?.available_balance || 0,
    //arrowIcon: "ri-arrow-up-line",
    //percentage: "17.32 %",
    //percentageClass: "success",
    //icon: "ri-wallet-3-line ri-lg",
    //decimals: 0,
    //prefix: `${stats?.currency} `.toUpperCase(),
    //suffix: "",
    //},
  ];

  return (
    <React.Fragment>
      {(casesWidgets || []).map((item, key) => (
        <Col xxl={3} sm={6} key={key}>
          <Card className="card-animate">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="fw-medium text-muted mb-0">{item.title}</p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span className="counter-value" data-target="547">
                      <CountUp
                        start={0}
                        end={item.count}
                        duration={1}
                        suffix={item.suffix}
                        prefix={item.prefix}
                        decimals={item.decimals}
                      />
                    </span>
                  </h2>
                  {/* <p className="mb-0 text-muted">
                    <span
                      className={"badge bg-light text-" + (casesPercentage[key] > 0 ? "success" : "danger") + " mb-0"}
                    >
                      <i
                        className={"ri-arrow-" + (casesPercentage[key] > 0 ? "up" : "down") + "-line align-middle"}
                      ></i>{" "}
                      {casesPercentage[key]?.toFixed(2) + " %"}
                    </span>{" "}
                    vs. previous month
                  </p> */}
                </div>
                <div>
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-soft-info text-info rounded-circle fs-4">
                      <i className={item.icon}></i>
                    </span>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default Widgets;
