import React from "react";
import ReactApexChart from "react-apexcharts";

function getChartColorsArray(colors) {
  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
      if (color.indexOf("#") !== -1) color = color.replace(" ", "");
      if (color) return color;
      else return newValue;
    } else {
      var val = value.split(",");
      if (val.length === 2) {
        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
}

const ColumnChart = ({ data }) => {
  const dataColors = '["--vz-info"]';
  const chartColumnRotateLabelsColors = getChartColorsArray(dataColors);

  const series = [
    {
      name: "Total cases",
      data: data?.series || [],
    },
  ];
  var options = {
    chart: {
      height: 200,
      type: "bar",
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "40%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
    },
    colors: chartColumnRotateLabelsColors,
    xaxis: {
      labels: {
        rotate: -45,
      },
      categories: data?.labels || [],
      tickPlacement: "on",
    },
    yaxis: {
      title: {
        text: "Total cases",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [50, 0, 100],
      },
    },
  };

  return <ReactApexChart options={options} series={series} type="bar" height={200} />;
};

export { ColumnChart };
