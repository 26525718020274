import { useFormik } from "formik";
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Col, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap";
import * as Yup from "yup";
import { approvedOptions, statusOptions } from "../../../common/data/common";
import DateField from "../../../Components/Common/Fields/DateField";
import { getMemberships as onGetMembership } from "../../../store/actions";

const OffCanvasFilter = ({ show, onCloseClick }) => {
  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      joined_date: "",
      start_date: "",
      expiry_date: "",
      status: "",
      approved: "",
    },

    validationSchema: Yup.object({
      first_name: Yup.string().nullable(),
      last_name: Yup.string().nullable(),
      email: Yup.string().email("Please enter a valid email").nullable(),
      joined_date: Yup.date().nullable(),
      expiry_date: Yup.date().nullable(),
      start_date: Yup.date().nullable(),
      status: Yup.string().nullable(),
      approved: Yup.boolean().nullable(),
    }),

    onSubmit: (values) => {
      dispatch(onGetMembership(values));
      onCloseClick();
      return;
    },

    onReset: () => {
      // we could make a new search if needed
      //dispatch(onGetMembers());
    },
  });

  return (
    <Offcanvas
      direction="end"
      className="offcanvas-end border-0"
      isOpen={show}
      id="offcanvasExample"
      toggle={onCloseClick}
    >
      <OffcanvasHeader
        className="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header-dark"
        toggle={onCloseClick}
      >
        <span className="m-0 me-2 text-white">Membership Filters</span>
      </OffcanvasHeader>
      <PerfectScrollbar>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          onReset={() => {
            validation.handleReset();
          }}
          className="d-flex flex-column justify-content-end h-100"
        >
          <OffcanvasBody>
            <div className="mb-4">
              <Label htmlFor="name" className="form-label text-muted text-uppercase fw-semibold mb-3">
                Name
              </Label>
              <Row className="g-2 align-items-center">
                <div className="col-lg">
                  <Input
                    type="text"
                    id="first_name"
                    name="first_name"
                    className="form-control"
                    value={validation.values.first_name}
                    placeholder="Enter first name"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    onFocus={validation.handleFocus}
                  />
                </div>
                <div className="col-lg">
                  <Input
                    type="text"
                    id="last_name"
                    name="last_name"
                    className="form-control"
                    placeholder="Enter last name"
                    value={validation.values.last_name}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    onFocus={validation.handleFocus}
                  />
                </div>
              </Row>
            </div>
            <div className="mb-4">
              <Label htmlFor="datepicker-range" className="form-label text-muted text-uppercase fw-semibold mb-3">
                Email
              </Label>
              <Input
                type="text"
                id="email"
                name="email"
                className="form-control"
                placeholder="Enter email"
                value={validation.values.email}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                onFocus={validation.handleFocus}
              />
            </div>
            <div className="mb-4">
              <Row className="g-2 align-items-center">
                <div className="col-lg">
                  <DateField
                    name="start_date"
                    label="Start Date"
                    validation={validation}
                    value={validation.values.start_date}
                    onChange={validation.handleChange}
                  />
                </div>
                <div className="col-lg-auto">To </div>
                <div className="col-lg">
                  <DateField
                    name="expiry_date"
                    label="Expiry Date"
                    validation={validation}
                    value={validation.values.expiry_date}
                    onChange={validation.handleChange}
                  />
                </div>
              </Row>
            </div>
            <div className="mb-4">
              <Label htmlFor="approved-input" className="form-label text-muted text-uppercase fw-semibold mb-3">
                Approval
              </Label>
              <Row className="g-2" id="approved-input">
                <Col>
                  <Select
                    id="approved-input"
                    name="approved"
                    value={approvedOptions.find((v) => validation.values.approved === v.value) || ""}
                    options={approvedOptions}
                    onChange={(e) => {
                      validation.setFieldValue("approved", e.value);
                    }}
                  />
                </Col>
              </Row>
            </div>
            <div className="mb-4">
              <Label htmlFor="status-input" className="form-label text-muted text-uppercase fw-semibold mb-3">
                Status
              </Label>
              <Row className="g-2" id="status-input">
                <Col>
                  <Select
                    id="status-input"
                    name="status"
                    value={statusOptions.find((v) => validation.values.status === v.value) || ""}
                    options={statusOptions}
                    onChange={(e) => {
                      validation.setFieldValue("status", e.value);
                    }}
                  />
                </Col>
              </Row>
            </div>
            <div>
              <Row className="g-3"></Row>
            </div>
          </OffcanvasBody>

          <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
            <button type="reset" className="btn btn-light w-100">
              Clear Filters
            </button>
            <button type="submit" className="btn btn-success w-100">
              Filter
            </button>
          </div>
        </form>
      </PerfectScrollbar>
    </Offcanvas>
  );
};

export default OffCanvasFilter;
