import React from "react";
import { Col, Pagination, PaginationItem, PaginationLink } from "reactstrap";

const TabsPagination = (props) => {
  return (
    <Col lg={12}>
      <Pagination listClassName="justify-content-center" className="pagination-separated mb-0">
        <PaginationItem onClick={() => props.handleNext(props.pageNumber - 1)}>
          <PaginationLink to="#">
            <i className="mdi mdi-chevron-left" />
          </PaginationLink>
        </PaginationItem>
        <PaginationItem active>
          <PaginationLink to="#"> {props.pageNumber + 1} </PaginationLink>
        </PaginationItem>

        <PaginationItem onClick={() => props.handleNext(props.pageNumber + 1)}>
          <PaginationLink to="#">
            <i className="mdi mdi-chevron-right" />
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </Col>
  );
};

export default TabsPagination;
