import classnames from "classnames";
import React, { useState } from "react";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

//import images
import avatar1 from "../../../../assets/images/users/avatar-1.jpg";
import Profile from "./Profile";

const SettingsProfile = () => {
  document.title = "Profile Settings | Velzon - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <Profile />
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SettingsProfile;
