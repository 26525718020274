import {
  GET_TEAMS_LIST,
  GET_TEAM,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  ADD_NEW_TEAM_SUCCESS,
  ADD_NEW_TEAM_FAIL,
  ADD_NEW_TEAM,
  UPDATE_TEAM,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAIL,
  DELETE_TEAM,
  DELETE_TEAM_FAIL,
  DELETE_TEAM_SUCCESS,
  RESET_TEAM_FLAG,
} from "./actionType";

export const TeamsApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const TeamsApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getTeamsList = (params) => ({
  type: GET_TEAMS_LIST,
  payload: { params },
});

export const getTeam = (team) => ({
  type: GET_TEAM,
  payload: team,
});

export const updateTeam = (team) => ({
  type: UPDATE_TEAM,
  payload: team,
});

export const updateTeamSuccess = (team) => ({
  type: UPDATE_TEAM_SUCCESS,
  payload: team,
});

export const updateTeamFail = (team) => ({
  type: UPDATE_TEAM_FAIL,
  payload: team,
});

export const addNewTeam = (team) => ({
  type: ADD_NEW_TEAM,
  payload: team,
});

export const addNewTeamSuccess = (team) => ({
  type: ADD_NEW_TEAM_SUCCESS,
  payload: team,
});

export const addNewTeamFail = (error) => ({
  type: ADD_NEW_TEAM_FAIL,
  payload: error,
});

export const deleteTeam = (team) => ({
  type: DELETE_TEAM,
  payload: team,
});

export const deleteTeamSuccess = (team) => ({
  type: DELETE_TEAM_SUCCESS,
  payload: team,
});

export const deleteTeamFail = (error) => ({
  type: DELETE_TEAM_FAIL,
  payload: error,
});

export const resetTeamFlag = () => ({
  type: RESET_TEAM_FLAG,
});
