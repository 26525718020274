import {
  GET_DONATION,
  RESET_DONATION_FLAG,
  GET_DONATION_SUCCESS,
  REPORT_DELETE_SUCCESS,
  DONATION_LIST_SUCCESS,
  DELETE_DONATION,
  UPDATE_DONATION,
  UPDATE_DONATION_SUCCESS,
  CREATE_DONATION_SUCCESS,
  DELETE_DONATION_SUCCESS,
} from "./actionType";

const INIT_STATE = {
  context: null,
  donations: null,
  deleted: false,
};

const Donations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DONATION:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };

    case GET_DONATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        donationSuccess: true,
      };
    case DELETE_DONATION:
      return {
        ...state,
        loading: true,
        error: false,
        deleted: false,
      };
    case DELETE_DONATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        deleted: true,
        donations: state?.donations?.filter((donation) => donation.id.toString() !== action.payload.id.toString()),
      };

    case UPDATE_DONATION:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case CREATE_DONATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        created: true,
      };
    case UPDATE_DONATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        updateSuccess: true,
      };
    case REPORT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        deleted: true,
      };

    case DONATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        context: action.payload,
        donations: action.payload.donations,
        page: {
          count: action.payload.count,
          offset: action.payload.offset,
          per_page: action.payload.per_page,
        },
      };

    case RESET_DONATION_FLAG:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
        deleted: false,
        updateSuccess: false,
        created: false,
        donationSuccess: false,
      };

    default:
      return { ...state };
  }
};

export default Donations;
