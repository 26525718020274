import {
  GET_CLIENT,
  GET_CLIENTS_LIST,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  UPDATE_CLIENT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  DELETE_CLIENT,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL,
  ADD_NEW_CLIENT,
  ADD_NEW_CLIENT_SUCCESS,
  ADD_NEW_CLIENT_FAIL,
  RESET_CLIENT_FLAG,
  CREATE_CLIENT_COMMENT_SUCCESS,
  CREATE_CLIENT_COMMENT_FAIL,
  GET_COMMENT_LIST,
} from "./actionType";

const INIT_STATE = {
  clientsList: null,
  clientsComment: null,
};

const Clients = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CLIENTS_LIST:
          return {
            ...state,
            clientsList: action.payload.data.client_obj_list,
            page: action.payload.data.page,
            isClientCreated: false,
            isClientSuccess: true,
            fetchingClientList: false,
            clientListSuccess: true,
          };
        case GET_CLIENT:
          return {
            ...state,
            client: action.payload.data,
            isClientSuccess: true,
            isClientCreated: false,
          };
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_CLIENTS_LIST:
        case GET_CLIENT:
          return {
            ...state,
            error: action.payload.error,
            isClientCreated: false,
            isClientSuccess: false,
          };
        case ADD_NEW_CLIENT:
          return {
            ...state,
            error: action.payload.error,
            isClientCreated: false,
            isClientSuccess: false,
            isClientAddFail: true,
            loading: false,
          };
        default:
          return { ...state };
      }
    case ADD_NEW_CLIENT:
      return {
        ...state,
        loading: true,
      };
    case GET_CLIENTS_LIST: {
      return {
        ...state,
        fetchingClientList: true,
        clientListSuccess: false,
      };
    }

    case ADD_NEW_CLIENT_SUCCESS:
      return {
        ...state,
        isClientCreated: true,
        isClientAdd: true,
        isCaseAddFail: false,
        loading: false,
        error: false,
      };
    case ADD_NEW_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload,
        isClientAdd: false,
        isClientAddFail: true,
        loading: false,
      };
    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        isClientUpdate: true,
        isClientUpdateFail: false,
        loading: false,
      };
    case UPDATE_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload,
        isClientUpdate: true,
        isClientUpdateFail: true,
        loading: false,
      };
    case DELETE_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload,
        isClientDelete: false,
        isClientDeleteFail: true,
        loading: false,
      };
    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        clientsList: state.clientsList.filter((client) => client.id.toString() !== action.payload.client.toString()),
        isClientDelete: true,
        isClientDeleteFail: false,
        loading: false,
      };
    case CREATE_CLIENT_COMMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        isCommentAdd: false,
        isCommentAddFail: true,
        loading: false,
      };
    case CREATE_CLIENT_COMMENT_SUCCESS:
      return {
        ...state,
        isCommentAdd: true,
        isCommentAddFail: false,
        loading: false,
      };
    case RESET_CLIENT_FLAG:
      return {
        ...state,
        isClientAdd: false,
        isClientAddFail: false,
        isClientCreated: false,
        isCommentAdd: false,
        isCommentAddFail: false,
        isClientDelete: false,
        isClientDeleteFail: false,
        isClientUpdate: false,
        isClientUpdateFail: false,
        errors: false,
        error: false,
      };
    default:
      return { ...state };
  }
};

export default Clients;
