import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import * as yup from "yup";
import dummyImg from "../../../../assets/images/users/user-dummy-img.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  editProfile,
  profileChangePassword as onChangePassword,
  resetProfileFlag,
} from "../../../../store/actions";
import { api } from "../../../../config";
import MsgToast from "../../../../Components/Common/MsgToast";
import { withRouter } from "react-router-dom";

const AccountProfile = () => {
  const [userProfile, setUserProfile] = useState();
  const [files, setFiles] = useState(null);
  const dispatch = useDispatch();
  const { profile, passwordChangeSuccess, success, editProfileSuccess, passwordChangeFail } = useSelector((state) => ({
    profile: state.Profile.user,
    passwordChangeSuccess: state.Profile.passwordChangeSuccess,
    success: state.Profile.success,
    passwordChangeFail: state.Profile.passwordChangeFail,
    editProfileSuccess: state.Profile.editProfileSuccess,
  }));

  useEffect(() => {
    if (!profile) dispatch(getProfile());
    if (profile) setUserProfile(profile);
  }, [dispatch, profile]);

  useEffect(() => {
    if (passwordChangeSuccess || success || passwordChangeFail || editProfileSuccess) {
      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success, passwordChangeSuccess, passwordChangeFail, editProfileSuccess]);

  const infoForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: userProfile?.id || "",
      username: userProfile?.user_details?.username || "",
      first_name: userProfile?.user_details?.first_name || "",
      last_name: userProfile?.user_details?.last_name || "",
      email: userProfile?.user_details?.email || "",
      website: userProfile?.user_details?.website || "",
    },
    validationSchema: yup.object({
      id: yup.string(),
      username: yup.string().notRequired(),
      first_name: yup.string().notRequired(),
      last_name: yup.string().notRequired(),
      email: yup.string().notRequired(),
      website: yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      const user = values;
      const userFormData = new FormData();
      // if (!files[0]) delete user.profile_pic;

      for (let v in user) {
        if (v === "profile_pic") continue;
        userFormData.set(v, user[v]);
      }
      if (files) userFormData.set("profile_pic", files[0]);

      dispatch(editProfile(userFormData));

      infoForm.setSubmitting(false);
    },
  });
  const passwordForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      old_password: "",
      new_password: "",
      retype_password: "",
    },
    validationSchema: yup.object({
      old_password: yup.string().required("Please enter your current password"),
      new_password: yup
        .string()
        .required("Enter a new password")
        .min(8, "8 Minimum Characters")
        .max(25, "25 Maximum Characters")
        .matches(/^(?=.*[a-z])/, "Must container one lowercase character"),
      // .matches(/^(?=.*[A-Z])/, "Must container one uppercase character")
      // .matches(/^(?=.*[0-9])/, "Must container one number")
      // .matches(/^(?=.*[!@#$%^&*+])/, "Must container one special character"),
      retype_password: yup
        .string()
        .required("Please confirm your new password")
        .oneOf([yup.ref("new_password"), null], "Passwords do not match"),
    }),
    onSubmit: (values) => {
      dispatch(onChangePassword(values));
      passwordForm.setSubmitting(false);
    },
  });

  return (
    <React.Fragment>
      <Container className="py-3">
        {/* Change User Details */}
        <Row className="">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              infoForm.handleSubmit();
            }}
          >
            <Row className="mb-3">
              <CardHeader className="mb-0 border-0 pb-0">
                <h2 className="title mb-0">Profile Photo</h2>
              </CardHeader>
              <CardBody className="p-4 py-2">
                <Row className="py-2 justify-conent-start align-items-center">
                  <Col md={2}>
                    <div className="profile-user position-relative d-inline-block">
                      <img
                        src={userProfile?.user_details?.profile_pic || dummyImg}
                        className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                        alt="user-profile"
                      />
                      <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                        <Input
                          id="profile-img-file-input"
                          type="file"
                          accept="image/png, image/gif, image/jpeg image/jpg"
                          onChange={(e) => setFiles(e.target.files)}
                          className="profile-img-file-input"
                        />
                        <Label htmlFor="profile-img-file-input" className="profile-photo-edit avatar-xs">
                          <span className="avatar-title rounded-circle bg-light text-body">
                            <i className="ri-camera-fill"></i>
                          </span>
                        </Label>
                      </div>
                    </div>
                  </Col>

                  <Col className="justify-content-center">
                    <Row className="flex-grow-1">
                      <h5>Upload your photo ...</h5>
                      <h5>Click on the camera icon to add one</h5>
                      {/*<h5>Photo should be at least 300px × 300px</h5> */}
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          type="submit"
                          name="submit"
                          disabled={infoForm.isSubmitting}
                          onClick={() => infoForm.handleSubmit()}
                          className="btn btn-success text-white bg-success border-0 px-3 py-2 fs-14"
                        >
                          Upload Photo
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Row>
          </Form>
        </Row>

        <Row className="d-flex">
          {/* Change User Details */}
          <Col md={6} xxl={6} className="">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                infoForm.handleSubmit();
              }}
            >
              <Row className="px-2">
                <CardHeader className="mb-0 border-0 pb-0">
                  <h2 className="title mb-0">Basic Information</h2>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="first-name-input">
                          Username
                        </Label>
                        <Input
                          type="text"
                          name="username"
                          value={infoForm.values.username}
                          onChange={infoForm.handleChange}
                          className="form-control"
                          id="username-input"
                          invalid={infoForm.touched.username && infoForm.errors.username ? true : false}
                          placeholder="Username"
                        />
                        {infoForm.touched.username && infoForm.errors.username ? (
                          <FormFeedback type="invalid">{infoForm.errors.username}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="first-name-input">
                          First Name
                        </Label>
                        <Input
                          type="text"
                          name="first_name"
                          value={infoForm.values.first_name}
                          onChange={infoForm.handleChange}
                          className="form-control"
                          id="first-name-input"
                          invalid={infoForm.touched.first_name && infoForm.errors.first_name ? true : false}
                          placeholder="First Name"
                        />
                        {infoForm.touched.first_name && infoForm.errors.first_name ? (
                          <FormFeedback type="invalid">{infoForm.errors.first_name}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="last-name-input">
                          Last Name
                        </Label>
                        <Input
                          type="text"
                          value={infoForm.values.last_name}
                          onChange={infoForm.handleChange}
                          name="last_name"
                          className="form-control"
                          invalid={infoForm.touched.last_name && infoForm.errors.last_name ? true : false}
                          id="last-name-input"
                          placeholder="Last Name"
                        />
                        {infoForm.touched.last_name && infoForm.errors.last_name ? (
                          <FormFeedback type="invalid">{infoForm.errors.last_name}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="email-input">
                          Email
                        </Label>
                        <Input
                          type="text"
                          name="email"
                          value={infoForm.values.email}
                          onChange={infoForm.handleChange}
                          className="form-control"
                          id="email-input"
                          invalid={infoForm.touched.email && infoForm.errors.email ? true : false}
                          placeholder="Email"
                        />
                        {infoForm.touched.email && infoForm.errors.email ? (
                          <FormFeedback type="invalid">{infoForm.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="website-input">
                          Website
                        </Label>
                        <Input
                          type="text"
                          name="website"
                          value={infoForm.values.website}
                          onChange={infoForm.handleChange}
                          className="form-control"
                          id="website-input"
                          invalid={infoForm.touched.website && infoForm.errors.website ? true : false}
                          placeholder="Website"
                        />
                        {infoForm.touched.website && infoForm.errors.website ? (
                          <FormFeedback type="invalid">{infoForm.errors.website}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12} className="mt-2 mb-2">
                      <Button
                        disabled={infoForm.isSubmitting}
                        type="submit"
                        // onClick={() => infoForm.handleSubmit()}
                        name="submit"
                        className="btn btn-success text-white bg-success border-0 px-5 py-2 fs-14"
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Row>
            </Form>
          </Col>

          {/* Change Password */}
          <Col md={6} xxl={6} className="">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                passwordForm.handleSubmit();
              }}
            >
              <Row className="px-2">
                <CardHeader className="mb-0 border-0 pb-0">
                  <h2 className="title mb-0">Change Password</h2>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="old_password-input">
                          Verify Current Password
                        </Label>
                        <Input
                          type="text"
                          name="old_password"
                          value={passwordForm.values.old_password}
                          onChange={passwordForm.handleChange}
                          className="form-control"
                          id="old_password-input"
                          invalid={passwordForm.touched.old_password && passwordForm.errors.old_password ? true : false}
                          placeholder="Current Password"
                        />
                        {passwordForm.touched.old_password && passwordForm.errors.old_password ? (
                          <FormFeedback type="invalid">{passwordForm.errors.old_password}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="new_password-input">
                          New Password
                        </Label>
                        <Input
                          type="text"
                          name="new_password"
                          value={passwordForm.values.new_password}
                          onChange={passwordForm.handleChange}
                          className="form-control"
                          id="new_password-input"
                          invalid={passwordForm.touched.new_password && passwordForm.errors.new_password ? true : false}
                          placeholder="New Password"
                        />
                        {passwordForm.touched.new_password && passwordForm.errors.new_password ? (
                          <FormFeedback type="invalid">{passwordForm.errors.new_password}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Row>
                      <Col>
                        <div className="mb-3">
                          <div className="d-flex">
                            <div className="flex-shrink-0">
                              <i className="ri-checkbox-circle-fill text-success"></i>
                            </div>
                            <div className="flex-grow-1 ms-2">One Lowercase Character</div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="d-flex">
                            <div className="flex-shrink-0">
                              <i className="ri-checkbox-circle-fill text-success"></i>
                            </div>
                            <div className="flex-grow-1 ms-2">One Uppercase Character</div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="d-flex">
                            <div className="flex-shrink-0">
                              <i className="ri-checkbox-circle-fill text-success"></i>
                            </div>
                            <div className="flex-grow-1 ms-2">One Number</div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <div className="d-flex">
                            <div className="flex-shrink-0">
                              <i className="ri-checkbox-circle-fill text-success"></i>
                            </div>
                            <div className="flex-grow-1 ms-2">One Special Character</div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="d-flex">
                            <div className="flex-shrink-0">
                              <i className="ri-checkbox-circle-fill text-success"></i>
                            </div>
                            <div className="flex-grow-1 ms-2">8 Characters Minimum</div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="d-flex">
                            <div className="flex-shrink-0">
                              <i className="ri-checkbox-circle-fill text-success"></i>
                            </div>
                            <div className="flex-grow-1 ms-2">50 Characters Maximum</div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Col sm={12}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="retype_password-input">
                          Confirm New Password
                        </Label>
                        <Input
                          type="text"
                          value={passwordForm.values.retype_password}
                          onChange={passwordForm.handleChange}
                          name="retype_password"
                          className="form-control"
                          invalid={
                            passwordForm.touched.retype_password && passwordForm.errors.retype_password ? true : false
                          }
                          id="retype_password-input"
                          placeholder="Confirm New Password"
                        />
                        {passwordForm.touched.retype_password && passwordForm.errors.retype_password ? (
                          <FormFeedback type="invalid">{passwordForm.errors.retype_password}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={12} className="mt-2 mb-2">
                      <Button
                        disabled={passwordForm.isSubmitting}
                        // onClick={() => passwordForm.handleSubmit()}
                        type="submit"
                        name="submit"
                        className="btn btn-success text-white bg-success border-0 px-5 py-2 fs-14"
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>

                  {passwordChangeSuccess ? (
                    <MsgToast msg="password updated successfully" color="success" icon="ri-error-warning-line" />
                  ) : null}
                  {editProfileSuccess ? (
                    <MsgToast msg="Profile updated successfully" color="success" icon="ri-error-warning-line" />
                  ) : null}
                  {passwordChangeFail ? (
                    <MsgToast
                      msg={
                        passwordChangeFail && passwordChangeFail?.old_password && passwordChangeFail?.old_password[0]
                      }
                      color="danger"
                      icon="ri-error-warning-line"
                    />
                  ) : null}

                  {passwordChangeFail ? (
                    <MsgToast
                      msg={
                        passwordChangeFail &&
                        passwordChangeFail?.non_field_errors &&
                        passwordChangeFail?.non_field_errors[0]
                      }
                      color="danger"
                      icon="ri-error-warning-line"
                    />
                  ) : null}
                </CardBody>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(AccountProfile);
