import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import TextField from "../../../Components/Common/Fields/TextField.js";
import AsyncSelect from "react-select/async";

// Import Images
// import dummyImg from "../../assets/images/users/user-dummy-img.jpg";

import MultiSelectField from "../../../Components/Common/Fields/MultiSelectField";
import SelectField from "../../../Components/Common/Fields/SelectField.js";

import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";

//Import actions
import {
  addNewService,
  deleteService,
  getServicesList,
  getUsersList as onGetUsers,
  resetServiceFlag,
  updateService,
} from "../../../store/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";

// Formik
import { useFormik } from "formik";
import * as Yup from "yup";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Components/Common/Loader";
import MsgToast from "../../../Components/Common/MsgToast";
import { getLoggedInUser } from "../../../helpers/jwt-token-access/auth-token-header.js";
import { getUsers } from "../../../helpers/backend_helper.js";
import { debounce } from "lodash";

const ServicesList = () => {
  const dispatch = useDispatch();

  const {
    isServiceCreated,
    isServiceAddFail,
    isServiceDelete,
    isServiceDeleteFail,
    isServiceUpdate,
    isServiceUpdateFail,
    usersList,
    servicesList,
    page,
    isServiceAdd,

    error,
  } = useSelector((state) => ({
    isTeamCreated: state.Teams.isTeamsCreated,

    usersList: state.Users.usersList,
    servicesList: state.Services.servicesList,
    page: state.Services.page,
    isServiceCreated: state.Services.isServiceCreated,
    isServiceAdd: state.Services.isServiceAdd,

    isServiceAddFail: state.Services.isServiceAddFail,
    isServiceDelete: state.Services.isServiceDelete,
    isServiceDeleteFail: state.Services.isServiceDeleteFail,
    isServiceUpdate: state.Services.isServiceUpdate,
    isServiceUpdateFail: state.Services.isServiceUpdateFail,

    error: state.Services.error,

    isTeamAdd: state.Teams.isTeamAdd,
    isTeamAddFail: state.Teams.isTeamAddFail,
    isTeamDelete: state.Teams.isTeamDelete,
    isTeamDeleteFail: state.Teams.isTeamDeleteFail,
    isTeamUpdate: state.Teams.isTeamUpdate,
    isTeamUpdateFail: state.Teams.isTeamUpdateFail,
  }));

  const [isEdit, setIsEdit] = useState(false);
  const [service, setService] = useState(null);
  const [serviceStatus, setServiceStatus] = useState(null);
  const [serviceMembers, setServiceMembers] = useState(null);

  //delete service
  const [deleteModal, setDeleteModal] = useState(false);
  const [modal, setModal] = useState(false);

  const [sortBy, setsortBy] = useState(null);
  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  let toggle;

  useEffect(() => {
    if (!usersList) dispatch(onGetUsers());
    if (!servicesList) dispatch(getServicesList());

    setTimeout(() => {
      dispatch(resetServiceFlag());
    }, 3000);
  }, [
    dispatch,
    isServiceCreated,
    isServiceAddFail,
    isServiceDelete,
    isServiceDeleteFail,
    isServiceUpdate,
    isServiceUpdateFail,
    usersList,
    servicesList,
  ]);

  // Update Data
  const handleServiceClick = useCallback(
    (service) => {
      setService(service);
      setServiceStatus({ label: service.status, value: service.status });
      setServiceMembers(service?.members);
      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(getServicesList({ offset, limit }));
    },
    [dispatch]
  );

  // Add Data
  const handleServiceClicks = () => {
    setService("");
    setIsEdit(false);
    toggle();
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return (
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="checkAll" value="option1" />
            </div>
          );
        },
      },

      {
        Header: "Service Name",
        accessor: "name",
        filterable: true,
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        Cell: (service) => (
          <>
            {service.row.original.status === "active" ? (
              <span className={"badge badge-soft-success me-1"}>Active</span>
            ) : (
              <span className={"badge badge-soft-danger me-1"}>In-Active</span>
            )}
          </>
        ),
      },

      {
        Header: "Created By",
        accessor: "created_by",
        filterable: false,
        Cell: (cellProps) => {
          const user = cellProps.row.original?.created_by?.user_details;
          if (!user) return;
          const { first_name, last_name } = user;
          return <>{`${first_name} ${last_name}`}</>;
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Message">
                <Link
                  to="#"
                  onClick={() => {
                    const serviceData = cellProps.row.original;
                    handleServiceClick(serviceData);
                  }}
                  className="text-muted d-inline-block"
                >
                  <i className="ri-edit-2-fill align-bottom me-2"></i>Edit
                </Link>
              </li>
              <li className="list-inline-item edit" title="Message">
                <Link
                  to="#"
                  onClick={() => {
                    const serviceData = cellProps.row.original;
                    onClickDelete(serviceData);
                  }}
                  className="text-muted d-inline-block"
                >
                  <i className="ri-delete-bin-fill align-bottom me-2"></i>Delete
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleServiceClick]
  );

  const handlesortBy = (sortBy) => {
    setsortBy(sortBy);
  };

  const sortbyname = [
    { label: "Owner", value: "Owner" },
    { label: "Company", value: "Company" },
    { label: "Location", value: "Location" },
  ];

  const created_onOptions = [
    { label: "Active", value: "active" },
    { label: "In-Active", value: "in-active" },
  ];

  // Delete Data
  const handleDeleteService = () => {
    if (service) {
      dispatch(deleteService(service));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (service) => {
    setService(service);
    setDeleteModal(true);
  };

  const statusOptions = [
    { label: "Active", value: "active" },
    { label: "In-Active", value: "in-active" },
  ];

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      ...(isEdit && { id: service && service.id }),
      //is_account_new: newData,
      name: (service && service.name) || "",
      status: (service && service.status) || "",
      members: (service && JSON.stringify(service?.users?.map((t) => t.id))) || [],
      description: (service && service.description) || "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter A First name"),
      members: Yup.mixed().notRequired("Please Assign member to a service"),
      description: Yup.string().required("Please Enter Description"),
    }),

    onSubmit: (values) => {
      if (isEdit) {
        dispatch(updateService(values));
      } else {
        dispatch(addNewService(values));
      }

      validation.resetForm();
      toggle();
    },
  });

  toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setService(null);
      setServiceMembers([]);
      setServiceStatus(null);
      setIsEdit(false);
      setTag([]);
      setAssignTag([]);
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleAssignMembers = (userslabels) => {
    const assigned = userslabels.map((item) => item.value);
    const assignedStringified = JSON.stringify(assigned);
    validation.setFieldValue("members", assignedStringified);
  };

  const loadUsersOptions = (inputValue) => {
    return new Promise((resolve) => {
      const orgList = getUsers({ search: inputValue }).then(({ users: { users } }) => {
        return users;
      });
      resolve(orgList);
    });
  };

  const handleFieldSearch = useCallback(
    debounce((value) => {
      dispatch(getServicesList({ search: value }));
    }, 1500),
    []
  );

  document.title = "Services | SimplyNP";
  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteService}
          onCloseClick={() => setDeleteModal(false)}
        />
        <Container fluid>
          <BreadCrumb title="List Services" pageTitle="Services" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-1">
                      <button
                        className="btn btn-info add-btn"
                        onClick={() => {
                          setModal(true);
                        }}
                      >
                        <i className="ri-add-fill me-1 align-bottom"></i> Add Service
                      </button>
                    </div>
                    <div className="flex-shrink-0">
                      {/*
                      <div className="hstack text-nowrap gap-2">
                        <button
                          className="btn btn-soft-danger"
                          // onClick="deleteMultiple()"
                        >
                          <i className="ri-delete-bin-2-line"></i>
                        </button>
                        <button className="btn btn-danger">
                          <i className="ri-filter-2-line me-1 align-bottom"></i> Filters
                        </button>
                        <button className="btn btn-soft-success">Import</button>

                        <UncontrolledDropdown>
                          <DropdownToggle href="#" className="btn btn-soft-info" tag="button">
                            <i className="ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" href="#">
                              All
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Week
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Month
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Year
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      */}
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col>
              <Card id="serviceList">
                <CardHeader>
                  <Row className="g-3">
                    <Col md={4}>
                      <div className="search-box">
                        <Input
                          type="text"
                          className="form-control search"
                          onChange={(e) => handleFieldSearch(e.target.value)}
                          placeholder="Search for service..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    <div className="col-md-auto ms-auto">
                      <div className="d-flex align-items-center gap-2">
                        <span className="text-muted">Sort by: </span>
                        <Select
                          className="mb-0"
                          value={sortBy}
                          onChange={() => {
                            handlesortBy();
                          }}
                          options={sortbyname}
                          id="choices-single-default"
                        ></Select>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    {servicesList ? (
                      <TableContainer
                        columns={columns}
                        data={servicesList || []}
                        isGlobalFilter={false}
                        isAddServiceList={false}
                        customPageSize={10}
                        customFetchData={fetchData}
                        customTotalSize={page.count}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        //handleServiceClick={handleServiceClicks}
                      />
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>

                  <Modal id="showModal" size="lg" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-soft-info p-3" toggle={toggle}>
                      {!!isEdit ? "Edit Service" : "Add Service"}
                    </ModalHeader>

                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <Input type="hidden" id="id-field" />
                        <Row className="g-3">
                          <SelectField
                            md="3"
                            mb="mb-3"
                            onChange={validation.handleChange}
                            defaultValue={serviceStatus}
                            validation={validation}
                            onBlur={validation.handleBlur}
                            placeholder="select..."
                            label="Status"
                            name="status"
                            options={statusOptions}
                          />

                          <TextField
                            md="9"
                            mb="mb-3"
                            value={validation.values.name}
                            onChange={validation.handleChange}
                            validation={validation}
                            onBlur={validation.handleBlur}
                            placeholder="Enter service name"
                            label="Service name"
                            name="name"
                            errorMessage={error && error.errors}
                          />

                          <Col lg={12}>
                            <div>
                              <Label htmlFor="status-field" className="form-label font-size-13">
                                Members
                              </Label>
                              <AsyncSelect
                                name="members"
                                id="members-field"
                                placeholder="Select..."
                                className="mb-0"
                                isClearable
                                isMulti
                                loadOptions={loadUsersOptions}
                                getOptionValue={(option) => option?.id}
                                getOptionLabel={(option) =>
                                  `${option?.user_details.first_name} ${option?.user_details.last_name}`
                                }
                                defaultValue={serviceMembers}
                                value={serviceMembers}
                                key={serviceMembers}
                                defaultOptions={usersList}
                                onChange={(e) => {
                                  setServiceMembers(e);
                                  validation.setFieldValue(
                                    "members",
                                    e.map((e) => e?.id)
                                  );
                                }}
                                onBlur={validation.handleBlur}
                              />
                              {validation.touched.team_lead && validation.errors.team_lead ? (
                                <FormFeedback type="invalid">{validation.errors.team_lead}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          {/* Description */}
                          <Col lg={12}>
                            <div>
                              <Label htmlFor="description-field" className="form-label">
                                Description
                              </Label>
                              <Input
                                name="description"
                                id="description-field"
                                className="form-control"
                                placeholder="Enter Service Description"
                                type="textarea"
                                rows="3"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                invalid={validation.touched.description && validation.errors.description ? true : false}
                              />
                              {validation.touched.description && validation.errors.description ? (
                                <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            {" "}
                            Close{" "}
                          </button>
                          <button type="submit" className="btn btn-success" id="add-btn">
                            {" "}
                            {!!isEdit ? "Update" : "Add Service"}{" "}
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  {isServiceCreated ? (
                    <MsgToast msg="Service created  Successfully" color="success" icon="ri-checkbox-circle-line" />
                  ) : null}

                  {isServiceAddFail ? (
                    <MsgToast msg="Service Added Failed" color="danger" icon="ri-error-warning-line" />
                  ) : null}
                  {isServiceDelete ? (
                    <MsgToast msg="Service Deleted Successfully" color="success" icon="ri-checkbox-circle-line" />
                  ) : null}
                  {isServiceDeleteFail ? (
                    <MsgToast msg="Service Deleted Failed" color="danger" icon="ri-error-warning-line" />
                  ) : null}
                  {isServiceUpdate ? (
                    <MsgToast msg="Service Updated Successfully" color="success" icon="ri-checkbox-circle-line" />
                  ) : null}
                  {isServiceUpdateFail ? (
                    <MsgToast msg="Service Updated Failed" color="danger" icon="ri-error-warning-line" />
                  ) : null}
                  <ToastContainer limit={1} closeButton={false} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ServicesList;
