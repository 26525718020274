import React from "react";
import { Col, Label, FormFeedback } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { handleValidDate } from "../../../apps/cases/ListView/CaseCol";

const DateField = (props) => {
  return (
    <Col md={props.md}>
      <div className="mb-3">
        {props?.label ? (
          <Label htmlFor={props.name} className="form-label">
            {props.label || props.name}
          </Label>
        ) : null}

        <Flatpickr
          disabled={props?.disabled}
          name={props.name}
          id="date-field"
          className={`${
            (props.validation.touched[props.name] && props.validation.errors[props.name]) ||
            (props.errorMessage && props.errorMessage[props.name])
              ? "form-control is-invalid"
              : ""
          } `}
          placeholder={props?.placeholder || "Select a date"}
          options={{
            altInput: true,
            altFormat: "Y-m-d",
            dateFormat: "Y-m-d",
          }}
          onChange={(e) => props.validation.setFieldValue(`${props.name}`, handleValidDate(e))}
          value={props.validation.values[props.name] || ""}
        />
        {props.validation.touched[props.name] && props.validation.errors[props.name] ? (
          <FormFeedback type="invalid">{props.validation.errors[props.name]}</FormFeedback>
        ) : null}

        {props.errorMessage && props.errorMessage[props.name] && props.errorMessage[props.name].length > 0 ? (
          <FormFeedback type="invalid">
            <div>{props.errorMessage[props.name]}</div>
          </FormFeedback>
        ) : (
          ""
        )}
      </div>
    </Col>
  );
};

export default DateField;
