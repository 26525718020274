import React, { useEffect, useState } from "react";
import countries from "../../../common/data/countries.json";

// Import Images
import dummyImg from "../../../assets/images/users/user-dummy-img.jpg";

import { useParams } from "react-router-dom";
import Select from "react-select";
// import { Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, ModalFooter, Row } from "reactstrap";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  ModalFooter,
  Row,
} from "reactstrap";

import BreadCrumb from "../../../Components/Common/BreadCrumb";

//Import actions
import {
  addNewUser as onAddNewUser,
  getUsersList as onGetUsers,
  updateUser as onUpdateUser,
  resetUserFlag,
} from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";

// Formik
import { useFormik } from "formik";
import * as Yup from "yup";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IntNumberInput from "../../../Components/Common/Fields/IntNumberInput";
import MsgToast from "../../../Components/Common/MsgToast";

const UsersList = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [user, setUser] = useState({});
  const [files, setFiles] = useState([]);
  const [countryState, setCountryState] = useState(null);

  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);
  const [assignUser, setAssignUser] = useState([]);

  const {
    usersList,
    isUserCreated,
    isUserSuccess,
    isUserDelete,
    isUserDeleteFail,
    isUserUpdate,
    isUserUpdateFail,
    error,
    isUserAdd,
    isUserAddFail,
  } = useSelector((state) => ({
    isUserCreated: state.Users.isUsersCreated,
    isUserSuccess: state.Users.isUserSuccess,
    usersList: state.Users.usersList,
    error: state.Users.error,
    isUserAdd: state.Users.isUserAdd,
    isUserAddFail: state.Users.isUserAddFail,
    isUserDelete: state.Users.isUserDelete,
    isUserDeleteFail: state.Users.isUserDeleteFail,
    isUserUpdate: state.Users.isUserUpdate,
    isUserUpdateFail: state.Users.isUserUpdateFail,
  }));

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetUserFlag());
    }, 3000);
  }, [
    dispatch,
    isUserSuccess,
    error,
    isUserAdd,
    isUserAddFail,
    isUserDelete,
    isUserDeleteFail,
    isUserUpdate,
    isUserUpdateFail,
  ]);

  useEffect(() => {
    if (props.updateUser) {
      if (usersList && usersList.length) {
        let user = usersList.find((user) => user.id.toString() === id);
        if (!user) return;

        let c = countries.find((c) => c.value === user?.address?.country);
        setCountryState(c);

        const supervisorlabels = user?.assigned_to?.map((user) => ({
          label: `${user.user_details.first_name} ${user.user_details.last_name}`,
          value: user.id,
        }));
        setAssignUser(supervisorlabels);

        setUser(user);
      }
    }

    if (!usersList) {
      dispatch(onGetUsers());
    }
  }, [dispatch, id, usersList, props.updateUser]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: user?.id || "",
      profile_pic: user?.user_details?.profile_pic || "",

      role: user?.role || "USER",
      first_name: user?.user_details?.first_name || "",
      last_name: user?.user_details?.last_name || "",
      email: user?.user_details?.email || "",
      phone: user?.phone || "",
      alternate_phone: user?.alternate_phone || "",
      alternate_email: user?.user_details?.alternate_email || "",

      address_line: user?.address?.address_line || "",
      street: user?.address?.street || "",
      city: user?.address?.city || "",
      state: user?.address?.state || "",
      postcode: user?.address?.postcode || "",
      country: user?.address?.country || "",

      // reports_to: user.reports_to) || [],
      status: user?.is_active || true,
      description: user?.user_details?.description || "",

      password: "",
      // organization: user.organization) || '',
      // title: user.title) || '',
      has_sales_access: user?.has_sales_access || "",
      has_marketing_access: user?.has_marketing_access || "",
      is_organization_admin: user?.is_organization_admin || "",
    },

    validationSchema: Yup.object({
      // userId: Yup.string().required("Please Enter User Id"),
      profile_pic: Yup.mixed().notRequired(),

      role: Yup.string().required("Please Enter A Role"),
      first_name: Yup.string().required("Please Enter A First Name"),
      last_name: Yup.string().required("Please Enter A Last Name"),
      phone: Yup.string().notRequired("Please Enter Phone Number"),
      alternate_phone: Yup.string().notRequired("Please Enter Phone Number"),
      email: Yup.string().email("Please enter a valid email").required("Please Enter An Email"),
      alternate_email: Yup.string().email("Please enter a valid email").notRequired("Please Enter An Alternate Email"),

      address_line: Yup.string().notRequired("Please enter user address line"),
      street: Yup.string().notRequired("Please enter user street"),
      city: Yup.string().notRequired("Please enter user city"),
      state: Yup.string().notRequired("Please enter user state"),
      postcode: Yup.string().notRequired("Please enter user postcode"),
      country: Yup.string().notRequired("Please enter user country"),

      // reports_to: Yup.mixed().notRequired("Please state who the user reports  to"),
      status: Yup.boolean().required("Define a status for the user"),
      description: Yup.string().notRequired("Please enter a description for the user"),
      password: !id
        ? Yup.string().required("Please enter a default password for the user")
        : Yup.string().notRequired(),

      has_sales_access: Yup.boolean().notRequired(),
      has_marketing_access: Yup.boolean().notRequired(),
      is_organization_admin: Yup.boolean().notRequired(),
    }),

    onSubmit: (values) => {
      const user = values;

      const userFormData = new FormData();
      // if (!files[0]) delete user.profile_pic;

      for (let v in user) {
        if (v === "profile_pic") continue;
        userFormData.set(v, user[v]);
      }
      if (files[0]) userFormData.set("profile_pic", files[0]);

      if (props.updateUser || id) {
        dispatch(onUpdateUser(userFormData));
      } else {
        dispatch(onAddNewUser(userFormData));
        validation.resetForm();
      }
    },
  });

  const handlestag = (status) => {
    setTag(status);
    const assigned = status.map((item) => item.value);
    setAssignTag(assigned);
  };

  const handleAssignUser = (userslabels) => {
    const assigned = userslabels.map((item) => item.value);
    const stringified = JSON.stringify(assigned);
    validation.setFieldValue("reports_to", stringified);
    // setAssignUser(assigned);
  };

  document.title = "Users | SimplyNP";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={`${props.updateUser ? "Update" : "Create"} User `} pageTitle="Users" />
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Input type="hidden" id="id-field" />

            <Row>
              <Col lg={8}>
                {/* Personal Information */}
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Personal Information</h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="first-name-input">
                            First Name
                          </Label>
                          <Input
                            name="first_name"
                            id="first_name-field"
                            className="form-control"
                            placeholder="Enter First Name"
                            type="text"
                            validate={{ required: { value: true } }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.first_name || ""}
                            invalid={validation.touched.first_name && validation.errors.first_name ? true : false}
                          />
                          {validation.touched.first_name && validation.errors.first_name ? (
                            <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="last-name-input">
                            Last Name
                          </Label>
                          <Input
                            name="last_name"
                            id="last_name-field"
                            className="form-control"
                            placeholder="Enter Last Name"
                            type="text"
                            validate={{ required: { value: true } }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.last_name || ""}
                            invalid={validation.touched.last_name && validation.errors.last_name ? true : false}
                          />
                          {validation.touched.last_name && validation.errors.last_name ? (
                            <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <div className="mb-3">
                        <Label htmlFor="email-field" className="form-label">
                          Primary Email ID
                        </Label>

                        <Input
                          name="email"
                          id="email-field"
                          className="form-control"
                          placeholder="Enter Primary Email"
                          type="text"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={validation.touched.email && validation.errors.email ? true : false}
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                    </Row>

                    <Row>
                      <IntNumberInput
                        md="6"
                        mb="mb-3"
                        onChange={validation.handleChange}
                        validation={validation}
                        onBlur={validation.handleBlur}
                        value={validation.values.phone}
                        label="Phone"
                        name="phone"
                        //errorMessage={client_errors}
                      />

                      {/*
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="mobile_number-field" className="form-label">
                            Phone
                          </Label>

                          <Input
                            name="phone"
                            id="mobile_number-field"
                            className="form-control"
                            placeholder="Enter Phone No."
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone || ""}
                            invalid={validation.touched.phone && validation.errors.phone ? true : false}
                          />
                          {validation.touched.phone && validation.errors.phone ? (
                            <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      */}

                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="role-field" className="form-label">
                            Role
                          </Label>
                          <Select
                            name="role"
                            id="role-field"
                            placeholder="Role"
                            className={`${
                              validation.touched.role && validation.errors.role ? "form-control is-invalid" : ""
                            } `}
                            validate={{ required: { value: true } }}
                            // onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={validation.touched.role && validation.errors.role ? true : false}
                            onChange={(e) => validation.setFieldValue("role", e.value)}
                            value={{ label: validation.values.role ?? "USER", value: validation.values.role ?? "USER" }}
                            options={[
                              { label: "ADMIN", value: "ADMIN" },
                              { label: "USER", value: "USER" },
                            ]}
                          />
                          {validation.touched.role && validation.errors.role ? (
                            <FormFeedback type="invalid">{validation.errors.role}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="status-field" className="form-label font-size-13">
                            Status
                          </Label>
                          <Select
                            name="status"
                            id="status-field"
                            placeholder="Status"
                            className={`mb-0 ${
                              validation.touched.status && validation.errors.status ? "form-control is-invalid" : ""
                            } `}
                            defaultValue={{
                              label: "Active",
                              value: true,
                            }}
                            value={{
                              label: validation.values.status ? "Active" : "In-active",
                              value: validation.values.status,
                            }}
                            onChange={(e) => validation.setFieldValue("status", e.value)}
                            onBlur={validation.handleBlur}
                            options={[
                              { label: "Active", value: true },
                              { label: "In-active", value: false },
                            ]}
                          ></Select>
                          {validation.touched.status && validation.errors.status ? (
                            <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                {/* Address Information */}
                {/*
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Address Information</h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="address_line-input">
                            Address Line
                          </Label>
                          <Input
                            type="text"
                            name="address_line"
                            className="form-control"
                            id="address_line-input"
                            value={validation.values.address_line}
                            onChange={validation.handleChange}
                            placeholder="Address Line"
                            invalid={validation.touched.address_line && validation.errors.address_line ? true : false}
                          />
                          {validation.touched.address_line && validation.errors.address_line ? (
                            <FormFeedback type="invalid">{validation.errors.address_line}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="street-input">
                            Street
                          </Label>
                          <Input
                            type="text"
                            name="street"
                            className="form-control"
                            id="street-input"
                            value={validation.values.street}
                            onChange={validation.handleChange}
                            placeholder="Street"
                            invalid={validation.touched.street && validation.errors.street ? true : false}
                          />
                          {validation.touched.street && validation.errors.street ? (
                            <FormFeedback type="invalid">{validation.errors.street}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="city-input">
                            City
                          </Label>
                          <Input
                            type="text"
                            name="city"
                            className="form-control"
                            id="city-input"
                            value={validation.values.city}
                            onChange={validation.handleChange}
                            invalid={validation.touched.city && validation.errors.city ? true : false}
                            placeholder="City"
                          />
                          {validation.touched.city && validation.errors.city ? (
                            <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="state-input">
                            State/Province/Region
                          </Label>
                          <Input
                            type="text"
                            name="state"
                            className="form-control"
                            id="state-input"
                            invalid={validation.touched.state && validation.errors.state ? true : false}
                            value={validation.values.state}
                            onChange={validation.handleChange}
                            placeholder="State"
                          />
                          {validation.touched.state && validation.errors.state ? (
                            <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="postal_code-input">
                            Zip/Postal Code
                          </Label>
                          <Input
                            type="text"
                            name="postcode"
                            className="form-control"
                            id="postcode-input"
                            value={validation.values.postcode}
                            onChange={validation.handleChange}
                            placeholder="Postal Code"
                            invalid={validation.touched.postcode && validation.errors.postcode ? true : false}
                          />
                          {validation.touched.postcode && validation.errors.postcode ? (
                            <FormFeedback type="invalid">{validation.errors.postcode}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="contact_country-input" className="form-label">
                            Country
                          </Label>
                          <Select
                            // defaultValue={countries.find((c) => c.value === contact.country)}
                            // key={contact.country}
                            id="contact_country-input"
                            placeholder="Select A Country..."
                            value={countryState}
                            key={countryState}
                            name="country"
                            onChange={(e) => {
                              setCountryState(e);
                              validation.setFieldValue("country", e?.value);
                            }}
                            options={countries}
                          />
                          {validation.touched.country && validation.errors.country ? (
                            <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
    */}
                {/* Other Information */}
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Additional Information</h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="password-field" className="form-label">
                            Default Password
                          </Label>
                          <Input
                            name="password"
                            id="password-field"
                            className="form-control"
                            placeholder="Enter default password"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={validation.touched.password && validation.errors.password ? true : false}
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {/*
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="description-field" className="form-label">
                            Description
                          </Label>
                          <Input
                            name="description"
                            id="description-field"
                            className="form-control"
                            placeholder="Enter User Description"
                            type="textarea"
                            rows="3"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            invalid={validation.touched.description && validation.errors.description ? true : false}
                          />
                          {validation.touched.description && validation.errors.description ? (
                            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                          ) : null}
                        </div>
                      </Col> */}
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={4}>
                <CardHeader>
                  <h5 className="card-title mb-0">Personal Image</h5>
                </CardHeader>
                <div className="text-center">
                  <div className="position-relative d-inline-block">
                    <div className="position-absolute  bottom-0 end-0">
                      <Label htmlFor="customer-image-input" className="mb-0">
                        <div className="avatar-xs cursor-pointer">
                          <div className="avatar-title bg-light border rounded-circle text-muted">
                            <i className="ri-image-fill"></i>
                          </div>
                        </div>
                      </Label>
                      <Input
                        className="form-control d-none"
                        id="customer-image-input"
                        type="file"
                        accept="image/png, image/gif, image/jpeg image/jpg"
                        // onChange={validation.handleChange}
                        // onChange={(e) => validation.setFieldValue("profile_pic", e.currentTarget.files[0])}
                        onChange={(e) => setFiles(e.target.files)}
                        onBlur={validation.handleBlur}
                        // value={validation.values.profile_pic || ""}
                        // value={files}
                        invalid={validation.touched.profile_pic && validation.errors.profile_pic ? true : false}
                      />
                    </div>
                    <div className="avatar-lg p-1">
                      <div className="avatar-title bg-light rounded-circle">
                        <img
                          src={validation.values.profile_pic || dummyImg}
                          alt="dummyImg"
                          id="customer-profile_pic"
                          className="avatar-md rounded-circle object-cover"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Permissions</h5>
                  </CardHeader>
                  <CardBody>
                    {/*<Row>
                      <Col>
                        <div className="form-check form-switch form-switch-md mb-3" dir="ltr">
                          <Label className="form-check-label" htmlFor="has_sales_access-checkbox">
                            Has Sales Access
                          </Label>
                          <Input
                            type="checkbox"
                            id="has_sales_access-checkbox"
                            name="has_sales_access"
                            className="form-check-input"
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="form-check form-switch form-switch-md mb-3" dir="ltr">
                          <Label className="form-check-label" htmlFor="has_marketing_access-checkbox">
                            Has Marketing Access
                          </Label>
                          <Input
                            type="checkbox"
                            id="has_marketing_access-checkbox"
                            name="has_marketing_access"
                            className="form-check-input"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                          />
                        </div>
                      </Col>
                    </Row>
                  */}

                    <Row>
                      <Col>
                        <div className="form-check form-switch form-switch-md mb-3" dir="ltr">
                          <Label className="form-check-label" htmlFor="is_organization_admin-checkbox">
                            Is Organization Admin
                          </Label>
                          <Input
                            type="checkbox"
                            id="is_organization_admin-checkbox"
                            name="is_organization_admin"
                            className="form-check-input"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <ModalFooter>
              <div className="hstack gap-2 justify-content-end">
                <button type="button" className="btn btn-danger" onClick={() => {}}>
                  Close
                </button>
                <button type="submit" className="btn btn-success" id="add-btn">
                  {props.updateUser ? "Update" : "Add User"}{" "}
                </button>
              </div>
            </ModalFooter>
          </Form>

          {isUserCreated ? (
            <MsgToast msg="User Added Successfully" color="success" icon="ri-checkbox-circle-line" />
          ) : null}
          {isUserAdd ? <MsgToast msg="User Added Successfully" color="success" icon="ri-checkbox-circle-line" /> : null}
          {isUserAddFail ? <MsgToast msg="User Added Failed" color="danger" icon="ri-error-warning-line" /> : null}
          {isUserUpdate ? (
            <MsgToast msg="User Updated Successfully" color="success" icon="ri-checkbox-circle-line" />
          ) : null}
          {isUserUpdateFail ? <MsgToast msg="User Updated Failed" color="danger" icon="ri-error-warning-line" /> : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UsersList;
