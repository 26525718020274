import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CardBody, Col, Container, Row } from "reactstrap";

//redux
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  // getDirectContact as onGetDirectContact,
  // getMessages,
  // getChannels as onGetChannels,
  // addMessage as onAddMessage,
  getUsersList as onGetUsers,
} from "../../store/actions";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { globalSocket } from "../../helpers/globalWebSocket";
import { getLoggedInUser } from "../../helpers/jwt-token-access/auth-token-header";
import ChatInbox from "./Components/ChatInbox";
import ChatList from "./Components/ChatList";
import UserList from "./Components/UserList";

const Chat = () => {
  const dispatch = useDispatch();
  const [Chat_Box_Username, setChat_Box_Username] = useState("");
  const [currentRoomId, setCurrentRoomId] = useState();

  const [messages, setMessages] = useState([]);
  const [currentRoomMessages, setCurrentRoomMessages] = useState([]);

  const [chats, setChats] = useState([]);
  const [receiverId, setReceiverId] = useState();
  const [socket, setSocket] = useState();
  const [user, setUser] = useState();

  const { usersList, channels, authUser, org } = useSelector((state) => ({
    //authUser: state.Account.user,
    authUser: state.Profile.user,
    org: state.Profile.org,
    chats: state.chat.chats,
    messages: state.chat.messages,
    channels: state.chat.channels,
    usersList: state.Users.usersList,
  }));

  useEffect(() => {
    dispatch(onGetUsers({ limit: 10 }));
    if (!authUser || !org) dispatch(getProfile());
  }, [dispatch, authUser, org]);

  useEffect(() => {
    // authUser from session storage is now in store.Profile.user
    const { token } = getLoggedInUser();

    const orgId = org.id;

    if (!authUser) return;

    let user = authUser?.user_details;

    user.access_token = token;
    user.user_id = authUser.user_details.id;

    setUser(user);
  }, []);

  useEffect(() => {
    let userId = user && user.user_id;
    let { token } = getLoggedInUser();

    if (!userId || !token || !org.id) return;

    let selfRoom = "chat_" + userId;

    let socket = globalSocket(selfRoom, token, org.id);
    setSocket(socket);
  }, [user, org]);

  useEffect(() => {
    if (!socket) return;

    let userId = user && user.user_id;

    if (!userId) return;

    socket.onopen = () => {
      getChats(userId);
      return;
    };

    if (socket.readyState) {
      getChats(userId);
    }
  }, [socket, user, messages]);

  useEffect(() => {
    if (!socket) return;

    socket.onmessage = (message) => {
      const data = JSON.parse(message.data);

      if (!data) return;

      if (data.type === "new_message") {
        let { message } = data;
        if (!message) return;
        setMessages([...messages, message]);
      }

      if (data.type === "create_chat") {
        let chats = data.chats;
        setChats(chats);
      }

      if (data.type === "get_chats") {
        let chats = data.chats;
        setChats(chats);
      }

      if (data.type === "open_chat") {
        setMessages(data.messages);
      }
      if (data.type === "new_message_received") {
        let userId = user && user.user_id;
        getChats(userId);
      }
    };
  });

  useEffect(() => {
    let currentRoomMessage = [];
    messages.forEach((message) => {
      if (message.room_id === currentRoomId) {
        return currentRoomMessage.push(message);
      }
    });
    setCurrentRoomMessages(currentRoomMessage);
  }, [messages, currentRoomId]);

  //Use For Chat Box
  const [openedChat, setOpenedChat] = useState({});
  const [receiver, setReceiver] = useState();

  const userChatOpen = (chat) => {
    let chat_box_name = +user.user_id === chat.receiver.id ? chat.creator.first_name : chat.receiver.first_name;

    let receiver = +user.user_id === chat.receiver.id ? chat.creator : chat.receiver;

    setReceiver(receiver);

    let receiverId = +user.user_id === chat.creator.id ? chat.receiver.id : chat.creator.id;

    setChat_Box_Username(chat_box_name);
    setCurrentRoomId(chat.room_id);
    // setChat_Box_Image(image);
    // dispatch(getMessages(roomId));
    setOpenedChat(chat);
    setReceiverId(receiverId);

    socket.send(
      JSON.stringify({
        type: "open_chat",
        room_id: chat.room_id,
      })
    );
  };

  const createChat = (user) => {
    const obj = authUser;

    let userId = +user.user_details.id;

    if (!userId) return;

    let roomId = "chat_" + obj.user_details.id + userId;

    let sender_name = obj.user_details.first_name;

    let creatorId = +obj.user_details.id;
    let receiverId = userId;
    let receiver_name = user.user_details.first_name;

    setReceiver(user.user_details);

    setChat_Box_Username(receiver_name);
    setReceiverId(receiverId);

    setCurrentRoomId(roomId);

    socket.send(
      JSON.stringify({
        type: "create_chat",
        room_id: roomId,
        sender_name: sender_name,
        receiver_name: receiver_name,
        room_users: [creatorId, receiverId],
        currentUserId: creatorId,
        creatorId: creatorId,
        receiverId: receiverId,
        createdAt: new Date(),
      })
    );
  };

  const handleOpenChat = (user) => {
    const obj = authUser;

    let receiverUserId = +user.user_details.id;
    setReceiverId(receiverUserId);

    let currentUserId = +obj.user_details.id;

    let roomId = "chat_" + currentUserId + receiverUserId;
    let currentChat = {};

    let chatRoomExist = () => {
      let doesRoomExist = false;
      chats.forEach((chat) => {
        if (currentUserId !== chat.creator.id) {
          roomId = "chat_" + receiverUserId + currentUserId;
        }

        if (chat.room_id === roomId) {
          currentChat = chat;
          return (doesRoomExist = true);
        }
      });
      return doesRoomExist;
    };

    if (chatRoomExist()) {
      userChatOpen(currentChat);
    } else {
      createChat(user);
    }
  };

  function getChats(userId) {
    socket.send(
      JSON.stringify({
        type: "get_chats",
        user_id: +userId,
        room_id: "chat_" + userId,
      })
    );
  }

  const handler = useCallback(
    debounce((value) => {
      // if (value) {
      dispatch(onGetUsers({ search: value }));
      // }
    }, 500),
    []
  );

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    handler(value);
  };
  document.title = "Chat | simplynp";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
            <div className="chat-leftsidebar">
              <div className="px-4 pt-4 mb-4">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h5 className="mb-4">Chats</h5>
                  </div>
                </div>
                <div className="search-box">
                  <input
                    onChange={(e) => {
                      handleFieldSearch(e);
                    }}
                    id="search-user"
                    type="text"
                    className="form-control bg-light border-light"
                    placeholder="Search here..."
                  />
                  <i className="ri-search-2-line search-icon"></i>
                </div>
              </div>

              <PerfectScrollbar className="chat-room-list">
                {chats.length ? (
                  <div className="d-flex align-items-center px-4 mb-2">
                    <div className="flex-grow-1">
                      <h4 className="mb-0 fs-11 text-muted text-uppercase">Chats</h4>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <ChatList chats={chats} currentRoomId={currentRoomId} currentUser={user} userChatOpen={userChatOpen} />

                <div className="d-flex align-items-center px-4 mb-2">
                  <div className="flex-grow-1">
                    <h4 className="mb-0 fs-11 text-muted text-uppercase">Direct Contacts</h4>
                  </div>
                </div>

                <UserList usersList={usersList} receiverId={receiverId} handleOpenChat={handleOpenChat} />

                <div className="chat-message-list">
                  <ul className="list-unstyled chat-list chat-user-list mb-0 users-list" id="channelList">
                    {channels.map((channel, key) => (
                      <React.Fragment key={key}>
                        <li>
                          <Link to="#" className="unread-msg-user">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                <div className="avatar-xxs">
                                  <div className="avatar-title bg-light rounded-circle text-body">#</div>
                                </div>
                              </div>
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-truncate mb-0">{channel.name}</p>
                              </div>
                              {channel.unReadMessage && (
                                <div className="flex-shrink-0">
                                  <span className="badge badge-soft-dark rounded p-1">{channel.unReadMessage}</span>
                                </div>
                              )}
                            </div>
                          </Link>
                        </li>
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              </PerfectScrollbar>
            </div>

            {/*  */}

            {currentRoomId ? (
              <ChatInbox
                chat={openedChat}
                receiverId={receiverId}
                chatSocket={socket}
                currentRoomMessages={currentRoomMessages}
                chats={chats}
                Chat_Box_Username={Chat_Box_Username}
                currentRoomId={currentRoomId}
                receiver={receiver}
              />
            ) : (
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <div className="text-center py-5">
                      <h5>Tap on a Chat</h5>
                      <p className="text-muted">Tap on a Chat to start chatting.</p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Chat;
