export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_NOTES_LIST = "GET_NOTES_LIST";
export const GET_NOTE = "GET_NOTE";

export const ADD_NEW_NOTE = "ADD_NEW_NOTE";
export const ADD_NEW_NOTE_SUCCESS = "ADD_NEW_NOTE_SUCCESS";
export const ADD_NEW_NOTE_FAIL = "ADD_NEW_NOTE_FAIL";

export const UPDATE_NOTE = "UPDATE_NOTE";
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS";
export const UPDATE_NOTE_FAIL = "UPDATE_NOTE_FAIL";

export const DELETE_NOTE = "DELETE_NOTE";
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";
export const DELETE_NOTE_FAIL = "DELETE_NOTE_FAIL";

export const RESET_NOTE_FLAG = "RESET_NOTE_FLAG";
