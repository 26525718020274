import {
  GET_CAMPAIGN_LIST,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_CAMPAIGN_SUCCESS,
  ADD_CAMPAIGN_FAIL,
  UPDATE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_FAIL,
  DELETE_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN_FAIL,
  RESET_CAMPAIGN_FLAG,
} from "./actionType";

const INIT_STATE = {
  campaignList: null,
};

const Campaigns = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CAMPAIGN_LIST:
          return {
            ...state,
            campaignList: action.payload.data.campaigns,
            page: action.payload.data.page,
            stats: action.payload.data.stats,
            isCampaignCreated: false,
            isCampaignSuccess: true,
          };

        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_CAMPAIGN_LIST:
          return {
            ...state,
            error: action.payload.error,
            isCampaignCreated: false,
            isCampaignSuccess: true,
          };

        default:
          return { ...state };
      }
    case GET_CAMPAIGN_LIST: {
      return {
        ...state,
        isCampaignCreated: false,
      };
    }
    case ADD_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isCampaignCreated: true,
        // campaignList: [...state.campaignList, action.payload.data],
        isCampaignAdd: true,
        isCampapignAddFail: false,
      };
    case ADD_CAMPAIGN_FAIL:
      return {
        ...state,
        error: action.payload,
        isCampaignAdd: false,
        isCampaignAddFail: true,
      };
    case UPDATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        // campaignList: state.campaignList.map((task) =>
        //   task.id.toString() === action.payload.task.id.toString() ? { ...task, ...action.payload.task } : task
        // ),
        isCampaignUpdate: true,
        isCampaignUpdateFail: false,
      };
    case UPDATE_CAMPAIGN_FAIL:
      return {
        ...state,
        error: action.payload,
        isCampaignUpdate: false,
        isCampaignUpdateFail: true,
      };
    case DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaignList: state.campaignList.filter(
          (campaign) => campaign.id.toString() !== action.payload.campaign.toString()
        ),
        isCampaignDeleted: true,
        isCampaignDeletedFail: false,
      };

    case DELETE_CAMPAIGN_FAIL:
      return {
        ...state,
        error: action.payload,
        isCampaignDelete: false,
        isCampaignDeleteFail: true,
      };
    case RESET_CAMPAIGN_FLAG:
      return {
        ...state,
        isCampaignAdd: false,
        isCampaignAddFail: false,
        isCampaignDelete: false,
        isCampaignDeleteFail: false,
        isCampaignUpdate: false,
        isCampaignUpdateFail: false,
        error: false,
      };
    default:
      return { ...state };
  }
};

export default Campaigns;
