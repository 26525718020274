import React from "react";
import { Link } from "react-router-dom";

const ChatList = ({ chats, currentRoomId, currentUser, userChatOpen }) => {
  return (
    <div className="chat-message-list">
      <ul className="list-unstyled chat-list chat-user-list users-list" id="userList">
        {((chats && chats) || []).map((chat, i) => {
          return (
            <li key={i} className={currentRoomId === chat.room_id ? "active" : ""}>
              <Link
                to="#"
                onClick={(e) => {
                  userChatOpen(chat);
                }}
              >
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                    {chat.currentSender?.id != +currentUser?.user_id && !chat.is_chat_opened ? (
                      <span className="user-status"></span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-truncate mb-0">{`${
                      +currentUser.user_id === chat.creator.id ? chat.receiver.first_name : chat.creator.first_name
                    }`}</p>
                  </div>
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ChatList;
