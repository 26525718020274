import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import { useFormik } from "formik";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../../Components/Common/DeleteModal";

//Import actions
import {
  deleteMembershipLevel as onDeleteLevel,
  getMembershipLevels as onGetLevels,
  addNewMembershipLevel as onAddNewLevel,
  updateMembershipLevel as onUpdateLevel,
  resetMembershipFlag,
} from "../../../../store/actions";

import * as Yup from "yup";
//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../../../Components/Common/TableContainer";

// Formik

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../../Components/Common/Loader";
import MsgToast from "../../../../Components/Common/MsgToast";
import TextField from "../../../../Components/Common/Fields/TextField";
// import Widgets from "./Widgets";

const MembershipLevels = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [level, setLevel] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [sortBy, setsortBy] = useState(null);
  const [search, setSearch] = useState("");

  const {
    levelsList,
    isLevelsDelete,
    isLevelsDeleteFail,
    isLevelsAdd,
    isLevelsAddFail,
    isLevelsUpdate,
    isLevelsUpdateFail,
    error,
    page,
  } = useSelector((state) => ({
    levelsList: state.Membership.membership_levels,
    error: state.Membership.error,
    page: state.Membership.page,
    isLevelsAdd: state.Membership.isLevelsAdd,
    isLevelsAddFail: state.Membership.isLevelsAddFail,
    isLevelsDelete: state.Membership.isLevelsDelete,
    isLevelsDeleteFail: state.Membership.isLevelsDeleteFail,
    isLevelsUpdate: state.Membership.isLevelsUpdate,
    isLevelsUpdateFail: state.Membership.isLevelsUpdateFail,
  }));

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetMembershipFlag());
    }, 3000);
  }, [
    dispatch,
    error,
    isLevelsAdd,
    isLevelsAddFail,
    isLevelsDelete,
    isLevelsDeleteFail,
    isLevelsUpdate,
    isLevelsUpdateFail,
  ]);

  useEffect(() => {
    dispatch(onGetLevels());
  }, [dispatch]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: (level && level.id) || "",
      name: (level && level.name) || "",
      status: (level && level.status) || "active",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Please enter level name"),
      status: Yup.string().notRequired("Please enter a level status"),
    }),

    onSubmit: (values) => {
      let level = values;

      if (isEdit) {
        dispatch(onUpdateLevel(level));
      } else {
        dispatch(onAddNewLevel(level));
      }

      toggle();
    },
  });

  const toggle = () => {
    if (modal) {
      setModal(false);
      setLevel(null);
      setIsEdit(false);
      setIsCreate(true);
      validation.resetForm();
    } else {
      setModal(true);
    }
  };

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(onGetLevels({ offset, limit }));
    },
    [dispatch]
  );

  const handlesortBy = (sortBy) => {
    setsortBy(sortBy);
  };

  const sortbyname = [
    {
      options: [
        { label: "Owner", value: "Owner" },
        { label: "Company", value: "Company" },
        { label: "Location", value: "Location" },
      ],
    },
  ];

  // Delete Data
  const handleDeleteLevel = () => {
    if (level) {
      dispatch(onDeleteLevel(level));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (level) => {
    setLevel(level);
    setDeleteModal(true);
  };

  const handler = useCallback(
    debounce((value) => {
      dispatch(onGetLevels({ search: value }));
    }, 1500),
    []
  );

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    setSearch(value);
    handler(value);
  };

  const handleLevelClick = (arg) => {
    const level = arg;
    setLevel(level);
    setIsEdit(true);
    toggle();
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return (
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="checkAll" value="option1" />
            </div>
          );
        },
      },
      {
        Header: "Level Name",
        accessor: "name",
        filterable: false,
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: (cellProps) => {
          const status = cellProps.row.original?.status;

          return (
            <React.Fragment>
              {status === "active" ? (
                <span className="badge badge-soft-success text-uppercase">{status}</span>
              ) : status === "in-active" ? (
                <span className="badge badge-soft-danger text-uppercase">{status}</span>
              ) : null}
            </React.Fragment>
          );
        },
      },
      {
        Header: "Actions",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-3 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-muted d-inline-block"
                  onClick={() => {
                    const levelData = cellProps.row.original;
                    handleLevelClick(levelData);
                  }}
                >
                  <i className="ri-edit-2-line fs-16"></i>
                </Link>
              </li>

              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle href="#" className="btn-soft-secondary btn-sm dropdown" tag="button">
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    {/* <Link to={`//${cellProps.row.original.id}`}>
                      <DropdownItem
                        className="dropdown-item edit-item-btn"
                        // onClick={() => {
                        //   const contactData = cellProps.row.original;
                        //   handleContactClick(contactData);
                        // }}
                      >
                        <i className="ri-recycle-fill align-bottom me-2 text-muted"></i>Renew
                      </DropdownItem>
                    </Link> */}

                    <DropdownItem
                      className="dropdown-item remove-item-btn"
                      onClick={() => {
                        const levelData = cellProps.row.original;
                        onClickDelete(levelData);
                      }}
                    >
                      <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  document.title = "Levels - Membership Management | SimplyNP";

  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal show={deleteModal} onDeleteClick={handleDeleteLevel} onCloseClick={() => setDeleteModal(false)} />
        <Container fluid>
          <BreadCrumb title="List Levels" pageTitle="Membership Levels" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-1">
                      <button className="btn btn-info add-btn" onClick={() => setModal(true)}>
                        <i className="ri-add-fill me-1 align-bottom"></i> Add Level
                      </button>
                    </div>
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                        <button
                          className="btn btn-soft-danger"
                          // onClick="deleteMultiple()"
                        >
                          <i className="ri-delete-bin-2-line"></i>
                        </button>
                        <button className="btn btn-danger">
                          <i className="ri-filter-2-line me-1 align-bottom"></i> Filters
                        </button>
                        <button className="btn btn-soft-success">Import</button>

                        <UncontrolledDropdown>
                          <DropdownToggle href="#" className="btn btn-soft-info" tag="button">
                            <i className="ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" href="#">
                              All
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Week
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Month
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Year
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col>
              <Card id="memberList">
                <CardHeader>
                  <Row className="g-3">
                    <Col md={4}>
                      <div className="search-box">
                        <Input
                          onChange={(e) => {
                            handleFieldSearch(e);
                          }}
                          type="text"
                          className="form-control search"
                          placeholder="Search for member..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    <div className="col-md-auto ms-auto">
                      <div className="d-flex align-items-center gap-2">
                        <span className="text-muted">Sort by: </span>
                        <Select
                          className="mb-0"
                          value={sortBy}
                          onChange={() => {
                            handlesortBy();
                          }}
                          options={sortbyname}
                          id="choices-single-default"
                        ></Select>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    {levelsList ? (
                      <TableContainer
                        columns={columns}
                        data={levelsList || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        customFetchData={fetchData}
                        customTotalSize={page.count}
                        customPageSize={10}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        // handleMemberClick={handleMemberClicks}
                      />
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={modal} id="level-modal" centered>
            <ModalHeader toggle={toggle} tag="h5" className="p-3 bg-soft-info modal-title">
              {!!isEdit ? "Edit Level" : "Add Level"}
            </ModalHeader>
            <ModalBody>
              <Form
                className={!!isEdit ? "needs-validation view-level" : "needs-validation"}
                name="level-form"
                id="form-level"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row className="level-form">
                  <TextField
                    mb="mb-3"
                    value={validation.values.name}
                    onChange={validation.handleChange}
                    validation={validation}
                    onBlur={validation.handleBlur}
                    placeholder="Enter level name"
                    label="Level Description"
                    name="name"
                    errorMessage={error && error.errors}
                  />
                </Row>
                <Row>
                  <Label className="form-label" htmlFor="status-input">
                    Status
                  </Label>
                  <Select
                    name="status"
                    className="mb-3"
                    onChange={(e) => validation.setFieldValue("status", e?.value)}
                    options={[
                      { label: "Active", value: "active" },
                      { label: "In Active", value: "in-active" },
                    ]}
                    isClearable
                    // placeholder="Select length..."
                    defaultValue={{ label: "Active", value: "Active" }}
                    id="length-type-input"
                    invalid={validation.touched.status && validation.errors.status ? true : false}
                    classNamePrefix="select2-selection form-select"
                  />
                </Row>

                <div className="hstack gap-2 justify-content-end">
                  <button type="submit" className="btn btn-success" id="btn-save-level">
                    {!!isEdit ? "Edit Level" : "Add Level"}
                  </button>
                </div>
              </Form>
            </ModalBody>
          </Modal>

          {isLevelsDelete ? (
            <MsgToast msg="Level Deleted Successfully" color="success" icon="ri-checkbox-circle-line" />
          ) : null}

          {isLevelsDeleteFail ? (
            <MsgToast msg="Level Delete Failed" color="danger" icon="ri-error-warning-line" />
          ) : null}

          {isLevelsAdd ? (
            <MsgToast msg="Level Added Successfully" color="success" icon="ri-checkbox-circle-line" />
          ) : null}

          {isLevelsAddFail ? <MsgToast msg="Level Add Failed" color="danger" icon="ri-error-warning-line" /> : null}

          {isLevelsUpdate ? (
            <MsgToast msg="Level Update Successfully" color="success" icon="ri-checkbox-circle-line" />
          ) : null}

          {isLevelsUpdateFail ? (
            <MsgToast msg="Level Update Failed" color="danger" icon="ri-error-warning-line" />
          ) : null}

          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MembershipLevels;
