import React, { useRef } from "react";
import { Col, Label, FormFeedback, Row, Input } from "reactstrap";

//import IntlTelInput from "react-intl-tel-input";
//import "react-intl-tel-input/dist/main.css";

import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import "react-phone-number-input/style.css";
import "./style.css";

const IntNumberInput = ({ ...props }) => {
  return (
    <Col md={props.md}>
      <div className={props.mb}>
        <Label htmlFor={props.id} className="form-label">
          {props.label}
        </Label>

        <div
          className={`${
            (props.validation.touched[props.name] && props.validation.errors[props.name]) ||
            (props.errorMessage && props.errorMessage[props.name])
              ? "form-control is-invalid"
              : ""
          } `}
        >
          <PhoneInput
            //specialLabel={""}
            flags={flags}
            //copyNumbersOnly={false}
            //jumpCursorToEnd={true}
            //inputClass="form-control border-0 fs-14"
            className="form-control"
            //inputComponent={Input}
            value={props.validation.values[props.name]}
            onChange={(e) => {
              props.validation.setFieldValue(props.name, e?.toString());
            }}
          />
        </div>

        {props.validation.touched[props.name] && props.validation.errors[props.name] ? (
          <FormFeedback type="invalid">{props.validation.errors[props.name]}</FormFeedback>
        ) : null}

        {props.errorMessage && props.errorMessage[props.name] && props.errorMessage[props.name].length > 0 ? (
          <FormFeedback type="invalid">
            <div>{props.errorMessage[props.name]}</div>
          </FormFeedback>
        ) : (
          ""
        )}
      </div>
    </Col>
  );
};

export default IntNumberInput;
