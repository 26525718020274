import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { CREATE_DONATION, DELETE_DONATION, GET_DONATIONS_LIST, UPDATE_DONATION } from "./actionType";

import {
  createDonationSuccess,
  deleteDonationSuccess,
  donationError,
  getDonationsList,
  getDonationsSuccess,
  updateDonationSuccess,
} from "./action";

import { createDonation, deleteDonation, getDonationList, updateDonation } from "../../helpers/backend_helper";

function* createDonationWorker({ payload: data }) {
  try {
    const response = yield call(createDonation, data);

    if (response.status === 200) {
      yield put(createDonationSuccess(response));
    }
    if (response.status === 400) {
      yield put(donationError(response));
    }
  } catch (err) {
    yield put(donationError(err));
  }
}

function* getDonationsListWorker({ payload: data }) {
  try {
    const response = yield call(getDonationList, data);
    yield put(getDonationsSuccess(response));
  } catch (err) {
    yield put(donationError(err));
  }
}

function* updateDonationWorker({ payload: data }) {
  try {
    const response = yield call(updateDonation, data["id"], data);
    yield put(updateDonationSuccess(response));
  } catch (err) {
    yield put(donationError(err));
  }
}

function* deleteDonationWorker({ payload: id }) {
  try {
    const response = yield call(deleteDonation, id);
    yield put(deleteDonationSuccess({ id }));
    //yield put(getDonationsList());
  } catch (err) {
    yield put(donationError(err));
  }
}

export function* watchCreateDonation() {
  yield takeEvery(CREATE_DONATION, createDonationWorker);
}

export function* watchGetDonationList() {
  yield takeEvery(GET_DONATIONS_LIST, getDonationsListWorker);
}

export function* watchDeleteDonation() {
  yield takeEvery(DELETE_DONATION, deleteDonationWorker);
}
export function* watchUpdateDonationWorker() {
  yield takeEvery(UPDATE_DONATION, updateDonationWorker);
}

function* DonationSaga() {
  yield all([
    fork(watchGetDonationList),
    fork(watchCreateDonation),
    fork(watchUpdateDonationWorker),
    fork(watchDeleteDonation),
  ]);
}

export default DonationSaga;
