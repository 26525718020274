import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import {
  CLIENT_STATS,
  GET_CASES_STATS,
  GET_SERVICES_STATS,
  GET_DONATIONS_ORG_STATS,
  GET_DONATIONS_CONTACTS_STATS,
  GET_DONATIONS_CAMPAIGNS_STATS,
  GET_DEMOGRAPHIC_REPORTS_GROUPBY,
  GET_DONATIONS_OGS_BY_PATTERN_STATS,
} from "./actionType";

import {
  getClientStatsSuccess,
  getCasesStatsSuccess,
  getServicesStatsSuccess,
  getDonationsOrgStatsSuccess,
  getDemographicsReportsGroupBySuccess,
  getDonationOrgsByPatternSuccess,
} from "./action";

import {
  getDonationsContactsStats,
  getClientStats,
  getCasesStats,
  getServicesStats,
  getDonationsOrgStats,
  getDonationsCampaignsStats,
  getDemographicsReportsGroupBy,
  getDonationOrgsByPattern,
} from "../../helpers/backend_helper";

function* getClientStatsWorker({ payload: { params } }) {
  try {
    const response = yield call(getClientStats, params);

    yield put(getClientStatsSuccess(response));
  } catch (error) {
    // yield put(getStatsFailure(GET_CLIENTS_LIST, error));
  }
}

function* getCasesStatsWorker({ payload: { params } }) {
  try {
    const response = yield call(getCasesStats, params);

    yield put(getCasesStatsSuccess(response));
  } catch (error) {
    // yield put(getStatsFailure(GET_CLIENTS_LIST, error));
  }
}

function* getServicesStatsWorker({ payload: { params } }) {
  try {
    const response = yield call(getServicesStats, params);

    yield put(getServicesStatsSuccess(response));
  } catch (error) {
    // yield put(getStatsFailure(GET_CLIENTS_LIST, error));
  }
}

function* getDonationStatsWorker({ payload: { params } }) {
  try {
    const response = yield call(getDonationsOrgStats, params);

    yield put(getDonationsOrgStatsSuccess(response));
  } catch (error) {
    // yield put(getStatsFailure(GET_CLIENTS_LIST, error));
  }
}

function* getDonationContactsStatsWorker({ payload: { params } }) {
  try {
    const response = yield call(getDonationsContactsStats, params);

    yield put(getDonationsOrgStatsSuccess(response));
  } catch (error) {
    // yield put(getStatsFailure(GET_CLIENTS_LIST, error));
  }
}

function* getDonationsCampaignsStatsWorker({ payload: { params } }) {
  try {
    const response = yield call(getDonationsCampaignsStats, params);

    yield put(getDonationsOrgStatsSuccess(response));
  } catch (error) {
    // yield put(getStatsFailure(GET_CLIENTS_LIST, error));
  }
}

function* getDemographicsReportsGroupByWorker({ payload: { params } }) {
  try {
    const response = yield call(getDemographicsReportsGroupBy, params);

    yield put(getDemographicsReportsGroupBySuccess(response));
  } catch (error) {
    // yield put(getStatsFailure(GET_CLIENTS_LIST, error));
  }
}

function* getDonationOrgsByPatternWorker({ payload: { params } }) {
  try {
    const response = yield call(getDonationOrgsByPattern, params);

    yield put(getDonationOrgsByPatternSuccess(response));
  } catch (error) {
    // yield put(getStatsFailure(GET_CLIENTS_LIST, error));
  }
}

export function* watchGetCLientStats() {
  yield takeEvery(CLIENT_STATS, getClientStatsWorker);
}

export function* watchGetCasesStats() {
  yield takeEvery(GET_CASES_STATS, getCasesStatsWorker);
}
export function* watchGetServicesStatsWorker() {
  yield takeEvery(GET_SERVICES_STATS, getServicesStatsWorker);
}

export function* watchGetGetDonationStatsWorker() {
  yield takeEvery(GET_DONATIONS_ORG_STATS, getDonationStatsWorker);
}

export function* watchGetGetDonationContactsStatsWorker() {
  yield takeEvery(GET_DONATIONS_CONTACTS_STATS, getDonationContactsStatsWorker);
}

export function* watchGetDonationsCampaignsStatsWorker() {
  yield takeEvery(GET_DONATIONS_CAMPAIGNS_STATS, getDonationsCampaignsStatsWorker);
}

export function* watchGetDemographicsReportsGroupByWorker() {
  yield takeEvery(GET_DEMOGRAPHIC_REPORTS_GROUPBY, getDemographicsReportsGroupByWorker);
}

export function* watchGetDonationOrgsByPatternWorker() {
  yield takeEvery(GET_DONATIONS_OGS_BY_PATTERN_STATS, getDonationOrgsByPatternWorker);
}

function* StatisticsSaga() {
  yield all([
    fork(watchGetCLientStats),
    fork(watchGetCasesStats),
    fork(watchGetServicesStatsWorker),
    fork(watchGetGetDonationStatsWorker),
    fork(watchGetGetDonationContactsStatsWorker),
    fork(watchGetDonationsCampaignsStatsWorker),
    fork(watchGetDemographicsReportsGroupByWorker),
    fork(watchGetDonationOrgsByPatternWorker),
  ]);
}

export default StatisticsSaga;
