export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILED = "REFRESH_TOKEN_FAILED";

export const ACTIVATE_USER = "ACTIVATE_USER";
export const ACTIVATE_USER_SUCCESSFUL = "ACTIVATE_USER_SUCCESSFUL";
export const ACTIVATE_USER_FAILED = "ACTIVATE_USER_FAILED";
export const ACTIVATE_ERROR = "ACTIVATE_ERROR";
export const RESET_ACTIVATE_FLAG = "RESET_ACTIVATE_FLAG";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESSFUL = "REGISTER_USER_SUCCESSFUL";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";
export const REGISTRATION_ERROR = "REGISTRATION_ERROR";
export const RESET_REGISTER_FLAG = "RESET_REGISTER_FLAG";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const RESET_LOGIN_FLAG = "RESET_LOGIN_FLAG";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";

// Get Organisations
export const GET_USER_ORG = "GET_USER_ORG";
export const GET_USER_ORG_SUCCESS = "GET_USER_ORG_SUCCESS";
export const GET_USER_ORG_FAILURE = "GET_USER_ORG_FAILURE";
