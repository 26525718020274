import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import CountUp from "react-countup";
import { ProjectsOverviewCharts } from "./DashboardProjectCharts";

const ProjectsOverview = ({ context, activeDonor, monthlyActiveDonors }) => {
  const numberOfDonors = (context && context.donor_month_count.count) || 0;
  const totalDonationAmount = (context && context.total_donation_amount) || 0;
  const monthlyDonors = context && context.monthly_donors;
  const monthlyDonationAmount = context && context.monthly_donation_amount;
  const activeDonorsPerMonth = monthlyActiveDonors;

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Card>
            <CardHeader className="border-0 align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">Donors Overview</h4>
              <div className="d-flex gap-1">
                <button type="button" className="btn btn-soft-secondary btn-sm">
                  ALL
                </button>
                <button type="button" className="btn btn-soft-secondary btn-sm">
                  1M
                </button>
                <button type="button" className="btn btn-soft-secondary btn-sm">
                  6M
                </button>
                <button type="button" className="btn btn-soft-primary btn-sm">
                  1Y
                </button>
              </div>
            </CardHeader>

            <CardHeader className="p-0 border-0 bg-soft-light">
              <Row className="g-0 text-center">
                <Col xs={6} sm={4}>
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className="mb-1">
                      <span className="counter-value" data-target="9851">
                        <CountUp start={0} end={numberOfDonors} separator={","} duration={2} />
                      </span>
                    </h5>
                    <p className="text-muted mb-0">No. of Donations</p>
                  </div>
                </Col>
                <Col xs={6} sm={4}>
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className="mb-1">
                      <span className="counter-value">
                        <CountUp start={0} end={activeDonor} separator={","} duration={2} />
                      </span>
                    </h5>
                    <p className="text-muted mb-0">No. of Active Donors</p>
                  </div>
                </Col>
                <Col xs={6} sm={4}>
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className="mb-1">
                      $
                      <span className="counter-value" data-target="228.89">
                        <CountUp start={0} end={totalDonationAmount} separator={","} decimals={2} duration={4} />
                      </span>
                    </h5>
                    <p className="text-muted mb-0">Total Revenue</p>
                  </div>
                </Col>
                {/*<Col xs={6} sm={3}>
                  <div className="p-3 border border-dashed border-start-0 border-end-0">
                    <h5 className="mb-1 text-success">
                      <span className="counter-value" data-target="10589">
                        <CountUp start={0} end={10589} separator={","} duration={4} />
                      </span>
                      h
                    </h5>
                    <p className="text-muted mb-0">Working Hours</p>
                  </div>
                </Col>
              */}
              </Row>
            </CardHeader>
            <CardBody className="p-0 pb-2">
              <div>
                <div dir="ltr" className="apex-charts">
                  <ProjectsOverviewCharts
                    monthlyDonors={monthlyDonors}
                    monthlyDonationAmount={monthlyDonationAmount}
                    activeDonorsPerMonth={activeDonorsPerMonth}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ProjectsOverview;
