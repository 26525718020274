import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import MsgToast from "../../../Components/Common/MsgToast";

// Import CreateUserForm
import { useDispatch, useSelector } from "react-redux";
import { deleteDonation, getDonationsList, resetDonationFlag } from "../../../store/actions";
import CreateUserForm from "./FormComponents/CreateDonationForm";

import { debounce } from "lodash";
import DeleteModal from "../../../Components/Common/DeleteModal";
import Loader from "../../../Components/Common/Loader";
import TableContainer from "../../../Components/Common/TableContainer";
import CrmFilter from "./CrmFilter";

const DonationsList = () => {
  const dispatch = useDispatch();
  const [lead, setLead] = useState({});
  const [sortBy, setSortBy] = useState(null);
  const [isInfoDetails, setIsInfoDetails] = useState(false);

  const [modalList, setModalList] = useState(false);
  const toggleList = () => {
    setModalList(!modalList);
  };
  const toggleInfo = () => {
    setIsInfoDetails(!isInfoDetails);
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const { donations, page, deleted, error, context } = useSelector((state) => ({
    donations: state.Donations.donations,
    page: state.Donations.page,
    context: state.Donations.context,
    deleted: state.Donations.deleted,
    isLeadsDelete: state.Crm.isLeadsDelete,
    isLeadsSuccess: state.Crm.isLeadsSuccess,
    error: state.Crm.error,
  }));

  useEffect(() => {
    if (deleted) {
      setTimeout(() => {
        dispatch(resetDonationFlag());
      }, 3000);
    }
  }, [dispatch, deleted]);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return (
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="checkAll" value="option1" />
            </div>
          );
        },
      },
      {
        Header: "Donor",
        accessor: (row) => `${row.donor.first_name} ${row.donor.last_name}`,
        filterable: true,
      },
      {
        Header: "Company",
        accessor: "donor.organization.account_name",
        filterable: false,
      },
      {
        Header: "Amount",
        accessor: "amount",
        filterable: true,
      },
      {
        Header: "Cause",
        accessor: "cause",
        filterable: false,
      },

      {
        Header: "Mobile",
        accessor: "donor.phone",
        filterable: true,
      },

      {
        Header: "City",
        accessor: "donor.address.city",
        filterable: false,
      },
      {
        Header: "Date",
        accessor: "created_on",
        filterable: true,
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle href="#" className="btn-soft-secondary btn-sm dropdown" tag="button">
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <Link to={`/donor-donations-update/${cellProps.row.original.id}`}>
                      <DropdownItem className="dropdown-item edit-item-btn">
                        <i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Edit
                      </DropdownItem>
                    </Link>
                    <DropdownItem
                      className="dropdown-item remove-item-btn"
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        onClickDelete(contactData);
                      }}
                    >
                      <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const handlesortBy = (sortBy) => {
    setSortBy(sortBy);
  };

  const sortbyname = [
    {
      options: [
        { label: "Owner", value: "Owner" },
        { label: "Company", value: "Company" },
        { label: "Location", value: "Location" },
      ],
    },
  ];

  // Get Data
  useEffect(() => {
    if (!donations) dispatch(getDonationsList());
  }, [dispatch, deleted, donations]);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(getDonationsList({ limit, offset }));
    },
    [dispatch]
  );

  const handleDeleteLead = (item) => {
    if (lead) {
      dispatch(deleteDonation(lead.id));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (lead) => {
    setLead(lead);
    setDeleteModal(true);
  };

  const handler = useCallback(
    debounce((value) => {
      dispatch(getDonationsList({ search: value }));
    }, 3000),
    []
  );

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    handler(value);
  };

  document.title = "Donations | Velzon - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <div className="page-content">
        <BreadCrumb title="Donations" pageTitle="Donations" />

        <DeleteModal show={deleteModal} onDeleteClick={handleDeleteLead} onCloseClick={() => setDeleteModal(false)} />

        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-1">
                      <Link to="/donor-donations-create">
                        <button className="btn btn-info add-btn">
                          <i className="ri-add-fill me-1 align-bottom"></i> Add Donation
                        </button>
                      </Link>
                    </div>
                    <div className="flex-shrink-0">
                      <button type="button" className="btn btn-info" onClick={toggleInfo}>
                        <i className="ri-filter-3-line align-bottom me-1"></i> Fliters
                      </button>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col xxl={12}>
              <Card>
                <CardHeader>
                  <Row className="g-3">
                    <Col md={4}>
                      <div className="search-box">
                        <Input
                          onChange={(e) => {
                            handleFieldSearch(e);
                          }}
                          type="text"
                          className="form-control search"
                          placeholder="Search for donation..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    <div className="col-md-auto ms-auto">
                      <div className="d-flex align-items-center gap-2">
                        <span className="text-muted">Sort by: </span>
                        <Select
                          className="mb-0"
                          value={sortBy}
                          onChange={() => {
                            handlesortBy();
                          }}
                          options={sortbyname}
                          id="choices-single-default"
                        ></Select>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    {donations ? (
                      <TableContainer
                        columns={columns}
                        data={donations || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        customPageSize={10}
                        customTotalSize={page.count}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3 z-50"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        customFetchData={fetchData}
                      />
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>
                </CardBody>

                {deleted ? (
                  <MsgToast msg="Donation deleted successfully" color="success" icon="ri-error-warning-line" />
                ) : null}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Add Modal */}
      <Modal
        isOpen={modalList}
        toggle={() => {
          toggleList();
        }}
        centered
      >
        <ModalHeader className="bg-light p-3">
          Add User
          <Button
            type="button"
            onClick={() => {
              setModalList(false);
            }}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </ModalHeader>
        <form>
          <ModalBody>
            <CreateUserForm />
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button type="button" className="btn btn-light" onClick={() => setModalList(false)}>
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                Add Customer
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      <CrmFilter show={isInfoDetails} onCloseClick={() => setIsInfoDetails(false)} />
    </React.Fragment>
  );
};

export default DonationsList;
