import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AccountSaga from "./auth/account/saga";
import ProfileSaga from "./auth/profile/saga";
import CreateOrganisationsSaga from "./auth/organization/saga";

//Case
import CaseSaga from "./cases/saga";

// Tasks
import TaskSaga from "./tasks/saga";

// Calendar
import CalendarSaga from "./calendar/saga";

// Client
import ClientsSaga from "./clients/saga";

// Membership
import MembershipSaga from "./membership/saga";

// Volunteer
import VolunteersSaga from "./volunteers/saga";
import CampaignSaga from "./campaigns/saga";

// Reports
import ReportingSaga from "./reporting/saga";

// User
import UsersSaga from "./users/saga";

// Teams
import TeamsSaga from "./teams/saga";

import ForgetPasswordSaga from "./auth/forgetpwd/saga";
// import ProfileSaga from "./auth/profile/saga";

import CrmSaga from "./crm/saga";

import NotesSaga from "./notes/saga";

// Services
import ServicesSaga from "./services/saga";
import DonationSaga from "./donations/saga";

// billing
import BillingSaga from "./billing/saga";

// statistics
import StatisticsSaga from "./statistics/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(ForgetPasswordSaga),

    fork(CreateOrganisationsSaga),
    fork(ProfileSaga),

    fork(TaskSaga),
    fork(CaseSaga),
    fork(ClientsSaga),
    // fork(ClientsReportSaga),
    fork(ReportingSaga),

    // fork Volunteers
    fork(VolunteersSaga),

    fork(UsersSaga),
    fork(TeamsSaga),
    fork(CrmSaga),
    fork(MembershipSaga),
    fork(NotesSaga),
    // fork(orgSaga),
    fork(ServicesSaga),
    fork(CalendarSaga),
    fork(DonationSaga),
    fork(BillingSaga),
    fork(CampaignSaga),
    fork(StatisticsSaga),
  ]);
}
