import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {
  getCasesList,
  getDonationsList,
  getEvents,
  getProfile,
  getTaskList,
  getUsers,
  getContacts as onGetContacts,
  resetCaseFlag,
} from "../../store/actions";
import MyTasks from "./MyTasks";
import ProjectsOverview from "./ProjectsOverview";
import ProjectsStatus from "./ProjectsStatus";
import UpcomingSchedules from "./UpcomingSchedules";
import Widgets from "./Widgets";

const DashboardProject = () => {
  const dispatch = useDispatch();

  const {
    casesList,
    crmusers,
    isCaseCreated,
    isCaseSuccess,
    error,
    activeDonor,
    monthlyActiveDonors,
    events,
    tasks,
    monthly_cases,
    page,
    stats,
    context,
    donations,
    isCaseAdd,
    isCaseAddFail,
    isCaseDelete,
    isCaseDeleteFail,
    isCaseUpdate,
    isCaseUpdateFail,
  } = useSelector((state) => ({
    casesList: state.Cases.casesList,
    crmusers: state.Users.usersList,
    isCaseCreated: state.Cases.isCaseCreated,
    isCaseSuccess: state.Cases.isCaseSuccess,
    events: state.Calendar.events,
    tasks: state.Tasks.taskList,
    error: state.Cases.error,
    context: state.Donations.context,
    page: state.Cases.page,
    stats: state.Cases.stats,
    monthly_cases: state.Cases.monthly_cases,
    activeDonor: state.Crm.activeDonorCount,
    monthlyActiveDonors: state.Crm.monthlyActiveDonors,
    isCaseAdd: state.Cases.isCaseAdd,
    isCaseAddFail: state.Cases.isCaseAddFail,
    isCaseDelete: state.Cases.isCaseDelete,
    isCaseDeleteFail: state.Cases.isCaseDeleteFail,
    isCaseUpdate: state.Cases.isCaseUpdate,
    isCaseUpdateFail: state.Cases.isCaseUpdateFail,
  }));

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetCaseFlag());
    }, 3000);
  }, [
    dispatch,
    isCaseSuccess,
    error,
    isCaseAdd,
    isCaseAddFail,
    isCaseDelete,
    isCaseDeleteFail,
    isCaseUpdate,
    isCaseUpdateFail,
  ]);

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getUsers());
    dispatch(getCasesList());
    dispatch(getDonationsList());
    dispatch(onGetContacts());
    dispatch(getEvents());
    dispatch(getTaskList());
  }, [dispatch]);

  document.title = "Dashboard | SimplyNP";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Dashboard" pageTitle="Dashboard" />
          <Row className="project-wrapper">
            <Col xxl={8}>
              <Widgets monthly_cases={monthly_cases} context={context} />
              <ProjectsOverview context={context} activeDonor={activeDonor} monthlyActiveDonors={monthlyActiveDonors} />
            </Col>
            <UpcomingSchedules events={events} />
          </Row>
          <Row>
            {/* <ActiveProjects /> */}
            <MyTasks tasks={tasks} />
          </Row>
          <Row>
            {/* 
              <TeamMembers />
              <Chat />
              <ProjectsStatus />
            */}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardProject;
