import {
  GET_SERVICES_LIST,
  GET_SERVICE,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  ADD_NEW_SERVICE_SUCCESS,
  ADD_NEW_SERVICE_FAIL,
  ADD_NEW_SERVICE,
  UPDATE_SERVICE,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_FAIL,
  DELETE_SERVICE,
  DELETE_SERVICE_FAIL,
  DELETE_SERVICE_SUCCESS,
  RESET_SERVICE_FLAG,
} from "./actionType";

export const serviceApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const servicesApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getServicesList = (params) => ({
  type: GET_SERVICES_LIST,
  payload: params,
});

export const getService = (service) => ({
  type: GET_SERVICE,
  payload: service,
});

export const updateService = (service) => ({
  type: UPDATE_SERVICE,
  payload: service,
});

export const updateServiceSuccess = (service) => ({
  type: UPDATE_SERVICE_SUCCESS,
  payload: service,
});

export const updateServiceFail = (service) => ({
  type: UPDATE_SERVICE_FAIL,
  payload: service,
});

export const addNewService = (service) => ({
  type: ADD_NEW_SERVICE,
  payload: service,
});

export const addNewServiceSuccess = (service) => ({
  type: ADD_NEW_SERVICE_SUCCESS,
  payload: service,
});

export const addNewServiceFail = (error) => ({
  type: ADD_NEW_SERVICE_FAIL,
  payload: error,
});

export const deleteService = (service) => ({
  type: DELETE_SERVICE,
  payload: service,
});

export const deleteServiceSuccess = (service) => ({
  type: DELETE_SERVICE_SUCCESS,
  payload: service,
});

export const deleteServiceFail = (error) => ({
  type: DELETE_SERVICE_FAIL,
  payload: error,
});

export const resetServiceFlag = () => ({
  type: RESET_SERVICE_FLAG,
});
