module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    API_URL: process.env.REACT_APP_API_URL || "http://localhost:8000/api",
    BASE_URL: process.env.REACT_APP_API_BASE_URL || "http://localhost:8000",
    WS: process.env.REACT_APP_WS_API || "ws://127.0.0.1:8000/ws",
  },
};
