import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row } from "reactstrap";
import { Priority } from "../cases/ListView/CaseCol";
import { Status } from "../cases/ListView/CaseCol";
import DeleteModal from "../../Components/Common/DeleteModal";
import { deleteCase } from "../../store/actions";
import { useDispatch } from "react-redux";

const CasesList = ({ cases }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState([]);
  const dispatch = useDispatch();

  const onClickDelete = (deleteData, type) => {
    setDeleteData(deleteData);
    setDeleteModal(true);
  };

  const handleDeleteCase = () => {
    if (deleteData) {
      dispatch(deleteCase(deleteData));

      setDeleteModal(false);
    }
  };

  return (
    <Row>
      <DeleteModal show={deleteModal} onDeleteClick={handleDeleteCase} onCloseClick={() => setDeleteModal(false)} />

      {cases &&
        cases.map((casse) => {
          let priorityCellProps = {};
          priorityCellProps["value"] = casse.priority;

          let statusCellProps = {};
          statusCellProps["value"] = casse.status;

          return (
            <div key={casse.id} className="d-flex align-items-center py-3">
              <div className="flex-grow-1">
                <div>
                  <h5 className="fs-14 mb-1">{casse.title}</h5>
                  Priority: <Priority {...priorityCellProps} />
                  {` `} {/*  for space between top and bottom tags  */}
                  status: <Status {...statusCellProps} />
                  <p className="text-muted mb-0">Created on: {(casse && casse.created_on?.split("T")[0]) || ""}</p>
                  <p className="text-muted mb-0">Due date: {casse.due_date}</p>
                </div>
              </div>
              <ul className="list-inline hstack gap-3 mb-0">
                <li className="list-inline-item edit" title="Edit">
                  <Link className="text-muted d-inline-block" to={"/updatecase/" + casse.id}>
                    <i className="ri-edit-2-line fs-16 align-bottom me-2 text-muted"></i>
                  </Link>
                </li>
                <li className="list-inline-item edit" title="view details">
                  <Link to={{ pathname: "/case-details-page/" + casse.id }} className="text-muted d-inline-block">
                    <i className="ri-eye-fill me-2 align-middle text-muted" />
                    View
                  </Link>
                </li>
                <li className="list-inline-item edit" title="Delete">
                  <Link
                    to="#"
                    onClick={() => {
                      onClickDelete(casse);
                    }}
                    className="text-muted d-inline-block"
                  >
                    <i className="ri-delete-bin-fill fs-16"></i>
                  </Link>
                </li>
              </ul>
            </div>
          );
        })}
    </Row>
  );
};

export default CasesList;
