import React from "react";
import AddUserForm from "./FormComponent/CreateUserForm";

const CreateUser = () => {
  document.title = "Create Lead | Velzon - React Admin & Dashboard Template";

  return (
    // <div className="page-content">
    <AddUserForm title="Create User" createUser />
    // </div>
  );
};

export default CreateUser;
