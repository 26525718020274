import React from "react";
import { Container } from "reactstrap";
// import BreadCrumb from "../../../Components/Common/BreadCrumb";
import UpdateForm from "./form";

const UpdateContact = () => {
  document.title = "Update Contact | SimplyNP - Xacent Global ";

  return <UpdateForm update="update" title="Update Contact" />;
};

export default UpdateContact;
