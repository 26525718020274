import {
  GET_TASK_LIST,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_TASK_SUCCESS,
  ADD_TASK_FAIL,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_FAIL,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAIL,
  RESET_TASK_FLAG,
} from "./actionType";

const INIT_STATE = {
  taskList: null,
};

const Tasks = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_TASK_LIST:
          return {
            ...state,
            taskList: action.payload.data.tasks,
            page: action.payload.data.page,
            stats: action.payload.data.stats,
            isTaskCreated: false,
            isTaskSuccess: true,
            isLoading: false,
          };

        default:
          return { ...state };
      }

    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_TASK_LIST:
          return {
            ...state,
            error: action.payload.error,
            isTaskCreated: false,
            isTaskSuccess: true,
            isLoading: false,
          };

        default:
          return { ...state };
      }

    case GET_TASK_LIST: {
      return {
        ...state,
        isTaskCreated: false,
        isLoading: true,
      };
    }

    case ADD_TASK_SUCCESS:
      return {
        ...state,
        isTaskCreated: true,
        // taskList: [...state.taskList, action.payload.data],
        isTaskAdd: true,
        isTaskAddFail: false,
      };

    case ADD_TASK_FAIL:
      return {
        ...state,
        error: action.payload,
        isTaskAdd: false,
        isTaskAddFail: true,
      };

    case UPDATE_TASK_SUCCESS:
      return {
        ...state,
        // taskList: state.taskList.map((task) =>
        //   task.id.toString() === action.payload.task.id.toString() ? { ...task, ...action.payload.task } : task
        // ),
        isTaskUpdate: true,
        isTaskUpdateFail: false,
      };

    case UPDATE_TASK_FAIL:
      return {
        ...state,
        error: action.payload,
        isTaskUpdate: false,
        isTaskUpdateFail: true,
      };

    case DELETE_TASK_SUCCESS:
      return {
        ...state,
        taskList: state.taskList.filter((task) => task.id.toString() !== action.payload.task.toString()),
        isTaskDelete: true,
        isTaskDeleteFail: false,
      };

    case DELETE_TASK_FAIL:
      return {
        ...state,
        error: action.payload,
        isTaskDelete: false,
        isTaskDeleteFail: true,
      };

    case RESET_TASK_FLAG:
      return {
        ...state,
        isTaskAdd: false,
        isTaskAddFail: false,
        isTaskDelete: false,
        isTaskDeleteFail: false,
        isTaskUpdate: false,
        isTaskUpdateFail: false,
        error: false,
      };
    default:
      return { ...state };
  }
};

export default Tasks;
