import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Form, Label, Card, FormFeedback, Input, CardHeader, CardBody, Container } from "reactstrap";
import { api } from "../../config";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link, useHistory } from "react-router-dom";

import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";

import { resetForgotPasswordFlag, userResetPassword as onResetPassword } from "../../store/actions";

// import images
import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "./ParticlesAuth";
import MsgToast from "../../Components/Common/MsgToast";

const ResetUserPassword = (props) => {
  const dispatch = useDispatch();
  const { uid, token, delta_hours } = useParams();
  const history = useHistory();
  const [expired, setExpired] = useState(false);

  const { resetStatus, reset, resetStatusMsg } = useSelector((state) => ({
    reset: state.ForgetPassword.reset,
    resetStatus: state.ForgetPassword.resetStatus,
    resetStatusMsg: state.ForgetPassword.resetStatusMsg,
  }));

  useEffect(() => {
    // DETERMINE IF THE PASSWORD RESET HAS TIMED OUT
    let unPrepared = delta_hours.split("-").map((n) => Number(n));
    unPrepared[1] = unPrepared[1] - 1;
    let expDate = new Date(...unPrepared).getTime();
    let today = Date.now();

    if (today > expDate) {
      //console.log("Link Has Expired");
      setExpired(true);
    } else {
      //console.log({ uid, token, delta_hours, date: expDate, today });
    }
  }, []);

  useEffect(() => {
    const switchPage = async () => {
      setTimeout(() => dispatch(resetForgotPasswordFlag()), 3000);
      if (resetStatus === true) {
        setTimeout(() => history.push("/login"), 4000);
      }
    };

    switchPage();
  }, [dispatch, history, reset, resetStatus]);

  const passwordForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      new_password1: "",
      new_password2: "",
    },
    validationSchema: yup.object({
      new_password1: yup
        .string()
        .required("Enter a new password")
        .min(8, "8 Minimum Characters")
        .max(25, "25 Maximum Characters")
        .matches(/^(?=.*[a-z])/, "Must container one lowercase character"),
      // .matches(/^(?=.*[A-Z])/, "Must container one uppercase character")
      // .matches(/^(?=.*[0-9])/, "Must container one number")
      // .matches(/^(?=.*[!@#$%^&*+])/, "Must container one special character"),
      new_password2: yup
        .string()
        .required("Please confirm your new password")
        .oneOf([yup.ref("new_password1"), null], "Passwords do not match"),
    }),
    onSubmit: (values) => {
      dispatch(onResetPassword({ uid, token }, values));
      passwordForm.setSubmitting(false);
    },
  });

  document.title = "Reset Password | SimplyNP";
  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="20" />
                  </Link>
                </div>
                <p className="mt-3 fs-15 fw-medium">The better way to manage your NON-Profit</p>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col xxl={6} className="d-flex flex-column">
              <Card className="mt-4">
                <CardHeader className="mb-1">
                  <h4 className="card-title mb-0">Change Password</h4>
                </CardHeader>
                <CardBody>
                  <Form
                    className="mb-4"
                    onSubmit={(e) => {
                      e.preventDefault();
                      passwordForm.handleSubmit();
                    }}
                  >
                    <Col className="px-4">
                      {reset ? (
                        <Alert color={resetStatus ? "success" : "danger"} style={{ marginTop: "13px" }}>
                          {resetStatusMsg}
                        </Alert>
                      ) : null}
                      {expired ? (
                        <Row>
                          <div className="p-2">
                            <Alert color="danger" style={{ marginTop: "13px" }}>
                              This Password Reset Link Has Expired, Please Request Another,{" "}
                              <Link to="/forgot-password">Here</Link>
                            </Alert>
                          </div>
                        </Row>
                      ) : (
                        <Col>
                          <Row sm={12}>
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="new_password1-input">
                                New Password
                              </Label>
                              <Input
                                type="text"
                                name="new_password1"
                                value={passwordForm.values.new_password1}
                                onChange={passwordForm.handleChange}
                                className="form-control"
                                id="new_password1-input"
                                invalid={
                                  passwordForm.touched.new_password1 && passwordForm.errors.new_password1 ? true : false
                                }
                                placeholder="New Password"
                              />
                              {passwordForm.touched.new_password1 && passwordForm.errors.new_password1 ? (
                                <FormFeedback type="invalid">{passwordForm.errors.new_password1}</FormFeedback>
                              ) : null}
                            </div>
                          </Row>

                          <Row>
                            <Col>
                              <div className="mb-3">
                                <div className="d-flex">
                                  <div className="flex-shrink-0">
                                    <i className="ri-checkbox-circle-fill text-success"></i>
                                  </div>
                                  <div className="flex-grow-1 ms-2">One Lowercase Character</div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <div className="d-flex">
                                  <div className="flex-shrink-0">
                                    <i className="ri-checkbox-circle-fill text-success"></i>
                                  </div>
                                  <div className="flex-grow-1 ms-2">One Uppercase Character</div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <div className="d-flex">
                                  <div className="flex-shrink-0">
                                    <i className="ri-checkbox-circle-fill text-success"></i>
                                  </div>
                                  <div className="flex-grow-1 ms-2">One Number</div>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div className="mb-3">
                                <div className="d-flex">
                                  <div className="flex-shrink-0">
                                    <i className="ri-checkbox-circle-fill text-success"></i>
                                  </div>
                                  <div className="flex-grow-1 ms-2">One Special Character</div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <div className="d-flex">
                                  <div className="flex-shrink-0">
                                    <i className="ri-checkbox-circle-fill text-success"></i>
                                  </div>
                                  <div className="flex-grow-1 ms-2">8 Characters Minimum</div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <div className="d-flex">
                                  <div className="flex-shrink-0">
                                    <i className="ri-checkbox-circle-fill text-success"></i>
                                  </div>
                                  <div className="flex-grow-1 ms-2">50 Characters Maximum</div>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row sm={12}>
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="new_password2-input">
                                Confirm New Password
                              </Label>
                              <Input
                                type="text"
                                value={passwordForm.values.new_password2}
                                onChange={passwordForm.handleChange}
                                name="new_password2"
                                className="form-control"
                                invalid={
                                  passwordForm.touched.new_password2 && passwordForm.errors.new_password2 ? true : false
                                }
                                id="new_password2-input"
                                placeholder="Confirm New Password"
                              />
                              {passwordForm.touched.new_password2 && passwordForm.errors.new_password2 ? (
                                <FormFeedback type="invalid">{passwordForm.errors.new_password2}</FormFeedback>
                              ) : null}
                            </div>
                          </Row>

                          <Row sm={12} className="mt-4">
                            <Input
                              disabled={passwordForm.isSubmitting}
                              // onClick={() => passwordForm.handleSubmit()}
                              type="submit"
                              name="submit"
                              value="Change Password"
                              className="text-white bg-success border-0"
                            />
                          </Row>
                        </Col>
                      )}
                    </Col>

                    {/* {reset ? (
                      <MsgToast
                        msg={resetStatusMsg}
                        color={resetStatus ? "success" : "error"}
                        icon={resetStatus ? "ri-error-warning-line" : "ri-success-line"}
                      />
                    ) : null} */}
                    {/* {userResetPasswordError ? (
                      <MsgToast
                        msg={
                          passwordChangeFail &&
                          passwordChangeFail?.non_field_errors &&
                          passwordChangeFail?.non_field_errors[0]
                        }
                        color="danger"
                        icon="ri-error-warning-line"
                      />
                    ) : null} */}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

ResetUserPassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ResetUserPassword);
