import React from "react";
import { Container } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import CreateLeadForm from "./FormComponents/CreateLeadForm";

const UpdateLead = () => {
  document.title = "Update Lead | Velzon - React Admin & Dashboard Template";

  return (
    // <div className="page-content">
    <CreateLeadForm updateLead title="Update Lead" />
    // </div>
  );
};

export default UpdateLead;
