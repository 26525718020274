import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";

import { useProfile } from "../Components/Hooks/UserHooks";
import { logoutUser } from "../store/actions";

const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { userProfile, loading, token, isActive } = useProfile();

  useEffect(() => {
    if (userProfile && !loading && token) {
      setAuthorization(token);
    } else if (!userProfile && !loading && !token) {
      dispatch(logoutUser());
    }
  }, [token, userProfile, loading, dispatch]);

  /*
    redirect is un-auth access protected routes via url
    */
  if (!userProfile && loading && !token) {
    return <Redirect to={{ pathname: "/login", state: { from: pathname } }} />;
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { AccessRoute, AuthProtected };
