import React, { useEffect, useMemo, useState, useCallback } from "react";

import { Input, Card, CardBody, Col, Container, Row, CardHeader } from "reactstrap";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import OffCanvasFilter from "./OffCanvasFilter";
import ReportTableContainer from "../../../Components/Common/ReportTableContainer";

//Import actions
import { getCasesStats, runReport as onRunReport } from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

// Formik

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { SimpleRadar } from "../../../Components/Common/RadarChart";

const Gender = () => {
  const dispatch = useDispatch();

  const [toggleFilterCanvas, setToggleFilterCanvas] = useState(false);

  const { currentReport, loading } = useSelector((state) => ({
    currentReport: state.Reporting.currentReport,

    loading: state.Membership.loading,
  }));

  useEffect(() => {
    dispatch(
      onRunReport({
        group_by: "gender",
        date_field: "created_on",
        columns: ["gender", "__count__"],
        offset: 0,
        limit: 30,
      })
    );
  }, []);

  // Column
  const servicesColumns = useMemo(
    () => [
      {
        Header: "gender",
        accessor: "gender",
        filterable: false,
        Cell: (data) => {
          let obj = data.row.original;
          if (!obj.gender) {
            return "N/A";
          }

          return <>{obj.gender}</>;
        },
      },

      {
        Header: "Count",
        accessor: "__count__",
        filterable: false,
      },
    ],
    []
  );

  let getChartData = (data = []) => {
    if (!data.length) return;
    let labels = [];
    let series = [];

    data.forEach((obj) => {
      labels.push(Object.values(obj)[0]);
      series.push(Object.values(obj)[1]);
    });

    return { labels, series };
  };

  let servicesChart = getChartData(currentReport?.data);

  document.title = "Demographics report | SimplyNP";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Demographics" pageTitle="Demographics" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <div className="text-center py-0">
                      <h5>Clients grouped by gender</h5>

                      <SimpleRadar data={servicesChart} />
                    </div>
                  </Row>
                </CardBody>

                <div className="text-center py-5">
                  <ReportTableContainer
                    tableClass="table-bordered"
                    divClass=" table-responsive"
                    columns={servicesColumns}
                    data={currentReport?.data || []}
                    customFetchData={() => []}
                  />
                </div>
              </Card>

              <Card>
                <ToastContainer limit={1} closeButton={false} />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <OffCanvasFilter show={toggleFilterCanvas} onCloseClick={() => setToggleFilterCanvas(false)} />
    </React.Fragment>
  );
};

export default Gender;
