import React from "react";
import AddDonationForm from "./FormComponents/CreateDonationForm";

const CreateDonation = () => {
  document.title = "Create Donation | Velzon - React Admin & Dashboard Template";

  return (
    // <div className="page-content">
    <AddDonationForm title="Create Donation" />
    // </div>
  );
};

export default CreateDonation;
