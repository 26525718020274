import * as moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";

const MyTasks = ({ tasks }) => {
  const getPagTask = tasks && tasks.slice(0, 4);

  const statusClass =
    tasks &&
    tasks.map((task) => {
      return task.status === "Completed"
        ? "success"
        : task.status === "New"
        ? "warning"
        : task.status === "Pending"
        ? "danger"
        : "";
    });

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const img =
    tasks &&
    tasks.map((task) => {
      const assg = task.assigned_to.map((user) => {
        return user.user_details.profile_pic;
      });
      return assg || null;
    });

  const formattedDates =
    tasks &&
    tasks.map((task) => {
      return handleValidDate(task.due_date);
    });

  const uniqueStatuses = Array.from(new Set(tasks && tasks.map((task) => task.status)));

  return (
    <React.Fragment>
      <Col className="col-xxl-4">
        <Card>
          <CardHeader className="card-header align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1 py-1">My Tasks</h4>
            <div className="flex-shrink-0">
              <UncontrolledDropdown className="card-header-dropdown">
                <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                  <span className="text-muted">
                    All Tasks <i className="mdi mdi-chevron-down ms-1"></i>
                  </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  {uniqueStatuses.map((status, index) => (
                    <DropdownItem key={index}>{status}</DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </CardHeader>
          <CardBody>
            <div className="table-responsive table-card">
              <table className="table table-borderless table-nowrap table-centered align-middle mb-0">
                <thead className="table-light text-muted">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Deadline</th>
                    <th scope="col">Status</th>
                    <th scope="col">Assignee</th>
                  </tr>
                </thead>
                <tbody>
                  {(getPagTask || []).map((item, key) => (
                    <tr key={key}>
                      <td>
                        <div className="form-check">
                          <input className="form-check-input fs-15" type="checkbox" value="" />
                          <label className="form-check-label ms-1">{item.title}</label>
                        </div>
                      </td>
                      <td className="text-muted">{formattedDates[key]}</td>
                      <td>
                        <span className={"badge badge-soft-" + statusClass[key]}>{item.status}</span>
                      </td>
                      <td>
                        {img[key] &&
                          img[key].map((url, idx) => (
                            <Link
                              to="#"
                              className="d-inline-block"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title=""
                              data-bs-original-title="User"
                              key={idx}
                            >
                              <img src={url || dummyImg} alt="" className="rounded-circle avatar-xxs" />
                            </Link>
                          ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-3 text-center">
              <Link to="/tasks" className="text-muted text-decoration-underline">
                Load More
              </Link>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default MyTasks;
