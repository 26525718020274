import {
  CREATE_REPORT,
  CREATE_REPORT_ERROR,
  CREATE_REPORT_SUCCESS,
  DELETE_REPORT,
  DELETE_REPORT_ERROR,
  DELETE_REPORT_SUCCESS,
  GET_REPORTS_LIST,
  GET_REPORTS_LIST_ERROR,
  GET_REPORTS_LIST_SUCCESS,
  RESET_REPORT_FLAGS,
  RUN_REPORTS,
  RUN_REPORTS_ERROR,
  RUN_REPORTS_SUCCESS,
  UPDATE_REPORT,
  UPDATE_REPORT_ERROR,
  UPDATE_REPORT_SUCCESS,
} from "./actionType";

const INITIAL_STATE = {
  report: null,
};

const Reporting = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RUN_REPORTS:
      return { ...state, isReportRun: true, isRunningReport: true };
    case RUN_REPORTS_SUCCESS:
      return {
        ...state,
        currentReport: action.payload,
        loading: false,
        isReportRun: false,
        isReportRunSuccess: true,
        isReportRunFailed: false,
        isRunningReport: false,
      };
    case RUN_REPORTS_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        loading: false,
        isReportRun: false,
        isReportRunFailed: true,
        isReportRunSuccess: false,
        isRunningReport: false,
      };

    case UPDATE_REPORT:
      return {
        ...state,
      };
    case UPDATE_REPORT_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        isUpdateSuccess: false,
        isUpdateFailed: true,
      };
    case UPDATE_REPORT_SUCCESS:
      return {
        ...state,
        isUpdateSuccess: true,
      };

    case DELETE_REPORT:
      return {
        ...state,
      };
    case DELETE_REPORT_ERROR:
      return {
        ...state,
        isDeleteFailed: true,
        isDeleteSuccess: false,
        error: true,
        errorMessage: action.payload,
      };
    case DELETE_REPORT_SUCCESS:
      return {
        ...state,
        isDeleteFailed: false,
        isDeleteSuccess: true,
      };

    case CREATE_REPORT:
      return {
        ...state,
      };
    case CREATE_REPORT_ERROR:
      return {
        ...state,
        isCreateFailed: true,
        isCreateSuccess: false,
      };
    case CREATE_REPORT_SUCCESS:
      return {
        ...state,
        isCreateFailed: false,
        isCreateSuccess: true,
      };

    case GET_REPORTS_LIST:
      return {
        ...state,
      };
    case GET_REPORTS_LIST_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
      };
    case GET_REPORTS_LIST_SUCCESS:
      return {
        ...state,
        reportList: action.payload.reports_obj_list,
        data: action.payload,
      };

    case RESET_REPORT_FLAGS:
      return {
        ...state,
        error: false,
        loading: false,
        isReportRun: false,
        isReportRunSuccess: false,
        isReportRunFailed: false,
        isDeleteFailed: false,
        isDeleteSuccess: false,
        isUpdateFailed: false,
        isUpdateSuccess: false,
        isCreateFailed: false,
        isCreateSuccess: false,
        isGetReportFailed: false,
        isGetReportSuccess: false,
      };
    default:
      return state;
  }
};

export default Reporting;
