export const CREATE_BILLING = "CREATE_BILLING";
export const CREATE_BILLING_SUCCESSFUL = "CREATE_BILLING_SUCCESSFUL";
export const CREATE_BILLING_ERROR = "CREATE_BILLING_ERROR";
export const CREATE_BILLING_FAILED = "CREATE_BILLING_FAILED";
export const RESET_BILLING_FLAG = "RESET_BILLING_FLAG";
export const GET_BILLING = "GET_BILLING";
export const GET_BILLING_SUCCESS = "GET_BILLING_SUCCESS";
export const GET_BILLING_FAIL = "GET_BILLING_FAIL";

export const DELETE_BILLING = "DELETE_BILLING";
export const DELETE_BILLING_SUCCESSFUL = "DELETE_BILLING_SUCCESSFUL";
export const UPDATE_BILLING = "UPDATE_BILLING";
export const UPDATE_BILLING_SUCCESSFUL = "UPDATE_BILLING_SUCCESSFUL";
export const UPDATE_BILLING_FAIL = "UPDATE_BILLING_FAIL";

export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
export const ADD_PAYMENT_METHOD_SUCCESS = "ADD_PAYMENT_METHOD_SUCCESS";
export const ADD_PAYMENT_METHOD_FAIL = "ADD_PAYMENT_METHOD_FAIL";

export const REMOVE_PAYMENT_METHOD = "REMOVE_PAYMENT_METHOD";
export const REMOVE_PAYMENT_METHOD_SUCCESS = "REMOVE_PAYMENT_METHOD_SUCCESS";
export const REMOVE_PAYMENT_METHOD_FAIL = "REMOVE_PAYMENT_METHOD_FAIL";

export const GET_PAYMENT_METHOD = "GET_PAYMENT_METHOD";
export const GET_PAYMENT_METHOD_SUCCESS = "GET_PAYMENT_METHOD_SUCCESS";
export const GET_PAYMENT_METHOD_FAIL = "GET_PAYMENT_METHOD_FAIL";

export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS";
export const UPDATE_PAYMENT_METHOD_FAIL = "UPDATE_PAYMENT_METHOD_FAIL";
