import {
  CREATE_BILLING,
  CREATE_BILLING_SUCCESSFUL,
  GET_ACCOUNT_LIST_SUCCESS,
  CREATE_BILLING_ERROR,
  CREATE_BILLING_FAILED,
  RESET_BILLING_FLAG,
  DELETE_BILLING_SUCCESSFUL,
  UPDATE_BILLING_SUCCESSFUL,
  UPDATE_BILLING_FAIL,
  GET_BILLING_SUCCESS,
  GET_BILLING,
  GET_BILLING_FAIL,
  ADD_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD_SUCCESS,
  ADD_PAYMENT_METHOD_FAIL,
  UPDATE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_FAIL,
  REMOVE_PAYMENT_METHOD,
  REMOVE_PAYMENT_METHOD_SUCCESS,
  REMOVE_PAYMENT_METHOD_FAIL,
  GET_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD_FAIL,
} from "./actionTypes";

const initialState = {
  errorMessage: null,
  loading: true,
  user: null,
  success: false,
};

const Billing = (state = initialState, action) => {
  switch (action.type) {
    case GET_BILLING:
      state = {
        ...state,
        loading: true,
        getBillingSuccess: false,
      };
      break;
    case GET_BILLING_SUCCESS:
      state = {
        ...state,
        loading: false,
        getBillingSuccess: true,
        billing_info: action.payload,
      };
      break;

    case GET_BILLING_FAIL:
      state = {
        ...state,
        loading: false,
        getBillingSuccess: false,
        errorMessage: action.payload,
      };
      break;

    case CREATE_BILLING:
      state = {
        ...state,
        loading: true,
        createBillingFailed: null,
      };
      break;

    case ADD_PAYMENT_METHOD:
      state = {
        ...state,
        loading: true,
      };
      break;

    case ADD_PAYMENT_METHOD_FAIL:
      state = {
        ...state,
        loading: false,
        error: true,
        errorObject: action.payload,
      };
      break;

    case ADD_PAYMENT_METHOD_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
      };
      break;

    case UPDATE_PAYMENT_METHOD:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPDATE_PAYMENT_METHOD_FAIL:
      state = {
        ...state,
        loading: false,
        error: true,
        errorObject: action.payload,
      };
      break;

    case UPDATE_PAYMENT_METHOD_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        billing_info: action.payload,
      };
      break;

    case UPDATE_BILLING_SUCCESSFUL:
      state = {
        ...state,
        updateBillingSuccess: true,
        billing_info: action.payload,
      };
      break;

    case CREATE_BILLING_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        billing_info: action.payload,
        create_billing_success: true,
      };
      break;

    case CREATE_BILLING_ERROR:
      state = {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
      break;

    case CREATE_BILLING_FAILED:
      state = {
        ...state,
        loading: false,
        errorMessage: action.payload,
        error: true,
      };
      break;

    case UPDATE_BILLING_FAIL:
      state = {
        ...state,
        errorMessage: action.payload,
        updateBillingSuccess: false,
      };
      break;

    case DELETE_BILLING_SUCCESSFUL:
      state = {
        ...state,
        deleteBillingSuccess: true,
        billing_info: null,
      };
      break;

    case REMOVE_PAYMENT_METHOD_SUCCESS:
      state = {
        ...state,
        deleteBillingSuccess: true,
        billing_info: null,
      };
      break;

    case REMOVE_PAYMENT_METHOD_FAIL:
      state = {
        ...state,
        deleteBillingSuccess: false,
      };
      break;

    case RESET_BILLING_FLAG:
      state = {
        ...state,
        success: false,
        loading: false,
        deleteBillingSuccess: false,
        updateBillingSuccess: false,
        create_billing_success: false,
        errorMessage: null,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Billing;
