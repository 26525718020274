// ACCOUNTS
export const GET_ACCOUNTS_LIST = `/accounts/`;
export const CREATE_ACCOUNTS = `/accounts/`;
export const DELETE_ACCOUNTS_ATTACHMENT = `/accounts/attachment/{id}/`;
export const UPDATE_ACCOUNTS_COMMENT = `/accounts/comment/{id}/`;
export const DELETE_ACCOUNTS_COMMENT = `/accounts/comment/{id}/`;
export const GET_ACCOUNT = `/accounts/{id}/`;
export const CREATE_ACCOUNT = `/accounts/{id}/`;
export const UPDATE_ACCOUNT = `/accounts/{id}/`;
export const DELETE_ACCOUNT = `/accounts/{id}/`;
export const CREATE_ACCOUNT_MAIL = `/accounts/{id}/create_mail/`;

// SETTINGS
export const GET_API_SETTINGS_LIST = `/api-settings/`;
export const CREATE_API_SETTINGS = `/api-settings/`;
export const GET_API_SETTINGS = `/api-settings/{id}/`;
export const UPDATE_API_SETTINGS = `/api-settings/{id}/`;
export const DELETE_API_SETTINGS = `/api-settings/{id}/`;

// AUTH
export const FORGORT_PASSWORD = `/auth/forgot-password/`;
export const GOOGLE_CREATE = `/auth/google/`;
export const LOGIN = `/auth/login/`;
export const LOGOUT = `/auth/logout/`;
export const REGISTER = `/auth/register/`;
export const RESEND_ACTIVATION_LINK = `/auth/resend-activation-link/`;
export const RESET_PASSWORD = `/auth/reset-password/uid/token/`;
export const REFRESH_TOKEN = `/auth/token/refresh/`;
export const GET_ORG_LIST = `/auth/create-org/`;
export const CREATE_ORG = `/auth/create-org/`;
export const CREATE_ORG_ADDRESS = `/auth/org/billing`;
export const UPDATE_ORG_ADDRESS = `/auth/org/billing`;
export const UPDATE_ORG = `/auth/create-org/`;

// BILLING
export const GET_PAYMENT_METHOD = `/snp-subscriptions/payment-method/`;
export const DELETE_PAYMENT_METHOD = `/snp-subscriptions/payment-method/`;
export const CREATE_PAYMENT_METHOD = `/snp-subscriptions/payment-method/`;
export const UPDATE_PAYMENT_METHOD = `/snp-subscriptions/payment-method/`;
export const ADD_PAYMENT_METHOD = `/snp-subscriptions/payment-method/`;

//export const CONFIRM_PAYMENT_METHOD = `snp-subscriptions/confirm_payment/`;
export const GET_BILLING_LIST = "/snp-subscriptions/billing/";
export const CREATE_BILLING = `snp-subscriptions/billing/`;
export const GET_BILLING = `/snp-subscriptions/billing/`;
export const UPDATE_BILLING = `snp-subscriptions/billing/`;
export const DELETE_BILLING = `snp-subscriptions/billing/`;

export const GET_PLAN = "/snp-subscriptions/plan/";

// BILLING X SUBSCRIPTION MGMT
export const SIMPLYNP_SUBSCRIPTION_CHECKOUT_SESSION = "/snp-subscriptions/";
export const SIMPLYNP_CUSTOMER_PORTAL_SESSION = "/snp-subscriptions/customer-portal/";
export const GET_PUBLISHABLE_KEY = "/snp-subscriptions/config/";
export const GET_PUBLISHABLE_KEY_AND_PRODUCTS = "/snp-subscriptions/subscription/";
export const CREATE_SIMPLYNP_SUBSCRIPTION = "/snp-subscriptions/subscription/";
export const UPDATE_SIMPLYNP_SUBSCRIPTION = "/snp-subscriptions/subscription/";
export const CANCEL_SIMPLYNP_SUBSCRIPTION = "/snp-subscriptions/subscription/";
export const MANAGE_SIMPLYNP_CUSTOMER = "/snp-subscriptions/customer/";
export const CONNECT_ONBOARDING_LINK = "/snp-subscriptions/connected-account-onboarding/";
export const CREATE_SIMPLYNP_STRIPE_CONNECT = `/snp-subscriptions/connected-account/`;
export const RETRIEVE_SIMPLYNP_CONNECT_LOGIN_LINK = `/snp-subscriptions/connected-account/`;

// ACTIVATEUSER
export const ACTIVATE_USER = `auth/activate-user/uid/token/activation_key/`;

// CASES
export const GET_CASES_LIST = `/cases/`;
export const CREATE_CASES = `/cases/`;
export const DELETE_CASES_ATTACHMENT = `/cases/attachment/{id}`;
export const UPDATE_CASES_COMMENT = `/cases/comment/{id}/`;
export const DELETE_CASES_COMMENT = `/cases/comment/{id}/`;
export const GET_CASE = `/cases/{id}/`;
export const CREATE_CASE = `/cases/{id}/`;
export const UPDATE_CASE = `/cases/{id}/`;
export const DELETE_CASE = `/cases/{id}/`;
export const GET_CLIENT_CASES = "/cases/client/{id}/";

// CONTACTS
export const GET_CONTACTS_LIST = "/contacts/";
export const CREATE_CONTACTS = `/contacts/`;
export const DELETE_CONTACT_ATTACHMENT = `/contacts/attachment/{id}/`;
export const UPDATE_CONTACT_COMMENT = `/contacts/comment/{id}/`;
export const DELETE_CONTACT_COMMENT = `/contacts/comment/{id}/`;
export const GET_CONTACT = `/contacts/{id}/`;
export const CREATE_CONTACT = `/contacts/{id}/`;
export const UPDATE_CONTACT = `/contacts/{id}/`;
export const DELETE_CONTACT = `/contacts/{id}/`;

// MEMBERS
export const GET_MEMBERS_LIST = "/membership/members/";
export const CREATE_MEMBERS = `/membership/members/`;
export const DELETE_MEMBER_ATTACHMENT = `/membership/members/attachment/{id}/`;
export const UPDATE_MEMBER_COMMENT = `/membership/members/comment/{id}/`;
export const DELETE_MEMBER_COMMENT = `/membership/members/comment/{id}/`;
export const GET_MEMBER = `/membership/members/{id}/`;
export const CREATE_MEMBER = `/membership/members/{id}/`;
export const UPDATE_MEMBER = `/membership/members/{id}/`;
export const DELETE_MEMBER = `/membership/members/{id}/`;
export const CREATE_MEMBERSHIP_PAYMENT_INTENT = `/membership/{id}/checkout`;
export const GET_PAYMENT_PUBLISHABLE_KEY = `/membership/checkout/config`;

// MEMBERSHIP_LEVELS
export const GET_MEMBERSHIP_LEVELS_LIST = "/membership/levels/";
export const CREATE_MEMBERSHIP_LEVELS = `/membership/levels/`;
export const GET_MEMBERSHIP_LEVEL = `/membership/levels/{id}/`;
export const CREATE_MEMBERSHIP_LEVEL = `/membership/levels/{id}/`;
export const UPDATE_MEMBERSHIP_LEVEL = `/membership/levels/{id}/`;
export const DELETE_MEMBERSHIP_LEVEL = `/membership/levels/{id}/`;

// MEMBERSHIP_BENEFITS
export const GET_MEMBERSHIP_BENEFITS_LIST = "/membership/benefits/";
export const CREATE_MEMBERSHIP_BENEFITS = `/membership/benefits/`;
export const GET_MEMBERSHIP_BENEFIT = `/membership/benefits/{id}/`;
export const CREATE_MEMBERSHIP_BENEFIT = `/membership/benefits/{id}/`;
export const UPDATE_MEMBERSHIP_BENEFIT = `/membership/benefits/{id}/`;
export const DELETE_MEMBERSHIP_BENEFIT = `/membership/benefits/{id}/`;

// MEMBERSHIP_TYPES
export const GET_MEMBERSHIP_TYPES_LIST = "/membership/types/";
export const CREATE_MEMBERSHIP_TYPES = `/membership/types/`;
export const DELETE_MEMBERSHIP_TYPES_ATTACHMENT = `/membership/types/attachment/{id}/`;
export const UPDATE_MEMBERSHIP_TYPES_COMMENT = `/membership/types/comment/{id}/`;
export const DELETE_MEMBERSHIP_TYPES_COMMENT = `/membership/types/comment/{id}/`;
export const GET_MEMBERSHIP_TYPE = `/membership/types/{id}/`;
export const CREATE_MEMBERSHIP_TYPE = `/membership/types/{id}/`;
export const UPDATE_MEMBERSHIP_TYPE = `/membership/types/{id}/`;
export const DELETE_MEMBERSHIP_TYPE = `/membership/types/{id}/`;

// MEMBERSHIPS
export const GET_MEMBERSHIP_LIST = "/membership/";
export const CREATE_MEMBERSHIPS = `/membership/`;
export const DELETE_MEMBERSHIP_ATTACHMENT = `/membership/attachment/{id}/`;
export const UPDATE_MEMBERSHIP_COMMENT = `/membership/comment/{id}/`;
export const DELETE_MEMBERSHIP_COMMENT = `/membership/comment/{id}/`;
export const GET_MEMBERSHIP = `/membership/{id}/`;
export const CREATE_MEMBERSHIP = `/membership/{id}/`;
export const UPDATE_MEMBERSHIP = `/membership/{id}/`;
export const RENEW_MEMBERSHIP = `/membership/{id}/renew/`;
export const DELETE_MEMBERSHIP = `/membership/{id}/`;

// MEMBERSHIPS SITE CONFIG
export const GET_MEMBERSHIP_SITE_CONFIG = `/membership/site-config/`;
export const UPDATE_MEMBERSHIP_SITE_CONFIG = `/membership/site-config/`;

// CLIENTS
export const GET_CLIENTS_LIST = "/clients/";
export const CREATE_CLIENTS = `/clients/`;
export const DELETE_CLIENT_ATTACHMENT = `/clients/attachment/{id}/`;
export const CREATE_CLIENT_COMMENT = `/clients/{id}/`;
export const UPDATE_CLIENT_COMMENT = `/clients/comment/{id}/`;
export const DELETE_CLIENT_COMMENT = `/clients/comment/{id}/`;
export const GET_CLIENT = `/clients/{id}/`;
export const CREATE_CLIENT = `/clients/{id}/`;
export const UPDATE_CLIENT = `/clients/{id}/`;
export const DELETE_CLIENT = `/clients/{id}/`;
export const GET_CLIENT_DETAILS = `/clients/details/{id}/`;

// VOLUNTEERS
export const GET_VOLUNTEERS_LIST = "/volunteers/";
export const CREATE_VOLUNTEERS = `/volunteers/`;
export const DELETE_VOLUNTEER_ATTACHMENT = `/volunteers/attachment/{id}/`;
export const UPDATE_VOLUNTEER_COMMENT = `/volunteers/comment/{id}/`;
export const DELETE_VOLUNTEER_COMMENT = `/volunteers/comment/{id}/`;
export const GET_VOLUNTEER = `/volunteers/{id}/`;
export const CREATE_VOLUNTEER = `/volunteers/{id}/`;
export const UPDATE_VOLUNTEER = `/volunteers/{id}/`;
export const DELETE_VOLUNTEER = `/volunteers/{id}/`;

// REPORTS
export const RUN_REPORTS = "/reporting/run/";
export const GET_REPORTS_LIST = "/reporting/";
export const CREATE_REPORTS = "/reporting/";
export const DELETE_REPORT = "/reporting/{id}";
export const UPDATE_REPORT = "/reporting/{id}";
// EXPORT REPORT
export const EXPORT_REPORT = "/reporting/export/";

// DASHBOARD
export const GET_DASHBOARD_LIST = "/dashboard/";

// DOCUMENTS
export const GET_DOCUMENT_LIST = `/documents/`;
export const POST_DOCUMENT_CREATE = `/documents/`;
export const GET_DOCUMENT_READ = `/documents/{id}/`;
export const PUT_DOCUMENT_UPDATE = "/documents/{id}/";
export const DELETE_DOCUMENT = "/documents/{id}/";

// EVENTS
export const GET_EVENT_LIST = "/events/";
export const GET_EVENT_LIST_IN_RANGE = "/events/{start}/{end}/";
export const CREATE_EVENTS = "/events/";
export const DELETE_EVENT_ATTACHMENT = `/events/attachment/{id}/`;
export const UPDATE_EVENT_COMMENT = "/events/comment/{id}/";
export const DELETE_EVENT_COMMENT = `/events/comment/{id}/`;
export const GET_EVENT = `/events/{id}/`;
export const CREATE_EVENT = `/events/{id}/`;
export const UPDATE_EVENT = `/events/{id}/`;
export const DELETE_EVENT = `/events/{id}/`;

// LEADS
export const GET_LEADS_LIST = `/leads/`;
export const CREATE_LEADS = `/leads/`;
export const DELETE_LEADS_ATTACHMENT = "/leads/attachment/{id}/";
export const UPDATE_LEAD_COMMENT = `/leads/comment/{id}/`;
export const DELETE_LEAD_COMMENT = `/leads/comment/{id}/`;
export const CREATE_LEAD_FROM_SITE = `/leads/create-from-site/`;
export const IMPORT_LEADS = `/leads/upload/`;
export const GET_LEAD = `/leads/{id}/`;
export const POST_LEAD = `/leads/{id}/`;
export const UPDATE_LEAD = `/leads/{id}/`;
export const DELETE_LEAD = `/leads/{id}/`;

// DONATIONS
export const GET_DONATION_LIST = `/donations/`;
export const CREATE_DONATION = `/donations/`;
export const GET_DONATION = `/donations/{id}/`;
export const UPDATE_DONATION = `/donations/{id}`;
export const DELETE_DONATION = "/donations/{id}";

// OPPORTUNITIES
export const GET_OPPORTUNITIES_LIST = "/opportunities/";
export const CREATE_OPPORTUNITIES = `/opportunities/`;
export const DELETE_OPPORTUNITY_ATTACHMENT = `/opportunities/attachment/{id}/`;
export const UPDATE_OPPORTUNITY_COMMENT = `/opportunities/comment/{id}/`;
export const DELETE_OPPORTUNITY_COMMENT = `/opportunities/comment/{id}/`;
export const GET_OPPORTUNITY = `/opportunities/{id}/`;
export const CREATE_OPPORTUNITY = `/opportunities/{id}/`;
export const UPDATE_OPPORTUNITY = `/opportunities/{id}/`;
export const DELETE_OPPORTUNITY = `/opportunities/{id}/`;

// PROFILE
export const GET_PROFILE = `/profile/`;
export const UPDATE_PROFILE = `/profile/{id}/`;
export const PROFILE_CHANGE_PASSWORD = `/profile/change-password/`;

// Tasks
export const GET_TASKS_LIST = `/tasks/`;
export const CREATE_TASKS = `/tasks/`;
export const DELETE_TASK_ATTACHMENT = `/tasks/attachment/{id}/`;
export const UPDATE_TASK_COMMENT = `/tasks/comment/{id}/`;
export const DELETE_TASK_COMMENT = `/tasks/comment/{id}/`;
export const GET_TASK = `/tasks/{id}/`;
export const CREATE_TASK = `/tasks/{id}/`;
export const UPDATE_TASK = `/tasks/{id}/`;
export const DELETE_TASK = `/tasks/{id}/`;

// Campaigns
export const GET_CAMPAIGNS_LIST = `/campaigns/`;
export const CREATE_CAMPAIGNS = `/campaigns/`;
export const DELETE_CAMPAIGN_ATTACHMENT = `/campaigns/attachment/{id}/`;
export const UPDATE_CAMPAIGN_COMMENT = `/campaigns/comment/{id}/`;
export const DELETE_CAMPAIGN_COMMENT = `/campaigns/comment/{id}/`;
export const GET_CAMPAIGN = `/campaigns/{id}/`;
export const CREATE_CAMPAIGN = `/campaigns/{id}/`;
export const UPDATE_CAMPAIGN = `/campaigns/{id}/`;
export const DELETE_CAMPAIGN = `/campaigns/{id}/`;

// Teams
export const GET_TEAMS_LIST = `/teams/`;
export const CREATE_TEAMS = `/teams/`;
export const GET_TEAM = `/teams/{id}/`;
export const UPDATE_TEAM = `/teams/{id}/`;
export const DELETE_TEAM = `/teams/{id}/`;

// Users
export const GET_USERS_LIST = `/users/`;
export const CREATE_USERS = `/users/`;
export const GET_TEAMS_AND_USERS_LIST = `/users/get-teams-and-users/`;
export const GET_USER = `/users/{id}/`;
export const UPDATE_USER = `/users/{id}/`;
export const DELETE_USER = `/users/{id}/`;
export const UPDATE_USER_STATUS = "/users/{id}/status/";

// Notes
export const GET_NOTES_LIST = `/notes/`;
export const CREATE_NOTES = `/notes/`;
export const DELETE_NOTE_ATTACHMENT = `/notes/attachment/{id}/`;
export const UPDATE_NOTE_COMMENT = `/notes/comment/{id}/`;
export const DELETE_NOTE_COMMENT = `/notes/comment/{id}/`;
export const GET_NOTE = `/notes/{id}/`;
export const CREATE_NOTE = `/notes/{id}/`;
export const UPDATE_NOTE = `/notes/{id}/`;
export const DELETE_NOTE = `/notes/{id}/`;
export const GET_CLIENT_NOTES = "/notes/client/{id}/";
export const GET_CASE_NOTES = "/notes/case-notes/{id}/";

// Service
export const GET_SERVICES_LIST = `/services/`;
export const CREATE_SERVICE = `/services/`;
export const GET_SERVICE = `/services/{id}/`;
export const UPDATE_SERVICE = `/services/{id}/`;
export const DELETE_SERVICE = `/services/{id}/`;

// CLIENTS STATS
export const GET_CLIENTS_STATS = "/statistic/client/";
export const GET_CASES_STATS = "/statistic/cases/";
export const GET_SERVICES_STATS = "/statistic/services/";
export const GET_DONATIONS_ORG_STATS = "/statistic/donations-organization/";

export const GET_DONATIONS_CONTACTS_STATS = "/statistic/donations-contacts/";
export const GET_DONATIONS_CAMPAIGNS_STATS = "/statistic/donations-compaigns/";
export const GET_DEMOGRAPHIC_REPORTS_GROUPBY = "/statistic/demographics/";

export const GET_DONATIONS_OGS_BY_PATTERN_STATS = "/statistic/donations-organization-overtime/";
