import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { debounce, isEmpty } from "lodash";
import * as moment from "moment";

import Flatpickr from "react-flatpickr";

// Import Images
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";
import { handleValidDate, handleValidTime } from "../../Utility";

import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";

//Import actions
import {
  getUsersList as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
  resetUserFlag,
} from "../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import Loader from "../../Components/Common/Loader";
import MsgToast from "../../Components/Common/MsgToast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UsersList = () => {
  const dispatch = useDispatch();
  const {
    usersList,
    page,
    isUserCreated,
    isUserSuccess,
    isUserDelete,
    isUserDeleteFail,
    isUserUpdate,
    isUserUpdateFail,
    error,
    isUserAdd,
    isUserAddFail,
  } = useSelector((state) => ({
    isUserCreated: state.Users.isUsersCreated,
    isUserSuccess: state.Users.isUserSuccess,
    usersList: state.Users.usersList,
    page: state.Users.page,
    error: state.Users.error,
    isUserAdd: state.Users.isUserAdd,
    isUserAddFail: state.Users.isUserAddFail,
    isUserDelete: state.Users.isUserDelete,
    isUserDeleteFail: state.Users.isUserDeleteFail,
    isUserUpdate: state.Users.isUserUpdate,
    isUserUpdateFail: state.Users.isUserUpdateFail,
  }));

  const [user, setUser] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [sortBy, setsortBy] = useState(null);

  useEffect(() => {
    if (!usersList) {
      dispatch(onGetUsers());
    }
    setTimeout(() => {
      dispatch(resetUserFlag());
    }, 3000);
  }, [
    dispatch,
    isUserSuccess,
    error,
    isUserAdd,
    isUserAddFail,
    isUserDelete,
    isUserDeleteFail,
    isUserUpdate,
    isUserUpdateFail,
    usersList,
  ]);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(onGetUsers({ offset, limit }));
    },
    [dispatch]
  );

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return (
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="checkAll" value="option1" />
            </div>
          );
        },
      },

      {
        Header: "Role",
        accessor: "role",
        filterable: false,
      },
      //{
      //Header: "First Name",
      //accessor: "",
      //filterable: false,
      //Cell: (user) => <>{user.row.original.user_details.first_name}</>,
      //},
      //{
      //Header: "Last Name",
      //accessor: "",
      //filterable: false,
      //Cell: (user) => <>{user.row.original.user_details?.last_name}</>,
      //},
      {
        Header: "Email",
        accessor: "email",
        filterable: false,
        Cell: (user) => <>{user.row.original.user_details.email}</>,
      },
      {
        Header: "Date Of Joining",
        accessor: "date_of_joining",
      },
      {
        Header: "Status",
        accessor: "is_active",
        filterable: false,
        Cell: (user) => (
          <>
            {user.row.original.is_active ? (
              <span className={"badge badge-soft-success me-1"}>Active</span>
            ) : (
              <span className={"badge badge-soft-danger me-1"}>In-Active</span>
            )}
          </>
        ),
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Message">
                <Link
                  to={{
                    pathname: `/settings-update-user/${cellProps.row.original.id}`,
                  }}
                >
                  <i className="ri-edit-2-fill align-bottom me-2"></i>Edit
                </Link>
              </li>
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle href="#" className="btn-soft-secondary btn-sm dropdown" tag="button">
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      className="dropdown-item remove-item-btn"
                      onClick={() => {
                        const userData = cellProps.row.original;
                        onClickDelete(userData);
                      }}
                    >
                      <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const handlesortBy = (sortBy) => {
    setsortBy(sortBy);
  };

  const sortbyname = [
    { label: "Owner", value: "Owner" },
    { label: "Company", value: "Company" },
    { label: "Location", value: "Location" },
  ];

  // Delete Data
  const handleDeleteUser = () => {
    if (user) {
      dispatch(onDeleteUser(user));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (user) => {
    setUser(user);
    setDeleteModal(true);
  };

  const handleFieldSearch = useCallback(
    debounce((value) => {
      dispatch(onGetUsers({ search: value }));
    }, 1500),
    []
  );

  document.title = "Users | SimplyNP";
  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal show={deleteModal} onDeleteClick={handleDeleteUser} onCloseClick={() => setDeleteModal(false)} />
        <Container fluid>
          <BreadCrumb title="List Users" pageTitle="Users" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-1">
                      <Link to="settings-create-user">
                        <button className="btn btn-info add-btn">
                          <i className="ri-add-fill me-1 align-bottom"></i> Add User
                        </button>
                      </Link>
                    </div>
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                        <button
                          className="btn btn-soft-danger"
                          // onClick="deleteMultiple()"
                        >
                          <i className="ri-delete-bin-2-line"></i>
                        </button>
                        <button className="btn btn-danger">
                          <i className="ri-filter-2-line me-1 align-bottom"></i> Filters
                        </button>
                        <button className="btn btn-soft-success">Import</button>

                        <UncontrolledDropdown>
                          <DropdownToggle href="#" className="btn btn-soft-info" tag="button">
                            <i className="ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" href="#">
                              All
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Week
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Month
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Year
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col>
              <Card id="userList">
                <CardHeader>
                  <Row className="g-3">
                    <Col md={4}>
                      <div className="search-box">
                        <Input
                          type="text"
                          onChange={(e) => handleFieldSearch(e.target.value)}
                          className="form-control search"
                          placeholder="Search for user..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    <div className="col-md-auto ms-auto">
                      <div className="d-flex align-items-center gap-2">
                        <span className="text-muted">Sort by: </span>
                        <Select
                          className="mb-0"
                          value={sortBy}
                          onChange={() => {
                            handlesortBy();
                          }}
                          options={sortbyname}
                          id="choices-single-default"
                        ></Select>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    {isUserSuccess && usersList && usersList.length ? (
                      <TableContainer
                        columns={columns}
                        data={usersList || []}
                        customFetchData={fetchData}
                        customTotalSize={page.count}
                        customPageSize={5}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        // handleUserClick={handleUserClicks}
                      />
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {isUserDelete ? (
            <MsgToast msg="User Deleted Successfully" color="success" icon="ri-checkbox-circle-line" />
          ) : null}
          {isUserDeleteFail ? <MsgToast msg="User Deleted Failed" color="danger" icon="ri-error-warning-line" /> : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UsersList;
