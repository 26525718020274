export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_SERVICES_LIST = "GET_SERVICES_LIST";
export const GET_SERVICE = "GET_SERVICE";

export const ADD_NEW_SERVICE = "ADD_NEW_SERVICE";
export const ADD_NEW_SERVICE_SUCCESS = "ADD_NEW_SERVICE_SUCCESS";
export const ADD_NEW_SERVICE_FAIL = "ADD_NEW_SERVICE_FAIL";

export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS";
export const UPDATE_SERVICE_FAIL = "UPDATE_SERVICE_FAIL";

export const DELETE_SERVICE = "DELETE_SERVICE";
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS";
export const DELETE_SERVICE_FAIL = "DELETE_SERVICE_FAIL";

export const RESET_SERVICE_FLAG = "RESET_SERVICE_FLAG";
