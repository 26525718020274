import React from "react";
import { Col, Label, FormFeedback } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { handleValidTimeAlt } from "../../../Utility";

const TimeField = (props) => {
  return (
    <Col md={6} xs={12}>
      <div className="mb-3">
        <Label> {props.label}</Label>
        <div className={"input-group"}>
          <Flatpickr
            // className="form-control"
            className={`${
              (props.validation.touched[props.name] && props.validation.errors[props.name]) ||
              (props.errorMessage && props.errorMessage[props.name])
                ? "form-control is-invalid form-control"
                : "form-control"
            } `}
            id="event-start_time"
            placeholder="Select time"
            name={props.name}
            value={props.value}
            options={{
              enableTime: true,
              noCalendar: true,
            }}
            onChange={(time) => {
              props.validation.setFieldValue(`${props.name}`, time.toString().split(" ")[4]);

              // props.validation.setFieldValue(`${props.name}`, handleValidTimeAlt(time[0]));
            }}
            invalid={
              props.validation.touched[props.name] && props.validation.errors[props.name]
                ? true.toString()
                : false.toString() ||
                  (props.errorMessage && props.errorMessage[props.name] && props.errorMessage[props.name].length)
                ? true.toString()
                : false.toString()
            }
          />
          <span className="input-group-text">
            <i className="ri-calendar-event-line"></i>
          </span>

          {props.validation.touched[props.name] && props.validation.errors[props.name] ? (
            <FormFeedback type="invalid">{props.validation.errors[props.name]}</FormFeedback>
          ) : null}

          {props.errorMessage && props.errorMessage[props.name] ? (
            <FormFeedback type="invalid">
              <div>{props.errorMessage[props.name]}</div>
            </FormFeedback>
          ) : (
            ""
          )}
        </div>
      </div>
    </Col>
  );
};

export default TimeField;
