const filterOperators = [
  { label: "Equal To", value: "__eq" },
  { label: "Not Equal To", value: "__neq" },
  { label: "Greater Than", value: "__gt" },
  { label: "Greater or Equal", value: "__gte" },
  { label: "Less Than", value: "__lt" },
  { label: "Less or Equal", value: "__leq" },
  { label: "Less or Greater Than", value: "__lgt" },
  { label: "Contains", value: "__contains" },
  { label: "Does not contain", value: "__n_contains" },
  { label: "Starts with", value: "__starts" },
];

export { filterOperators };
