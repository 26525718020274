import React, { useState } from "react";
import { Offcanvas, OffcanvasHeader, OffcanvasBody, Label, Form, Input, Row, Col } from "reactstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import actions
import { getOrganizations } from "../../../store/actions";
//redux
import * as Yup from "yup";
import DateField from "../../../Components/Common/Fields/DateField";
import countries from "../../../common/data/countries.json";
import TextField from "../../../Components/Common/Fields/TextField.js";
import SelectField from "../../../Components/Common/Fields/SelectField.js";
import industryOptions from "../../../common/data/industry.json";

const CrmFilter = ({ show, onCloseClick }) => {
  const dispatch = useDispatch();

  const [selectCountry, setselectCountry] = useState(null);

  const handleselectCountry = (selectCountry) => {
    setselectCountry(selectCountry);
  };

  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {},

    onSubmit: (org) => {
      dispatch(getOrganizations(org));
    },
  });

  const statusOptions = [
    // { id: 1, name: "status", label: "Select A Status...", value: "" },
    { name: "active", label: "Active", value: "active" },
    { name: "inactive", label: "Inactive", value: "inactive" },
  ];

  const typeOptions = [
    // { id: 1, name: "status", label: "Select A Status...", value: "" },
    { name: "prospect", label: "Prospect", value: "prospect" },
    { name: "customer-direct", label: "Customer(Direct)", value: "customer-direct" },
    { name: "customer-channel", label: "Customer(Channel)", value: "customer-channel" },
    { name: "customer-reseller", label: "Customer(Reseller)", value: "customer-reseller" },
  ];

  return (
    <Offcanvas direction="end" isOpen={show} id="offcanvasExample" toggle={onCloseClick}>
      <OffcanvasHeader className="bg-light" toggle={onCloseClick}>
        Leads Fliters
      </OffcanvasHeader>
      <PerfectScrollbar>
        <Form
          className="d-flex flex-column justify-content-end h-100"
          onSubmit={(e) => {
            e.preventDefault();
            form.handleSubmit();
            return false;
          }}
          onReset={() => {
            form.handleReset();
            form.handleSubmit();
          }}
        >
          <OffcanvasBody>
            <TextField
              md="12"
              mb="mb-3"
              value={form.values.account_name || ""}
              onChange={form.handleChange}
              validation={form}
              onBlur={form.handleBlur}
              placeholder="account name"
              label="Enter account name"
              name="account_name"
            />
            <SelectField
              md="12"
              mb="mb-3"
              onChange={form.handleChange}
              validation={form}
              onBlur={form.handleBlur}
              placeholder="Select a status..."
              label="status"
              name="status"
              optional={true}
              options={[
                { label: "active", value: "active" },
                { label: "inactive", value: "inactive" },
              ]}
            />
            <SelectField
              md="12"
              mb="mb-3"
              onChange={form.handleChange}
              validation={form}
              onBlur={form.handleBlur}
              placeholder="Select a type..."
              label="type"
              name="type"
              optional={true}
              options={typeOptions}
            />
            <SelectField
              md="12"
              mb="mb-3"
              onChange={form.handleChange}
              validation={form}
              onBlur={form.handleBlur}
              placeholder="Select a industry..."
              label="industry"
              name="industry"
              optional={true}
              options={industryOptions}
            />

            <TextField
              md="12"
              mb="mb-3"
              value={form.values.department || ""}
              onChange={form.handleChange}
              validation={form}
              onBlur={form.handleBlur}
              placeholder="Enter first name"
              label="department"
              name="department"
            />
          </OffcanvasBody>
          <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
            <button type="reset" className="btn btn-light w-100" onClick={onCloseClick}>
              Clear Filter
            </button>
            <button type="submit" className="btn btn-success w-100" onClick={onCloseClick}>
              Filters
            </button>
          </div>
        </Form>
      </PerfectScrollbar>
    </Offcanvas>
  );
};

export default CrmFilter;
