import React, { useEffect, useMemo, useState, useCallback } from "react";

import { Input, Card, CardBody, Col, Container, Row, CardHeader } from "reactstrap";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";

//Import actions
import { getCasesStats, getDonationsCampaignsStats } from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

// Formik

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ColumnChart } from "../ColumnChart";

const Contacts = () => {
  const dispatch = useDispatch();

  const { count, stats_list, loading } = useSelector((state) => ({
    count: state.Statistics.count,
    stats_list: state.Statistics.stats_list,

    loading: state.Statistics.loading,
  }));

  useEffect(() => {
    dispatch(getDonationsCampaignsStats());
  }, []);

  // Column
  const servicesColumns = useMemo(
    () => [
      {
        Header: "Campaign",
        accessor: "campaign",
        filterable: false,
      },
      {
        Header: "Sum",
        accessor: "sum",
        filterable: false,
      },
    ],
    []
  );

  const handler = useCallback(
    debounce((value) => {
      dispatch(getDonationsCampaignsStats({ search: value }));
    }, 500),
    []
  );

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    handler(value);
  };

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(getCasesStats({ offset, limit }));
    },
    [dispatch]
  );

  let getChartData = (data = []) => {
    if (!data.length) return;
    let labels = [];
    let series = [];

    data.forEach((obj) => {
      labels.push(Object.values(obj)[0]);
      series.push(Object.values(obj)[1]);
    });

    return { labels, series };
  };

  let servicesChart = getChartData(stats_list);

  document.title = "Cases stats - statistics | SimplyNP";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Donation - Campaigns stats" pageTitle="Donation stats" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <div className="text-center py-0">
                      <h5>Donation - Campaigns chart</h5>

                      <ColumnChart data={servicesChart} />
                    </div>
                  </Row>
                </CardBody>
                <CardBody>
                  <CardHeader>
                    <Row className="g-3">
                      <Col md={4}>
                        <div className="search-box">
                          <Input
                            onChange={(e) => {
                              handleFieldSearch(e);
                            }}
                            type="text"
                            className="form-control search"
                            placeholder="Search for Campaigns..."
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>

                  <div className="text-center py-5">
                    <TableContainer
                      columns={servicesColumns}
                      data={stats_list || []}
                      isGlobalFilter={false}
                      isAddUserList={false}
                      customFetchData={fetchData}
                      customTotalSize={count}
                      customPageSize={10}
                      className="custom-header-css"
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light"
                    />
                  </div>
                </CardBody>
              </Card>

              <Card>
                <ToastContainer limit={1} closeButton={false} />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Contacts;
