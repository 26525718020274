import React, { useEffect, useState } from "react";
import { Card, CardBody, Row } from "reactstrap";

import { useSelector } from "react-redux";
import NotesList from "../NotesList";
import { getClientNotes } from "../../../helpers/backend_helper";
import Loader from "../../../Components/Common/Loader";
import TabsPagination from "./Pagination";

const NotesTab = ({ clientId }) => {
  const [notes, setNotes] = useState([]);

  const [notesTotal, setNotesTotal] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [limit, setLimit] = useState(4);
  const [isLoading, setIsLoading] = useState("");

  const { isNoteDelete } = useSelector((state) => ({
    isNoteDelete: state.Notes.isNoteDelete,
  }));

  const handleNext = async (newPageNumber) => {
    if (limit * newPageNumber >= notesTotal) return;
    if (newPageNumber < 0) return;

    setIsLoading(true);

    setPageNumber(newPageNumber);
    try {
      let res = await getClientNotes(clientId, { limit: limit, offset: limit * newPageNumber });

      if (res.status === 200) {
        setNotes(res.notes);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchCLientNotes = async () => {
      setIsLoading(true);

      try {
        let res = await getClientNotes(clientId, { limit: limit, offset: 0 });

        if (res.status === 200) {
          setNotes(res.notes);
          setNotesTotal(res.notes_count);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };

    fetchCLientNotes();
  }, [isNoteDelete]);
  return (
    <Card>
      <CardBody>
        <div className="d-flex align-items-center mb-4">
          <div className="flex-grow-1">
            <h5 className="card-title mb-0">Notes ({notesTotal || 0})</h5>
          </div>
        </div>
        <Row>
          {isLoading ? <Loader /> : ""}

          <NotesList notes={notes} />

          <TabsPagination handleNext={handleNext} pageNumber={pageNumber} />
        </Row>
      </CardBody>
    </Card>
  );
};

export default NotesTab;
