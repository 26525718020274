import React from "react";
import ReactApexChart from "react-apexcharts";

function getChartColorsArray(colors) {
  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
      if (color.indexOf("#") !== -1) color = color.replace(" ", "");
      if (color) return color;
      else return newValue;
    } else {
      var val = value.split(",");
      if (val.length === 2) {
        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
}

const getChartData = (chart) => {
  let data = [];
  let categories = [];

  if (chart && chart.length) {
    chart.forEach((c) => {
      data.push(c.total);
      categories.push(c.name || "unset");
    });
  }

  return { data, categories };
};

const ReportChart = ({ dataColors, chart }) => {
  var BasicColors = getChartColorsArray(dataColors);

  let { data, categories } = getChartData(chart);

  const series = [
    {
      data: data,
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: !1,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    colors: BasicColors,
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: categories,
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart options={options} series={series} type="bar" height={200} />
    </React.Fragment>
  );
};

export { ReportChart };
