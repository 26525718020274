import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { ADD_NEW_NOTE, DELETE_NOTE, GET_NOTE, GET_NOTES_LIST, UPDATE_NOTE } from "./actionType";

import {
  NotesApiResponseError,
  NotesApiResponseSuccess,
  deleteNoteFail,
  deleteNoteSuccess,
  getNotesList as getNoteListAction,
  updateNoteFail,
  updateNoteSuccess,
  addNewNoteSuccess,
  addNewNoteFail,
} from "./action";

import {
  createNotes,
  deleteNote,
  getNote as getNoteApi,
  getNotes as getNotesListApi,
  updateNote,
} from "../../helpers/backend_helper";

function* getNotesList({ payload: { params } }) {
  try {
    const response = yield call(getNotesListApi, params);
    const { notes } = response;
    yield put(
      NotesApiResponseSuccess(GET_NOTES_LIST, { notes, page: { offset: response.offset, count: response.notes_count } })
    );
  } catch (error) {
    yield put(NotesApiResponseError(GET_NOTES_LIST, error));
  }
}

function* getNoteWorker({ payload: note }) {
  try {
    const response = yield call(getNoteApi, note);
    yield put(NotesApiResponseSuccess(GET_NOTE, response));
  } catch (error) {
    yield put(NotesApiResponseError(GET_NOTE, error));
  }
}

function* addNewNoteWorker({ payload: note }) {
  try {
    const response = yield call(createNotes, note);
    if (response.status === 200) {
      yield put(addNewNoteSuccess(response));
    }
    if (response.status === 400) {
      yield put(addNewNoteFail(response));
    }
  } catch (error) {
    yield put(addNewNoteFail(error));
  }
}

function* updateNoteWorker({ payload: note }) {
  try {
    const response = yield call(updateNote, note.get("id"), note);

    if (response.status === 200) {
      yield put(updateNoteSuccess(response));
      yield put(getNoteListAction());
    }
    if (response.status === 400) {
      yield put(updateNoteFail(response));
    }
  } catch (error) {
    yield put(updateNoteFail(error));
  }
}

function* deleteNoteWorker({ payload: note }) {
  try {
    const response = yield call(deleteNote, note.id);
    yield put(deleteNoteSuccess({ note, ...response }));
    yield put(getNoteListAction());
  } catch (error) {
    yield put(deleteNoteFail(error));
  }
}

export function* watchGetNote() {
  yield takeEvery(GET_NOTE, getNoteWorker);
}

export function* watchGetNotesList() {
  yield takeEvery(GET_NOTES_LIST, getNotesList);
}

export function* watchAddNewNote() {
  yield takeEvery(ADD_NEW_NOTE, addNewNoteWorker);
}

export function* watchUpdateNote() {
  yield takeEvery(UPDATE_NOTE, updateNoteWorker);
}

export function* watchDeleteNote() {
  yield takeEvery(DELETE_NOTE, deleteNoteWorker);
}

function* NotesSaga() {
  yield all([
    fork(watchGetNote),
    fork(watchGetNotesList),
    fork(watchAddNewNote),
    fork(watchUpdateNote),
    fork(watchDeleteNote),
  ]);
}

export default NotesSaga;
