import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import countries from "../../../common/data/countries.json";

// Import React FilePond
import { registerPlugin } from "react-filepond";
import Select from "react-select";
// Import FilePond styles
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";

import { ToastContainer } from "react-toastify";
import MsgToast from "../../../Components/Common/MsgToast";

import { useParams } from "react-router-dom";
import DateField from "../../../Components/Common/Fields/DateField";
import IntNumberInput from "../../../Components/Common/Fields/IntNumberInput";
import TextField from "../../../Components/Common/Fields/TextField.js";

import dummyImg from "../../../assets/images/users/user-dummy-img.jpg";
import { genders, statusOptions } from "../../../common/data/common";

import { debounce } from "lodash";
import Loader from "../../../Components/Common/Loader";
import TableContainer from "../../../Components/Common/TableContainer";
import {
  addNewMember as onAddNewMember,
  getContacts as onGetContacts,
  getUsersList as onGetUsers,
  updateMember as onUpdateMember,
  resetMembershipFlag,
} from "../../../store/actions";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const CreateMember = (props) => {
  const dispatch = useDispatch();
  const { id: member_pk } = useParams();

  const {
    error,
    users,
    page,
    loading,
    crmusers,
    isMemberAdd,
    isMemberAddFail,
    isMemberCreated,
    membersList,
    contactsList,
    isMemberUpdate,
    isMemberUpdateFail,
    isMemberSuccess,
  } = useSelector((state) => ({
    isMemberCreated: state.Membership.isMemberCreated,
    isMemberSuccess: state.Membership.isMemberSuccess,
    isMemberUpdate: state.Membership.isMemberUpdate,
    loading: state.Membership.loading,
    crmusers: state.Users.usersList,
    contactsList: state.Crm.crmcontacts,
    page: state.Crm.contacts_page_info,
    error: state.Membership.error,
    isMemberAdd: state.Membership.isMemberAdd,
    isMemberAddFail: state.Membership.isMemberAddFail,
    membersList: state.Membership.members,
    isMemberUpdateFail: state.Membership.isMemberUpdateFail,
  }));

  const [memberErrors, setMemberErrors] = useState(null);
  const [addressErrors, setAddressErrors] = useState(null);

  const [contactModal, setContactModal] = useState(false);

  const [files, setFiles] = useState([]);
  const [member, setMember] = useState(null);

  const [memberCountry, setMemberCountry] = useState("");
  const [assignUser, setAssignUser] = useState(null);

  useEffect(() => {
    if (error) {
      setMemberErrors(error?.errors?.member_errors);
      setAddressErrors(error?.errors?.address_errors);
    }

    setTimeout(() => {
      dispatch(resetMembershipFlag());
    }, 3000);
  }, [
    error,
    dispatch,
    isMemberSuccess,
    isMemberCreated,
    isMemberAdd,
    isMemberAddFail,
    isMemberUpdate,
    isMemberUpdateFail,
  ]);

  useEffect(() => {
    if (member_pk) {
      let member = membersList?.find((c) => c.id.toString() === member_pk.toString());
      setMember(member);
    }

    if (member) {
      let c = countries.find((c) => c.value === member?.address.country);
      setMemberCountry(c);
      setAssignUser(member?.assigned_to);
    }

    if (!crmusers) {
      dispatch(onGetUsers());
    }

    if (!contactsList) {
      dispatch(onGetContacts());
    }
  }, [dispatch, crmusers, member, page, member_pk, membersList, contactsList]);

  const toggle = () => setContactModal(!contactModal);

  const fetchContactsData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(onGetContacts({ offset, limit }));
    },
    [dispatch]
  );

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "First name",
        accessor: "first_name",
        filterable: true,
      },

      {
        Header: "Last name",
        accessor: "last_name",
        filterable: true,
      },
      {
        Header: "E-Mail",
        accessor: "email",
        filterable: true,
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <React.Fragment>
              <Link
                to="#"
                onClick={() => {
                  const contact = cellProps.row.original;
                  setMember(contact);
                  setContactModal(false);
                }}
                className="text-muted d-inline-block btn btn-soft-success py-1"
              >
                <i className="ri-add-line fs-16"></i>
              </Link>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (member && member.id) || "",

      profile_pic: (member && member.profile_pic) || "",

      salutation: (member && member.salutation) || "",
      first_name: (member && member.first_name) || "",
      last_name: (member && member.last_name) || "",
      gender: (member && member.gender) || "",
      date_of_birth: (member && member.date_of_birth) || "",

      email: (member && member.email) || "",
      secondary_email: (member && member.secondary_email) || "",
      phone: (member && member.phone) || "",
      mobile: (member && member.mobile) || "",

      status: (member && member.status) || "",
      title: (member && member.title) || "",
      organization: (member && (member?.organization?.account_name || member?.organization)) || "",

      language: (member && member.language) || "",

      country: (member && member.address?.country) || "",
      address_line: (member && member.address?.address_line) || "",
      city: (member && member.address?.city) || "",
      postcode: (member && member.address?.postcode) || "",
      state: (member && member.address?.state) || "",
      street: (member && member.address?.street) || "",

      description: (member && member.description) || "",
    },

    validationSchema: Yup.object({
      salutation: Yup.string().notRequired("Please enter a salutation"),
      first_name: Yup.string().notRequired("Please enter a first name"),
      last_name: Yup.string().required("Please enter a last name"),
      date_of_birth: Yup.date().notRequired("Please enter date of birth"),
      gender: Yup.string().notRequired(),
      language: Yup.string().notRequired("Please enter a language"),
      title: Yup.string().notRequired("Please enter a language"),
      organization: Yup.string().notRequired("Please enter a language"),
      status: Yup.string().notRequired("Please enter a language"),
      email: Yup.string().email("Please enter a valid email").required("Please enter an email"),
      secondary_email: Yup.string().email("Please enter a valid email").notRequired("Please enter an email"),
      phone: Yup.string().required("Please enter phone number"),
      description: Yup.string().notRequired("Please enter description"),
      // assigned_to: Yup.mixed().notRequired("Please Assign Member to a User"),
      address_line: Yup.string().notRequired("Please enter an address"),
      city: Yup.string().notRequired("Please enter an city"),
      postcode: Yup.string().notRequired("Please enter an postcode"),
      state: Yup.string().notRequired("Please enter a state"),
      street: Yup.string().notRequired("Please enter an address"),
      country: Yup.string().notRequired("Please enter an country"),
    }),

    onSubmit: (member) => {
      // prevent id from being submitted as part of the form
      // when members are added from existing contacts
      if (!member_pk) delete member.id;

      setAddressErrors(null);
      setMemberErrors(null);

      const memberFormData = new FormData();
      // if (!files[0]) delete user.profile_pic;

      for (let v in member) {
        if (v === "profile_pic") continue;
        memberFormData.set(v, member[v]);
      }
      if (files[0]) memberFormData.set("profile_pic", files[0]);

      if (member_pk) {
        memberFormData.set("id", member_pk);
        dispatch(onUpdateMember(memberFormData));
      } else {
        dispatch(onAddNewMember(memberFormData));
        // validation.resetForm();
      }
    },
  });

  const handleSearch = useCallback(
    debounce((value) => {
      dispatch(onGetContacts({ search: value }));
    }, 3000),
    []
  );
  document.title = "Create Member - Membersip Management | SimplyNP";

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={props.title} pageTitle="Members " />

        <Row>
          <Col lg={8}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-1">
                      <h5 className="card-title mb-0">Personal Information</h5>
                    </div>
                    <div className="flex-shrink-0">
                      <button type="button" className="btn btn-soft-success" onClick={() => setContactModal(true)}>
                        <i className="ri-plus-line ri-xl fs-16"></i>add from contacts
                      </button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.salutation}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter salutation"
                      label="Salutation"
                      name="salutation"
                      errorMessage={memberErrors}
                    />

                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.first_name}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter first name"
                      label="First name"
                      name="first_name"
                      errorMessage={memberErrors}
                    />
                  </Row>

                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.last_name}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter lastname"
                      label="Lastname"
                      name="last_name"
                      errorMessage={memberErrors}
                    />

                    <Col>
                      <DateField
                        mb="mb-3"
                        md="12"
                        value={validation.values.date_of_birth || ""}
                        onChange={validation.handleChange}
                        validation={validation}
                        onBlur={validation.handleBlur}
                        label="Date of Birth Date"
                        name="date_of_birth"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="gender-input">
                          Gender
                        </Label>

                        <Select
                          className={`${
                            validation.touched.gender && validation.errors.gender ? "form-control is-invalid" : ""
                          } `}
                          name="gender"
                          onChange={(e) => validation.setFieldValue("gender", e.value)}
                          options={genders}
                          placeholder="Select a Gender..."
                          id="gender-input"
                          //noOptionsMessage="Select Gender"
                          //validate={{required: {value: true}}}
                          value={{ value: validation.values.gender, label: validation.values.gender }}
                          key={genders}
                          invalid={validation.touched.gender && validation.errors.gender ? true : false}
                          classNamePrefix="select2-selection form-select"
                        />

                        {validation.touched.gender && validation.errors.gender ? (
                          <FormFeedback type="invalid">{validation.errors.gender}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.language}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter language"
                      label="Language"
                      name="language"
                      errorMessage={memberErrors}
                    />
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Contact Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.email}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter email"
                      label="Email"
                      name="email"
                      errorMessage={memberErrors}
                    />

                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.secondary_email}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter secondary email"
                      label="Secondary Email"
                      name="secondary_email"
                      errorMessage={memberErrors}
                    />
                  </Row>

                  <Row>
                    <IntNumberInput
                      md="6"
                      mb="mb-3"
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      value={validation.values.phone}
                      label="Phone Number"
                      name="phone"
                      errorMessage={memberErrors}
                    />

                    <IntNumberInput
                      md="6"
                      mb="mb-3"
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      value={validation.values.mobile}
                      label="Mobile Number"
                      name="mobile"
                      errorMessage={memberErrors}
                    />
                  </Row>
                </CardBody>
              </Card>

              {/* <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Memberships Information</h5>
                </CardHeader>
                <CardBody></CardBody>
              </Card> */}

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Address Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.address_line}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter address line"
                      label="Address (street numer +  name)"
                      name="address_line"
                      errorMessage={addressErrors}
                    />

                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.street}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Apartment, Suite, Unit, Building, Floor, etc."
                      label="Address line 2"
                      name="street"
                      errorMessage={addressErrors}
                    />
                  </Row>

                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.city}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter city"
                      label="city"
                      name="city"
                      errorMessage={addressErrors}
                    />

                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.state}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="State, Province, Region"
                      label="State / Province / Region"
                      name="state"
                      errorMessage={addressErrors}
                    />
                  </Row>
                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.postcode}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="ZIP / Postal Code"
                      label="ZIP / Postal Code"
                      name="postcode"
                      errorMessage={addressErrors}
                    />

                    {/*  */}
                    <Col>
                      <div className="mb-3 md-6">
                        <Label htmlFor="choices-lead-input" className="form-label">
                          Country
                        </Label>
                        <Select
                          className={`${
                            validation.touched.country && validation.errors.country ? "form-control is-invalid" : ""
                          } `}
                          id="choices-team-input"
                          placeholder="Select a country..."
                          value={memberCountry}
                          key={memberCountry}
                          name="country"
                          onChange={(e) => {
                            setMemberCountry(e);
                            validation.setFieldValue("country", e?.value);
                          }}
                          options={countries}
                        />
                        {validation.touched.country && validation.errors.country ? (
                          <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    {/*  */}
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Other Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="status-input" className="form-label">
                          Status
                        </Label>
                        <Select
                          id="status-input"
                          placeholder="Assign a status..."
                          name="status"
                          isClearable
                          options={statusOptions}
                          value={statusOptions.find((s) => s?.value === validation.values.status)}
                          onChange={(e) => validation.setFieldValue("status", e?.value)}
                          classNamePrefix="select2-selection form-select"
                        />
                      </div>
                    </Col>

                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.organization}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter organization"
                      label="Organization"
                      name="organization"
                      errorMessage={memberErrors}
                    />
                  </Row>

                  <div>
                    <Label>Member Description</Label>
                    <Input
                      type="textarea"
                      className="form-control"
                      placeholder="Member Description"
                      name="description"
                      value={validation.values.description}
                      onChange={validation.handleChange}
                      invalid={validation.touched.description && validation.errors.description ? true : false}
                      rows="10"
                    />
                    {validation.touched.description && validation.errors.description ? (
                      <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>

              <div className="text-end mb-3">
                <button type="reset" className="btn btn-danger w-sm me-1">
                  Clear
                </button>
                <button type="submit" className="btn btn-success w-sm">
                  {loading ? "Submitting" : "Submit"}
                </button>
              </div>
            </Form>

            {isMemberCreated ? (
              <MsgToast msg="Member Added Successfully" color="success" icon="ri-checkbox-circle-line" />
            ) : null}
            {isMemberAdd ? (
              <MsgToast msg="Member Added Successfully" color="success" icon="ri-checkbox-circle-line" />
            ) : null}
            {isMemberUpdate ? (
              <MsgToast msg="Member updated Successfully" color="success" icon="ri-checkbox-circle-line" />
            ) : null}
            {isMemberUpdateFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
            {isMemberAddFail ? (
              <MsgToast msg="Member Added Failed" color="danger" icon="ri-error-warning-line" />
            ) : null}

            <ToastContainer limit={1} closeButton={false} />
          </Col>

          <Col lg={4}>
            {/* Profile Image */}
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Profile Picture</h5>
              </CardHeader>
              <CardBody>
                <Col lg={12}>
                  <div className="text-center">
                    <div className="position-relative d-inline-block">
                      <div className="position-absolute  bottom-0 end-0">
                        <Label htmlFor="customer-image-input" className="mb-0">
                          <div className="avatar-xs cursor-pointer">
                            <div className="avatar-title bg-light border rounded-circle text-muted">
                              <i className="ri-image-fill"></i>
                            </div>
                          </div>
                        </Label>
                        <Input
                          className="form-control d-none"
                          id="customer-image-input"
                          type="file"
                          accept="image/png, image/gif, image/jpeg image/jpg"
                          onChange={(e) => setFiles(e.target.files)}
                          onBlur={validation.handleBlur}
                          invalid={validation.touched.profile_pic && validation.errors.profile_pic ? true : false}
                        />
                      </div>
                      <div className="avatar-xl p-1">
                        <div className="avatar-title bg-light rounded-circle">
                          <img
                            // src={
                            //   validation.values.profile_pic ? api.BASE_URL + validation.values.profile_pic : dummyImg
                            // }
                            src={validation.values.profile_pic || dummyImg}
                            alt="dummyImg"
                            id="customer-profile_pic"
                            className="avatar-xl rounded-circle object-cover"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </CardBody>
            </Card>

            {/*
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Assign</h5>
              </CardHeader>
              <CardBody>
                <div className="mb-3">
                  <Label htmlFor="assigned_to-input" className="form-label">
                    To a User(s)
                  </Label>
                  <Select
                    id="assinged_to-input"
                    placeholder="Select a User..."
                    isClearable
                    isSearchable
                    defaultValue={assignUser}
                    key={assignUser}
                    options={crmusers}
                    getOptionLabel={(option) => `${option?.user_details.first_name} ${option?.user_details.last_name}`}
                    getOptionValue={(option) => option?.id}
                    onChange={(e) => validation.setFieldValue("assigned_to", JSON.stringify(e?.id))}
                    name="assigned_to"
                    classNamePrefix="select2-selection form-select"
                  />
                </div>
              </CardBody>
            </Card>
      */}

            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Tags</h5>
              </CardHeader>
              <CardBody>
                <div className="hstack gap-3 align-items-start">
                  <div className="flex-grow-1">
                    <input className="form-control" placeholder="Enter tags" type="text" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal size="lg" isOpen={contactModal} toggle={toggle} id="contact-member-modal" centered>
          <ModalHeader toggle={toggle} tag="h5" className="p-3 bg-soft-info modal-title">
            Find a Contact
          </ModalHeader>
          <ModalBody>
            <Row className="g-3">
              <div className="search-box">
                <Input
                  onChange={({ target: { value } }) => handleSearch(value)}
                  type="text"
                  className="form-control search pl-2"
                  placeholder="Search for contact..."
                />
                <i className="ri-search-line search-icon"></i>
              </div>
            </Row>
            <hr />
            <Row>
              <div className="p-4">
                {contactsList && contactsList.length ? (
                  <TableContainer
                    columns={columns}
                    data={contactsList || []}
                    isGlobalFilter={false}
                    isAddUserList={false}
                    customFetchData={fetchContactsData}
                    customTotalSize={page.count}
                    customPageSize={10}
                    className="custom-header-css"
                    divClass="table-responsive table-card mb-3"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light"
                  />
                ) : (
                  <Loader error={error} />
                )}
              </div>
            </Row>
          </ModalBody>
        </Modal>
      </Container>
    </div>
  );
};

export default CreateMember;
