import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import countries from "../../common/data/countries.json";
import TextField from "./Fields/TextField.js";
import MsgToast from "./MsgToast.js";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import SelectField from "./Fields/SelectField.js";

import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { createSimplyNPSubscription, updateSimplyNPSubscription } from "../../helpers/backend_helper.js";
import { getBilling, resetBillingFlag } from "../../store/billing/actions.js";
import { getProfile } from "../../store/actions";
import IntNumberInput from "./Fields/IntNumberInput";

let checkIfFieldsIsValid = (fields) => {
  let fieldKeys = Object.keys(fields);
  let errOject = {};
  let fieldIsValid = true;

  for (let v of fieldKeys) {
    let value = fields[v];

    if (!value) {
      fieldIsValid = false;
      errOject[v] = v.replaceAll("_", " ") + " field is required";
    }
  }

  return { fieldIsValid, errOject };
};

const CheckoutCard = ({ featureList, plan, products, selectedPlanId, isUpdatePlan, isCreatePlan }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [activeVerticalTab, setactiveVerticalTab] = useState(7);
  const [passedverticalSteps, setPassedverticalSteps] = useState([1]);
  const [fieldErrors, setFieldErrors] = useState({});
  const [isPlanUpgraded, setIsPlanUpgraded] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("");

  const [clientSecret, setClientSecret] = useState(null);
  const [billingInfo, setBillingInfo] = useState(null);
  const [contactInfo, setContactInfo] = useState(null);

  const [alert, setAlert] = useState({
    status: true,
    title: "Initializing",
    message: "Initializing Payment Form...",
    class: "spinner-border spinner-border-lg",
    textClass: "text-primary",
  });

  const { user, billing_info } = useSelector((state) => ({
    user: state.Profile.user,
    billing_info: state.Billing.billing_info,
  }));

  let newSelectedPlanId = (billing_info && billing_info.current_plan) || selectedPlanId;

  useEffect(() => {
    if (!user) dispatch(getProfile());
    if (!billing_info && isUpdatePlan) dispatch(getBilling());

    setactiveVerticalTab(7);

    setTimeout(() => {
      dispatch(resetBillingFlag());
    }, 3000);
  }, [dispatch, billing_info, user, isUpdatePlan]);

  useEffect(() => {
    if (billing_info) {
      setBillingInfo({
        first_name: billing_info.name.split(" ")[0],
        last_name: billing_info.name.split(" ")[1],
        email: billing_info.email,
        phone: billing_info.phone,
        billing_address: billing_info.address,
      });
    }
  }, [billing_info]);
  //

  const timeOut = useCallback(
    () =>
      setTimeout(() => {
        if (!stripe) {
          setAlert({
            status: true,
            title: "Timeout",
            message: "Request Timed out, please try again later",
            class: "ri-information-line",
            textClass: "text-primary",
            back_url: "/login",
          });
        }
      }, 3 * 1000 * 60),
    [stripe]
  );

  useEffect(() => {
    if (stripe && elements && plan) {
      setAlert({ status: false });
      //clearTimeout(timeOut);
    }

    // lets check if loading stripe resulted in an error or it timed-out
    //timeOut();
  }, [stripe, elements, plan, timeOut]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetBillingFlag());
    }, 3000);
  }, [dispatch]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...(isUpdatePlan ? { id: billingInfo?.id } : null),
      first_name: billingInfo?.first_name || "",
      last_name: billingInfo?.last_name || "",
      email: billingInfo?.email || "",
      phone: billingInfo?.phone || "",

      address_line: billingInfo?.billing_address?.line1 || "",
      address2: billingInfo?.billing_address?.line2 || "",
      country: billingInfo?.billing_address?.country || "",
      state: billingInfo?.billing_address?.state || "",
      city: billingInfo?.billing_address?.city || "",
      postcode: billingInfo?.billing_address?.postal_code || "",
      street: billingInfo?.billing_address?.street || "",
      name_on_card: "",
    },
    validationSchema: yup.object({
      first_name: yup.string().required("Please enter your first name"),
      last_name: yup.string().required("Please enter your last name"),
      email: yup.string().email("Please enter a valid email").required("Please enter your email"),
    }),

    onSubmit: async (values) => {
      let dataValues = { ...values };
      setErrorMessage("");
      setIsPlanUpgraded(false);

      if (!stripe || !elements)
        return setAlert({
          status: true,
          message: "Network Error, Please Try Again Later",
          title: "Warning",
          class: "ri-warning-fill-lg",
          textClass: "text-warning display-3",
        });

      setAlert({
        status: true,
        title: "Loading",
        message: "Please Wait...",
        class: "spinner-border spinner-border-lg",
        textClass: "text-primary",
      });

      let billing_address = {
        address_line: values.address_line,
        country: values.country,
        state: values.state,
        city: values.city,
        postcode: values.postcode,
        street: values.street,
        //...(isUpdatePlan ? { id: billing_info.billing_address.id } : ""),
      };

      dataValues["billing_address"] = billing_address;

      try {
        setErrorMessage("");
        setIsPlanUpgraded(false);

        const billing_details = {
          email: values.email,
          phone: values.phone,
          name: `${values.first_name} ${values.last_name}`,
          address: {
            city: values.city,
            country: values.country,
            line1: values.address_line,
            line2: values.street,
            postal_code: values.postcode,
            state: values.state,
          },
        };
        ///  0. create a new payment method on failure
        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
          billing_details: billing_details,
        });

        let data = { selectedPlanId: newSelectedPlanId, ...dataValues, paymentMethod: paymentMethod.id };

        if (isUpdatePlan) {
          //// 2.update subscription and confirm payment
          const response = await updateSimplyNPSubscription(data);
          //console.log("SUBSCRIPTION UPDATED: ", response);

          setAlert({
            ...alert,
            status: true,
            message: "Subscription changed Successfully..",
            title: "Done !",
            class: "ri-checkbox-circle-fill",
            textClass: "text-success",
            back_url: "/dashboard",
          });
        } else {
          //// 1. create customer, subscription and connect-account
          const response = await createSimplyNPSubscription(data);
          //console.log("SUBSCRIPTION CREATED: ", response);

          //// 2. confirm card payment
          const { paymentIntent, error: confirmationError } = await stripe.confirmCardPayment(response.client_secret, {
            payment_method: paymentMethod.id,
            receipt_email: values.email || null,
            save_payment_method: true,
            setup_future_usage: "off_session",
          });

          if (confirmationError) {
            return setAlert({
              ...alert,
              status: true,
              title: "Error !",
              message: confirmationError.message,
              class: "ri-error-warning-line ri-3xl",
              textClass: "text-danger display-3",
            });
          }

          //console.log("PAYMENT CONFIRMED: ", paymentIntent, "PAYMENT ERROR: ", confirmationError);
          setAlert({ status: false });

          setAlert({
            ...alert,
            status: true,
            message: "Subscription Created Successfully..",
            title: "Done !",
            class: "ri-checkbox-circle-fill",
            textClass: "text-success",
            back_url: "/login",
          });
        }
      } catch (error) {
        //console.log("SUBSCRIPTION ERROR: ", error);
        setLoading(false);
        return setAlert({
          status: true,
          message: "Subscription Failed..",
          textClass: "text-danger display-3",
          title: "Error !",
          class: "ri-error-warning-line ri-3xl",
          //back_url: "/login",
        });
      }
    },
  });

  const handleBillingInfoSubmit = (tabNumber) => {
    let fields = {
      first_name: validation.values.first_name,
      last_name: validation.values.last_name,
    };

    let { fieldIsValid, errOject } = checkIfFieldsIsValid(fields);
    setFieldErrors(errOject);

    if (!fieldIsValid) return;

    if (tabNumber) {
      return togglePaymentTabs(tabNumber);
    }

    togglePaymentTabs(activeVerticalTab + 1);
  };

  const handlePayment = (tabNumber) => {
    let fields = {
      address_line: validation.values.address_line,
      country: validation.values.country,
      state: validation.values.state,
      city: validation.values.city,
      postcode: validation.values.postcode,
    };
    let { fieldIsValid, errOject } = checkIfFieldsIsValid(fields);
    setFieldErrors(errOject);

    if (!fieldIsValid) {
      return;
    }

    if (tabNumber) {
      return togglePaymentTabs(tabNumber);
    }

    togglePaymentTabs(activeVerticalTab + 1);
  };

  const handleCompleteOrder = (tabNumber) => {
    validation.handleSubmit();
  };

  const toggleModal = () => {
    togglePaymentTabs(7);
    //toggle();
  };
  const togglePaymentTabs = (tab) => {
    if (activeVerticalTab !== tab) {
      var modifiedSteps = [...passedverticalSteps, tab];

      if (tab >= 6 && tab <= 11) {
        setactiveVerticalTab(tab);
        setPassedverticalSteps(modifiedSteps);
      }
    }
  };

  return (
    <Row size="xl">
      <Modal
        isOpen={alert.status}
        centered
        toggle={alert.back_url ? () => null : () => setAlert({ ...alert, status: !alert.status })}
      >
        <ModalBody>
          <div className="text-center pt-4 pb-2">
            <div className="mb-4">
              <div className="avatar-lg mx-auto my-2">
                <div className={`avatar-title bg-light ${alert.textClass} display-1 rounded-circle`}>
                  <span className="d-flex align-items-center">
                    <span className={`${alert.class} flex-shrink-0`} role="status">
                      <span className="visually-hidden">Loading...</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <h5>{alert.title}</h5>
            <p className="text-muted">{alert.message}</p>
            <button
              hidden={!alert.back_url}
              className="btn btn-soft-primary mt-2"
              onClick={() => (window.location = alert.back_url)}
            >
              Go Back
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Card hidden={alert.status} /*style={{ opacity: alert.status ? 0 : 1 }}*/>
        <CardHeader>
          <h3 className="card-title mb-0">{plan?.product_name ? plan?.product_name : "Add Plan"}</h3>
        </CardHeader>
        <CardBody className="p-0 m-0">
          <Row>
            <Col xl={12}>
              <CardBody className="form-steps">
                {/* <form className="vertical-navs-step"> */}
                <Form
                  className="mb-5 vertical-navs-step"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                  }}
                >
                  <Row className="gy-3">
                    <Col lg={3}>
                      <Nav className="flex-column custom-nav nav-pills">
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: activeVerticalTab === 7,
                              done: activeVerticalTab <= 11 && activeVerticalTab > 7,
                            })}
                            onClick={() => {
                              togglePaymentTabs(7);
                            }}
                          >
                            <span className="step-title me-2">
                              <i className="ri-close-circle-fill step-icon me-2"></i>
                              Step 1
                            </span>
                            Billing Info
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: activeVerticalTab === 8,
                              done: activeVerticalTab <= 11 && activeVerticalTab >= 8,
                            })}
                            onClick={() => {
                              handleBillingInfoSubmit(8);
                              // togglePaymentTabs(8);
                            }}
                          >
                            <span className="step-title me-2">
                              <i className="ri-close-circle-fill step-icon me-2"></i>
                              Step 2
                            </span>
                            Billing Address
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: activeVerticalTab === 9,
                              done: activeVerticalTab <= 11 && activeVerticalTab >= 9,
                            })}
                            onClick={() => {
                              // togglePaymentTabs(9);
                              handlePayment(9);
                            }}
                          >
                            <span className="step-title me-2">
                              <i className="ri-close-circle-fill step-icon me-2"></i>
                              Step 3
                            </span>
                            Payment
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            href="#"
                            className={classnames({
                              active: activeVerticalTab === 10,
                              done: activeVerticalTab <= 11 && activeVerticalTab >= 10,
                            })}
                          >
                            <span className="step-title me-2">
                              <i className="ri-close-circle-fill step-icon me-2"></i>
                              Step 4
                            </span>
                            Finish {isUpdatePlan ? "Update" : ""}
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                    <Col lg={6}>
                      <div className="px-lg-4">
                        <TabContent activeTab={activeVerticalTab}>
                          <TabPane tabId={7}>
                            <div>
                              <h3>Billing Info</h3>
                              <p className="text-muted">Fill all information below</p>
                            </div>

                            <div>
                              <Row className="g-3">
                                <TextField
                                  md="6"
                                  mb="mb-3"
                                  value={validation.values.first_name || ""}
                                  onChange={validation.handleChange}
                                  validation={validation}
                                  onBlur={validation.handleBlur}
                                  placeholder="Enter first name"
                                  label="First Name"
                                  name="first_name"
                                  errorMessage={fieldErrors}
                                />

                                <TextField
                                  md="6"
                                  mb="mb-3"
                                  value={validation.values.last_name || ""}
                                  onChange={validation.handleChange}
                                  validation={validation}
                                  onBlur={validation.handleBlur}
                                  placeholder="Enter last name"
                                  label="Last Name"
                                  name="last_name"
                                  errorMessage={fieldErrors}
                                />

                                <Col sm={6} xs={12}>
                                  <Label htmlFor="email" className="form-label">
                                    Email <span className="text-muted">(Optional)</span>
                                  </Label>
                                  <Input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Enter Email"
                                    value={validation.values.email || ""}
                                    onChange={validation.handleChange}
                                    validation={validation}
                                    onBlur={validation.handleBlur}
                                    label="Email"
                                    name="email"
                                  />
                                </Col>

                                <IntNumberInput
                                  md="6"
                                  mb="mb-3"
                                  id="phone"
                                  placeholder="Phone Number"
                                  value={validation.values.phone || ""}
                                  onChange={validation.handleChange}
                                  validation={validation}
                                  onBlur={validation.handleBlur}
                                  label="Phone Number"
                                  name="phone"
                                />
                              </Row>

                              <hr className="my-4 text-muted" />

                              <div className="form-check mb-2">
                                <Input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="same-contact"
                                  onChange={({ target }) => {
                                    if (target.checked) {
                                      validation.setFieldValue("first_name", user?.user_details.first_name || "");
                                      validation.setFieldValue("last_name", user?.user_details.last_name || "");
                                      validation.setFieldValue("email", user?.user_details?.email || "");
                                      validation.setFieldValue("phone", user?.user_details?.phone || "");
                                    } else {
                                      validation.setFieldValue("first_name", "");
                                      validation.setFieldValue("last_name", "");
                                      validation.setFieldValue("email", "");
                                      validation.setFieldValue("phone", "");
                                    }
                                  }}
                                />
                                <Label className="form-check-label" htmlFor="same-contact">
                                  Contact information is the same as my billing information
                                </Label>
                              </div>
                            </div>

                            <div className="d-flex align-items-start gap-3 mt-4">
                              <button
                                type="button"
                                className="btn btn-success btn-label right ms-auto nexttab nexttab"
                                onClick={() => handleBillingInfoSubmit()}
                                // onClick={() => togglePaymentTabs(activeVerticalTab + 1)}
                              >
                                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                Go to Billing
                              </button>
                            </div>
                          </TabPane>

                          <TabPane tabId={8}>
                            <div>
                              <h5>Billing Address</h5>
                              <p className="text-muted">Fill all information below</p>
                            </div>

                            <div>
                              <Row className="g-3">
                                <TextField
                                  md="6"
                                  mb="mb-3"
                                  value={validation.values.address_line || ""}
                                  onChange={validation.handleChange}
                                  validation={validation}
                                  onBlur={validation.handleBlur}
                                  placeholder="1234 Main St"
                                  label="Address Line 1"
                                  name="address_line"
                                  errorMessage={fieldErrors}
                                />

                                <TextField
                                  md="6"
                                  mb="mb-3"
                                  value={validation.values.address2 || ""}
                                  onChange={validation.handleChange}
                                  validation={validation}
                                  onBlur={validation.handleBlur}
                                  placeholder="Apartment or suite"
                                  label="Address Line 2"
                                  name="address2"
                                  errorMessage={fieldErrors}
                                  optional={true}
                                />

                                <SelectField
                                  md="6"
                                  mb="mb-3"
                                  onChange={validation.handleChange}
                                  validation={validation}
                                  onBlur={validation.handleBlur}
                                  placeholder="select..."
                                  label="Country"
                                  name="country"
                                  errorMessage={fieldErrors}
                                  optional={true}
                                  defaultValue={countries.find((c) => c.value === country)}
                                  key={country}
                                  options={countries}
                                />

                                <TextField
                                  md="6"
                                  mb="mb-3"
                                  value={validation.values.state || ""}
                                  onChange={validation.handleChange}
                                  validation={validation}
                                  onBlur={validation.handleBlur}
                                  placeholder="enter state"
                                  label="State"
                                  name="state"
                                  errorMessage={fieldErrors}
                                />

                                <TextField
                                  md="6"
                                  mb="mb-3"
                                  value={validation.values.city || ""}
                                  onChange={validation.handleChange}
                                  validation={validation}
                                  onBlur={validation.handleBlur}
                                  placeholder="enter city"
                                  label="City"
                                  name="city"
                                  errorMessage={fieldErrors}
                                />

                                <TextField
                                  md="6"
                                  mb="mb-3"
                                  value={validation.values.postcode || ""}
                                  onChange={validation.handleChange}
                                  validation={validation}
                                  onBlur={validation.handleBlur}
                                  label="ZIP / Postal Code"
                                  name="postcode"
                                  errorMessage={fieldErrors}
                                  type="text"
                                />
                              </Row>

                              <hr className="my-4 text-muted" />

                              <div className="form-check mb-2">
                                <Input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="same-address"
                                  onChange={({ target }) => {
                                    if (target.checked) {
                                      validation.setFieldValue(
                                        "address_line",
                                        user?.org?.shipping_address.address_line
                                      );
                                      validation.setFieldValue("address2", user?.org?.shipping_address.street);
                                      validation.setFieldValue("city", user?.org?.shipping_address.city);
                                      validation.setFieldValue("state", user?.org?.shipping_address.state);
                                      validation.setFieldValue("country", user?.org?.shipping_address.country);
                                      validation.setFieldValue("postcode", user?.org?.shipping_address.postcode);
                                    } else {
                                      validation.setFieldValue("address_line", "");
                                      validation.setFieldValue("address2", "");
                                      validation.setFieldValue("city", "");
                                      validation.setFieldValue("state", "");
                                      validation.setFieldValue("country", "");
                                      validation.setFieldValue("postcode", "");
                                    }
                                  }}
                                />
                                <Label className="form-check-label" htmlFor="same-address">
                                  Shipping address is the same as my billing address
                                </Label>
                              </div>
                            </div>
                            <div className="d-flex align-items-start gap-3 mt-4">
                              <button
                                type="button"
                                className="btn btn-light btn-label previestab"
                                onClick={() => {
                                  togglePaymentTabs(activeVerticalTab - 1);
                                }}
                              >
                                <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back to
                                Billing Info
                              </button>
                              <button
                                type="button"
                                className="btn btn-success btn-label right ms-auto nexttab"
                                onClick={() => handlePayment()}
                              >
                                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                Go to Payment
                              </button>
                            </div>
                          </TabPane>

                          <TabPane tabId={9}>
                            <div>
                              <h5>Payment</h5>
                              <p className="text-muted">Fill card information below</p>
                            </div>

                            <div>
                              <Row>
                                <Col md="12">
                                  <div className="mb-3">
                                    <Label className="form-check-label">Enter card number</Label>
                                    <CardNumberElement className="form-control" />
                                  </div>
                                </Col>

                                <Col md="8">
                                  <div className="mb-3">
                                    <Label className="form-check-label">Enter card Expiry</Label>
                                    <CardExpiryElement className="form-control" />
                                  </div>
                                </Col>

                                <Col md="4">
                                  <div className="mb-3">
                                    <Label className="form-check-label">Enter card Cvc</Label>
                                    <CardCvcElement className="form-control" />
                                  </div>
                                </Col>
                              </Row>
                            </div>

                            <div className="d-flex align-items-start gap-3 mt-4">
                              <button
                                type="button"
                                className="btn btn-light btn-label previestab"
                                onClick={() => {
                                  togglePaymentTabs(activeVerticalTab - 1);
                                }}
                              >
                                <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back to
                                Billing Info
                              </button>
                              <button
                                disabled={loading}
                                type="button"
                                className="btn btn-success btn-label right ms-auto nexttab"
                                onClick={() => handleCompleteOrder()}
                              >
                                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                {loading ? "Please wait" : "Pay"}
                              </button>
                            </div>
                          </TabPane>

                          <TabPane tabId={10}>
                            <div className="text-center pt-4 pb-2">
                              <div className="mb-4">
                                <div className="avatar-lg mx-auto my-2">
                                  <div className="avatar-title bg-light text-success display-3 rounded-circle">
                                    <i className="ri-checkbox-circle-fill ri-3xl"></i>
                                  </div>
                                </div>
                              </div>
                              <h5>Success !</h5>
                              <p className="text-muted">
                                Your payment successfully, your plan has been successfully upgraded
                              </p>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </Col>

                    <Col lg={3}>
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5 className="fs-14 text-primary mb-0">
                          <i className="ri-shopping-cart-fill align-middle me-2"></i> Your cart
                        </h5>
                        {/*<span className="badge bg-danger rounded-pill">1</span>*/}
                      </div>
                      <ul className="list-group mb-3">
                        <li className="list-group-item d-flex justify-content-between lh-sm">
                          <div>
                            <h6 className="my-0">{plan?.product_name}</h6>
                            <small className="text-muted">{plan?.purpose}</small>
                          </div>
                          {/*<div>{plan?.prices?.find((p) => p?.lookup_key === selectedPlanId).interval}</div>*/}
                        </li>
                        {/* FEATURE LIST */}
                        <ul className="list-unstyled vstack gap-3 py-2">
                          <li>
                            <div className="d-flex ms-1">
                              <div className={`flex-shrink-0 text-success me-1`}>
                                <i className={`ri-checkbox-circle-fill fs-15 align-middle`}></i>
                              </div>
                              <div className="flex-grow-1 ">{featureList?.features}</div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex ms-1">
                              <div className={`flex-shrink-0 text-success me-1`}>
                                <i className={`ri-checkbox-circle-fill fs-15 align-middle`}></i>
                              </div>
                              <div className="flex-grow-1 ">{featureList?.users}</div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex ms-1">
                              <div className={`flex-shrink-0 text-success me-1`}>
                                <i className={`ri-checkbox-circle-fill fs-15 align-middle`}></i>
                              </div>
                              <div className="flex-grow-1 ">{featureList?.storage}</div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex ms-1">
                              <div className={`flex-shrink-0 text-success me-1`}>
                                <i className={`ri-checkbox-circle-fill fs-15 align-middle`}></i>
                              </div>
                              <div className="flex-grow-1 ">{featureList?.support}</div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex ms-1">
                              <div className={`flex-shrink-0 text-success me-1`}>
                                <i className={`ri-checkbox-circle-fill fs-15 align-middle`}></i>
                              </div>
                              <div className="flex-grow-1 ">{featureList?.donors}</div>
                            </div>
                          </li>
                          <li>
                            <div className="d-flex ms-1">
                              <div className={`flex-shrink-0 text-success me-1`}>
                                <i className={`ri-checkbox-circle-fill fs-15 align-middle`}></i>
                              </div>
                              <div className="flex-grow-1 ">{featureList?.membership}</div>
                            </div>
                          </li>
                        </ul>
                        <li className="list-group-item d-flex justify-content-between">
                          <span>Total: </span>
                          <div>{plan?.prices?.find((p) => p?.lookup_key === selectedPlanId)?.amount_readable}</div>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  {/* </form> */}
                </Form>
              </CardBody>
            </Col>
          </Row>

          {isPlanUpgraded ? <MsgToast msg={isPlanUpgraded} color="success" icon="ri-checkbox-circle-line" /> : null}
          {errorMessage ? <MsgToast msg={errorMessage} color="danger" icon="ri-error-warning-line" /> : null}
        </CardBody>
      </Card>
    </Row>
  );
};

export default CheckoutCard;
