import React, { useEffect, useMemo, useState } from "react";

import classnames from "classnames";
import Select from "react-select";
import { APIClient } from "../../helpers/api_helper";
import * as URL from "../../helpers/url_helper";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";

//Import actions
//redux
import { useDispatch, useSelector } from "react-redux";
import MsgToast from "../../Components/Common/MsgToast";
import ReportTableContainer from "../../Components/Common/ReportTableContainer";

import "react-toastify/dist/ReactToastify.css";
import selectColumnsStyles from "./selectComponentStyles";

import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import * as Yup from "yup";
import AlertModal from "../../Components/Common/AlertModal";
import DateField from "../../Components/Common/Fields/DateField";
import "./reporting.style.css";

import { MultiValueLabel, Placeholder } from "./components";

// actions
import { useCallback } from "react";
import { createReport, runReport as onRunReport, resetReportFlags, updateReport } from "../../store/reporting/action";
import { filterOperators } from "./data";
import { handleValidDateAlt } from "../../Utility";
import { ReportChart } from "./ReportChart";
import { CSVLink } from "react-csv";
import Loader from "../../Components/Common/Loader";

let jsonToString = (item) => {
  if (!item) return "";
  return JSON.stringify(item);
};

let parseToJson = (item) => {
  if (!item) return [];
  return JSON.parse(item);
};

let getAggregateOptions = (columns) => {
  if (!columns) return [];

  return columns.map((agg) => {
    if (agg) {
      return { value: agg, label: agg.replaceAll("_", " ") };
    }
  });
};
let aggregation_columns = ["__count__", "__avg__", "__sum__", "__min__", "__max__"];

let time_series_pattern = [
  { label: "none", value: "" },
  { label: "daily", value: "daily" },
  { label: "weekly", value: "weekly" },
  { label: "semimonthly", value: "semimonthly" },
  { label: "monthly", value: "monthly" },
  { label: "quarterly", value: "quarterly" },
  { label: "semiannually", value: "semiannually" },
  { label: "annually", value: "annually" },
];

let related_fields = ["created_by", "services", "address", "assigned_to"];

const CreateReportForm = () => {
  const { id, type } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showReportTitleInput, setShowReportTitleInput] = useState(false);

  const [report, setReport] = useState(null);
  const [reportData, setReportData] = useState([]); // represent the report itself, the data gotten with filters

  const [saveModal, setSaveModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  let [isTimeSeries, setIsTimeSeries] = useState(type === "timeseries");

  const [groupBy, setGroupBy] = useState("");

  const [tableColumns, setTableColumns] = useState([]);
  const [serviceValues, setServiceValues] = useState([]);
  const [timeSeriesColumnsValues, setTimeSeriesColumnsValues] = useState([]);

  const [assignedToValues, setAssignedToValues] = useState([]);

  const [columnOptions, setColumnsOptions] = useState([]);
  const [groupByOptions, setGroupByOptions] = useState([]);

  const [unsavedChanges, setunSavedChanges] = useState(true);

  const [serviceOptions, setServiceOptions] = useState();
  const [assignedToOptions, setAssignedToOptions] = useState();

  const [originalReport, setOriginalReport] = useState();
  const [dateField, setDateField] = useState("created_on");
  const [columnValue, setColumnValue] = useState({});

  const [timeSeriesPattern, setTimeSeriesPattern] = useState("");
  const [timeSeriesColumns, settimeSeriesColumns] = useState([]);

  const reportChart = report && report.chart;
  let aggregateOptions = getAggregateOptions(aggregation_columns);

  let aggregations_labels = report && parseToJson(report.aggregation_columns);

  aggregations_labels = getAggregateOptions(aggregations_labels);

  // Custom Tabs Bordered
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  // report store
  const {
    reportList,
    currentReport,
    isUpdateFailed,
    isCreateFailed,
    isExportFailed,
    isUpdateSuccess,
    isCreateSuccess,
    isExportSuccess,
    error,
    loading,
    isReportRun,
    isReportRunFailed,
    isReportRunSuccess,
    isRunningReport,
  } = useSelector((state) => ({
    reportList: state.Reporting.reportList,
    currentReport: state.Reporting.currentReport,
    isUpdateFailed: state.Reporting.isUpdateFailed,
    isCreateFailed: state.Reporting.isCreateFailed,
    isExportFailed: state.Reporting.isExportFailed,
    isUpdateSuccess: state.Reporting.isUpdateSuccess,
    isCreateSuccess: state.Reporting.isCreateSuccess,
    isExportSuccess: state.Reporting.isExportSuccess,
    error: state.Reporting.error,
    loading: state.Reporting.loading,
    isReportRun: state.Reporting.isReportRun,
    isReportRunSuccess: state.Reporting.isReportRunSuccess,
    isReportRunFailed: state.Reporting.isReportRunFailed,
    isRunningReport: state.Reporting.isRunningReport,
  }));

  const form = useFormik({
    enableReinitialize: true,

    initialValues: {
      report_type: isTimeSeries ? "time_series" : "simple",
      report_model: "Client",
      start_date: report?.start_date ? handleValidDateAlt(report?.start_date) : "",
      end_date: report?.end_date ? handleValidDateAlt(report?.end_date) : "",

      kw_filters: {
        services: originalReport?.kw_filters?.services || [],

        assigned_to: originalReport?.kw_filters?.assigned_to || [],
      },
      q_filters: {},
      chart_settings: (originalReport && originalReport.chart_settings) || {},
      group_by: groupBy || "",
      date_field: (originalReport && originalReport.date_field) || "created_on",

      ...(isTimeSeries && { time_series_pattern: timeSeriesPattern || "quarterly" }),
      ...(isTimeSeries && { time_series_columns: ["__count__"] }),
      ...(isTimeSeries && { columns: ["first_name"] }),
      ...(isTimeSeries && { group_by: "first_name" }),

      ...(id && { time_series_pattern: timeSeriesPattern }),
      ...(id && { time_series_columns: timeSeriesColumns }),
      ...(id && { report_type: originalReport?.report_type }),

      cross_tab_model: (originalReport && originalReport.cross_tab_model) || "assigned_to",
      cross_tab_columns: (originalReport && originalReport.cross_tab_columns) || [],
      id: (originalReport && originalReport.id) || "",
      title: (originalReport && originalReport?.title) || "Client Report N#",
      unique_title: (originalReport && originalReport.unique_title) || "",
      description: (originalReport && originalReport.description) || "",
      aggregation_columns: (originalReport && originalReport.aggregation_columns) || [],
    },

    validationSchema: Yup.object().shape({
      id: Yup.number().nullable(),
      start_date: Yup.date(),
      end_date: Yup.date(),
      kw_filters: Yup.object().shape({
        services: Yup.array().nullable(),
        assigned_to: Yup.array().nullable(),
        created_by: Yup.array().nullable(),
      }),
      q_filters: Yup.object().shape({
        services: Yup.array().nullable(),
        assigned_to: Yup.array().nullable(),
        created_by: Yup.array().nullable(),
      }),
      chart_settings: Yup.object().nullable(),
      group_by: Yup.string().nullable(),
      date_field: Yup.string(),
      columns: Yup.array().of(Yup.string()),
      time_series_pattern: Yup.mixed().nullable(),
      time_series_columns: Yup.mixed().nullable(),
      cross_tab_model: Yup.string(),
      cross_tab_columns: Yup.array(),
      title: Yup.string(),
      unique_title: Yup.string(),
      description: Yup.string().notRequired(),
    }),

    onSubmit: (values) => {
      if (id) {
        dispatch(createReport(values));
      } else {
        dispatch(updateReport(values));
      }
    },
  });

  useEffect(() => {
    if (currentReport) {
      setReport(currentReport);
    }
  }, [isReportRun, currentReport]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetReportFlags());
    }, 3000);
  }, [
    dispatch,
    isCreateFailed,
    isCreateSuccess,
    isUpdateFailed,
    isUpdateSuccess,
    isExportSuccess,
    isExportFailed,
    error,
    isReportRun,
    isReportRunFailed,
    isReportRunSuccess,
  ]);

  const { values: formValues, resetForm } = form;
  const run = useCallback(() => {
    let values = {};
    if (id) {
      values = reportList?.find((rl) => rl.id.toString() === id);

      if (!values) return;

      setOriginalReport(values);

      setDateField(values.date_field);

      let kw_services = values?.kw_services.map((service) => {
        return service.id;
      });
      let kw_assigned_to = values?.kw_assigned_to.map((ass_to) => {
        return ass_to.id;
      });

      const serviceValues = values?.kw_services?.map((service) => ({
        label: `${service.name}`,
        value: service.id,
      }));

      const assignedToValues = values?.kw_assigned_to?.map((profile) => ({
        label: `${profile.user_details.first_name} ${profile.user_details.last_name}`,
        value: profile.id,
      }));

      setServiceValues(serviceValues);
      setAssignedToValues(assignedToValues);

      isTimeSeries = isTimeSeries || values.report_type === "time_series";

      if (isTimeSeries) {
        const timeSeriesColumnsValues = values?.time_series_columns?.map((col) => ({
          label: `${col.replaceAll("_", "")}`,
          value: col,
        }));
        setTimeSeriesColumnsValues(timeSeriesColumnsValues);
      }

      setIsTimeSeries(isTimeSeries);

      setTimeSeriesPattern(values.time_series_pattern || "");

      settimeSeriesColumns(values.time_series_columns || []);

      values.kw_filters = { services: kw_services, assigned_to: kw_assigned_to };
    } else {
      values = formValues;
    }
    dispatch(onRunReport(values));
  }, [dispatch, id]);

  useEffect(() => {
    run();
  }, [run, id]);

  useEffect(() => {
    if (!report) return;

    const { data, columns, m_columns, r_columns, foreign_keys, filter_values } = report; // from store
    setReportData(data); // for react-table

    // prepare report columns options
    // column options will comprise of
    // c_columns: columns return by the generated report from the backend
    // mc_columns: columns returned by the model we can use on the report
    // rmc_columns: columns from foreign-keys (related-models) on our current report model eg. client
    // const c_options = columns?.map((c) => ({ value: c.name, label: toProperCaseName(c.name) }));

    let c_options = [];
    columns?.forEach((c) => {
      if (!c) return;

      let cl = { value: c.name, label: toProperCaseName(c.name) };

      return c_options.push(cl);
    });

    const mc_options = m_columns?.map((mc) => ({ value: mc, label: toProperCaseName(mc) }));
    const rmc_options =
      r_columns &&
      Object.keys(r_columns)
        ?.map((c) => r_columns[c]?.map((r) => ({ value: r, label: toProperCaseName(r) })))
        .reduce((acc, curr) => [...curr, ...acc], []);
    // add model columns and related columns as options to columns select
    setColumnsOptions([...c_options, ...mc_options, ...rmc_options]);
    // prepare foreign keys
    const fks = Object?.keys(foreign_keys).map((fk) => ({
      label: toProperCaseName(foreign_keys[fk]),
      value: foreign_keys[fk],
    }));
    // group_by fields are fields we can group the report based on eg. Services, Assignee
    // it can also be simply fields on our model eg. gender, lastname, etc..
    // set foreign_keys,  mc_columns, rmc_columns as fields we can group_by
    setGroupByOptions([...fks, ...mc_options, ...rmc_options]);

    //  This hook is responsible for reparing the options and select components for filtering by
    //  We can go ahead to filter a generated report by services, assignee, or author(created_by)

    const { assigned_to, services, created_by } = filter_values; // from store
    // prepare assigned_to / owner field for labels and values
    const a_labels = assigned_to?.map(({ id, user_details: user }) => ({
      label: `${user.first_name} ${user.last_name}`,
      value: id,
    }));
    setAssignedToOptions(a_labels);
    // prepare created_by / author field for labels and values
    const c_labels = created_by?.map(({ user_details: user }) => ({
      label: `${user.first_name} ${user.last_name}`,
      value: user.id,
    }));
    // prepare services field for labels and values
    const s_labels = services?.map((service) => ({ label: `${service.name}`, value: service.id }));
    setServiceOptions(s_labels);

    // set model columns as active/selected on the report
    handleSelectColumns(c_options, false); // prolly causing some issues

    const { title, unique_title, description, group_by } = report;
    setGroupBy(group_by);

    form.setFieldValue("title", title);
    form.setFieldValue("unique_title", unique_title);
    form.setFieldValue("description", description);
    form.setFieldValue("group_by", group_by);

    setColumnValue({ label: group_by, value: group_by });

    // clean up the state
    return () => {
      // resetForm();
      // console.log("RESET_FORM", form.values);
    };
  }, [report]);

  // Close modal and handler
  const handleCloseReportClick = () => {
    let a = unsavedChanges
      ? () => {
          setCloseModal(true);
        }
      : () => {
          //TODO: probably a good idea to handle clean ups first
          history.goBack();
        };
    a();
  };

  const handleCloseReportAccept = () => {
    alert("changes have been discarded");
    history.goBack();
  };

  const handleExportClick = () => console.log("Export Click");

  const handleAddChartClick = () => console.log("Chart Click");

  const handleSaveClick = (e) => {
    e.preventDefault();
    let newValue = form.values;

    let cols = tableColumns.filter((col) => {
      if (!col.value.includes("TS")) {
        return col;
      }
    });

    cols = cols.map((c) => c.value);

    if (id) {
      newValue.columns = [...new Set([...cols])];
      newValue.id = id;
      return dispatch(updateReport(newValue));
    }
    setSaveModal(true);
  };

  const handleSaveWithDetails = () => {
    let values = form.values;

    let cols = tableColumns.filter((col) => {
      if (!col.value.includes("TS")) {
        return col;
      }
    });
    cols = cols.map((c) => c.value);

    values.columns = cols;

    dispatch(createReport(form.values));
    setSaveModal(false);
  };

  // handle form submit and form repaint
  const handleRunClick = (group_by, new_cols) => {
    const { values, errors } = form;

    let newValue = { ...values };

    let cols = tableColumns.filter((col) => {
      if (!col.value.includes("TS")) {
        return col;
      }
    });

    cols = cols.map((c) => c.value);

    newValue.columns = [...new Set([...cols])];

    if (group_by) {
      newValue.columns = new_cols;
    }

    newValue.group_by = group_by || groupBy;

    dispatch(onRunReport(newValue));
  };

  // handle on_group_by change
  const handleSelectGroupBy = (option) => {
    if (!option) {
      setGroupBy("");
      return setColumnValue({});
    }
    let { value } = option;
    setTableColumns([]);
    setGroupBy(option.value);

    let new_cols = ["id", "__count__"];

    if (!related_fields.includes(value)) {
      new_cols.push(value);
    }

    let new_tc = new_cols.map((tc) => ({ label: toProperCaseName(tc), value: tc }));
    setTableColumns(new_tc);

    handleRunClick(option.value, new_cols);
  };

  /** 
    handle columns change in select component and form
    saves columns on our form object as an array of strings [value, value1, value3]
    and saves columns for our select component as an array of objects
    with the form  : { label, value} 
  */
  const handleSelectColumns = (options, refetch = true) => {
    const group_by = form.values.group_by;

    // if all columns are cleared when a grouping is set
    // clear group_by field an refetch orginal simple report
    if (group_by && !options.length) return handleSelectGroupBy();

    setTableColumns(options);
  };

  //  convert columns strings into readable human format without symbols
  const toProperCaseName = (str) => {
    const e = str?.replaceAll("_", " ");
    var rg = /(^\w{1}|\.\s*\w{1})/gi;
    const myString = e?.replace(rg, function (toReplace) {
      return toReplace.toUpperCase();
    });

    return myString;
  };

  const asyncSearch = (url = URL.GET_CLIENTS_LIST, query) => {
    const results = new APIClient().get(url, { search: query });
    return results;
  };

  const servicesPromiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      new APIClient()
        .get(URL.GET_CLIENTS_LIST, { search: inputValue })
        .then(({ client_obj_list }) => {
          return client_obj_list;
          // return client_obj_list.filter(
          //   (client) =>
          //     client.first_name.toLowerCase().includes(inputValue) ||
          //     client.last_name.toLowerCase().includes(inputValue)
          // );
        })
        .then((foundClients) =>
          foundClients.map((client) => ({ label: `${client.first_name} ${client.last_name}`, value: client.id }))
        )
        .then((options) => resolve(options));
    });
  };

  const reportColumns = useMemo(
    () => tableColumns?.map((c) => ({ Header: c.label, accessor: c.value })),
    [tableColumns]
  );

  document.title = "Reports | SimplyNP";

  return (
    <React.Fragment>
      <div className="page-content">
        <AlertModal
          show={closeModal}
          alertTitle={"You have unsaved changes."}
          alertMessage={"Are you sure you want to proceed?"}
          closeText={"Go Back"}
          onAcceptClick={handleCloseReportAccept}
          onDenyClick={() => {}}
          onCloseClick={() => setCloseModal(false)}
        />

        <Container fluid>
          <BreadCrumb title="Create Report" pageTitle="Reports" />
          {(reportChart && reportChart.length && <ReportChart chart={reportChart} dataColors='["--vz-success"]' />) ||
            null}

          {/*  Actions  */}
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    {/* Input Fields Report Name */}
                    <div className="flex-shink-0">
                      {!showReportTitleInput ? (
                        <span
                          style={{
                            fontSize: "16px",
                            cursor: "pointer",
                            paddingLeft: "6px",
                          }}
                          onClick={(e) => setShowReportTitleInput(true)}
                        >
                          {form.values.title}
                        </span>
                      ) : (
                        <Input
                          name="title"
                          id="report-title"
                          type="text"
                          autoFocus={showReportTitleInput}
                          style={{
                            fontSize: "15px",
                            boxShadow: showReportTitleInput ? "0 0 0 2px rgba(0, 150, 255, 0.5)" : "none",
                          }}
                          value={form.values.title}
                          className="border-1 border-info offset-0"
                          onChange={form.handleChange}
                          onBlur={(e) => {
                            // change input selectable mode
                            setShowReportTitleInput(false);
                            // save with new name
                          }}
                        />
                      )}
                    </div>
                    <div className="flex-shink-0">
                      <button
                        hidden={showReportTitleInput}
                        className="btn px-0 text-muted"
                        onClick={(e) => setShowReportTitleInput(true)}
                      >
                        <i className="ri-pencil-fill ri-md me-1 align-top px-0"></i>
                      </button>
                    </div>
                    <div className="flex-shrink-0" hidden={showReportTitleInput}>
                      <span
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.1)", cursor: "default" }}
                        className="rounded-pill px-2 py-0 m-0"
                      >
                        <strong>Clients</strong>
                      </span>
                    </div>

                    {/* Spacer / Gap */}
                    <div className="flex-grow-1"></div>

                    {/* Action Buttons */}
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                        <button type="button" className="btn btn-warning" onClick={handleCloseReportClick}>
                          <i className="ri-close-fill me-1 align-bottom"></i>Close
                        </button>
                        <button type="button" className="btn btn-soft-success" onClick={handleExportClick}>
                          <i className="ri-file-upload-fill me-1 align-bottom"></i>
                          <CSVLink filename="generated_report" data={reportData || ""} asyncOnClick={true}>
                            Export
                          </CSVLink>
                        </button>
                        <button type="button" className="btn btn-soft-info" onClick={handleAddChartClick}>
                          <i className="ri-pie-chart-fill me-1 align-bottom"></i>Add Chart
                        </button>
                        <button type="button" className="btn btn-success" onClick={handleSaveClick}>
                          <i className="ri-save-fill me-1 align-bottom"></i>
                          {id ? "update" : "Save"}
                        </button>
                        <button
                          type="submit"
                          className="btn btn-info"
                          onClick={() => {
                            handleRunClick();
                          }}
                        >
                          <i className="ri-play-fill me-1 align-bottom"></i>Run
                        </button>
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
          </Row>

          {isRunningReport ? <Loader /> : null}

          {/* Filters and Table */}
          <Row className="">
            {/* Filter and Columns */}
            <Col md={3} className="">
              <Card className="card-h-100 h-100">
                <Nav tabs className="nav nav-tabs nav-tabs-custom nav-primary nav-justified mb-0 p-0">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames("py-4", {
                        active: customActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleCustom("1");
                      }}
                    >
                      <div className="d-flex align-items-left">
                        <div className="flex-shrink-0">
                          <i className="ri-list-check ri-xl align-middle text-gray px-1"></i>
                          Outline
                        </div>
                      </div>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames("py-4", {
                        active: customActiveTab === "2",
                      })}
                      onClick={() => {
                        toggleCustom("2");
                      }}
                    >
                      <div className="d-flex align-items-left">
                        <div className="flex-shrink-0">
                          <i className="ri-filter-2-line ri-xl align-middle text-gray px-1"></i>
                          Filters
                        </div>
                      </div>
                    </NavLink>
                  </NavItem>
                </Nav>

                {/* Tab Content Page */}
                <CardBody className="">
                  <TabContent activeTab={customActiveTab}>
                    {/* columns tab */}
                    <TabPane tabId="1" id="home1">
                      <div className="">
                        <div className="flex-grow-1">
                          <h6 className="">Groups</h6>

                          <Col className="px-0">
                            <Select
                              placeholder="Select a column..."
                              isClearable
                              // value={columnValue}
                              value={groupByOptions.find((g) => g.value === columnValue.value) || ""}
                              options={groupByOptions}
                              onChange={handleSelectGroupBy}
                            />
                          </Col>
                        </div>
                        <hr />
                        <div className="flex-grow-1">
                          <h6 className="">Columns</h6>

                          <Col className="px-0">
                            <Select
                              className="px-0 border-0"
                              name="table_columns"
                              isMulti
                              components={{ Placeholder }}
                              placeholder="Select a column..."
                              styles={selectColumnsStyles}
                              value={tableColumns}
                              options={columnOptions}
                              onChange={(e) => handleSelectColumns(e)}
                            />
                          </Col>
                        </div>
                      </div>
                    </TabPane>

                    {/* filters tab */}
                    <TabPane tabId="2">
                      <div className="flex-grow-1">
                        <h6 className="">Date field</h6>
                        <Col className="px-0">
                          <Select
                            components={{
                              MultiValueLabel,
                              Placeholder,
                            }}
                            placeholder="Add a Filter..."
                            styles={{ ...selectColumnsStyles }}
                            options={[
                              {
                                label: toProperCaseName("created_on"),
                                value: "2023-07-20",
                                name: "start-date",
                                field: "created_on",
                              },
                              {
                                label: toProperCaseName("date_of_birth"),
                                value: "2022-06-12",
                                start_date: "start-date",
                                end_date: "start-date",
                                field: "date_of_birth",
                              },
                            ]}
                            onChange={(e) => {
                              form.setFieldValue("date_field", e.field);
                              setDateField(e.field);
                            }}
                            value={[
                              {
                                label: dateField?.replaceAll("_", " "),
                                value: dateField,
                              },
                            ]}
                          />
                        </Col>
                      </div>
                      <hr />
                      <div className="">
                        <div className="flex-grow-1">
                          <Col className="px-0">
                            <h6 className="">Start Date</h6>
                            <DateField name="start_date" placeholder="Select Start Date..." validation={form} />
                          </Col>
                          <Col className="px-0">
                            <h6 className="">End Date</h6>
                            <DateField name="end_date" placeholder="Select End Date..." validation={form} />
                          </Col>
                        </div>
                        <hr />

                        {isTimeSeries ? (
                          <Card>
                            <h6>Time series pattern</h6>
                            <Row>
                              <Col md="12">
                                <div className="mb-3">
                                  <Select
                                    id="choices-team-input"
                                    value={[
                                      {
                                        label: timeSeriesPattern || "select...",
                                        value: timeSeriesPattern,
                                      },
                                    ]}
                                    key={[
                                      {
                                        label: timeSeriesPattern,
                                        value: timeSeriesPattern,
                                      },
                                    ]}
                                    name="time_series_pattern"
                                    onChange={(e) => setTimeSeriesPattern(e.value)}
                                    options={time_series_pattern}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Card>
                        ) : null}

                        <hr />

                        {isTimeSeries ? (
                          <Row>
                            <div className="flex-grow-1">
                              <h6 className="">Time series column</h6>

                              <Col className="px-0">
                                <Select
                                  className="px-0 border-0"
                                  name="aggregation_columns"
                                  isMulti
                                  components={{ Placeholder }}
                                  placeholder="Select a column..."
                                  styles={selectColumnsStyles}
                                  value={timeSeriesColumnsValues}
                                  options={aggregateOptions}
                                  defaultValue={aggregations_labels}
                                  key={aggregations_labels}
                                  onChange={(e) => {
                                    setTimeSeriesColumnsValues(e);
                                    form.setFieldValue(
                                      "time_series_columns",
                                      e.map((e) => e.value)
                                    );
                                  }}
                                />
                              </Col>
                            </div>
                          </Row>
                        ) : null}

                        <hr style={{ opacity: 0 }} />
                        <h4>Filter by</h4>
                        <hr style={{ opacity: 0 }} />
                        <div className="flex-grow-1">
                          <h6 className="">Service(s)</h6>
                          <Col className="px-0">
                            {/* <AsyncSelect
                              isMulti
                              cacheOptions={true}
                              components={{ Placeholder }}
                              placeholder="Add a Filter..."
                              // components={{ animatedComponents, Control: (props) => {} }}
                              styles={selectColumnsStyles}
                              loadOptions={servicesPromiseOptions}
                            /> */}
                            <Select
                              isMulti
                              components={{ Placeholder }}
                              placeholder="Add a Filter..."
                              styles={selectColumnsStyles}
                              value={serviceValues}
                              options={serviceOptions}
                              onChange={(options) => {
                                const ids = options.map((o) => o.value);
                                const { kw_filters } = form.values;

                                setServiceValues(options);
                                form.setFieldValue("kw_filters", {
                                  ...kw_filters,
                                  services: ids,
                                });
                              }}
                            />
                          </Col>
                        </div>

                        <hr />
                        <div className="flex-grow-1">
                          <h6 className="">Assigned To</h6>
                          <Col className="px-0">
                            <Select
                              isMulti
                              components={{ Placeholder }}
                              placeholder="Add a Filter..."
                              // components={{ animatedComponents, Control: (props) => {} }}
                              styles={selectColumnsStyles}
                              value={assignedToValues}
                              options={assignedToOptions}
                              onChange={(options) => {
                                setAssignedToValues(options);

                                const ids = options.map((o) => o.value);
                                const { kw_filters } = form.values;
                                form.setFieldValue("kw_filters", {
                                  ...kw_filters,
                                  assigned_to: ids,
                                });
                              }}
                            />
                          </Col>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>

            {/* Reporting Table */}
            <Col md={9} className="">
              <Card className="h-100">
                <CardBody>
                  <ReportTableContainer
                    tableClass="table-bordered"
                    divClass=" table-responsive"
                    columns={reportColumns}
                    data={reportData}
                    customFetchData={() => []}
                    // customPageSize={10}
                    // customTotalSize={400}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* // message alerts */}
          {isCreateSuccess && <MsgToast msg="Report saved successfully" color="success" icon="ri-check-line" />}
          {isUpdateSuccess && <MsgToast msg="Report updated successfully" color="success" icon="ri-check-line" />}
          {isExportSuccess && <MsgToast msg="Report exported successfully" color="success" icon="ri-check-line" />}

          {isCreateFailed && <MsgToast msg="Error Saving Report" color="success" icon="ri-error-warning-line" />}
          {isUpdateFailed && <MsgToast msg="Error Updating Report" color="success" icon="ri-error-warning-line" />}
          {isExportFailed && <MsgToast msg="Error Exporting Report" color="success" icon="ri-error-warning-line" />}

          {/* Form Modal */}
          <Modal isOpen={saveModal} id="event-modal" centered toggle={() => setSaveModal(!saveModal)}>
            <ModalHeader tag="h5" toggle={() => setSaveModal(!saveModal)} className="p-3 bg-soft-info modal-title">
              Report details
            </ModalHeader>
            <ModalBody>
              <Row>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="title">
                    Report Title
                  </Label>
                  <Input
                    type="text"
                    name="title"
                    value={form.values.title}
                    onChange={form.handleChange}
                    classtitle="form-control"
                    id="case-title-input"
                    placeholder="Enter a title"
                    invalid={form.touched.title && form.errors.title ? true : false}
                  />
                  {form.touched.title && form.errors.title ? (
                    <FormFeedback type="invalid">{form.errors.title}</FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label classtitle="form-label" htmlFor="unique_title">
                    Report Unique Title
                  </Label>
                  <Input
                    type="text"
                    name="unique_title"
                    value={form.values.unique_title}
                    onChange={form.handleChange}
                    classunique_title="form-control"
                    id="case-unique_title-input"
                    placeholder="Enter a unique title"
                    invalid={form.touched.unique_title && form.errors.unique_title ? true : false}
                  />
                  {form.touched.unique_title && form.errors.unique_title ? (
                    <FormFeedback type="invalid">{form.errors.unique_title}</FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label className="form-label" htmlFor="description">
                    Report Description
                  </Label>
                  <Input
                    type="textarea"
                    cols="5"
                    col="5"
                    name="description"
                    value={form.values.description}
                    onChange={form.handleChange}
                    className="form-control"
                    placeholder="Enter report description"
                    invalid={form.touched.description && form.errors.description ? true : false}
                  />
                  {form.touched.description && form.errors.description ? (
                    <FormFeedback type="invalid">{form.errors.description}</FormFeedback>
                  ) : null}
                </div>

                <div className="hstack gap-2 justify-content-end">
                  <button type="button" onClick={handleSaveWithDetails} className="btn btn-success" id="btn-save-event">
                    Save
                  </button>
                </div>
              </Row>
            </ModalBody>
          </Modal>

          {/* Fiter Modal  */}
          <Modal isOpen={filterModal} id="event-modal" centered toggle={() => setFilterModal(!filterModal)}>
            <ModalHeader tag="h5" toggle={() => setFilterModal(!filterModal)} className="p-3 bg-soft-info modal-title">
              Edit Filter
            </ModalHeader>
            <ModalBody>
              <Row>
                <div className="mb-3">
                  <Label className="form-label" htmlFor="title">
                    Operator
                  </Label>
                  <Select
                    name="filter_operator"
                    options={filterOperators}
                    onChange={(option) => {}}
                    id="filter_operator-input"
                    placeholder="Enter Operator"
                  />
                </div>

                <div className="mb-3">
                  <Label classtitle="form-label" htmlFor="unique_title">
                    Value
                  </Label>
                  <Input type="text" name="filter_value" onChange={form.handleChange} id="filter_value-input" />
                </div>

                <div className="hstack gap-2 justify-content-end">
                  <button
                    type="button"
                    onClick={() => setFilterModal(false)}
                    className="btn btn-soft-primary"
                    id="btn-save-event"
                  >
                    Cancel
                  </button>
                  <button type="button" onClick={() => {}} className="btn btn-soft-success" id="btn-save-event">
                    Apply
                  </button>
                </div>
              </Row>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateReportForm;
