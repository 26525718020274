import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import {
  ADD_PAYMENT_METHOD,
  CREATE_BILLING,
  DELETE_BILLING,
  GET_BILLING,
  REMOVE_PAYMENT_METHOD,
  UPDATE_BILLING,
  UPDATE_PAYMENT_METHOD,
} from "./actionTypes";
import {
  // createAccountError,
  deleteBillingSuccess,
  // updateAccountSuccess,
  // updateAccountFail,
  getBilling as onGetBilling,
  getBillingFail,
  updateBillingSuccessful,
  getBillingSuccess,
  createBillingSuccessful,
  addPaymentMethodFail,
  addPaymentMethodSuccess,
  removePaymentMethodSuccess,
  removePaymentMethodFail,
  updateBillingFail,
  updatePaymentMethodSuccess,
  updatePaymentMethodFail,
} from "./actions";
import {
  removePaymentMethod,
  getBilling,
  updateBilling,
  addPaymentMethod,
  updatePaymentMethod,
} from "../../helpers/backend_helper";

//

// Is user register successfull then direct plot user in redux.
function* getBillingInfoWorker({ payload: account }) {
  try {
    const response = yield call(getBilling, account);

    if (response.status === 200) {
      yield put(getBillingSuccess(response.billing_information));
    } else if (response.status === 400) {
      yield put(getBillingFail(response.errors));
    }
  } catch (error) {
    yield put(getBillingFail(error));
  }
}

function* updateBillingInfoWorker({ payload: billing }) {
  try {
    const response = yield call(updateBilling, billing.id, billing);
    yield put(updateBillingSuccessful(response.billing_information));
    yield put(onGetBilling());
  } catch (error) {
    yield put(updateBillingFail(error));
  }
}

function* addPaymentMethodWorker({ payload }) {
  try {
    const response = yield call(addPaymentMethod, payload);
    yield put(addPaymentMethodSuccess(response));
  } catch (error) {
    yield put(addPaymentMethodFail(error));
  }
}

function* updatePaymentMethodWorker({ payload }) {
  try {
    const response = yield call(updatePaymentMethod, payload);
    yield put(updatePaymentMethodSuccess(response.billing_information));
  } catch (error) {
    yield put(updatePaymentMethodFail(error));
  }
}

function* removePaymentMethodWorker({ payload: billingId }) {
  try {
    const response = yield call(removePaymentMethod, billingId);
    yield put(removePaymentMethodSuccess(response));
  } catch (error) {
    yield put(removePaymentMethodFail(error));
  }
}

export function* watchAddPaymentMethod() {
  yield takeEvery(ADD_PAYMENT_METHOD, addPaymentMethodWorker);
}

export function* watchUpdatePaymentMethod() {
  yield takeEvery(UPDATE_PAYMENT_METHOD, updatePaymentMethodWorker);
}

export function* watchRemovePaymentMethod() {
  yield takeEvery(REMOVE_PAYMENT_METHOD, removePaymentMethodWorker);
}

export function* watchUpdateBillingWorker() {
  yield takeEvery(UPDATE_BILLING, updateBillingInfoWorker);
}

export function* watchGetBillingWorker() {
  yield takeEvery(GET_BILLING, getBillingInfoWorker);
}

function* BillingSaga() {
  yield all([
    fork(watchAddPaymentMethod),
    fork(watchUpdatePaymentMethod),
    fork(watchRemovePaymentMethod),

    fork(watchGetBillingWorker),
    fork(watchUpdateBillingWorker),
  ]);
}

export default BillingSaga;
