// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_CASES_LIST = "GET_CASES_LIST";
export const GET_CASE = "GET_CASE";
// export const ADD_NEW_CASES_LIST = "ADD_NEW_CASES_LIST";
// export const UPDATE_CASES_LIST = "UPDATE_CASES_LIST";
// export const DELETE_CASES_LIST = "DELETE_CASES_LIST";

/// Add CASE
export const ADD_NEW_CASE = "ADD_NEW_CASE";
export const ADD_CASE_SUCCESS = "ADD_CASE_SUCCESS";
export const ADD_CASE_FAIL = "ADD_CASE_FAIL";

// Edit CASE
export const UPDATE_CASE = "UPDATE_CASE";
export const UPDATE_CASE_SUCCESS = "UPDATE_CASE_SUCCESS";
export const UPDATE_CASE_FAIL = "UPDATE_CASE_FAIL";

// Delete CASE
export const DELETE_CASE = "DELETE_CASE";
export const DELETE_CASE_SUCCESS = "DELETE_CASE_SUCCESS";
export const DELETE_CASE_FAIL = "DELETE_CASE_FAIL";

export const RESET_CASE_FLAG = "RESET_CASE_FLAG";
