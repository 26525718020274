import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { contactStageOptions, statusOptions } from "../../../../../common/data/common";
import countries from "../../../../../common/data/countries.json";
import sourceOptions from "../../../../../common/data/leadSource.json";

import BreadCrumb from "../../../../../Components/Common/BreadCrumb";

import { useParams } from "react-router-dom";

import * as moment from "moment";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import AsyncSelect from "react-select/async";

// Import FilePond styles
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";

import { ToastContainer } from "react-toastify";
import MsgToast from "../../../../../Components/Common/MsgToast";

import { getContacts, getOrganizationList, getUsers } from "../../../../../helpers/backend_helper";
import {
  getContactByID,
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  getOrganizations as onGetOrganizationList,
  getUsersList as onGetUsers,
  updateContact as onUpdateContact,
  resetCrmFlag,
} from "../../../../../store/actions";
import IntNumberInput from "../../../../../Components/Common/Fields/IntNumberInput";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const CreateContact = (props) => {
  const dispatch = useDispatch();
  const { id: contact_pk } = useParams();

  const {
    contactList,
    crmContact,
    error,
    users,
    organizationList,
    isContactUpdate,
    isContactUpdateFail,
    isContactAddFail,
    isContactAdd,
    isContactCreated,
    isContactSuccess,
  } = useSelector((state) => ({
    isContactCreated: state.Crm.isContactsCreated,
    isContactSuccess: state.Crm.isContactSuccess,
    contactList: state.Crm.crmcontacts,
    organizationList: state.Crm.organizations,
    error: state.Crm.error,
    crmContact: state.Crm.contact,
    users: state.Users.usersList,
    isContactAdd: state.Crm.isContactAdd,
    isContactAddFail: state.Crm.isContactAddFail,
    isContactUpdate: state.Crm.isContactUpdate,
    isContactUpdateFail: state.Crm.isContactUpdateFail,
  }));

  const [files, setFiles] = useState([]);
  const [contact, setContact] = useState(null);
  const [country, setCountry] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [assignedUser, setAssignedUser] = useState(null);
  const [reportsTo, setReportsTo] = useState(null);

  const loadUsersOptions = (inputValue) => {
    return new Promise((resolve) => {
      const orgList = getUsers({ search: inputValue }).then(({ users: { users } }) => {
        return users;
      });
      resolve(orgList);
    });
  };

  const loadOrganizationOptions = (inputValue) => {
    return new Promise((resolve) => {
      const orgList = getOrganizationList({ search: inputValue }).then(
        ({ active_accounts: { active_accounts }, inactive_accounts: { inactive_accounts } }) => {
          const c = [...active_accounts, ...inactive_accounts];
          return c;
        }
      );
      resolve(orgList);
    });
  };

  const loadReportsToOptions = (inputValue) => {
    return new Promise((resolve) => {
      const orgList = getContacts({ search: inputValue }).then(({ contact_obj_list }) => {
        return contact_obj_list;
      });
      resolve(orgList);
    });
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetCrmFlag());
    }, 3000);
  }, [dispatch, isContactSuccess, error, isContactAdd, isContactAddFail, isContactUpdate, isContactUpdateFail]);

  useEffect(() => {
    if (!contactList) {
      dispatch(onGetContacts({ id: contact_pk }));
      return;
    }

    if (!users) dispatch(onGetUsers());
    if (!organizationList) dispatch(onGetOrganizationList());
    // check if update props is passed
    if (props.update) {
      if (contactList) {
        let c = contactList.find((contact) => contact.id.toString() === contact_pk);
        if (c) setContact(c);
      }
    }

    if (contact) {
      let c = countries.find((c) => c.value === contact?.address.country);
      setCountry(c);
      setOrganization(contact?.organization);
      setReportsTo(contact?.reports_to);
      setAssignedUser(contact?.assigned_to);
    }
  }, [dispatch, organizationList, contact_pk, props.update, contactList, users, contact, crmContact]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: (contact && contact.id) || "",
      salutation: (contact && contact.salutation) || "",
      first_name: (contact && contact.first_name) || "",
      last_name: (contact && contact.last_name) || "",
      date_of_birth: (contact && contact.date_of_birth) || "",
      email: (contact && contact.email) || "",
      description: (contact && contact.description) || "",
      phone: (contact && contact.phone) || "",
      home_phone: (contact && contact.home_phone) || "",
      other_phone: (contact && contact.other_phone) || "",
      mobile: (contact && contact.mobile) || "",
      fax: (contact && contact.fax) || "",
      reports_to: (contact && contact?.reports_to?.id) || "",
      contact_owner: (contact && contact.contact_owner) || "",
      stage: (contact && contact.stage) || "",

      title: (contact && contact.title) || "",
      department: (contact && contact.department) || "",
      organization: (contact && contact?.organization?.id) || "",

      address_line: (contact && contact.address?.address_line) || "",
      street: (contact && contact.address?.street) || "",
      city: (contact && contact.address?.city) || "",
      state: (contact && contact.address?.state) || "",
      // country: contact && countries && countries.find((c) => c.label === contact.country).value,
      country: (contact && contact?.address.country) || "",
      postcode: (contact && contact?.address?.postcode) || "",

      source: (contact && contact.source) || "",
      assigned_to: (contact && JSON.stringify(contact?.assigned_to?.map((e) => e.id))) || "",

      status: (contact && contact.status) || "",
      assistant: (contact && contact.assistant) || "",
      assistant_phone: (contact && contact.assistant_phone) || "",
    },

    validationSchema: Yup.object({
      salutation: Yup.string().notRequired("Please Enter a Salutation"),
      first_name: Yup.string().notRequired("Please Enter a First Name"),
      last_name: Yup.string().required("Please Enter a Last Name"),
      date_of_birth: Yup.string().notRequired("Please Enter a Date of Birth"),
      email: Yup.string().email("Please enter a valid email").notRequired("Please Enter a gender"),
      phone: Yup.string().notRequired("Please Enter a phone number"),

      mobile: Yup.string().notRequired("Please Enter a mobile number"),
      home_phone: Yup.string().notRequired("Please Enter a home number"),
      other_phone: Yup.string().notRequired("Please Enter a work number or other"),
      fax: Yup.string().notRequired("Please Enter a fax number"),

      title: Yup.mixed().notRequired("Please Enter a Title"),
      department: Yup.mixed().notRequired("Please Enter a Department"),
      organization: Yup.mixed().notRequired("Please Enter an Organization"),
      status: Yup.mixed().notRequired("Please Enter status"),
      source: Yup.mixed().notRequired("Please Enter lead source"),

      address_line: Yup.string().notRequired("Please Enter a Address Line"),
      street: Yup.string().notRequired("Please Enter a street name"),
      city: Yup.string().notRequired("Please Enter a city"),
      state: Yup.string().notRequired("Please Enter a state"),
      country: Yup.string().notRequired("Please Enter a country"),
      postcode: Yup.string().notRequired("Please Enter a postal code"),

      assistant: Yup.mixed().notRequired("Please Enter an assistant name"),
      assistant_phone: Yup.mixed().notRequired("Please Enter an assistant phone"),
      reports_to: Yup.mixed().notRequired("Please Enter name of supervisor"),
      contact_owner: Yup.mixed().notRequired("Please Enter name of contact_owner"),

      assigned_to: Yup.mixed().notRequired("Please assign this contact to a user"),
      description: Yup.mixed().notRequired("Please Enter description or remarks"),
    }),

    onSubmit: (values) => {
      if (!contact_pk) delete values.id;

      if (props.update || contact_pk) {
        dispatch(onUpdateContact(values));
      } else {
        dispatch(onAddNewContact(values));
        reset();
      }
    },
  });

  const reset = () => {
    setContact(null);
    setAssignedUser(null);
    setCountry(null);
    validation.resetForm();
  };

  const handleValidDate = (date) => {
    // console.log(date[0]);
    const date1 = moment(new Date(date[0])).format("YYYY-MM-DD");
    return date1;
  };

  document.title = "Create Contact | SimplyNP";

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={props.title} pageTitle="Contacts " />

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            // console.log(validation.errors);
            return false;
          }}
        >
          <Row>
            <Col lg={8}>
              {/* Personal Information */}
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Personal Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="product-title-input">
                          Salutation
                        </Label>
                        <Input
                          type="text"
                          name="salutation"
                          className="form-control"
                          value={validation.values.salutation}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={validation.touched.salutation && validation.errors.salutation ? true : false}
                          id="salutation-input"
                          placeholder="Salutation"
                        />
                        {validation.touched.salutation && validation.errors.salutation ? (
                          <FormFeedback type="invalid">{validation.errors.salutation}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="first-name-input">
                          First Name
                        </Label>
                        <Input
                          type="text"
                          name="first_name"
                          value={validation.values.first_name}
                          onChange={validation.handleChange}
                          className="form-control"
                          id="first-name-input"
                          invalid={validation.touched.first_name && validation.errors.first_name ? true : false}
                          placeholder="Contact First Name"
                        />
                        {validation.touched.first_name && validation.errors.first_name ? (
                          <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="last-name-input">
                          Last Name
                        </Label>
                        <Input
                          type="text"
                          value={validation.values.last_name}
                          onChange={validation.handleChange}
                          name="last_name"
                          className="form-control"
                          invalid={validation.touched.last_name && validation.errors.last_name ? true : false}
                          id="last-name-input"
                          placeholder="Contact Last Name"
                        />
                        {validation.touched.last_name && validation.errors.last_name ? (
                          <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="date_of_birth-input">
                          Date of Birth
                        </Label>
                        <Flatpickr
                          name="date_of_birth"
                          className="form-control"
                          id="date_of_birth-input"
                          value={validation.values.date_of_birth}
                          onChange={(e) => validation.setFieldValue("date_of_birth", handleValidDate(e))}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            // dateFormat: "d.m.y",
                            // defaultDate: validation.values.date_of_birth,
                          }}
                          // invalid={validation.touched.date_of_birth && validation.errors.date_of_birth ? true : false}
                          placeholder="Date of Birth"
                        />
                        {validation.touched.date_of_birth && validation.errors.date_of_birth ? (
                          <FormFeedback type="invalid">{validation.errors.date_of_birth}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              {/* Contact Information */}
              <Card>
                <CardHeader className="card-title mb-0">Contact Information</CardHeader>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="email-input">
                          Email
                        </Label>
                        <Input
                          type="text"
                          value={validation.values.email}
                          onChange={validation.handleChange}
                          name="email"
                          className="form-control"
                          invalid={validation.touched.email && validation.errors.email ? true : false}
                          id="email-input"
                          placeholder="Contact E-mail Address"
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <IntNumberInput
                      md="6"
                      mb="mb-3"
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      value={validation.values.fax}
                      label="Fax"
                      name="fax"
                      //errorMessage={client_errors}
                    />

                    {/*
                    <Col>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="fax-input">
                          Fax
                        </Label>
                        <Input
                          type="text"
                          value={validation.values.fax}
                          onChange={validation.handleChange}
                          name="fax"
                          className="form-control"
                          invalid={validation.touched.fax && validation.errors.fax ? true : false}
                          id="fax-input"
                          placeholder="Enter Fax Number"
                        />
                        {validation.touched.fax && validation.errors.fax ? (
                          <FormFeedback type="invalid">{validation.errors.fax}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                        */}
                  </Row>

                  <Row>
                    <IntNumberInput
                      md="6"
                      mb="mb-3"
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      value={validation.values.phone}
                      label="Phone Number"
                      name="phone"
                      //errorMessage={client_errors}
                    />

                    <IntNumberInput
                      md="6"
                      mb="mb-3"
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      value={validation.values.mobile}
                      label="Contact Mobile Number"
                      name="mobile"
                      //errorMessage={client_errors}
                    />

                    {/*
                    <Col>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="phone-input">
                          Phone Number
                        </Label>
                        <Input
                          type="text"
                          value={validation.values.phone}
                          onChange={validation.handleChange}
                          name="phone"
                          className="form-control"
                          invalid={validation.touched.phone && validation.errors.phone ? true : false}
                          id="phone-input"
                          placeholder="Contact Phone Number"
                        />
                        {validation.touched.phone && validation.errors.phone ? (
                          <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="mobile-input">
                          Mobile Number
                        </Label>
                        <Input
                          type="text"
                          value={validation.values.mobile}
                          onChange={validation.handleChange}
                          name="mobile"
                          className="form-control"
                          invalid={validation.touched.mobile && validation.errors.mobile ? true : false}
                          id="mobile-input"
                          placeholder="Contact Mobile Number"
                        />
                        {validation.touched.mobile && validation.errors.mobile ? (
                          <FormFeedback type="invalid">{validation.errors.mobile}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

      */}
                  </Row>

                  <Row>
                    <IntNumberInput
                      md="6"
                      mb="mb-3"
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      value={validation.values.home_phone}
                      label="Home Number"
                      name="home_phone"
                      //errorMessage={client_errors}
                    />

                    <IntNumberInput
                      md="6"
                      mb="mb-3"
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      value={validation.values.other_phone}
                      label="Other / Work Phone"
                      name="other_phone"
                      //errorMessage={client_errors}
                    />

                    {/*
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="home_phone-input">
                          Home Number
                        </Label>
                        <Input
                          type="text"
                          value={validation.values.home_phone}
                          onChange={validation.handleChange}
                          name="home_phone"
                          className="form-control"
                          invalid={validation.touched.home_phone && validation.errors.home_phone ? true : false}
                          id="home_phone-input"
                          placeholder="Contact Home Number"
                        />
                        {validation.touched.home_phone && validation.errors.home_phone ? (
                          <FormFeedback type="invalid">{validation.errors.home_phone}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="other_phone-input">
                          Other/Work Number
                        </Label>
                        <Input
                          type="text"
                          value={validation.values.other_phone}
                          onChange={validation.handleChange}
                          name="other_phone"
                          className="form-control"
                          invalid={validation.touched.other_phone && validation.errors.other_phone ? true : false}
                          id="other_phone-input"
                          placeholder="Work/Other Number"
                        />
                        {validation.touched.other_phone && validation.errors.other_phone ? (
                          <FormFeedback type="invalid">{validation.errors.other_phone}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                */}
                  </Row>
                </CardBody>
              </Card>
              {/* Work Information */}
              <Card>
                <CardHeader className="card-title mb-0">Work Information</CardHeader>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="choices-lead-input" className="form-label">
                          Organization
                        </Label>
                        <AsyncSelect
                          className={
                            validation.touched.organization &&
                            validation.errors.organization &&
                            "form-control is-invalid"
                          }
                          id="choices-lead-input"
                          onBlur={validation.handleBlur}
                          name="organization"
                          placeholder="Select organization"
                          isMulti={false}
                          isClearable
                          isSearchable
                          value={organization}
                          getOptionLabel={(option) => option?.account_name}
                          getOptionValue={(option) => option?.id}
                          onChange={(e) => {
                            setOrganization(e);
                            validation.setFieldValue("organization", e?.id);
                          }}
                          loadOptions={loadOrganizationOptions}
                          // cacheOptions
                          defaultOptions={organizationList}
                        />
                        {validation.touched.organization && validation.errors.organization ? (
                          <FormFeedback type="invalid">{validation.errors.organization}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="title-input">
                          Title
                        </Label>
                        <Input
                          type="text"
                          name="title"
                          className="form-control"
                          id="title-input"
                          value={validation.values.title}
                          onChange={validation.handleChange}
                          placeholder="Contact Title"
                          invalid={validation.touched.title && validation.errors.title ? true : false}
                        />
                        {validation.touched.title && validation.errors.title ? (
                          <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="assistant-input">
                          Assistant Name
                        </Label>
                        <Input
                          type="text"
                          value={validation.values.assistant}
                          onChange={validation.handleChange}
                          name="assistant"
                          className="form-control"
                          invalid={validation.touched.assistant && validation.errors.assistant ? true : false}
                          id="assistant-input"
                          placeholder="Enter Assistant name here.."
                        />
                        {validation.touched.assistant && validation.errors.assistant ? (
                          <FormFeedback type="invalid">{validation.errors.assistant}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <IntNumberInput
                      md="6"
                      mb="mb-3"
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      value={validation.values.assistant_phone}
                      label="Assistant Phone"
                      name="assistant_phone"
                      //errorMessage={client_errors}
                    />
                    {/*
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="assistant_phone-input">
                          Assistant Phone
                        </Label>
                        <Input
                          type="text"
                          value={validation.values.assistant_phone}
                          onChange={validation.handleChange}
                          name="assistant_phone"
                          className="form-control"
                          invalid={
                            validation.touched.assistant_phone && validation.errors.assistant_phone ? true : false
                          }
                          id="assistant_phone-input"
                          placeholder="Enter Assistant Phone here.."
                        />
                        {validation.touched.assistant_phone && validation.errors.assistant_phone ? (
                          <FormFeedback type="invalid">{validation.errors.assistant_phone}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  */}
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="first-name-input">
                          Department
                        </Label>
                        <Input
                          type="text"
                          name="department"
                          value={validation.values.department}
                          onChange={validation.handleChange}
                          className="form-control"
                          id="first-name-input"
                          invalid={validation.touched.department && validation.errors.department ? true : false}
                          placeholder="Enter Department Here..."
                        />
                        {validation.touched.department && validation.errors.department ? (
                          <FormFeedback type="invalid">{validation.errors.department}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="reports_to-input">
                          Reports To
                        </Label>
                        <AsyncSelect
                          className={
                            validation.touched.reports_to && validation.errors.reports_to && "form-control is-invalid"
                          }
                          id="reports_to-input"
                          onBlur={validation.handleBlur}
                          name="reports_to"
                          placeholder="Reports to ..."
                          isMulti={false}
                          isClearable
                          isSearchable
                          loadOptions={loadReportsToOptions}
                          value={reportsTo}
                          getOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
                          getOptionValue={(option) => option?.id}
                          onChange={(e) => {
                            setReportsTo(e);
                            validation.setFieldValue("reports_to", e?.id);
                          }}
                          // we cannot report to ourself basically
                          defaultOptions={contactList?.filter((c) => c?.id.toString() !== contact_pk)}
                        />
                        {validation.touched.reports_to && validation.errors.reports_to ? (
                          <FormFeedback type="invalid">{validation.errors.reports_to}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              {/* Address Information */}
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Address Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="address_line-input">
                          Address Line
                        </Label>
                        <Input
                          type="text"
                          name="address_line"
                          className="form-control"
                          id="address_line-input"
                          value={validation.values.address_line}
                          onChange={validation.handleChange}
                          placeholder="Address Line"
                          invalid={validation.touched.address_line && validation.errors.address_line ? true : false}
                        />
                        {validation.touched.address_line && validation.errors.address_line ? (
                          <FormFeedback type="invalid">{validation.errors.address_line}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="street-input">
                          Street
                        </Label>
                        <Input
                          type="text"
                          name="street"
                          className="form-control"
                          id="street-input"
                          value={validation.values.street}
                          onChange={validation.handleChange}
                          placeholder="Street"
                          invalid={validation.touched.street && validation.errors.street ? true : false}
                        />
                        {validation.touched.street && validation.errors.street ? (
                          <FormFeedback type="invalid">{validation.errors.street}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="city-input">
                          City
                        </Label>
                        <Input
                          type="text"
                          name="city"
                          className="form-control"
                          id="city-input"
                          value={validation.values.city}
                          onChange={validation.handleChange}
                          invalid={validation.touched.city && validation.errors.city ? true : false}
                          placeholder="City"
                        />
                        {validation.touched.city && validation.errors.city ? (
                          <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="state-input">
                          State
                        </Label>
                        <Input
                          type="text"
                          name="state"
                          className="form-control"
                          id="state-input"
                          invalid={validation.touched.state && validation.errors.state ? true : false}
                          value={validation.values.state}
                          onChange={validation.handleChange}
                          placeholder="State"
                        />
                        {validation.touched.state && validation.errors.state ? (
                          <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="postal_code-input">
                          Postal Code
                        </Label>
                        <Input
                          type="text"
                          name="postcode"
                          className="form-control"
                          id="postcode-input"
                          value={validation.values.postcode}
                          onChange={validation.handleChange}
                          placeholder="Postal Code"
                          invalid={validation.touched.postal_code && validation.errors.postal_code ? true : false}
                        />
                        {validation.touched.postal_code && validation.errors.postal_code ? (
                          <FormFeedback type="invalid">{validation.errors.postal_code}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Label htmlFor="contact_country-input" className="form-label">
                          Country
                        </Label>
                        <Select
                          // defaultValue={countries.find((c) => c.value === contact.country)}
                          // key={contact.country}
                          id="contact_country-input"
                          placeholder="Select A Country..."
                          value={country}
                          key={country}
                          name="country"
                          onChange={(e) => {
                            setCountry(e);
                            validation.setFieldValue("country", e.value);
                          }}
                          options={countries}
                        />
                        {validation.touched.country && validation.errors.country ? (
                          <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              {/* Other Information */}
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Other Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="gender-input">
                          Source
                        </Label>
                        <Select
                          name="status"
                          onChange={(e) => validation.setFieldValue("source", e.value)}
                          options={sourceOptions}
                          placeholder="select a source..."
                          id="source-input"
                          value={sourceOptions.find((g) => g.value === validation.values.source)}
                          invalid={validation.touched.source && validation.errors.source ? true : false}
                          classNamePrefix="select2-selection form-select"
                        />
                        {validation.touched.source && validation.errors.source ? (
                          <FormFeedback type="invalid">{validation.errors.source}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="gender-input">
                          Status
                        </Label>
                        <Select
                          name="status"
                          onChange={(e) => validation.setFieldValue("status", e.value)}
                          options={statusOptions}
                          placeholder="select a status..."
                          id="status-input"
                          value={statusOptions.find((g) => g.value === validation.values.status)}
                          invalid={validation.touched.status && validation.errors.status ? true : false}
                          classNamePrefix="select2-selection form-select"
                        />
                        {validation.touched.status && validation.errors.status ? (
                          <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="stage-input">
                          Stage
                        </Label>
                        <Select
                          name="stage"
                          onChange={(e) => validation.setFieldValue("stage", e?.value)}
                          options={contactStageOptions}
                          placeholder="select a stage..."
                          id="source-input"
                          value={contactStageOptions.find((g) => g.value === validation.values.stage)}
                          invalid={validation.touched.stage && validation.errors.stage ? true : false}
                          classNamePrefix="select2-selection form-select"
                        />
                        {validation.touched.stage && validation.errors.stage ? (
                          <FormFeedback type="invalid">{validation.errors.stage}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    {/* <Col>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="contact_owner-input">
                          Contact Owner
                        </Label>
                        <Input
                          type="text"
                          name="contact_owner"
                          className="form-control"
                          id="contact_owner-input"
                          placeholder="Contact Owner"
                          value={validation.values.contact_owner}
                          onChange={validation.handleChange}
                          invalid={validation.touched.contact_owner && validation.errors.contact_owner ? true : false}
                        />
                        {validation.touched.contact_owner && validation.errors.contact_owner ? (
                          <FormFeedback type="invalid">{validation.errors.contact_owner}</FormFeedback>
                        ) : null}
                      </div>
                    </Col> */}
                  </Row>
                  <div>
                    <Label>Other Description</Label>

                    <Input
                      type="textarea"
                      className="form-control"
                      placeholder="Contact Description"
                      name="description"
                      value={validation.values.description}
                      onChange={validation.handleChange}
                      invalid={validation.touched.description && validation.errors.description ? true : false}
                      rows="10"
                    />
                    {validation.touched.description && validation.errors.description ? (
                      <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Assign</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label htmlFor="assigned_to-input" className="form-label">
                      To a User(s)
                    </Label>
                    <AsyncSelect
                      id="assinged_to-input"
                      placeholder="Select a User..."
                      value={assignedUser}
                      onChange={(e) => {
                        setAssignedUser(e);
                        validation.setFieldValue("assigned_to", JSON.stringify(e?.map((e) => e?.id)));
                      }}
                      isMulti
                      isSearchable
                      isClearable
                      loadOptions={loadUsersOptions}
                      getOptionLabel={(option) =>
                        `${option?.user_details.first_name} ${option?.user_details.last_name}`
                      }
                      getOptionValue={(option) => option?.id}
                      defaultOptions={users}
                      name="assigned_to"
                      classNamePrefix="select2-selection form-select"
                    />
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Tags</h5>
                </CardHeader>
                <CardBody>
                  <div className="hstack gap-3 align-items-start">
                    <div className="flex-grow-1">
                      <input className="form-control" placeholder="Enter tags" type="text" />
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Attached files</h5>
                </CardHeader>
                <CardBody>
                  <div>
                    <p className="text-muted">Add Attached files here.</p>
                    <FilePond
                      files={files}
                      onupdatefiles={setFiles}
                      allowMultiple={true}
                      maxFiles={2}
                      name="case_attachment"
                      // value={validation.values.case_attachment}
                      className="filepond filepond-input-multiple"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={8}>
              <div className="text-end mb-3">
                <button type="reset" onClick={reset} className="btn btn-danger w-sm me-1">
                  Clear
                </button>
                <button type="submit" className="btn btn-success w-sm">
                  Submit
                </button>
              </div>
            </Col>
          </Row>
        </Form>

        {isContactAdd ? (
          <MsgToast msg="Contact Added Successfully" color="success" icon="ri-checkbox-circle-line" />
        ) : null}

        {isContactAddFail ? <MsgToast msg="Contact Added Failed" color="danger" icon="ri-error-warning-line" /> : null}

        {isContactUpdate ? (
          <MsgToast msg="Contact Updated Successfully" color="success" icon="ri-checkbox-circle-line" />
        ) : null}

        {isContactUpdateFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}

        <ToastContainer limit={1} closeButton={false} />
      </Container>
    </div>
  );
};

export default CreateContact;
