import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";

//Import actions
import {
  deleteMembership as onDeleteMembership,
  getMemberships as onGetMemberships,
  updateMembership,
  resetMembershipFlag,
} from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";

// Formik
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Components/Common/Loader";
import MsgToast from "../../../Components/Common/MsgToast";
import Widgets from "./Widgets";
import OffCanvasFilter from "./OffCanvasFilter";

const MembershipList = () => {
  const dispatch = useDispatch();
  const {
    membershipList,
    isMemberSuccess,
    isMembershipUpdate,
    isMembershipUpdateFail,
    isMembershipDelete,
    isMembershipDeleteFail,
    error,
    page,
    stats,
  } = useSelector((state) => ({
    isMembershipSuccess: state.Membership.isMembershipSuccess,
    membershipList: state.Membership.memberships,
    error: state.Membership.error,
    page: state.Membership.page,
    stats: state.Membership.stats,
    crmusers: state.Profile.usersList,
    isMembershipUpdate: state.Membership.isMembershipUpdate,
    isMembershipUpdateFail: state.Membership.isMembershipUpdateFail,
    isMembershipDelete: state.Membership.isMembershipDelete,
    isMembershipDeleteFail: state.Membership.isMembershipDeleteFail,
  }));

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetMembershipFlag());
    }, 3000);
  }, [
    dispatch,
    error,
    isMemberSuccess,
    isMembershipUpdate,
    isMembershipUpdateFail,
    isMembershipDelete,
    isMembershipDeleteFail,
  ]);

  //delete member
  const [membership, setMembership] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [sortBy, setsortBy] = useState(null);
  const [toggleFilterCanvas, setToggleFilterCanvas] = useState(false);

  const toggleInfo = () => setToggleFilterCanvas(!toggleFilterCanvas);

  useEffect(() => {
    if (!membershipList) {
      dispatch(onGetMemberships());
    }
  }, [dispatch, membershipList]);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(onGetMemberships({ offset, limit }));
    },
    [dispatch]
  );

  const handleApproval = useCallback(
    (membership, approval) => {
      dispatch(
        updateMembership({
          ...membership,
          member: membership?.member?.id,
          membership_type: membership?.membership_type?.id,
          created_by: membership?.created_by?.id,
          org: membership?.org?.id,
          sub_members: membership?.sub_members?.map((s) => s?.id),
          approved: approval,
        })
      );
    },
    [dispatch]
  );

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return (
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="checkAll" value="option1" />
            </div>
          );
        },
      },
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
      },
      {
        Header: "Member",
        filterable: false,
        Cell: (member) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {member.row.original?.member.profile_pic ? (
                  <img
                    src={member.row.original?.member.profile_pic}
                    alt="profile_pic"
                    className="avatar-xxs rounded-circle"
                  />
                ) : (
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                      {member.row.original?.member.first_name.charAt(0)}
                      {member.row.original?.member.last_name.charAt(0)}
                    </div>
                  </div>
                )}
              </div>
              <div className="flex-grow-1 ms-2 name">
                {member.row.original?.member.first_name} {member.row.original?.member.last_name}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Membership Type",
        accessor: "membership_type",
        Cell: (cellProps) => {
          const membership_type = cellProps.row.original?.membership_type;
          return <React.Fragment>{membership_type?.name}</React.Fragment>;
        },
      },
      {
        Header: "Approval",
        accessor: "approved",
        filterable: true,
        Cell: (cellProps) => {
          const approved = cellProps.row.original?.approved;
          return (
            <React.Fragment>
              {approved === true ? (
                <span className="badge badge-soft-success text-uppercase">Approved</span>
              ) : approved === false ? (
                <span className="badge badge-soft-danger text-uppercase">Declined</span>
              ) : null}
            </React.Fragment>
          );
        },
      },
      {
        Header: "Joined",
        accessor: "created_on_arrow",
      },
      {
        Header: "Joined Date",
        accessor: "joined_date",
        filterable: false,
      },
      {
        Header: "Expiry Date",
        accessor: "expiry_date",
        filterable: false,
      },
      { Header: "Days Left", accessor: "days_left", sortable: true },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: (cellProps) => {
          const status = cellProps.row.original?.status;
          return (
            <React.Fragment>
              {status === "active" ? (
                <span className="badge badge-soft-success text-uppercase">{status}</span>
              ) : status === "in-active" ? (
                <span className="badge badge-soft-danger text-uppercase">{status}</span>
              ) : null}
            </React.Fragment>
          );
        },
      },
      {
        Header: "Actions",
        Cell: (cellProps) => {
          const membership = cellProps.row.original;
          return (
            <ul className="list-inline hstack gap-3 mb-0">
              <li className="list-inline-item edit me-0" title="Make Payment and Approve">
                <Link
                  to="#"
                  onClick={() => handleApproval(membership, true)}
                  type="button"
                  className="d-inline-block border-2 btn btn-outline-success py-1"
                >
                  <i className="ri-plus-fill align-middle text-muted" /> Approve
                </Link>
              </li>
              <li className="list-inline-item edit me-0" title="Make Payment and Approve">
                <Link
                  //to={`/memberships/${cellProps.row.original.id}/checkout`}
                  to="#"
                  onClick={() => handleApproval(membership, false)}
                  type="button"
                  className="d-inline-block border-2 btn btn-outline-danger py-1"
                >
                  <i className="ri-plus-fill align-middle text-muted" /> Decline
                </Link>
              </li>

              <li className="list-inline-item edit">
                <UncontrolledDropdown className="z-3">
                  <DropdownToggle href="#" className="btn-soft-secondary btn-sm dropdown" tag="button">
                    <i className="ri-more-fill fs-14 align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <Link to={`/memberships/${cellProps.row.original.id}/checkout`}>
                      <DropdownItem className="dropdown-item remove-item-btn text-muted">
                        <i className="ri-add-fill align-middle me-2 fs-16 text-muted" /> Accept Payment
                      </DropdownItem>
                    </Link>

                    {/*<Link
                      to={{
                        pathname: `/memberships-create/${cellProps.row.original.id}/renew`,
                        state: { enrollment_type: "renewal" },
                      }}
                    >
                      <DropdownItem className="dropdown-item remove-item-btn text-muted">
                        <i className="ri-recycle-line align-middle me-2 fs-16 text-muted" /> Renew Membership
                      </DropdownItem>
                    </Link>
                    */}

                    <Link to={`/memberships-update/${cellProps.row.original.id}`}>
                      <DropdownItem className="dropdown-item remove-item-btn text-muted">
                        <i className="ri-pencil-fill align-middle me-2 fs-16 text-muted"></i> Edit Membership
                      </DropdownItem>
                    </Link>

                    <DropdownItem
                      className="dropdown-item remove-item-btn text-muted"
                      onClick={() => {
                        const memberData = cellProps.row.original;
                        onClickDelete(memberData);
                      }}
                    >
                      <i className="ri-delete-bin-fill align-middle me-2 fs-16 text-muted"></i> Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleApproval]
  );

  const handlesortBy = (sortBy) => {
    setsortBy(sortBy);
  };

  const sortbyname = [
    {
      options: [
        { label: "Owner", value: "Owner" },
        { label: "Company", value: "Company" },
        { label: "Location", value: "Location" },
      ],
    },
  ];

  // Delete Data
  const handleDeleteMembership = () => {
    if (membership) {
      dispatch(onDeleteMembership(membership));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (membership) => {
    setMembership(membership);
    setDeleteModal(true);
  };

  const handler = useCallback(
    debounce((value) => {
      dispatch(onGetMemberships({ search: value }));
    }, 1500),
    []
  );

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    handler(value);
  };

  document.title = "Memberships - Membership Management | SimplyNP";
  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteMembership}
          onCloseClick={() => setDeleteModal(false)}
        />
        <Container fluid>
          <BreadCrumb title="List Memberships" pageTitle="Membership" />
          <Row>
            <Widgets stats={stats} />
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-1">
                      <Link to="/memberships-create">
                        <button className="btn btn-info add-btn">
                          <i className="ri-add-fill me-1 align-bottom"></i> Add Membership
                        </button>
                      </Link>
                    </div>
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                        <button
                          className="btn btn-soft-danger"
                          // onClick="deleteMultiple()"
                        >
                          <i className="ri-delete-bin-2-line"></i>
                        </button>
                        <button type="button" className="btn btn-info" onClick={toggleInfo}>
                          <i className="ri-filter-3-line align-bottom me-1"></i> Filters
                        </button>
                        <button className="btn btn-soft-success">Import</button>

                        <UncontrolledDropdown>
                          <DropdownToggle href="#" className="btn btn-soft-info" tag="button">
                            <i className="ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" href="#">
                              All
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Week
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Month
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Year
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col>
              <Card id="memberList">
                <CardHeader>
                  <Row className="g-3">
                    <Col md={4}>
                      <div className="search-box">
                        <Input
                          onChange={(e) => {
                            handleFieldSearch(e);
                          }}
                          type="text"
                          className="form-control search"
                          placeholder="Search for member..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    <div className="col-md-auto ms-auto">
                      <div className="d-flex align-items-center gap-2">
                        <span className="text-muted">Sort by: </span>
                        <Select
                          className="mb-0"
                          value={sortBy}
                          onChange={() => {
                            handlesortBy();
                          }}
                          options={sortbyname}
                          id="choices-single-default"
                        ></Select>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    {membershipList ? (
                      <TableContainer
                        columns={columns}
                        data={membershipList || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        customFetchData={fetchData}
                        customTotalSize={page.count}
                        customPageSize={10}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        // handleMemberClick={handleMemberClicks}
                      />
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>

                  {isMembershipUpdate ? (
                    <MsgToast msg="Membership updated successfully" color="success" icon="ri-checkbox-circle-line" />
                  ) : null}

                  {isMembershipUpdateFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}

                  {isMembershipDelete ? (
                    <MsgToast msg="Membership deleted successfully" color="success" icon="ri-checkbox-circle-line" />
                  ) : null}

                  {isMembershipDeleteFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}

                  <ToastContainer limit={1} closeButton={false} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <OffCanvasFilter show={toggleFilterCanvas} onCloseClick={() => setToggleFilterCanvas(false)} />
    </React.Fragment>
  );
};

export default MembershipList;
