import React from "react";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";

const UpcomingSchedules = ({ events }) => {
  return (
    <React.Fragment>
      <div className="col-xxl-4">
        <div className="card">
          <div className="card-header border-0">
            <h4 className="card-title mb-0">Upcoming Schedules</h4>
          </div>
          <div className="card-body pt-0">
            <div className="upcoming-scheduled">
              <Flatpickr
                className="form-control"
                options={{
                  dateFormat: "d M, Y",
                  inline: true,
                }}
              />
            </div>

            <h6 className="text-uppercase fw-semibold mt-4 mb-3 text-muted">Events:</h6>
            {events?.length === 0 ? (
              <div>No events currently</div>
            ) : (
              events?.map((event) => (
                <div key={event.id} className="mini-stats-wid d-flex align-items-center mt-3">
                  <div className="flex-shrink-0 avatar-sm">
                    <span className="mini-stat-icon avatar-title rounded-circle text-success bg-soft-success fs-4">
                      {event.date_of_meeting.slice(8, 10)}
                    </span>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h6 className="mb-1">{event.name}</h6>
                    <p className="text-muted mb-0">{event.description}</p>
                  </div>
                  <div className="flex-shrink-0">
                    <p className="text-muted mb-0">
                      {event.start_time.slice(0, 5)}{" "}
                      <span className="text-uppercase">
                        {parseInt(event.start_time.slice(0, 2)) >= 12 ? "pm" : "am"}
                      </span>
                    </p>
                  </div>
                </div>
              ))
            )}

            <div className="mt-3 text-center">
              <Link to="/calendar" className="text-muted text-decoration-underline">
                View all Events
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UpcomingSchedules;
