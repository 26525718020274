import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import MsgToast from "../../../../Components/Common/MsgToast";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";

import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";

import {
  getDonationsList,
  getOrganizations,
  getContacts as onGetContacts,
  getEvents as onGetEvents,
  resetCrmFlag,
} from "../../../../store/actions";

import AsyncSelect from "react-select/async";
import TextField from "../../../../Components/Common/Fields/TextField.js";

import { getContacts, getEvents } from "../../../../helpers/backend_helper";
import { createDonation, resetDonationFlag, updateDonation } from "../../../../store/donations/action";

const CreateDonationForm = (props) => {
  const dispatch = useDispatch();
  const [donation, setDonation] = useState(null);
  const { id } = useParams();

  const anonymityOptions = [
    { label: "Yes ", value: "yes", name: "yes" },
    { label: "No", value: "no", name: "no" },
  ];

  const statusOptions = [
    { label: "Pledged ", value: "pledged", name: "pledged" },
    { label: "Donation-InProgress", value: "donation-inprogress", name: "donation-inprogress" },
    { label: "Donation-Completed", value: "donation-completed", name: "donation-completed" },
  ];

  let {
    events,
    orgList,
    donations,
    data,
    updateSuccess,
    updateFail,
    created,
    error,
    contactList,
    activeDonor,
    organizations,
  } = useSelector((state) => ({
    data: state.Donations.data,
    donations: state.Donations.donations,
    orgList: state.Crm.organizations,
    updateSuccess: state.Donations.updateSuccess,
    updateFail: state.Donations.updateFail,
    created: state.Donations.created,
    contactList: state.Crm.crmcontacts,
    organizations: state.Crm.organizations,
    activeDonor: state.Crm.activeDonorCount,
    events: state.Calendar.events,

    error: state.Crm.error,
  }));

  const [assignedContact, setAssignedContact] = useState(null);
  const [assignedEvent, setAssignedEvent] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      if (updateSuccess || created) {
        dispatch(resetDonationFlag());
      }
      dispatch(resetCrmFlag());
    }, 3000);
  }, [dispatch, updateSuccess, created]);

  useEffect(() => {
    if (!donations) {
      dispatch(getDonationsList({ id }));
      return;
    }

    if (id) {
      if (donations) {
        if (donation) return;
        let d = donations.find((donation) => donation.id.toString() === id);
        if (!d) return;
        setDonation(d);
        setAssignedContact(d?.donor);
        setAssignedEvent(d?.event);
      }
    }

    if (!orgList) {
      dispatch(getOrganizations());
    }

    if (!donations) {
      dispatch(getDonationsList());
    }

    if (!contactList) {
      dispatch(onGetContacts());
    }

    if (!events) {
      dispatch(onGetEvents());
    }
  }, [dispatch, id, props.updateDonation, data, organizations, donations, orgList, contactList, events, donation]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      ...(props.updateDonation && { id: (donation && donation.id) || "" }),
      cause: (donation && donation.cause) || "",
      amount: (donation && donation.amount) || "",
      acknowledge_name: (donation && donation.acknowledge_name) || "",
      donor: (donation && donation.donor && donation?.donor?.id) || "",
      // description: (donation && donation.description) || "donation for charity",
      donor_alt_name: (donation && donation.donor_alt_name && donation.donor_alt_name) || "",
      honor_name: (donation && donation.honor_name) || "",
      campaign: (donation && donation.campaign) || "",
      // cause: (donation && donation.cause) || "",
      fund: (donation && donation.fund) || "",
      // fund: (donation && donation.fund) || "",
      status: (donation && donation.status) || "",
      anonymous: (donation && donation.anonymous) || "",
    },

    validationSchema: Yup.object({
      cause: Yup.string().notRequired("Please Enter Your cause"),
      amount: Yup.string().required("Please Enter the amount"),
      acknowledge_name: Yup.string().notRequired("Please Enter acknowlegment name here.."),
      donor: Yup.string().required("Please assign the donor"),
      status: Yup.string().notRequired("Please assign the status"),
      anonymous: Yup.string().notRequired("Please Choose anonymity option"),
      donor_alt_name: Yup.string().notRequired("Please Enter the Donor alt name ..."),
      fund: Yup.string().notRequired("Please Enter the fund"),
      event: Yup.mixed().notRequired("Please Enter Your campaign message here.. "),
      campaign: Yup.string().notRequired("Please Enter campaign"),
      honor_name: Yup.string().notRequired("Please Enter honor name"),
    }),

    onSubmit: (values) => {
      if (props.updateDonation || id) {
        dispatch(updateDonation(values));
      } else {
        dispatch(createDonation(values));
      }

      // reset();
    },
  });

  // const reset = () => {
  //   setDonation({});
  //    setAssignedContactsState(null);
  //    validation.resetForm();
  // };

  // const loadEventsOptions = debounce(async (inputValue) => {
  //   try {
  //     let res = await getEvents({ search: inputValue });
  //     console.log(res, "responsfsd");
  //     return res.events;
  //   } catch (err) {
  //     console.log(err, "ESEEE");
  //   }
  // }, 1500);

  // console.log(loadEventsOptions());
  // const loadEventsOptions = async (inputValue) => {
  //   console.log(inputValue, "Indisod");
  //   let res = await handler(inputValue);
  //   console.log(res, "NEWRESS");
  // };

  const loadEventsOptions = async (inputValue) => {
    return new Promise((resolve) => {
      getEvents({ search: inputValue }).then((res) => {
        resolve(res.events);
      });
    });
  };

  const loadContactsOptions = (inputValue) => {
    return new Promise((resolve) => {
      const orgList = getContacts({ search: inputValue }).then(({ contact_obj_list }) => contact_obj_list);
      resolve(orgList);
    });
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={`${props.updateDonation ? "Update" : "Create"} Donation`} pageTitle="Donations " />

        <Row>
          <Col lg={8}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();

                return false;
              }}
              // onReset={(e) => {
              //   e.preventDefault();
              //   reset();
              // }}
            >
              <Card>
                <CardBody>
                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.cause || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter donation cause"
                      label="Cause"
                      name="cause"
                    />
                    {/*<Col>
                      <div md="6" className="mb-3">
                        <Label htmlFor="assigned_to-input" className="form-label">
                          Campaign
                        </Label>
                        <AsyncSelect
                          id="event-input"
                          placeholder="Select an event"
                          value={assignedEvent}
                          onChange={(e) => {
                            setAssignedEvent(e);
                            validation.setFieldValue("event", e?.id);
                          }}
                          isMulti={false}
                          isSearchable
                          isClearable
                          loadOptions={(inputValue) => loadEventsOptions(inputValue)}
                          getOptionLabel={(option) => `${option?.name}`}
                          getOptionValue={(option) => option?.id}
                          defaultOptions={events}
                          name="event"
                          classNamePrefix="select2-selection form-select"
                        />
                      </div>
                    </Col>
      */}

                    <TextField
                      md="6"
                      mb={"mb-3"}
                      value={validation.values.campaign || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter campaign"
                      label="Campaign"
                      name="campaign"
                    />
                  </Row>

                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.amount || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter amount"
                      label="Amount"
                      name="amount"
                    />

                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.fund || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter fund"
                      label="Fund"
                      name="fund"
                    />
                  </Row>

                  <Row>
                    <div className="mb-3">
                      <Label>Acknowledge</Label>

                      <Input
                        type="textarea"
                        className="form-control"
                        placeholder="Enter acknowlegment"
                        name="acknowledge_name"
                        value={validation.values.acknowledge_name || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.acknowledge_name && validation.errors.acknowledge_name ? true : false
                        }
                        rows="10"
                      />
                      {validation.touched.acknowledge_name && validation.errors.acknowledge_name ? (
                        <FormFeedback type="invalid">{validation.errors.acknowledge_name}</FormFeedback>
                      ) : null}
                    </div>

                    {/* <TextField
                      md="6"
                      mb={"mb-3"}
                      value={validation.values.acknowledge_name || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter acknowlegment name"
                      label="Acknowledge"
                      name="acknowledge_name"
                    /> */}

                    <TextField
                      md="6"
                      mb={"mb-3"}
                      value={validation.values.honor_name || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter honor name"
                      label="Honor Name"
                      name="honor_name"
                    />
                  </Row>

                  <Row>
                    <Col md="6">
                      <div className="mb-3">
                        <Label htmlFor="choices-lead-input" className="form-label">
                          Donor
                        </Label>
                        <AsyncSelect
                          className={`${
                            validation.touched.donor && validation.errors.donor ? "form-control is-invalid" : ""
                          } `}
                          id="choices-lead-input"
                          value={assignedContact}
                          key={assignedContact}
                          name="donor"
                          isMulti={false}
                          isSearchable
                          isClearable
                          loadOptions={loadContactsOptions}
                          getOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
                          getOptionValue={(option) => option?.id}
                          defaultOptions={contactList}
                          onBlur={validation.handleBlur}
                          onChange={(e) => {
                            setAssignedContact(e);
                            validation.setFieldValue("donor", e?.id);
                          }}
                        />
                        {validation.touched.donor && validation.errors.donor ? (
                          <FormFeedback type="invalid">{validation.errors.donor}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <TextField
                      md="6"
                      mb={"mb-3"}
                      value={validation.values.donor_alt_name || ""}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter donor alt name"
                      label="Donor Alternate Name"
                      name="donor_alt_name"
                    />
                  </Row>

                  <Row>
                    <Col md="6">
                      <div className="mb-3">
                        <Label htmlFor="choices-lead-input" className="form-label">
                          Status
                        </Label>

                        <Select
                          name="status"
                          onChange={(e) => validation.setFieldValue("status", e.value)}
                          options={statusOptions}
                          placeholder="Select status..."
                          id="status-input"
                          value={statusOptions.find((g) => g.value === validation.values.status)}
                          invalid={validation.touched.status && validation.errors.status ? true : false}
                          classNamePrefix="select2-selection form-select"
                        />
                        {validation.touched.status && validation.errors.status ? (
                          <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label htmlFor="choices-lead-input" className="form-label">
                          Anonymity
                        </Label>

                        <Select
                          name="anonymous"
                          onChange={(e) => validation.setFieldValue("anonymous", e.value)}
                          options={anonymityOptions}
                          placeholder="Select anonymity..."
                          id="anonymous-input"
                          value={anonymityOptions.find((g) => g.value === validation.values.anonymous)}
                          invalid={validation.touched.anonymous && validation.errors.anonymous ? true : false}
                          classNamePrefix="select2-selection form-select"
                        />
                        {validation.touched.anonymous && validation.errors.anonymous ? (
                          <FormFeedback type="invalid">{validation.errors.anonymous}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <div className="text-end mb-3">
                <button type="reset" className="btn btn-danger w-sm me-1">
                  Clear
                </button>
                <button type="submit" className="btn btn-success w-sm">
                  {`${props.updateDonation ? "update" : "Create"}`}
                </button>
              </div>
            </Form>
          </Col>
        </Row>

        {created ? (
          <MsgToast msg="donation created successfully" color="success" icon="ri-checkbox-circle-line" />
        ) : null}

        {updateFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}

        {updateSuccess ? (
          <MsgToast msg="donation Updated Successfully" color="success" icon="ri-checkbox-circle-line" />
        ) : null}
      </Container>
    </div>
  );
};

export default CreateDonationForm;
