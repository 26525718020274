import { w3cwebsocket as W3CWebSocket } from "websocket";
import { api } from "../config";

let globalSocket = (selfRoom, token, orgId) => {
  if (selfRoom) {
    return new W3CWebSocket(api.WS + "/" + "chat/" + selfRoom + `/?token=${token}&orgid=${orgId}`);
  }
};

export { globalSocket };
