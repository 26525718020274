import React from "react";
import ReactApexChart from "react-apexcharts";

const DonutChart = ({ data, chartDonutBasicColors }) => {
  var options = {
    labels: data?.labels || [],
    chart: {
      type: "donut",
    },
    legend: {
      position: "bottom",
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },
    colors: chartDonutBasicColors,
  };
  return (
    <React.Fragment>
      <ReactApexChart options={options} series={data?.series || []} type="donut" height="300" className="apex-charts" />
    </React.Fragment>
  );
};
export { DonutChart };
