import React, { useEffect, useState } from "react";
import { Offcanvas, OffcanvasHeader, OffcanvasBody, Label, Form, Input, Row, Col } from "reactstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import actions
import {
  getClientsList as onGetClients,
  deleteClient as onDeleteClient,
  resetClientFlag,
  getUsersList as onGetUsers,
  getCasesList,
} from "../../../store/actions";
//redux
import * as Yup from "yup";
import DateField from "../../../Components/Common/Fields/DateField";
import countries from "../../../common/data/countries.json";
import TextField from "../../../Components/Common/Fields/TextField.js";
import SelectField from "../../../Components/Common/Fields/SelectField.js";
import AsyncSelect from "react-select/async";
import { getTeams, getUsers, getClients } from "../../../helpers/backend_helper";

const CrmFilter = ({ show, onCloseClick }) => {
  const dispatch = useDispatch();

  const [selectCountry, setselectCountry] = useState(null);
  const [client, setClient] = useState(null);

  const handleselectCountry = (selectCountry) => {
    setselectCountry(selectCountry);
  };

  const { clientsList, teamsList, casesList, users, isCaseUpdate, isCaseUpdateFail } = useSelector((state) => ({
    casesList: state.Cases.casesList,
    crmusers: state.Users.usersList,
    error: state.Cases.error,
    isCaseAdd: state.Cases.isCaseAdd,
    isCaseAddFail: state.Cases.isCaseAddFail,
    isCaseUpdate: state.Cases.isCaseUpdate,
    isCaseUpdateFail: state.Cases.isCaseUpdateFail,
    users: state.Users.usersList,
    teamsList: state.Teams.teamsList,
    clientsList: state.Clients.clientsList,
  }));

  useEffect(() => {
    dispatch(onGetClients());
  }, [dispatch]);

  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {},

    validationSchema: Yup.object({
      salutation: Yup.string().notRequired("Please Enter A Salutation"),
      first_name: Yup.string().notRequired("Please Enter A First Name"),
      last_name: Yup.string().notRequired("Please Enter A Last Name"),
      date_of_birth: Yup.date().notRequired("Please enter date of birth"),
      gender: Yup.string().notRequired(),
      language: Yup.string().notRequired("Please Enter A Language"),
    }),

    onSubmit: (client) => {
      dispatch(getCasesList(client));
    },
  });

  const loadClientsOptions = (inputValue) => {
    return new Promise((resolve) => {
      const usersList = getClients({ search: inputValue }).then(({ users }) => {
        const c = users.users;
        return c;
      });
      resolve(usersList);
    });
  };
  const NoOptionsMessage = () => {
    return <div>type to search...</div>;
  };

  const statusOptions = [
    { label: "Open", value: "Open" },
    { label: "Pending", value: "Pending" },
    { label: "Closed", value: "Closed" },
  ];

  return (
    <Offcanvas direction="end" isOpen={show} id="offcanvasExample" toggle={onCloseClick}>
      <OffcanvasHeader className="bg-light" toggle={onCloseClick}>
        Cases Fliters
      </OffcanvasHeader>
      <PerfectScrollbar>
        <Form
          className="d-flex flex-column justify-content-end h-100"
          onSubmit={(e) => {
            e.preventDefault();
            form.handleSubmit();
            return false;
          }}
          onReset={() => {
            form.handleReset();
            form.handleSubmit();
          }}
        >
          <OffcanvasBody>
            <div className="mb-4">
              <DateField
                mb="mb-3"
                md="12"
                value={form.values.create_date || ""}
                onChange={form.handleChange}
                validation={form}
                onBlur={form.handleBlur}
                label="create birth"
                name="create_date"
              />
            </div>
            <div className="mb-4">
              <DateField
                mb="mb-3"
                md="12"
                value={form.values.closed_date || ""}
                onChange={form.handleChange}
                validation={form}
                onBlur={form.handleBlur}
                label="closed date"
                name="closed_date"
              />
            </div>

            <TextField
              md="12"
              mb="mb-3"
              value={form.values.title || ""}
              onChange={form.handleChange}
              validation={form}
              onBlur={form.handleBlur}
              placeholder="Enter title"
              label="Title"
              name="title"
            />
            <SelectField
              md="12"
              mb="mb-3"
              onChange={form.handleChange}
              validation={form}
              onBlur={form.handleBlur}
              placeholder="Select a priority..."
              label="Priority"
              name="priority"
              optional={true}
              options={[
                { label: "High", value: "High" },
                { label: "Medium", value: "Medium" },
                { label: "Low", value: "Low" },
              ]}
            />

            <SelectField
              md="12"
              mb="mb-3"
              onChange={form.handleChange}
              validation={form}
              onBlur={form.handleBlur}
              placeholder="Select a status..."
              label="Status"
              name="status"
              optional={true}
              options={statusOptions}
            />

            <Col md={12}>
              <div className="mb-3">
                <AsyncSelect
                  cacheOptions
                  id="assigned_to-input"
                  onBlur={form.handleBlur}
                  name="client"
                  placeholder="clients..."
                  isMulti={false}
                  isClearable
                  isSearchable
                  loadOptions={loadClientsOptions}
                  value={client}
                  noOptionsMessage={NoOptionsMessage}
                  defaultOptions={clientsList}
                  getOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
                  getOptionValue={(option) => option?.id}
                  onChange={(e) => {
                    setClient(e);

                    form.setFieldValue("client", e?.id || "");
                  }}
                />
              </div>
            </Col>
          </OffcanvasBody>
          <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
            <button type="reset" className="btn btn-light w-100" onClick={onCloseClick}>
              Clear Filter
            </button>
            <button type="submit" className="btn btn-success w-100" onClick={onCloseClick}>
              Filters
            </button>
          </div>
        </Form>
      </PerfectScrollbar>
    </Offcanvas>
  );
};

export default CrmFilter;
