import React from "react";
import AddCaseForm from "../FormComponent/CreateCase";

const CreateCase = () => {
  document.title = "Create Lead | Velzon - React Admin & Dashboard Template";

  return (
    // <div className="page-content">
    <AddCaseForm title="Create Case" />
    // </div>
  );
};

export default CreateCase;
