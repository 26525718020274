import React from "react";
import UpdateClientForm from "../FormComponent/index";

const CreateCase = () => {
  document.title = "Create Client | Velzon - React Admin & Dashboard Template";

  return <UpdateClientForm title="Update CLient" updateClient />;
};

export default CreateCase;
