import React from "react";

const Status = (cell) => {
  return (
    <React.Fragment>
      {cell.value === true ? (
        <span className="badge badge-soft-success text-uppercase">{cell.value?.toString()}</span>
      ) : cell.value === false ? (
        <span className="badge badge-soft-danger text-uppercase">{cell.value?.toString()}</span>
      ) : null}
    </React.Fragment>
  );
};

export { Status };
