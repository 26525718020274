import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import countries from "../../../common/data/countries.json";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
import Select from "react-select";
// Import FilePond styles
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";

import AsyncSelect from "react-select/async";
import { ToastContainer } from "react-toastify";
import MsgToast from "../../../Components/Common/MsgToast";

import { useParams } from "react-router-dom";
import DateField from "../../../Components/Common/Fields/DateField";
import IntNumberInput from "../../../Components/Common/Fields/IntNumberInput";
import TextField from "../../../Components/Common/Fields/TextField.js";
import { getClients, getServices, getUsers } from "../../../helpers/backend_helper";
import {
  getClientsList as onGetClients,
  addNewClient as onAddNewClient,
  getCasesList as onGetCases,
  getServicesList as onGetServices,
  getUsersList as onGetUsers,
  updateClient as onUpdateClient,
  resetClientFlag,
} from "../../../store/actions";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const CreateClient = (props) => {
  const dispatch = useDispatch();
  const { id: client_pk } = useParams();

  const {
    error,
    users,
    loading,
    isClientAdd,
    isClientAddFail,
    isClientCreated,
    clientsList,
    isClientUpdate,
    isClientUpdateFail,
    isClientSuccess,
    services,
    casesList,
  } = useSelector((state) => ({
    isClientCreated: state.Clients.isClientCreated,
    isClientSuccess: state.Clients.isClientSuccess,
    isClientUpdate: state.Clients.isClientUpdate,
    loading: state.Clients.loading,
    casesList: state.Cases.casesList,
    users: state.Users.usersList,
    services: state.Services.servicesList,
    error: state.Clients.error,
    isClientAdd: state.Clients.isClientAdd,
    isClientAddFail: state.Clients.isClientAddFail,
    clientsList: state.Clients.clientsList,
    isClientUpdateFail: state.Clients.isClientUpdateFail,
  }));

  const [client_errors, set_client_errors] = useState(null);
  const [address_errors, set_address_errors] = useState(null);

  const [files, setFiles] = useState([]);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [client, setClient] = useState(null);
  const [clientCountry, setClientCountry] = useState("");

  const [relatedTo, setRelatedTo] = useState(null);
  const [assignedServices, setAssignedServices] = useState(null);
  const [assignedUser, setAssignedUser] = useState([]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      ...(props.updateClient && { id: client && client.id }),

      salutation: client?.salutation || "",
      first_name: client?.first_name || "",
      last_name: client?.last_name || "",
      gender: client?.gender || "",
      date_of_birth: client?.date_of_birth || null,
      primary_email: client?.primary_email || "",
      secondary_email: client?.secondary_email || "",

      phone_work: client?.phone_work || "",
      primary_phone: client?.primary_phone || "",
      phone_mobile: client?.phone_mobile || "",
      phone_other: client?.phone_other || "",
      language: client?.language || "",
      // cases: client?.cases || "",
      cases: "",

      country: clientCountry || "",
      address_line: client?.address?.address_line || "",
      city: client?.address?.city || "",
      postcode: client?.address?.postcode || "",
      state: client?.address?.state || "",
      street: client?.address?.street || "",

      related_to: client?.reports_to?.id || "",

      description: client?.description || "",
      assigned_to: client?.assigned_to?.id || "",
      services: "",

      tags: client?.tags || "",
    },

    validationSchema: Yup.object({
      salutation: Yup.string().notRequired("Please Enter A Salutation"),
      first_name: Yup.string().notRequired("Please Enter A First Name"),
      last_name: Yup.string().notRequired("Please Enter A Last Name"),
      date_of_birth: Yup.date().nullable().notRequired("Please enter date of birth"),
      gender: Yup.string().notRequired(),
      language: Yup.string().notRequired("Please Enter A Language"),
      cases: Yup.mixed().notRequired(),
      primary_email: Yup.string().email("Enter a valid email").required("Please Enter An Email"),
      secondary_email: Yup.string().email("Enter a valid email").notRequired(),
      related_to: Yup.mixed().notRequired("Please Enter name of supervisor"),
      phone_work: Yup.string().notRequired("Please Enter Phone Number"),
      primary_phone: Yup.string().required("Please Enter Phone Number"),
      description: Yup.string().notRequired("Please Enter Description"),
      assigned_to: Yup.mixed().notRequired("Please Assign Client to a User"),
      address_line: Yup.string().notRequired("Please Enter An Address"),
      city: Yup.string().notRequired("Please Enter An city"),
      postcode: Yup.string().notRequired("Please Enter An postcode"),
      state: Yup.string().notRequired("Please Enter A state"),
      street: Yup.string().notRequired("Please Enter An Address"),
      country: Yup.string().notRequired("Please Enter An country"),
      tags: Yup.string().notRequired(),
      services: Yup.mixed().notRequired(),
    }),

    onSubmit: (client) => {
      set_address_errors(null);
      set_client_errors(null);

      if (props.updateClient) {
        // update Client
        dispatch(onUpdateClient(client));
      } else {
        // save new Client
        dispatch(onAddNewClient(client));
        // validation.resetForm();
      }
    },
  });

  useEffect(() => {
    if (isClientSuccess || isClientCreated || error || isClientAddFail || isClientUpdate) {
      if (error) {
        set_client_errors(error && error.errors && error.errors["client_errors"]);
        set_address_errors(error && error.errors && error.errors["address_errors"]);
      }

      setTimeout(() => {
        dispatch(resetClientFlag());
      }, 3000);
    }
  }, [dispatch, isClientSuccess, isClientUpdate, isClientCreated, error, isClientAddFail]);

  useEffect(() => {
    if (!clientsList) {
      dispatch(onGetClients({ id: client_pk }));
      return;
    }

    if (client_pk) {
      if (client) return; // this is to prevent multiple re-renders on page refresh

      const c = clientsList?.find((c) => c.id.toString() === client_pk);
      if (!c) return;

      setClient(c);
      setRelatedTo(c?.related_to);
      setAssignedUser(c?.assigned_to);
      setAssignedServices(c?.services);
      handleClientCountry(c?.country);
    }

    if (!casesList) {
      dispatch(onGetCases());
    }

    if (!users) {
      dispatch(onGetUsers());
    }

    if (!services) {
      dispatch(onGetServices());
    }
  }, [dispatch, clientsList, client_pk, users, services, client, casesList]);

  const handleClientCountry = (country) => {
    if (country) {
      let countryObj = countries.find((c) => c.label === country);
      let countryValue = countryObj.value;
      setClientCountry(countryValue);
    }
  };

  const loadUsersOptions = (inputValue) => {
    return new Promise((resolve) => {
      const orgList = getUsers({ search: inputValue }).then(({ users: { users } }) => users);
      resolve(orgList);
    });
  };

  const loadServicesOptions = (inputValue) => {
    return new Promise((resolve) => {
      const servList = getServices({ search: inputValue }).then(({ services }) => services);
      resolve(servList);
    });
  };

  const loadRelatedToOptions = (inputValue) => {
    return new Promise((resolve) => {
      const list = getClients({ search: inputValue }).then(({ client_obj_list }) => {
        return client_obj_list;
      });
      resolve(list);
    });
  };

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const genders = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Trans Male", value: "Trans Male" },
    { label: "Trans Female", value: "Trans Female" },
    { label: "Gender Nonconforming", value: "Gender Nonconforming" },
    { label: "Other", value: "Other" },
    { label: "Decline to Answer", value: "Decline to Answer" },
  ];

  document.title = "Create Client | SimplyNP";

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={props.title} pageTitle="Clients " />

        <Row>
          <Col lg={8}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}

              // onReset={(e) => {
              //   validation.handleReset();
              // }}
            >
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Personal Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.salutation}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter salutation"
                      label="Salutation"
                      name="salutation"
                      errorMessage={client_errors}
                    />

                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.first_name}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter first name"
                      label="First name"
                      name="first_name"
                      errorMessage={client_errors}
                    />
                  </Row>

                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.last_name}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter last name"
                      label="Last name"
                      name="last_name"
                      errorMessage={client_errors}
                    />

                    <Col>
                      <DateField
                        mb="mb-3"
                        md="12"
                        value={validation.values.date_of_birth || ""}
                        onChange={validation.handleChange}
                        validation={validation}
                        onBlur={validation.handleBlur}
                        label="Date of Birth Date"
                        name="date_of_birth"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="gender-input">
                          Gender
                        </Label>

                        <Select
                          className={`${
                            validation.touched.gender && validation.errors.gender ? "form-control is-invalid" : ""
                          } `}
                          name="gender"
                          onChange={(e) => validation.setFieldValue("gender", e.value)}
                          options={genders}
                          placeholder="Select a Gender..."
                          id="gender-input"
                          //noOptionsMessage="Select Gender"
                          //validate={{required: {value: true}}}
                          //value={{ value: validation.values.gender, label: validation.values.gender }}
                          value={genders?.find((m) => m.value === validation.values.gender)}
                          key={genders}
                          invalid={validation.touched.gender && validation.errors.gender ? true : false}
                          classNamePrefix="select2-selection form-select"
                        />

                        {validation.touched.gender && validation.errors.gender ? (
                          <FormFeedback type="invalid">{validation.errors.gender}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.language}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter language"
                      label="Language"
                      name="language"
                      errorMessage={client_errors}
                    />
                  </Row>

                  <div>
                    <Label>Client Description</Label>

                    <Input
                      type="textarea"
                      className="form-control"
                      placeholder="Client Description"
                      name="description"
                      value={validation.values.description}
                      onChange={validation.handleChange}
                      invalid={validation.touched.description && validation.errors.description ? true : false}
                      rows="10"
                    />
                    {validation.touched.description && validation.errors.description ? (
                      <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Contact Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.primary_email}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter primary email"
                      label="Primary Email ID"
                      name="primary_email"
                      errorMessage={client_errors}
                    />

                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.secondary_email}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter your secondary email"
                      label="Secondary Email"
                      name="secondary_email"
                      errorMessage={client_errors}
                      optional={true}
                    />
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="reports_to-input">
                          Related To
                        </Label>
                        <AsyncSelect
                          className={
                            validation.touched.related_to && validation.errors.related_to && "form-control is-invalid"
                          }
                          id="related_to-input"
                          onBlur={validation.handleBlur}
                          name="related_to"
                          placeholder="Related to ..."
                          isMulti={true}
                          isClearable
                          isSearchable
                          loadOptions={loadRelatedToOptions}
                          value={relatedTo}
                          // noOptionsMessage="Type to search for a contact..."
                          getOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
                          getOptionValue={(option) => option?.id}
                          onChange={(e) => {
                            setRelatedTo(e);
                            validation.setFieldValue("related_to", e?.id);
                          }}
                          // we cannot report to ourself basically
                          defaultOptions={clientsList?.filter((c) => c?.id.toString() !== client_pk)}
                        />
                        {validation.touched.related_to && validation.errors.related_to ? (
                          <FormFeedback type="invalid">{validation.errors.related_to}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <IntNumberInput
                      md="6"
                      mb="mb-3"
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      value={validation.values.primary_phone}
                      label="Primary phone"
                      name="primary_phone"
                      errorMessage={client_errors}
                    />

                    <IntNumberInput
                      md="6"
                      mb="mb-3"
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      value={validation.values.phone_work}
                      label="Phone work"
                      name="phone_work"
                      errorMessage={client_errors}
                    />
                  </Row>
                  <Row>
                    <IntNumberInput
                      md="6"
                      mb="mb-3"
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      value={validation.values.phone_mobile}
                      label="Phone mobile"
                      name="phone_mobile"
                      errorMessage={client_errors}
                    />

                    <IntNumberInput
                      md="6"
                      mb="mb-3"
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      value={validation.values.phone_other}
                      label="Phone other"
                      name="phone_other"
                      errorMessage={client_errors}
                    />
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Address Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.address_line}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter address line"
                      label="Address (street numer +  name)"
                      name="address_line"
                      errorMessage={address_errors}
                    />

                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.street}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Apartment, Suite, Unit, Building, Floor, etc."
                      label="Address line 2"
                      name="street"
                      errorMessage={address_errors}
                    />
                  </Row>

                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.city}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter city"
                      label="City"
                      name="city"
                      errorMessage={address_errors}
                    />

                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.state}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="State, Province, Region"
                      label="State / Province / Region"
                      name="state"
                      errorMessage={address_errors}
                    />
                  </Row>

                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.postcode}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="ZIP / Postal Code"
                      label="ZIP / Postal Code"
                      name="postcode"
                      errorMessage={address_errors}
                    />

                    {/*  */}
                    <Col>
                      <div className="mb-3 md-6">
                        <Label htmlFor="choices-lead-input" className="form-label">
                          Country
                        </Label>
                        <Select
                          className={`${
                            validation.touched.country && validation.errors.country ? "form-control is-invalid" : ""
                          } `}
                          id="choices-team-input"
                          defaultValue={countries.find((c) => c.value === clientCountry)}
                          key={clientCountry}
                          name="country"
                          onChange={(e) => validation.setFieldValue("country", e.value)}
                          options={countries}
                        />
                        {validation.touched.country && validation.errors.country ? (
                          <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    {/*  */}
                  </Row>
                </CardBody>
              </Card>

              <div className="text-end mb-3">
                <button type="reset" className="btn btn-danger w-sm me-1">
                  Clear
                </button>
                <button type="submit" className="btn btn-success w-sm">
                  {loading ? "Submitting" : "Submit"}
                </button>
              </div>
            </Form>

            {isClientCreated ? (
              <MsgToast msg="Client Added Successfully" color="success" icon="ri-checkbox-circle-line" />
            ) : null}

            {isClientAdd ? (
              <MsgToast msg="Client Added Successfully" color="success" icon="ri-checkbox-circle-line" />
            ) : null}

            {isClientUpdate ? (
              <MsgToast msg="Client updated Successfully" color="success" icon="ri-checkbox-circle-line" />
            ) : null}

            {isClientAddFail ? (
              <MsgToast msg="Client Added Failed" color="danger" icon="ri-error-warning-line" />
            ) : null}

            {isClientUpdateFail ? (
              <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
            ) : null}

            <ToastContainer limit={1} closeButton={false} />
          </Col>

          <Col lg={4}>
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Assign</h5>
              </CardHeader>
              <CardBody>
                <div className="mb-3">
                  <Label htmlFor="assigned_to-input" className="form-label">
                    To a User
                  </Label>
                  <AsyncSelect
                    id="assinged_to-input"
                    placeholder="Select a User..."
                    value={assignedUser}
                    onChange={(e) => {
                      setAssignedUser(e);
                      validation.setFieldValue("assigned_to", e?.id);
                    }}
                    isMulti={false}
                    isSearchable
                    isClearable
                    loadOptions={loadUsersOptions}
                    getOptionLabel={(option) => `${option?.user_details.first_name} ${option?.user_details.last_name}`}
                    getOptionValue={(option) => option?.id}
                    defaultOptions={users}
                    name="assigned_to"
                    classNamePrefix="select2-selection form-select"
                  />
                </div>

                <div className="mb-3">
                  <Label htmlFor="services-input" className="form-label">
                    Services
                  </Label>
                  <AsyncSelect
                    id="services-input"
                    placeholder="Select a Service..."
                    value={assignedServices}
                    onChange={(e) => {
                      setAssignedServices(e);
                      validation.setFieldValue("services", e?.id);
                    }}
                    isSearchable
                    isClearable
                    loadOptions={loadServicesOptions}
                    getOptionLabel={(option) => `${option?.name}`}
                    getOptionValue={(option) => option?.id}
                    defaultOptions={services}
                    name="services"
                    classNamePrefix="select2-selection form-select"
                  />
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Tags</h5>
              </CardHeader>
              <CardBody>
                <div className="hstack gap-3 align-items-start">
                  <div className="flex-grow-1">
                    <input className="form-control" placeholder="Enter tags" type="text" />
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Attached files</h5>
              </CardHeader>
              <CardBody>
                <div>
                  <p className="text-muted">Add Attached files here.</p>
                  <FilePond
                    files={files}
                    onupdatefiles={setFiles}
                    allowMultiple={true}
                    maxFiles={2}
                    name="case_attachment"
                    // value={validation.values.case_attachment}
                    className="filepond filepond-input-multiple"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreateClient;
