// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_MEMBERS = "GET_MEMBERS";
export const REMOVE_MEMBER_FROM_STORE = "REMOVE_MEMBER_FROM_STORE";

export const GET_MEMBER_BY_ID = "GET_MEMBER_BY_ID";
export const GET_MEMBERSHIPS = "GET_MEMBERSHIPS";
export const GET_MEMBERSHIP_TYPES = "GET_MEMBERSHIP_TYPES";
export const GET_MEMBERSHIP_BENEFITS = "GET_MEMBERSHIP_BENEFITS";
export const GET_MEMBERSHIP_LEVELS = "GET_MEMBERSHIP_LEVELS";

export const GET_MEMBERSHIP_SITE_CONFIG = "GET_MEMBERSHIP_SITE_CONFIG";
export const UPDATE_MEMBERSHIP_SITE_CONFIG = "UPDATE_MEMBERSHIP_SITE_CONFIG";
export const UPDATE_MEMBERSHIP_SITE_CONFIG_FAIL = "UPDATE_MEMBERSHIP_SITE_CONFIG_FAIL";
export const UPDATE_MEMBERSHIP_SITE_CONFIG_SUCCESS = "UPDATE_MEMBERSHIP_SITE_CONFIG_SUCCESS";

// Add MEMBERSHIPS
export const ADD_NEW_MEMBERSHIPS = "ADD_NEW_MEMBERSHIPS";
export const ADD_MEMBERSHIPS_SUCCESS = "ADD_MEMBERSHIPS_SUCCESS";
export const ADD_MEMBERSHIPS_FAIL = "ADD_MEMBERSHIPS_FAIL";

// Edit MEMBERSHIPS
export const UPDATE_MEMBERSHIPS = "UPDATE_MEMBERSHIPS";
export const UPDATE_MEMBERSHIPS_SUCCESS = "UPDATE_MEMBERSHIPS_SUCCESS";
export const UPDATE_MEMBERSHIPS_FAIL = "UPDATE_MEMBERSHIPS_FAIL";

// Renew MEMBERSHIPS
export const RENEW_MEMBERSHIPS = "RENEW_MEMBERSHIPS";
export const RENEW_MEMBERSHIPS_SUCCESS = "RENEW_MEMBERSHIPS_SUCCESS";
export const RENEW_MEMBERSHIPS_FAIL = "RENEW_MEMBERSHIPS_FAIL";

// Delete MEMBERSHIPS
export const DELETE_MEMBERSHIPS = "DELETE_MEMBERSHIPS";
export const DELETE_MEMBERSHIPS_SUCCESS = "DELETE_MEMBERSHIPS_SUCCESS";
export const DELETE_MEMBERSHIPS_FAIL = "DELETE_MEMBERSHIPS_FAIL";

// Add MEMBER
export const ADD_NEW_MEMBER = "ADD_NEW_MEMBER";
export const ADD_MEMBER_SUCCESS = "ADD_MEMBER_SUCCESS";
export const ADD_MEMBER_FAIL = "ADD_MEMBER_FAIL";

// Edit MEMBER
export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const UPDATE_MEMBER_SUCCESS = "UPDATE_MEMBER_SUCCESS";
export const UPDATE_MEMBER_FAIL = "UPDATE_MEMBER_FAIL";

// Delete MEMBER
export const DELETE_MEMBER = "DELETE_MEMBER";
export const DELETE_MEMBER_SUCCESS = "DELETE_MEMBER_SUCCESS";
export const DELETE_MEMBER_FAIL = "DELETE_MEMBER_FAIL";

// Add MEMBERSHIP_TYPE
export const ADD_NEW_MEMBERSHIP_TYPE = "ADD_NEW_MEMBERSHIP_TYPE";
export const ADD_MEMBERSHIP_TYPE_SUCCESS = "ADD_MEMBERSHIP_TYPE_SUCCESS";
export const ADD_MEMBERSHIP_TYPE_FAIL = "ADD_MEMBERSHIP_TYPE_FAIL";

// Edit MEMBERSHIP_TYPE
export const UPDATE_MEMBERSHIP_TYPE = "UPDATE_MEMBERSHIP_TYPE";
export const UPDATE_MEMBERSHIP_TYPE_SUCCESS = "UPDATE_MEMBERSHIP_TYPE_SUCCESS";
export const UPDATE_MEMBERSHIP_TYPE_FAIL = "UPDATE_MEMBERSHIP_TYPE_FAIL";

// Delete MEMBERSHIP_TYPE
export const DELETE_MEMBERSHIP_TYPE = "DELETE_MEMBERSHIP_TYPE";
export const DELETE_MEMBERSHIP_TYPE_SUCCESS = "DELETE_MEMBERSHIP_TYPE_SUCCESS";
export const DELETE_MEMBERSHIP_TYPE_FAIL = "DELETE_MEMBERSHIP_TYPE_FAIL";

// Add MEMBERSHIP_BENEFIT
export const ADD_NEW_MEMBERSHIP_BENEFIT = "ADD_NEW_MEMBERSHIP_BENEFIT";
export const ADD_MEMBERSHIP_BENEFIT_SUCCESS = "ADD_MEMBERSHIP_BENEFIT_SUCCESS";
export const ADD_MEMBERSHIP_BENEFIT_FAIL = "ADD_MEMBERSHIP_BENEFIT_FAIL";

// Edit MEMBERSHIP_BENEFIT
export const UPDATE_MEMBERSHIP_BENEFIT = "UPDATE_MEMBERSHIP_BENEFIT";
export const UPDATE_MEMBERSHIP_BENEFIT_SUCCESS = "UPDATE_MEMBERSHIP_BENEFIT_SUCCESS";
export const UPDATE_MEMBERSHIP_BENEFIT_FAIL = "UPDATE_MEMBERSHIP_BENEFIT_FAIL";

// Delete MEMBERSHIP_BENEFIT
export const DELETE_MEMBERSHIP_BENEFIT = "DELETE_MEMBERSHIP_BENEFIT";
export const DELETE_MEMBERSHIP_BENEFIT_SUCCESS = "DELETE_MEMBERSHIP_BENEFIT_SUCCESS";
export const DELETE_MEMBERSHIP_BENEFIT_FAIL = "DELETE_MEMBERSHIP_BENEFIT_FAIL";

// Add MEMBERSHIP_LEVEL
export const ADD_NEW_MEMBERSHIP_LEVEL = "ADD_NEW_MEMBERSHIP_LEVEL";
export const ADD_MEMBERSHIP_LEVEL_SUCCESS = "ADD_MEMBERSHIP_LEVEL_SUCCESS";
export const ADD_MEMBERSHIP_LEVEL_FAIL = "ADD_MEMBERSHIP_LEVEL_FAIL";

// Edit MEMBERSHIP_LEVEL
export const UPDATE_MEMBERSHIP_LEVEL = "UPDATE_MEMBERSHIP_LEVEL";
export const UPDATE_MEMBERSHIP_LEVEL_SUCCESS = "UPDATE_MEMBERSHIP_LEVEL_SUCCESS";
export const UPDATE_MEMBERSHIP_LEVEL_FAIL = "UPDATE_MEMBERSHIP_LEVEL_FAIL";

// Delete MEMBERSHIP_LEVEL
export const DELETE_MEMBERSHIP_LEVEL = "DELETE_MEMBERSHIP_LEVEL";
export const DELETE_MEMBERSHIP_LEVEL_SUCCESS = "DELETE_MEMBERSHIP_LEVEL_SUCCESS";
export const DELETE_MEMBERSHIP_LEVEL_FAIL = "DELETE_MEMBERSHIP_LEVEL_FAIL";

export const RESET_MEMBERSHIP_FLAG = "RESET_MEMBERSHIP_FLAG";
export const IS_LOADING = "IS_LOADING";
export const IS_LOADED = "IS_LOADED";
