import React, { useEffect, useMemo, useState, useCallback } from "react";

import { Input, Card, CardBody, Col, Container, Row, CardHeader } from "reactstrap";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import OffCanvasFilter from "./OffCanvasFilter";
import ReportTableContainer from "../../../Components/Common/ReportTableContainer";
import TableContainer from "../../../Components/Common/TableContainer";

//Import actions
import { getCasesStats } from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

// Formik

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Components/Common/Loader";
import MsgToast from "../../../Components/Common/MsgToast";
import { DonutChart } from "../DonutChart";
import { ColumnChart } from "../ColumnChart";

const CasestStats = () => {
  const dispatch = useDispatch();

  const [toggleFilterCanvas, setToggleFilterCanvas] = useState(false);

  const { services_count, casestStatsList, loading, error, page } = useSelector((state) => ({
    casestStatsList: state.Statistics.casesPriorityStats,
    services_count: state.Statistics.services_count,

    error: state.Membership.error,
    page: state.Membership.page,

    loading: state.Membership.loading,
  }));

  useEffect(() => {
    dispatch(getCasesStats());
  }, []);

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "priority",
        accessor: "priority",
        filterable: false,
      },
      {
        Header: "count",
        accessor: "count",
        filterable: false,
      },
    ],
    []
  );

  // Column
  const statusColumns = useMemo(
    () => [
      {
        Header: "status",
        accessor: "status",
        filterable: false,
      },
      {
        Header: "count",
        accessor: "count",
        filterable: false,
      },
    ],
    []
  );

  // Column
  const servicesColumns = useMemo(
    () => [
      {
        Header: "services name",
        accessor: "name",
        filterable: false,
      },
      {
        Header: "total cases",
        accessor: "total_cases",
        filterable: false,
      },
    ],
    []
  );

  const handler = useCallback(
    debounce((value) => {
      dispatch(getCasesStats({ search: value }));
    }, 500),
    []
  );

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    // setSearch(value);
    handler(value);
  };

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(getCasesStats({ offset, limit }));
    },
    [dispatch]
  );

  let getChartData = (data = []) => {
    if (!data.length) return;
    let labels = [];
    let series = [];

    data.forEach((obj) => {
      labels.push(Object.values(obj)[0]);
      series.push(Object.values(obj)[1]);
    });

    return { labels, series };
  };

  let statusChart = getChartData(casestStatsList?.case_status_stats);

  let priorityChart = getChartData(casestStatsList?.res);

  let servicesChart = getChartData(casestStatsList?.services_stats);

  document.title = "Cases stats - statistics | SimplyNP";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Cases stats" pageTitle="Cases stats" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardBody>
                    <Row>
                      <Col xs={6}>
                        <div className="text-center py-0">
                          <h5>Cases status Chart</h5>

                          <DonutChart data={statusChart} chartDonutBasicColors={["#f7b84b", "#f06548", "#299cdb"]} />
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="text-center py-0">
                          <h5>Cases Priority Chart</h5>

                          <DonutChart data={priorityChart} chartDonutBasicColors={["#405189", "#0ab39c", "#f7b84b"]} />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <div className="text-center py-5">
                    <p className="text-muted">Cases grouped by priority.</p>

                    <ReportTableContainer
                      tableClass="table-bordered"
                      divClass=" table-responsive"
                      columns={columns}
                      data={casestStatsList?.res || []}
                      customFetchData={() => []}
                    />
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <div className="text-center py-5">
                    <p className="text-muted">Cases grouped by status.</p>

                    <ReportTableContainer
                      tableClass="table-bordered"
                      divClass=" table-responsive"
                      columns={statusColumns}
                      data={casestStatsList?.case_status_stats || []}
                      customFetchData={() => []}
                    />
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <Row>
                    <div className="text-center py-0">
                      <h5>services Chart</h5>

                      <ColumnChart data={servicesChart} />
                    </div>
                  </Row>
                </CardBody>
                <CardBody>
                  <CardHeader>
                    <Row className="g-3">
                      <Col md={4}>
                        <div className="search-box">
                          <Input
                            onChange={(e) => {
                              handleFieldSearch(e);
                            }}
                            type="text"
                            className="form-control search"
                            placeholder="Search for service..."
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>

                  <div className="text-center py-5">
                    <TableContainer
                      columns={servicesColumns}
                      data={casestStatsList?.services_stats || []}
                      isGlobalFilter={false}
                      isAddUserList={false}
                      customFetchData={fetchData}
                      customTotalSize={casestStatsList?.services_count}
                      customPageSize={10}
                      className="custom-header-css"
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light"
                    />
                  </div>
                </CardBody>
              </Card>

              <Card>
                {/* {fetchingMemberList ?  : ""} */}

                <ToastContainer limit={1} closeButton={false} />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <OffCanvasFilter show={toggleFilterCanvas} onCloseClick={() => setToggleFilterCanvas(false)} />
    </React.Fragment>
  );
};

export default CasestStats;
