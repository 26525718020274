import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Input, Row } from "reactstrap";
//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";
import { deleteNote, getNotesList, resetNoteFlag } from "../../../store/actions";

import { AssignedTo, CasesId, CreateDate, Priority, Status, Title } from "./NoteCol";

import Widgets from "./Widgets";

import DeleteModal from "../../../Components/Common/DeleteModal";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loader from "../../../Components/Common/Loader";
import MsgToast from "../../../Components/Common/MsgToast";

import { debounce } from "lodash";
import CrmFilter from "./CrmFilter";

const NotesData = () => {
  const dispatch = useDispatch();
  const [isInfoDetails, setIsInfoDetails] = useState(false);

  const [caseData, setCase] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  const { isNoteSuccess, notesList, error, page, stats, isNoteDelete, isNoteDeleteFail, isLoading } = useSelector(
    (state) => ({
      casesList: state.Cases.casesList,
      error: state.Notes.error,
      stats: state.Cases.stats,
      page: state.Notes.page,
      notesList: state.Notes.notesList,
      isNoteSuccess: state.Notes.isNoteSuccess,
      isNoteDelete: state.Notes.isNoteDelete,
      isNoteDeleteFail: state.Notes.isNoteDeleteFail,
      isLoading: state.Notes.isLoading,
    })
  );

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetNoteFlag());
    }, 3000);
  }, [dispatch, isNoteSuccess, error, isNoteDelete, isNoteDeleteFail]);

  useEffect(() => {
    dispatch(getNotesList({ limit: 10 }));
  }, [dispatch]);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(getNotesList({ offset, limit }));
    },
    [dispatch]
  );

  // Delete Data
  const onClickDelete = (caseData) => {
    setCase(caseData);
    setDeleteModal(true);
  };

  const handleDeleteCase = () => {
    if (caseData) {
      dispatch(deleteNote(caseData));
      setDeleteModal(false);
    }
  };

  const handler = useCallback(
    debounce((value) => {
      dispatch(getNotesList({ search: value, limit: 10 }));
    }, 500),
    []
  );

  const toggleInfo = () => {
    setIsInfoDetails(!isInfoDetails);
  };

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    handler(value);
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <Input type="checkbox" />;
        },
      },
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
        Cell: (cellProps) => {
          return <CasesId {...cellProps} />;
        },
      },
      {
        Header: "Title",
        accessor: "title",
        filterable: false,
        Cell: (cellProps) => {
          return <Title {...cellProps} />;
        },
      },
      {
        Header: "Case",
        filterable: false,
        Cell: (cellProps) => {
          if (cellProps.row.original.case_id) {
            const { title } = cellProps.row.original.case_id;
            return title;
          }
        },
      },
      {
        Header: "Client",
        Cell: (cellProps) => {
          if (!cellProps) return "";

          const client_id = cellProps.row?.original?.client_id;
          if (!client_id) return "--";

          const { first_name, last_name } = client_id;

          return (
            <ul className="list-inline hstack gap-3 mb-0">
              <li className="list-inline-item edit" title="Add Note">
                <Link
                  className="d-inline-block"
                  to={{
                    pathname: "/clients-details-page/" + cellProps.row.original.client_id.id,
                    state: { caseItem: cellProps.row.original, clientItem: cellProps.row.original.client },
                  }}
                >
                  {first_name} {last_name}
                </Link>
              </li>
            </ul>
          );
        },
      },
      {
        Header: "Assigned To",
        accessor: "assigned_to",
        filterable: false,
        Cell: (cellProps) => {
          let ass_to = cellProps.cell.value;

          if (!ass_to.length) return "--";

          return <AssignedTo {...cellProps} />;
        },
      },
      {
        Header: "Create Date",
        accessor: "created_on",
        filterable: false,
        Cell: (cellProps) => {
          return <CreateDate {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "Priority",
        accessor: "priority",
        filterable: false,
        Cell: (cellProps) => {
          return <Priority {...cellProps} />;
        },
      },
      {
        Header: "Actions",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-3 mb-0">
              <li className="list-inline-item edit" title="Edit">
                <Link
                  className="text-muted d-inline-block"
                  to={{
                    pathname: "/case-notes/" + cellProps.row.original.id,
                  }}
                >
                  <i className="ri-edit-2-line fs-16 align-bottom me-2 text-muted"></i>
                </Link>
              </li>
              <li className="list-inline-item edit" title="Delete">
                <Link
                  to="#"
                  onClick={() => {
                    const NotesData = cellProps.row.original;
                    onClickDelete(NotesData);
                  }}
                  className="text-muted d-inline-block"
                >
                  <i className="ri-delete-bin-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Row>
        <Widgets stats={stats} />
      </Row>
      <Row>
        <DeleteModal show={deleteModal} onDeleteClick={handleDeleteCase} onCloseClick={() => setDeleteModal(false)} />
        <Col lg={12}>
          <Card>
            <CardHeader className="border-0">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 flex-grow-1">Notes</h5>
                <div className="flex-shrink-0">
                  <button type="button" className="btn btn-info" onClick={toggleInfo}>
                    <i className="ri-filter-3-line align-bottom me-1"></i> Fliters
                  </button>
                </div>
              </div>
            </CardHeader>
            <CardBody className="border border-dashed border-end-0 border-start-0">
              <form>
                <Row className="g-3">
                  <Col xxl={5} sm={12}>
                    <div className="search-box">
                      <Input
                        onChange={(e) => {
                          handleFieldSearch(e);
                        }}
                        type="text"
                        className="form-control search bg-light border-light"
                        placeholder="Search for note details or something..."
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </Col>
                </Row>
              </form>
            </CardBody>

            <CardBody>
              {notesList ? (
                <TableContainer
                  columns={columns}
                  data={notesList || []}
                  isGlobalFilter={false}
                  isAddUserList={false}
                  customFetchData={fetchData}
                  customTotalSize={page.count}
                  customPageSize={10}
                  className="custom-header-css"
                  divClass="table-responsive table-card mb-4"
                  tableClass="align-middle table-nowrap mb-0"
                  theadClass=""
                  thClass=""
                />
              ) : (
                <Loader error={error} />
              )}

              {isNoteDelete ? (
                <MsgToast msg="Note Deleted Successfusslly" color="success" icon="ri-checkbox-circle-line" />
              ) : null}
              {isNoteDeleteFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
              <ToastContainer limit={1} closeButton={false} />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <CrmFilter show={isInfoDetails} onCloseClick={() => setIsInfoDetails(false)} />
    </React.Fragment>
  );
};

export default NotesData;
