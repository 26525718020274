import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, CardBody, CardHeader, Col, Container, Form, Input, Row } from "reactstrap";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  updateOrgAddress,
  createOrgAddress,
  updateOrg,
  resetCreateOrginzationError,
  getProfile,
} from "../../../../store/actions";
import MsgToast from "../../../../Components/Common/MsgToast";
import TextField from "../../../../Components/Common/Fields/TextField";
import countries from "../../../../common/data/countries.json";
import SelectField from "../../../../Components/Common/Fields/SelectField";

const AccountOrganization = () => {
  const [shippingAddress, setShippingAddress] = useState({});
  const [orgName, setOrgName] = useState("");
  const [orgId, setOrgId] = useState("");
  const [shippingCountry, setShippingCountry] = useState("");

  const dispatch = useDispatch();
  const { success, org, error, isAddressUpdated, isOrgUpdated } = useSelector((state) => ({
    org: state.Profile.org,
    isAddressUpdated: state.Organization.isAddressUpdated,
    isOrgUpdated: state.Organization.isOrgUpdated,
    error: state.Organization.error,
    success: state.Organization.success,
  }));

  useEffect(() => {
    if (isAddressUpdated || isOrgUpdated || success || error) {
      setTimeout(() => {
        dispatch(resetCreateOrginzationError());
      }, 3000);
    }
  }, [dispatch, isAddressUpdated, isOrgUpdated, error, success]);

  useEffect(() => {
    if (!org) {
      dispatch(getProfile());
      return;
    }

    let orgName = org.name;
    let orgId = org.id;
    let shippingAddress = org.shipping_address;

    setShippingAddress(shippingAddress);
    setShippingCountry(shippingAddress?.country);
    setOrgName(orgName);
    setOrgId(orgId);
  }, [dispatch, org]);

  const orgValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: orgName || "",
      orgId: orgId,
    },
    validationSchema: yup.object({
      name: yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      dispatch(updateOrg(values));
    },
  });

  const shippingAddressValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: (shippingAddress && shippingAddress.id) || "",
      orgName: orgName,
      orgId: orgId,
      address_line: (shippingAddress && shippingAddress.address_line) || "",
      city: (shippingAddress && shippingAddress.city) || "",
      country: (shippingAddress && shippingAddress.country) || "",
      state: (shippingAddress && shippingAddress.state) || "",
      postcode: (shippingAddress && shippingAddress.postcode) || "",
      street: (shippingAddress && shippingAddress.street) || "",
      isUpdateShippingAddress: true,
    },
    validationSchema: yup.object({
      // name: yup.string().required("Please enter your current password"),
    }),
    onSubmit: (values) => {
      if (shippingAddress && shippingAddress.id) {
        dispatch(updateOrgAddress(values));
      } else {
        dispatch(createOrgAddress(values));
      }
    },
  });

  return (
    <React.Fragment>
      <Container className="py-3 pb-4">
        <Row>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              orgValidation.handleSubmit();
            }}
          >
            <Row>
              <Col>
                <CardHeader className="mb-0 border-0 pb-0">
                  <h2 className="mb-0">Organization Name</h2>
                </CardHeader>
                <CardBody className="pt-0">
                  <Row className="">
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={orgValidation.values.name}
                      onChange={orgValidation.handleChange}
                      validation={orgValidation}
                      onBlur={orgValidation.handleBlur}
                      placeholder="Enter work phone"
                      name="name"
                      // errorMessage={}
                    />
                  </Row>

                  <Button
                    type="submit"
                    name="submit"
                    value="Update"
                    className="btn btn-success text-white border-0 px-5 py-2 mx-auto"
                  >
                    Update
                  </Button>
                </CardBody>
              </Col>
            </Row>
          </Form>
        </Row>
        <Row>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              shippingAddressValidation.handleSubmit();
            }}
          >
            <Row>
              <Col md={6} xxl={6} className="">
                <CardHeader className="mb-0 border-0 pb-0">
                  <h2 className="title mb-0">Shipping Address</h2>
                </CardHeader>
                <CardBody className="pe-0">
                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={shippingAddressValidation.values.address_line}
                      onChange={shippingAddressValidation.handleChange}
                      validation={shippingAddressValidation}
                      onBlur={shippingAddressValidation.handleBlur}
                      placeholder="Enter address line"
                      label="Address Line"
                      name="address_line"
                      // errorMessage={}
                    />
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={shippingAddressValidation.values.street}
                      onChange={shippingAddressValidation.handleChange}
                      validation={shippingAddressValidation}
                      onBlur={shippingAddressValidation.handleBlur}
                      placeholder="Enter your street"
                      label="Street"
                      name="street"
                      // errorMessage={}
                    />
                  </Row>

                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={shippingAddressValidation.values.city}
                      onChange={shippingAddressValidation.handleChange}
                      validation={shippingAddressValidation}
                      onBlur={shippingAddressValidation.handleBlur}
                      placeholder="Enter your city"
                      label="City"
                      name="city"
                      // errorMessage={}
                    />
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={shippingAddressValidation.values.state}
                      onChange={shippingAddressValidation.handleChange}
                      validation={shippingAddressValidation}
                      onBlur={shippingAddressValidation.handleBlur}
                      placeholder="Enter you state"
                      label="State"
                      name="state"
                      // errorMessage={}
                    />
                  </Row>

                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={shippingAddressValidation.values.postcode}
                      onChange={shippingAddressValidation.handleChange}
                      validation={shippingAddressValidation}
                      onBlur={shippingAddressValidation.handleBlur}
                      placeholder="Enter your postcode"
                      label="Postcode"
                      name="postcode"
                      // errorMessage={}
                    />
                    <SelectField
                      md="6"
                      mb="mb-3"
                      onChange={shippingAddressValidation.handleChange}
                      validation={shippingAddressValidation}
                      onBlur={shippingAddressValidation.handleBlur}
                      placeholder="select..."
                      label="Country"
                      name="country"
                      // errorMessage={fieldErrors}
                      defaultValue={countries.find((c) => c.value === shippingCountry)}
                      key={shippingCountry}
                      options={countries}
                    />
                  </Row>
                  <Button
                    type="submit"
                    name="submit"
                    value="Update"
                    className="btn btn-success text-white border-0 px-5 py-2 mx-auto"
                  >
                    Update
                  </Button>
                </CardBody>
              </Col>
            </Row>
          </Form>
        </Row>
        {isAddressUpdated ? (
          <MsgToast msg="Address updated successfully" color="success" icon="ri-checkbox-circle-line" />
        ) : null}
        {success ? (
          <MsgToast msg="Address created successfully" color="success" icon="ri-checkbox-circle-line" />
        ) : null}
        {isOrgUpdated ? (
          <MsgToast msg="organization name updated successfully" color="success" icon="ri-checkbox-circle-line" />
        ) : null}
        {error ? <MsgToast msg={error.message} color="danger" icon="ri-error-warning-line" /> : null}{" "}
      </Container>
    </React.Fragment>
  );
};

export default AccountOrganization;
