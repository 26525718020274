import React from "react";

import { Link } from "react-router-dom";

//redux

const UserList = ({ handleOpenChat, receiverId, usersList }) => {
  return (
    <div className="chat-message-list">
      <ul className="list-unstyled chat-list chat-user-list users-list" id="userList">
        {((usersList && usersList) || []).map((user, i) => (
          <li key={i} className={receiverId === user.user_details.id ? "active" : ""}>
            <Link
              to="#"
              onClick={(e) => {
                handleOpenChat(user);
              }}
            >
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                  <div className="avatar-xxs">
                    {user.image ? (
                      <img src={user.image} className="rounded-circle img-fluid userprofile" alt="" />
                    ) : (
                      <div className={"avatar-title rounded-circle bg-" + "red" + " userprofile"}>
                        {user?.user_details?.first_name.charAt(0)}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-truncate mb-0">{`${user?.user_details?.first_name} ${user?.user_details?.last_name}`}</p>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserList;
