import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { Card, CardBody, CardHeader, Col, Container, Input, Row } from "reactstrap";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import OffCanvasFilter from "./OffCanvasFilter";

//Import actions
import { getMembers as onGetMembers, resetMembershipFlag, getClientStats } from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";

// Formik

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Components/Common/Loader";
import MsgToast from "../../../Components/Common/MsgToast";

const ClientsStats = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [toggleFilterCanvas, setToggleFilterCanvas] = useState(false);

  const { count, clientsStatsList, isMemberSuccess, error } = useSelector((state) => ({
    clientsStatsList: state.Statistics.clientStats,
    error: state.Membership.error,
    page: state.Membership.page,
    loading: state.Membership.loading,
    count: state.Statistics.count,
  }));

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetMembershipFlag());
    }, 3000);
  }, [dispatch, isMemberSuccess, error]);

  useEffect(() => {
    dispatch(getClientStats());
  }, []);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(getClientStats({ offset, limit }));
    },
    [dispatch]
  );

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "Client",
        Cell: (cellProps) => {
          const { full_name, cliend_id } = cellProps.row.original;
          return (
            <ul className="list-inline hstack gap-3 mb-0">
              <li className="list-inline-item edit" title="Add Note">
                <Link
                  to={{
                    pathname: "/clients-details-page/" + cliend_id,
                    state: { caseItem: cellProps.row.original, clientItem: cellProps.row.original.client },
                  }}
                  className="text-muted d-inline-block"
                >
                  {full_name}
                </Link>
              </li>
            </ul>
          );
        },
      },

      {
        Header: "open cases",
        accessor: "open_cases",
        filterable: false,
      },
      {
        Header: "pending cases",
        accessor: "pending_cases",
        filterable: false,
      },
      {
        Header: "closed cases",
        accessor: "closed_cases",
        filterable: false,
      },

      {
        Header: "total cases",
        accessor: "total_cases",
        filterable: false,
      },
    ],
    []
  );

  const handler = useCallback(
    debounce((value) => {
      dispatch(getClientStats({ search: value }));
    }, 500),
    []
  );

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    setSearch(value);
    handler(value);
  };

  document.title = "CLient stats - statistics | SimplyNP";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="CLient stats" pageTitle="CLient stats" />
          <Row>
            <Col>
              <Card id="memberList">
                <CardHeader>
                  <Row className="g-3">
                    <Col md={4}>
                      <div className="search-box">
                        <Input
                          onChange={(e) => {
                            handleFieldSearch(e);
                          }}
                          type="text"
                          className="form-control search"
                          placeholder="Search for client stats..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    {clientsStatsList ? (
                      <TableContainer
                        columns={columns}
                        data={clientsStatsList || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        customFetchData={fetchData}
                        customTotalSize={count}
                        customPageSize={10}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                      />
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>

                  {/* {fetchingMemberList ?  : ""} */}

                  <ToastContainer limit={1} closeButton={false} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <OffCanvasFilter show={toggleFilterCanvas} onCloseClick={() => setToggleFilterCanvas(false)} />
    </React.Fragment>
  );
};

export default ClientsStats;
