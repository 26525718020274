import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getProfile } from "../store/actions";

const Navdata = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isAuth, setIsAuth] = useState(false);

  // Apps
  const [isMembership, setIsMembership] = useState(false);
  const [isCases, setIsCases] = useState(false);
  const [isClients, setIsClients] = useState(false);
  const [isDonors, setIsDonors] = useState(false);
  const [IsVolunteers, setIsVolunteers] = useState(false);
  const [IsCampaigns, setIsCampaigns] = useState(false);
  const [IsReports, setIsReports] = useState(false);
  const [IsSettings, setIsSettings] = useState(false);
  const [isAccountSettings, setAccountSettings] = useState(false);
  const [isMembershipSettings, setMembershipSettings] = useState(false);
  const [isGeneralStats, setGeneralStats] = useState(false);
  const [isDonationStats, setIsDonationStats] = useState(false);
  const [isDemographicReports, setIsDemographicReports] = useState(false);
  const [isExecutiveReport, setIsExecutiveReport] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  const [sideMenuItems, setSideMenuItems] = useState([]);

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id)) document.getElementById(id).classList.remove("show");
      });
    }
  }

  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Apps") {
      setIsApps(false);
    }
    if (iscurrentState !== "Auth") {
      setIsAuth(false);
    }
    if (iscurrentState !== "Cases") {
      setIsCases(false);
    }
    if (iscurrentState !== "Clients") {
      setIsClients(false);
    }

    if (iscurrentState !== "Membership") {
      setIsMembership(false);
    }
    if (iscurrentState !== "Volunteers") {
      setIsVolunteers(false);
    }
    if (iscurrentState !== "Campaigns") {
      setIsCampaigns(false);
    }
    if (iscurrentState !== "Reports") {
      setIsReports(false);
    }
    if (iscurrentState !== "Settings") {
      setIsSettings(false);
    }
    if (iscurrentState !== "AccountSettings") {
      setAccountSettings(false);
    }
    if (iscurrentState !== "GeneralStats") {
      setGeneralStats(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isApps,
    isMembership,
    isAuth,
    isCases,
    isClients,
    isDonors,
    IsVolunteers,
    IsCampaigns,
    IsReports,
    IsSettings,
    isAccountSettings,
    isGeneralStats,
  ]);

  useEffect(() => {
    const menuItems = [
      {
        label: "Menu",
        isHeader: true,
      },
      {
        id: "dashboard",
        label: "Dashboard",
        link: "/dashboard",
        icon: "ri-dashboard-2-line",
        stateVariables: isDashboard,
      },

      {
        id: "tasks",
        label: "My Tasks",
        icon: "ri-task-line",
        link: "/tasks",
      },

      {
        id: "Calendar",
        label: "Calendar",
        icon: "las la-calendar",
        link: "/calendar",
        // stateVariables: isCalendar,
      },

      {
        id: "chat",
        label: "Chat",
        icon: "las la-comments",
        link: "/chat",
      },
      //{
      //id: "mailbox",
      //label: "Email",
      //icon: "las la-envelope",
      //link: "/apps-mailbox",
      //},
      {
        id: "campaigns",
        label: "Campaigns",
        icon: "las la-bullhorn",
        link: "/campaigns",
      },
      {
        label: "Client Management",
        isHeader: true,
      },
      {
        id: "clients",
        label: "Clients",
        icon: "las la-user-friends",
        link: "/#",
        click: function (e) {
          e.preventDefault();
          setIsClients(!isClients);
          setIscurrentState("Clients");
          updateIconSidebar(e);
        },
        parentId: "Clients",
        stateVariables: isClients,
        subItems: [
          {
            id: 4,
            label: "Overview",
            icon: "las la-list",
            link: "/clients-list",
            parentid: "clients",
          },
          // {
          //   id: 1,
          //   label: "List",
          //   icon: "las la-list",
          //   link: "/clients-list",
          //   parentid: "clients",
          // },
          {
            id: 1,
            label: "Create",
            icon: "las la-user-plus",
            link: "/clients-new",
            parentid: "clients",
          },
          // {
          //   id: 3,
          //   label: "Search",
          //   icon: "las la-search",
          //   link: "/clients-search",
          //   parentid: "clients",
          // },
          //  {
          //   id: 4,
          //   label: "Report",
          //   icon: "las la-search",
          //   link: "/clients-report",
          //   parentid: "clients",
          // },
        ],
      },
      {
        label: "Case Management",
        isHeader: true,
      },

      {
        id: "cases",
        label: "Cases",
        icon: "mdi mdi-clipboard-list-outline",
        link: "/cases",
      },
      {
        id: "notes",
        label: "Notes",
        icon: "mdi mdi-notebook-edit-outline",
        link: "/case-notes",
      },
      {
        label: "Membership management",
        isHeader: true,
      },
      {
        label: "Membership",
        icon: "mdi mdi-account-group-outline",
        link: "/#",
        click: function (e) {
          e.preventDefault();
          setIsMembership(!isMembership);
          setIscurrentState("Membership");
          updateIconSidebar(e);
        },
        parentId: "Membership",
        stateVariables: isMembership,
        subItems: [
          {
            id: 1,
            label: "Overview",
            link: "/memberships",
            parentid: "Membership",
          },
          {
            id: 2,
            label: "Members",
            link: "/members",
            parentid: "Membership",
          },
          //{
          //id: 3,
          //label: "Memberships",
          //link: "/memberships",
          //parentId: "Membership",
          //},
          {
            id: 4,
            label: "Membership Types",
            link: "/membership-types",
            parentId: "Membership",
          },
          {
            id: 5,
            label: "Settings",
            // link: "/membership-settings",
            parentId: "Membership",
            isChildItem: true,
            click: function (e) {
              e.preventDefault();
              setMembershipSettings(!isMembershipSettings);
            },
            stateVariables: isMembershipSettings,
            childItems: [
              {
                id: 2,
                label: "Benefits",
                link: "/membership-benefits",
                parentId: "Membership",
              },
              //{
              //id: 3,
              //label: "Levels",
              //link: "/membership-levels",
              //parentId: "Membership",
              //},
              //{
              //id: 4,
              //label: "Groups",
              //link: "/membership-groups",
              //parentId: "Membership",
              //},
              //{
              //id: 5,
              //label: "Website",
              //link: "/membership-site-settings",
              //parentId: "Membership",
              //},
            ],
          },
        ],
      },
      {
        label: "Donors Management",
        isHeader: true,
      },
      {
        id: "donors",
        label: "Donors",
        icon: "ri-user-heart-fill",
        link: "/#",
        click: function (e) {
          e.preventDefault();
          setIsDonors(!isDonors);
          setIscurrentState("Donors");
          updateIconSidebar(e);
        },
        parentId: "donors",
        stateVariables: isDonors,
        subItems: [
          {
            id: 1,
            label: "Donations",
            //link: "/donor-overview",
            link: "/donor-donations",
            parentid: "donors",
          },
          //{
          //id: 4,
          //label: "Leads",
          //link: "/apps-crm-leads",
          //parentId: "donors",
          //},
          {
            id: 2,
            label: "Contacts",
            link: "/donor-contacts",
            parentid: "donors",
          },
          {
            id: 3,
            label: "Organizations",
            link: "/donor-orgs",
            parentId: "donors",
          },
          //{
          //id: 5,
          //label: "Donations",
          //link: "/donor-donations",
          //parentId: "donors",
          //},
        ],
      },

      {
        label: "Volunteers",
        isHeader: true,
      },

      {
        id: "volunteers",
        label: "Volunteers",
        icon: "mdi mdi-human-greeting",
        link: "/#",
        click: function (e) {
          e.preventDefault();
          setIsVolunteers(!IsVolunteers);
          setIscurrentState("Volunteers");
          updateIconSidebar(e);
        },
        stateVariables: IsVolunteers,
        subItems: [
          {
            id: 1,
            label: "Overview",
            link: "/volunteer-list",
            parentid: "volunteers",
          },
          // {
          //   id: 2,
          //   label: "List",
          //   link: "/volunteer-list",
          //   parentid: "volunteers",
          // },
          {
            id: 2,
            label: "Add New",
            link: "/volunteer-new",
            parentid: "volunteers",
          },
        ],
      },

      {
        label: "Reports",
        isHeader: true,
      },

      {
        id: "reports",
        label: "Reports",
        icon: "las las la-chart-pie",
        link: "/#",
        click: function (e) {
          e.preventDefault();
          setIsReports(!IsReports);
          setIscurrentState("Reports");
          updateIconSidebar(e);
        },
        stateVariables: IsReports,
        subItems: [
          {
            id: 1,
            label: "Overview",
            link: "/report-list",
            parentid: "reports",
          },
          // { id: 2, label: "List", link: "/report-list", parentId: "reports" },
          { id: 2, label: "Add New", link: "/report-new", parentId: "reports" },
        ],
      },
      {
        label: "General stats",
        isHeader: true,
      },

      {
        id: "generalStats",
        label: "General stats",
        icon: "las las la-chart-pie",
        link: "/#",
        click: function (e) {
          e.preventDefault();
          setGeneralStats(!isGeneralStats);
          setIscurrentState("GeneralStats");
          updateIconSidebar(e);
        },
        stateVariables: isGeneralStats,
        subItems: [
          {
            id: 1,
            label: "client stats",
            link: "/client-stats",
            parentid: "generalStats",
          },
          {
            id: 2,
            label: "cases stats",
            link: "/cases-stats",
            parentid: "generalStats",
          },
          {
            id: 3,
            label: "services stats",
            link: "/services-stats",
            parentid: "generalStats",
          },

          {
            id: 5,
            label: "Donations - group by",
            parentId: "DonationStats",
            isChildItem: true,
            click: function (e) {
              e.preventDefault();
              setIsDonationStats(!isDonationStats);

              updateIconSidebar(e);
            },
            stateVariables: isDonationStats,

            childItems: [
              {
                id: 2,
                label: "organization",
                link: "/donations-organization",
                parentId: "DonationStats",
              },
              {
                id: 3,
                label: "contacts",
                link: "/donations-contacts",
                parentId: "DonationStats",
              },
              {
                id: 4,
                label: "campaigns",
                link: "/donations-campaigns",
                parentId: "DonationStats",
              },
              {
                id: 5,
                label: "Orgs time pattern",
                link: "/donations-org-pattern",
                parentId: "DonationStats",
              },
            ],
          },
        ],
      },
      {
        label: "executive reports",
        isHeader: true,
      },
      {
        id: "executiveReports",
        label: "executive reports",
        icon: "las las la-chart-pie",
        click: function (e) {
          e.preventDefault();
          setIsExecutiveReport(!isExecutiveReport);
          updateIconSidebar(e);
        },
        stateVariables: isExecutiveReport,
        subItems: [
          {
            id: 1,
            label: "Demographics report",
            parentId: "Membership",
            isChildItem: true,
            click: function (e) {
              e.preventDefault();
              setIsDemographicReports(!isDemographicReports);

              updateIconSidebar(e);
            },
            stateVariables: isDemographicReports,

            childItems: [
              {
                id: 2,
                label: "Birth year",
                link: "/demographics-birth-year",
                parentId: "executiveReports",
              },
              {
                id: 3,
                label: "Gender",
                link: "/demographics-gender",
                parentId: "executiveReports",
              },
              {
                id: 4,
                label: "Language",
                link: "/demographics-language",
                parentId: "executiveReports",
              },
              {
                id: 5,
                label: "Country",
                link: "/demographics-country",
                parentId: "executiveReports",
              },
            ],
          },
        ],
      },
    ];

    const adminSettingsMenu = [
      {
        label: "Settings",
        isHeader: true,
      },
      {
        id: "settings",
        label: "Settings",
        icon: "ri-settings-4-line",
        link: "/#",
        click: function (e) {
          e.preventDefault();
          setIsSettings(!IsSettings);
          setIscurrentState("Settings");
          updateIconSidebar(e);
        },
        stateVariables: IsSettings,
        subItems: [
          {
            id: "account",
            label: "Account Settings",
            link: "/settings-accounts",
            parentId: "settings",
          },

          {
            id: "users",
            label: "Users Management",
            link: "/settings-users",
            parentid: "settings",
          },

          {
            id: "teams",
            label: "Team Management",
            link: "/settings-teams",
            parentid: "settings",
          },

          {
            id: "services",
            label: "Services Settings",
            link: "/settings-services",
            parentid: "settings",
          },
        ],
      },
    ];

    const userSettingsMenu = [
      {
        label: "Settings",
        isHeader: true,
      },
      {
        id: "settings",
        label: "Settings",
        icon: "ri-settings-4-line",
        link: "/settings/profile",
        click: function (e) {
          e.preventDefault();
          setIsSettings(!IsSettings);
          setIscurrentState("Settings");
          updateIconSidebar(e);
        },
        stateVariables: IsSettings,
      },
    ];

    if (user?.role === "ADMIN") {
      setSideMenuItems([...menuItems, ...adminSettingsMenu]);
    } else if (user?.role === "USER") {
      setSideMenuItems([...menuItems, ...userSettingsMenu]);
    }
  }, [
    user,
    IsReports,
    IsSettings,
    IsVolunteers,
    isClients,
    isDashboard,
    isDemographicReports,
    isDonationStats,
    isDonors,
    isExecutiveReport,
    isGeneralStats,
    isMembership,
    isMembershipSettings,
  ]);

  return <React.Fragment>{sideMenuItems}</React.Fragment>;
};

export default Navdata;
