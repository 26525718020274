import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { ToastContainer } from "react-toastify";
import MsgToast from "../../Components/Common/MsgToast";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup } from "reactstrap";
import { createClientComment as ccc, resetClientFlag } from "../../store/actions";

function QuickCommentModal({ show, clientId, onCloseClick }) {
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");
  const [modal, setModal] = useState(false);
  const [client_errors, set_client_errors] = useState(null);

  const { error, loading, isCommentAdd, isCommentAddFail } = useSelector((state) => ({
    error: state.Clients.error,
    loading: state.Clients.loading,
    isCommentAdd: state.Clients.isCommentAdd,
    isCommentAddFail: state.Clients.isCommentAddFail,
  }));

  useEffect(() => {
    if (isCommentAdd || isCommentAddFail || error) {
      if (error) {
        set_client_errors(error && error.errors && error.errors["client_errors"]);
      }

      setTimeout(() => {
        dispatch(resetClientFlag());
      }, 3000);
    }
  }, [dispatch, isCommentAdd, isCommentAddFail, error]);

  //   const toggle = () => setModal(!modal);
  const changeUnmountOnClose = (e) => {
    let { value } = e.target;
    //console.log(value, 'valueee')
    setComment(JSON.stringify(value));
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      comment: comment,
    },
    validationSchema: Yup.object({
      comment: Yup.string().notRequired("Please Enter A comment"),
    }),
    onSubmit: (values) => {
      set_client_errors(null);

      // You can access the comment data through 'values.comment'.
      // Make sure 'clientId' is passed as a prop to this component.
      if (clientId) {
        dispatch(ccc({ clientId, comment: values.comment }));
        onCloseClick();
      }

      //console.log(values, 'vvvv')
    },
  });

  return (
    <div>
      <Form inline onSubmit={(e) => e.preventDefault()}></Form>
      <Modal isOpen={show} toggle={onCloseClick} unmountOnClose={true}>
        <ModalHeader>Comment</ModalHeader>
        <ModalBody>
          <Input
            type="textarea"
            className="form-control"
            placeholder="Add comment here...."
            name="comment"
            onChange={changeUnmountOnClose}
          />
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="primary" onClick={validation.handleSubmit}>
            Add Comment
          </Button>{" "}
          <Button type="button" color="secondary" onClick={onCloseClick}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {isCommentAdd ? (
        <MsgToast msg="Comment Added Successfully" color="success" icon="ri-checkbox-circle-line" />
      ) : null}

      {isCommentAddFail ? <MsgToast msg="Comment Failed" color="succdangeress" icon="ri-error-warning-line" /> : null}

      <ToastContainer limit={1} closeButton={false} />
    </div>
  );
}

QuickCommentModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};
export default QuickCommentModal;

