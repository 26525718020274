import classnames from "classnames";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Container,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import SimpleBar from "simplebar-react";
// Import Content
//import Components
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import AccountProfile from "./Profile/Profile";
import AccountsBilling from "./billing/Billing";
import AccountsExtras from "./extras/Extras";
import Organization from "./organization/Organization";
import AccountsOverView from "./overview/Overview";

import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Link, Redirect, useParams, useRouteMatch, withRouter } from "react-router-dom";
import { getProfile } from "../../../store/actions";

const AccountSettings = () => {
  const { id } = useParams();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("overview");
  const [activeArrowTab, setactiveArrowTab] = useState("overview");

  const { user, org } = useSelector((state) => ({
    user: state.Profile.user,
    org: state.Profile.org,
  }));

  let newPaths = path && path.split("/:")[0];

  useEffect(() => {
    if (!org) dispatch(getProfile());
    if (id) {
      setactiveArrowTab(id);
      setActiveTab(id);
    }
  }, [dispatch, id, org, path]);

  const tabToggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setactiveArrowTab(tab);
    }
  };

  document.title = "Settings - Profile | Velzon - React Admin & Dashboard Template";
  if (user?.role === "ADMIN")
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title="Account" pageTitle="Account Settings" />
            <Row className="">
              <BrowserRouter>
                {/*<Card className="my-1">
                <CardHeader>
                  <h2 className="px-2 py-3 mb-0">{org?.name}</h2>
                </CardHeader>
              </Card> */}
                <Card>
                  <CardHeader>
                    <Nav pills className="nav-justified">
                      <NavItem>
                        <NavLink
                          tag={Link}
                          // to={`${url}/'overview'`}
                          to={`${newPaths}/${"overview"}`}
                          style={{ cursor: "pointer" }}
                          className={classnames({ active: activeTab === "overview" })}
                          onClick={() => tabToggle("overview")}
                        >
                          Overview
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          tag={Link}
                          // to={`${url}/'profile'`}
                          to={`${newPaths}/${"profile"}`}
                          style={{ cursor: "pointer" }}
                          className={classnames({ active: activeTab === "profile" })}
                          onClick={() => tabToggle("profile")}
                        >
                          Profile
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          tag={Link}
                          // to={`${url}/'billing'`}
                          to={`${newPaths}/${"billing"}`}
                          style={{ cursor: "pointer" }}
                          className={classnames({ active: activeTab === "billing" })}
                          onClick={() => tabToggle("billing")}
                        >
                          Billing
                        </NavLink>
                      </NavItem>
                      {/*
                    <NavItem>
                      <NavLink
                        tag={Link}
                        // to={`${url}/4`}
                        to={`${newPaths}/${4}`}
                        style={{ cursor: "pointer" }}
                        className={classnames({ active: activeTab === 4 })}
                        onClick={() => tabToggle(4)}
                      >
                        Extras
                      </NavLink>
                    </NavItem>
*/}
                      <NavItem>
                        <NavLink
                          tag={Link}
                          // to={`${url}/'organization'`}
                          to={`${newPaths}/${"organization"}`}
                          style={{ cursor: "pointer" }}
                          className={classnames({ active: activeTab === "organization" })}
                          onClick={() => tabToggle("organization")}
                        >
                          Organization
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </CardHeader>

                  <TabContent activeTab={activeArrowTab}>
                    <TabPane id="steparrow-gen-info" tabId={"overview"}>
                      <AccountsOverView />
                    </TabPane>
                    <TabPane id="steparrow-gen-info" tabId={"profile"}>
                      <AccountProfile />
                    </TabPane>
                    <TabPane id="steparrow-gen-info" tabId={"billing"}>
                      <AccountsBilling />
                    </TabPane>
                    <TabPane id="steparrow-gen-info" tabId={"extras"}>
                      <AccountsExtras />
                    </TabPane>
                    <TabPane id="steparrow-gen-info" tabId={"organization"}>
                      <Organization />
                    </TabPane>
                  </TabContent>

                  {/* <CardBody>
                  <Switch>
                    <Route exact path={`${path}/overview`} component={AccountsOverView} />
                    <Route path={`${path}/profile`} component={AccountProfile} />
                    <Route path={`${path}/billing`} component={AccountsBilling} />
                    <Route path={`${path}/extras`} component={AccountsExtras} />
                    <Route path={`${path}/organization`} component={Organization} />

                    <Redirect exact path={`${path}/`} to={`${path}/overview`} />
                  </Switch>
                </CardBody> */}
                </Card>
              </BrowserRouter>
            </Row>
            <Offcanvas direction="bottom" style={{ minHeight: "80vh" }}>
              <OffcanvasHeader>Upgrade Plan</OffcanvasHeader>
              <OffcanvasBody>
                <div>
                  <SimpleBar></SimpleBar>
                </div>
              </OffcanvasBody>
            </Offcanvas>
          </Container>
        </div>
      </React.Fragment>
    );

  return <Redirect to="/settings/profile" />;
};

export default withRouter(AccountSettings);
