import { PROFILE_CHANGE_PASSWORD } from "../../../helpers/url_helper";
import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_PROFILE,
  PROFILE_CHANGE_PASSWORD_SUCCESS,
  PROFILE_CHANGE_PASSWORD_FAIL,
  EDIT_PROFILE_SUCCESS,
} from "./actionTypes";

export const getProfile = () => ({
  type: GET_PROFILE,
});

export const editProfile = (user) => ({
  type: EDIT_PROFILE,
  payload: { user },
});

export const profileChangePassword = (passwords) => ({
  type: PROFILE_CHANGE_PASSWORD,
  payload: { passwords },
});

export const profileChangePasswordSuccess = (passwords) => ({
  type: PROFILE_CHANGE_PASSWORD_SUCCESS,
  payload: { passwords },
});

export const profileChangePasswordFail = (response) => ({
  type: PROFILE_CHANGE_PASSWORD_FAIL,
  payload: response,
});

export const profileSuccess = (msg) => ({
  type: PROFILE_SUCCESS,
  payload: msg,
});
export const editProfileSuccess = (msg) => ({
  type: EDIT_PROFILE_SUCCESS,
  payload: msg,
});

export const profileError = (error) => ({
  type: PROFILE_ERROR,
  payload: error,
});

export const resetProfileFlag = () => ({
  type: RESET_PROFILE_FLAG,
});
