import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { debounce } from "lodash";

import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from "reactstrap";
import Select from "react-select";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import QuickCommentModal from "../../Components/Common/QuickCommentModal";

//Import actions
import {
  getClientsList as onGetClients,
  deleteClient as onDeleteClient,
  resetClientFlag,
  getCasesList,
  createClientComment,
} from "../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";

import Loader from "../../Components/Common/Loader";
import MsgToast from "../../Components/Common/MsgToast";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Widgets from "../cases/ListView/Widgets";
import CrmFilter from "./CrmFilter";

const ClientsList = () => {
  const dispatch = useDispatch();
  const {
    fetchingClientList,
    clientsList,
    isClientSuccess,
    isClientDelete,
    isClientDeleteFail,
    isClientUpdate,
    isClientUpdateFail,
    error,
    page,
    isClientAdd,
    stats,
    isClientAddFail,
  } = useSelector((state) => ({
    isClientCreated: state.Clients.isClientsCreated,
    isClientSuccess: state.Clients.isClientSuccess,
    clientsList: state.Clients.clientsList,
    error: state.Clients.error,
    page: state.Clients.page,
    stats: state.Cases.stats,
    crmusers: state.Profile.usersList,
    isClientAdd: state.Clients.isClientAdd,
    isClientAddFail: state.Clients.isClientAddFail,
    isClientDelete: state.Clients.isClientDelete,
    isClientDeleteFail: state.Clients.isClientDeleteFail,
    isClientUpdate: state.Clients.isClientUpdate,
    isClientUpdateFail: state.Clients.isClientUpdateFail,
    fetchingClientList: state.Clients.fetchingClientList,
  }));

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetClientFlag());
    }, 3000);
  }, [
    dispatch,
    isClientSuccess,
    error,
    isClientAdd,
    isClientAddFail,
    isClientDelete,
    isClientDeleteFail,
    isClientUpdate,
    isClientUpdateFail,
  ]);

  //delete client
  const [client, setClient] = useState([]);
  const [commentClient, setCommentClient] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [sortBy, setsortBy] = useState(null);
  const [search, setSearch] = useState("");
  const [isInfoDetails, setIsInfoDetails] = useState(false);

  useEffect(() => {
    dispatch(onGetClients());
    dispatch(getCasesList());
  }, [dispatch]);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(onGetClients({ offset, limit }));
    },
    [dispatch]
  );

  const toggleInfo = () => {
    setIsInfoDetails(!isInfoDetails);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return (
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="checkAll" value="option1" />
            </div>
          );
        },
      },
      {
        Header: "Salutation",
        accessor: "salutation",
        filterable: false,
      },
      {
        Header: "Full Name",
        accessor: "first_name",
        filterable: false,
        Cell: (client) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {client.row.original.image_src ? (
                  <img
                    src={process.env.REACT_APP_API_URL + "/images/users/" + client.row.original.image_src}
                    alt=""
                    className="avatar-xxs rounded-circle"
                  />
                ) : (
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                      {client.row.original.first_name.charAt(0)}
                      {client.row.original.last_name.charAt(0)}
                    </div>
                  </div>
                )}
              </div>
              <div className="flex-grow-1 ms-2 name">
                {client.row.original.first_name} {client.row.original.last_name}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Email",
        accessor: "primary_email",
        filterable: false,
      },
      {
        Header: "Mobile Number",
        accessor: "primary_phone",
        filterable: false,
      },
      {
        Header: "Country",
        accessor: "country",
      },
      {
        Header: "Date Of Birth",
        accessor: "date_of_birth",
      },
      {
        Header: "Actions",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-3 mb-0">
              <li className="list-inline-item edit" title="view details">
                <Link
                  to={{ pathname: "/clients-details-page/" + cellProps.row.original.id }}
                  className="text-muted d-inline-block"
                >
                  <i className="ri-eye-fill me-2 align-middle text-muted" />
                  View
                </Link>
              </li>
              <li className="list-inline-item edit" title="Add Case">
                <Link
                  to={{ pathname: "/cases-new", state: { client: cellProps.row.original } }}
                  className="text-muted d-inline-block"
                >
                  <i className="ri-folder-add-line fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item edit" title="Add Comment">
                <div
                  onClick={() => {
                    const clientId = cellProps.row.original.id;
                    onClickComment(clientId);
                  }}
                  className="text-muted d-inline-block"
                >
                  <i className="ri-message-2-line fs-16"></i>
                </div>
              </li>

              <li className="list-inline-item edit">
                <UncontrolledDropdown>
                  <DropdownToggle href="#" className="btn-soft-secondary btn-sm dropdown" tag="button">
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <Link to={"/clients-update/" + cellProps.row.original.id} className="text-muted">
                      <DropdownItem className="dropdown-item remove-item-btn text-muted">
                        <i className="ri-edit-2-line align-middle me-2 fs-16 text-muted"></i> Edit
                      </DropdownItem>
                    </Link>
                    <DropdownItem
                      className="dropdown-item remove-item-btn text-muted"
                      onClick={() => {
                        const clientData = cellProps.row.original;
                        onClickDelete(clientData);
                      }}
                    >
                      <i className="ri-delete-bin-fill align-middle me-2 text-muted"></i> Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const handlesortBy = (sortBy) => {
    setsortBy(sortBy);
  };

  const sortbyname = [
    {
      options: [
        { label: "Owner", value: "Owner" },
        { label: "Company", value: "Company" },
        { label: "Location", value: "Location" },
      ],
    },
  ];

  // Delete Data
  const handleDeleteClient = () => {
    if (client) {
      dispatch(onDeleteClient(client));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (client) => {
    setClient(client);
    setDeleteModal(true);
  };

  // Add Client Comment

  const onClickComment = (clientId) => {
    setCommentClient(clientId);
    setCommentModal(true);
  };

  const handler = useCallback(
    debounce((value) => {
      dispatch(onGetClients({ search: value }));
    }, 1500),
    []
  );

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    setSearch(value);
    handler(value);
  };

  document.title = "Clients | SimplyNP";
  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal show={deleteModal} onDeleteClick={handleDeleteClient} onCloseClick={() => setDeleteModal(false)} />
        <QuickCommentModal show={commentModal} onCloseClick={() => setCommentModal(false)} clientId={commentClient} />
        <Container fluid>
          <BreadCrumb title="List Clients" pageTitle="Clients" />
          <Row>
            <Widgets stats={stats} />
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-1">
                      <Link to="/clients-new">
                        <button className="btn btn-info add-btn">
                          <i className="ri-add-fill me-1 align-bottom"></i> Add Client
                        </button>
                      </Link>
                    </div>
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                        <button className="btn btn-soft-danger">
                          <i className="ri-delete-bin-2-line"></i>
                        </button>
                        <button type="button" className="btn btn-info" onClick={toggleInfo}>
                          <i className="ri-filter-3-line align-bottom me-1"></i> Fliters
                        </button>
                        <button className="btn btn-soft-success">Import</button>

                        <UncontrolledDropdown>
                          <DropdownToggle href="#" className="btn btn-soft-info" tag="button">
                            <i className="ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" href="#">
                              All
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Week
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Month
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Year
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col>
              <Card id="clientList">
                <CardHeader>
                  <Row className="g-3">
                    <Col md={4}>
                      <div className="search-box">
                        <Input
                          onChange={(e) => {
                            handleFieldSearch(e);
                          }}
                          type="text"
                          className="form-control search"
                          placeholder="Search for client..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    <div className="col-md-auto ms-auto">
                      <div className="d-flex align-items-center gap-2">
                        <span className="text-muted">Sort by: </span>
                        <Select
                          className="mb-0"
                          value={sortBy}
                          onChange={() => {
                            handlesortBy();
                          }}
                          options={sortbyname}
                          id="choices-single-default"
                        ></Select>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    {isClientSuccess ? (
                      <TableContainer
                        columns={columns}
                        data={clientsList || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        customFetchData={fetchData}
                        customTotalSize={page.count}
                        customPageSize={10}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                      />
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>

                  {isClientDelete ? (
                    <MsgToast msg="Client Deleted Successfully" color="success" icon="ri-checkbox-circle-line" />
                  ) : null}

                  {isClientDeleteFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}

                  <ToastContainer limit={1} closeButton={false} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CrmFilter show={isInfoDetails} onCloseClick={() => setIsInfoDetails(false)} />
    </React.Fragment>
  );
};

export default ClientsList;
