import {
  GET_CONTACT_BY_ID,
  GET_CONTACTS,
  GET_ORGANIZATIONS,
  GET_DEALS,
  GET_LEADS,
  // GET_USERS,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_ORGANIZATIONS_SUCCESS,
  ADD_ORGANIZATIONS_FAIL,
  UPDATE_ORGANIZATIONS_SUCCESS,
  UPDATE_ORGANIZATIONS_FAIL,
  DELETE_ORGANIZATIONS_SUCCESS,
  DELETE_ORGANIZATIONS_FAIL,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_FAIL,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAIL,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAIL,
  ADD_LEAD_SUCCESS,
  ADD_LEAD_FAIL,
  UPDATE_LEAD_SUCCESS,
  UPDATE_LEAD_FAIL,
  DELETE_LEAD_SUCCESS,
  DELETE_LEAD_FAIL,
  RESET_CRM_FLAG,
} from "./actionType";

const INIT_STATE = {
  // users: [],
  error: {},
};

const Crm = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CONTACT_BY_ID:
          return {
            ...state,
            contact: action.payload.data.contact_obj,
            isContactSuccess: true,
            isContactCreated: false,
          };
        case GET_CONTACTS:
          return {
            ...state,
            crmcontacts: action.payload.data.contact_obj_list,
            activeDonorCount: action.payload.data.active_donor_count,
            monthlyActiveDonors: action.payload.data.monthly_active_donors,
            contacts_page_info: { count: action.payload.data.contacts_count, offset: action.payload.data.offset },
            isContactCreated: false,
            isContactSuccess: true,
          };
        case GET_ORGANIZATIONS:
          return {
            ...state,
            organizations: action.payload.data.org_list,
            isOrganizationsCreated: false,
            isOrganizationsSuccess: true,
            count: action.payload.data.count,
            isLoading: false,
          };
        case GET_DEALS:
          return {
            ...state,
            deals: action.payload.data,
          };
        case GET_LEADS:
          return {
            ...state,
            leadList: action.payload.data,
            isLeadCreated: false,
            isLeadsSuccess: true,
          };
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_CONTACT_BY_ID:
          return {
            ...state,
            error: action.payload.error,
            isContactSuccess: false,
            isContactCreated: false,
          };
        case GET_CONTACTS:
          return {
            ...state,
            error: action.payload.error,
            isContactCreated: false,
            isContactSuccess: false,
          };
        case GET_ORGANIZATIONS:
          return {
            ...state,
            error: action.payload.error,
            isOrganizationsCreated: false,
            isOrganizationsSuccess: false,
            isLoading: false,
          };
        case GET_DEALS:
          return {
            ...state,
            error: action.payload.error,
          };
        case GET_LEADS:
          return {
            ...state,
            error: action.payload.error,
            isLeadCreated: false,
            isLeadsSuccess: false,
          };
        default:
          return { ...state };
      }
    case GET_ORGANIZATIONS: {
      return {
        ...state,
        isOrganizationsCreated: false,
        isLoading: true,
      };
    }
    case GET_DEALS: {
      return {
        ...state,
      };
    }
    case GET_LEADS:
      return {
        ...state,
        isLeadCreated: false,
      };
    case ADD_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        isOrganizationsCreated: true,
        organizations: [...state.organizations, action.payload.data],
        isOrganizationsAdd: true,
        isOrganizationsAddFail: false,
      };

    case ADD_ORGANIZATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        isOrganizationsAdd: false,
        isOrganizationsAddFail: true,
      };

    case UPDATE_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        // organizations: state.organizations.map((organization) =>
        //   organization.id.toString() === action.payload.organization.id.toString() ? { ...organization, ...action.payload.data } : organization
        // ),
        isOrganizationsUpdate: true,
        isOrganizationsUpdateFail: false,
      };

    case UPDATE_ORGANIZATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        isOrganizationsUpdate: false,
        isOrganizationsUpdateFail: true,
      };

    case DELETE_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: state.organizations.filter(
          (organization) => organization.id.toString() !== action.payload.organizations.toString()
        ),
        isOrganizationsDelete: true,
        isOrganizationsDeleteFail: false,
      };

    case DELETE_ORGANIZATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        isOrganizationsDelete: false,
        isOrganizationsDeleteFail: true,
      };

    case ADD_CONTACT_SUCCESS:
      return {
        ...state,
        isContactCreated: true,
        // crmcontacts: [...state.crmcontacts, action.payload.data],
        isContactAdd: true,
        isContactAddFail: false,
      };

    case ADD_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload,
        isContactAdd: false,
        isContactAddFail: true,
      };

    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        // crmcontacts: state.crmcontacts.map((contact) =>
        //   contact.id.toString() === action.payload.contact.id.toString()
        //     ? { ...contact, ...action.payload.data }
        //     : contact
        // ),
        isContactUpdate: true,
        isContactUpdateFail: false,
      };

    case UPDATE_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload,
        isContactUpdate: false,
        isContactUpdateFail: true,
      };

    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        crmcontacts: state.crmcontacts.filter((contact) => contact.id.toString() !== action.payload.contact.toString()),
        isContactDelete: true,
        isContactDeleteFail: false,
      };

    case DELETE_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload,
        isContactDelete: false,
        isContactDeleteFail: true,
      };

    case ADD_LEAD_SUCCESS:
      return {
        ...state,
        isLeadCreated: true,
        // leadList: [...state.leadList, action.payload.data],
        isLeadsAdd: true,
        isLeadsAddFail: false,
      };

    case ADD_LEAD_FAIL:
      return {
        ...state,
        error: action.payload,
        isLeadsAdd: false,
        isLeadsAddFail: true,
      };

    case UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        leadList: state.leadList.map((lead) =>
          lead.id.toString() === action.payload.leads.id.toString() ? { ...lead, ...action.payload.data } : lead
        ),
        isLeadsUpdate: true,
        isLeadsUpdateFail: false,
      };

    case UPDATE_LEAD_FAIL:
      return {
        ...state,
        error: action.payload,
        isLeadsUpdate: false,
        isLeadsUpdateFail: true,
      };

    case DELETE_LEAD_SUCCESS:
      return {
        ...state,
        leadList: state.leadList.filter((lead) => lead.id.toString() !== action.payload.leads.id.toString()),
        isLeadsDelete: true,
        isLeadsDeleteFail: false,
      };

    case DELETE_LEAD_FAIL:
      return {
        ...state,
        error: action.payload,
        isLeadsDelete: false,
        isLeadsDeleteFail: true,
      };
    case RESET_CRM_FLAG:
      return {
        ...state,
        isContactAdd: false,
        isContactAddFail: false,
        isContactDelete: false,
        isContactDeleteFail: false,
        isContactUpdate: false,
        isContactUpdateFail: false,
        isOrganizationsAdd: false,
        isOrganizationsAddFail: false,
        isOrganizationsDelete: false,
        isOrganizationsDeleteFail: false,
        isOrganizationsUpdate: false,
        isOrganizationsUpdateFail: false,
        isLeadsAdd: false,
        isLeadsAddFail: false,
        isLeadsDelete: false,
        isLeadsDeleteFail: false,
        isLeadsUpdate: false,
        isLeadsUpdateFail: false,
        error: false,
        isLeadCreated: false,
      };

    default:
      return { ...state };
  }
};

export default Crm;
