import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import * as actionType from "./actionType";
import * as actions from "./action";
import * as backend from "../../helpers/backend_helper";

function* getContacts({ payload }) {
  try {
    const response = yield call(backend.getContacts, payload);
    yield put(actions.crmApiResponseSuccess(actionType.GET_CONTACTS, response));
  } catch (error) {
    yield put(actions.crmApiResponseError(actionType.GET_CONTACTS, error));
  }
}

function* onGetContactByID({ payload }) {
  try {
    const response = yield call(backend.getContact, payload);
    yield put(actions.crmApiResponseSuccess(actionType.GET_CONTACT_BY_ID, response));
  } catch (error) {
    yield put(actions.crmApiResponseError(actionType.GET_CONTACT_BY_ID, error));
  }
}

function* getOrganizations({ payload }) {
  try {
    const response = yield call(backend.getOrganizationList, payload);
    const org_list = response.accounts_list;
    let data = { org_list, count: response.count, offset: response.offset };
    yield put(actions.crmApiResponseSuccess(actionType.GET_ORGANIZATIONS, data));
  } catch (error) {
    yield put(actions.crmApiResponseError(actionType.GET_ORGANIZATIONS, error));
  }
}

// function* getDeals() {
//   try {
//     const response = yield call(getDealsApi);
//     yield put(crmApiResponseSuccess(GET_DEALS, response));
//   } catch (error) {
//     yield put(crmApiResponseError(GET_DEALS, error));
//   }
// }

function* getLeads({ payload }) {
  try {
    const response = yield call(backend.getLeads, payload);
    const allLeads = [...response.open_leads.open_leads, ...response.close_leads.close_leads];
    yield put(actions.crmApiResponseSuccess(actionType.GET_LEADS, allLeads));
  } catch (error) {
    yield put(actions.crmApiResponseError(actionType.GET_LEADS, error));
  }
}

function* onUpdateOrganizations({ payload: organizations }) {
  try {
    const response = yield call(backend.updateOrganization, organizations.id, organizations);
    yield put(actions.updateOrganizationsSuccess({ organizations, ...response }));
    yield put(actions.getOrganizations());
  } catch (error) {
    yield put(actions.updateOrganizationsFail(error));
  }
}

function* onDeleteOrganizations({ payload: organizations }) {
  try {
    const response = yield call(backend.deleteOrganization, organizations.id);
    yield put(actions.deleteOrganizationsSuccess({ organizations, ...response }));
  } catch (error) {
    yield put(actions.deleteOrganizationsFail(error));
  }
}

function* onAddNewOrganizations({ payload: organizations }) {
  try {
    const response = yield call(backend.createOrganization, organizations);

    if (response.status === 400) {
      return yield put(actions.addOrganizationsFail(response));
    }

    yield put(actions.addOrganizationsSuccess({ organizations, ...response }));
    yield put(actions.getOrganizations());
  } catch (error) {
    yield put(actions.addOrganizationsFail(error));
  }
}

function* onUpdateContact({ payload: contact }) {
  try {
    const response = yield call(backend.updateContact, contact.id, contact);
    yield put(actions.updateContactSuccess({ contact, ...response }));
    yield put(actions.getContacts());
  } catch (error) {
    yield put(actions.updateContactFail(error));
  }
}

function* onDeleteContact({ payload: contact }) {
  try {
    const response = yield call(backend.deleteContact, contact);
    yield put(actions.deleteContactSuccess({ contact, ...response }));
  } catch (error) {
    yield put(actions.deleteContactFail(error));
  }
}

function* onAddNewContact({ payload: contact }) {
  try {
    const response = yield call(backend.createContacts, contact);
    yield put(actions.addContactSuccess({ contact, ...response }));
  } catch (error) {
    yield put(actions.addContactFail(error));
  }
}

function* onUpdateLead({ payload: leads }) {
  try {
    const response = yield call(backend.updateLead, leads.id, leads);
    yield put(actions.updateLeadSuccess({ leads, ...response }));
    yield put(actions.getLeads());
  } catch (error) {
    yield put(actions.updateLeadFail(error));
  }
}

function* onDeleteLead({ payload: leads }) {
  try {
    const response = yield call(backend.deleteLead, leads.id);
    yield put(actions.deleteLeadSuccess({ leads, ...response }));
  } catch (error) {
    yield put(actions.deleteLeadFail(error));
  }
}

function* onAddNewLead({ payload: leads }) {
  try {
    const response = yield call(backend.addNewLead, leads);

    if (response.status === 200) {
      yield put(actions.addLeadSuccess(response));
    } else {
      yield put(actions.addLeadFail(response));
    }
  } catch (error) {
    yield put(actions.addLeadFail(error));
  }
}

export function* watchGetContacts() {
  yield takeEvery(actionType.GET_CONTACTS, getContacts);
}

export function* watchGetOrganizations() {
  yield takeEvery(actionType.GET_ORGANIZATIONS, getOrganizations);
}

// export function* watchGetDeals() {
//   yield takeEvery(GET_DEALS, getDeals);
// }

export function* watchGetLeads() {
  yield takeEvery(actionType.GET_LEADS, getLeads);
}

export function* watchUpdateOrganizations() {
  yield takeEvery(actionType.UPDATE_ORGANIZATIONS, onUpdateOrganizations);
}

export function* watchDeleteOrganizations() {
  yield takeEvery(actionType.DELETE_ORGANIZATIONS, onDeleteOrganizations);
}

export function* watchAddNewOrganizations() {
  yield takeEvery(actionType.ADD_NEW_ORGANIZATIONS, onAddNewOrganizations);
}

export function* watchUpdateContact() {
  yield takeEvery(actionType.UPDATE_CONTACT, onUpdateContact);
}

export function* watchDeleteContact() {
  yield takeEvery(actionType.DELETE_CONTACT, onDeleteContact);
}

export function* watchAddNewContact() {
  yield takeEvery(actionType.ADD_NEW_CONTACT, onAddNewContact);
}

export function* watchUpdateLead() {
  yield takeEvery(actionType.UPDATE_LEAD, onUpdateLead);
}

export function* watchDeleteLead() {
  yield takeEvery(actionType.DELETE_LEAD, onDeleteLead);
}

export function* watchAddNewLead() {
  yield takeEvery(actionType.ADD_NEW_LEAD, onAddNewLead);
}

export function* watchGetContactByID() {
  yield takeEvery(actionType.GET_CONTACT_BY_ID, onGetContactByID);
}

function* crmSaga() {
  yield all([
    fork(watchGetContactByID),

    // fork(watchGetDeals),
    fork(watchGetLeads),
    fork(watchAddNewLead),
    fork(watchDeleteLead),
    fork(watchUpdateLead),

    fork(watchGetOrganizations),
    fork(watchDeleteOrganizations),
    fork(watchUpdateOrganizations),
    fork(watchAddNewOrganizations),

    fork(watchGetContacts),
    fork(watchDeleteContact),
    fork(watchUpdateContact),
    fork(watchAddNewContact),
  ]);
}

export default crmSaga;
