import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import {
  GET_VOLUNTEERS_LIST,
  ADD_NEW_VOLUNTEER,
  DELETE_VOLUNTEER,
  UPDATE_VOLUNTEER,
  GET_VOLUNTEER,
} from "./actionType";

import {
  VolunteersApiResponseSuccess,
  VolunteersApiResponseError,
  addVolunteerSuccess,
  addNewVolunteerSuccess,
  addVolunteerFail,
  getVolunteer as getVolunteerAction,
  getVolunteersList as getVolunteerListAction,
  updateVolunteerSuccess,
  updateVolunteerFail,
  deleteVolunteerSuccess,
  deleteVolunteerFail,
} from "./action";

import {
  getVolunteers as getVolunteersListApi,
  getVolunteer as getVolunteerApi,
  createVolunteers,
  updateVolunteer,
  deleteVolunteer,
} from "../../helpers/backend_helper";

function* getVolunteersList({ payload: { params } }) {
  try {
    const response = yield call(getVolunteersListApi, params);
    yield put(
      VolunteersApiResponseSuccess(GET_VOLUNTEERS_LIST, {
        volunteersList: response.volunteer_obj_list,
        page: { offset: response.offset, count: response.volunteers_count },
      })
    );
  } catch (error) {
    yield put(VolunteersApiResponseError(GET_VOLUNTEERS_LIST, error));
  }
}

function* getVolunteerWorker({ payload: volunteer }) {
  try {
    const response = yield call(getVolunteerApi, volunteer);
    yield put(VolunteersApiResponseSuccess(GET_VOLUNTEER, response));
  } catch (error) {
    yield put(VolunteersApiResponseError(GET_VOLUNTEER, error));
  }
}

function* addNewVolunteerWorker({ payload: volunteer }) {
  try {
    const response = yield call(createVolunteers, volunteer);
    // yield put(VolunteersApiResponseSuccess(GET_VOLUNTEERS_LIST, response));
    yield put(addNewVolunteerSuccess(response));
  } catch (error) {
    yield put(VolunteersApiResponseError(ADD_NEW_VOLUNTEER, error));
  }
}

function* updateVolunteerWorker({ payload: volunteer }) {
  try {
    // because we are sending a form data object
    const id = volunteer.get("id");
    const response = yield call(updateVolunteer, id, volunteer);
    yield put(updateVolunteerSuccess(response));
    yield put(getVolunteerListAction());
    if (response.status === 400) {
      return yield put(VolunteersApiResponseError(ADD_NEW_VOLUNTEER, response));
    }
  } catch (error) {
    yield put(updateVolunteerFail(error));
  }
}

function* deleteVolunteerWorker({ payload: volunteer }) {
  try {
    const response = yield call(deleteVolunteer, volunteer.id);
    yield put(deleteVolunteerSuccess({ volunteer, ...response }));
    return yield put(getVolunteerListAction());
  } catch (error) {
    yield put(deleteVolunteerFail(error));
  }
}

export function* watchGetVolunteer() {
  yield takeEvery(GET_VOLUNTEER, getVolunteerWorker);
}

export function* watchGetVolunteersList() {
  yield takeEvery(GET_VOLUNTEERS_LIST, getVolunteersList);
}

export function* watchAddNewVolunteer() {
  yield takeEvery(ADD_NEW_VOLUNTEER, addNewVolunteerWorker);
}

export function* watchUpdateVolunteer() {
  yield takeEvery(UPDATE_VOLUNTEER, updateVolunteerWorker);
}

export function* watchDeleteVolunteer() {
  yield takeEvery(DELETE_VOLUNTEER, deleteVolunteerWorker);
}

function* VolunteersSaga() {
  yield all([
    fork(watchGetVolunteer),
    fork(watchGetVolunteersList),
    fork(watchAddNewVolunteer),
    fork(watchUpdateVolunteer),
    fork(watchDeleteVolunteer),
  ]);
}

export default VolunteersSaga;
