import {
  GET_NOTE,
  GET_NOTES_LIST,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  UPDATE_NOTE,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_FAIL,
  DELETE_NOTE,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_FAIL,
  ADD_NEW_NOTE,
  ADD_NEW_NOTE_SUCCESS,
  ADD_NEW_NOTE_FAIL,
  RESET_NOTE_FLAG,
} from "./actionType";

const INIT_STATE = {
  notesList: null,
};

const Notes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_NOTES_LIST:
          return {
            ...state,
            notesList: action.payload.data.notes,
            page: action.payload.data.page,
            isNoteCreated: false,
            isNoteSuccess: true,
            isLoading: false,
            error: "",
          };
        case GET_NOTE:
          return {
            ...state,
            note: action.payload.data,
            isNoteSuccess: true,
            isNoteCreated: false,
            error: "",
          };
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_NOTES_LIST:
          return {
            ...state,
            isLoading: false,
          };
        case GET_NOTE:
          return {
            ...state,
            error: action.payload.error,
            isNoteCreated: false,
            isNoteSuccess: false,
            isLoading: false,
          };
        default:
          return { ...state };
      }
    case GET_NOTES_LIST: {
      return {
        ...state,
        isNoteCreated: false,
        isLoading: true,
      };
    }
    case ADD_NEW_NOTE_SUCCESS:
      return {
        ...state,
        isNoteCreated: true,
        isNoteAdd: true,
        error: "",
      };
    case ADD_NEW_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
        isNoteAdd: false,
        isNoteAddFail: true,
      };
    case UPDATE_NOTE_SUCCESS:
      return {
        ...state,
        isNoteUpdate: true,
        isNoteUpdateFail: false,
      };
    case UPDATE_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
        isNoteUpdate: false,
        isNoteUpdateFail: true,
      };
    case DELETE_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
        isNoteDelete: false,
        isNoteDeleteFail: true,
      };
    case DELETE_NOTE_SUCCESS:
      return {
        ...state,
        notesList: state.notesList.filter((note) => note.id.toString() !== action.payload.note.toString()),
        isNoteDelete: true,
        isNoteDeleteFail: false,
      };
    case RESET_NOTE_FLAG:
      return {
        ...state,
        isNoteAdd: false,
        isNoteAddFail: false,
        isNoteCreated: false,
        isNoteDelete: false,
        isNoteDeleteFail: false,
        isNoteUpdate: false,
        isNoteUpdateFail: false,
        errors: false,
        isLoading: false,
      };
    default:
      return { ...state };
  }
};

export default Notes;
