import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { handleValidDate } from "../ListView/CaseCol";

//Import Flatepicker
import Flatpickr from "react-flatpickr";
import Select from "react-select";

import { useFormik } from "formik";
import * as Yup from "yup";

import MsgToast from "../../../Components/Common/MsgToast";

// Import React FilePond
import { FilePond } from "react-filepond";
// Import FilePond styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";

//Import Images
import { useHistory, useParams } from "react-router-dom";

import TextField from "../../../Components/Common/Fields/TextField.js";
import { getTeams, getUsers } from "../../../helpers/backend_helper";
import {
  addNewCase,
  getCase as onGetCase,
  getTeamsList as onGetTeams,
  getUsersList as onGetUsers,
  resetCaseFlag,
  updateCase,
} from "../../../store/actions";

import AsyncSelect from "react-select/async";

const CreateCase = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();

  const { caseObj, isCaseAdd, isCaseAddFail, error, teamsList, casesList, usersList, isCaseUpdate, isCaseUpdateFail } =
    useSelector((state) => ({
      error: state.Cases.error,
      caseObj: state.Cases.caseObj,
      casesList: state.Cases.casesList,
      usersList: state.Users.usersList,
      teamsList: state.Teams.teamsList,
      isCaseAdd: state.Cases.isCaseAdd,
      isCaseAddFail: state.Cases.isCaseAddFail,
      isCaseUpdate: state.Cases.isCaseUpdate,
      isCaseUpdateFail: state.Cases.isCaseUpdateFail,
    }));

  const [errorObj, setErrorObj] = useState(null);
  const [assignedUser, setAssignedUser] = useState(null);
  const [assignedteam, setAssignedTeam] = useState(null);

  const [fieldError, setFieldError] = useState(false);
  const [casee, setCase] = useState(null);
  const [client, setClient] = useState(null);

  //Dropzone file upload
  const [selectedFiles, setselectedFiles] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (!teamsList) dispatch(onGetTeams());
    if (!usersList) dispatch(onGetUsers());
    if (casesList?.length) return;
    if (id) dispatch(onGetCase(id));
  }, [dispatch, id, casesList?.length, usersList, teamsList]);

  useEffect(() => {
    if (props.updateCase) {
      if (!casesList?.length && !caseObj) return;

      let case_obj = casesList?.find((c) => c.id.toString() === id);
      if (!case_obj) case_obj = caseObj && caseObj.cases_obj;

      if (!case_obj) return;
      setCase(case_obj);
      setAssignedUser(case_obj?.assigned_to);
      setAssignedTeam(case_obj?.teams);
      setClient(case_obj?.client);
    }

    if (location.state) {
      const { client } = location.state;
      setClient(client);
    }
  }, [casesList, location, id, props.updateCase, caseObj]);

  useEffect(() => {
    if (isCaseUpdate || isCaseAdd) {
      history.replace("/cases");
    }
  }, [history, isCaseUpdate, isCaseAdd]);

  useEffect(() => {
    setTimeout(() => {
      if (isCaseAdd || isCaseAddFail || error || isCaseUpdate || isCaseUpdateFail) {
        if (error) {
          setErrorObj(error.message);
        }
        dispatch(resetCaseFlag());
      }
    }, 3000);
  }, [dispatch, isCaseAdd, isCaseAddFail, error, isCaseUpdate, isCaseUpdateFail]);

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: casee?.id || id || "",
      title: casee?.title || "",
      case_type: casee?.case_type || "",
      client: (client && client.id) || "",
      created_on: casee?.created_on || "",
      due_date: casee?.due_date || "",
      status: casee?.status || "",
      priority: casee?.priority || "",
      assigned_to: casee?.assigned_to?.id || "",
      description: casee?.description || "",
      case_attachment: casee?.case_attachment || "",
      // teams: (casee && JSON.stringify(casee?.teams.map((t) => t.id))) || "",
      teams: "",
    },

    validationSchema: Yup.object({
      case_type: Yup.string().notRequired("Please enter what type of case this is"),
      title: Yup.string().required("Please Enter a Title for this Case"),
      client: Yup.string().required("Please Enter a Client for this case"),
      created_on: Yup.date().nullable().notRequired("Select a creation date for this case"),
      due_date: Yup.date().notRequired("What date should this case be closed"),
      status: Yup.string().notRequired("Please select the status for this case"),
      priority: Yup.string().notRequired("Please select a priority level for this case"),
      assigned_to: Yup.string().notRequired("Please select whom to assign this case to"),
      description: Yup.string().notRequired("Please enter a case description"),
      case_attachment: Yup.mixed(),
      teams: Yup.mixed().notRequired(),
    }),

    onSubmit: (values) => {
      const noFieldErrors = Object.keys(validation.errors).length === 0;

      if (noFieldErrors) {
        const formData = new FormData();

        for (let v in values) {
          formData.set(v, values[v]);
        }

        if (files && files.length) {
          formData.set("case_attachment", files[0].file);
        }

        if (props.updateCase) {
          dispatch(updateCase(formData));
        } else {
          dispatch(addNewCase(formData));
          // reset();
        }
      } else {
        setFieldError(true);
        setTimeout(() => {
          setFieldError(false);
        }, 3000);
      }
    },
  });

  const reset = () => {
    validation.handleReset();
    validation.resetForm();
    validation.setValues({});
  };

  const handleCaseDelete = () => {
    reset();
  };

  /**
   * Formats the size
   */
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const loadTeamsOptions = (inputValue) => {
    return new Promise((resolve) => {
      const teamsList = getTeams({ search: inputValue }).then(({ teams }) => {
        const c = teams;
        return c;
      });
      resolve(teamsList);
    });
  };

  const loadUsersOptions = (inputValue) => {
    return new Promise((resolve) => {
      const usersList = getUsers({ search: inputValue }).then(({ users }) => {
        const c = users.users;
        return c;
      });
      resolve(usersList);
    });
  };

  const NoOptionsMessage = () => {
    return <div>type to search...</div>;
  };

  document.title = "Create Case | Velzon - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={`${props.updateCase ? "Update" : "Create"} Case`} pageTitle="Cases" />
          <Form
            encType="multipart/form-data"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            // onSubmit={validation.handleSubmit}
          >
            <Row>
              <Col lg={8}>
                <Card>
                  <CardBody>
                    <Row>
                      <TextField
                        md="6"
                        mb="mb-3"
                        value={validation.values.title}
                        onChange={validation.handleChange}
                        validation={validation}
                        onBlur={validation.handleBlur}
                        placeholder="Enter Case title"
                        label="Case Title"
                        name="title"
                        errorMessage={errorObj}
                      />

                      <Col>
                        <div className="mb-3 mb-lg-0">
                          <Label htmlFor="case-type-input" className="form-label">
                            Case type
                          </Label>
                          <Select
                            data-choices
                            data-choices-search-false
                            id="choices-status-input"
                            value={{ label: validation.values.case_type, value: validation.values.case_type }}
                            name="case_type"
                            isClearable
                            // className="form-control is-invalid"
                            className={`${
                              validation.touched.case_type && validation.errors.case_type
                                ? "form-control is-invalid"
                                : ""
                            } `}
                            isMulti={false}
                            onChange={(e) => validation.setFieldValue("case_type", e?.value)}
                            options={[
                              { label: "Problem", value: "Problem" },
                              { label: "Incident", value: "Incident" },
                              { label: "Question", value: "Question" },
                            ]}
                            invalid={validation.touched.case_type && validation.errors.case_type ? true : false}
                          />

                          {validation.touched.case_type && validation.errors.case_type ? (
                            <FormFeedback type="invalid">{validation.errors.case_type}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="case-client-input">
                          Client
                        </Label>
                        <Select
                          name="client"
                          onChange={(e) => validation.setFieldValue("client", e.value)}
                          defaultValue={client}
                          key={client}
                          value={client}
                          isDisabled={(location.state && client) || props.updateCase || false}
                          id="client"
                          getOptionLabel={(client) => `${client?.first_name} ${client?.last_name}`}
                          getOptionValue={(client) => `${client?.id}`}
                          //options={clientList}
                          invalid={validation.touched.client && validation.errors.client ? true : false}
                          classNamePrefix="select2-selection form-select"
                        />
                        {validation.touched.client && validation.errors.client ? (
                          <FormFeedback type="invalid">{validation.errors.client}</FormFeedback>
                        ) : null}
                      </div>
                    </Row>

                    <div className="mb-3">
                      <Label htmlFor="description" className="form-label">
                        Case Description
                      </Label>
                      <Input
                        type="textarea"
                        name="description"
                        onChange={validation.handleChange}
                        invalid={validation.touched.description && validation.errors.description ? true : false}
                        value={validation.values.description}
                        rows="15"
                      />
                      {validation.touched.description && validation.errors.description ? (
                        <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                      ) : null}
                    </div>

                    <Row className="mb-3">
                      <Col lg={6}>
                        <div className="mb-3 mb-lg-0">
                          <Label htmlFor="choices-priority-input" className="form-label">
                            Priority
                          </Label>

                          <Select
                            data-choices
                            data-choices-search-false
                            id="choices-status-input"
                            value={{ label: validation.values.priority, value: validation.values.priority }}
                            name="priority"
                            // className="form-control is-invalid"
                            className={`${
                              validation.touched.priority && validation.errors.priority ? "form-control is-invalid" : ""
                            } `}
                            isMulti={false}
                            onChange={(e) => validation.setFieldValue("priority", e.value)}
                            options={[
                              { label: "Urgent", value: "Urgent" },
                              { label: "Normal", value: "Normal" },
                              { label: "High", value: "High" },
                              { label: "Medium", value: "Medium" },
                              { label: "Low", value: "Low" },
                            ]}
                            invalid={validation.touched.priority && validation.errors.priority ? true : false}
                          />

                          {validation.touched.priority && validation.errors.priority ? (
                            <FormFeedback type="invalid">{validation.errors.priority}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3 mb-lg-0">
                          <Label htmlFor="choices-status-input" className="form-label">
                            Status
                          </Label>
                          <Select
                            data-choices
                            data-choices-search-false
                            className={`${
                              validation.touched.status && validation.errors.status ? "form-control is-invalid" : ""
                            } `}
                            id="choices-status-input"
                            value={{ label: validation.values.status, value: validation.values.status }}
                            name="status"
                            isMulti={false}
                            onChange={(e) => validation.setFieldValue("status", e.value)}
                            options={[
                              { label: "Open", value: "Open" },
                              { label: "Pending", value: "Pending" },
                              { label: "Closed", value: "Closed" },
                            ]}
                          />
                          {validation.touched.status && validation.errors.status ? (
                            <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6}>
                        {id ? (
                          <div>
                            <Label htmlFor="created_on" className="form-label">
                              Create Date
                            </Label>
                            <Flatpickr
                              name="created_on"
                              id="date-field"
                              disabled
                              //style={{ cursor: "disabled", backgroundColor: "red", borderWidth: "0px" }}
                              //className={`${
                              //validation.touched.created_on && validation.errors.created_on
                              //? "form-control is-invalid"
                              //: "form-control"
                              //} `}
                              placeholder="Select a date"
                              options={{
                                altInput: true,
                                altFormat: "Y-m-d",
                                dateFormat: "Y-m-d",
                                // dateFormat: "D M, Y",
                              }}
                              // onChange={(e) => handleValidDate(e)}
                              onChange={(e) => validation.setFieldValue("created_on", handleValidDate(e))}
                              value={validation.values.created_on || ""}
                            />

                            {validation.touched.created_on && validation.errors.created_on ? (
                              <FormFeedback type="invalid">{validation.errors.created_on}</FormFeedback>
                            ) : null}
                          </div>
                        ) : null}
                      </Col>
                      <Col lg={6}>
                        <div>
                          <Label htmlFor="due_date" className="form-label">
                            Close Date
                          </Label>

                          <Flatpickr
                            name="due_date"
                            id="date-field"
                            className={`${
                              validation.touched.due_date && validation.errors.due_date
                                ? "form-control is-invalid"
                                : "form-control"
                            } `}
                            placeholder="Select a date"
                            options={{
                              altInput: true,
                              altFormat: "Y-m-d",
                              dateFormat: "Y-m-d",
                              // dateFormat: "D M, Y",
                            }}
                            onChange={(e) => validation.setFieldValue("due_date", handleValidDate(e))}
                            // onChange={(e) => handleValidDate(e)}
                            value={validation.values.due_date || ""}
                          />
                          {validation.touched.due_date && validation.errors.due_date ? (
                            <FormFeedback type="invalid">{validation.errors.due_date}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Attached files</h5>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <p className="text-muted">Add Attached files here.</p>
                      <FilePond
                        files={files}
                        onupdatefiles={setFiles}
                        allowMultiple={true}
                        maxFiles={1}
                        name="case_attachment"
                        value={validation.values.case_attachment}
                        className="filepond filepond-input-multiple"
                      />
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <button type="reset" onClick={handleCaseDelete} className="btn btn-danger w-sm me-1">
                    Delete
                  </button>
                  <button type="submit" /*onClick={handleCaseDraft}*/ className="btn btn-secondary w-sm me-1">
                    Draft
                  </button>
                  <button type="submit" className="btn btn-success w-sm">
                    {props.updateCase ? "Update Case" : "Create Case"}
                  </button>
                </div>
              </Col>

              <Col lg={4}>
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title mb-0">Teams</h5>
                  </div>
                  <CardBody>
                    {/* <div>
                      <Label htmlFor="choices-text-input" className="form-label">
                        Teams
                      </Label>
                      <Select
                        className={`${
                          validation.touched.teams && validation.errors.teams ? "form-control is-invalid" : ""
                        } `}
                        name="teams"
                        defaultValue={validation.values.teams}
                        id="choices-text-input"
                        isMulti={true}
                        onChange={(e) => validation.setFieldValue("teams", JSON.stringify(e.map((e) => e.value)))}
                        options={teamsOptions}
                      />
                      {validation.touched.teams && validation.errors.teams ? (
                        <FormFeedback type="invalid">{validation.errors.teams}</FormFeedback>
                      ) : null}
                    </div> */}

                    <Col md={12}>
                      <div className="mb-3">
                        <AsyncSelect
                          className={validation.touched.teams && validation.errors.teams && "form-control is-invalid"}
                          cacheOptions
                          id="teams-input"
                          onBlur={validation.handleBlur}
                          name="teams"
                          placeholder="Assign case to a team..."
                          isMulti={false}
                          isClearable
                          isSearchable
                          loadOptions={loadTeamsOptions}
                          value={assignedteam}
                          noOptionsMessage={NoOptionsMessage}
                          defaultOptions={teamsList}
                          getOptionLabel={(option) => `${option?.name}`}
                          getOptionValue={(option) => option?.id}
                          onChange={(e) => {
                            // validation.setFieldValue("teams", e?.id);
                            setAssignedTeam(e);
                            validation.setFieldValue("teams", e ? `[${e?.id}]` : "[]");
                          }}
                        />
                        {validation.touched.teams && validation.errors.teams ? (
                          <FormFeedback type="invalid">{validation.errors.teams}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </CardBody>
                </div>

                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Assign To</h5>
                  </CardHeader>

                  <CardBody>
                    <Col md={12}>
                      <div className="mb-3">
                        <AsyncSelect
                          className={
                            validation.touched.assigned_to && validation.errors.assigned_to && "form-control is-invalid"
                          }
                          cacheOptions
                          id="assigned_to-input"
                          onBlur={validation.handleBlur}
                          name="assigned_to"
                          placeholder="Assign case to a user"
                          isMulti={false}
                          isClearable
                          isSearchable
                          loadOptions={loadUsersOptions}
                          value={assignedUser}
                          noOptionsMessage={NoOptionsMessage}
                          defaultOptions={usersList}
                          getOptionLabel={(option) =>
                            `${option?.user_details?.first_name} ${option?.user_details?.last_name}`
                          }
                          getOptionValue={(option) => option?.id}
                          onChange={(e) => {
                            setAssignedUser(e);
                            validation.setFieldValue("assigned_to", e?.id);
                          }}
                        />
                        {validation.touched.assigned_to && validation.errors.assigned_to ? (
                          <FormFeedback type="invalid">{validation.errors.assigned_to}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    {/* <div className="mb-3">
                      <Label htmlFor="choices-lead-input" className="form-label">
                        Members
                      </Label>
                      <Select
                        className={`${
                          validation.touched.assigned_to && validation.errors.assigned_to
                            ? "form-control is-invalid"
                            : ""
                        } `}
                        id="choices-team-input"
                        defaultValue={assignedUserLabelState}
                        key={assignedUserLabelState}
                        name="assigned_to"
                        // isMulti={true}
                        onChange={(e) => validation.setFieldValue("assigned_to", e.value)}
                        options={userLabels}
                      />
                      {validation.touched.assigned_to && validation.errors.assigned_to ? (
                        <FormFeedback type="invalid">{validation.errors.assigned_to}</FormFeedback>
                      ) : null}
                    </div> */}

                    {isCaseAdd ? (
                      <MsgToast msg="Case Added Successfully" color="success" icon="ri-checkbox-circle-line" />
                    ) : null}
                    {isCaseAddFail ? (
                      <MsgToast
                        msg={error && error.error ? error.message.title[0] : ""}
                        color="danger"
                        icon="ri-error-warning-line"
                      />
                    ) : null}
                    {fieldError ? (
                      <MsgToast msg="Dont forget the required fields" color="danger" icon="ri-error-warning-line" />
                    ) : null}

                    {isCaseUpdate ? (
                      <MsgToast msg="Case Updated Successfully" color="success" icon="ri-checkbox-circle-line" />
                    ) : null}
                    {isCaseUpdateFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
                    {/*{isCaseDelete ? <MsgToast msg="Case Deleted Successfully" color="success" icon="ri-checkbox-circle-line" /> : null}
                       {isCaseDeleteFail ? <MsgToast msg="Case Deleted Failed" color="danger" icon="ri-error-warning-line" /> : null}
                       {isCaseUpdate ? <MsgToast msg="Case Updated Successfully" color="success" icon="ri-checkbox-circle-line" /> : null}
                       {isCaseUpdateFail ? <MsgToast msg="Case Updated Failed" color="danger" icon="ri-error-warning-line" /> : null}*/}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateCase;
