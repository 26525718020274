import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_CASES_LIST, ADD_NEW_CASE, DELETE_CASE, UPDATE_CASE, GET_CASE } from "./actionType";
import {
  CasesApiResponseSuccess,
  CasesApiResponseError,
  addCaseSuccess,
  addCaseFail,
  getCasesList as getCasesListAction,
  updateCaseSuccess,
  updateCaseFail,
  deleteCaseSuccess,
  deleteCaseFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getCases as getCasesListApi,
  getCase as getCaseApi,
  addCase,
  addCases,
  updateCase,
  deleteCase,
} from "../../helpers/backend_helper";

function* getCasesList({ payload: { params } }) {
  try {
    const response = yield call(getCasesListApi, params);
    const { cases, stats, monthly_cases } = response;

    yield put(
      CasesApiResponseSuccess(GET_CASES_LIST, {
        cases,
        stats,
        monthly_cases,
        page: { offset: response.offset, count: response.cases_count },
      })
    );
  } catch (error) {
    yield put(CasesApiResponseError(GET_CASES_LIST, error));
  }
}

function* onGetCase({ payload: MyCase }) {
  try {
    const response = yield call(getCaseApi, MyCase);
    yield put(CasesApiResponseSuccess(GET_CASE, response));
  } catch (error) {
    yield put(CasesApiResponseError(GET_CASE, error));
  }
}

//
function* onAddNewCase({ payload: MyCase }) {
  try {
    const response = yield call(addCases, MyCase);

    if (response.error) {
      if (response.status === 400) {
        yield put(addCaseFail(response));
      }
    } else {
      if (response.status === 200) {
        yield put(addCaseSuccess(response));
        yield put(getCasesListAction());
      }
    }
  } catch (error) {
    yield put(addCaseFail(error));
  }
}

//

// function* onAddNewCase({ payload: MyCase }) {

//   try {
//     const response = yield call(addCases, MyCase);

//     yield put(addCaseSuccess(response));
//     yield put(getCasesListAction());
//   } catch (error) {
//     yield put(addCaseFail(error));
//   }
// }

//

function* onUpdateCase({ payload: MyCase }) {
  try {
    const response = yield call(updateCase, MyCase.get("id"), MyCase);
    yield put(updateCaseSuccess(response));
    yield put(getCasesListAction());
  } catch (error) {
    yield put(updateCaseFail(error));
  }
}

function* onDeleteCase({ payload: MyCase }) {
  try {
    const response = yield call(deleteCase, MyCase.id);
    yield put(deleteCaseSuccess({ MyCase, ...response }));
    yield put(getCasesListAction());
  } catch (error) {
    yield put(deleteCaseFail(error));
  }
}

export function* watchGetCase() {
  yield takeEvery(GET_CASE, onGetCase);
}

export function* watchGetCasesList() {
  yield takeEvery(GET_CASES_LIST, getCasesList);
}

export function* watchAddNewCase() {
  yield takeEvery(ADD_NEW_CASE, onAddNewCase);
}

export function* watchUpdateCase() {
  yield takeEvery(UPDATE_CASE, onUpdateCase);
}

export function* watchDeleteCase() {
  yield takeEvery(DELETE_CASE, onDeleteCase);
}

function* casesSaga() {
  yield all([
    fork(watchGetCase),
    fork(watchGetCasesList),
    fork(watchAddNewCase),
    fork(watchUpdateCase),
    fork(watchDeleteCase),
  ]);
}

export default casesSaga;
