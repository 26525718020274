import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_CLIENTS_LIST, ADD_NEW_CLIENT, DELETE_CLIENT, UPDATE_CLIENT, GET_CLIENT, CREATE_CLIENT_COMMENT, GET_COMMENT_LIST} from "./actionType";

import {
  ClientsApiResponseSuccess,
  ClientsApiResponseError,
  addClientSuccess,
  addNewClientSuccess,
  addClientFail,
  getClient as getClientAction,
  getClientsList as getClientListAction,
  updateClientSuccess,
  updateClientFail,
  deleteClientSuccess,
  deleteClientFail,
  createClientCommentSuccess,
  createClientCommentFail,
  getCommentList as getCommentListAction
} from "./action";

import {
  getClients as getClientsListApi,
  getClient as getClientApi,
  createClients,
  updateClient,
  deleteClient,
  createClientComment
} from "../../helpers/backend_helper";

function* getClientsList({ payload: { params } }) {
  try {
    const response = yield call(getClientsListApi, params);
    const { client_obj_list } = response;
    yield put(
      ClientsApiResponseSuccess(GET_CLIENTS_LIST, {
        client_obj_list,
        page: { offset: response.offset, count: response.clients_count },
      })
    );
  } catch (error) {
    yield put(ClientsApiResponseError(GET_CLIENTS_LIST, error));
  }
}

function* getClientWorker({ payload: client }) {
  try {
    const response = yield call(getClientApi, client);
    yield put(ClientsApiResponseSuccess(GET_CLIENT, response));
  } catch (error) {
    yield put(ClientsApiResponseError(GET_CLIENT, error));
  }
}

function* addNewClientWorker({ payload: client }) {
  try {
    const response = yield call(createClients, client);
    // yield put(ClientsApiResponseSuccess(GET_CLIENTS_LIST, response));

    if (response.status === 200) {
      yield put(addNewClientSuccess(response));
      return yield put(getClientListAction());
    }
    if (response.status === 400) {
      return yield put(ClientsApiResponseError(ADD_NEW_CLIENT, response));
    }
  } catch (error) {
    yield put(ClientsApiResponseError(GET_CLIENTS_LIST, error));
  }
}

function* updateClientWorker({ payload: client }) {
  try {
    const response = yield call(updateClient, client.id, client);

    if (response.status === 200) {
      yield put(updateClientSuccess(response));
      return yield put(getClientListAction());
    }
    if (response.status === 400) {
      return yield put(ClientsApiResponseError(ADD_NEW_CLIENT, response));
    }
  } catch (error) {
    yield put(updateClientFail(error));
  }
}

function* deleteClientWorker({ payload: client }) {
  try {
    const response = yield call(deleteClient, client.id);
    yield put(deleteClientSuccess({ client, ...response }));
    return yield put(getClientListAction());
  } catch (error) {
    yield put(deleteClientFail(error));
  }
}

function* createClientCommentWorker({ payload: { clientId, comment } }) {
  try {
    const response = yield call(createClientComment, clientId, comment);
    const {comments} = response
    yield put(createClientCommentSuccess({...response }));
    yield put(getClientListAction());
  } catch (error) {
    yield put(createClientCommentFail(error));
  }
}


export function* watchGetClient() {
  yield takeEvery(GET_CLIENT, getClientWorker);
}

export function* watchGetClientsList() {
  yield takeEvery(GET_CLIENTS_LIST, getClientsList);
}

export function* watchAddNewClient() {
  yield takeEvery(ADD_NEW_CLIENT, addNewClientWorker);
}

export function* watchUpdateClient() {
  yield takeEvery(UPDATE_CLIENT, updateClientWorker);
}

export function* watchDeleteClient() {
  yield takeEvery(DELETE_CLIENT, deleteClientWorker);
}

export function* watchCreateClientComment() {
  yield takeEvery(CREATE_CLIENT_COMMENT, createClientCommentWorker);
}

function* ClientsSaga() {
  yield all([
    fork(watchGetClient),
    fork(watchGetClientsList),
    fork(watchAddNewClient),
    fork(watchUpdateClient),
    fork(watchDeleteClient),
    fork(watchCreateClientComment)
  ]);
}

export default ClientsSaga;
