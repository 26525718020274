import React from "react";

import CreateDonationForm from "./FormComponents/CreateDonationForm";

const UpdateDonation = () => {
  document.title = "Update Donation | Velzon - React Admin & Dashboard Template";

  return <CreateDonationForm updateDonation title="Update donation" />;
};

export default UpdateDonation;
