import {
  CREATE_REPORT,
  CREATE_REPORT_ERROR,
  CREATE_REPORT_SUCCESS,
  DELETE_REPORT,
  DELETE_REPORT_ERROR,
  DELETE_REPORT_SUCCESS,
  EXPORT_REPORT,
  EXPORT_REPORT_ERROR,
  EXPORT_REPORT_SUCCESS,
  GET_REPORTS_LIST,
  GET_REPORTS_LIST_ERROR,
  GET_REPORTS_LIST_SUCCESS,
  RESET_REPORT_FLAGS,
  RUN_REPORTS,
  RUN_REPORTS_ERROR,
  RUN_REPORTS_SUCCESS,
  UPDATE_REPORT,
  UPDATE_REPORT_ERROR,
  UPDATE_REPORT_SUCCESS,
} from "./actionType";

export const runReport = (report) => ({
  type: RUN_REPORTS,
  payload: report,
});

export const runReportSuccess = (report) => ({
  type: RUN_REPORTS_SUCCESS,
  payload: report,
});

export const runReportError = (error) => ({
  type: RUN_REPORTS_ERROR,
  payload: error,
});

export const createReport = (report) => ({
  type: CREATE_REPORT,
  payload: report,
});

export const createReportError = (error) => ({
  type: CREATE_REPORT_ERROR,
  payload: error,
});

export const createReportSuccess = (report) => ({
  type: CREATE_REPORT_SUCCESS,
  payload: report,
});

export const getReportList = (params) => ({
  type: GET_REPORTS_LIST,
  payload: params,
});

export const getReportListSuccess = (payload) => ({
  type: GET_REPORTS_LIST_SUCCESS,
  payload,
});

export const getReportListError = (error) => ({
  type: GET_REPORTS_LIST_ERROR,
  payload: error,
});

export const updateReport = (report) => ({
  type: UPDATE_REPORT,
  payload: report,
});

export const updateReportError = (error) => ({
  type: UPDATE_REPORT_ERROR,
  payload: error,
});

export const updateReportSuccess = (report) => ({
  type: UPDATE_REPORT_SUCCESS,
  payload: report,
});

export const deleteReport = (report) => ({
  type: DELETE_REPORT,
  payload: report,
});

export const deleteReportError = (error) => ({
  type: DELETE_REPORT_ERROR,
  payload: error,
});

export const deleteReportSuccess = (report) => ({
  type: DELETE_REPORT_SUCCESS,
  payload: report,
});

export const exportReport = (report) => ({
  type: EXPORT_REPORT,
  payload: report,
});

export const exportReportSuccess = (report) => ({
  type: EXPORT_REPORT_SUCCESS,
  payload: report,
});

export const exportReportError = (error) => ({
  type: EXPORT_REPORT_ERROR,
  payload: error,
});

export const resetReportFlags = () => ({
  type: RESET_REPORT_FLAGS,
});
