import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
import { registerPlugin } from "react-filepond";
import { Document, Page, pdfjs } from "react-pdf";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Button, Col, Input, Row } from "reactstrap";

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export default function PdfPreview(props) {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [file, setFile] = useState(null);
  const [name, setName] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (props.document) {
      let parts = props.document.split("/");
      let name = parts[parts.length - 1];
      setFile(props.document);
      setName(name);
    }
  }, [props.document]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => setPageNumber((prevPageNumber) => prevPageNumber + offset);
  const previousPage = () => changePage(-1);
  const nextPage = () => changePage(1);

  return (
    <div>
      {file && (
        <div className="cursor-pointer btn btn-soft-success w-full w-100" onClick={() => setShowModal(true)}>
          Preview Resume
          {/* 
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
          <Page className="w-50" scale={0.525} pageNumber={1} />
        </Document>
        */}
        </div>
      )}
      {/* */}
      <CModal
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        centered
        className="border-0 bg-transparent"
        modalClassName="modal fade zoomIn bg-transparent"
      >
        {/*<Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
          <Page pageNumber={pageNumber} />
        </Document>*/}

        <DocViewer
          documents={[
            {
              uri: file,
              fileName: name,
            },
          ]}
          prefetchMethod="GET"
          initialActiveDocument={{
            uri: file,
            fileName: name,
          }}
          pluginRenderers={DocViewerRenderers}
        />
        {/*<CModalFooter /> */}
      </CModal>
    </div>
  );
}

function CModal({ isOpen, toggle, children }) {
  const OVERLAY_STYLES = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 100000000,
  };

  const MODAL_STYLES = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    paddingX: "50px",
    paddingY: "10px",
    backgroundColor: "#fff",
    overflow: "scroll",
    zIndex: 10000000000,
    height: "99%",
    width: "75%",
  };

  //useEffect(() => {
  //document.body.scroll = "no";
  //return () => {
  //document.documentElement.style.overflow = "scroll";
  //document.body.scroll = "yes";
  //};
  //}, []);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <div style={OVERLAY_STYLES} onClick={toggle}></div>
      <div style={MODAL_STYLES}>{children}</div>
    </>,
    document.getElementById("portal")
  );
}

function CModalFooter({ setShowModal, showModal, file, numPages, previousPage, nextPage, pageNumber }) {
  return (
    <Row className="align-items-center p-2 w-full">
      <Col className="me-auto col-md-auto">
        <button onClick={() => setShowModal(!showModal)} className=" btn btn-soft-danger add-btn">
          <i className="ri ri-close-fill me-1 align-bottom"></i>Close
        </button>
      </Col>
      <Col className="me-auto col-md-auto">
        <div className="d-flex gap-1">
          <a
            href={file}
            target="_blank"
            rel="noreferrer"
            download={() => {
              let parts = file.split("/");
              let file_name = parts[parts.length - 1];
              return file_name;
            }}
          >
            <Button color="primary cursor-pointer btn-soft-success">
              <i className="ri ri-download-line me-1 align-bottom"></i>Download
            </Button>
          </a>
        </div>
      </Col>

      <Col className="col-md-auto">
        <div className="d-flex gap-1">
          <Button color="primary cursor-pointer" onClick={previousPage} disabled={pageNumber <= 1}>
            {"<"}
          </Button>
        </div>
      </Col>

      <Col className="col-md-auto d-none d-md-block">
        <strong>
          <span className="mx-2">
            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </span>
        </strong>
      </Col>

      <Col className="col-md-auto">
        <Input type="number" min={1} style={{ width: 70 }} max={numPages} defaultValue={pageNumber} onChange={null} />
      </Col>

      <Col className="col-md-auto">
        <div className="d-flex gap-1">
          <Button color="primary cursor-pointer" onClick={nextPage} disabled={pageNumber >= numPages}>
            {">"}
          </Button>
        </div>
      </Col>
    </Row>
  );
}
