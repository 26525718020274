import {
  GET_VOLUNTEERS_LIST,
  GET_VOLUNTEER,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  ADD_NEW_VOLUNTEER_SUCCESS,
  ADD_NEW_VOLUNTEER_FAIL,
  ADD_NEW_VOLUNTEER,
  UPDATE_VOLUNTEER,
  UPDATE_VOLUNTEER_SUCCESS,
  UPDATE_VOLUNTEER_FAIL,
  DELETE_VOLUNTEER,
  DELETE_VOLUNTEER_FAIL,
  DELETE_VOLUNTEER_SUCCESS,
  RESET_VOLUNTEER_FLAG,
} from "./actionType";

export const VolunteersApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const VolunteersApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getVolunteersList = (params) => ({
  type: GET_VOLUNTEERS_LIST,
  payload: { params },
});

export const getVolunteer = (volunteer) => ({
  type: GET_VOLUNTEER,
  payload: volunteer,
});

export const updateVolunteer = (volunteer) => ({
  type: UPDATE_VOLUNTEER,
  payload: volunteer,
});

export const updateVolunteerSuccess = (volunteer) => ({
  type: UPDATE_VOLUNTEER_SUCCESS,
  payload: volunteer,
});

export const updateVolunteerFail = (volunteer) => ({
  type: UPDATE_VOLUNTEER_FAIL,
  payload: volunteer,
});

export const addNewVolunteer = (volunteer) => ({
  type: ADD_NEW_VOLUNTEER,
  payload: volunteer,
});

export const addNewVolunteerSuccess = (volunteer) => ({
  type: ADD_NEW_VOLUNTEER_SUCCESS,
  payload: volunteer,
});

export const addNewVolunteerFail = (error) => ({
  type: ADD_NEW_VOLUNTEER_FAIL,
  payload: error,
});

export const deleteVolunteer = (volunteer) => ({
  type: DELETE_VOLUNTEER,
  payload: volunteer,
});

export const deleteVolunteerSuccess = (volunteer) => ({
  type: DELETE_VOLUNTEER_SUCCESS,
  payload: volunteer,
});

export const deleteVolunteerFail = (error) => ({
  type: DELETE_VOLUNTEER_FAIL,
  payload: error,
});

export const resetVolunteerFlag = () => ({
  type: RESET_VOLUNTEER_FLAG,
});
