import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody } from "reactstrap";

const AlertModal = ({
  show,
  alertTitle,
  alertMessage,
  denyText,
  onDenyClick,
  acceptText,
  onAcceptClick,
  closeText,
  onCloseClick,
}) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <lord-icon
            src="https://cdn.lordicon.com/gsqxdxog.json"
            trigger="loop"
            colors="primary:#f7b84b,secondary:#f06548"
            style={{ width: "100px", height: "100px" }}
          ></lord-icon>
          <div className="mt-4 pt-2 fs-15">
            <h4>{alertTitle || "Are you sure ?"}</h4>
            <p className="text-muted mx-4 mb-0">{alertMessage || "Are you sure you want to remove this record ?"}</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button type="button" className="btn w-sm btn-light" data-bs-dismiss="modal" onClick={onCloseClick}>
            {closeText || "Close"}
          </button>
          <button type="button" className="btn w-sm btn-danger " id="delete-record" onClick={onAcceptClick}>
            {acceptText || "Yes, Go Ahead!"}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

AlertModal.propTypes = {
  show: PropTypes.any,
  alertTitle: PropTypes.string,
  alertMessage: PropTypes.string,
  denyText: PropTypes.string,
  onDenyClick: PropTypes.func,
  acceptText: PropTypes.string,
  onAcceptClick: PropTypes.func,
  closeText: PropTypes.string,
  onCloseClick: PropTypes.func,
};

export default AlertModal;
