// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_CONTACTS = "GET_CONTACTS";
export const REMOVE_CONTACT_FROM_STORE = "REMOVE_CONTACT_FROM_STORE";

export const GET_CONTACT_BY_ID = "GET_CONTACT_BY_ID";
export const GET_ORGANIZATIONS = "GET_ORGANIZATIONS";
export const GET_DEALS = "GET_DEALS";
export const GET_LEADS = "GET_LEADS";
export const GET_USERS = "GET_USERS";

// Add ORGANIZATIONS
export const ADD_NEW_ORGANIZATIONS = "ADD_NEW_ORGANIZATIONS";
export const ADD_ORGANIZATIONS_SUCCESS = "ADD_ORGANIZATIONS_SUCCESS";
export const ADD_ORGANIZATIONS_FAIL = "ADD_ORGANIZATIONS_FAIL";

// Edit ORGANIZATIONS
export const UPDATE_ORGANIZATIONS = "UPDATE_ORGANIZATIONS";
export const UPDATE_ORGANIZATIONS_SUCCESS = "UPDATE_ORGANIZATIONS_SUCCESS";
export const UPDATE_ORGANIZATIONS_FAIL = "UPDATE_ORGANIZATIONS_FAIL";

// Delete ORGANIZATIONS
export const DELETE_ORGANIZATIONS = "DELETE_ORGANIZATIONS";
export const DELETE_ORGANIZATIONS_SUCCESS = "DELETE_ORGANIZATIONS_SUCCESS";
export const DELETE_ORGANIZATIONS_FAIL = "DELETE_ORGANIZATIONS_FAIL";

// Add CONTACT
export const ADD_NEW_CONTACT = "ADD_NEW_CONTACT";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const ADD_CONTACT_FAIL = "ADD_CONTACT_FAIL";

// Edit CONTACT
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_FAIL = "UPDATE_CONTACT_FAIL";

// Delete CONTACT
export const DELETE_CONTACT = "DELETE_CONTACT";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const DELETE_CONTACT_FAIL = "DELETE_CONTACT_FAIL";

// Add LEAD
export const ADD_NEW_LEAD = "ADD_NEW_LEAD";
export const ADD_LEAD_SUCCESS = "ADD_LEAD_SUCCESS";
export const ADD_LEAD_FAIL = "ADD_LEAD_FAIL";

// Edit LEAD
export const UPDATE_LEAD = "UPDATE_LEAD";
export const UPDATE_LEAD_SUCCESS = "UPDATE_LEAD_SUCCESS";
export const UPDATE_LEAD_FAIL = "UPDATE_LEAD_FAIL";

// Delete LEAD
export const DELETE_LEAD = "DELETE_LEAD";
export const DELETE_LEAD_SUCCESS = "DELETE_LEAD_SUCCESS";
export const DELETE_LEAD_FAIL = "DELETE_LEAD_FAIL";

// Add USER
export const ADD_NEW_USER = "ADD_NEW_USER";
export const ADD_NEW_USER_FAIL = "ADD_NEW_USER_FAIL";
export const ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS";

// Edit USER
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

// Delete USER
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

export const RESET_CRM_FLAG = "RESET_CRM_FLAG";
