import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Account from "./auth/account/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import Organization from "./auth/organization/reducer";

// Client
import Clients from "./clients/reducer";

// Membership
import Membership from "./membership/reducer";

// Volunteer
import Volunteers from "./volunteers/reducer";

// Campaigns
import Campaigns from "./campaigns/reducer";

// Reports
import Reporting from "./reporting/reducer";

// Users
import Users from "./users/reducer";

//Calendar
import Calendar from "./calendar/reducer";

//Chat
import chat from "./chat/reducer";

// Tasks
import Tasks from "./tasks/reducer";

//Crypto
import Crypto from "./crypto/reducer";

//Crm
import Crm from "./crm/reducer";

//Mailbox
import Mailbox from "./mailbox/reducer";

//CasesList
import Cases from "./cases/reducer";

// Teams
import Teams from "./teams/reducer";

import Notes from "./notes/reducer";
// Services
import Services from "./services/reducer";

// Services
import Donations from "./donations/reducer";

// Billing
import Billing from "./billing/reducer";

// Statistics
import Statistics from "./statistics/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Account,
  ForgetPassword,
  Organization,
  Profile,
  Calendar,
  chat,
  Tasks,
  //changeNumber,
  Crypto,
  Crm,
  Membership,
  Mailbox,
  Cases,
  Users,
  Clients,
  Volunteers,
  // Reports,
  Reporting,
  // Org,
  Teams,
  Notes,
  Services,
  Donations,
  Billing,
  Campaigns,
  Statistics,
});

export default rootReducer;
