import { all, call, fork, put, takeEvery } from "redux-saga/effects";

//Account Redux states
import { createOrgAddress, postOrganisations, updateOrg, updateOrgAddress } from "../../../helpers/backend_helper";
import {
  createOrginzationError,
  createOrginzationFailed,
  createOrginzationSuccessful,
  updateOrgAddressSuccessful,
  updateOrginzationError,
  updateOrgSuccessful,
} from "./actions";
import {
  CREATE_ORGANIZAION,
  CREATE_ORGANIZAION_ADDRESS,
  UPDATE_ORGANIZAION,
  UPDATE_ORGANIZAION_ADDRESS,
} from "./actionTypes";

// Is user register successfull then direct plot user in redux.
function* createOrganisations({ payload: { data } }) {
  try {
    const response = yield call(postOrganisations, data);

    if (response.error) {
      if (response.status === 400) {
        yield put(createOrginzationFailed(response));
      }

      if (response.status === 500) {
        yield put(createOrginzationError(response));
      }
    } else {
      if (response.status === 200) {
        yield put(createOrginzationSuccessful(response));
      }
    }
  } catch (error) {
    yield put(createOrginzationError(error));
  }
}

function* updateOrgWorker({ payload: { data } }) {
  try {
    if (process.env.REACT_APP_API_URL) {
      const response = yield call(updateOrg, data.id, data);

      if (response.error) {
        if (response.status === 400) {
          yield put(updateOrginzationError(response));
        }

        if (response.status === 500) {
          yield put(updateOrginzationError(response));
        }
      } else {
        if (response.status === 200) {
          yield put(updateOrgSuccessful(response));
        }
      }
    }
  } catch (error) {
    // yield put(createOrginzationError(error));
  }
}

function* updateOrgAddressWorker({ payload: { data } }) {
  try {
    if (process.env.REACT_APP_API_URL) {
      const response = yield call(updateOrgAddress, data.id, data);

      if (response.error) {
        if (response.status === 400) {
          yield put(createOrginzationFailed(response));
        }

        if (response.status === 500) {
          yield put(createOrginzationError(response));
        }
      } else {
        if (response.status === 200) {
          yield put(updateOrgAddressSuccessful(response));
        }
      }
    }
  } catch (error) {
    // yield put(createOrginzationError(error));
  }
}

function* createOrgAddressWorker({ payload: { data } }) {
  try {
    const response = yield call(createOrgAddress, data);

    if (response.error) {
      if (response.status === 400) {
        yield put(createOrginzationFailed(response));
      }

      if (response.status === 500) {
        yield put(createOrginzationError(response));
      }
    } else {
      if (response.status === 200) {
        yield put(createOrginzationSuccessful(response));
      }
    }
  } catch (error) {
    // yield put(createOrginzationError(error));
  }
}

export function* watchcreateOrganisations() {
  yield takeEvery(CREATE_ORGANIZAION, createOrganisations);
}
export function* watchUpdateOrgAddress() {
  yield takeEvery(UPDATE_ORGANIZAION_ADDRESS, updateOrgAddressWorker);
}
export function* watchCreateOrgsOrganisations() {
  yield takeEvery(CREATE_ORGANIZAION_ADDRESS, createOrgAddressWorker);
}

export function* watchUpdateOrgs() {
  yield takeEvery(UPDATE_ORGANIZAION, updateOrgWorker);
}
function* createOrganisationsSaga() {
  yield all([
    fork(watchcreateOrganisations),
    fork(watchUpdateOrgAddress),
    fork(watchCreateOrgsOrganisations),
    fork(watchUpdateOrgs),
  ]);
}

export default createOrganisationsSaga;
