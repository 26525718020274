import React from "react";
import CreateVolunteerForm from "../FormComponent/index";

const CreateCase = () => {
  document.title = "Create Volunteer | Velzon - React Admin & Dashboard Template";

  return <CreateVolunteerForm title="Create Volunteer" createVolunteer />;
};

export default CreateCase;
