import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Offcanvas,
  OffcanvasBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import Img9 from "../../assets/images/small/img-9.jpg";
import dummyImage from "../../assets/images/users/user-dummy-img.jpg";
import { attachements } from "../../common/data/chat";

const Attachements = (props) => {
  return (
    <div className="border rounded border-dashed p-2">
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0 me-3">
          <div className="avatar-xs">
            <div className="avatar-title bg-light text-secondary rounded fs-20">
              <i className={props.attachement.foldericon}></i>
            </div>
          </div>
        </div>
        <div className="flex-grow-1 overflow-hidden">
          <h5 className="fs-13 mb-1">
            <Link to="#" className="text-body text-truncate d-block">
              {props.attachement.foldername}
            </Link>
          </h5>
          <div className="text-muted">{props.attachement.foldersize}</div>
        </div>
        <div className="flex-shrink-0 ms-2">
          <div className="d-flex gap-1">
            <button type="button" className="btn btn-icon text-muted btn-sm fs-18">
              <i className="ri-download-2-line"></i>
            </button>
            <UncontrolledDropdown>
              <DropdownToggle tag="button" className="btn btn-icon text-muted btn-sm fs-18 dropdown">
                <i className="ri-more-fill"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <i className="ri-share-line align-bottom me-2 text-muted"></i> Share
                </DropdownItem>
                <DropdownItem>
                  <i className="ri-bookmark-line align-bottom me-2 text-muted"></i> Bookmark
                </DropdownItem>
                <DropdownItem>
                  <i className="ri-delete-bin-line align-bottom me-2 text-muted"></i> Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

const PersonalInfo = ({ chat, receiver, show, onCloseClick, currentuser, cuurentiseImg }) => {
  const [menu1, setMenu1] = useState(false);
  const [menu2, setMenu2] = useState(false);

  return (
    <Offcanvas isOpen={show} direction="end" className="offcanvas-end border-0" toggle={onCloseClick}>
      <OffcanvasBody className="offcanvas-body profile-offcanvas p-0">
        <div className="team-cover">
          <img src={Img9} alt="" className="img-fluid" />
        </div>
        <div className="p-1 pb-4 pt-0">
          <div className="team-settings">
            <div className="row g-0">
              <div className="col">
                <div className="btn nav-btn">
                  <Button onClick={onCloseClick} color="" className="btn-close btn-close-white"></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-3 text-center">
          {receiver?.profile_pic ? (
            <img src={receiver?.profile_pic} alt="" className="avatar-lg img-thumbnail rounded-circle mx-auto" />
          ) : (
            <img src={dummyImage} alt="" className="avatar-lg img-thumbnail rounded-circle mx-auto" />
          )}
          <div className="mt-3">
            <h5 className="fs-16 mb-1">
              <Link to="#" className="link-primary username">
                {`${receiver.username}`}
              </Link>
            </h5>
          </div>
        </div>
        <div className="border-top border-top-dashed p-3">
          <h5 className="fs-15 mb-3">Personal Details</h5>

          <div className="mb-3">
            <p className="text-muted text-uppercase fw-medium fs-12 mb-1">Email</p>
            <h6>{receiver.email}</h6>
          </div>
          <div className="mb-3">
            <p className="text-muted text-uppercase fw-medium fs-12 mb-1">First name</p>
            <h6>{receiver.first_name}</h6>
          </div>
          <div className="mb-3">
            <p className="text-muted text-uppercase fw-medium fs-12 mb-1">Last name</p>
            <h6>{receiver.last_name}</h6>
          </div>
          <div className="mb-3">
            <p className="text-muted text-uppercase fw-medium fs-12 mb-1">Full name</p>
            <h6> {`${receiver.first_name} ${receiver.last_name}`}</h6>
          </div>
        </div>
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default PersonalInfo;
