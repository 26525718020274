import React from "react";
import { Col, Input, Label, FormFeedback } from "reactstrap";

const NumberField = (props) => {
  return (
    <Col md={props.md}>
      <div className={props.mb}>
        <Label className="form-label" htmlFor="product-title-input">
          {props.label}
        </Label>
        <Input
          type="number"
          name={props.name}
          className="form-control"
          defaultValue={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          invalid={props.validation.touched[props.name] && props.validation.errors[props.name] ? true : false}
          placeholder={props.placeholder || props.name}
        />
        {props.validation.touched[props.name] && props.validation.errors[props.name] ? (
          <FormFeedback type="invalid">{props.validation.errors[props.name]}</FormFeedback>
        ) : null}
      </div>
    </Col>
  );
};

export default NumberField;
