import React, { useEffect, useState } from "react";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
//Import images

import classnames from "classnames";
import SelectField from "../../Components/Common/Fields/SelectField.js";

// Formik Validation
import { useFormik } from "formik";
import * as Yup from "yup";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//redux
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
//import countries from "../../common/data/countries.json";
import countries from "../../common/data/countries_initial.json";

import { checkIfFieldsIsValid } from "../../helpers/modules/checkIfFieldsIsValid";
import TextField from "./Fields/TextField";
// action
import { createOrginzation, resetCreateOrginzationError } from "../../store/actions";
import { getLoggedInUser } from "../../helpers/jwt-token-access/auth-token-header.js";
import IntNumberInput from "./Fields/IntNumberInput.js";

const OrganizationTab = () => {
  const [activeArrowTab, setactiveArrowTab] = useState(4);
  const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
  const [fieldErrors, setFieldErrors] = React.useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [userId, setUserId] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  const { createOrgError, error, registrationError, success, message } = useSelector((state) => ({
    registrationError: state.Organization.registrationError,
    success: state.Organization.success,
    error: state.Organization.error,
    message: state.Organization.message,
    createOrgError: state.Organization.createOrgError,
  }));

  function toggleArrowTab(tab) {
    if (activeArrowTab !== tab) {
      var modifiedSteps = [...passedarrowSteps, tab];

      if (tab >= 4 && tab <= 7) {
        setactiveArrowTab(tab);
        setPassedarrowSteps(modifiedSteps);
      }
    }
  }
  useEffect(() => {
    // if (success) {
    //   setTimeout(() => history.push("login"), 3000);
    // }
    if (registrationError) {
      setErrorMessage(message);
    }
    setTimeout(() => {
      dispatch(resetCreateOrginzationError());
    }, 3000);
  }, [dispatch, registrationError, success, error, history, message]);

  // Retrieve the user details from local storage
  //useEffect(() => {
  //const { userId } = getLoggedInUser();

  //if (userId) {
  //setUserId(userId);
  //}
  //}, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      userId,
      name: "",
      first_name: "",
      last_name: "",
      phone: "",
      country: "",
      address_line: "",
      city: "",
      postcode: "",
      state: "",
      street: "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Organisation name"),
      country: Yup.string().required("Please Enter Your country"),

      first_name: Yup.string().required("Please Enter A First Name"),
      last_name: Yup.string().required("Please Enter A Last Name"),

      phone: Yup.string().required("Please Enter Phone Number"),
      address_line: Yup.string().required("Please Enter An Address"),
      city: Yup.string().required("Please Enter An city"),
      postcode: Yup.string().required("Please Enter An postcode"),
      state: Yup.string().required("Please Enter A state"),
      street: Yup.string().required("Please Enter An Address"),
    }),

    onSubmit: (values) => {
      dispatch(createOrginzation(values));
    },
  });

  const handleNextTab = (fields, submit) => {
    let { fieldIsValid, errOject } = checkIfFieldsIsValid(fields);
    setFieldErrors(errOject);

    if (!fieldIsValid) {
      return;
    }

    if (submit) {
      validation.handleSubmit();
    } else {
      toggleArrowTab(activeArrowTab + 1);
    }
  };

  document.title = "Wizard | Velzon - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      {/* <div className="page-content"> */}
      <Container fluid>
        <Row>
          <Col xl={12}>
            <Card className="px-3 py-4">
              <div className="text-center mt-2">
                <h5 className="text-primary">You are almost done</h5>
                <p className="text-muted">create your organisation below</p>
              </div>
              <CardBody className="form-steps">
                <Form>
                  <div className="step-arrow-nav mb-4">
                    <Nav className="nav-pills custom-nav nav-justified" role="tablist">
                      <NavItem>
                        <NavLink
                          href="#"
                          id="steparrow-gen-info-tab"
                          className={classnames({
                            active: activeArrowTab === 4,
                            done: activeArrowTab <= 6 && activeArrowTab > 3,
                          })}
                          //   onClick={() => {
                          //     toggleArrowTab(4);
                          //   }}
                        >
                          User info
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          id="steparrow-gen-info-tab"
                          className={classnames({
                            active: activeArrowTab === 5,
                            done: activeArrowTab <= 6 && activeArrowTab > 4,
                          })}
                          //   onClick={() => {
                          //     toggleArrowTab(5);
                          //   }}
                        >
                          Address info
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          id="steparrow-gen-info-tab"
                          className={classnames({
                            active: activeArrowTab === 6,
                            done: activeArrowTab <= 6 && activeArrowTab > 5,
                          })}
                          //   onClick={() => {
                          //     toggleArrowTab(6);
                          //   }}
                        >
                          Finish
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>

                  {success && success ? (
                    <>
                      {toast("Your Redirect To Login Page...", {
                        position: "top-right",
                        hideProgressBar: false,
                        className: "bg-success text-white",
                        progress: undefined,
                        toastId: "",
                      })}
                      <ToastContainer autoClose={2000} limit={1} />
                      <Alert color="success">Register User Successfully, Check Your Email And Verify To Login...</Alert>
                    </>
                  ) : null}

                  {registrationError && registrationError ? (
                    <Alert color="danger">
                      <div>
                        {/* {registrationError} */}
                        {/* Email Already Registered, Please Try Loggin In or
                              Use Another Email Address...{" "} */}
                        {message}
                      </div>
                    </Alert>
                  ) : null}

                  {error && error ? (
                    <>
                      {toast("Internal Server Error, Please Try Again Later", {
                        position: "top-right",
                        hideProgressBar: true,
                        className: "bg-danger text-white",
                        progress: undefined,
                        toastId: "",
                      })}
                      <ToastContainer autoClose={2000} limit={1} />
                      {/* <Alert color="danger">
                              Register User Successfully and Your Redirect To
                              Login Page...
                            </Alert> */}
                    </>
                  ) : null}

                  <TabContent activeTab={activeArrowTab}>
                    <TabPane id="steparrow-gen-info" tabId={4}>
                      <div>
                        <Row>
                          <TextField
                            md="6"
                            mb="mb-3"
                            value={validation.values.name}
                            onChange={validation.handleChange}
                            validation={validation}
                            onBlur={validation.handleBlur}
                            placeholder="Enter organization name"
                            label="Organization name"
                            name="name"
                            errorMessage={fieldErrors}
                          />

                          <IntNumberInput
                            md="6"
                            mb="mb-3"
                            value={validation.values.phone}
                            onChange={validation.handleChange}
                            validation={validation}
                            onBlur={validation.handleBlur}
                            placeholder="Enter phone mobile"
                            label="Phone mobile"
                            name="phone"
                            errorMessage={fieldErrors}
                          />
                        </Row>

                        <Row>
                          <TextField
                            md="6"
                            mb="mb-3"
                            value={validation.values.first_name}
                            onChange={validation.handleChange}
                            validation={validation}
                            onBlur={validation.handleBlur}
                            placeholder="Enter your first name"
                            label="First Name"
                            name="first_name"
                            errorMessage={fieldErrors}
                          />
                          <TextField
                            md="6"
                            mb="mb-3"
                            value={validation.values.last_name}
                            onChange={validation.handleChange}
                            validation={validation}
                            onBlur={validation.handleBlur}
                            placeholder="Enter your last name"
                            label="Last name"
                            name="last_name"
                            errorMessage={fieldErrors}
                          />
                        </Row>
                      </div>
                      <div className="d-flex align-items-start gap-3 mt-4">
                        <button
                          type="button"
                          className="btn btn-success btn-label right ms-auto nexttab nexttab"
                          onClick={() => {
                            // toggleArrowTab(activeArrowTab + 1);
                            let fields = {
                              name: validation.values.name,
                              first_name: validation.values.first_name,
                              last_name: validation.values.last_name,
                              phone: validation.values.phone,
                              // address: validation.values.address,
                              // address_line: validation.values.address_line,
                            };
                            handleNextTab(fields);
                          }}
                        >
                          <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                          Next
                        </button>
                      </div>
                    </TabPane>

                    <TabPane id="steparrow-description-info" tabId={5}>
                      <div>
                        <Row>
                          <SelectField
                            md="6"
                            mb="mb-3"
                            value={validation.values.country || ""}
                            onChange={validation.handleChange}
                            validation={validation}
                            onBlur={validation.handleBlur}
                            placeholder="Select a country..."
                            label="Country"
                            name="country"
                            errorMessage={fieldErrors}
                            optional={true}
                            options={countries}
                          />
                          <TextField
                            md="6"
                            mb="mb-3"
                            value={validation.values.state}
                            onChange={validation.handleChange}
                            validation={validation}
                            onBlur={validation.handleBlur}
                            placeholder="Enter your state"
                            label="State"
                            name="state"
                            errorMessage={fieldErrors}
                          />
                        </Row>

                        <Row>
                          <TextField
                            md="6"
                            mb="mb-3"
                            value={validation.values.street}
                            onChange={validation.handleChange}
                            validation={validation}
                            onBlur={validation.handleBlur}
                            placeholder="Enter your street"
                            label="Street"
                            name="street"
                            errorMessage={fieldErrors}
                          />
                          <TextField
                            md="6"
                            mb="mb-3"
                            value={validation.values.city}
                            onChange={validation.handleChange}
                            validation={validation}
                            onBlur={validation.handleBlur}
                            placeholder="Enter your city"
                            label="City"
                            name="city"
                            errorMessage={fieldErrors}
                          />
                        </Row>

                        <Row>
                          <TextField
                            md="6"
                            mb="mb-3"
                            value={validation.values.postcode}
                            onChange={validation.handleChange}
                            validation={validation}
                            onBlur={validation.handleBlur}
                            placeholder="Enter your postcode"
                            label="Postcode"
                            name="postcode"
                            errorMessage={fieldErrors}
                          />

                          <TextField
                            md="6"
                            mb="mb-3"
                            value={validation.values.address_line}
                            onChange={validation.handleChange}
                            validation={validation}
                            onBlur={validation.handleBlur}
                            placeholder="Enter your address line"
                            label="Address Line"
                            name="address_line"
                            errorMessage={fieldErrors}
                          />
                        </Row>
                      </div>
                      <div className="d-flex align-items-start gap-3 mt-4">
                        <button
                          type="button"
                          className="btn btn-light btn-label previestab"
                          onClick={() => {
                            toggleArrowTab(activeArrowTab - 1);
                          }}
                        >
                          <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i> Back
                        </button>
                        <button
                          type="button"
                          className="btn btn-success btn-label right ms-auto nexttab nexttab"
                          //   onClick={() => {
                          //     toggleArrowTab(activeArrowTab + 1);
                          //   }}
                          onClick={() => {
                            // toggleArrowTab(activeArrowTab + 1);
                            let fields = {
                              street: validation.values.street,
                              city: validation.values.city,
                              postcode: validation.values.postcode,
                              state: validation.values.state,
                              country: validation.values.country,
                              address_line: validation.values.address_line,
                            };
                            handleNextTab(fields, "submit");
                          }}
                        >
                          <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                          Submit
                        </button>
                      </div>
                    </TabPane>

                    <TabPane id="pills-experience" tabId={6}>
                      <div className="text-center">
                        <div className="avatar-md mt-5 mb-4 mx-auto">
                          <div className="avatar-title bg-light text-success display-4 rounded-circle">
                            <i className="ri-checkbox-circle-fill"></i>
                          </div>
                        </div>
                        <h5>Well Done !</h5>
                        <p className="text-muted">You have Successfully Signed Up</p>
                      </div>
                    </TabPane>
                  </TabContent>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* </div> */}
    </React.Fragment>
  );
};

export default OrganizationTab;
