import {
    GET_CAMPAIGN_LIST,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    ADD_NEW_CAMPAIGN,
    ADD_CAMPAIGN_SUCCESS,
    ADD_CAMPAIGN_FAIL,
    UPDATE_CAMPAIGN,
    UPDATE_CAMPAIGN_SUCCESS,
    UPDATE_CAMPAIGN_FAIL,
    DELETE_CAMPAIGN,
    DELETE_CAMPAIGN_SUCCESS,
    DELETE_CAMPAIGN_FAIL,
    RESET_CAMPAIGN_FLAG,
} from "./actionType";
// common success
export const CampaignApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const CampaignApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getCampaignList = (params) => ({
    type: GET_CAMPAIGN_LIST,
    payload: { params },
});

export const updateCampaign = (campaign) => ({
    type: UPDATE_CAMPAIGN,
    payload: campaign,
});

export const updateCampaignSuccess = (campaign) => ({
    type: UPDATE_CAMPAIGN_SUCCESS,
    payload: campaign,
});

export const updateCampaignFail = (error) => ({
    type: UPDATE_CAMPAIGN_FAIL,
    payload: error,
});
  
export const addNewCampaign = (campaign) => ({
    type: ADD_NEW_CAMPAIGN,
    payload: campaign,
});
  
export const addCampaignSuccess = (campaign) => ({
    type: ADD_CAMPAIGN_SUCCESS,
    payload: campaign,
});
  
export const addCampaignFail = (error) => ({
    type: ADD_CAMPAIGN_FAIL,
    payload: error,
});

export const deleteCampaign = (campaign) => ({
    type: DELETE_CAMPAIGN,
    payload: campaign,
});
  
export const deleteCampaignSuccess = (campaign) => ({
    type: DELETE_CAMPAIGN_SUCCESS,
    payload: campaign,
});
  
export const deleteCampaignFail = (error) => ({
    type: DELETE_CAMPAIGN_FAIL,
    payload: error,
});
  
export const resetCampaignFlag = () => {
    return {
      type: RESET_CAMPAIGN_FLAG,
    };
};
