import React from "react";
import AddLeadForm from "./FormComponents/CreateLeadForm";

const CreateLead = () => {
  document.title = "Create Lead | Velzon - React Admin & Dashboard Template";

  return (
    // <div className="page-content">
    <AddLeadForm title="Create Lead" />
    // </div>
  );
};

export default CreateLead;
