import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Card, CardBody, Container, Button } from "reactstrap";
import { api } from "../../config";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link, useHistory } from "react-router-dom";

import { useParams } from "react-router-dom";

// import images
import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "./ParticlesAuth";
import axios from "axios";
import { activateUser } from "../../helpers/backend_helper";
import Loader from "../../Components/Common/Loader";

const RegistrationAccepted = (props) => {
  const dispatch = useDispatch();
  const { uid, token, activation_key } = useParams();
  const [invalidToken, setInvalidToken] = useState("");
  const [activationSuccess, setActivationSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  const { forgetError, forgetSuccessMsg } = useSelector((state) => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));

  document.title = "Activate Account | SimplyNP";
  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="40" />
                  </Link>
                </div>
                <p className="mt-3 fs-15 fw-medium">The better way to manage your NON-Profit</p>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Registration successful</h5>
                    <p className="text-muted">
                      you have registered Successfully, Check your email to activate your account
                    </p>
                  </div>

                  <div className="p-2 mt-4">
                    <div>
                      <Row className="justify-content-center">
                        <Col lg={5}>
                          <div className="text-center mt-4 mb-5">
                            <div className="py-3 px-2 d-inline-block" id="logo-tour"></div>

                            <div className="hstack gap-2 justify-content-center">
                              <Link to="/login">go to login</Link>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
    // <ParticlesAuth>
    //   <div className="auth-page-content">
    //     <Container fluid>
    //       {/* <BreadCrumb title="Tour" pageTitle="Advance UI" /> */}
    //       <Row>
    //         <Col lg={12}>
    //           <Card>
    //             <CardBody>
    //               <div>
    //                 <Row className="justify-content-center">
    //                   <Col lg={5}>
    //                     <div className="text-center mt-4 mb-5">
    //                       <div className="py-3 px-2 d-inline-block" id="logo-tour"></div>
    //                       <h5 className="fs-16">Registration successfull</h5>
    //                       <div>Register User Successfully, Check your email to activate your account</div>

    //                       <div className="hstack gap-2 justify-content-center">
    //                         {/* <Button color="success" size="sm"> */}
    //                         <Link to="/login">go to login</Link>
    //                         {/* </Button> */}
    //                       </div>
    //                     </div>
    //                   </Col>
    //                 </Row>
    //               </div>
    //             </CardBody>
    //           </Card>
    //         </Col>
    //       </Row>
    //     </Container>
    //   </div>
    // </ParticlesAuth>
  );
};

RegistrationAccepted.propTypes = {
  history: PropTypes.object,
};

export default withRouter(RegistrationAccepted);
