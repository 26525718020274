import {
  GET_MEMBER_BY_ID,
  GET_MEMBERS,
  GET_MEMBERSHIPS,
  GET_MEMBERSHIP_TYPES,
  GET_MEMBERSHIP_BENEFITS,
  GET_MEMBERSHIP_LEVELS,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_MEMBERSHIP_SITE_CONFIG,
  UPDATE_MEMBERSHIP_SITE_CONFIG,
  UPDATE_MEMBERSHIP_SITE_CONFIG_FAIL,
  UPDATE_MEMBERSHIP_SITE_CONFIG_SUCCESS,
  UPDATE_MEMBERSHIPS,
  UPDATE_MEMBERSHIPS_SUCCESS,
  UPDATE_MEMBERSHIPS_FAIL,
  RENEW_MEMBERSHIPS,
  RENEW_MEMBERSHIPS_SUCCESS,
  RENEW_MEMBERSHIPS_FAIL,
  ADD_NEW_MEMBERSHIPS,
  ADD_MEMBERSHIPS_SUCCESS,
  ADD_MEMBERSHIPS_FAIL,
  DELETE_MEMBERSHIPS,
  DELETE_MEMBERSHIPS_SUCCESS,
  DELETE_MEMBERSHIPS_FAIL,
  UPDATE_MEMBER,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAIL,
  ADD_NEW_MEMBER,
  ADD_MEMBER_SUCCESS,
  ADD_MEMBER_FAIL,
  DELETE_MEMBER,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAIL,
  UPDATE_MEMBERSHIP_LEVEL,
  UPDATE_MEMBERSHIP_LEVEL_SUCCESS,
  UPDATE_MEMBERSHIP_LEVEL_FAIL,
  ADD_NEW_MEMBERSHIP_LEVEL,
  ADD_MEMBERSHIP_LEVEL_SUCCESS,
  ADD_MEMBERSHIP_LEVEL_FAIL,
  DELETE_MEMBERSHIP_LEVEL,
  DELETE_MEMBERSHIP_LEVEL_SUCCESS,
  DELETE_MEMBERSHIP_LEVEL_FAIL,
  UPDATE_MEMBERSHIP_BENEFIT,
  UPDATE_MEMBERSHIP_BENEFIT_SUCCESS,
  UPDATE_MEMBERSHIP_BENEFIT_FAIL,
  ADD_NEW_MEMBERSHIP_BENEFIT,
  ADD_MEMBERSHIP_BENEFIT_SUCCESS,
  ADD_MEMBERSHIP_BENEFIT_FAIL,
  DELETE_MEMBERSHIP_BENEFIT,
  DELETE_MEMBERSHIP_BENEFIT_SUCCESS,
  DELETE_MEMBERSHIP_BENEFIT_FAIL,
  UPDATE_MEMBERSHIP_TYPE,
  UPDATE_MEMBERSHIP_TYPE_SUCCESS,
  UPDATE_MEMBERSHIP_TYPE_FAIL,
  ADD_NEW_MEMBERSHIP_TYPE,
  ADD_MEMBERSHIP_TYPE_SUCCESS,
  ADD_MEMBERSHIP_TYPE_FAIL,
  DELETE_MEMBERSHIP_TYPE,
  DELETE_MEMBERSHIP_TYPE_SUCCESS,
  DELETE_MEMBERSHIP_TYPE_FAIL,
  RESET_MEMBERSHIP_FLAG,
  REMOVE_MEMBER_FROM_STORE,
  IS_LOADED,
  IS_LOADING,
} from "./actionType";

// common success
export const membershipApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const membershipApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const removeMemberFromStore = () => ({
  type: REMOVE_MEMBER_FROM_STORE,
});

export const getMemberByID = (memberID) => ({
  type: GET_MEMBER_BY_ID,
  payload: memberID,
});

export const getMembers = (data) => ({
  type: GET_MEMBERS,
  payload: data,
});

export const getMemberships = (data) => ({
  type: GET_MEMBERSHIPS,
  payload: data,
});

export const getMembershipTypes = (data) => ({
  type: GET_MEMBERSHIP_TYPES,
  payload: data,
});

export const getMembershipBenefits = (data) => ({
  type: GET_MEMBERSHIP_BENEFITS,
  payload: data,
});

export const getMembershipLevels = (data) => ({
  type: GET_MEMBERSHIP_LEVELS,
  payload: data,
});

export const updateMembership = (memberships) => ({
  type: UPDATE_MEMBERSHIPS,
  payload: memberships,
});

export const renewMembership = (memberships) => ({
  type: RENEW_MEMBERSHIPS,
  payload: memberships,
});

export const renewMembershipSuccess = (memberships) => ({
  type: RENEW_MEMBERSHIPS_SUCCESS,
  payload: memberships,
});

export const renewMembershipFail = (memberships) => ({
  type: RENEW_MEMBERSHIPS_FAIL,
  payload: memberships,
});

export const updateMembershipSuccess = (memberships) => ({
  type: UPDATE_MEMBERSHIPS_SUCCESS,
  payload: memberships,
});

export const updateMembershipFail = (error) => ({
  type: UPDATE_MEMBERSHIPS_FAIL,
  payload: error,
});

export const addNewMembership = (memberships) => ({
  type: ADD_NEW_MEMBERSHIPS,
  payload: memberships,
});

export const addMembershipSuccess = (memberships) => ({
  type: ADD_MEMBERSHIPS_SUCCESS,
  payload: memberships,
});

export const addMembershipFail = (error) => ({
  type: ADD_MEMBERSHIPS_FAIL,
  payload: error,
});

export const deleteMembership = (memberships) => ({
  type: DELETE_MEMBERSHIPS,
  payload: memberships,
});

export const deleteMembershipSuccess = (memberships) => ({
  type: DELETE_MEMBERSHIPS_SUCCESS,
  payload: memberships,
});

export const deleteMembershipFail = (error) => ({
  type: DELETE_MEMBERSHIPS_FAIL,
  payload: error,
});

export const updateMember = (member) => ({
  type: UPDATE_MEMBER,
  payload: member,
});

export const updateMemberSuccess = (member) => ({
  type: UPDATE_MEMBER_SUCCESS,
  payload: member,
});

export const updateMemberFail = (error) => ({
  type: UPDATE_MEMBER_FAIL,
  payload: error,
});

export const addNewMember = (member) => ({
  type: ADD_NEW_MEMBER,
  payload: member,
});

export const addMemberSuccess = (member) => ({
  type: ADD_MEMBER_SUCCESS,
  payload: member,
});

export const addMemberFail = (error) => ({
  type: ADD_MEMBER_FAIL,
  payload: error,
});

export const deleteMember = (member) => ({
  type: DELETE_MEMBER,
  payload: member,
});

export const deleteMemberSuccess = (member) => ({
  type: DELETE_MEMBER_SUCCESS,
  payload: member,
});

export const deleteMemberFail = (error) => ({
  type: DELETE_MEMBER_FAIL,
  payload: error,
});

///
export const updateMembershipLevel = (membership_level) => ({
  type: UPDATE_MEMBERSHIP_LEVEL,
  payload: membership_level,
});

export const updateMembershipLevelSuccess = (membership_level) => ({
  type: UPDATE_MEMBERSHIP_LEVEL_SUCCESS,
  payload: membership_level,
});

export const updateMembershipLevelFail = (error) => ({
  type: UPDATE_MEMBERSHIP_LEVEL_FAIL,
  payload: error,
});

export const addNewMembershipLevel = (membership_level) => ({
  type: ADD_NEW_MEMBERSHIP_LEVEL,
  payload: membership_level,
});

export const addMembershipLevelSuccess = (membership_level) => ({
  type: ADD_MEMBERSHIP_LEVEL_SUCCESS,
  payload: membership_level,
});

export const addMembershipLevelFail = (error) => ({
  type: ADD_MEMBERSHIP_LEVEL_FAIL,
  payload: error,
});

//
export const getMembershipSiteConfig = () => ({
  type: GET_MEMBERSHIP_SITE_CONFIG,
});

export const updateMembershipSiteConfig = (config) => ({
  type: UPDATE_MEMBERSHIP_SITE_CONFIG,
  payload: config,
});

export const updateMembershipSiteConfigSuccess = (config) => ({
  type: UPDATE_MEMBERSHIP_SITE_CONFIG_SUCCESS,
  payload: config,
});

export const updateMembershipSiteConfigFail = (error) => ({
  type: UPDATE_MEMBERSHIP_SITE_CONFIG_FAIL,
  payload: error,
});

//
export const deleteMembershipLevel = (membership_level) => ({
  type: DELETE_MEMBERSHIP_LEVEL,
  payload: membership_level,
});

export const deleteMembershipLevelSuccess = (membership_level) => ({
  type: DELETE_MEMBERSHIP_LEVEL_SUCCESS,
  payload: membership_level,
});

export const deleteMembershipLevelFail = (error) => ({
  type: DELETE_MEMBERSHIP_LEVEL_FAIL,
  payload: error,
});

///
export const updateMembershipBenefit = (membership_benefit) => ({
  type: UPDATE_MEMBERSHIP_BENEFIT,
  payload: membership_benefit,
});

export const updateMembershipBenefitSuccess = (membership_benefit) => ({
  type: UPDATE_MEMBERSHIP_BENEFIT_SUCCESS,
  payload: membership_benefit,
});

export const updateMembershipBenefitFail = (error) => ({
  type: UPDATE_MEMBERSHIP_BENEFIT_FAIL,
  payload: error,
});

export const addNewMembershipBenefit = (membership_benefit) => ({
  type: ADD_NEW_MEMBERSHIP_BENEFIT,
  payload: membership_benefit,
});

export const addMembershipBenefitSuccess = (membership_benefit) => ({
  type: ADD_MEMBERSHIP_BENEFIT_SUCCESS,
  payload: membership_benefit,
});

export const addMembershipBenefitFail = (error) => ({
  type: ADD_MEMBERSHIP_BENEFIT_FAIL,
  payload: error,
});

export const deleteMembershipBenefit = (membership_benefit) => ({
  type: DELETE_MEMBERSHIP_BENEFIT,
  payload: membership_benefit,
});

export const deleteMembershipBenefitSuccess = (membership_benefit) => ({
  type: DELETE_MEMBERSHIP_BENEFIT_SUCCESS,
  payload: membership_benefit,
});

export const deleteMembershipBenefitFail = (error) => ({
  type: DELETE_MEMBERSHIP_BENEFIT_FAIL,
  payload: error,
});
///
export const updateMembershipType = (membership_type) => ({
  type: UPDATE_MEMBERSHIP_TYPE,
  payload: membership_type,
});

export const updateMembershipTypeSuccess = (membership_type) => ({
  type: UPDATE_MEMBERSHIP_TYPE_SUCCESS,
  payload: membership_type,
});

export const updateMembershipTypeFail = (error) => ({
  type: UPDATE_MEMBERSHIP_TYPE_FAIL,
  payload: error,
});

export const addNewMembershipType = (membership_type) => ({
  type: ADD_NEW_MEMBERSHIP_TYPE,
  payload: membership_type,
});

export const addMembershipTypeSuccess = (membership_type) => ({
  type: ADD_MEMBERSHIP_TYPE_SUCCESS,
  payload: membership_type,
});

export const addMembershipTypeFail = (error) => ({
  type: ADD_MEMBERSHIP_TYPE_FAIL,
  payload: error,
});

export const deleteMembershipType = (membership_type) => ({
  type: DELETE_MEMBERSHIP_TYPE,
  payload: membership_type,
});

export const deleteMembershipTypeSuccess = (membership_type) => ({
  type: DELETE_MEMBERSHIP_TYPE_SUCCESS,
  payload: membership_type,
});

export const deleteMembershipTypeFail = (error) => ({
  type: DELETE_MEMBERSHIP_TYPE_FAIL,
  payload: error,
});

export const resetMembershipFlag = () => {
  return {
    type: RESET_MEMBERSHIP_FLAG,
  };
};

export const isLoading = () => {
  return {
    type: IS_LOADING,
  };
};

export const isLoaded = () => {
  return {
    type: IS_LOADED,
  };
};
