export const GET_DONATION = "GET_DONATION";
export const GET_DONATIONS_LIST = "GET_DONATIONS_LIST";
export const RESET_DONATION_FLAG = "RESET_DONATION_FLAG";
export const CREATE_DONATION = "CREATE_DONATION";
export const REPORT_DELETE_SUCCESS = "REPORT_DELETE_SUCCESS";

export const DELETE_DONATION = "DELETE_DONATION";
export const DONATION_LIST_SUCCESS = "DONATION_LIST_SUCCESS";
export const GET_DONATION_SUCCESS = "GET_DONATION_SUCCESS";

export const UPDATE_DONATION = "UPDATE_DONATION";
export const FILTER_REPORTS_SUCCESS = "FILTER_REPORTS_SUCCESS";

export const CREATE_DONATION_SUCCESS = "CREATE_DONATION_SUCCESS";

export const DONATION_ERROR = "DONATION_ERROR";

export const UPDATE_DONATION_SUCCESS = "UPDATE_DONATION_SUCCESS";
export const DELETE_DONATION_SUCCESS = "DELETE_DONATION_SUCCESS";
