import React from "react";
import AddUserForm from "./FormComponent/CreateUserForm";

const UpdateUser = () => {
  document.title = "Create Lead | Velzon - React Admin & Dashboard Template";

  return (
    // <div className="page-content">
    <AddUserForm title="Update User" updateUser />
    // </div>
  );
};

export default UpdateUser;
