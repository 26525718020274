import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import * as moment from "moment";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalHeader,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

// Import React FilePond
import { registerPlugin } from "react-filepond";
import Select from "react-select";
// Import FilePond styles
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";

import { ToastContainer } from "react-toastify";
import MsgToast from "../../../Components/Common/MsgToast";

import AsyncSelect from "react-select/async";
import DateField from "../../../Components/Common/Fields/DateField";
import CreateMember from "../members/CreateMember";

import {
  approvedOptions,
  enrollmentTypeOptions,
  paymentTypeOptions,
  statusOptions,
  yesOrNoOptions,
} from "../../../common/data/common";
import { getMembers, getMembershipTypes } from "../../../helpers/backend_helper";
import {
  addNewMembership as onAddNewMembership,
  getMemberships as onGetMemberships,
  getMembers as onGetMembers,
  getMembershipTypes as onGetMembershipTypes,
  updateMembership as onUpdateMembership,
  //renewMembership as onRenewMembership,
  resetMembershipFlag,
} from "../../../store/actions";
import { handleValidDate } from "../../cases/ListView/CaseCol";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const CreateMembership = (props) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { id: membership_pk, action: membership_renew } = useParams();

  const enrollment_type = state?.enrollment_type;
  const enrollment_date = handleValidDate(Date.now());

  const {
    error,
    loading,
    membersList,
    membershipsList,
    membershipTypeList,
    isMembershipAdd,
    isMembershipAddFail,
    isMembershipCreated,
    isMembershipUpdate,
    isMembershipUpdateFail,
    isMembershipRenew,
    isMembershipRenewFail,
    isMembershipSuccess,
    membershipObj,
  } = useSelector((state) => ({
    error: state.Membership.error,
    loading: state.Membership.loading,
    membersList: state.Membership.members,
    membershipsList: state.Membership.memberships,
    membershipTypeList: state.Membership.membership_types,
    isMembershipAdd: state.Membership.isMembershipAdd,
    isMembershipUpdate: state.Membership.isMembershipUpdate,
    isMembershipRenew: state.Membership.isMembershipRenew,
    isMembershipAddFail: state.Membership.isMembershipAddFail,
    isMembershipCreated: state.Membership.isMembershipCreated,
    isMembershipSuccess: state.Membership.isMembershipSuccess,
    isMembershipUpdateFail: state.Membership.isMembershipUpdateFail,
    isMembershipRenewFail: state.Membership.isMembershipRenewFail,
    membershipObj: state.Membership.membership,
  }));

  const [membership_errors, setMembershipErrors] = useState(null);
  const [address_errors, setAddressErrors] = useState(null);
  const [member, setMember] = useState(null);
  const [saveAndCreate, setSaveAndCreate] = useState(false);
  const [shortCutMember, setShortCutMember] = useState(false);

  const [subMembers, setSubMembers] = useState(null);
  const [membership, setMembership] = useState(null);
  const [membershipType, setMembershipType] = useState(null);

  useEffect(() => {
    if (error) {
      setMembershipErrors(error?.errors?.membership_errors);
      setAddressErrors(error?.errors?.address_errors);
    }

    setTimeout(() => {
      dispatch(resetMembershipFlag());
    }, 3000);
  }, [
    error,
    dispatch,
    isMembershipRenew,
    isMembershipRenewFail,
    isMembershipSuccess,
    isMembershipCreated,
    isMembershipAdd,
    isMembershipAddFail,
    isMembershipUpdate,
    isMembershipUpdateFail,
  ]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: membership?.id || "",
      member: membership?.member.id || "",
      member_id: membership?.member.member_id || "",
      membership_type: membership?.membership_type.id || "",
      organization_name: membership?.organization_name || "",

      first_name: membership?.member.first_name || "",
      last_name: membership?.member.last_name || "",
      email: membership?.member.email || "",
      enrollment_type: enrollment_type || membership?.member.enrollment_type || "register",
      renew_date: (enrollment_type && handleValidDate(enrollment_date)) || membership?.renew_date || null,

      start_date: membership?.start_date || null,
      auto_renew: membership?.auto_renew || false,
      approved: membership?.approved || false,
      expiry_date: membership?.expiry_date || null,
      payment_date: membership?.payment_date || null,
      payment_type: membership?.payment_type || "",

      status: membership?.status || "in-active",

      cancelled: membership?.cancelled || false,
      cancelled_date: membership?.cancelled_date || null,
      cancelled_reason: membership?.cancelled_reason || "",

      description: membership?.description || "",
    },

    validationSchema: Yup.object({
      member: Yup.mixed(),
      membership_type: Yup.mixed(),
      start_date: Yup.date().nullable(),
      expiry_date: Yup.date().nullable(),
      billing_contact: Yup.string(),
      payment_date: Yup.date().nullable(),
      notes: Yup.string(),
      source: Yup.string(),
      campaign: Yup.string(),
      cancelled: Yup.string().notRequired(),
      cancelled_date: Yup.date().notRequired().nullable(),
      cancelled_reason: Yup.string().notRequired(),
      description: Yup.string().notRequired(),
    }),

    onSubmit: (membership) => {
      setAddressErrors(null);
      setMembershipErrors(null);

      // LOGIC HERE - We're creating a new membership anytime we renew
      // costly on DB but good for reporting
      // if we needed to just renew the fields, then all we'll do instead
      // is to just update and not add a new membership - EZ Right
      if (membership_pk && !membership_renew) {
        dispatch(onUpdateMembership(membership));
      } else if (membership_renew) {
        membership._id = membership.id;
        membership.is_renewing = true;
        delete membership?.id;
        dispatch(onUpdateMembership(membership)); // edit old membership model with type Renewal
        //dispatch(onAddNewMembership(membership));  // create new membership model from scratch
      } else {
        dispatch(onAddNewMembership(membership));
        validation.resetForm();
        // prompt for payment
        //handlePaymentApproval()
        //setTimeout(() => history.push(`/memberships/${membershipObj.id}/checkout`), 3000);
        //setTimeout(() => history.push(`/memberships-details`), 1500);
      }
    },
  });

  useEffect(() => {
    if (!membershipsList) {
      dispatch(onGetMemberships({ id: membership_pk }));
      // TODO: set loading screen
      return;
    }

    if (membership_pk) {
      let c = membershipsList?.find((c) => c.id.toString() === membership_pk);
      if (!c) return;

      setMembership(c);
      setMember(c?.member);
      setMembershipType(c?.membership_type);
      //setSubMembers(c?.sub_members);
    }

    if (membership_renew && membershipObj) {
      setMembership(membershipObj);
      setMember(membershipObj?.member);
      setMembershipType(membershipObj?.membership_type);
      //setSubMembers(membershipObj?.sub_members);
    }

    if (!membersList) {
      dispatch(onGetMembers());
    }

    if (!membershipTypeList) {
      dispatch(onGetMembershipTypes());
    }

    return () => {
      setMembership(null);
    };
  }, [membership_pk, membership_renew, dispatch, membershipObj, membershipsList, membersList, membershipTypeList]);

  const setFieldValue = useCallback(
    (field, value) => {
      validation.setFieldValue(field, value);
    },
    [validation]
  );

  useEffect(() => {
    if (membershipType) {
      let sd, ed;
      if (membershipType.duration_type === "Rolling" && !membership_pk) {
        const { duration, duration_unit } = membershipType;
        sd = moment().format();
        ed = moment().add(duration, duration_unit).format();
      } else if (membershipType.duration_type === "Fixed" && !membership_pk) {
        const { start_date, expiry_date } = membershipType;
        sd = start_date;
        ed = expiry_date;
      } else if (membership_pk) {
        //const { start_date: sd, expiry_date: ed } = membershipType;
        const { start_date, expiry_date } = membership;
        sd = start_date;
        ed = expiry_date;
      }

      setFieldValue("start_date", sd);
      setFieldValue("end_date", ed);
    } else {
      setFieldValue("start_date", null);
      setFieldValue("end_date", null);
    }
  }, [membershipType]);

  document.title = "Create Membership - Membership Management | SimplyNP";

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={props.title} pageTitle="Memberships " />
        <Form
          id="membership-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col lg={8}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Membership Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Label>Membership Type</Label>
                    <div className="mb-3">
                      <AsyncSelect
                        loadOptions={(inputValue) => {
                          return new Promise((resolve) => {
                            const members = getMembershipTypes({ search: inputValue }).then(
                              ({ membership_type_obj_list: mems }) => mems
                            );
                            resolve(members);
                          });
                        }}
                        isClearable
                        isSearchable
                        getOptionLabel={(option) => option?.name}
                        getOptionValue={(option) => option?.id}
                        defaultOptions={membershipTypeList}
                        isDisabled={membership_pk}
                        value={membershipType}
                        onChange={(e) => {
                          setMembershipType(e);
                          validation.setFieldValue("membership_type", e?.id);
                          setSubMembers(subMembers?.slice(0, e?.no_of_sub_members));
                        }}
                      />
                    </div>
                  </Row>

                  <Row className={``}>
                    <DateField
                      mb="mb-3"
                      md="6"
                      value={validation.values.start_date}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      label="Membership Starts"
                      name="start_date"
                      disabled={true}
                    />

                    <DateField
                      mb="mb-3"
                      md="6"
                      value={validation.values.end_date}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      label="Membership Ends"
                      name="end_date"
                      disabled={true}
                    />
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Member Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    {/* // async select method for existing members */}
                    <div className="mb-3">
                      <Label>Member</Label>
                      {/*
                      <AsyncCreatableSelect
                        onCreateOption={(option) => {
                          setShortCutMember(true);
                        }}
                        getOptionLabel={(option) => {
                          if (option?.__isNew__) return <div>{option?.label}</div>;
                          return (
                            <div className="d-flex">
                              <div className="flex-1">{`${option?.first_name} ${option?.last_name} - ${option?.email}`}</div>
                              <Link className='flex-0 me-1' to={`/members-update/${option?.id}`} >Edit+</Link>
                            </div>
                          );
                        }}
                        */}
                      <AsyncSelect
                        loadOptions={(inputValue) => {
                          // setTimeout(() => {
                          return new Promise((resolve) => {
                            const members = getMembers({ search: inputValue }).then(
                              ({ members_obj_list: mems }) => mems
                            );
                            resolve(members);
                          });
                          // }, 1500);
                        }}
                        getOptionLabel={(option) => `${option?.first_name} ${option?.last_name} - ${option?.email}`}
                        getOptionValue={(option) => option?.id}
                        value={member}
                        onChange={(e) => {
                          //if (e?.__isNew__) return;
                          setMember(e);
                          validation.setFieldValue("member", e?.id);
                        }}
                        defaultValue={membership?.member}
                        isDisabled={membership_pk}
                        defaultOptions={membersList}
                        cacheOptions
                        isClearable
                      />
                    </div>
                  </Row>
                  <Row>
                    {/* // async select method for existing members */}
                    {/*
                    <div className="mb-3">
                      <Label>Sub-Members</Label>
                      <AsyncSelect
                        loadOptions={(inputValue) => {
                          // setTimeout(() => {
                          return new Promise((resolve) => {
                            const members = getMembers({ search: inputValue }).then(({ members_obj_list: mems }) =>
                              mems?.filter((m) => m?.id !== member?.id)
                            );
                            resolve(members);
                          });
                          // }, 1500);
                        }}
                        isMulti
                        getOptionLabel={(option) => `${option?.first_name} ${option?.last_name} - ${option?.email}`}
                        getOptionValue={(option) => option?.id}
                        value={subMembers}
                        onChange={(options_members) => {
                          const members =
                            options_members?.length >= membershipType?.no_of_sub_members
                              ? options_members.slice(0, membershipType?.no_of_sub_members)
                              : options_members;

                          // notify because we cant add more members because of the type and max_no_submembers
                          if (members?.length === validation.values?.sub_members?.length) {
                            // TODO: create proper alert
                            alert("Please choose a membership type with more sub-member slots");
                            return;
                          }

                          setSubMembers(members);
                          validation.setFieldValue(
                            "sub_members",
                            members?.map((e) => e?.id)
                          );
                        }}
                        defaultOptions={membersList?.filter((m) => m?.id !== member?.id)}
                        cacheOptions
                        isClearable
                      />
                    </div>
                      */}
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Other Information</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label htmlFor="enrollment-type-input" className="form-label">
                      Enrollment Type
                    </Label>
                    <Select
                      id="enrollment-input"
                      placeholder="Enrollment..."
                      name="enrollment_type"
                      isClearable
                      options={enrollmentTypeOptions}
                      //defaultValue={enrollmentTypeOptions.find((s) => s.value === validation.values.enrollment_type)}
                      value={enrollmentTypeOptions.find((s) => s.value === validation.values.enrollment_type)}
                      onChange={(e) => {
                        validation.setFieldValue("enrollment_type", e.value);
                      }}
                      classNamePrefix="select2-selection form-select"
                    />
                  </div>

                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="payment_type-input" className="form-label">
                          Payment Type
                        </Label>
                        <Select
                          id="payment_type-input"
                          placeholder="Payment type..."
                          name="payment_type"
                          isClearable
                          options={paymentTypeOptions}
                          value={paymentTypeOptions.find((s) => s.value === validation.values.payment_type)}
                          onChange={(e) => {
                            validation.setFieldValue("payment_type", e?.value);
                          }}
                          classNamePrefix="select2-selection form-select"
                        />
                      </div>
                    </Col>
                    <DateField
                      mb="mb-3"
                      md="6"
                      value={validation.values.payment_date}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      label="Payment Date"
                      name="payment_date"
                    />
                  </Row>

                  <div>
                    <Label>Membership Description</Label>
                    <Input
                      type="textarea"
                      className="form-control"
                      name="description"
                      value={validation.values.description}
                      onChange={validation.handleChange}
                      invalid={validation.touched.description && validation.errors.description ? true : false}
                      rows="10"
                    />
                    {validation.touched.description && validation.errors.description ? (
                      <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Status Settings</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label htmlFor="approved-input" className="form-label">
                      Approved
                    </Label>
                    <Select
                      id="approved-input"
                      placeholder="approved..."
                      name="approved"
                      isClearable
                      options={approvedOptions}
                      defaultValue={approvedOptions[1]}
                      value={approvedOptions.find((s) => s.value === validation.values.approved)}
                      onChange={(e) => {
                        validation.setFieldValue("approved", e?.value);
                      }}
                      classNamePrefix="select2-selection form-select"
                    />
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="status-input" className="form-label">
                      Status
                    </Label>
                    <Select
                      id="status-input"
                      placeholder="Assign a status..."
                      name="status"
                      isClearable
                      options={statusOptions}
                      value={statusOptions.find((s) => s?.value === validation.values.status)}
                      onChange={(e) => validation.setFieldValue("status", e?.value)}
                      classNamePrefix="select2-selection form-select"
                    />
                  </div>
                </CardBody>
              </Card>

              {/*
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Renewal Settings</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label htmlFor="auto_renewal-input" className="form-label">
                      Automatic Renewal
                    </Label>
                    <Select
                      id="auto_renewal-input"
                      placeholder="Automatically Renew..."
                      name="auto_renew"
                      isClearable
                      options={yesOrNoOptions}
                      defaultValue={yesOrNoOptions[1]}
                      value={yesOrNoOptions.find((s) => s.value === validation.values.auto_renew)}
                      onChange={(e) => {
                        validation.setFieldValue("auto_renew", e?.value);
                      }}
                      classNamePrefix="select2-selection form-select"
                    />
                  </div>

                  <div className="mb-3">
                    <DateField
                      mb="mb-3"
                      md="12"
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      label="Renewal Date"
                      name="renew_date"
                    />
                  </div>
                </CardBody>
              </Card>
                */}

              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Cancellation</h5>
                </CardHeader>

                <CardBody>
                  <div className="mb-3">
                    <Label htmlFor="cancel-input" className="form-label">
                      Cancelled Status
                    </Label>
                    <Select
                      id="cancel-input"
                      placeholder="Cancel..."
                      name="cancel"
                      isClearable
                      options={yesOrNoOptions}
                      defaultValue={yesOrNoOptions[1]}
                      value={yesOrNoOptions.find((s) => s.value === validation.values.cancelled)}
                      onChange={(e) => {
                        validation.setFieldValue("cancelled", e?.value || "no");

                        // set cancel date to today
                        if (e?.value === "yes") {
                          validation.setFieldValue("cancelled_date", handleValidDate(Date.now()));
                          validation.setFieldValue("status", "in-active");
                        } else {
                          validation.setFieldValue("cancelled_date", null);
                          validation.setFieldValue("status", membership?.status);
                        }
                      }}
                      classNamePrefix="select2-selection form-select"
                    />
                  </div>

                  <DateField
                    mb="mb-3"
                    md="12"
                    value={validation.values.cancelled_date || ""}
                    onChange={validation.handleChange}
                    validation={validation}
                    onBlur={validation.handleBlur}
                    label="Cancelled Date"
                    name="cancelled_date"
                  />

                  <div>
                    <Label>Cancelled Reason</Label>
                    <Input
                      mb="mb-3"
                      type="textarea"
                      rows="5"
                      className="form-control"
                      onChange={validation.handleChange}
                      invalid={validation.touched.cancelled_reason && validation.errors.cancelled_reason ? true : false}
                      value={validation.values.cancelled_reason}
                      name="cancelled_reason"
                      //errorMessage={membership_errors}
                    />
                    {validation.touched.cancelled_reason && validation.errors.cancelled_reason ? (
                      <FormFeedback type="invalid">{validation.errors.cancelled_reason}</FormFeedback>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              <div className="d-flex">
                {membership_pk ? (
                  <div className="mb-3 text-start flex-0">
                    <Link
                      to={`/memberships/${membership_pk}/checkout`}
                      type="button"
                      className="btn btn-soft-success outline w-sm me-1"
                    >
                      Accept Payment
                    </Link>
                  </div>
                ) : null}

                <div className="mb-3 text-end flex-1">
                  <button type="reset" className="btn btn-danger w-sm me-1">
                    Clear
                  </button>
                  <button type="submit" className="btn btn-success w-sm">
                    {loading ? "Saving..." : "Save"}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Form>

        {!membership_renew && isMembershipUpdate && (
          <MsgToast msg="Membership Updated successfully" color="success" icon="ri-checkbox-circle-line" />
        )}

        {!membership_renew && isMembershipUpdateFail && (
          <MsgToast msg="Membership Update failed" color="danger" icon="ri-error-warning-line" />
        )}

        {isMembershipRenew && (
          <MsgToast msg="Membership Renewed successfully" color="success" icon="ri-checkbox-circle-line" />
        )}

        {isMembershipRenewFail && (
          <MsgToast msg="Membership Renewal failed" color="danger" icon="ri-error-warning-line" />
        )}

        {isMembershipAdd && (
          <MsgToast msg="Membership Added successfully" color="success" icon="ri-checkbox-circle-line" />
        )}

        {isMembershipAddFail && <MsgToast msg="Membership Added failed" color="danger" icon="ri-error-warning-line" />}
        <ToastContainer limit={1} closeButton={false} />

        <Modal size="xl" isOpen={shortCutMember} toggle={() => setShortCutMember(!shortCutMember)} centered>
          <ModalHeader
            tag="h5"
            toggle={() => setShortCutMember(!shortCutMember)}
            className="p-3 bg-soft-info modal-title"
          />
          <CreateMember />
        </Modal>
      </Container>
    </div>
  );
};

export default CreateMembership;
