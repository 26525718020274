import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import countries from "../../../common/data/countries.json";

// Import Images
import IntNumberInput from "../../../Components/Common/Fields/IntNumberInput";
import dummyImg from "../../../assets/images/users/user-dummy-img.jpg";

// Import React FilePond
import Select from "react-select";
import AsyncSelect from "react-select/async";

// Import FilePond styles
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePonstPluginFileValidateSize from "filepond-plugin-file-validate-size";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";

import { FilePond, registerPlugin } from "react-filepond";

import { ToastContainer } from "react-toastify";
import MsgToast from "../../../Components/Common/MsgToast";

import { useParams } from "react-router-dom";
import DateField from "../../../Components/Common/Fields/DateField";
import TextField from "../../../Components/Common/Fields/TextField.js";
import TimeField from "../../../Components/Common/Fields/TimeField.js";
import { genders } from "../../../common/data/common";
import { getCampaigns, getUsers } from "../../../helpers/backend_helper";
import {
  addNewVolunteer,
  getUsersList,
  getVolunteersList as onGetVolunteers,
  getCampaignList as onGetCampaigns,
  resetVolunteerFlag,
  updateVolunteer,
} from "../../../store/actions";
import PdfPreview from "../../../Components/Common/PdfPreview";

// Register the plugins
registerPlugin(
  FilePondPluginFileValidateType,
  FilePonstPluginFileValidateSize,
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation
);

const CreateVolunteer = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    // events,
    error,
    volunteersList,
    isVolunteerCreated,
    isVolunteerSuccess,
    isVolunteerUpdate,
    isVolunteerUpdateFail,
    isVolunteerAdd,
    isVolunteerAddFail,
    usersList,
    loading,
    campaignList,
  } = useSelector((state) => ({
    volunteersList: state.Volunteers.volunteersList,
    isVolunteerCreated: state.Volunteers.isVolunteerCreated,
    isVolunteerSuccess: state.Volunteers.isVolunteerSuccess,
    isVolunteerAdd: state.Volunteers.isVolunteerAdd,
    isVolunteerAddFail: state.Volunteers.isVolunteerAddFail,
    isVolunteerUpdate: state.Volunteers.isVolunteerUpdate,
    isVolunteerUpdateFail: state.Volunteers.isVolunteerUpdateFail,
    error: state.Volunteers.error,
    usersList: state.Users.usersList,
    loading: state.Volunteers.loading,
    campaignList: state.Campaigns.campaignList,
  }));

  const [volunteer_errors, set_volunteer_errors] = useState(null);
  const [address_errors, set_address_errors] = useState(null);

  const [volunteer, setVolunteer] = useState(null);
  const [profile_pic, setProfile_pic] = useState("");
  const [resume, setResume] = useState("");

  const [volunteerCountry, setVolunteerCountry] = useState("");
  const [assignedCampaign, setAssignedCampaign] = useState(null);
  const [assignedUser, setAssignedUser] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // ...(props.updateVolunteer && { id: volunteer && volunteer.id }),
      salutation: volunteer?.salutation || "",
      first_name: volunteer?.first_name || "",
      last_name: volunteer?.last_name || "",
      //gender: genders?.find((m) => m.label === volunteer?.gender)?.value || "",
      gender: volunteer?.gender || "",
      date_of_birth: volunteer?.date_of_birth || "",
      email: volunteer?.email || "",
      phone_number: volunteer?.phone_number || "",
      emergency_contact: volunteer?.emergency_contact || "",
      status: volunteer?.status || "in-active",
      profile_pic: volunteer?.profile_pic || "",
      skill_and_interest: volunteer?.skill_and_interest || "",
      department: volunteer?.department || "",
      description: volunteer?.description || "",
      language: volunteer?.language || "",
      address_line: volunteer?.address?.address_line || "",
      street: volunteer?.address?.street || "",
      city: volunteer?.address?.city || "",
      state: volunteer?.address?.state || "",
      postcode: volunteer?.address?.postcode || "",
      country: volunteer?.address?.country || "",
      start_time: volunteer?.start_time || "",
      end_time: volunteer?.end_time || "",
      start_date: volunteer?.start_date || "",
      end_date: volunteer?.end_date || "",
      campaign: (volunteer && JSON.stringify(volunteer.campaign?.map((a) => a.id))) || [],

      // events: "",
      assigned_to: (volunteer && volunteer.assigned_to?.id) || "",
      // tags: (volunteer && volunteer.tags) || "",
      // cases: "",
      resume: (volunteer && volunteer.resume) || "",
    },

    validationSchema: Yup.object({
      profile_pic: Yup.mixed()
        .notRequired()
        .test(
          "File Size",
          "Profile picture too large, should be less than 1MB",
          (value) =>
            !profile_pic || !(profile_pic[0] instanceof File) || (profile_pic && profile_pic[0].size <= 1024 * 1024 * 1)
        ),
      salutation: Yup.string().notRequired("Please enter a salutation"),
      first_name: Yup.string().notRequired("Please enter a first name"),
      last_name: Yup.string().required("Please enter a last name"),
      gender: Yup.string().notRequired("Please enter a gender"),
      date_of_birth: Yup.date().nullable().notRequired("Please enter date of birth"),
      email: Yup.string().email("Please enter a valid email").required("Please Enter an email"),
      phone_number: Yup.string().required("Please Enter Phone Number"),
      emergency_contact: Yup.string().notRequired("Please Enter Emergency Contact"),
      department: Yup.string().notRequired("Please Enter Department"),
      language: Yup.string().notRequired("Please Enter A Language"),
      address_line: Yup.string().notRequired("Please Enter An Address"),
      street: Yup.string().notRequired("Please Enter A Street"),
      city: Yup.string().notRequired("Please Enter A City"),
      state: Yup.string().notRequired("Please Enter A State"),
      postcode: Yup.string().notRequired("Please Enter A Postcode"),
      country: Yup.string().notRequired("Please Enter A Country"),
      description: Yup.string().notRequired("Please Enter a Description"),
      skill_and_interest: Yup.string().notRequired("Please Enter Skills and Interests"),
      status: Yup.string().notRequired("Please Enter Status"),
      // events: Yup.mixed().notRequired(),
      assigned_to: Yup.mixed().notRequired("Please Assign Volunteer to a User"),
      start_time: Yup.string().notRequired("Please Enter Start Time"),
      end_time: Yup.string().notRequired("Please Enter End Time"),
      start_date: Yup.string().notRequired("Please Enter Start Date"),
      end_date: Yup.string().notRequired("Please Enter End Time"),
      campaign: Yup.mixed().notRequired(),
      resume: Yup.mixed()
        .notRequired()
        .test(
          "File Size",
          "Resume Attachment too large, should be less than 2MB",
          (value) => !resume || !(resume instanceof File) || (resume && resume[0].file.size <= 1024 * 1024 * 2)
        ),
    }),

    onSubmit: (values) => {
      const volunteer = values;
      set_address_errors(null);
      set_volunteer_errors(null);

      const volunteerFormData = new FormData();

      for (let v in volunteer) {
        if (v === "profile_pic" || v === "resume") continue;
        volunteerFormData.set(v, volunteer[v]);
      }
      if (profile_pic[0]) volunteerFormData.set("profile_pic", profile_pic[0]);
      if (resume[0]) volunteerFormData.set("resume", resume[0].file);

      if (props.updateVolunteer || id) {
        volunteerFormData.set("id", id);
        dispatch(updateVolunteer(volunteerFormData));
      } else {
        dispatch(addNewVolunteer(volunteerFormData));
      }
    },
  });

  const loadUsersOptions = (inputValue) => {
    return new Promise((resolve) => {
      const orgList = getUsers({ search: inputValue }).then(({ users: { users } }) => {
        return users;
      });
      resolve(orgList);
    });
  };

  const loadCampaignsOptions = (inputValue) => {
    return new Promise((resolve) => {
      const orgList = getCampaigns({ search: inputValue }).then(({ campaigns }) => {
        return campaigns;
      });
      resolve(orgList);
    });
  };

  useEffect(() => {
    if (isVolunteerSuccess || isVolunteerCreated || isVolunteerUpdate || isVolunteerAddFail || error) {
      if (error) {
        set_volunteer_errors(error?.errors?.volunteer_errors);
        set_address_errors(error?.errors?.address_errors);
      } else {
        //validation.resetForm();
      }

      setTimeout(() => {
        dispatch(resetVolunteerFlag());
      }, 3000);
    }
  }, [dispatch, isVolunteerSuccess, isVolunteerCreated, isVolunteerUpdate, isVolunteerAddFail, error]);

  useEffect(() => {
    if (!volunteersList) {
      dispatch(onGetVolunteers({ id }));
      return;
    }

    if (props.updateVolunteer) {
      if (volunteersList) {
        const v = volunteersList.find((v) => v.id.toString() === id.toString());
        const countryObj = countries.find((c) => c.value === v?.address.country);
        setVolunteer(v);
        setAssignedUser(v?.assigned_to);
        setAssignedCampaign(v?.campaign);
        setVolunteerCountry(countryObj);
      }
    }

    if (!usersList) dispatch(getUsersList());
    if (!campaignList) dispatch(onGetCampaigns());
  }, [dispatch, props.updateVolunteer, id, volunteersList, usersList, campaignList]);

  // const handleAssignEvent = (eventslabels) => {
  //   const assigned = eventslabels && eventslabels.map((item) => item.value);
  //   validation.setFieldValue("events", JSON.stringify(assigned));
  //   // setSelectedEvents(assigned);
  // };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  document.title = "Create Volunteer | SimplyNP";

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={props.title} pageTitle="Volunteers " />
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col lg={8}>
              {/* Profile Image */}
              <Card>
                <Row className="g-3 py-4">
                  <Col>
                    <div className="text-center">
                      <div className="position-relative d-inline-block">
                        <div className="position-absolute  bottom-0 end-0">
                          <Label htmlFor="customer-image-input" hidden={validation.errors.profile_pic} className="mb-0">
                            <div className="avatar-xs cursor-pointer">
                              <div className="avatar-title bg-light border rounded-circle text-muted">
                                <i className="ri-image-fill"></i>
                              </div>
                            </div>
                          </Label>

                          <Label
                            htmlFor="customer-image-input"
                            hidden={!validation.errors.profile_pic}
                            className="mb-0"
                          >
                            <div
                              className="avatar-xs cursor-pointer"
                              onClick={() => {
                                setProfile_pic("");
                                validation.setErrors({});
                                validation.setTouched({}, false);
                              }}
                            >
                              <div className="avatar-title bg-light border rounded-circle text-muted">
                                <i className="ri-bin-fill"></i>
                              </div>
                            </div>
                          </Label>
                          <Input
                            className="form-control d-none"
                            id="customer-image-input"
                            type="file"
                            accept="image/png, image/gif, image/jpeg, image/jpg"
                            onChange={(e) => setProfile_pic(e.target.files)}
                            name="profile_pic"
                            onBlur={validation.handleBlur}
                            invalid={validation.touched.profile_pic && validation.errors.profile_pic ? true : false}
                          />
                          {validation.touched.profile_pic && validation.errors.profile_pic ? (
                            <FormFeedback type={"invalid"}>{validation.errors.profile_pic}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="avatar-xl p-1">
                          <div className="avatar-title bg-light rounded-circle">
                            <img
                              src={validation.values.profile_pic || dummyImg}
                              alt="dummyImg"
                              id="customer-profile_pic"
                              className="avatar-lg rounded-circle object-cover"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Personal Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    {/* Salutation, Firt Name, Last Name */}
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.salutation}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter salutation"
                      label="Salutation"
                      name="salutation"
                      errorMessage={volunteer_errors}
                    />
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.first_name}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter First Name"
                      label="First Name"
                      name="first_name"
                      errorMessage={volunteer_errors}
                    />
                  </Row>
                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.last_name}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter Last Name"
                      label="Last Name"
                      name="last_name"
                      errorMessage={volunteer_errors}
                    />
                    {/*<Col>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="date_of_birth-input">
                          Date of Birth
                        </Label>
                        <Input
                          type="date"
                          className="form-control"
                          placeholder="Date of Birth"
                          name="date_of_birth"
                          value={validation.values.date_of_birth}
                          onChange={validation.handleChange}
                          invalid={validation.touched.date_of_birth && validation.errors.date_of_birth ? true : false}
                        />
                        {validation.touched.date_of_birth && validation.errors.date_of_birth ? (
                          <FormFeedback type="invalid">{validation.errors.date_of_birth}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>*/}

                    <DateField
                      mb="mb-3"
                      md="6"
                      value={validation.values.date_of_birth}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter date of birth"
                      label="Date of Birth"
                      name="date_of_birth"
                      errorMessage={volunteer_errors}
                    />
                  </Row>
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="gender-input">
                          Gender
                        </Label>

                        <Select
                          className={`${
                            validation.touched.gender && validation.errors.gender ? "form-control is-invalid" : ""
                          } `}
                          name="gender"
                          onChange={(e) => validation.setFieldValue("gender", e.value)}
                          options={genders}
                          placeholder="Select a Gender..."
                          id="gender-input"
                          //noOptionsMessage="Select Gender"
                          //validate={{required: {value: true}}}
                          value={genders?.find((m) => m.value === validation.values.gender)}
                          key={genders}
                          invalid={validation.touched.gender && validation.errors.gender ? true : false}
                          classNamePrefix="select2-selection form-select"
                        />

                        {validation.touched.gender && validation.errors.gender ? (
                          <FormFeedback type="invalid">{validation.errors.gender}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.language}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter language"
                      label="Language"
                      name="language"
                      errorMessage={volunteer_errors}
                    />
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Contact Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.email}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter email"
                      label="Email"
                      name="email"
                      errorMessage={volunteer_errors}
                    />
                    {/* <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.secondary_email}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter email "
                      label="Secondary Email"
                      name="secondary_email"
                      errorMessage={volunteer_errors}
                    /> */}
                  </Row>
                  <Row>
                    <IntNumberInput
                      md="6"
                      mb="mb-3"
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      value={validation.values.phone_number}
                      label="Phone Number"
                      name="phone_number"
                      errorMessage={volunteer_errors}
                    />
                    <IntNumberInput
                      md="6"
                      mb="mb-3"
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      value={validation.values.emergency_contact}
                      label="Emergency Contact"
                      name="emergency_contact"
                      errorMessage={volunteer_errors}
                    />
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Availability Schedule</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <TimeField
                      md="6"
                      mb="mb-3"
                      value={validation.values.start_time}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter start time"
                      label="Start Time"
                      name="start_time"
                      errorMessage={volunteer_errors}
                    />
                    <TimeField
                      md="6"
                      mb="mb-3"
                      value={validation.values.end_time}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter end time "
                      label="End Time"
                      name="end_time"
                      errorMessage={volunteer_errors}
                    />
                  </Row>
                  <Row>
                    <DateField
                      md="6"
                      mb="mb-3"
                      value={validation.values.start_date}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter start date"
                      label="Start Date"
                      name="start_date"
                      errorMessage={volunteer_errors}
                    />
                    <DateField
                      md="6"
                      mb="mb-3"
                      value={validation.values.end_date}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter end date"
                      label="End Date"
                      name="end_date"
                      errorMessage={volunteer_errors}
                    />
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Address Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.address_line}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter address"
                      label="Address"
                      name="address_line"
                      errorMessage={address_errors}
                    />
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.street}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter street"
                      label="Street"
                      name="street"
                      errorMessage={address_errors}
                    />
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.city}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter city"
                      label="City"
                      name="city"
                      errorMessage={address_errors}
                    />
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.state}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter state"
                      label="State"
                      name="state"
                      errorMessage={address_errors}
                    />
                    <TextField
                      md="6"
                      mb="mb-3"
                      value={validation.values.postcode}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter postcode"
                      label="Postcode"
                      name="postcode"
                      errorMessage={address_errors}
                    />
                    <Col>
                      <div className="mb-3 md-6">
                        <Label htmlFor="choices-lead-input" className="form-label">
                          Country
                        </Label>
                        <Select
                          className={`${
                            validation.touched.country && validation.errors.country ? "form-control is-invalid" : ""
                          } `}
                          id="choices-team-input"
                          value={volunteerCountry}
                          key={volunteerCountry}
                          name="country"
                          onChange={(e) => {
                            setVolunteerCountry(e);
                            validation.setFieldValue("country", e.value);
                          }}
                          options={countries}
                        />
                        {validation.touched.country && validation.errors.country ? (
                          <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Additional Information</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <TextField
                      mb="mb-3"
                      value={validation.values.department}
                      onChange={validation.handleChange}
                      validation={validation}
                      onBlur={validation.handleBlur}
                      placeholder="Enter Department"
                      label="Department"
                      name="department"
                      errorMessage={volunteer_errors}
                    />
                    <Col>
                      <div className="mb-3">
                        <Label htmlFor="status-field" className="form-label font-size-13">
                          Status
                        </Label>
                        <Select
                          name="status"
                          id="status-field"
                          placeholder="Status"
                          className="mb-2"
                          value={{
                            label: validation.values.status === "active" ? "Active" : "In-active",
                            value: validation.values.status,
                          }}
                          onChange={(e) => validation.setFieldValue("status", e.value)}
                          onBlur={validation.handleBlur}
                          options={[
                            { label: "Active", value: "active" },
                            { label: "In-active", value: "in-active" },
                          ]}
                        ></Select>
                        {validation.touched.status && validation.errors.status ? (
                          <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="mb-3">
                      <Label>Skills & Interests</Label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="SKills & Interests"
                        name="skill_and_interest"
                        value={validation.values.skill_and_interest}
                        onChange={validation.handleChange}
                        invalid={
                          validation.touched.skill_and_interest && validation.errors.skill_and_interest ? true : false
                        }
                      />
                      {validation.touched.skill_and_interest && validation.errors.skill_and_interest ? (
                        <FormFeedback type="invalid">{validation.errors.skill_and_interest}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>

                  <Row>
                    <div>
                      <Label>Description</Label>
                      <Input
                        type="textarea"
                        className="form-control"
                        placeholder="Description"
                        name="description"
                        value={validation.values.description}
                        onChange={validation.handleChange}
                        invalid={validation.touched.description && validation.errors.description ? true : false}
                        rows="5"
                      />
                      {validation.touched.description && validation.errors.description ? (
                        <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                      ) : null}
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Assign</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label htmlFor="assigned_to-input" className="form-label">
                      To a User(s)
                    </Label>
                    <AsyncSelect
                      id="assinged_to-input"
                      loadOptions={loadUsersOptions}
                      placeholder="Select a User..."
                      defaultOptions={usersList}
                      value={assignedUser}
                      isSearchable
                      isClearable
                      getOptionLabel={(option) =>
                        `${option?.user_details.first_name} ${option?.user_details.last_name}`
                      }
                      getOptionValue={(option) => option?.id}
                      onChange={(e) => {
                        setAssignedUser(e);
                        validation.setFieldValue("assigned_to", e?.id);
                      }}
                      name="assigned_to"
                      classNamePrefix="select2-selection form-select"
                    />
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Assign Campaign</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    {/* <Label htmlFor="assigned_to-input" className="form-label">
                    To a Volunteer(s)
                  </Label> */}
                    <AsyncSelect
                      id="campaign"
                      placeholder="Select a Campaign..."
                      defaultOptions={campaignList}
                      getOptionLabel={(option) => `${option?.title}`}
                      getOptionValue={(option) => option?.id}
                      value={assignedCampaign}
                      onChange={(e) => {
                        setAssignedCampaign(e);
                        validation.setFieldValue("campaign", JSON.stringify(e.map((e) => e?.id)));
                      }}
                      loadOptions={loadCampaignsOptions}
                      name="campaign"
                      classNamePrefix="select2-selection form-select"
                      isMulti
                      isClearable
                      isSearchablee
                      invalid={validation.touched.campaign && validation.errors.campaign ? true : false}
                    />
                    {validation.touched.campaign && validation.errors.campaign ? (
                      <FormFeedback type="invalid">{validation.errors.campaign}</FormFeedback>
                    ) : null}
                  </div>
                  {/* <SelectField
                  md="6"
                  mb="mb-3"
                  onChange={validation.handleChange}
                  validation={validation}
                  onBlur={validation.handleBlur}
                  placeholder="select..."
                  label="Events"
                  name="events"
                  defaultValue={selectedEvents}
                  key={selectedEvents}
                  options={eventsLabels}
                /> */}
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Resume</h5>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <div className="border-1-danger">
                      <p className="text-muted">Add Attached files here.</p>
                      <FilePond
                        files={resume}
                        dropValidation={true}
                        allowFileSizeValidation={true}
                        allowFileTypeValidation={true}
                        maxFileSize={1024 * 1000 * 2}
                        maxTotalFileSize={1024 * 1000 * 2}
                        acceptedFileTypes={[
                          "application/pdf",
                          //".docx",
                          //"application/doc",
                          //"application/msword",
                          //"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        ]}
                        onupdatefiles={(files) => {
                          setResume(files);
                          validation.setErrors({});
                          validation.setTouched({}, false);
                        }}
                        allowMultiple={false}
                        maxFiles={1}
                        name="resume"
                        className="filepond filepond-input-multiple"
                      />
                      <Input hidden invalid={validation.touched.resume && validation.errors.resume} />
                      {validation.touched.resume && validation.errors.resume ? (
                        <FormFeedback type={"invalid"} className="pb-2 mx-auto">
                          {validation.errors.resume}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <PdfPreview document={validation.values.resume} name="resume" />
                  </FormGroup>
                  {/*<DocViewer
                    documents={[{ uri: validation.values.resume }]}
                    initialActiveDocument={{ uri: validation.values.resume }}
                    pluginRenderers={DocViewerRenderers}
                  />*/}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={8}>
              <div className="text-end mb-3">
                <button type="reset" className="btn btn-danger w-sm me-1">
                  Clear
                </button>
                <button type="submit" className="btn btn-success w-sm">
                  {loading ? "Submitting" : "Submit"}
                </button>
              </div>
            </Col>
          </Row>
        </Form>

        {(validation.touched.profile_pic && validation.errors.profile_pic) ||
        (validation.touched.resume && validation.errors.resume) ? (
          <MsgToast
            msg={validation.errors.resume || validation.errors.profile_pic}
            color="danger"
            icon="ri-error-warning-line"
          />
        ) : null}
        {isVolunteerAdd ? (
          <MsgToast msg="Volunteer added Successfully" color="success" icon="ri-checkbox-circle-line" />
        ) : null}
        {isVolunteerAddFail ? (
          <MsgToast msg="Volunteer added Failed" color="danger" icon="ri-error-warning-line" />
        ) : null}
        {isVolunteerUpdate ? (
          <MsgToast msg="Volunteer updated Successfully" color="success" icon="ri-checkbox-circle-line" />
        ) : null}
        {isVolunteerUpdateFail ? <MsgToast msg={error} color="danger" icon="ri-close-circle-line" /> : null}
        <ToastContainer limit={1} closeButton={false} />
      </Container>
    </div>
  );
};

export default CreateVolunteer;
