import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

const EmptyPage = () => {
  return (
    <React.Fragment>
      <Container fluid>
        <div className="position-relative mx-n4 mt-n4">
          <div className="profile-wid-bg profile-setting-img">
            <div className="overlay-content">
              <div className="text-end p-3"></div>
            </div>
          </div>
        </div>
        <Row>
          <Col xxl={3}>
            <Card className="mt-n5">
              <CardBody className="p-4">
                <div className="text-center">
                  <h5 className="fs-16 mb-1">Ooops!!</h5>
                  <p className="text-muted mb-0">You are on the wrong page</p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default EmptyPage;
