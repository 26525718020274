import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import { createMembershipPaymentIntent } from "../../../helpers/backend_helper";

import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../../store/actions";

const PaymentForm = ({ membership }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { id: membership_pk } = useParams();

  const stripe = useStripe();
  const elements = useElements();

  const { org } = useSelector((state) => ({
    org: state.Profile.org,
  }));

  const [form, setForm] = useState({});
  const [membershipType, setMembershipType] = useState(null);

  const [clientSecret, setClientSecret] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [paymentError, setPaymentError] = useState(false);

  const [alert, setAlert] = useState({
    status: true,
    title: "Initializing",
    message: "Initializing Payment Form...",
    class: "spinner-border spinner-border-lg",
    textClass: "text-primary",
  });

  useEffect(() => {
    if (!org) dispatch(getProfile());

    if (membership) {
      if (!membershipType) {
        setMembershipType(membership?.membership_type);
      }
      setForm({
        full_name: `${membership?.member?.first_name} ${membership?.member?.last_name}`,
        email: membership?.member?.email,
        phone: membership?.member?.phone,
      });
    }
  }, [membership, membershipType, dispatch, org]);

  useEffect(() => {
    if (stripe && elements) {
      setAlert({ status: false });
    }
  }, [stripe, elements]);

  const validation = Yup.object().shape({
    email: Yup.string().email("Please enter a valid email").required("Please enter an email"),
    full_name: Yup.string().required("Please enter full name"),
  });

  const handleChange = async (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handlePaymentApproval = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    try {
      const f = await validation.validate(form);
      const cardNumberElement = elements.getElement(CardNumberElement);

      if (!membership) {
        return setAlert({
          status: true,
          title: "Error",
          message: "No Membership Found...",
          class: "ri-warning-fill-lg",
          textClass: "text-warning display-3",
        });
      }

      setAlert({
        status: true,
        title: "Loading...",
        message: "Your payment is being Processed.",
        class: "spinner-border spinner-border-lg",
        textClass: "text-primary",
      });

      // create a payment method
      const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod(
        {
          type: "card",
          card: cardNumberElement,
        }
        //{
        //stripeAccount: org?.connected_account[0].stripe_id,
        //}
      );

      if (paymentMethodError) {
        setAlert({
          status: true,
          title: "Failed",
          message: paymentMethodError.message,
          class: "spinner-border spinner-border-lg",
          textClass: "text-danger",
        });
      }

      // initialized payment
      const { client_secret } = await createMembershipPaymentIntent({
        membership: membership.id,
        payment_method: paymentMethod.id,
      });

      // confirm payment
      const { error: paymentIntentError, paymentIntent } = await stripe.confirmCardPayment(client_secret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: f.full_name,
            email: f.email,
          },
        },
      });

      // handle Errors
      if (paymentIntentError) {
        //console.log("STRIPE ERROR: ", paymentIntentError);
        setAlert({
          ...alert,
          status: true,
          message: paymentIntentError.message,
          title: "Error...",
          class: "ri-error-warning-line ri-3xl",
          textClass: "text-success",
          //back_url: paymentIntentError.request_log_url,
        });
      }

      if (paymentIntent) {
        //console.log("STRIPE PAYMENT_INTENT: ", paymentIntent);
        setAlert({
          ...alert,
          status: true,
          message: "Subscription Created Successfully..",
          title: "Done !",
          class: "ri-checkbox-circle-fill",
          textClass: "text-success",
        });
      }
    } catch (error) {
      setAlert({
        ...alert,
        status: true,
        message: error?.data.message || error.statusText,
        title: "Error !",
        class: "ri-error-warning-line ri-3xl",
        textClass: "text-danger display-3",
      });
    }
  };

  return (
    <React.Fragment>
      <Container fluid>
        <Modal
          isOpen={alert.status}
          centered
          toggle={alert.back_url ? () => null : () => setAlert({ ...alert, status: !alert.status })}
        >
          <ModalBody>
            <div className="text-center pt-4 pb-2">
              <div className="mb-4">
                <div className="avatar-lg mx-auto my-2">
                  <div className={`avatar-title bg-light ${alert.textClass} display-1 rounded-circle`}>
                    <span className="d-flex align-items-center">
                      <span className={`${alert.class} flex-shrink-0`} role="status">
                        <span className="visually-hidden">Loading...</span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <h5>{alert.title}</h5>
              <p className="text-muted">{alert.message}</p>

              <button
                hidden={!alert.back_url}
                className="btn btn-soft-primary mt-2"
                //onClick={() => history.push("/memberships", {})}
                onClick={() => (window.location = alert.back_url)}
              >
                Go Back
              </button>
            </div>
          </ModalBody>
        </Modal>

        <Card hidden={!stripe || !elements || alert.status === true}>
          <CardHeader>
            <Row className="">
              <h5 className="card-title text-start">Membership Checkout</h5>
              {/*<button type="button" className="btn btn-close text-end"></button>*/}
            </Row>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handlePaymentApproval}>
              <Row>
                {/* Item Details */}
                <Col>
                  <Row>
                    <h6>Payment Method</h6>
                  </Row>
                  <hr className="opacity-0" />
                  {/* Card Information */}
                  <Row>
                    <Col /* xxl={3} lg={6} */ className="">
                      <Card>
                        <CardBody>
                          <Row>
                            {/* - */}
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="card-owner-full-name">
                                Full name
                              </Label>
                              <Input
                                id="card-owner-full-name"
                                name="full_name"
                                type="text"
                                className="form-control"
                                value={form.full_name}
                                onChange={handleChange}
                                placeholder="Full name"
                                style={{ fontSize: 14, padding: "8px" }}
                              />
                              {validation.full_name && (
                                <FormFeedback type="invalid">
                                  <div>{validation.full_name}</div>
                                </FormFeedback>
                              )}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label" htmlFor="card-owner-email">
                                Email
                              </Label>
                              <Input
                                id="card-owner-email"
                                name="email"
                                type="email"
                                value={form.email}
                                onChange={handleChange}
                                className="form-control"
                                style={{ fontSize: 14, padding: "8px" }}
                                placeholder="Email"
                              />
                              {validation.email && (
                                <FormFeedback type="invalid">
                                  <div>{validation.email}</div>
                                </FormFeedback>
                              )}
                            </div>
                          </Row>
                          <Row>
                            {/* - */}
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="card-number">
                                Card Number
                              </Label>
                              <CardNumberElement id="card-number" className="form-control" />
                            </div>
                          </Row>
                          <Row>
                            {/* - */}
                            <Col>
                              <Label className="form-label" htmlFor="card-expiry">
                                Expiry Date
                              </Label>
                              <CardExpiryElement id="card-expiry" className="form-control" options={null} />
                            </Col>

                            {/* - */}
                            <Col>
                              <Label className="form-label" htmlFor="card-cvc">
                                CVC
                              </Label>
                              <CardCvcElement id="card-cvc" className="form-control" />
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  {/* More Information */}
                  {/*<Row>
                          <AddressElement options={{mode: 'billing'}} />
                        </Row>
                      */}
                </Col>

                {/* Product Details */}
                <Col>
                  <Row>
                    <h6>Product Details</h6>
                  </Row>
                  <hr className="opacity-0" />
                  <Row>
                    <Col>
                      <Card className="pricing-box ribbon-box right">
                        <CardBody className="bg-light m-2 p-4">
                          <div className="d-flex align-items-center mb-3">
                            <div className="flex-grow-1">
                              <h5 className="mb-0 fw-semibold">{membershipType?.name}</h5>
                            </div>
                            <div className="ms-auto">
                              <h6 className="month mb-0">${membershipType?.price}</h6>
                            </div>
                          </div>
                          <ul className="list-unstyled vstack gap-3">
                            {membershipType?.benefits?.map((benefit, key) => (
                              <li key={key}>
                                <div className="d-flex">
                                  <div className="flex-shrink-0 text-success me-1">
                                    <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                  </div>
                                  <div className="flex-grow-1 text-muted">
                                    <b>{benefit?.description}</b>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                          <hr />
                          <div className="d-flex align-items-center mb-3">
                            <div className="flex-grow-1">
                              <h6 className="mb-0">Total</h6>
                            </div>
                            <div className="ms-auto">
                              <h6 className="mb-0">
                                ${membershipType?.price}
                                <small className="fs-13 text-muted"></small>
                              </h6>
                            </div>
                          </div>
                          <div className="mt-3 pt-2">
                            <Button type="submit" className="btn btn-soft-success w-100 border-0" disabled={!stripe}>
                              Buy Now
                            </Button>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default PaymentForm;
