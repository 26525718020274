import {
  CREATE_ORGANIZAION,
  UPDATE_ORGANIZAION,
  CREATE_ORGANIZAION_ERROR,
  CREATE_ORGANIZAION_FAILED,
  CREATE_ORGANIZAION_FLAG,
  CREATE_ORGANIZAION_SUCCESSFUL,
  CREATE_ORGANIZAION_ADDRESS,
  UPDATE_ORGANIZAION_ADDRESS,
  UPDATE_ORG_ADDRESS_SUCCESSFUL,
  UPDATE_ORGANIZATION_SUCCESSFUL,
  UPDATE_ORGANIZATION_ERROR,
} from "./actionTypes";

export const createOrginzation = (data) => {
  return {
    type: CREATE_ORGANIZAION,
    payload: { data },
  };
};
export const updateOrg = (data) => {
  return {
    type: UPDATE_ORGANIZAION,
    payload: { data },
  };
};
export const updateOrgSuccessful = (data) => {
  return {
    type: UPDATE_ORGANIZATION_SUCCESSFUL,
    payload: { data },
  };
};

export const updateOrginzationError = (data) => {
  return {
    type: UPDATE_ORGANIZATION_ERROR,
    payload: data,
  };
};

export const updateOrgAddress = (data) => {
  return {
    type: UPDATE_ORGANIZAION_ADDRESS,
    payload: { data },
  };
};

export const createOrgAddress = (data) => {
  return {
    type: CREATE_ORGANIZAION_ADDRESS,
    payload: { data },
  };
};

export const createOrginzationSuccessful = (data) => {
  return {
    type: CREATE_ORGANIZAION_SUCCESSFUL,
    payload: data,
  };
};

export const updateOrgAddressSuccessful = (data) => {
  return {
    type: UPDATE_ORG_ADDRESS_SUCCESSFUL,
    payload: data,
  };
};

export const createOrginzationFailed = (error) => {
  return {
    type: CREATE_ORGANIZAION_FAILED,
    payload: error.message,
  };
};

export const resetCreateOrginzationError = () => {
  return {
    type: CREATE_ORGANIZAION_FLAG,
  };
};

export const createOrginzationError = (error) => {
  return {
    type: CREATE_ORGANIZAION_ERROR,
    payload: error,
  };
};
