import React from "react";
import CreateOrgForm from "./FormComponents/CreateOrgForm";

const CreateOrg = () => {
  document.title = "Create Organization | Velzon - React Admin & Dashboard Template";

  return (
    // <div className="page-content">
    <CreateOrgForm title="Create organization" />
    // </div>
  );
};

export default CreateOrg;
