import {
  CREATE_ORGANIZAION,
  CREATE_ORGANIZAION_SUCCESSFUL,
  CREATE_ORGANIZAION_ERROR,
  CREATE_ORGANIZAION_FAILED,
  CREATE_ORGANIZAION_FLAG,
  UPDATE_ORG_ADDRESS_SUCCESSFUL,
  UPDATE_ORGANIZATION_SUCCESSFUL,
  UPDATE_ORGANIZATION_ERROR,
} from "./actionTypes";

const initialState = {
  createOrgError: null,
  message: null,
  loading: false,
  user: null,
  success: false,
  error: false,
  isAddressUpdated: false,
};

const Organisation = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ORGANIZAION:
      state = {
        ...state,
        loading: true,
        createOrgError: null,
      };
      break;
    case CREATE_ORGANIZAION_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: action.payload.data,
        success: true,
        createOrgError: null,
      };
      break;
    case UPDATE_ORG_ADDRESS_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        response: action.payload,
        isAddressUpdated: true,
      };
      break;
    case UPDATE_ORGANIZATION_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        response: action.payload,
        isOrgUpdated: true,
      };
      break;

    case UPDATE_ORGANIZATION_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        isOrgUpdated: false,
      };
      break;
    case CREATE_ORGANIZAION_ERROR:
      state = {
        ...state,
        user: null,
        loading: false,
        error: true,
      };
      break;
    case CREATE_ORGANIZAION_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        createOrgError: true,
        message: action.payload,
        error: false,
      };
      break;
    case CREATE_ORGANIZAION_FLAG:
      state = {
        ...state,
        success: false,
        error: false,
        isOrgUpdated: false,
        isAddressUpdate: false,
        createOrgError: false,
        isAddressUpdated: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Organisation;
