import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import countries from "../../../common/data/countries.json";
// Import Images

import { debounce } from "lodash";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";

//Import actions
import {
  deleteContact as onDeleteContact,
  getContacts as onGetContacts,
  getUsersList as onGetUsers,
  resetCrmFlag,
} from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";

// Formik

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Components/Common/Loader";
import MsgToast from "../../../Components/Common/MsgToast";
import CrmFilter from "./CrmFilter";

// import { crmcontacts as xcrmcontacts } from "../../../common/data/crm";

const ContactList = () => {
  const dispatch = useDispatch();
  const {
    crmcontacts,
    crmusers,
    isContactCreated,
    isContactSuccess,
    error,
    page,
    isContactAdd,
    isContactAddFail,
    isContactDelete,
    isContactDeleteFail,
    isContactUpdate,
    isContactUpdateFail,
  } = useSelector((state) => ({
    crmcontacts: state.Crm.crmcontacts,
    page: state.Crm.contacts_page_info,
    crmusers: state.Users.usersList,
    isContactCreated: state.Crm.isContactCreated,
    isContactSuccess: state.Crm.isContactSuccess,
    error: state.Crm.error,
    isContactAdd: state.Crm.isContactAdd,
    isContactAddFail: state.Crm.isContactAddFail,
    isContactDelete: state.Crm.isContactDelete,
    isContactDeleteFail: state.Crm.isContactDeleteFail,
    isContactUpdate: state.Crm.isContactUpdate,
    isContactUpdateFail: state.Crm.isContactUpdateFail,
  }));

  const [contact, setContact] = useState(null);
  const [isInfoDetails, setIsInfoDetails] = useState(false);

  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const [sortBy, setSortBy] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetCrmFlag());
    }, 3000);
  }, [
    dispatch,
    isContactSuccess,
    error,
    isContactAdd,
    isContactAddFail,
    isContactDelete,
    isContactDeleteFail,
    isContactUpdate,
    isContactUpdateFail,
  ]);

  useEffect(() => {
    if (!crmcontacts) {
      dispatch(onGetContacts());
    }

    if (!crmusers) {
      dispatch(onGetUsers());
    }
  }, [dispatch, isContactAdd, isContactDelete, crmcontacts, crmusers]);

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return (
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="checkAll" value="option1" />
            </div>
          );
        },
      },
      {
        Header: "Salutation",
        accessor: "salutation",
        filterable: false,
      },
      {
        Header: "Name",
        accessor: "first_name",
        filterable: false,
        Cell: (contact) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {
                  contact.row.original.image_src ? (
                    <img
                      src={process.env.REACT_APP_API_URL + "/images/users/" + contact.row.original.image_src}
                      alt=""
                      className="avatar-xxs rounded-circle"
                    />
                  ) : (
                    <div className="flex-shrink-0 avatar-xs me-2">
                      <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                        {contact.row.original.first_name.charAt(0)}
                      </div>
                    </div>
                  )
                  // <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
                }
              </div>
              <div className="flex-grow-1 ms-2 name">{`${contact.row.original.first_name} ${contact.row.original.last_name}`}</div>
            </div>
          </>
        ),
      },
      {
        Header: "Email ID",
        accessor: "email",
        filterable: false,
      },
      {
        Header: "Mobile Number",
        accessor: "phone",
        filterable: false,
      },
      {
        Header: "Title",
        accessor: "title",
        filterable: false,
      },
      {
        Header: "Department",
        accessor: "department",
        // Cell: (contact) => (
        //   <>
        //     {contact.row.original.tags.map((item, key) => (<span className="badge badge-soft-primary me-1" key={key}>{item}</span>))}
        //   </>
        // ),
      },
      {
        Header: "Country",
        accessor: "country",
        Cell: (cellProps) => {
          return countries.find((c) => c?.value === cellProps.row.original?.address?.country)?.label;
        },
      },
      {
        Header: "Date Of Birth",
        accessor: "date_of_birth",
        // Cell: (contact) => (
        //   <>
        //     {handleValidDate(contact.row.original.last_contacted)},{" "}
        //     <small className="text-muted">{handleValidTime(contact.row.original.last_contacted)}</small>
        //   </>
        // ),
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Call">
                <Link to="#" className="text-muted d-inline-block">
                  <i className="ri-phone-line fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item edit" title="Message">
                <Link to="#" className="text-muted d-inline-block">
                  <i className="ri-question-answer-line fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle href="#" className="btn-soft-secondary btn-sm dropdown" tag="button">
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <Link to={`/donor-contacts-edit/${cellProps.row.original.id}`}>
                      <DropdownItem className="dropdown-item edit-item-btn">
                        <i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Edit
                      </DropdownItem>
                    </Link>
                    <DropdownItem
                      className="dropdown-item remove-item-btn"
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        onClickDelete(contactData);
                      }}
                    >
                      <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const handlesortBy = (sortBy) => {
    setSortBy(sortBy);
  };

  const sortbyname = [
    {
      options: [
        { label: "Owner", value: "Owner" },
        { label: "Company", value: "Company" },
        { label: "Location", value: "Location" },
      ],
    },
  ];

  // Delete Data
  const handleDeleteContact = () => {
    if (contact) {
      dispatch(onDeleteContact(contact.id));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(onGetContacts({ limit, offset }));
    },
    [dispatch]
  );

  const handler = useCallback(
    debounce((value) => {
      dispatch(onGetContacts({ search: value }));
    }, 3000),
    []
  );

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    handler(value);
  };
  const toggleInfo = () => {
    setIsInfoDetails(!isInfoDetails);
  };

  document.title = "Contacts | Velzon - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteContact}
          onCloseClick={() => setDeleteModal(false)}
        />

        <Container fluid>
          <BreadCrumb title="Contacts" pageTitle="CRM" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-1">
                      <Link to="/donor-contacts-create">
                        <button className="btn btn-info add-btn">
                          <i className="ri-add-fill me-1 align-bottom"></i> Add Contacts
                        </button>
                      </Link>
                    </div>
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                        <button className="btn btn-soft-danger">
                          <i className="ri-delete-bin-2-line"></i>
                        </button>
                        <div className="flex-shrink-0">
                          <button type="button" className="btn btn-info" onClick={toggleInfo}>
                            <i className="ri-filter-3-line align-bottom me-1"></i> Fliters
                          </button>
                        </div>
                        <button className="btn btn-soft-success">Import</button>

                        <UncontrolledDropdown>
                          <DropdownToggle href="#" className="btn btn-soft-info" tag="button">
                            <i className="ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" href="#">
                              All
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Week
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Month
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Year
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col xxl={12}>
              <Card id="contactList">
                <CardHeader>
                  <Row className="g-3">
                    <Col md={4}>
                      <div className="search-box">
                        <Input
                          onChange={(e) => {
                            handleFieldSearch(e);
                          }}
                          type="text"
                          className="form-control search"
                          placeholder="Search for contact..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    <div className="col-md-auto ms-auto">
                      <div className="d-flex align-items-center gap-2">
                        <span className="text-muted">Sort by: </span>
                        <Select
                          className="mb-0"
                          value={sortBy}
                          onChange={() => {
                            handlesortBy();
                          }}
                          options={sortbyname}
                          id="choices-single-default"
                        ></Select>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    {isContactSuccess || crmcontacts ? (
                      <TableContainer
                        columns={columns}
                        data={crmcontacts || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        customPageSize={10}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3 z-50"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        customFetchData={fetchData}
                        customTotalSize={page?.count}

                        // handleContactClick={handleContactClicks}
                      />
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>

                  {isContactDelete ? (
                    <MsgToast msg="Contact Deleted Successfully" color="success" icon="ri-checkbox-circle-line" />
                  ) : null}
                  {isContactDeleteFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
                  <ToastContainer limit={1} closeButton={false} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CrmFilter show={isInfoDetails} onCloseClick={() => setIsInfoDetails(false)} />
    </React.Fragment>
  );
};

export default ContactList;
