import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_USERS_LIST, ADD_NEW_USER, DELETE_USER, UPDATE_USER, GET_USER } from "./actionType";

import {
  UsersApiResponseSuccess,
  UsersApiResponseError,
  addUserSuccess,
  addUserFail,
  getUser as getUserAction,
  getUsersList as getUserListAction,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  addNewUserSuccess,
  addNewUserFail,
} from "./action";

import {
  getUsers as getUsersListApi,
  getUser as getUserApi,
  createUser,
  updateUser,
  deleteUser,
} from "../../helpers/backend_helper";

function* getUsersList({ payload: params }) {
  try {
    const response = yield call(getUsersListApi, params);
    const active_users = response.users.users;
    yield put(
      UsersApiResponseSuccess(GET_USERS_LIST, {
        users: [...active_users],
        page: { offset: response.users.offset, count: response.users.users_count },
      })
    );
  } catch (error) {
    yield put(UsersApiResponseError(GET_USERS_LIST, error));
  }
}

function* getUserWorker({ payload: user }) {
  try {
    const response = yield call(getUserApi, user);
    yield put(UsersApiResponseSuccess(GET_USER, response));
  } catch (error) {
    yield put(UsersApiResponseError(GET_USER, error));
  }
}

function* addNewUserWorker({ payload: user }) {
  try {
    const response = yield call(createUser, user);
    yield put(addNewUserSuccess(response));
  } catch (error) {
    yield put(addNewUserFail(error));
  }
}

function* updateUserWorker({ payload: user }) {
  // user payload here is a FormData Object - hence "user.get("id")
  try {
    const response = yield call(updateUser, user.get("id"), user);
    yield put(updateUserSuccess(response));
    yield put(getUserListAction());
  } catch (error) {
    yield put(updateUserFail(error));
  }
}

function* deleteUserWorker({ payload: user }) {
  try {
    const response = yield call(deleteUser, user.id);
    yield put(deleteUserSuccess({ user, ...response }));
    yield put(getUserListAction());
  } catch (error) {
    yield put(deleteUserFail(error));
  }
}

export function* watchGetUser() {
  yield takeEvery(GET_USER, getUserWorker);
}

export function* watchGetUsersList() {
  yield takeEvery(GET_USERS_LIST, getUsersList);
}

export function* watchAddNewUser() {
  yield takeEvery(ADD_NEW_USER, addNewUserWorker);
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, updateUserWorker);
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUserWorker);
}

function* UsersSaga() {
  yield all([
    fork(watchGetUser),
    fork(watchGetUsersList),
    fork(watchAddNewUser),
    fork(watchUpdateUser),
    fork(watchDeleteUser),
  ]);
}

export default UsersSaga;
