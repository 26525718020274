import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { Card, CardBody, CardHeader, Col, Container, Input, Row } from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import OffCanvasFilter from "./clients/OffCanvasFilter";

//Import actions
import { getMembers as onGetMembers, resetMembershipFlag, getServicesStats } from "../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";

// Formik

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Components/Common/Loader";
import MsgToast from "../../Components/Common/MsgToast";

const ServicesStats = () => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [toggleFilterCanvas, setToggleFilterCanvas] = useState(false);

  const { servicesStats, count, error } = useSelector((state) => ({
    servicesStats: state.Statistics.servicesStats,
    count: state.Statistics.count,

    error: state.Membership.error,
    page: state.Membership.page,
    loading: state.Membership.loading,
  }));

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetMembershipFlag());
    }, 3000);
  }, [dispatch]);

  useEffect(() => {
    dispatch(getServicesStats());
  }, []);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(getServicesStats({ offset, limit }));
    },
    [dispatch]
  );

  // Column
  const columns = useMemo(
    () => [
      // {
      //   Header: "service",
      //   Cell: (cellProps) => {
      //     const { service_name, service_id } = cellProps.row.original;
      //     return (
      //       <ul className="list-inline hstack gap-3 mb-0">
      //         <li className="list-inline-item edit" title="Add Note">
      //           <Link
      //             to={{
      //               pathname: "/clients-details-page/" + service_id,
      //               state: { caseItem: cellProps.row.original, clientItem: cellProps.row.original.client },
      //             }}
      //             className="text-muted d-inline-block"
      //           >
      //             {service_name}
      //           </Link>
      //         </li>
      //       </ul>
      //     );
      //   },
      // },
      {
        Header: "service name",
        accessor: "service_name",
        filterable: false,
      },
      {
        Header: "open cases",
        accessor: "open_cases",
        filterable: false,
      },
      {
        Header: "pending cases",
        accessor: "pending_cases",
        filterable: false,
      },
      {
        Header: "closed cases",
        accessor: "closed_cases",
        filterable: false,
      },

      {
        Header: "total cases",
        accessor: "total_cases",
        filterable: false,
      },
      {
        Header: "total clients",
        accessor: "total_clients",
        filterable: false,
      },
    ],
    []
  );

  const handler = useCallback(
    debounce((value) => {
      dispatch(getServicesStats({ search: value }));
    }, 500),
    []
  );

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    setSearch(value);
    handler(value);
  };

  document.title = "Service stats - statistics | SimplyNP";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Service stats" pageTitle="Service stats" />
          <Row>
            <Col>
              <Card id="memberList">
                <CardHeader>
                  <Row className="g-3">
                    <Col md={4}>
                      <div className="search-box">
                        <Input
                          onChange={(e) => {
                            handleFieldSearch(e);
                          }}
                          type="text"
                          className="form-control search"
                          placeholder="Search for service..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    {servicesStats ? (
                      <TableContainer
                        columns={columns}
                        data={servicesStats || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        customFetchData={fetchData}
                        customTotalSize={count}
                        customPageSize={10}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                      />
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>

                  {/* {fetchingMemberList ?  : ""} */}

                  <ToastContainer limit={1} closeButton={false} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <OffCanvasFilter show={toggleFilterCanvas} onCloseClick={() => setToggleFilterCanvas(false)} />
    </React.Fragment>
  );
};

export default ServicesStats;
