import React from "react";
import { Container } from "reactstrap";
// import BreadCrumb from "../../../Components/Common/BreadCrumb";
import CreateForm from "./form";

const CreateContact = () => {
  document.title = "Create Contact | SimplyNP - Xacent Global ";

  return <CreateForm title="Create Contact" />;
};

export default CreateContact;
