export const genders = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Trans Male", value: "Trans Male" },
  { label: "Trans Female", value: "Trans Female" },
  { label: "Gender Nonconforming", value: "Gender Nonconforming" },
  { label: "Other", value: "Other" },
  { label: "Decline to Answer", value: "Decline to Answer" },
];

export const contactStageOptions = [
  { label: "Contact", value: "contact" },
  { label: "Lead", value: "lead" },
];

export const statusOptions = [
  { label: "Active", value: "active" },
  { label: "In-Active", value: "in-active" },
];

export const groupTypeOptions = [
  { label: "No Group", value: "no-group" },
  { label: "Organization Group", value: "organization-group" },
  { label: "Household Group", value: "household-group" },
];

export const enrollmentTypeOptions = [
  { label: "Renewal", value: "renewal" },
  { label: "Register", value: "register" },
];

export const paymentTypeOptions = [
  { label: "Credit Card - Online", value: "credit-card-online" },
  { label: "Cash - Offline", value: "cash-offline" },
  //{ label: "Wire Transfer - Online", value: "wire-transfer-online" },
];

export const yesOrNoOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const approvedOptions = [
  { label: "Approved", value: true },
  { label: "Un-Approved", value: false },
];

export const durationTypeOptions = [
  { label: "Fixed", value: "Fixed" },
  { label: "Rolling", value: "Rolling" },
];

export const durationUnitOptions = [
  { label: "Day(s)", value: "day" },
  { label: "Month(s)", value: "month" },
  { label: "Year(s)", value: "year" },
  // { label: "Life", value: "Life" },
];
