import React, { useState } from "react";
import { Card, Col, Collapse, Container, Row } from "reactstrap";
import classnames from "classnames";
import logoDark from "../../assets/images/logo-dark.png";
import { withRouter, Link } from "react-router-dom";

const Terms = () => {
  document.title = "Terms | SimplyNP";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoDark} alt="" height="60" />
                  </Link>
                </div>
                <p className="mt-3 fs-15 fw-medium text-dark">The better way to manage your NON-Profit</p>
              </div>
            </Col>
          </Row>

          {/* <BreadCrumb title="Terms of Services" pageTitle="Terms" /> */}
          <Row>
            <Col xs={12}>
              <section className="section bg-light">
                <div className="container mb-4">
                  <div className="row justify-content-center">
                    <div className="col-lg-8">
                      <div className="text-center mb-5">
                        <p className="mb-4 fs-6 ff-secondary text-success">EFFECTIVE DATE: OCTOBER 10, 2022</p>
                        <h1 className="display-6 mb-3 fw-semibold">North American Terms of Service</h1>
                        <p className="text-muted mb-4 fs-4 ff-secondary">For customers using app.simplynp.com</p>
                      </div>
                    </div>
                  </div>

                  <div className="container mb-5">
                    <div className="card">
                      <div className="card-body fs-5 lead">
                        <p align="center">
                          For customers using eu.app.SimplyNP.com, please refer to our{" "}
                          <a href="https://simplynp.com/tos/eu.html">EMEA Terms of Service.</a>
                        </p>
                        <p>
                          The following “User License Agreement” governs your use of the software and services provided
                          by Xacent Global Inc. (and its subsidiaries) (“Xacent”) also known as SimplyNP. This is a
                          legal agreement between you and Xacent and incorporates the Privacy Policy at
                          <a href="https://simplynp.com/privacy/" target="_blank">
                            simplynp.com/privacy
                          </a>
                          and the attached Exhibits. By registering your use of the Service (as defined below), you are
                          accepting to be bound to the terms of this User License Agreement.
                        </p>
                        <p className="fs-4">
                          <strong>1. Definitions</strong>
                        </p>
                        <p>
                          (a) “Administrator” shall mean a Subscriber (as defined in Section 1(i)) with authority to
                          designate additional Authorized Users and/or Administrators, and commit the Subscriber to
                          additional services from Xacent.
                          <br />
                          (b) “Agreement” shall mean this entire User License Agreement and incorporates by reference
                          the Privacy Policy located at
                          <a href="https://simplynp.com/privacy/" target="_blank">
                            simplynp.com/privacy
                          </a>
                          and the attached Exhibits.
                        </p>
                        <ul type="disc">
                          <li>
                            <a href="https://simplynp.com/tos/service-level-commitments.html">
                              Exhibit A – Xacent Service Level Commitments and Support Services
                            </a>
                          </li>
                          <li>
                            <a href="https://simplynp.com/tos/data-protection-addendum.html">
                              Exhibit B – Xacent Data Protection Addendum for GDPR Compliance
                            </a>
                          </li>
                          <li>
                            <a href="https://simplynp.com/tos/subprocessors.html">
                              Exhibit C – Xacent Authorized Subprocessors
                            </a>
                          </li>
                        </ul>
                        <p>
                          (c) “Authorized User” shall mean an individual subscriber or the partners, members, employees,
                          temporary employees, and independent contractors of an organization with a subscription to the
                          Service who have been added to the account as users.
                        </p>
                        <p>
                          (d) “Confidential Information” shall mean the Content (as defined in Section 1(e)) and any
                          information, technical data, or know-how considered proprietary or confidential by either
                          party to this Agreement including, but not limited to, either party’s research, services,
                          inventions, processes, specifications, designs, drawings, diagrams, concepts, marketing,
                          techniques, documentation, source code, customer information, personally identifiable
                          information, pricing information, procedures, menu concepts, business and marketing plans or
                          strategies, financial information, and business opportunities disclosed by either party before
                          or after the Effective Date of this Agreement, either directly or indirectly in any form
                          whatsoever, including in writing, orally, machine-readable form or through access to either
                          party’s premises.
                        </p>
                        <p>
                          (e) “Content” shall mean any information you upload or post to the Service and any information
                          provided by you to Xacent in connection with the Service, including, without limitation,
                          information about your Authorized Users or Registered Clients, as defined in Section 1(g).
                        </p>
                        <p>
                          (f) “Primary Subscriber” shall mean the Subscriber who initiated the Services offered by
                          Xacent and is assumed by Xacent to have the sole authority to administer the subscription.
                        </p>
                        <p>
                          (g) “Registered Client” means an individual who has been invited to use the client-facing
                          features of the Service in a limited capacity as a client of an Authorized User.
                        </p>
                        <p>
                          (h) “Service” shall mean any software or services provided by Xacent, including but not
                          limited to SimplyNP Manage, non-profit management software, and Velcrm, customer relationship
                          management (CRM) and client intake software.
                        </p>
                        <p>
                          (i) “Subscriber” shall refer to the purchaser of the Services provided by Xacent and shall
                          also include any present or former agent, representative, independent contractor, employee,
                          servant, attorney and any entity or person who had authority to act on your behalf.
                        </p>
                        <p>
                          (j) “Security Emergency” shall mean a violation by Subscriber of this Agreement that (a) could
                          disrupt (i) Xacent’s provision of the Service; (ii) the business of other subscribers to the
                          Service; or (iii) the network or servers used to provide the Service; or (b) provides
                          unauthorized third party access to the Service.
                        </p>
                        <p className="fs-4">
                          <strong>2. Limited License &amp; Use of the Service</strong>
                        </p>
                        <p>
                          2.1 Subscriber is granted a non-exclusive, non-transferable, limited license to access and use
                          the Service.
                        </p>
                        <p>
                          2.2 Xacent does not review or pre-screen the Content and Xacent claims no intellectual
                          property rights with respect to the Content.
                        </p>
                        <p>
                          2.3 Authorized Users agree not to reproduce, duplicate, copy, sell, resell or exploit access
                          to the Service, use of the Service, or any portion of the Service, including, but not limited
                          to the HTML, Cascading Style Sheet (“CSS”) or any visual design elements without the express
                          written permission from Xacent.
                        </p>
                        <p>
                          2.4 Authorized Users agree not to modify, reverse engineer, adapt or otherwise tamper with the
                          Service or modify another website so as to falsely imply that it is associated with the
                          Service, Xacent, or any other software or service provided by Xacent.
                        </p>
                        <p>
                          2.5 Authorized Users agree that they will not knowingly use the Service in any manner which
                          may infringe copyright or intellectual property rights or in any manner which is unlawful,
                          offensive, threatening, libelous, defamatory, pornographic, obscene or in violation of the
                          terms of this Agreement.
                        </p>
                        <p>
                          2.6 Authorized Users agree that they will not knowingly use the Service to upload, post, host,
                          or transmit unsolicited bulk email “Spam”, short message service “SMS” messages, viruses,
                          self-replicating computer programs “Worms” or any code of a destructive or malicious nature.
                        </p>
                        <p>
                          2.7 Except for the non-exclusive license granted pursuant to this Agreement, Subscriber
                          acknowledges and agrees that all ownership, license, intellectual property and other rights
                          and interests in and to the Service shall remain solely with Xacent.
                        </p>
                        <p>
                          2.8 Authorized Users who configure the Service to share or make available certain Content to
                          the public, are deemed to acknowledge and agree that everyone will have access to the Content
                          (“Public Content”). It is the responsibility of the Authorized User to determine if the
                          Service being shared is appropriate for each Registered User. Xacent reserves the right, at
                          any time, in its sole discretion, to take any action deemed necessary with respect to Public
                          Content that violates the terms of this Agreement, including, but not limited to, removal of
                          such Public Content.
                        </p>
                        <p>
                          2.9 Xacent reserves the right at any time, and from time to time, to modify or discontinue,
                          temporarily or permanently, any feature associated with the Service, with or without notice,
                          except that Xacent shall provide Subscriber with 30-days notice of any modification that
                          materially reduces the functionality of the Service. Continued use of the Service following
                          any modification constitutes Subscriber’s acceptance of the modification.
                        </p>
                        <p>
                          2.10 Xacent reserves the right to temporarily suspend access to the Service for operational
                          purposes, including, but not limited to, maintenance, repairs or installation of upgrades, and
                          will endeavor to provide no less than two business days notice prior to any such suspension.
                          Such notice shall be provided to you in advance through by way of notification within the
                          Service, email or other notification method deemed appropriate by Xacent. Further, Xacent
                          shall endeavor to confine planned operational suspensions with a best effort to minimize
                          disruption to the Subscriber, but reserves the ability to temporarily suspend operations
                          without notice at any time to complete necessary repairs. In the event of a temporary
                          suspension, Xacent will use the same notification methods listed in this section to provide
                          updates as to the nature and duration of any temporary suspension.
                        </p>
                        <p>
                          2.11 Xacent stores all Content on redundant storage servers. The Subscriber may elect to, at a
                          regular interval, replicate all Content associated with the subscription to a third party
                          storage service (“Escrow Agent”). The replicated Content (“Escrowed Data”) will be held under
                          the terms of a separate agreement exclusively between the Subscriber and the Escrow Agent
                          (“Escrow Agreement”). The Subscriber may also elect to replicate all Content associated with
                          the subscription on its own storage device.
                        </p>
                        <p>
                          2.12 Subscriber grants to Xacent a non-exclusive, royalty-free right during Subscriber’s use
                          of the Service, to use the Confidential Information for the sole purpose of performing Xacent’
                          obligations under the Agreement in accordance with the terms of the Agreement. Such rights
                          shall include permission for Xacent to generate and publish aggregate, anonymized reports on
                          system usage and Content trends and type, provided they do not conflict with Section 4.1.
                        </p>
                        <p>
                          2.13 Xacent uses one code-base for all jurisdictions. Subscriber is required, using settings
                          available within the Service, to configure the Service for its own jurisdiction and to verify
                          that the settings meet the Subscriber’s requirements. Xacent will highlight known features
                          that may require Subscriber review.
                        </p>
                        <p className="fs-4">
                          <strong>3. Access to the Service</strong>
                        </p>
                        <p>
                          3.1 Subscriber is only permitted to access and use the Service if he/she is an Authorized User
                          or a Registered Client. Authorized Users are required to provide their full legal name, a
                          valid email address, and any other information reasonably requested by the Service.
                        </p>
                        <p>
                          3.2 Each Authorized User will be provided with a unique identifier to access and use the
                          Service (“Username”). The Username shall only be used by the Authorized User to whom it is
                          assigned, and shall not be shared with, or used by any other person, including other
                          Authorized Users.
                        </p>
                        <p>
                          3.3 The initial Administrator shall be the Primary Subscriber with authority to administer the
                          subscription and designate additional Authorized Users and/or Administrators. Each
                          subscription may designate multiple Authorized Users as Administrator. Any Administrator shall
                          be deemed to have the authority to manage the subscription and any Authorized Users. The
                          Administrator will deactivate an active Username if the Administrator wishes to terminate
                          access to the Service for any Authorized User.
                        </p>
                        <p>
                          3.4 Administrators are responsible for all use of the Service by Authorized Users on the list
                          of active Authorized Users associated with their subscription to the Service.
                        </p>
                        <p>
                          3.5 As between Xacent and the Subscriber, any Content uploaded or posted to the Service
                          remains the property of the Subscriber. Upon Cancellation or Termination of Service as
                          discussed in Section 10 below, Xacent shall only be responsible for the return of Content
                          directly to the Administrator or a designated Authorized User in the event that the
                          Administrator is unable to be reached.
                        </p>
                        <p>
                          3.6 All access to and use of the Service via mechanical, programmatic, robotic, scripted or
                          any other automated means not provided as part of the Service is strictly prohibited.
                        </p>
                        <p>
                          3.7 Authorized Users are permitted to access and use the Service using an Application Program
                          Interface (“API”) subject to the following conditions:
                        </p>
                        <p>
                          (a) any use of the Service using an API, including use of an API through a third-party product
                          that accesses and uses the Service, is governed by these Terms of Service;
                        </p>
                        <p>
                          (b) Xacent shall not be liable for any direct, indirect, incidental, special, consequential or
                          exemplary damages, including but not limited to, damages for loss of profits, goodwill, use,
                          data or other intangible losses (even if Xacent has been advised of the possibility of such
                          damages), resulting from any use of an API or third-party products that access and use the
                          Service via an API;
                        </p>
                        <p>
                          (c) Excessive use of the Service using an API may result in temporary or permanent suspension
                          of access to the Service via an API. Xacent, in its sole discretion, will determine excessive
                          use of the Service via an API, and will make a reasonable attempt to warn the Authorized User
                          prior to suspension; and
                        </p>
                        <p>
                          (d) Xacent reserves the right at any time to modify or discontinue, temporarily or
                          permanently, access and use of the Service via an API, with or without notice.
                        </p>
                        <p className="fs-4">
                          <strong>4. Confidentiality</strong>
                        </p>
                        <p>
                          4.1 Each party agrees to treat all Confidential Information as confidential and not to use or
                          disclose such Confidential Information except as necessary to perform its obligations under
                          this Agreement.
                        </p>
                        <p>
                          4.2 Xacent and any third party vendors and hosting partners it utilizes to provide the Service
                          shall hold Content in strict confidence and shall not use or disclose Content except (a) as
                          required to perform their obligations under this Agreement; (b) in compliance with Section 7
                          of this Agreement, or (c) as otherwise authorized by you in writing.
                        </p>
                        <p className="fs-4">
                          <strong>5. Security and Access</strong>
                        </p>
                        <p>
                          5.1 Xacent is responsible for providing a secure method of authentication and accessing its
                          Service. Xacent will provide mechanisms that:
                        </p>
                        <p>
                          (a) allow for user password management
                          <br />
                          (b) transmit passwords in a secure format
                          <br />
                          (c) protect passwords entered for purposes of gaining access to the Service by utilizing code
                          that follows password management best practices.
                        </p>
                        <p>
                          5.2 Subscriber will be responsible for protecting the security of usernames and passwords, or
                          any other codes associated to the Service, and for the accuracy and adequacy of personal
                          information provided to the Service.
                        </p>
                        <p>
                          5.3 Subscriber will implement policies and procedures to prevent unauthorized use of usernames
                          and passwords, and will promptly notify Xacent upon suspicion that a username and password has
                          been lost, stolen, compromised, or misused.
                        </p>
                        <p>
                          5.4 At all times, Xacent, and any third party vendors and hosting partners it utilizes to
                          provide the Service, will:
                        </p>
                        <p>
                          (a) use information security best practices for transmitting and storing your Content,
                          adhering to industry standards;
                        </p>
                        <p>
                          (b) employ information security best practices with respect to network security techniques,
                          including, but not limited to, firewalls, intrusion detection, and authentication protocols,
                          vulnerability and patch management;
                        </p>
                        <p>(c) ensure its host facilities maintain industry standards for security and privacy; and</p>
                        <p>
                          (d) within thirty (30) days of a request by Subscriber, provide Subscriber with a (SOC2 or
                          SOC3) audit report or industry standard successor report or a comparable description of its
                          security measures in respect of the data center facilities used to host the Service and the
                          Content. In order to obtain such a report, Subscriber must enter into an agreement with the
                          third party provider of the report.
                        </p>
                        <p>
                          5.5 Xacent shall report to Subscriber, with all relevant details (except those which could
                          prejudice the security of data uploaded by other customers), any event that Xacent reasonably
                          believes represents unauthorized access to, disclosure of, use of, or damage to Content (a
                          “Security Breach”). Xacent shall make such report within 72 hours after learning of the
                          Security Breach.
                        </p>
                        <p>
                          5.6 In the event of a Security Breach, Xacent shall (a) cooperate with Subscriber to identify
                          the cause of the breach and to identify any affected Content; (b) assist and cooperate with
                          Subscriber in investigating and preventing the recurrence of the Security Breach; (c) assist
                          and cooperate with Subscriber in any litigation or investigation against third parties that
                          Subscriber undertake to protect the security and integrity of Content; and (d) use
                          commercially reasonable endeavours to mitigate any harmful effect of the Security Breach.
                        </p>
                        <p className="fs-4">
                          <strong>6. EU Data Protection</strong>
                        </p>
                        <p>
                          The parties agree to comply with the provisions of the Data Processing Addendum set out in{" "}
                          <a href="https://simplynp.com/tos/data-protection-addendum.html">Exhibit B</a>.
                        </p>
                        <p className="fs-4">
                          <strong>7. Legal Compliance</strong>
                        </p>
                        <p>
                          7.1 Xacent maintains that its primary duty is to protect the Content to the extent the law
                          allows. Xacent reserves the right to provide the Confidential Information to third parties as
                          required and permitted by law (such as in response to a subpoena or court order), and to
                          cooperate with law enforcement authorities in the investigation of any criminal or civil
                          matter.
                        </p>
                        <p>
                          If Xacent is required by law to make any disclosure of the Confidential Information that is
                          prohibited or otherwise constrained by this Agreement, then Xacent will provide Subscriber
                          with prompt written notice (to the extent permitted by law) prior to such disclosure so that
                          the Subscriber may seek a protective order or other appropriate relief. Subject to the
                          foregoing sentence, Xacent may furnish that portion (and only that portion) of the
                          Confidential Information that it is legally compelled or otherwise legally required to
                          disclose.
                        </p>
                        <p>
                          7.2 Xacent will only accept legal requests for production of Content or other Confidential
                          Information through the procedures listed on
                          <a href="https://simplynp.com/legal-service">https://simplynp.com/legal-service</a>.
                        </p>
                        <p className="fs-4">
                          <strong>8. Managed Backup and Archiving</strong>
                        </p>
                        <p>
                          8.1 Xacent’s managed backup services must be designed to facilitate restoration of Content to
                          the server or device from which the Content originated in the event the primary data is lost
                          or corrupted. Xacent shall ensure recovery of lost or corrupted Content at no cost to you.
                          Following any cancellation or termination of Service for any reason, Subscriber shall have
                          ninety days to retrieve any and all Content.
                        </p>
                        <p className="fs-4">
                          <strong>9. Payment, Refunds, and Subscription Changes</strong>
                        </p>
                        <p>
                          9.1 Subscribers with paid subscriptions will provide Xacent with a valid credit card for
                          payment of the applicable subscription fees. All subscription fees are exclusive of all
                          federal, state, provincial, municipal, or other taxes which Subscribers agree to pay based on
                          where the Subscriber is located. Invoices will include (i) subscription fees and (ii) all
                          applicable sales taxes, as amended from time to time, for the jurisdiction in which the
                          Subscriber is located. In the event of updated tax rates, Xacent will apply the new tax rate
                          without notice to the Subscriber. In addition to any fees, the Subscriber may still incur
                          charges incidental to using the Service, for example, charges for Internet access, data
                          roaming, and other data transmission charges.
                        </p>
                        <p>
                          9.2 Subscribers with monthly paying subscriptions will be charged upon the expiration of any
                          applicable free trial period. Subscriptions cancelled prior to the expiration of any trial
                          period, will not be charged.
                          <strong>
                            Monthly Subscribers will thereafter be charged in advance each 30 days. Annual Subscribers
                            will thereafter be charged annually on the anniversary date of the initial subscription
                            charge. All charges are final and non-refundable, including payments made by Annual
                            Subscribers, setup fees, and other professional services charges.
                          </strong>
                        </p>
                        <p>
                          Subscribers who purchased setup or professional services, like tailored live training,
                          customized forms and documents, or migration services, must initiate those services within
                          sixty (60) days (Service Window) following their purchase. Absent a separate invoice, the date
                          of purchase for setup or professional services will be deemed to be the initial date of entry
                          of a valid credit card for payment as required in §9.1.
                          <strong>
                            Failure of the Subscriber to initiate purchased setup or professional services within
                            Service Window will result in those services no longer being available and no refund will be
                            issued
                          </strong>
                          .
                        </p>
                        <p>
                          9.3 No refunds or credits will be issued for partial periods of service, upgrade/downgrade
                          refunds, or refunds for periods unused with an active subscription, including, but not limited
                          to, instances involving the removal of a Subscriber.
                        </p>
                        <p>
                          9.4 There are no charges for cancelling a subscription and paying subscriptions cancelled
                          prior to the end of their current billing cycle will not be charged again in the following
                          cycle.
                        </p>
                        <p>
                          9.5 The amount charged on the next billing cycle will be automatically updated to reflect any
                          changes to the subscription, including upgrades or downgrades, and including the addition or
                          removal of discounts included for the purchase of suite services. Adding Authorized User
                          subscriptions or subscription upgrades will trigger prorated charges in the current billing
                          cycle. Subscriber authorizes Xacent to apply updated charge amounts. Subscription changes,
                          including downgrades, may result in loss of access to Content, features, or an increase or
                          reduction in the amount of available capacity for Content provided by the Service.
                        </p>
                        <p>
                          9.6 All prices are subject to change upon notice. Such notice may be provided by an e-mail
                          message to the Administrator, or in the form of an announcement on the Service.
                        </p>
                        <p>
                          9.7 Subscriber is responsible for paying all taxes associated with the subscription to the
                          Service. If Xacent has the legal obligation to pay or collect taxes for which Subscriber is
                          responsible under this section, the appropriate amount shall be charged to and paid by
                          Subscriber, unless Subscriber provides Xacent with a valid tax exemption certificate
                          authorized by the appropriate taxing authority.
                        </p>
                        <p>
                          9.8 Any and all payments by or on account of the compensation payable under this Agreement
                          shall be made free and clear of and without deduction or withholding for any taxes. If the
                          Subscriber is required to deduct or withhold any taxes from such payments, then the sum
                          payable shall be increased as necessary so that, after making all required deductions or
                          withholdings, Xacent receives an amount equal to the sum it would have received had no such
                          deduction or withholding been made.
                        </p>
                        <p className="fs-4">
                          <strong>10. Cancellation and Termination</strong>
                        </p>
                        <p>
                          10.1 Administrators are solely responsible for canceling subscriptions. An Administrator may
                          cancel their subscription at any time by accessing the Service and visiting
                          <a href="https://app.simplynp.com/settings/subscription/edit" target="_blank">
                            app.simplynp.com/settings/subscription/edit
                          </a>
                          as applicable. For security reasons, cancellations shall only be performed by an Administrator
                          using the account cancellation URL within the Service. The Administrator may be directed,
                          within the Service, to call support to complete the cancellation. Cancellations shall not be
                          accepted by any other means.
                        </p>
                        <p>
                          10.2 Xacent in its sole discretion has the right to suspend or discontinue providing the
                          Service to any Subscriber without notice for actions that are (a) in material violation of
                          this Agreement and (b) create a Security Emergency.
                        </p>
                        <p>
                          10.3 If (i) Authorized Users use the Service to materially violate this Agreement in a way
                          that does not create a Security Emergency; (ii) Xacent provides Subscriber with commercially
                          reasonable notice of this violation; (iii) Xacent uses commercially reasonable efforts to
                          discuss and resolve the violation with Subscriber; and (iv) despite the foregoing, the
                          violation is not resolved to Xacent’s reasonable satisfaction within thirty (30) days of such
                          notice, then Xacent reserves the right to suspend access to the Service.
                        </p>
                        <p>
                          10.4 As required by Section 8 above (“Managed Backup and Archiving”), upon cancellation or
                          termination of a subscription, Content is made available to the Administrator or a designated
                          Authorized User. Following a period of no less than ninety (90) days from the cancellation or
                          termination of a subscription, all Content associated with such subscription will be
                          irrevocably deleted from the Service. All Escrowed Data, if any, will continue to remain
                          available for a period of six months upon cancellation or termination of a subscription in
                          accordance with the terms of the Escrow Agreement.
                        </p>
                        <p className="fs-4">
                          <strong>11. Limitation of Liability</strong>
                        </p>
                        <p>
                          11.1 Except in the case of a violation by Xacent of its obligations under Section 4 above
                          (“Confidentiality”), Section 5 above (“Security and Access”), and Section 8 above (“Managed
                          Backup and Archiving”), and except as provided in Section 13.2 below (“Indemnification”),
                          Xacent shall not be liable for and Subscriber waives the right to claim any loss, injury,
                          claim, liability or damage of any kind resulting in any way from the Services provided to
                          Subscriber by Xacent.
                        </p>
                        <p>
                          11.2 SUBSCRIBER AGREES THAT THE LIABILITY OF XACENT ARISING OUT OF ANY CLAIM IN ANY WAY
                          CONNECTED WITH THE SERVICE WILL NOT EXCEED THE TOTAL AMOUNT YOU HAVE PAID FOR THE SERVICE
                          PURSUANT TO THE AGREEMENT WITHIN THE SIX MONTH PERIOD BEFORE THE DATE THE CLAIM AROSE.
                          SUBSCRIBER FURTHER AGREES THAT XACENT IS NOT AND WILL NOT BE LIABLE FOR ANY SPECIAL, INDIRECT,
                          INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER (INCLUDING WITHOUT LIMITATION,
                          ATTORNEY FEES) RELATING TO THIS AGREEMENT. THESE DISCLAIMERS APPLY REGARDLESS OF THE FORM OF
                          ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE,
                          WHETHER THOSE DAMAGES ARE FORESEEABLE AND WHETHER XACENT HAS BEEN ADVISED OF THE POSSIBILITY
                          OF THOSE DAMAGES. THESE DISCLAIMERS ARE NOT APPLICABLE TO THE INDEMNIFICATION OBLIGATION SET
                          FORTH IN SECTION 13.2. EACH PROVISION OF THIS AGREEMENT THAT PROVIDES FOR A LIMITATION OF
                          LIABILITY, DISCLAIMER OF DAMAGES, OR EXCLUSION OF DAMAGES IS TO ALLOCATE THE RISKS OF THIS
                          AGREEMENT BETWEEN THE PARTIES. THIS ALLOCATION IS REFLECTED IN THE PRICING OFFERED BY XACENT
                          TO SUBSCRIBER AND IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES.
                          EACH OF THESE PROVISIONS IS SEVERABLE FROM AND INDEPENDENT OF ALL OTHER PROVISIONS OF THIS
                          AGREEMENT.
                        </p>
                        <p>
                          11.3 Subscriber will solely be responsible for any damage and/or loss of Content contained in
                          Subscriber’s technology which occurs as a result of Subscriber’s electronic equipment and/or
                          Subscriber’s computer system.
                        </p>
                        <p className="fs-4">
                          <strong>12. Disclaimer of Warranties</strong>
                        </p>
                        <p>
                          12.1 XACENT HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR
                          STATUTORY, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
                          FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT OF THIRD PARTY RIGHTS WITH RESPECT TO ANY
                          SERVICES PROVIDED BY XACENT. NOTHING IN THIS SECTION 12.1 SHALL MODIFY XACENT’S OBLIGATION TO
                          INDEMNIFY SUBSCRIBER AS REQUIRED BY SECTION 13.2(A) OF THIS AGREEMENT (“INDEMNIFICATION”).
                        </p>
                        <p>
                          12.2 Xacent makes no warranty that its services when provided to Subscriber in digital or
                          electronic format will be compatible with Subscriber computer and/or other equipment, or that
                          these Services will be secure or error free. Nor does Xacent make any warranty as to any
                          results that may be obtained from the use of the Service. Nothing in this Section 12.2 shall
                          modify Xacent’s obligations under Section 4 above (“Confidentiality”) or Section 5 above
                          (“Security and Access”) or Xacent’s obligation to indemnify you as required by Section 13.2(b)
                          of this Agreement (“Indemnification”).
                        </p>
                        <p>
                          12.3 Xacent hereby disclaims all warranties of any kind related to Subscriber’s hardware or
                          software beyond the warranties provided by the manufacturer of Subscriber’s hardware or
                          software.
                        </p>
                        <p className="fs-4">
                          <strong>13. Indemnification</strong>
                        </p>
                        <p>
                          13.1 Subscriber hereby agrees to indemnify and hold harmless Xacent from and against any
                          claim, action, proceeding, loss, liability, judgment, obligation, penalty, damage, cost or
                          expense, including attorneys’ fees, which arise from or relate to the following:
                        </p>
                        <p>a. Authorized Users’ breach of any obligation stated in this Agreement, and</p>
                        <p>b. Authorized Users’ negligent acts or omissions.</p>
                        <p>
                          Xacent will provide prompt notice to Subscriber of any indemnifiable event or loss. Subscriber
                          will undertake, at Subscriber’s own cost, the defense of any claim, suit or proceeding with
                          counsel reasonably acceptable to Xacent. Xacent reserves the right to participate in the
                          defense of the claim, suit, or proceeding, at Xacent’ expense, with counsel of Xacent’
                          choosing.
                        </p>
                        <p>
                          13.2 Xacent shall defend, indemnify and hold Subscriber harmless against any loss, damage or
                          costs (including reasonable attorneys’ fees) in connection with claims, demands, suits, or
                          proceedings (“Claims”) made or brought against Subscriber by a third party
                        </p>
                        <p>
                          a. alleging that the Service, or use of the Service as contemplated hereunder, infringes a
                          copyright, a U.S. patent issued as of the date of final execution of this Agreement, or a
                          trademark of a third party or involves the misappropriation of any trade secret of a third
                          party; provided, however, that Subscriber:
                        </p>
                        <p>
                          (a) promptly gives written notice of the Claim to Xacent (provided, however, that the failure
                          to so notify shall not relieve Xacent of its indemnification obligations unless Xacent can
                          show that it was materially prejudiced by such delay and then only to the extent of such
                          prejudice); (b) gives Xacent sole control of the defense and settlement of the Claim (provided
                          that Xacent may not settle any Claim unless it unconditionally releases Subscriber of all
                          liability); and (c) provides to Xacent, at Xacent’s cost, all reasonable assistance. Xacent
                          shall not be required to indemnify Subscriber in the event of: (x) modification of the Service
                          by Subscriber in conflict with Subscriber’s obligations or as a result of any prohibited
                          activity as set forth herein to the extent that the infringement or misappropriation would not
                          have occurred but for such modification; (y) use of the Service in combination with any other
                          product or service not provided by Xacent to the extent that the infringement or
                          misappropriation would not have occurred but for such use; or (z) use of the Service in a
                          manner not otherwise contemplated by this Agreement to the extent that the infringement or
                          misappropriation would not have occurred but for such use; or
                        </p>
                        <p>
                          b. arising out of or related to a violation by Xacent of its obligations under Section 4 above
                          (“Confidentiality”) or Section 5 above (“Security and Access”),
                        </p>
                        <p className="fs-4">
                          <strong>14. SimplyNP Payments</strong>
                        </p>
                        <p>
                          14.1 Xacent offers an optional product which allows you to process payments and other payment
                          related services (“SimplyNP Payments”). In addition to Services related to SimplyNP Payments
                          provided by Xacent, the payment processing component of SimplyNP Payments is provided by the
                          third party payment processing provider Stripe Payments Canada, Ltd. or its affiliates
                          (“Payment Processor”). This payment processing is a Third Party Service (as defined below) and
                          is subject to the
                          <a href="https://stripe.com/connect-account/legal">Stripe Connected Account Agreement</a>
                          including agreements and other documents referred to in such agreement (collectively, the
                          “Payment Processing Agreement”), as modified by the Payment Processor in accordance with the
                          Payment Processing Agreement. By enrolling in and continuing to use SimplyNP Payments,
                          Subscriber agrees to be bound by this Section 14 and the applicable terms of the Payment
                          Processing Agreement.
                        </p>
                        <p>
                          14.2 SimplyNP Payments are subject to certain fees and surcharges communicated to Subscriber
                          during the enrollment process. As a condition of Xacent enabling SimplyNP Payments, Subscriber
                          agrees to provide Xacent with accurate and complete information related to Subscriber’s use of
                          SimplyNP Payments and authorizes Xacent to share such information and transaction information
                          with the Payment Processor pursuant to our{" "}
                          <a href="https://simplynp.com/privacy/">Privacy Policy</a>. Transaction information from
                          payors will be collected for processing of transactions by the Payment Processor in accordance
                          with the Payment Processor’s terms applicable to the payments. To the extent permitted by law,
                          Xacent may collect any payment obligations Subscriber owes under this Agreement by deducting
                          the corresponding amounts from funds payable to Subscriber arising from the settlement of card
                          transactions through SimplyNP Payments. Fees will be assessed at the time a transaction is
                          processed and will be first deducted from the funds received for such transactions. If the
                          settlement amounts are not sufficient to meet Subscriber’s obligations, Xacent may charge or
                          debit the bank account or credit card registered in Subscriber’s account for any amounts owed
                          (and you agree to execute such additional directions in writing to permit us to do so, if
                          required). In the event a payment chargeback or dispute occurs, Subscriber may be charged a
                          dispute fee per occurrence by Xacent. This Section does not permit Xacent to debit a
                          Subscriber trust account for any reason. In addition to the amount due, delinquent accounts
                          may be charged fees that are incidental to the collection of delinquent accounts and
                          chargebacks including, but not limited to, collection fees, convenience fees, legal fees and
                          expenses, costs of any arbitration or court proceeding, collection agency fees, any applicable
                          interest and third party charges. Subscriber hereby explicitly agrees that all communication
                          in relation to delinquent accounts will be made by electronic mail or by phone, at addresses
                          and numbers provided to Xacent. Such communication may be made by Xacent or by anyone on its
                          behalf, including, but not limited to, a third party collection agent. Subscriber will comply
                          with the terms and conditions of any applicable merchant agreements and all applicable card
                          network rules, policies, laws and regulations, at all times while using SimplyNP Payments. An
                          Administrator may cancel the use of SimplyNP Payments at any time by visiting
                          <a href="https://app.simplynp.com/settings/account">here.</a>
                        </p>
                        <p className="fs-4">
                          <strong>15. Miscellaneous</strong>
                        </p>
                        <p>
                          15.1 Technical support and training are available to Authorized Users with active
                          subscriptions, and is available by telephone, email or electronic support ticket, as defined
                          at
                          <a href="https://support.simplynp.com/home" target="_blank">
                            support.simplynp.com/home
                          </a>
                          and in <a href="https://simplynp.com/tos/service-level-commitments.html">Exhibit A</a>.
                        </p>
                        <p>
                          15.2 Subscriber acknowledges and agrees that Xacent may use third party vendors and hosting
                          partners to provide the necessary hardware, software, networking, storage, and related
                          technology required to run the Service.
                        </p>
                        <p>
                          15.3 The Services may allow you to access or use or integrate with third party providers of
                          products and services (“Third Party Services”). Such Third Party Services are not “Services”
                          under this Agreement and are not subject to any terms related to Services, including related
                          warranties, indemnities, service commitments or other obligations . The availability of any
                          Third Party Services through the Services does not imply Xacent’s endorsement of or
                          affiliation with the provider. Access to and use of any Third Party Services are subject to
                          the separate terms and conditions required by the providers of the Third Party Services.
                          Xacent does not control the Third Party Services and will have no liability to Subscriber in
                          connection with any Third Party Service. Xacent has no obligation to monitor or maintain any
                          Third Party Service and may replace, disable or restrict access to any Third Party Service or
                          cancel related integrations at any time, without notice. The calculation of downtime pursuant
                          to <a href="https://simplynp.com/tos/service-level-commitments.html">Exhibit A</a>
                          does not include the unavailability of any integration to a Third Party Service. BY USING OR
                          ENABLING ANY THIRD PARTY SERVICE, SUBSCRIBER EXPRESSLY ACKNOWLEDGES THAT ANY LIABILITY AND
                          REMEDIES RELATED TO A THIRD PARTY SERVICE IS WHOLLY GOVERNED BY THE APPLICABLE THIRD PARTY
                          AGREEMENT AND XACENT DISCLAIMS ALL LIABILITY RELATED TO SUCH THIRD PARTY SERVICE.
                        </p>
                        <p>
                          15.4 Subscriber acknowledges the risk that information and the Content stored and transmitted
                          electronically through the Service may be intercepted by third parties. Subscriber agrees to
                          accept that risk and will not hold Xacent liable for any loss, damage, or injury resulting
                          from the interception of information. The Content is stored securely and encrypted. Only
                          Xacent, with strict business reasons, may access and transfer the Content and only to provide
                          Subscriber with the Service. Xacent will make reasonable efforts to provide notice to
                          Subscriber prior to such access and transfer. Xacent’ actions will comply with its obligations
                          under Sections 4 and 5 of this Agreement.
                        </p>
                        <p>
                          15.5 The failure of either party to enforce any provision hereof shall not constitute or be
                          construed as a waiver of such provision or of the right to enforce it at a later time.
                        </p>
                        <p>
                          15.6 This Agreement constitutes the entire agreement between Authorized Users and Xacent and
                          governs Authorized Users use of the Service, superseding any prior agreements between
                          Authorized Users and Xacent (including, but not limited to, any prior versions of this
                          agreement).
                        </p>
                        <p>
                          15.7 Xacent reserves the right to amend this Agreement. In the event of material changes to
                          the Agreement, Xacent will notify Subscribers, by email, or by other reasonable means of these
                          changes prior to their enactment. Continued use of the Service by the Subscriber after
                          reasonable notice will be considered acceptance of any new terms.
                        </p>
                        <p>
                          15.8 Neither party may assign any of its rights or obligations hereunder, whether by operation
                          of law or otherwise, without the prior written consent of the other party (which consent shall
                          not be unreasonably withheld). Notwithstanding the foregoing, either party may assign this
                          Agreement in its entirety without consent of the other party in connection with a merger,
                          acquisition, corporate reorganization, or sale of all or substantially all of its assets
                          provided the assignee has agreed to be bound by all of the terms of this Agreement. Any
                          attempt by a party to assign its rights or obligations under this Agreement in breach of this
                          Section shall be void and of no effect.
                        </p>
                        <p>
                          15.9 Governing Law and Venue. This Agreement and your relationship with Xacent shall be
                          governed exclusively by, and will be enforced, construed, and interpreted exclusively in
                          accordance with, the laws applicable in the province of Ontario, Canada and shall be
                          considered to have been made and accepted in Ontario, Canada, without regard to its conflict
                          of law provisions. All disputes under this Agreement will be resolved by the courts of Ontario
                          in Ottawa, and Subscribers consent to the jurisdiction of and venue in such courts and waive
                          any objection as to inconvenient forum. In any action or proceeding to enforce rights under
                          this Agreement, the prevailing party shall be entitled to recover costs and legal fees.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Terms;
