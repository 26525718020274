import {
  GET_NOTES_LIST,
  GET_NOTE,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  ADD_NEW_NOTE_SUCCESS,
  ADD_NEW_NOTE_FAIL,
  ADD_NEW_NOTE,
  UPDATE_NOTE,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_FAIL,
  DELETE_NOTE,
  DELETE_NOTE_FAIL,
  DELETE_NOTE_SUCCESS,
  RESET_NOTE_FLAG,
} from "./actionType";

export const NotesApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const NotesApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getNotesList = (params) => ({
  type: GET_NOTES_LIST,
  payload: { params },
});

export const getNote = (note) => ({
  type: GET_NOTE,
  payload: note,
});

export const updateNote = (note) => ({
  type: UPDATE_NOTE,
  payload: note,
});

export const updateNoteSuccess = (note) => ({
  type: UPDATE_NOTE_SUCCESS,
  payload: note,
});

export const updateNoteFail = (note) => ({
  type: UPDATE_NOTE_FAIL,
  payload: note,
});

export const addNewNote = (note) => ({
  type: ADD_NEW_NOTE,
  payload: note,
});

export const addNewNoteSuccess = (note) => ({
  type: ADD_NEW_NOTE_SUCCESS,
  payload: note,
});

export const addNewNoteFail = (error) => ({
  type: ADD_NEW_NOTE_FAIL,
  payload: error,
});

export const deleteNote = (note) => ({
  type: DELETE_NOTE,
  payload: note,
});

export const deleteNoteSuccess = (note) => ({
  type: DELETE_NOTE_SUCCESS,
  payload: note,
});

export const deleteNoteFail = (error) => ({
  type: DELETE_NOTE_FAIL,
  payload: error,
});

export const resetNoteFlag = () => ({
  type: RESET_NOTE_FLAG,
});
