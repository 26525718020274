import React from "react";
import { Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import AllCampaigns from "./AllCampaigns";
import Widgets from "./Widget";

const CampaignList = () => {
    document.title = "Campaigns List | Velzon - React Admin & Dashboard Template";
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title="Campaigns List" pageTitle="Campaign" />
            <AllCampaigns />
          </Container>
        </div>
      </React.Fragment>
    );
};

export default CampaignList;