import React, { useState } from "react";

import { Link } from "react-router-dom";
import { Card, CardBody, Row } from "reactstrap";

import { Priority } from "../cases/ListView/CaseCol";
import { Status as NoteStatus } from "../notes/ListView/NoteCol";
import DeleteModal from "../../Components/Common/DeleteModal";
import { deleteNote } from "../../store/actions";
import { useDispatch } from "react-redux";

const NotesList = ({ notes }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState([]);
  const dispatch = useDispatch();

  const onClickDelete = (deleteData, type) => {
    setDeleteData(deleteData);
    setDeleteModal(true);
  };

  const handleDeleteCase = () => {
    if (deleteData) {
      dispatch(deleteNote(deleteData));

      setDeleteModal(false);
    }
  };

  return (
    <Row>
      <DeleteModal show={deleteModal} onDeleteClick={handleDeleteCase} onCloseClick={() => setDeleteModal(false)} />

      {/* <CardBody> */}
      <Row>
        {notes &&
          notes.map((note) => {
            let priorityCellProps = {};
            priorityCellProps["value"] = note.priority;

            let statusCellProps = {};
            statusCellProps["value"] = note.status;

            return (
              <div key={note.id} className="d-flex align-items-center py-3">
                <div className="flex-grow-1">
                  <div>
                    <h5 className="fs-14 mb-1">{note.title}</h5>
                    Priority: <Priority {...priorityCellProps} />
                    {` `} {/*  for space between top and bottom tags  */}
                    status: <NoteStatus {...statusCellProps} />
                    <p className="text-muted mb-0">Created on: {(note && note.created_on?.split("T")[0]) || ""}</p>
                  </div>
                </div>

                <ul className="list-inline hstack gap-3 mb-0">
                  <li className="list-inline-item edit" title="Edit">
                    <Link className="text-muted d-inline-block" to={"/case-notes/" + note.id}>
                      <i className="ri-edit-2-line fs-16 align-bottom me-2 text-muted"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item edit" title="Delete">
                    <Link
                      to="#"
                      onClick={() => {
                        onClickDelete(note);
                      }}
                      className="text-muted d-inline-block"
                    >
                      <i className="ri-delete-bin-fill fs-16"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            );
          })}
      </Row>
      {/* </CardBody> */}
    </Row>
  );
};

export default NotesList;
