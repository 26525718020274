import {
  GET_CASES_LIST,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_CASE,
  ADD_CASE_SUCCESS,
  ADD_CASE_FAIL,
  UPDATE_CASE_SUCCESS,
  UPDATE_CASE_FAIL,
  DELETE_CASE_SUCCESS,
  DELETE_CASE_FAIL,
  RESET_CASE_FLAG,
} from "./actionType";

const INIT_STATE = {
  monthly_cases: null,
  casesList: null,
};

const Cases = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CASES_LIST:
          return {
            ...state,
            casesList: action.payload.data.cases,
            page: action.payload.data.page,
            stats: action.payload.data.stats,
            monthly_cases: action.payload.data.monthly_cases,
            isCaseCreated: false,
            isCaseSuccess: true,
            isLoading: false,
          };
        case GET_CASE:
          return {
            ...state,
            caseObj: action.payload.data,
            isCaseSuccess: true,
            isCaseCreated: false,
          };

        default:
          return { ...state };
      }

    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_CASES_LIST:
          return {
            ...state,
            isLoading: false,
          };
        case GET_CASE:
          return {
            ...state,
            error: action.payload.error,
            isCaseCreated: false,
            isCaseSuccess: false,
          };

        default:
          return { ...state };
      }

    case GET_CASES_LIST: {
      return {
        ...state,
        isCaseCreated: false,
        isLoading: true,
      };
    }

    case ADD_CASE_SUCCESS:
      return {
        ...state,
        isCaseCreated: true,
        // casesList: [...state.casesList, action.payload.data],
        isCaseAdd: true,
        isCaseAddFail: false,
      };

    case ADD_CASE_FAIL:
      return {
        ...state,
        error: action.payload,
        isCaseAdd: false,
        isCaseAddFail: true,
      };

    case UPDATE_CASE_SUCCESS:
      return {
        ...state,
        // casesList: state.casesList.map(MyCase =>
        //     MyCase.id.toString() === action.payload.data.id.toString()
        //         ? { ...MyCase, ...action.payload.data }
        //         : MyCase
        // ),
        isCaseUpdate: true,
        isCaseUpdateFail: false,
      };

    case UPDATE_CASE_FAIL:
      return {
        ...state,
        error: action.payload,
        isCaseUpdate: false,
        isCaseUpdateFail: true,
      };

    case DELETE_CASE_SUCCESS:
      return {
        ...state,
        casesList: state.casesList.filter((MyCase) => MyCase.id.toString() !== action.payload.MyCase.toString()),
        isCaseDelete: true,
        isCaseDeleteFail: false,
      };

    case DELETE_CASE_FAIL:
      return {
        ...state,
        error: action.payload,
        isCaseDelete: false,
        isCaseDeleteFail: true,
      };

    case RESET_CASE_FLAG:
      return {
        ...state,
        isCaseAdd: false,
        isCaseAddFail: false,
        isCaseDelete: false,
        isCaseDeleteFail: false,
        isCaseUpdate: false,
        isCaseUpdateFail: false,
        error: false,
      };

    default:
      return { ...state };
  }
};

export default Cases;
