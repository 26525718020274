import React, { useRef } from "react";
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";
import SimpleBar from "simplebar-react";
import { map } from "lodash";

//redux

const MessagesList = ({ currentRoomMessages, currentUserId, messagesEndRef }) => {
  const ref = useRef();

  return (
    <div className="position-relative" id="users-chat">
      <SimpleBar ref={ref} className="chat-conversation p-3 p-lg-4 " id="chat-conversation">
        <ul className="list-unstyled chat-conversation-list" id="users-conversation">
          {currentRoomMessages && currentRoomMessages.length
            ? map(currentRoomMessages, (message, key) => (
                <li
                  className={message?.sender?.id === currentUserId ? " chat-list right" : "chat-list left"}
                  key={key}
                  ref={messagesEndRef}
                >
                  <div className="conversation-list">
                    <div className="user-chat-content">
                      <div className="ctext-wrap">
                        <div className="ctext-wrap-content">
                          <p className="mb-0 ctext-content">{message?.message}</p>
                        </div>
                        <UncontrolledDropdown className="align-self-start message-box-drop">
                          <DropdownToggle href="#" className="btn nav-btn" tag="a">
                            <i className="ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem href="#" className="reply-message">
                              <i className="ri-reply-line me-2 text-muted align-bottom"></i>
                              Reply
                            </DropdownItem>
                            <DropdownItem href="#">
                              <i className="ri-share-line me-2 text-muted align-bottom"></i>
                              Forward
                            </DropdownItem>
                            <DropdownItem href="#">
                              <i className="ri-file-copy-line me-2 text-muted align-bottom"></i>
                              Copy
                            </DropdownItem>
                            <DropdownItem href="#">
                              <i className="ri-bookmark-line me-2 text-muted align-bottom"></i>
                              Bookmark
                            </DropdownItem>
                            <DropdownItem href="#">
                              <i className="ri-delete-bin-5-line me-2 text-muted align-bottom"></i>
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <div className="conversation-name">
                        <small className="text-muted time"> {message?.created_on?.split("T")[1].split(".")[0]}</small>{" "}
                        <span className="text-success check-message-icon">
                          <i className="ri-check-double-line align-bottom"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              ))
            : null}
        </ul>
      </SimpleBar>
      <div
        className="alert alert-warning alert-dismissible copyclipboard-alert px-4 fade show "
        id="copyClipBoard"
        role="alert"
      >
        Message copied
      </div>
    </div>
  );
};

export default MessagesList;
