import React from "react";
import { TabPane } from "reactstrap";

const AccountsExtras = () => {
  return (
    <div>
      <div className="d-flex">
        <div className="flex-shrink-0">
          <i className="ri-checkbox-circle-fill text-success"></i>
        </div>
        <div className="flex-grow-1 ms-2">
          Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth
          master cleanse.
        </div>
      </div>
      <div className="d-flex mt-2">
        <div className="flex-shrink-0">
          <i className="ri-checkbox-circle-fill text-success"></i>
        </div>
        <div className="flex-grow-1 ms-2">
          Too much or too little spacing, as in the example below, can make things unpleasant for the reader. The goal
          is to make your text as comfortable to read as possible.
        </div>
      </div>
    </div>
  );
};

export default AccountsExtras;
