import React, { useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Button } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { Redirect, withRouter } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// action
import { registerUser, apiError, resetRegisterFlag } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { Link, useHistory } from "react-router-dom";

//import images
import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "./ParticlesAuth";

const Register = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { plan } = useParams();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      first_name: "",
      password: "",
      confirm_password: "",
      selected_plan: plan,
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Please enter a valid email").required("Please Enter Your Email"),
      first_name: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
      confirm_password: Yup.string().when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref("password")], "Confirm Password Isn't a Match"),
      }),
    }),
    onSubmit: async (values) => {
      if (!plan) return;
      dispatch(registerUser(values));
    },
  });

  const { err, passwordError, error, nonFieldError, errorMessage, registrationError, success } = useSelector(
    (state) => ({
      registrationError: state.Account.registrationError,
      success: state.Account.success,
      error: state.Account.error,
      errorMessage: state.Account.errorMessage,
      nonFieldError: state.Account.nonFieldError,
      passwordError: state.Account.passwordError,
      err: state.Account.err,
    })
  );

  const SNP_LOOKUP_KEYS = [
    "snp_standard",
    "snp_standard_yearly",
    "snp_pro",
    "snp_pro_yearly",
    "snp_premium",
    "snp_premium_yearly",
  ];

  useEffect(() => {
    dispatch(apiError(""));
  }, [dispatch]);

  useEffect(() => {
    if (!success) return;

    setTimeout(() => {
      props.history.replace("/registration-accepted");
    }, 3000);
  }, [success]);

  document.title = "Sign UP | SimplyNP";

  if (!SNP_LOOKUP_KEYS.includes(plan)) {
    return window.location.replace("https://simplynp.com/pricing");
    //return <Redirect to="/https://simplynp.com/pricing" />;
  }

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="40" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">The better way to manage your NON-Profit</p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Create New Account</h5>
                      <p className="text-muted">Get your SimplyNP account now</p>
                    </div>
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        className="needs-validation"
                        action="#"
                      >
                        {success && success ? (
                          <>
                            {toast("success: please wait", {
                              position: "top-right",
                              autoClose: 3000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                            })}
                            <ToastContainer autoClose={2000} limit={1} />
                          </>
                        ) : null}

                        {success && success ? (
                          <Alert color="success">
                            <div>Register User Successfully, Check your email to activate your account</div>
                          </Alert>
                        ) : null}

                        {/* {registrationError && registrationError ? (
                          <Alert color="danger">
                            <div>Email Already Registered, Please Try Loggin In or Use Another Email Address... </div>
                            <ToastContainer autoClose={2000} closeButton={false} />
                          </Alert>
                        ) : null} */}

                        {errorMessage && errorMessage ? (
                          <Alert color="danger">
                            <div>{errorMessage} </div>
                            <ToastContainer autoClose={2000} closeButton={false} />
                          </Alert>
                        ) : null}

                        {nonFieldError && nonFieldError ? (
                          <Alert color="danger">
                            <div>{nonFieldError} </div>
                            <ToastContainer autoClose={2000} closeButton={false} />
                          </Alert>
                        ) : null}

                        {passwordError && passwordError ? (
                          <Alert color="danger">
                            <div>{passwordError} </div>
                            <ToastContainer autoClose={2000} closeButton={false} />
                          </Alert>
                        ) : null}

                        {error && error ? (
                          <>
                            {toast("Internal Server Error, Please Try Again Later", {
                              position: "top-right",
                              hideProgressBar: true,
                              className: "bg-danger text-white",
                              progress: undefined,
                              toastId: "",
                            })}
                            <ToastContainer autoClose={2000} limit={1} />
                          </>
                        ) : null}

                        <div className="mb-3">
                          <Label htmlFor="useremail" className="form-label">
                            Email <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Enter email address"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={validation.touched.email && validation.errors.email ? true : false}
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.email}</div>
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="username" className="form-label">
                            Username <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="first_name"
                            type="text"
                            placeholder="Enter username"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.first_name || ""}
                            invalid={validation.touched.first_name && validation.errors.first_name ? true : false}
                          />
                          {validation.touched.first_name && validation.errors.first_name ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.first_name}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="userpassword" className="form-label">
                            Password <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="password"
                            type="password"
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={validation.touched.password && validation.errors.password ? true : false}
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.password}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-2">
                          <Label htmlFor="confirmPassword" className="form-label">
                            Confirm Password <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="confirm_password"
                            type="password"
                            placeholder="Confirm Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirm_password || ""}
                            invalid={
                              validation.touched.confirm_password && validation.errors.confirm_password ? true : false
                            }
                          />
                          {validation.touched.confirm_password && validation.errors.confirm_password ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.confirm_password}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-4">
                          <p className="mb-0 fs-12 text-muted fst-italic">
                            By registering you agree to the SimplyNP &nbsp;
                            <Link to="terms" className="text-primary text-decoration-underline fst-normal fw-medium">
                              Terms of Use
                            </Link>
                          </p>
                        </div>

                        <div className="mt-4">
                          <button className="btn btn-success w-100" type="submit">
                            Next
                          </button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Already have an account ?{" "}
                    <Link to="/login" className="fw-semibold text-primary text-decoration-underline">
                      {" "}
                      Signin{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default Register;
