import {
  GET_CASES_LIST,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_CASE,
  UPDATE_CASE,
  UPDATE_CASE_SUCCESS,
  UPDATE_CASE_FAIL,
  ADD_NEW_CASE,
  ADD_CASE_SUCCESS,
  ADD_CASE_FAIL,
  DELETE_CASE,
  DELETE_CASE_SUCCESS,
  DELETE_CASE_FAIL,
  RESET_CASE_FLAG,
} from "./actionType";

// common success
export const CasesApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const CasesApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getCasesList = (params) => ({
  type: GET_CASES_LIST,
  payload: { params },
});

export const getCase = (MyCase) => ({
  type: GET_CASE,
  payload: MyCase,
});

export const updateCase = (MyCase) => ({
  type: UPDATE_CASE,
  payload: MyCase,
});

export const updateCaseSuccess = (MyCase) => ({
  type: UPDATE_CASE_SUCCESS,
  payload: MyCase,
});

export const updateCaseFail = (error) => ({
  type: UPDATE_CASE_FAIL,
  payload: error,
});

export const addNewCase = (MyCase) => ({
  type: ADD_NEW_CASE,
  payload: MyCase,
});

export const addCaseSuccess = (MyCase) => ({
  type: ADD_CASE_SUCCESS,
  payload: MyCase,
});

export const addCaseFail = (error) => ({
  type: ADD_CASE_FAIL,
  payload: error,
});

export const deleteCase = (MyCase) => ({
  type: DELETE_CASE,
  payload: MyCase,
});

export const deleteCaseSuccess = (MyCase) => ({
  type: DELETE_CASE_SUCCESS,
  payload: MyCase,
});

export const deleteCaseFail = (error) => ({
  type: DELETE_CASE_FAIL,
  payload: error,
});

export const resetCaseFlag = () => {
  return {
    type: RESET_CASE_FLAG,
  };
};
