import axios from "axios";
import { api } from "../config";
import { getAuthBearer } from "./jwt-token-access/auth-token-header";

// default
axios.defaults.baseURL = api.API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  async (error) => {
    let message;

    const status = error.status || error.response?.status;

    switch (status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 403:
        message = "Sorry! You are unauthorized";
        break;
      //case 404:
      //message = "Sorry! the data you are looking for could not be found";
      //break;
      default:
        //console.log("INTERCEPTER ERROR: ", JSON.parse(error.request.response).message);
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);

axios.interceptors.request.use(
  (config) => {
    const { token, org: orgID } = getAuthBearer();

    if (token) config.headers["X-Snp-Auth"] = `Bearer ${token}`;
    //if (token) config.headers.Authorization = `Bearer ${token}`;
    //if (orgID) config.headers["org"] = orgID;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  //axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  axios.defaults.headers.common["X-Snp-Auth"] = "Bearer " + token;
  axios.defaults.headers["X-Snp-Auth"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url, params) => {
    let response;
    let paramKeys = [];

    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}

export { APIClient, setAuthorization };
