import React, { useEffect, useState } from "react";
import { Card, CardBody, Row } from "reactstrap";
import { useSelector } from "react-redux";
import CasesList from "../CasesList";
import { getClientCases } from "../../../helpers/backend_helper";
import Loader from "../../../Components/Common/Loader";
import TabsPagination from "./Pagination";

const CasesTab = ({ clientId }) => {
  const [cases, setCases] = useState(0);
  const [casesTotal, setCasesTotal] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [limit, setLimit] = useState(4);
  const [isLoading, setIsLoading] = useState("");

  const { isCaseDelete } = useSelector((state) => ({
    isCaseDelete: state.Cases.isCaseDelete,
  }));

  const handleNext = async (newPageNumber) => {
    if (limit * newPageNumber >= casesTotal) return;

    if (newPageNumber < 0) return;

    setPageNumber(newPageNumber);
    setIsLoading(true);

    try {
      let res = await getClientCases(clientId, { limit: limit, offset: limit * newPageNumber });

      if (res.status === 200) {
        setCases(res.cases);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchClientCases = async () => {
      setIsLoading(true);

      try {
        let res = await getClientCases(clientId, { limit: limit, offset: 0 });

        if (res.status === 200) {
          setCasesTotal(res.cases_count);
          setCases(res.cases);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };

    fetchClientCases();
  }, [isCaseDelete]);

  return (
    <Card>
      <CardBody>
        <div className="d-flex align-items-center mb-4">
          <div className="flex-grow-1">
            <h5 className="card-title mb-0">Cases ({casesTotal})</h5>
          </div>
        </div>
        <Row>
          {isLoading ? <Loader /> : ""}

          <CasesList cases={cases} />

          <TabsPagination handleNext={handleNext} pageNumber={pageNumber} />
        </Row>
      </CardBody>
    </Card>
  );
};

export default CasesTab;
