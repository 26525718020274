import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import * as actionType from "./actionType";
import * as actions from "./action";
import * as backend from "../../helpers/backend_helper";

function* getMembersWorker({ payload }) {
  try {
    const response = yield call(backend.getMembers, payload);
    yield put(actions.membershipApiResponseSuccess(actionType.GET_MEMBERS, response));
  } catch (error) {
    yield put(actions.membershipApiResponseError(actionType.GET_MEMBERS, error));
  }
}

function* getMembershipsWorker({ payload }) {
  try {
    const response = yield call(backend.getMemberships, payload);
    yield put(actions.membershipApiResponseSuccess(actionType.GET_MEMBERSHIPS, response));
  } catch (error) {
    yield put(actions.membershipApiResponseError(actionType.GET_MEMBERSHIPS, error));
  }
}

function* getMembershipTypesWorker({ payload }) {
  try {
    const response = yield call(backend.getMembershipTypes, payload);
    yield put(actions.membershipApiResponseSuccess(actionType.GET_MEMBERSHIP_TYPES, response));
  } catch (error) {
    yield put(actions.membershipApiResponseError(actionType.GET_MEMBERSHIP_TYPES, error));
  }
}

function* getMembershipBenefitWorker({ payload }) {
  try {
    const response = yield call(backend.getMembershipBenefits, payload);
    yield put(actions.membershipApiResponseSuccess(actionType.GET_MEMBERSHIP_BENEFITS, response));
  } catch (error) {
    yield put(actions.membershipApiResponseError(actionType.GET_MEMBERSHIP_BENEFITS, error));
  }
}

function* getMembershipSiteConfigWorker() {
  try {
    const response = yield call(backend.getMembershipSiteConfig);
    yield put(actions.membershipApiResponseSuccess(actionType.GET_MEMBERSHIP_SITE_CONFIG, response));
  } catch (error) {
    yield put(actions.membershipApiResponseError(actionType.GET_MEMBERSHIP_SITE_CONFIG, error));
  }
}

function* updateMembershipSiteConfigWorker({ payload }) {
  try {
    const response = yield call(backend.updateMembershipSiteConfig, payload);
    yield put(actions.updateMembershipSiteConfigSuccess(response));
  } catch (error) {
    yield put(actions.updateMembershipSiteConfigFail(error));
  }
}

function* getMembershipLevelWorker({ payload }) {
  try {
    const response = yield call(backend.getMembershipLevels, payload);
    yield put(actions.membershipApiResponseSuccess(actionType.GET_MEMBERSHIP_LEVELS, response));
  } catch (error) {
    yield put(actions.membershipApiResponseError(actionType.GET_MEMBERSHIP_LEVELS, error));
  }
}

//
function* onAddNewMemberWorker({ payload: contact }) {
  try {
    const response = yield call(backend.createMembers, contact);

    if (response.error) {
      return yield put(actions.addMemberFail({ contact, ...response }));
    }
    yield put(actions.addMemberSuccess({ contact, ...response }));
    yield put(actions.membershipApiResponseSuccess(actionType.GET_MEMBERS, response));
  } catch (error) {
    yield put(actions.addMemberFail(error));
  }
}

function* onAddNewMembershipsWorker({ payload }) {
  try {
    const response = yield call(backend.createMemberships, payload);

    if (response.status === 400) {
      return yield put(actions.addMembershipFail(response));
    }

    yield put(actions.addMembershipSuccess({ payload, ...response }));
    yield put(actions.getMemberships());
  } catch (error) {
    yield put(actions.addMembershipFail(error));
  }
}

function* onAddNewMembershipTypeWorker({ payload: leads }) {
  try {
    const response = yield call(backend.createMembershipTypes, leads);
    yield put(actions.addMembershipTypeSuccess(response));
    // if (response.status === 200) {
    // } else {
    //   yield put(actions.addMembershipTypeFail(response));
    // }
  } catch (error) {
    yield put(actions.addMembershipTypeFail(error));
  }
}

function* onAddNewMembershipBenefitWorker({ payload: benefit }) {
  try {
    const response = yield call(backend.createMembershipBenefits, benefit);
    yield put(actions.addMembershipBenefitSuccess(response));
    // if (response.status === 200) {
    // } else {
    //   yield put(actions.addMembershipBenefitFail(response));
    // }
  } catch (error) {
    yield put(actions.addMembershipBenefitFail(error));
  }
}

function* onAddNewMembershipLevelWorker({ payload: benefit }) {
  try {
    const response = yield call(backend.createMembershipLevels, benefit);
    yield put(actions.addMembershipLevelSuccess(response));
    yield put(actions.getMembershipLevels());
    // if (response.status === 200) {
    // } else {
    //   yield put(actions.addMembershipLevelFail(response));
    // }
  } catch (error) {
    yield put(actions.addMembershipLevelFail(error));
  }
}

//
function* onUpdateMemberWorker({ payload: member }) {
  try {
    const response = yield call(backend.updateMember, member.get("id"), member);
    yield put(actions.updateMemberSuccess({ member, ...response }));
    // yield put(actions.getMembers());
  } catch (error) {
    yield put(actions.updateMemberFail(error));
  }
}

function* onUpdateMembershipsWorker({ payload: organizations }) {
  try {
    const id = organizations.id || organizations._id; // is update/renewal
    const response = yield call(backend.updateMembership, id, organizations);
    yield put(actions.updateMembershipSuccess({ organizations, ...response }));
    yield put(actions.getMemberships());
  } catch (error) {
    yield put(actions.updateMembershipFail(error));
  }
}

function* onRenewMembershipsWorker({ payload: organizations }) {
  try {
    const response = yield call(backend.updateMembership, organizations._id, organizations);
    yield put(actions.renewMembershipSuccess({ organizations, ...response }));
    yield put(actions.getMemberships());
  } catch (error) {
    yield put(actions.renewMembershipFail(error));
  }
}

function* onUpdateMembershipTypeWorker({ payload: leads }) {
  try {
    const response = yield call(backend.updateMembershipType, leads.id, leads);
    yield put(actions.updateMembershipTypeSuccess({ leads, ...response }));
    yield put(actions.getMembershipTypes());
  } catch (error) {
    yield put(actions.updateMembershipTypeFail(error));
  }
}

function* onUpdateMembershipBenefitWorker({ payload: benefit }) {
  try {
    const response = yield call(backend.updateMembershipBenefit, benefit.id, benefit);
    yield put(actions.updateMembershipBenefitSuccess({ benefit, ...response }));
    yield put(actions.getMembershipBenefits());
  } catch (error) {
    yield put(actions.updateMembershipBenefitFail(error));
  }
}

function* onUpdateMembershipLevelWorker({ payload: level }) {
  try {
    const response = yield call(backend.updateMembershipLevel, level.id, level);
    yield put(actions.updateMembershipLevelSuccess({ level, ...response }));
    yield put(actions.getMembershipLevels());
  } catch (error) {
    yield put(actions.updateMembershipTypeFail(error));
  }
}

//
function* onDeleteMemberWorker({ payload: member }) {
  try {
    const response = yield call(backend.deleteMember, member.id);
    yield put(actions.deleteMemberSuccess({ member, ...response }));
    yield put(actions.getMembers());
  } catch (error) {
    yield put(actions.deleteMemberFail(error));
  }
}

function* onDeleteMembershipsWorker({ payload: organizations }) {
  try {
    const response = yield call(backend.deleteMembership, organizations.id);
    yield put(actions.deleteMembershipSuccess({ organizations, ...response }));
    yield put(actions.getMemberships());
  } catch (error) {
    yield put(actions.deleteMembershipFail(error));
  }
}

function* onDeleteMembershipTypeWorker({ payload: leads }) {
  try {
    const response = yield call(backend.deleteMembershipType, leads.id);
    yield put(actions.deleteMembershipTypeSuccess({ leads, ...response }));
    yield put(actions.getMembershipTypes());
  } catch (error) {
    yield put(actions.deleteMembershipTypeFail(error));
  }
}

function* onDeleteMembershipBenefitWorker({ payload: leads }) {
  try {
    const response = yield call(backend.deleteMembershipBenefit, leads.id);
    yield put(actions.deleteMembershipBenefitSuccess({ leads, ...response }));
    yield put(actions.getMembershipBenefits());
  } catch (error) {
    yield put(actions.deleteMembershipBenefitFail(error));
  }
}

function* onDeleteMembershipLevelWorker({ payload: benefits }) {
  try {
    const response = yield call(backend.deleteMembershipLevel, benefits.id);
    yield put(actions.deleteMembershipLevelSuccess({ benefits, ...response }));
    yield put(actions.getMembershipLevels());
  } catch (error) {
    yield put(actions.deleteMembershipLevelFail(error));
  }
}

export function* watchGetMembers() {
  yield takeEvery(actionType.GET_MEMBERS, getMembersWorker);
}

export function* watchGetMemberships() {
  yield takeEvery(actionType.GET_MEMBERSHIPS, getMembershipsWorker);
}

export function* watchGetMembershipTypes() {
  yield takeEvery(actionType.GET_MEMBERSHIP_TYPES, getMembershipTypesWorker);
}

export function* watchGetMembershipBenefits() {
  yield takeEvery(actionType.GET_MEMBERSHIP_BENEFITS, getMembershipBenefitWorker);
}

export function* watchGetMembershipLevels() {
  yield takeEvery(actionType.GET_MEMBERSHIP_LEVELS, getMembershipLevelWorker);
}

export function* watchUpdateMemberships() {
  yield takeEvery(actionType.UPDATE_MEMBERSHIPS, onUpdateMembershipsWorker);
}

export function* watchRenewMemberships() {
  yield takeEvery(actionType.RENEW_MEMBERSHIPS, onRenewMembershipsWorker);
}

export function* watchDeleteMemberships() {
  yield takeEvery(actionType.DELETE_MEMBERSHIPS, onDeleteMembershipsWorker);
}

export function* watchAddNewMemberships() {
  yield takeEvery(actionType.ADD_NEW_MEMBERSHIPS, onAddNewMembershipsWorker);
}

export function* watchUpdateMember() {
  yield takeEvery(actionType.UPDATE_MEMBER, onUpdateMemberWorker);
}

export function* watchDeleteMember() {
  yield takeEvery(actionType.DELETE_MEMBER, onDeleteMemberWorker);
}

export function* watchAddNewMember() {
  yield takeEvery(actionType.ADD_NEW_MEMBER, onAddNewMemberWorker);
}

export function* watchGetMembershipSiteConfig() {
  yield takeEvery(actionType.GET_MEMBERSHIP_SITE_CONFIG, getMembershipSiteConfigWorker);
}

export function* watchUpdateMembershipSiteConfig() {
  yield takeEvery(actionType.UPDATE_MEMBERSHIP_SITE_CONFIG, updateMembershipSiteConfigWorker);
}

export function* watchUpdateMembershipLevel() {
  yield takeEvery(actionType.UPDATE_MEMBERSHIP_LEVEL, onUpdateMembershipLevelWorker);
}

export function* watchDeleteMembershipLevel() {
  yield takeEvery(actionType.DELETE_MEMBERSHIP_LEVEL, onDeleteMembershipLevelWorker);
}

export function* watchAddNewMembershipLevel() {
  yield takeEvery(actionType.ADD_NEW_MEMBERSHIP_LEVEL, onAddNewMembershipLevelWorker);
}

export function* watchUpdateMembershipBenefit() {
  yield takeEvery(actionType.UPDATE_MEMBERSHIP_BENEFIT, onUpdateMembershipBenefitWorker);
}

export function* watchDeleteMembershipBenefit() {
  yield takeEvery(actionType.DELETE_MEMBERSHIP_BENEFIT, onDeleteMembershipBenefitWorker);
}

export function* watchAddNewMembershipBenefit() {
  yield takeEvery(actionType.ADD_NEW_MEMBERSHIP_BENEFIT, onAddNewMembershipBenefitWorker);
}

export function* watchUpdateMembershipType() {
  yield takeEvery(actionType.UPDATE_MEMBERSHIP_TYPE, onUpdateMembershipTypeWorker);
}

export function* watchDeleteMembershipType() {
  yield takeEvery(actionType.DELETE_MEMBERSHIP_TYPE, onDeleteMembershipTypeWorker);
}

export function* watchAddNewMembershipType() {
  yield takeEvery(actionType.ADD_NEW_MEMBERSHIP_TYPE, onAddNewMembershipTypeWorker);
}

function* MembershipSaga() {
  yield all([
    // fork(watchGetDeals),
    fork(watchGetMembershipTypes),
    fork(watchAddNewMembershipType),
    fork(watchDeleteMembershipType),
    fork(watchUpdateMembershipType),

    fork(watchGetMembershipBenefits),
    fork(watchAddNewMembershipBenefit),
    fork(watchDeleteMembershipBenefit),
    fork(watchUpdateMembershipBenefit),

    fork(watchGetMembershipSiteConfig),
    fork(watchUpdateMembershipSiteConfig),

    fork(watchGetMembershipLevels),
    fork(watchAddNewMembershipLevel),
    fork(watchDeleteMembershipLevel),
    fork(watchUpdateMembershipLevel),

    fork(watchGetMemberships),
    fork(watchDeleteMemberships),
    fork(watchUpdateMemberships),
    fork(watchRenewMemberships),
    fork(watchAddNewMemberships),

    fork(watchGetMembers),
    fork(watchDeleteMember),
    fork(watchUpdateMember),
    fork(watchAddNewMember),
  ]);
}

export default MembershipSaga;
