import React from "react";
import UpdateNoteForm from "../FormComponent/CreateNote";

const CreateNote = () => {
  document.title = "Create Note | Velzon - React Admin & Dashboard Template";

  return (
    // <div className="page-content">
    <UpdateNoteForm title="Update Note" updateNote />
    // </div>
  );
};

export default CreateNote;
