import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

//import images
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";
import { getProfile } from "../../store/actions";

const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [URLS, setURLS] = useState([]);
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);

  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  useEffect(() => {
    const USER_URLs = [
      { url: "/settings/profile", title: "Profile", icon: "mdi-account-circle" },
      { url: "/chat", title: "Messages", icon: "mdi-message-text-outline" },
      { url: "/tasks", title: "Taskboard", icon: "mdi-calendar-check-outline" },
    ];

    const ADMIN_URLs = [
      { url: "/settings-accounts/profile", title: "Profile", icon: "mdi-account-circle" },
      { url: "/chat", title: "Messages", icon: "mdi-message-text-outline" },
      { url: "/tasks", title: "Taskboard", icon: "mdi-calendar-check-outline" },
      { url: "/settings-accounts", title: "Settings", icon: "mdi-cog-outline" },
    ];

    setUserName(`${user?.user_details?.first_name || ""} ${user?.user_details?.last_name || ""}` || "");
    setUserRole(user?.role);
    setURLS(user?.role === "ADMIN" ? ADMIN_URLs : USER_URLs);
  }, [user]);

  //Dropdown Toggle
  const toggleProfileDropdown = () => setIsProfileDropdown(!isProfileDropdown);

  return (
    <React.Fragment>
      <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              src={user?.user_details?.profile_pic || dummyImg}
              alt="Header Avatar"
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userName}</span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{userRole}</span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">Welcome {userName}!</h6>
          {/*<Link to="/settings-accounts/profile">
            <DropdownItem>
              <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">Profile</span>
            </DropdownItem>
          </Link>
          <Link to="/chat">
            <DropdownItem>
              <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle">Messages</span>
            </DropdownItem>
          </Link>
          <Link to="/tasks">
            <DropdownItem>
              <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle">Taskboard</span>
            </DropdownItem>
          </Link>

          <Link to="/settings-accounts">
            <DropdownItem>
              <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle">Settings</span>
            </DropdownItem>
          </Link>*/}
          {URLS.map((item, key) => (
            <Link key={key} to={item.url}>
              <DropdownItem>
                <i className={`mdi ${item.icon} text-muted fs-16 align-middle me-1`}></i>{" "}
                <span className="align-middle">{item.title}</span>
              </DropdownItem>
            </Link>
          ))}
          <hr className="py-0 my-0 text-muted" />
          <DropdownItem href="/logout">
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
