export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_TEAMS_LIST = "GET_TEAMS_LIST";
export const GET_TEAM = "GET_TEAM";

export const ADD_NEW_TEAM = "ADD_NEW_TEAM";
export const ADD_NEW_TEAM_SUCCESS = "ADD_NEW_TEAM_SUCCESS";
export const ADD_NEW_TEAM_FAIL = "ADD_NEW_TEAM_FAIL";

export const UPDATE_TEAM = "UPDATE_TEAM";
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
export const UPDATE_TEAM_FAIL = "UPDATE_TEAM_FAIL";

export const DELETE_TEAM = "DELETE_TEAM";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_FAIL = "DELETE_TEAM_FAIL";

export const RESET_TEAM_FLAG = "RESET_TEAM_FLAG";
