import {
  GET_CLIENT,
  GET_CLIENTS_LIST,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  UPDATE_CLIENT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  DELETE_CLIENT,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL,
  ADD_NEW_CLIENT,
  ADD_NEW_CLIENT_SUCCESS,
  ADD_NEW_CLIENT_FAIL,
  RESET_CLIENT_FLAG,
  CLIENT_STATS_SUCCESS,
  GET_CASES_STATS_SUCCESS,
  GET_SERVICES_STATS_SUCCESS,
  GET_DONATIONS_ORG_STATS,
  GET_DONATIONS_ORG_STATS_SUCCESS,
  GET_DONATIONS_CAMPAIGNS_STATS,
  GET_DONATIONS_CAMPAIGNS_STATS_SUCCESS,
  GET_DEMOGRAPHIC_REPORTS_GROUPBY_SUCCESS,
  GET_DEMOGRAPHIC_REPORTS_GROUPBY,
  GET_DONATIONS_OGS_BY_PATTERN_STATS_SUCCESS,
} from "./actionType";

const INIT_STATE = {
  clientsList: null,
};

const Statistics = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CLIENTS_LIST:
          return {
            ...state,
            clientsList: action.payload.data.client_obj_list,
            page: action.payload.data.page,
            isClientCreated: false,
            isClientSuccess: true,
            fetchingClientList: false,
            clientListSuccess: true,
          };
        case GET_CLIENT:
          return {
            ...state,
            client: action.payload.data,
            isClientSuccess: true,
            isClientCreated: false,
          };
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_CLIENTS_LIST:
        case GET_CLIENT:
          return {
            ...state,
            error: action.payload.error,
            isClientCreated: false,
            isClientSuccess: false,
          };
        case ADD_NEW_CLIENT:
          return {
            ...state,
            error: action.payload.error,
            isClientCreated: false,
            isClientSuccess: false,
            isClientAddFail: true,
            loading: false,
          };
        default:
          return { ...state };
      }
    case ADD_NEW_CLIENT:
      return {
        ...state,
        loading: true,
      };
    case CLIENT_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        clientStats: action.payload.client_stats,
        count: action.payload.count,
      };

    case GET_CASES_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        casesPriorityStats: action.payload.data,
      };

    case GET_SERVICES_STATS_SUCCESS:
      return {
        ...state,
        servicesStats: action.payload.stat_list,
        count: action.payload.count,
      };
    case GET_DONATIONS_ORG_STATS_SUCCESS:
      return {
        ...state,
        stats_list: action.payload.stat_list,
        count: action.payload.count,
      };

    case GET_DEMOGRAPHIC_REPORTS_GROUPBY_SUCCESS:
      return {
        ...state,
        stats_list: action.payload.stat_list,
        count: action.payload.count,
      };

    case GET_DONATIONS_OGS_BY_PATTERN_STATS_SUCCESS:
      return {
        ...state,
        weekly: action.payload.weekly,
        count: action.payload.count,
      };

    case RESET_CLIENT_FLAG:
      return {
        ...state,
        isClientAdd: false,
        isClientAddFail: false,
        isClientCreated: false,
        isClientDelete: false,
        isClientDeleteFail: false,
        isClientUpdate: false,
        isClientUpdateFail: false,
        errors: false,
        error: false,
      };
    default:
      return { ...state };
  }
};

export default Statistics;
