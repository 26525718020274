import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  FormText,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";

// Import React FilePond
import { registerPlugin } from "react-filepond";
import Select from "react-select";
// Import FilePond styles
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";

import { ToastContainer } from "react-toastify";
import MsgToast from "../../../../Components/Common/MsgToast";

import { useParams } from "react-router-dom";
import DateField from "../../../../Components/Common/Fields/DateField";
import TextField from "../../../../Components/Common/Fields/TextField.js";

import {
  durationUnitOptions,
  durationTypeOptions,
  groupTypeOptions,
  statusOptions,
} from "../../../../common/data/common";

import {
  getMembershipBenefits,
  getMembershipLevels,
  getMembershipTypes as onGetMembershipTypes,
  resetMembershipFlag,
  addNewMembershipType as onAddNewMembershipType,
  updateMembershipType as onUpdateMembershipType,
} from "../../../../store/actions";
import NumberField from "../../../../Components/Common/NumberField";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const CreateMembershipType = () => {
  const dispatch = useDispatch();
  const { id: type_pk } = useParams();
  const selectRef = useRef();

  const {
    error,
    loading,
    typesList,
    benefitsList,
    levelsList,
    isMembershipTypeAdd,
    isMembershipTypeAddFail,
    isMembershipTypeCreated,
    isMembershipTypeUpdate,
    isMembershipTypeUpdateFail,
    isMembershipTypeSuccess,
  } = useSelector((state) => ({
    error: state.Membership.error,
    benefitsList: state.Membership.membership_benefits,
    levelsList: state.Membership.membership_levels,
    typesList: state.Membership.membership_types,
    loading: state.Membership.loading,
    isMembershipTypeAdd: state.Membership.isMembershipTypeAdd,
    isMembershipTypeAddFail: state.Membership.isMembershipTypeAddFail,
    isMembershipTypeCreated: state.Membership.isMembershipTypeCreated,
    isMembershipTypeSuccess: state.Membership.isMembershipTypeSuccess,
    isMembershipTypeUpdate: state.Membership.isMembershipTypeUpdate,
    isMembershipTypeUpdateFail: state.Membership.isMembershipTypeUpdateFail,
  }));

  const [membershipType, setMembershipType] = useState(null);
  const [benefits, setBenefits] = useState(null);
  const [levelState, setLevelState] = useState(null);
  const [groupTypeState, setGroupTypeState] = useState(null);

  useEffect(() => {
    //console.log("ERROR: ", error, "UPDATE: ", isMembershipTypeUpdateFail);

    setTimeout(() => {
      dispatch(resetMembershipFlag());
    }, 3000);
  }, [
    error,
    dispatch,
    isMembershipTypeSuccess,
    isMembershipTypeCreated,
    isMembershipTypeAdd,
    isMembershipTypeAddFail,
    isMembershipTypeUpdate,
    isMembershipTypeUpdateFail,
  ]);

  useEffect(() => {
    if (!typesList) {
      dispatch(onGetMembershipTypes());
      return;
    }

    if (type_pk) {
      let type = typesList?.find((c) => c.id.toString() === type_pk.toString());
      setMembershipType(type);
      //setBenefits(type?.benefits);
    }

    if (!benefitsList) {
      dispatch(getMembershipBenefits());
    }

    if (!levelsList) {
      dispatch(getMembershipLevels());
    }
  }, [dispatch, typesList, levelsList, benefitsList, type_pk]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: membershipType?.id || "",
      name: membershipType?.name || "",
      grace_period: membershipType?.grace_period || 0,

      no_of_sub_members: membershipType?.no_of_sub_members || 0,
      group_type: membershipType?.group_type || "no-group",

      status: membershipType?.status || "active",

      duration_type: membershipType?.duration_type || "Fixed",
      duration_unit: membershipType?.duration_unit || "",
      duration: membershipType?.duration || null,

      start_date: membershipType?.start_date || null,
      expiry_date: membershipType?.expiry_date || null,

      price: membershipType?.price || 0,
      renewal_price: membershipType?.renewal_price || "",
      donation: membershipType?.donation || "",
      benefits: membershipType?.benefits || null,
      level: membershipType?.level?.id || "",
      description: membershipType?.description || "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Please enter a name"),
      grace_period: Yup.string().notRequired(),

      group_type: Yup.string().notRequired().nullable(),
      duration_unit: Yup.string().notRequired(),
      duration: Yup.number()
        .nullable()
        .notRequired()
        .positive("Duration Cannot Be Negative or Zero")
        .test(
          "max-duration",
          "Maximum Duration is 1 year",
          (value) =>
            validation.values.duration_unit !== "year" || (validation.values.duration_unit === "year" && value === 1)
        ),
      no_of_sub_members: Yup.number().notRequired(),
      status: Yup.string().notRequired(),
      start_date: Yup.string().notRequired().nullable(),
      expiry_date: Yup.string().notRequired().nullable(),
      price: Yup.number()
        .test("price", "Price must be a positive integer or 0", (value) => value >= 0)
        .required("Please enter a membership fee"),
      renewal_price: Yup.number().notRequired("Please enter a membership fee").nullable(),
      donation: Yup.number().notRequired("Please enter a donation"),
      benefits: Yup.mixed().notRequired("Please select the benefits"),
      description: Yup.string().required("Please enter a description for this membership type"),
    }),

    onSubmit: (values) => {
      //console.log("VALUES: ", values, selectRef);
      const m_type = values;
      //m_type.benefits = benefits?.map((benefit) => benefit.id);
      m_type.benefits = m_type.benefits?.map((benefit) => benefit.id);

      if (type_pk) {
        dispatch(onUpdateMembershipType(m_type));
      } else {
        dispatch(onAddNewMembershipType(m_type));
      }
      selectRef.current.clearValue();
      validation.resetForm();
    },
  });

  document.title = "Create MembershipType - Membersip Management | SimplyNP";

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Membership Types" pageTitle="Membership " />

        <Row>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col lg={8}>
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Basic Information</h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <TextField
                        md="6"
                        mb="mb-3"
                        value={validation.values.name}
                        onChange={validation.handleChange}
                        validation={validation}
                        onBlur={validation.handleBlur}
                        placeholder="Enter name"
                        label="Name"
                        name="name"
                      />

                      <NumberField
                        md="6"
                        mb="mb-3"
                        value={validation.values.grace_period}
                        onChange={validation.handleChange}
                        validation={validation}
                        onBlur={validation.handleBlur}
                        placeholder="Enter grace period in days"
                        label="Grace period after expiry"
                        name="grace_period"
                      />
                    </Row>

                    <Row>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="duration_type-input">
                          Duration Type:
                        </Label>

                        <Select
                          name="term"
                          onChange={(e) => validation.setFieldValue("duration_type", e?.value)}
                          options={durationTypeOptions}
                          defaultValue={durationTypeOptions[0]}
                          isDisabled={type_pk}
                          placeholder="Select a duration type..."
                          id="term-input"
                          value={
                            durationTypeOptions?.find((type) => type?.value === validation.values.duration_type) || null
                          }
                          invalid={validation.touched.duration_type && validation.errors.duration_type ? true : false}
                          classNamePrefix="select2-selection form-select"
                        />

                        {validation.touched.duration_type && validation.errors.duration_type ? (
                          <FormFeedback type="invalid">{validation.errors.duration_type}</FormFeedback>
                        ) : null}
                      </div>
                    </Row>

                    <Row>
                      {validation.values.duration_type === "Fixed" ? (
                        <>
                          <Col>
                            <DateField
                              mb="mb-3"
                              md="12"
                              value={validation.values.start_date || ""}
                              onChange={validation.handleChange}
                              validation={validation}
                              onBlur={validation.handleBlur}
                              label="Default start date"
                              name="start_date"
                              disabled={type_pk}
                            />
                          </Col>

                          <Col>
                            <DateField
                              mb="mb-3"
                              md="12"
                              value={validation.values.expiry_date || ""}
                              onChange={validation.handleChange}
                              validation={validation}
                              onBlur={validation.handleBlur}
                              label="Default expiry date"
                              name="expiry_date"
                              disabled={type_pk}
                            />
                          </Col>
                        </>
                      ) : (
                        <>
                          <NumberField
                            md="6"
                            mb="mb-3"
                            value={validation.values.duration}
                            onChange={validation.handleChange}
                            validation={validation}
                            onBlur={validation.handleBlur}
                            placeholder="Enter duration"
                            label="Duration"
                            disabled={type_pk}
                            name="duration"
                          />

                          <Col>
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="duration_type-input">
                                Duration Unit:
                              </Label>

                              <Select
                                name="duration_unit"
                                isDisabled={type_pk}
                                onChange={(e) => validation.setFieldValue("duration_unit", e?.value)}
                                options={durationUnitOptions}
                                defaultValue={durationUnitOptions[0]}
                                placeholder="Select a duration unit..."
                                id="duration_unit-input"
                                value={
                                  durationUnitOptions?.find(
                                    (duration_unit) => duration_unit.value === validation.values.duration_unit
                                  ) || null
                                }
                                invalid={
                                  validation.touched.duration_unit && validation.errors.duration_unit ? true : false
                                }
                                classNamePrefix="select2-selection form-select"
                              />

                              {validation.touched.duration_unit && validation.errors.duration_unit ? (
                                <FormFeedback type="invalid">{validation.errors.duration_unit}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Membership Price</h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col /*md={6}*/>
                        <Label>Price</Label>
                        <InputGroup className="mb-3">
                          <InputGroupText>$</InputGroupText>
                          <Input
                            md="6"
                            type="number"
                            step="any"
                            min={0}
                            value={validation.values.price}
                            onChange={validation.handleChange}
                            validation={validation}
                            onBlur={validation.handleBlur}
                            onFocus={validation.handleFocus}
                            placeholder="Enter amount"
                            name="price"
                            invalid={validation.touched.price && validation.errors.price ? true : false}
                            disabled={type_pk}
                          />

                          {validation.touched.price && validation.errors.price ? (
                            <FormFeedback type="invalid" valid={false}>
                              {validation.errors.price}
                            </FormFeedback>
                          ) : null}
                        </InputGroup>
                        {!validation.errors.price && validation.values.price ? (
                          <FormText type="valid">
                            Prices are calculated at a charge of (3.4% + 60 cents), Final Price:{" $"}
                            {(
                              parseFloat(validation.values.price).toFixed(2) -
                              (34 / 1000) * parseFloat(validation.values.price).toFixed(2)
                            ).toFixed(2)}
                          </FormText>
                        ) : null}
                      </Col>

                      {/*  <Col md={6}>
                        <Label>Renewal Price</Label>
                        <InputGroup className="mb-3">
                          <InputGroupText>$</InputGroupText>
                          <Input
                            md="6"
                            type="number"
                            value={validation.values.renewal_price}
                            onChange={validation.handleChange}
                            validation={validation}
                            onBlur={validation.handleBlur}
                            onFocus={validation.handleFocus}
                            placeholder="Enter renewal price"
                            label="Renewal Price"
                            name="renewal_price"
                          />
                        </InputGroup>
                      </Col>
      */}
                    </Row>

                    <Row>
                      {/*
                      <Label>Donation</Label>
                      <InputGroup className="mb-3">
                        <InputGroupText>$</InputGroupText>
                        <Input
                          md="6"
                          value={validation.values.donation}
                          onChange={validation.handleChange}
                          validation={validation}
                          onBlur={validation.handleBlur}
                          onFocus={validation.handleFocus}
                          placeholder="Enter donation amount"
                          label="Donation"
                          name="donation"
                        />
                      </InputGroup>
      */}
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Other Information</h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="status-input">
                          Status
                        </Label>

                        <Select
                          name="status"
                          isClearable
                          onChange={(e) => validation.setFieldValue("status", e?.value)}
                          options={statusOptions}
                          defaultValue={statusOptions[0]}
                          placeholder="Select a status..."
                          id="status-input"
                          value={statusOptions?.find((status) => status.value === validation.values.status) || null}
                          invalid={validation.touched.status && validation.errors.status ? true : false}
                          classNamePrefix="select2-selection form-select"
                        />

                        {validation.touched.status && validation.errors.status ? (
                          <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                        ) : null}
                      </div>
                    </Row>

                    <div>
                      <Label>Membership Type Description</Label>
                      <Input
                        type="textarea"
                        className="form-control"
                        placeholder="Membership Type Description"
                        name="description"
                        value={validation.values.description}
                        onChange={validation.handleChange}
                        invalid={validation.touched.description && validation.errors.description ? true : false}
                        rows="10"
                      />
                      {validation.touched.description && validation.errors.description ? (
                        <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={4}>
                {/* Levels */}
                {/*<Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Assign</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="levels-input">
                        Add Level
                      </Label>

                      <Select
                        name="levels"
                        id="levels-input"
                        isClearable
                        isSearchable
                        getOptionLabel={(option) => option?.name}
                        getOptionValue={(option) => option?.id}
                        placeholder="Add a level"
                        options={levelsList}
                        onChange={(e) => {
                          setLevelState(e);
                          validation.setFieldValue("level", e?.id);
                        }}
                        value={levelState}
                        key={levelState}
                        invalid={validation.touched.level && validation.errors.level ? true : false}
                        classNamePrefix="select2-selection form-select"
                      />

                      {validation.touched.level && validation.errors.level ? (
                        <FormFeedback type="invalid">{validation.errors.level}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label" htmlFor="group_type-input">
                        Group Type
                      </Label>

                      <Select
                        name="group_type"
                        id="group_type-input"
                        isClearable
                        isSearchable
                        placeholder="Add a group"
                        options={groupTypeOptions}
                        onChange={(e) => {
                          setGroupTypeState(e);
                          validation.setFieldValue("group_type", e?.value);
                          // clean up for when no-group is selected for group-type
                          if (e?.value === "no-group") validation.setFieldValue("no_of_sub_members", 0);
                        }}
                        defaultValue={groupTypeOptions[0]}
                        value={groupTypeOptions.find((option) => option?.value === validation.values.group_type)}
                        invalid={validation.touched.group_type && validation.errors.group_type ? true : false}
                        classNamePrefix="select2-selection form-select"
                      />

                      {validation.touched.group_type && validation.errors.group_type ? (
                        <FormFeedback type="invalid">{validation.errors.group_type}</FormFeedback>
                      ) : null}
                    </div>

                    {validation.values.group_type && validation.values.group_type !== "no-group" && (
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="no_of_sub_members-input">
                          Max No. of Sub-Members
                        </Label>

                        <Input
                          name="no_of_sub_members"
                          id="no_of_sub_members-input"
                          type="number"
                          placeholder="Number of sub-members"
                          onChange={validation.handleChange}
                          value={validation.values.no_of_sub_members}
                          invalid={
                            validation.touched.no_of_sub_members && validation.errors.no_of_sub_members ? true : false
                          }
                        />

                        {validation.touched.no_of_sub_members && validation.errors.no_of_sub_members ? (
                          <FormFeedback type="invalid">{validation.errors.no_of_sub_members}</FormFeedback>
                        ) : null}
                      </div>
                    )}
                  </CardBody>
                </Card>
*/}
                {/* Benefits */}
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Benefits</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="benefits-input">
                        Add Benefits
                      </Label>

                      <Select
                        name="benefits"
                        id="benefits-input"
                        ref={selectRef}
                        isMulti
                        isClearable
                        isSearchable
                        getOptionLabel={(option) => option?.description}
                        getOptionValue={(option) => option?.id}
                        placeholder="Add a benefit"
                        options={benefitsList}
                        cacheOptions={true}
                        onChange={(e) => {
                          //setBenefits(e);
                          validation.setFieldValue("benefits", e);
                        }}
                        //value={benefits}
                        value={validation.values.benefits}
                        key={JSON.stringify(validation.values.benefits)}
                        invalid={validation.touched.benefits && validation.errors.benefits ? true : false}
                        classNamePrefix="select2-selection form-select"
                      />

                      {validation.touched.benefits && validation.errors.benefits ? (
                        <FormFeedback type="invalid">{validation.errors.benefits}</FormFeedback>
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={8}>
                <div className="text-end mb-3">
                  <button type="button" className="btn btn-danger w-sm me-1" onClick={() => validation.handleReset()}>
                    Clear
                  </button>
                  <button disabled={validation.isSubmitting} type="submit" className="btn btn-success w-sm">
                    {validation.isSubmitting ? "Submitting" : "Submit"}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </Row>

        {isMembershipTypeAdd ? (
          <MsgToast msg="Membershiptype added successfully" color="success" icon="ri-checkbox-circle-line" />
        ) : null}

        {isMembershipTypeAddFail ? (
          <MsgToast msg="Membershiptype added failed" color="danger" icon="ri-error-warning-line" />
        ) : null}

        {isMembershipTypeUpdate ? (
          <MsgToast msg="Membershiptype updated successfully" color="success" icon="ri-checkbox-circle-line" />
        ) : null}

        {isMembershipTypeUpdateFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}

        <ToastContainer limit={1} closeButton={false} />
      </Container>
    </div>
  );
};

export default CreateMembershipType;
