import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedInUser } from "../../helpers/jwt-token-access/auth-token-header";
import { getProfile } from "../../store/actions";

const useProfile = () => {
  const dispatch = useDispatch();

  const userProfileSession = getLoggedInUser();
  var token = userProfileSession && userProfileSession["token"];
  var isActive = userProfileSession && userProfileSession["status"];
  const [loading, setLoading] = useState(userProfileSession ? false : true);
  const [userProfile, setUserProfile] = useState(userProfileSession ? userProfileSession : null);

  const { org } = useSelector((state) => ({
    org: state.Profile.org,
  }));

  useEffect(() => {
    if (!org) dispatch(getProfile());

    const userProfileSession = getLoggedInUser();
    var token = userProfileSession && userProfileSession["token"];
    setUserProfile(userProfileSession ? userProfileSession : null);
    setLoading(token ? false : true);
  }, [dispatch, org]);

  return { userProfile, loading, token, isActive: org?.subscription?.status };
};

export { useProfile };
