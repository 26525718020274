import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

//Import Flatepicker
import Select from "react-select";

import { useFormik } from "formik";
import * as Yup from "yup";

import MsgToast from "../../../Components/Common/MsgToast";

// Import React FilePond
import { FilePond } from "react-filepond";
// Import FilePond styles
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond/dist/filepond.min.css";
//Import Images
import { useParams } from "react-router-dom";

import {
  addNewNote,
  getNotesList,
  getCasesList as onGetCases,
  getClientsList as onGetClients,
  getUsersList as onGetUsers,
  resetNoteFlag,
  updateNote,
} from "../../../store/actions";

import AsyncSelect from "react-select/async";
import { getUsers } from "../../../helpers/backend_helper";

import TextField from "../../../Components/Common/Fields/TextField";

const NoteForm = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();

  const [fieldError, setFieldError] = useState(false);
  const [note, setNote] = useState(null);
  const [mCase, setMCase] = useState(null);
  const [client, setClient] = useState(null);
  const [assignedUser, setAssignedUser] = useState(null);

  // Dropzone file upload
  const [selectedFiles, setselectedFiles] = useState([]);
  const [files, setFiles] = useState([]);

  const {
    usersList,
    isNoteAdd,
    isNoteAddFail,
    isNoteDelete,
    isNoteDeleteFail,
    error,
    casesList,
    clientsList,
    notesList,
    isNoteUpdate,
    isNoteUpdateFail,
  } = useSelector((state) => ({
    casesList: state.Cases.casesList,
    notesList: state.Notes.notesList,
    clientsList: state.Clients.clientsList,
    usersList: state.Users.usersList,
    error: state.Notes.error,
    isNoteAdd: state.Notes.isNoteAdd,
    isNoteDelete: state.Notes.isNoteDelete,
    isNoteDeleteFail: state.Notes.isNoteDeleteFail,
    isNoteAddFail: state.Notes.isNoteAddFail,
    isNoteUpdate: state.Notes.isNoteUpdate,
    isNoteUpdateFail: state.Notes.isNoteUpdateFail,
  }));

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: (note && note.id) || id || "",
      title: (note && note.title) || "",
      status: (note && note.status) || "",
      priority: (note && note.priority) || "",
      note_type: (note && note.note_type) || "",
      resolution: (note && note.resolution) || "",
      note: (note && note.note) || ``,
      teams: (note && note.teams) || "",
      assigned_to: "",
      client: note?.client_id?.id || "",
      case: note?.case_id?.id || "",
      created_on: (note && note.created_on) || "",
      note_attachment: (note && note.note_attachment) || "",
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter a Title for this Note"),
      status: Yup.string().notRequired("Please select the status for this note"),
      priority: Yup.string().notRequired("Please select a priority level for this note"),
      note_type: Yup.string().notRequired("Please Enter What Type of Note This is"),
      resolution: Yup.string().notRequired("Please Enter a resolution for this note"),
      note: Yup.string().required("Please enter a note"),
      description: Yup.string().notRequired("Please enter a note description"),
      teams: Yup.mixed().notRequired("Please select a team to work on this Note"),
      assigned_to: Yup.string().notRequired("Please select whom to assign this note to"),
      client: Yup.string().notRequired("Please enter a client for this note"),
      case: Yup.string().notRequired("Please enter a case for this note"),
      note_attachment: Yup.mixed(),
    }),

    onSubmit: (values) => {
      const noFieldErrors = Object.keys(validation.errors).length === 0;

      if (noFieldErrors) {
        const formData = new FormData();

        for (let v in values) {
          formData.set(v, values[v]);
        }

        if (files && files.length) {
          formData.set("note_attachment", files[0].file);
        }

        if (props.updateNote || id) {
          dispatch(updateNote(formData));
        } else {
          dispatch(addNewNote(formData));
        }
      } else {
        setFieldError(true);
        setTimeout(() => {
          setFieldError(false);
        }, 3000);
      }
    },
  });

  const loadUsersOptions = (inputValue) => {
    return new Promise((resolve) => {
      const orgList = getUsers({ search: inputValue }).then(({ users: { users } }) => users);
      resolve(orgList);
    });
  };

  useEffect(() => {
    if (!notesList) {
      dispatch(getNotesList({ id }));
      return;
    }

    if (props.updateNote) {
      if (notesList) {
        if (note) return;
        const n = notesList?.find((c) => c.id.toString() === id);
        setNote(n);

        const { case_id, client_id, assigned_to } = n || {};
        setMCase(case_id);
        setClient(client_id);
        setAssignedUser(assigned_to);
      }
    }

    if (location.state) {
      const { caseItem, clientItem } = location.state;

      setMCase(caseItem);
      setFieldValue("case", caseItem?.id);

      setClient(clientItem);
      setFieldValue("client", clientItem?.id);
    }
  }, [dispatch, note, notesList, location, id, props.updateNote]);

  const setFieldValue = (source, value) => {
    validation.setFieldValue(source, value);
  };

  useEffect(() => {
    if (isNoteAdd || isNoteAddFail || error || isNoteUpdate || isNoteUpdateFail) {
      setTimeout(() => {
        dispatch(resetNoteFlag());
      }, 3000);
    }
  }, [dispatch, isNoteAdd, isNoteAddFail, error, isNoteUpdate, isNoteUpdateFail]);

  useEffect(() => {
    // TODO refactor this - either return all cases and clients or find a better way
    if (!casesList) dispatch(onGetCases());
    if (!clientsList) dispatch(onGetClients());
    if (!usersList) dispatch(onGetUsers());
  }, [dispatch, usersList, casesList, clientsList]);

  let noteCLient = note && note.case_id && note.case_id.client;

  noteCLient = noteCLient && noteCLient.client;
  noteCLient = noteCLient && noteCLient.id;

  useEffect(() => {
    if (isNoteAdd) {
      validation.resetForm();
    }
  }, [isNoteAdd]);

  const reset = () => {
    setAssignedUser(null);
    validation.resetForm({ values: "" });
    setNote(null);
  };

  const handleNoteDelete = () => {
    reset();
  };

  document.title = "Create Note | Velzon - React Admin & Dashboard Template";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={(props.updateNote ? "Update" : "Create") + " Note"} pageTitle="Notes" />
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            // onSubmit={validation.handleSubmit}
          >
            <Row>
              <Col lg={8}>
                <Card>
                  <CardBody>
                    <Row>
                      <TextField
                        md="6"
                        mb="mb-3"
                        value={validation.values.title}
                        onChange={validation.handleChange}
                        validation={validation}
                        onBlur={validation.handleBlur}
                        placeholder="Enter note title"
                        label="Note title"
                        name="title"
                        errorMessage={error && error.errors}
                      />
                      <Col lg={6}>
                        <div className="mb-3 mb-lg-0">
                          <Label htmlFor="case-type-input" className="form-label">
                            Note type
                          </Label>
                          <Select
                            data-choices
                            data-choices-search-false
                            id="choices-status-input"
                            value={{ label: validation.values.note_type, value: validation.values.note_type }}
                            name="note_type"
                            // className="form-control is-invalid"
                            className={`${
                              validation.touched.note_type && validation.errors.note_type
                                ? "form-control is-invalid"
                                : ""
                            } `}
                            isMulti={false}
                            onChange={(e) => validation.setFieldValue("note_type", e.value)}
                            options={[
                              { label: "Normal", value: "Normal" },
                              { label: "Reminder", value: "Reminder" },
                            ]}
                            invalid={validation.touched.note_type && validation.errors.note_type ? true : false}
                          />

                          {validation.touched.note_type && validation.errors.note_type ? (
                            <FormFeedback type="invalid">{validation.errors.note_type}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="case-client-input">
                            Client
                          </Label>
                          <Select
                            id="case-clien-input"
                            name="client"
                            onChange={(e) => {
                              validation.setFieldValue("client", e.value);
                              setClient(e);
                            }}
                            key={client}
                            value={client}
                            getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
                            getOptionValue={(option) => `${option?.id}`}
                            isDisabled={props.updateNote || (location.state && client) || false}
                            options={clientsList}
                            invalid={validation.touched.client && validation.errors.client ? true : false}
                            classNamePrefix="select2-selection form-select"
                          />
                          {validation.touched.client && validation.errors.client ? (
                            <FormFeedback type="invalid">{validation.errors.client}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="case-client-input">
                            Case
                          </Label>
                          <Select
                            id="case"
                            name="case"
                            onChange={(e) => {
                              validation.setFieldValue("case", e.value);
                              setMCase(e);
                            }}
                            key={mCase}
                            value={mCase}
                            getOptionLabel={(option) => `${option?.title}`}
                            getOptionValue={(option) => `${option?.id}`}
                            options={casesList}
                            isDisabled={props.updateNote || (location.state && mCase) || false}
                            invalid={validation.touched.case && validation.errors.case ? true : false}
                            classNamePrefix="select2-selection form-select"
                          />
                          {validation.touched.case && validation.errors.case ? (
                            <FormFeedback type="invalid">{validation.errors.case}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Note</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="mb-3">
                      <Input
                        type="textarea"
                        name="note"
                        onChange={validation.handleChange}
                        invalid={validation.touched.note && validation.errors.note ? true : false}
                        value={validation.values.note}
                        rows="5"
                      />
                      {validation.touched.note && validation.errors.note ? (
                        <FormFeedback type="invalid">{validation.errors.note}</FormFeedback>
                      ) : null}
                    </div>
                  </CardBody>
                </Card>

                {/* <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Note</h5>
                  </CardHeader>
                  <CardBody>
                    <CKEditor
                      editor={ClassicEditor}
                      // editor={Editor}
                      id="editor"
                      rows="16"
                      onChange={(e, editor) => {
                        validation.setFieldValue("note", editor && editor.getData());
                      }}
                      name="note"
                      data={validation.values.note}
                      // onReady={(editor) => {
                      //   // You can store the "editor" and use when it is needed.
                      //   console.log("Editor is ready to use!", editor);
                      // }}
                    />
                  </CardBody>
                </Card> */}
                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Attached files</h5>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <p className="text-muted">Add Attached files here.</p>
                      <FilePond
                        files={files}
                        onupdatefiles={setFiles}
                        allowMultiple={true}
                        maxFiles={1}
                        name="case_attachment"
                        value={validation.values.note_attachment}
                        className="filepond filepond-input-multiple"
                      />
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <button type="reset" onClick={handleNoteDelete} className="btn btn-danger w-sm me-1">
                    Clear
                  </button>
                  <button type="submit" /*onClick={handleCaseDraft}*/ className="btn btn-secondary w-sm me-1">
                    Draft
                  </button>
                  <button type="submit" className="btn btn-success w-sm">
                    {props.updateNote ? "Update Note" : "Create Note"}
                  </button>
                </div>
              </Col>

              <Col lg={4}>
                {/* <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Privacy</h5>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <Label htmlFor="choices-text-input" className="form-label">
                        Teams
                      </Label>
                      <Select
                        className={`${
                          validation.touched.teams && validation.errors.teams ? "form-control is-invalid" : ""
                        }`}
                        name="teams"
                        defaultValue={validation.values.teams}
                        id="choices-text-input"
                        isMulti={true}
                        onChange={(e) => validation.setFieldValue("teams", JSON.stringify(e.map((e) => e.value)))}
                        options={teamsOptions}
                      />
                      {validation.touched.teams && validation.errors.teams ? (
                        <FormFeedback type="invalid">{validation.errors.teams}</FormFeedback>
                      ) : null}
                    </div>
                  </CardBody>
                </Card> */}

                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Assign To</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="mb-3">
                      <Label htmlFor="choices-lead-input" className="form-label">
                        A User
                      </Label>
                      <AsyncSelect
                        className={`${
                          validation.touched.assigned_to && validation.errors.assigned_to
                            ? "form-control is-invalid"
                            : ""
                        } `}
                        isSearchable
                        isClearable
                        id="choices-team-input"
                        loadOptions={loadUsersOptions}
                        value={assignedUser}
                        getOptionLabel={(user) => `${user?.user_details?.first_name} ${user?.user_details?.last_name}`}
                        getOptionValue={(user) => `${user?.id}`}
                        defaultOptions={usersList}
                        name="assigned_to"
                        isMulti={true}
                        onChange={(e) => {
                          setAssignedUser(e);
                          validation.setFieldValue("assigned_to", JSON.stringify(e.map((e) => e?.id)));
                        }}
                      />
                      {validation.touched.assigned_to && validation.errors.assigned_to ? (
                        <FormFeedback type="invalid">{validation.errors.assigned_to}</FormFeedback>
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <h5>Status and Priority</h5>
                  </CardHeader>
                  <CardBody>
                    <Row className="mb-4">
                      <div className="mb-8 mb-lg-0">
                        <Label htmlFor="choices-status-input" className="form-label">
                          Status
                        </Label>
                        <Select
                          data-choices
                          data-choices-search-false
                          className={`${
                            validation.touched.status && validation.errors.status ? "form-control is-invalid" : ""
                          } `}
                          id="choices-status-input"
                          value={{ label: validation.values.status, value: validation.values.status }}
                          name="status"
                          isMulti={false}
                          isClearable
                          onChange={(e) => validation.setFieldValue("status", e?.value)}
                          options={[
                            { label: "Open", value: "Open" },
                            { label: "Pending", value: "Pending" },
                            { label: "Closed", value: "Closed" },
                          ]}
                        />
                        {validation.touched.status && validation.errors.status ? (
                          <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                        ) : null}
                      </div>
                    </Row>

                    <Row>
                      <div className="mb-3 mb-lg-0">
                        <Label htmlFor="choices-priority-input" className="form-label">
                          Priority
                        </Label>

                        <Select
                          data-choices
                          data-choices-search-false
                          id="choices-status-input"
                          value={{ label: validation.values.priority, value: validation.values.priority }}
                          name="priority"
                          // className="form-control is-invalid"
                          className={`${
                            validation.touched.priority && validation.errors.priority ? "form-control is-invalid" : ""
                          } `}
                          isMulti={false}
                          onChange={(e) => validation.setFieldValue("priority", e?.value)}
                          options={[
                            { label: "Urgent", value: "Urgent" },
                            { label: "Normal", value: "Normal" },
                            { label: "High", value: "High" },
                            { label: "Medium", value: "Medium" },
                            { label: "Low", value: "Low" },
                          ]}
                          isClearable
                          invalid={validation.touched.priority && validation.errors.priority ? true : false}
                        />

                        {validation.touched.priority && validation.errors.priority ? (
                          <FormFeedback type="invalid">{validation.errors.priority}</FormFeedback>
                        ) : null}
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {isNoteAdd ? (
              <MsgToast msg="Note Added Successfully" color="success" icon="ri-checkbox-circle-line" />
            ) : null}

            {isNoteAddFail ? <MsgToast msg="Note Added Failed" color="danger" icon="ri-error-warning-line" /> : null}

            {isNoteUpdate ? (
              <MsgToast msg="Note Updated Successfully" color="success" icon="ri-checkbox-circle-line" />
            ) : null}

            {isNoteUpdateFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NoteForm;
