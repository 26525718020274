import React, { useCallback, useEffect, useState } from "react";
import Loader from "../../../../Components/Common/Loader";

import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../../../Components/Common/DeleteModal";

import * as Yup from "yup";

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Select from "react-select";
import countries from "../../../../common/data/countries.json";

import {
  Button,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavLink,
  Row,
} from "reactstrap";

import { useFormik } from "formik";
import { Link, withRouter } from "react-router-dom";
import TextField from "../../../../Components/Common/Fields/TextField";
import { getSNPStripeKey } from "../../../../helpers/backend_helper";
import {
  removePaymentMethod,
  getPaymentMethod,
  addPaymentMethod,
  getBilling,
  resetBillingFlag,
  updateBilling,
  updatePaymentMethod,
} from "../../../../store/billing/actions.js";
import AlertModal from "../../../../Components/Common/AlertModal";

let checkIfFieldsIsValid = (fields) => {
  let fieldKeys = Object.keys(fields);
  let errOject = {};
  let fieldIsValid = true;

  for (let v of fieldKeys) {
    let value = fields[v];

    if (!value) {
      fieldIsValid = false;
      errOject[v] = v.replaceAll("_", " ") + " field is required";
    }
  }

  return { fieldIsValid, errOject };
};

const BillingInformation = ({ setShowContactModal, toggle }) => {
  const dispatch = useDispatch();

  const [isUpdate, setIsCheckoutUpdate] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const { billing_info, card, deleteBillingSuccess, error } = useSelector((state) => ({
    data: state.Billing.data,
    billing_info: state.Billing.billing_info,
    card: state.Billing.billing_info?.payment_card_summary,
    deleteBillingSuccess: state.Billing.deleteBillingSuccess,
  }));

  useEffect(() => {
    if (!billing_info) dispatch(getBilling());
    setTimeout(() => {
      dispatch(resetBillingFlag());
    }, 3000);
  }, [dispatch, billing_info]);

  const handleChangePaymentMethod = () => {
    setIsCheckoutUpdate(true);
    toggle();
  };

  const handleRemovePaymentMethod = (item) => {
    dispatch(removePaymentMethod());
    setDeleteModal(false);
  };

  const onClickDelete = () => {
    setDeleteModal(true);
  };

  return (
    <React.Fragment>
      <AlertModal
        show={deleteModal}
        alertTitle={"Remove Payment Method?"}
        alertMessage={`You're about to remove the following payment method: ${card?.card_brand} ending in ${card?.last4}`}
        acceptText={"Remove"}
        closeText={"Cancel"}
        onAcceptClick={handleRemovePaymentMethod}
        onCloseClick={() => setDeleteModal(false)}
      />

      <Container className="py-3">
        <Row>
          <Col>
            <CardHeader className="pb-0 border-0">
              <h2 className="mb-0">Billing Information</h2>
            </CardHeader>
            <CardBody>
              <p className="mb-0 fs-16 fw-medium">{billing_info?.name}</p>
              <br />
              <p className="mb-0 fs-14 fw-medium">{billing_info?.phone}</p>
              <p className="mb-0 fs-14 fw-medium">{billing_info?.email}</p>
              <br />
              <p className="mb-0 fs-14 fw-medium">{billing_info?.address?.line1}</p>
              <p className="mb-0 fs-14 fw-medium">{billing_info?.address?.line2}</p>
              <p className="mb-0 fs-14 fw-medium">{billing_info?.address?.street}</p>
              <p className="mb-0 fs-14 fw-medium">
                {billing_info?.address?.city} {billing_info?.address?.state}
              </p>
              <p className="mb-0 fs-14 fw-medium">{billing_info?.address?.country}</p>
              <br />
              <Link
                to="#"
                onClick={() => setShowContactModal(true)}
                className="text-decoration-underline fs-14 line-offset-1"
              >
                Edit Billing Contact Info
              </Link>
            </CardBody>
          </Col>
          <Col>
            <CardHeader className="pb-0 border-0">
              <h2 className="mb-0">Payment Method</h2>
            </CardHeader>
            <CardBody className="p-4">
              <div className="">
                <div className="border">
                  <div className="d-flex justify-content-between px-3 py-2 align-items-center border-bottom">
                    <div className="d-inline-flex align-items-center">
                      <div className="align-middle me-2">
                        <i className="ri-bank-card-fill fs-22"></i>
                      </div>
                      <div className="media-body">
                        <p className="mb-0 fs-16 fst-italic fw-medium">{`•••• •••• •••• ${card?.last4}`}</p>
                      </div>
                    </div>
                    <NavLink
                      onClick={() => {
                        onClickDelete(billing_info);
                      }}
                      data-action="remove-card"
                      className=" small-meta fs-14 fw-bold cursor-pointer link-opacity-hover-100"
                      aria-label="Remove this payment method"
                    >
                      Remove
                    </NavLink>
                  </div>
                  <div className="px-3 py-2">
                    <p className="small-meta mb-0 py-2 fs-16 fw-medium">
                      Expires: {`${card?.exp_month}/${card?.exp_year?.toString()?.substr(2, 3)}`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-4 pt-2">
                <div className="">
                  <Input
                    onClick={() => handleChangePaymentMethod()}
                    type="button"
                    className="text-white bg-success w-6/12 border-0"
                    value="Change Payment Method"
                  />
                </div>
              </div>
            </CardBody>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const PaymentInformationModal = ({
  isAddNewPaymentMethod,
  setIsAddNewPaymentMethod,
  showPaymentModal,
  setShowPaymentModal,
  setShowContactModal,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const { billing_info, loading, updateBillingSuccess } = useSelector((state) => ({
    billing_info: state.Billing.billing_info,
    loading: state.Billing.loading,
    updateBillingSuccess: state.Billing.updateBillingSuccess,
  }));

  useEffect(() => {
    if (!billing_info) dispatch(getBilling());
  }, [dispatch, billing_info]);

  const handleCreatePaymentMethod = async () => {
    if (!stripe) return;

    // 1.0
    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });
      //console.log("CREATING PAYMENT METHOD: ", paymentMethod, "ERROR: ", error);

      // 2.0 Add New payment Method
      if (isAddNewPaymentMethod) {
        dispatch(addPaymentMethod({ payment_method: paymentMethod }));
      } else {
        dispatch(updatePaymentMethod({ payment_method: paymentMethod }));
      }

      // 3.0 Get Current Updates
      dispatch(getBilling());
      setIsAddNewPaymentMethod(false);

      // 4.0 show success dialog
      setShowPaymentModal(false);
    } catch (error) {
      // 1.0 Dispaly Errors
      // console.log(error);
    }
  };

  return (
    <Modal
      scrollable
      centered
      isOpen={showPaymentModal}
      toggle={() => setShowPaymentModal(!showPaymentModal)}
      size="lg"
    >
      <ModalHeader className="border-bottom" toggle={() => setShowPaymentModal(!showPaymentModal)}>
        Payment Information
      </ModalHeader>
      <ModalBody className="px-5">
        <h4 className="mb-3">Billing Information</h4>
        <div className="d-flex justify-content-between ">
          <div className="d-flex flex-column pe-3 flex-start">
            <p className="fs-14 my-0">{billing_info?.name}</p>
            <p className="fs-14 my-0">{billing_info?.phone}</p>
            <p className="fs-14 my-0">{billing_info?.email}</p>
          </div>
          <div className="d-flex flex-column pe-3 flex-start">
            <p className="fs-14 my-0">{billing_info?.shipping?.address?.line1}</p>
            <p className="fs-14 my-0">{billing_info?.shipping?.address?.line2}</p>
            <p className="fs-14 my-0">{billing_info?.shipping?.address?.street}</p>
            <p className="fs-14 my-0">
              {billing_info?.shipping?.address?.city}, {billing_info?.shipping?.address?.state}
            </p>
            <p className="fs-14">{billing_info?.shipping?.address?.country}</p>
          </div>
          <div className="flex-start me-5 d-flex align-self-center">
            <Link
              to="#"
              onClick={() => setShowContactModal(true)}
              className="link cursor-pointer fs-14 link-underline-primary text-underline"
            >
              Edit
            </Link>
          </div>
        </div>
        <hr className="text-muted" />
        <Row className="pb-4 pt-2">
          {/*Payment INformation*/}
          <Row className="">
            <Col md={6} className="">
              <Label htmlFor="id_card_number">Credit Card Number: </Label>
              <CardNumberElement id="id_card_number" className="form-control" />
            </Col>

            <Col md={4} className="">
              <Label htmlFor="id_expr_date">Expiration Date: </Label>
              <CardExpiryElement id="id_expr_date" className="form-control" />
            </Col>

            <Col md={2}>
              <Label htmlFor="id_cvc">CVC: </Label>
              <CardCvcElement id="id_cvc" className="form-control" options={{ placeholder: "123" }} />
            </Col>
          </Row>
        </Row>
      </ModalBody>
      <hr className="text-muted" />
      <ModalFooter>
        <Row>
          <Col className="d-flex gap-2">
            <button type="button" onClick={() => setShowPaymentModal(false)} className="btn btn-soft-danger">
              Cancel
            </button>
            <button
              type="button"
              //disabled={{}}
              onClick={() => handleCreatePaymentMethod()}
              className="btn btn-soft-success"
            >
              Save
            </button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

const ContactInformationModal = ({ showContactModal, setShowContactModal }) => {
  const dispatch = useDispatch();

  const { billing_info, loading, updateBillingSuccess } = useSelector((state) => ({
    billing_info: state.Billing.billing_info,
    payment_method: state.Billing.payment_method,
    loading: state.Billing.loading,
    updateBillingSuccess: state.Billing.updateBillingSuccess,
  }));

  const validation = useFormik({
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string().email().notRequired(),
    }),
    initialValues: {
      email: billing_info?.email || "",
      phone: billing_info?.phone || "",
      first_name: billing_info?.name?.split(" ")[0] || "",
      last_name: billing_info?.name?.split(" ")[1] || "",
      line1: billing_info?.shipping?.address?.line1 || "",
      line2: billing_info?.shipping?.address?.line2 || "",
      country: billing_info?.shipping?.address?.country || "",
      city: billing_info?.shipping?.address?.city || "",
      state: billing_info?.shipping?.address?.state || "",
      postal_code: billing_info?.shipping?.address?.postal_code || "",
    },
    onSubmit: (values) => {
      dispatch(updateBilling(values));
      setShowContactModal(false);
    },
  });

  useEffect(() => {
    if (!billing_info) dispatch(getBilling());
  }, [dispatch, billing_info]);

  return (
    <Modal
      scrollable
      centered
      isOpen={showContactModal}
      toggle={() => setShowContactModal(!showContactModal)}
      size="lg"
    >
      <ModalHeader toggle={() => setShowContactModal(false)}>Billing Information</ModalHeader>
      <hr className="my-0 text-muted" />
      <ModalBody>
        <Container className="h-75 overflow-y-scroll">
          <Row>
            <TextField
              md="6"
              mb="mb-3"
              value={validation.values.first_name}
              onChange={validation.handleChange}
              validation={validation}
              onBlur={validation.handleBlur}
              placeholder="First Name"
              label="First Name"
              name="first_name"
              //errorMessage={address_errors}
            />

            <TextField
              md="6"
              mb="mb-3"
              value={validation.values.last_name}
              onChange={validation.handleChange}
              validation={validation}
              onBlur={validation.handleBlur}
              placeholder="Last Name"
              label="Last Name"
              name="last_name"
              //errorMessage={address_errors}
            />
          </Row>

          <Row>
            <TextField
              md="6"
              mb="mb-3"
              value={validation.values.email}
              onChange={validation.handleChange}
              validation={validation}
              onBlur={validation.handleBlur}
              placeholder="Email"
              label="Email Address"
              name="email"
              //errorMessage={address_errors}
            />

            <TextField
              md="6"
              mb="mb-3"
              value={validation.values.phone}
              onChange={validation.handleChange}
              validation={validation}
              onBlur={validation.handleBlur}
              placeholder="Phone"
              label="Phone"
              name="phone"
              //errorMessage={address_errors}
            />
          </Row>
          <Row>
            {/*<TextField
              md="6"
              mb="mb-3"
              value={validation.values.company}
              onChange={validation.handleChange}
              validation={validation}
              onBlur={validation.handleBlur}
              placeholder="Company / Organization"
              label="Company / Organization"
              name="company"
              //errorMessage={address_errors}
            /> */}
          </Row>

          <Row>
            <TextField
              md="6"
              mb="mb-3"
              value={validation.values.line1}
              onChange={validation.handleChange}
              validation={validation}
              onBlur={validation.handleBlur}
              placeholder="Address Line 1"
              label="Billing Address line 1"
              name="line1"
              //errorMessage={address_errors}
            />

            <TextField
              md="6"
              mb="mb-3"
              value={validation.values.line2}
              onChange={validation.handleChange}
              validation={validation}
              onBlur={validation.handleBlur}
              placeholder="Address Line 2"
              label="Billing Address Line 2"
              name="line2"
              //errorMessage={address_errors}
            />
          </Row>
          <Row>
            <Col>
              <div className="mb-3 md-6">
                <Label htmlFor="choices-lead-input" className="form-label">
                  Billing Country
                </Label>
                <Select
                  className={`${
                    validation.touched.country && validation.errors.country ? "form-control is-invalid" : ""
                  } `}
                  id="choices-team-input"
                  defaultValue={countries.find((c) => c.value === billing_info?.address?.country)}
                  key={{}}
                  name="country"
                  onBlur={validation.handleBlur}
                  placeholder="Country"
                  onChange={(e) => validation.setFieldValue("country", e?.value)}
                  options={countries}
                />
                {validation.touched.country && validation.errors.country ? (
                  <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                ) : null}
              </div>
            </Col>

            <TextField
              md="6"
              mb="mb-3"
              value={validation.values.city}
              onChange={validation.handleChange}
              validation={validation}
              onBlur={validation.handleBlur}
              placeholder="City"
              label="Billing City"
              name="city"
              //errorMessage={address_errors}
            />
          </Row>

          <Row>
            <TextField
              md="6"
              mb="mb-3"
              value={validation.values.postal_code}
              onChange={validation.handleChange}
              validation={validation}
              onBlur={validation.handleBlur}
              placeholder="Postcode"
              label="Billing Zip Code"
              name="postal_code"
              //errorMessage={address_errors}
            />

            <TextField
              md="6"
              mb="mb-3"
              value={validation.values.state}
              onChange={validation.handleChange}
              validation={validation}
              onBlur={validation.handleBlur}
              placeholder="State"
              label="Billing State"
              name="state"
              //errorMessage={address_errors}
            />
          </Row>
          <Row>{/*  */}</Row>
        </Container>
      </ModalBody>
      <hr className="my-0 text-muted" />
      <ModalFooter>
        <Row>
          <Col className="d-flex gap-2">
            <button
              type="button"
              onClick={() => {
                setShowContactModal(false);
              }}
              className="btn btn-soft-danger"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => {
                validation.handleSubmit();
              }}
              className="btn btn-soft-success"
            >
              Save
            </button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

const AccountBilling = () => {
  const dispatch = useDispatch();

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);

  const { billing_info, loading, updateBillingSuccess } = useSelector((state) => ({
    billing_info: state.Billing.billing_info,
    loading: state.Billing.loading,
    updateBillingSuccess: state.Billing.updateBillingSuccess,
  }));

  const [isAddNewMethod, setIsAddNewMethod] = useState(false);

  useEffect(() => {
    if (!billing_info) dispatch(getBilling());
    setTimeout(() => {
      dispatch(resetBillingFlag());
    }, 3000);
  }, [dispatch, billing_info]);

  const [stripePromise, setStripePromise] = useState(null);
  useEffect(() => {
    getSNPStripeKey().then(({ publishable_key }) => {
      setStripePromise(loadStripe(publishable_key));
    });
  }, []);

  const checkoutToggle = useCallback(
    (type) => {
      if (type === "setIsCheckoutUpdate") {
        //setIsCheckoutUpdate(true);
      }
      if (showPaymentModal) {
        setShowPaymentModal(false);
      } else {
        setShowPaymentModal(true);
      }
    },
    [showPaymentModal]
  );

  return (
    <React.Fragment>
      <Container>
        {/*Payment Information*/}
        <Elements stripe={stripePromise}>
          <PaymentInformationModal
            billingInfo={billing_info}
            isAddNewPaymentMethod={isAddNewMethod}
            setIsAddNewPaymentMethod={setIsAddNewMethod}
            showPaymentModal={showPaymentModal}
            setShowPaymentModal={setShowPaymentModal}
            setShowContactModal={setShowContactModal}
          />

          {/*Billing Information*/}
          <ContactInformationModal
            billingInfo={billing_info}
            showContactModal={showContactModal}
            setShowContactModal={setShowContactModal}
          />

          {loading ? (
            <Loader />
          ) : billing_info?.payment_card_summary ? (
            <BillingInformation
              toggle={checkoutToggle}
              billing_info={billing_info}
              setShowContactModal={setShowContactModal}
            />
          ) : (
            <Col>
              <Row className="justify-content-center">
                <Col md={8} className="p-4 text-center">
                  <div className="mt-4 pt-2">
                    <h3 className="mx-4">LOOKS LIKE YOU DONT HAVE A PAYMENT METHOD ADDED</h3>
                    <div className="mt-4">
                      <div className="mt-4">
                        <Button
                          type="button"
                          onClick={() => {
                            setIsAddNewMethod(true);
                            setShowPaymentModal(true);
                          }}
                          className="btn btn-success text-white bg-success w-6/12 border-0"
                        >
                          Add A Payment Method
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Elements>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(AccountBilling);
