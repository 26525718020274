import React from "react";
import UpdateVolunteerForm from "../FormComponent/index";

const CreateCase = () => {
  document.title = "Create Volunteer | Velzon - React Admin & Dashboard Template";

  return <UpdateVolunteerForm title="Update Volunteer" updateVolunteer />;
};

export default CreateCase;
