export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_CLIENTS_LIST = "GET_CLIENTS_LIST";
export const GET_CLIENT = "GET_CLIENT";

export const ADD_NEW_CLIENT = "ADD_NEW_CLIENT";
export const ADD_NEW_CLIENT_SUCCESS = "ADD_NEW_CLIENT_SUCCESS";
export const ADD_NEW_CLIENT_FAIL = "ADD_NEW_CLIENT_FAIL";

export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAIL = "UPDATE_CLIENT_FAIL";

export const DELETE_CLIENT = "DELETE_CLIENT";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAIL = "DELETE_CLIENT_FAIL";

export const RESET_CLIENT_FLAG = "RESET_CLIENT_FLAG";

export const CLIENT_STATS = "CLIENT_STATS";
export const CLIENT_STATS_SUCCESS = "CLIENT_STATS_SUCCESS";
export const GET_CASES_STATS = "GET_CASES_STATS";
export const GET_CASES_STATS_SUCCESS = "GET_CASES_STATS_SUCCESS";

export const GET_SERVICES_STATS = "GET_SERVICES_STATS";
export const GET_SERVICES_STATS_SUCCESS = "GET_SERVICES_STATS_SUCCESS";

export const GET_DONATIONS_ORG_STATS = "GET_DONATIONS_ORG_STATS";
export const GET_DONATIONS_ORG_STATS_SUCCESS = "GET_DONATIONS_ORG_STATS_SUCCESS";

export const GET_DONATIONS_CONTACTS_STATS = "GET_DONATIONS_CONTACTS_STATS";
export const GET_DONATIONS_CONTACTS_STATS_SUCCESS = "GET_DONATIONS_CONTACTS_STATS_SUCCESS";

export const GET_DONATIONS_CAMPAIGNS_STATS = "GET_DONATIONS_CAMPAIGNS_STATS";
export const GET_DONATIONS_CAMPAIGNS_STATS_SUCCESS = "GET_DONATIONS_CAMPAIGNS_STATS_SUCCESS";

export const GET_DEMOGRAPHIC_REPORTS_GROUPBY_SUCCESS = "GET_DEMOGRAPHIC_REPORTS_GROUPBY_SUCCESS";
export const GET_DEMOGRAPHIC_REPORTS_GROUPBY = "GET_DEMOGRAPHIC_REPORTS_GROUPBY";

export const GET_DONATIONS_OGS_BY_PATTERN_STATS = "GET_DONATIONS_OGS_BY_PATTERN_STATS";
export const GET_DONATIONS_OGS_BY_PATTERN_STATS_SUCCESS = "GET_DONATIONS_OGS_BY_PATTERN_STATS_SUCCESS";
