// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_CAMPAIGN_LIST = "GET_CAMPAIGN_LIST";

/**
 * Add CAMPAIGN
 */
export const ADD_NEW_CAMPAIGN = "ADD_NEW_CAMPAIGN";
export const ADD_CAMPAIGN_SUCCESS = "ADD_CAMPAIGN_SUCCESS";
export const ADD_CAMPAIGN_FAIL = "ADD_CAMPAIGN_FAIL";

/**
 * Edit CAMPAIGN
 */
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN_FAIL = "UPDATE_CAMPAIGN_FAIL";

/**
 * Delete CAMPAIGN
 */
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";
export const DELETE_CAMPAIGN_FAIL = "DELETE_CAMPAIGN_FAIL";

export const RESET_CAMPAIGN_FLAG = "RESET_CAMPAIGN_FLAG";
