import React, { useEffect, useRef, useState } from "react";
import { Col, Input, Label, FormFeedback } from "reactstrap";
import Select from "react-select";

// const SelectField = (props) => {
//   // console.log(props.errorMessage, props.name, "sel ERROR MESSAGE");

//   return (
//     <Col md={props.md}>
//       <div className={props.mb}>
//         <Label htmlFor="priority-field" className="form-label">
//           {props.label}
//         </Label>
//         <Input
//           name={props.name}
//           type="select"
//           // className="form-select"
//           className={`${
//             props.validation.touched[props.name] &&
//             props.validation.errors[props.name]
//               ? "form-control is-invalid"
//               : ""
//           } `}
//           id="billing_country-field"
//           onChange={props.validation.handleChange}
//           onBlur={props.validation.handleBlur}
//           value={props.validation.values[props.name] || ""}
//         >
//           <option>Select...</option>
//            {props.options.map((c) => {
//             return (
//               <option key={c.name} value={c.value}>
//                 {c.name}
//               </option>
//             );
//           })}
//         </Input>
//         {props.validation.touched.billing_country &&
//         props.validation.errors.billing_country ? (
//           <FormFeedback type="invalid">
//             {props.validation.errors.billing_country}
//           </FormFeedback>
//         ) : null}
//       </div>
//     </Col>
//   );
// };

const SelectField = ({ isClearOnSubmit, setIsClearOnSubmit, ...props }) => {
  const selectRef = useRef();

  const clearOnSubmit = () => {
    selectRef.current?.clearValue();
  };

  useEffect(() => {
    if (isClearOnSubmit) {
      clearOnSubmit();
      setIsClearOnSubmit(false);
    }
  }, [isClearOnSubmit]);

  return (
    <Col md={props.md}>
      <div className={props.mb}>
        <Label htmlFor={props.id} className="form-label">
          {props.label}
        </Label>

        <Select
          data-choices
          data-choices-search-false
          id={props.id}
          isClearable
          isSearchable
          ref={selectRef}
          defaultValue={props.defaultValue}
          key={props.defaultValue}
          name={props.name}
          placeholder={props.placeholder}
          value={props?.options.find((o) => o.value === props.validation.values[props.name])}
          className={`${
            (props.validation.touched[props.name] && props.validation.errors[props.name]) ||
            (props.errorMessage && props.errorMessage[props.name])
              ? "form-control is-invalid"
              : ""
          } `}
          onChange={(e) => props.validation.setFieldValue(`${props.name}`, (e && e.value) || "")}
          options={props?.options}
          invalid={props.validation.touched.source && props.validation.errors.source ? true : false}
        />

        {props.validation.touched[props.name] && props.validation.errors[props.name] ? (
          <FormFeedback type="invalid">{props.validation.errors[props.name]}</FormFeedback>
        ) : null}

        {props.errorMessage && props.errorMessage[props.name] && props.errorMessage[props.name].length > 0 ? (
          <FormFeedback type="invalid">
            <div>{props.errorMessage[props.name]}</div>
          </FormFeedback>
        ) : (
          ""
        )}
      </div>
    </Col>
  );
};

export default SelectField;
