import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

import { debounce } from "lodash";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";

//Import actions
import { deleteReport, getReportList, resetReportFlags } from "../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../Components/Common/MsgToast";
import { handleValidDate } from "../cases/ListView/CaseCol";

const ReportingList = () => {
  const dispatch = useDispatch();

  //delete client
  const [report, setReport] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { data, isDeleteSuccess, isDeleteFailed, reportList, loading, page, error } = useSelector((state) => ({
    page: state.Reporting.page,
    error: state.Reporting.error,
    loading: state.Reporting.loading,
    data: state.Reporting.data,
    reportList: state.Reporting.reportList,
    isDeleteSuccess: state.Reporting.isDeleteSuccess,
    isDeleteFailed: state.Reporting.isDeleteFailed,
  }));

  useEffect(() => {
    dispatch(getReportList());
  }, [dispatch, isDeleteSuccess]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetReportFlags());
    }, 3000);
  }, [dispatch, isDeleteSuccess]);

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return (
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="checkAll" value="option1" />
            </div>
          );
        },
      },
      {
        Header: "Report Title",
        accessor: "title",
        filterable: false,
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: false,
      },
      {
        Header: "Created By",
        filterable: true,
        accessor: "created_by",
        Cell: (cellProps) => {
          const user = cellProps.row.original?.created_by?.user_details;
          if (!user) return;
          const { first_name, last_name } = user;
          return <div>{`${first_name} ${last_name}`}</div>;
        },
      },
      {
        Header: "Created on",
        filterable: true,
        accessor: "created_on",
        Cell: (cellProps) => {
          return handleValidDate(cellProps.row.original.start_date);
        },
      },
      {
        Header: "Actions",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-3 mb-0">
              <li
                onClick={() => {}}
                className="list-inline-item edit text-muted d-inline-block cursor-pointer"
                title="View"
              >
                <i className="ri-eye-fill fs-16"></i>
              </li>
              <li className="list-inline-item edit" title="Edit">
                <Link to={"/report-update/" + cellProps.row.original.id} className="text-muted d-inline-block">
                  <i className="ri-edit-2-line fs-16"></i>
                </Link>
              </li>

              <li className="list-inline-item" title="Delete">
                <UncontrolledDropdown>
                  <DropdownToggle href="#" className="btn-soft-secondary btn-sm dropdown" tag="button">
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      className="dropdown-item remove-item-btn"
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        onClickDelete(contactData);
                      }}
                    >
                      <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  // Delete Data
  const handleDeleteClient = () => {
    if (report) {
      dispatch(deleteReport(report.id));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (report) => {
    setReport(report);
    setDeleteModal(true);
  };

  const handler = useCallback(
    debounce((value) => {
      dispatch(getReportList({ search: value }));
    }, 1000),
    []
  );

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    handler(value);
  };

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(getReportList({ offset, limit }));
    },
    [dispatch]
  );

  document.title = "Reports | SimplyNP";
  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal show={deleteModal} onDeleteClick={handleDeleteClient} onCloseClick={() => setDeleteModal(false)} />
        <Container fluid>
          <BreadCrumb title="List Reports" pageTitle="Reports" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-1">
                      <button onClick={() => setOpenModal(!openModal)} className="btn btn-info add-btn">
                        <i className="ri-add-fill me-1 align-bottom"></i> Add Report
                      </button>
                    </div>
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                        <button
                          className="btn btn-soft-danger"
                          // onClick="deleteMultiple()"
                        >
                          <i className="ri-delete-bin-2-line"></i>
                        </button>
                        <button className="btn btn-danger">
                          <i className="ri-filter-2-line me-1 align-bottom"></i> Filters
                        </button>
                        <button className="btn btn-soft-success">Import</button>

                        <UncontrolledDropdown>
                          <DropdownToggle href="#" className="btn btn-soft-info" tag="button">
                            <i className="ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" href="#">
                              All
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Week
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Month
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Year
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col>
              <Card id="clientList">
                <CardHeader>
                  <Row className="g-3">
                    <Col md={4}>
                      <div className="search-box">
                        <Input
                          onChange={(e) => {
                            handleFieldSearch(e);
                          }}
                          type="text"
                          className="form-control search"
                          placeholder="Search for reports..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    {/* (loading && <Loader />) || ( */}
                    <TableContainer
                      columns={columns}
                      data={reportList || []}
                      isGlobalFilter={false}
                      isAddUserList={false}
                      customFetchData={fetchData}
                      customTotalSize={data?.reports_count || 0}
                      customPageSize={10}
                      className="custom-header-css"
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light"
                    />
                    {/* ) */}
                  </div>

                  <ToastContainer limit={1} closeButton={false} />
                </CardBody>
              </Card>
              {isDeleteSuccess && (
                <MsgToast msg="Report deleted successfully" color="success" icon="ri-error-warning-line" />
              )}
            </Col>
          </Row>

          <Modal isOpen={openModal} id="event-modal" centered toggle={() => setOpenModal(!openModal)}>
            <ModalHeader tag="h5" toggle={() => setOpenModal(!openModal)} className="p-3 bg-soft-info modal-title">
              select report type
            </ModalHeader>
            <ModalBody>
              <CardBody>
                <Row>
                  <Col xs={12}>
                    <div className="text-center">
                      <Link to="/report-new/simple">Simple reports</Link>

                      <CardBody>
                        <Link to="/report-new/timeseries">Time series reports</Link>
                      </CardBody>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReportingList;
