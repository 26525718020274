import React, { useEffect } from "react";

import "./assets/scss/themes.scss";
import Route from "./Routes";
import TagManager from "react-gtm-module";

const GTM_ID = process.env.REACT_APP_GTM_ID;
const tagManagerArgs = {
  gtmId: GTM_ID,
};
TagManager.initialize(tagManagerArgs);

function App() {
  window.dataLayer.push({ event: "pageview", page: { url: window.location.pathname } });
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
