import {
  GET_USER,
  GET_USERS_LIST,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  ADD_NEW_USER,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAIL,
  RESET_USER_FLAG,
} from "./actionType";

const INIT_STATE = {
  usersList: null,
};

const Users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_USERS_LIST:
          return {
            ...state,
            usersList: action.payload.data.users,
            page: action.payload.data.page,
            isUserCreated: false,
            isUserSuccess: true,
          };
        case GET_USER:
          return {
            ...state,
            user: action.payload.data,
            isUserSuccess: true,
            isUserCreated: false,
          };
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_USERS_LIST:
        case GET_USER:
          return {
            ...state,
            error: action.payload.error,
            isUserCreated: false,
            isUserSuccess: false,
          };
        default:
          return { ...state };
      }
    // case GET_USERS_LIST: {
    //  return {
    //    ...state,
    //    isUserCreated: false,
    //  }
    // }
    case ADD_NEW_USER_SUCCESS:
      return {
        ...state,
        isUserCreated: true,
        isUserAdd: true,
        isCaseAddFail: false,
      };
    case ADD_NEW_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        isUserAdd: false,
        isUserAddFail: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isUserUpdate: true,
        isUserUpdateFail: false,
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        isUserUpdate: true,
        isUserUpdateFail: true,
      };
    case DELETE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        isUserDelete: false,
        isUserDeleteFail: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        usersList: state.usersList.filter((user) => user.id.toString() !== action.payload.user.toString()),
        isUserDelete: true,
        isUserDeleteFail: false,
      };
    case RESET_USER_FLAG:
      return {
        ...state,
        isUserAdd: false,
        isUserAddFail: false,
        isUserCreated: false,
        isUserDelete: false,
        isUserDeleteFail: false,
        isUserUpdate: false,
        isUserUpdateFail: false,
        errors: false,
      };
    default:
      return { ...state };
  }
};

export default Users;
