import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";

// Formik Validation

import "react-toastify/dist/ReactToastify.css";

// action
import { apiError, resetCreateOrginzationError, resetLoginFlag } from "../../store/actions";

//redux
import { useDispatch, useSelector } from "react-redux";

import { Link, useHistory } from "react-router-dom";
import MsgToast from "../../Components/Common/MsgToast";

//import images
import logoLight from "../../assets/images/logo-light.png";
import OrganizationTab from "../../Components/Common/OrganizationTab";
import ParticlesAuth from "./ParticlesAuth";
// import { createOrganisations } from "../../helpers/backend_helper";

const CreateOrganization = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState("");

  const { authUser, createOrgError, error, registrationError, success, message } = useSelector((state) => ({
    registrationError: state.Organization.registrationError,
    success: state.Organization.success,
    error: state.Organization.error,
    message: state.Organization.message,
    createOrgError: state.Organization.createOrgError,
    authUser: state.Account.user,
  }));

  useEffect(() => {
    try {
      //const authUser = getLoggedInUser();
      let planId = authUser?.user_details?.selected_plan;
      // TODO: validation plan_id on stripe using lookup-keys
      setSelectedPlanId(planId);
    } catch (err) {
      window.location.replace("/login");
    }
  }, []);

  useEffect(() => {
    dispatch(apiError(""));
  }, [dispatch]);

  const goToCheckout = useCallback(
    (selected_plan) => {
      setTimeout(() => {
        history.push(`/checkout/plan-create/${selected_plan}`);
      }, 1500);
    },
    [history]
  );

  const goToLogin = useCallback(() => {
    setTimeout(() => {
      history.replace(`/login`);
    }, 1500);
  }, [history]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetLoginFlag());
      dispatch(resetCreateOrginzationError());
    }, 3000);
  }, [dispatch, registrationError, success, error]);

  useEffect(() => {
    if (success) return goToLogin();
    if (registrationError) setErrorMessage(message);
  }, [success, registrationError, message, goToLogin]);

  document.title = "Sign UP | SimplyNP";

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="40" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">The better way to manage your NON-Profit</p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              {/* <OrganizationTab /> */}
              <Col md={8} lg={8} xl={8}>
                <OrganizationTab />
              </Col>
            </Row>

            {createOrgError ? <MsgToast msg={message} color="danger" icon="ri-error-warning-line" /> : null}
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default CreateOrganization;
