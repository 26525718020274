export const getCasedata = (stats) => [
    stats?.total,
    stats?.open,
    stats?.pending,
    stats?.closed,
  ];
  
  export const getCasesPercentage = (stats) => [
    stats?.percentage_total,
    stats?.percentage_open,
    stats?.percentage_pending,
    stats?.percentage_closed,
  ];
  