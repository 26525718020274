import {
  GET_CLIENTS_LIST,
  GET_CLIENT,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  ADD_NEW_CLIENT_SUCCESS,
  ADD_NEW_CLIENT_FAIL,
  ADD_NEW_CLIENT,
  UPDATE_CLIENT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  DELETE_CLIENT,
  DELETE_CLIENT_FAIL,
  DELETE_CLIENT_SUCCESS,
  CREATE_CLIENT_COMMENT,
  CREATE_CLIENT_COMMENT_SUCCESS,
  CREATE_CLIENT_COMMENT_FAIL,
  RESET_CLIENT_FLAG,
  GET_COMMENT_LIST
} from "./actionType";

export const ClientsApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const ClientsApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getClientsList = (params) => ({
  type: GET_CLIENTS_LIST,
  payload: { params },
});

export const getCommentList = (params) => ({
  type: GET_COMMENT_LIST,
  payload: { params },
});

export const getClient = (client) => ({
  type: GET_CLIENT,
  payload: client,
});

export const updateClient = (client) => ({
  type: UPDATE_CLIENT,
  payload: client,
});

export const updateClientSuccess = (client) => ({
  type: UPDATE_CLIENT_SUCCESS,
  payload: client,
});

export const updateClientFail = (client) => ({
  type: UPDATE_CLIENT_FAIL,
  payload: client,
});

export const addNewClient = (client) => ({
  type: ADD_NEW_CLIENT,
  payload: client,
});

export const addNewClientSuccess = (client) => ({
  type: ADD_NEW_CLIENT_SUCCESS,
  payload: client,
});

export const addNewClientFail = (error) => ({
  type: ADD_NEW_CLIENT_FAIL,
  payload: error,
});

export const deleteClient = (client) => ({
  type: DELETE_CLIENT,
  payload: client,
});

export const deleteClientSuccess = (client) => ({
  type: DELETE_CLIENT_SUCCESS,
  payload: client,
});

export const deleteClientFail = (error) => ({
  type: DELETE_CLIENT_FAIL,
  payload: error,
});

export const createClientComment = (comment) => ({
  type: CREATE_CLIENT_COMMENT,
  payload: comment,
});

export const createClientCommentSuccess = (client) => ({
  type: CREATE_CLIENT_COMMENT_SUCCESS,
  payload: client,
});

export const createClientCommentFail = (error) => ({
  type: CREATE_CLIENT_COMMENT_FAIL,
  payload: error,
});

export const resetClientFlag = () => ({
  type: RESET_CLIENT_FLAG,
});
