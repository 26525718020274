import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

import { Link, useHistory, withRouter } from "react-router-dom";

import { useParams } from "react-router-dom";

// import images
import logoLight from "../../assets/images/logo-light.png";
import Loader from "../../Components/Common/Loader";
import ParticlesAuth from "./ParticlesAuth";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { activateUser, activationError, resetActivateUserFlag, resetLoginFlag } from "../../store/actions";

const ActivateUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { uid, token, activation_key } = useParams();

  const { user, activateError, isAccountCompleted, message, isLoading, activationSuccess } = useSelector((state) => ({
    user: state.Account.user,
    isLoading: state.Account.loading,
    activateError: state.Account.activationError,
    activationSuccess: state.Account.activationSuccess,
    isAccountCompleted: state.Account.is_account_completed,
    message: state.Account.message,
  }));

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetActivateUserFlag());
      dispatch(resetLoginFlag());
    }, 3000);
  }, [dispatch, activationSuccess]);

  useEffect(() => {
    const data = { uid, token, activation_key };
    dispatch(activateUser(data));
  }, []);

  useEffect(() => {
    if (activationSuccess) {
      if (isAccountCompleted) {
        setTimeout(() => history.push("/login"), 1500);
      } else {
        setTimeout(() => history.push("/createorgnization"), 1500);
      }
      // //TODO: else { redirect to error page }
    }
  }, [dispatch, history, uid, token, activation_key, activationSuccess, user, message, isAccountCompleted]);

  document.title = "Activate Account | SimplyNP";

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <Link to="/" className="d-inline-block auth-logo">
                    <img src={logoLight} alt="" height="20" />
                  </Link>
                </div>
                <p className="mt-3 fs-15 fw-medium">The better way to manage your Non-Profit</p>
              </div>
            </Col>
          </Row>

          {message ? (
            <>
              {toast(message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              })}
              <ToastContainer autoClose={2000} limit={1} />
            </>
          ) : null}

          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    {!isLoading && message ? (
                      <h5 className="text-primary">{message}</h5>
                    ) : isLoading ? (
                      <Loader />
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

ActivateUser.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ActivateUser);
