import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_TEAMS_LIST, ADD_NEW_TEAM, DELETE_TEAM, UPDATE_TEAM, GET_TEAM } from "./actionType";

import {
  TeamsApiResponseSuccess,
  TeamsApiResponseError,
  addNewTeamSuccess,
  addTeamFail,
  getTeam as getTeamAction,
  getTeamsList as getTeamListAction,
  updateTeamSuccess,
  updateTeamFail,
  deleteTeamSuccess,
  deleteTeamFail,
} from "./action";

import {
  getTeams as getTeamsListApi,
  getTeam as getTeamApi,
  createTeam,
  updateTeam,
  deleteTeam,
} from "../../helpers/backend_helper";

function* getTeamsList({ payload: { params } }) {
  try {
    const response = yield call(getTeamsListApi, params);
    const { teams } = response;
    yield put(
      TeamsApiResponseSuccess(GET_TEAMS_LIST, {
        teams,
        page: { offset: response.teams.offset, count: response.teams_count },
      })
    );
  } catch (error) {
    yield put(TeamsApiResponseError(GET_TEAMS_LIST, error));
  }
}

function* getTeamWorker({ payload: team }) {
  try {
    const response = yield call(getTeamApi, team);
    yield put(TeamsApiResponseSuccess(GET_TEAM, response));
  } catch (error) {
    yield put(TeamsApiResponseError(GET_TEAM, error));
  }
}

function* addNewTeamWorker({ payload: team }) {
  try {
    const response = yield call(createTeam, team);
    yield put(addNewTeamSuccess(response));
    yield put(getTeamListAction());
  } catch (error) {
    yield put(TeamsApiResponseError(GET_TEAMS_LIST, error));
  }
}

function* updateTeamWorker({ payload: team }) {
  // team payload here is a FormData Object - hence "team.get("id")
  try {
    const response = yield call(updateTeam, team.id, team);
    yield put(updateTeamSuccess(response));
    yield put(getTeamListAction());
  } catch (error) {
    yield put(updateTeamFail(error));
  }
}

function* deleteTeamWorker({ payload: team }) {
  try {
    const response = yield call(deleteTeam, team.id);
    yield put(deleteTeamSuccess({ team, ...response }));
    yield put(getTeamListAction());
  } catch (error) {
    yield put(deleteTeamFail(error));
  }
}

export function* watchGetTeam() {
  yield takeEvery(GET_TEAM, getTeamWorker);
}

export function* watchGetTeamsList() {
  yield takeEvery(GET_TEAMS_LIST, getTeamsList);
}

export function* watchAddNewTeam() {
  yield takeEvery(ADD_NEW_TEAM, addNewTeamWorker);
}

export function* watchUpdateTeam() {
  yield takeEvery(UPDATE_TEAM, updateTeamWorker);
}

export function* watchDeleteTeam() {
  yield takeEvery(DELETE_TEAM, deleteTeamWorker);
}

function* TeamsSaga() {
  yield all([
    fork(watchGetTeam),
    fork(watchGetTeamsList),
    fork(watchAddNewTeam),
    fork(watchUpdateTeam),
    fork(watchDeleteTeam),
  ]);
}

export default TeamsSaga;
