import { takeEvery, put, call } from "redux-saga/effects";

// Calender Redux States
import {
  ADD_NEW_EVENT,
  DELETE_EVENT,
  GET_CATEGORIES,
  GET_EVENTS,
  UPDATE_EVENT,
  GET_UPCOMMINGEVENT,
  GET_EVENTS_IN_DATE_RANGE,
} from "./actionTypes";

import {
  getEvents as getEventsList,
  getEventsSuccess,
  getEventsFail,
  addEventFail,
  addEventSuccess,
  updateEventSuccess,
  updateEventFail,
  deleteEventSuccess,
  deleteEventFail,
  getCategoriesSuccess,
  getCategoriesFail,
  getUpCommingEventSuccess,
  getUpCommingEventFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getEvents,
  getEventsIn,
  createEvents,
  updateEvent,
  deleteEvent,
  getCategories,
  getUpCommingEvent,
} from "../../helpers/backend_helper";

function* fetchEvents() {
  try {
    const response = yield call(getEvents);
    yield put(getEventsSuccess(response));
  } catch (error) {
    yield put(getEventsFail(error));
  }
}

function* fetchEventsInRange({ payload: { start, end } }) {
  try {
    const response = yield call(getEventsIn, start, end);
    yield put(getEventsSuccess(response));
  } catch (error) {
    yield put(getEventsFail(error));
  }
}

function* onAddNewEvent({ payload: event }) {
  try {
    const response = yield call(createEvents, event);
    if (response.status === 200) {
      return yield put(addEventSuccess(event));
    }
    if (response.status === 400) {
      return yield put(addEventFail(response));
    }
    // yield put(getEventsList())
  } catch (error) {
    yield put(addEventFail(error));
  }
}

function* onUpdateEvent({ payload: event }) {
  try {
    const response = yield call(updateEvent, event.id, event);
    if (response.status === 200) {
      yield put(updateEventSuccess(event));
    }
    if (response.status === 400) {
      yield put(updateEventFail(response));
    }
    yield put(getEventsList());
  } catch (error) {
    yield put(updateEventFail(error));
  }
}

function* onDeleteEvent({ payload: event }) {
  try {
    const response = yield call(deleteEvent, event.id);
    yield put(deleteEventSuccess(event));
    yield put(getEventsList());
  } catch (error) {
    yield put(deleteEventFail(error));
  }
}

function* onGetCategories() {
  try {
    const response = yield call(getCategories);
    yield put(getCategoriesSuccess(response));
  } catch (error) {
    yield put(getCategoriesFail(error));
  }
}

function* onGetUpcommingEvents() {
  try {
    const response = yield call(getUpCommingEvent);
    yield put(getUpCommingEventSuccess(response));
  } catch (error) {
    yield put(getUpCommingEventFail(error));
  }
}

function* calendarSaga() {
  yield takeEvery(GET_EVENTS, fetchEvents);
  yield takeEvery(GET_EVENTS_IN_DATE_RANGE, fetchEventsInRange);
  yield takeEvery(ADD_NEW_EVENT, onAddNewEvent);
  yield takeEvery(UPDATE_EVENT, onUpdateEvent);
  yield takeEvery(DELETE_EVENT, onDeleteEvent);
  yield takeEvery(GET_CATEGORIES, onGetCategories);
  yield takeEvery(GET_UPCOMMINGEVENT, onGetUpcommingEvents);
}

export default calendarSaga;
