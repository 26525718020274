import React from "react";
import { Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import CasesData from "./CasesData";

const ListView = () => {
  document.title = "Cases | SimplyNP - Case Management";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Cases List" pageTitle="Cases" />
          <CasesData />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ListView;
