import {
  IS_LOADED,
  IS_LOADING,
  GET_MEMBER_BY_ID,
  GET_MEMBERS,
  GET_MEMBERSHIPS,
  GET_MEMBERSHIP_TYPES,
  GET_MEMBERSHIP_BENEFITS,
  GET_MEMBERSHIP_LEVELS,
  GET_MEMBERSHIP_SITE_CONFIG,
  UPDATE_MEMBERSHIP_SITE_CONFIG_FAIL,
  UPDATE_MEMBERSHIP_SITE_CONFIG_SUCCESS,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_MEMBERSHIPS_SUCCESS,
  ADD_MEMBERSHIPS_FAIL,
  UPDATE_MEMBERSHIPS_SUCCESS,
  UPDATE_MEMBERSHIPS_FAIL,
  RENEW_MEMBERSHIPS_SUCCESS,
  RENEW_MEMBERSHIPS_FAIL,
  DELETE_MEMBERSHIPS_SUCCESS,
  DELETE_MEMBERSHIPS_FAIL,
  ADD_MEMBER_SUCCESS,
  ADD_MEMBER_FAIL,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAIL,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAIL,
  ADD_MEMBERSHIP_LEVEL_SUCCESS,
  ADD_MEMBERSHIP_LEVEL_FAIL,
  UPDATE_MEMBERSHIP_LEVEL_SUCCESS,
  UPDATE_MEMBERSHIP_LEVEL_FAIL,
  DELETE_MEMBERSHIP_LEVEL_SUCCESS,
  DELETE_MEMBERSHIP_LEVEL_FAIL,
  ADD_MEMBERSHIP_BENEFIT_SUCCESS,
  ADD_MEMBERSHIP_BENEFIT_FAIL,
  UPDATE_MEMBERSHIP_BENEFIT_SUCCESS,
  UPDATE_MEMBERSHIP_BENEFIT_FAIL,
  DELETE_MEMBERSHIP_BENEFIT_SUCCESS,
  DELETE_MEMBERSHIP_BENEFIT_FAIL,
  ADD_MEMBERSHIP_TYPE_SUCCESS,
  ADD_MEMBERSHIP_TYPE_FAIL,
  UPDATE_MEMBERSHIP_TYPE_SUCCESS,
  UPDATE_MEMBERSHIP_TYPE_FAIL,
  DELETE_MEMBERSHIP_TYPE_SUCCESS,
  DELETE_MEMBERSHIP_TYPE_FAIL,
  RESET_MEMBERSHIP_FLAG,
} from "./actionType";

const INIT_STATE = {
  error: null,
};

const Membership = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_MEMBER_BY_ID:
          return {
            ...state,
            member: action.payload.data,
            isMemberSuccess: true,
            isMemberCreated: false,
          };
        case GET_MEMBERS:
          return {
            ...state,
            members: action.payload.data.members_obj_list,
            page: { offset: action.payload.data.offset, count: action.payload.data.members_count },
            isMemberCreated: false,
            isMemberSuccess: true,
          };
        case GET_MEMBERSHIPS:
          return {
            ...state,
            memberships: action.payload.data.membership_obj_list,
            page: { count: action.payload.data?.memberships_count, offset: action.payload.data?.offest },
            stats: action.payload.data?.stats,
            isMembershipCreated: false,
            isMembershipSuccess: true,
          };
        case GET_MEMBERSHIP_LEVELS:
          return {
            ...state,
            membership_levels: action.payload.data.levels_obj_list,
            page: { count: action.payload.data.levels_count, offset: action.payload.data.offset },
            isBenefitCreated: false,
            isBenefitSuccess: true,
          };
        case GET_MEMBERSHIP_BENEFITS:
          return {
            ...state,
            membership_benefits: action.payload.data.member_benefits_obj_list,
            page: { count: action.payload.data.member_benefits_count, offset: action.payload.data.offset },
            isBenefitCreated: false,
            isBenefitSuccess: true,
          };
        case GET_MEMBERSHIP_TYPES:
          return {
            ...state,
            membership_types: action.payload.data.membership_type_obj_list,
            page: { offset: action.payload.data.offset, count: action.payload.data.membership_type_count },
            isMembershipTypeCreated: false,
            isMembershipTypeSuccess: true,
          };
        case GET_MEMBERSHIP_SITE_CONFIG:
          return {
            ...state,
            siteconfig: action.payload.data,
            isSiteConfigUpdate: false,
            isSiteConfigSuccess: true,
          };
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_MEMBER_BY_ID:
          return {
            ...state,
            error: action.payload.error,
            isMemberSuccess: false,
            isMemberCreated: false,
          };
        case GET_MEMBERS:
          return {
            ...state,
            error: action.payload.error,
            isMemberCreated: false,
            isMemberSuccess: false,
          };
        case GET_MEMBERSHIPS:
          return {
            ...state,
            error: action.payload.error,
            isMembershipCreated: false,
            isMembershipSuccess: false,
          };
        case GET_MEMBERSHIP_LEVELS:
          return {
            ...state,
            error: action.payload.error,
            isLevelCreated: false,
            isLevelSuccess: false,
          };
        case GET_MEMBERSHIP_BENEFITS:
          return {
            ...state,
            error: action.payload.error,
            isBenefitCreated: false,
            isBenefitSuccess: false,
          };
        case GET_MEMBERSHIP_TYPES:
          return {
            ...state,
            error: action.payload.error,
            isMembershipTypeCreated: false,
            isMembershipTypeSuccess: false,
          };
        case GET_MEMBERSHIP_SITE_CONFIG:
          return {
            ...state,
            error: action.payload.error,
            isSiteConfigCreated: false,
            isSiteConfigSuccess: false,
          };
        default:
          return { ...state };
      }

    case ADD_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        isMembershipCreated: true,
        // memberships: [...state.memberships, action.payload.data],
        membership: action.payload.membership_obj,
        isMembershipAdd: true,
        isMembershipAddFail: false,
      };

    case ADD_MEMBERSHIPS_FAIL:
      return {
        ...state,
        error: action.payload,
        isMembershipAdd: false,
        isMembershipAddFail: true,
      };

    case UPDATE_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        // memberships: state.memberships.map((membership) =>
        //   membership.id.toString() === action.payload.data.id.toString()
        //     ? { ...membership, ...action.payload.data }
        //     : membership
        // ),
        isMembershipUpdate: true,
        isMembershipUpdateFail: false,
      };

    case RENEW_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        membershipObj: action.payload.data,
        isMembershipRenew: true,
        isMembershipRenewFail: false,
      };

    case UPDATE_MEMBERSHIPS_FAIL:
      return {
        ...state,
        error: action.payload,
        isMembershipUpdate: false,
        isMembershipUpdateFail: true,
      };

    case RENEW_MEMBERSHIPS_FAIL:
      return {
        ...state,
        error: action.payload,
        isMembershipRenew: false,
        isMembershipRenewFail: true,
      };

    case DELETE_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        // memberships: state.memberships.filter(
        //   (membership) => membership.id.toString() !== action.payload.memberships.toString()
        // ),
        isMembershipDelete: true,
        isMembershipDeleteFail: false,
      };

    case DELETE_MEMBERSHIPS_FAIL:
      return {
        ...state,
        error: action.payload,
        isMembershipDelete: false,
        isMembershipDeleteFail: true,
      };

    case ADD_MEMBER_SUCCESS:
      return {
        ...state,
        isMemberCreated: true,
        isMemberAdd: true,
        isMemberAddFail: false,
      };

    case ADD_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        isMemberAdd: false,
        isMemberAddFail: true,
      };

    case UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        // members: state.members.map((member) =>
        //   member.id.toString() === action.payload.member.id.toString() ? { ...member, ...action.payload.data } : member
        // ),
        isMemberUpdate: true,
        isMemberUpdateFail: false,
      };

    case UPDATE_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        isMemberUpdate: false,
        isMemberUpdateFail: true,
      };

    case DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        members: state.members.filter((member) => member.id.toString() !== action.payload.member.toString()),
        isMemberDelete: true,
        isMemberDeleteFail: false,
      };

    case DELETE_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload,
        isMemberDelete: false,
        isMemberDeleteFail: true,
      };
    ///
    case ADD_MEMBERSHIP_BENEFIT_SUCCESS:
      return {
        ...state,
        isBenefitCreated: true,
        isBenefitsAdd: true,
        isBenefitsAddFail: false,
      };

    case ADD_MEMBERSHIP_BENEFIT_FAIL:
      return {
        ...state,
        error: action.payload,
        isBenefitsAdd: false,
        isBenefitsAddFail: true,
      };

    case UPDATE_MEMBERSHIP_BENEFIT_SUCCESS:
      return {
        ...state,
        isBenefitsUpdate: true,
        isBenefitsUpdateFail: false,
      };

    case UPDATE_MEMBERSHIP_BENEFIT_FAIL:
      return {
        ...state,
        error: action.payload,
        isBenefitsUpdate: false,
        isBenefitsUpdateFail: true,
      };

    case DELETE_MEMBERSHIP_BENEFIT_SUCCESS:
      return {
        ...state,
        // membership_benefits: state.membership_benefits.filter(
        //   (membership_benefit) => membership_benefit.id.toString() !== action.payload.id.toString()
        // ),
        isBenefitsDelete: true,
        isBenefitsDeleteFail: false,
      };

    case DELETE_MEMBERSHIP_BENEFIT_FAIL:
      return {
        ...state,
        error: action.payload,
        isBenefitsDelete: false,
        isBenefitsDeleteFail: true,
      };
    case ADD_MEMBERSHIP_LEVEL_SUCCESS:
      return {
        ...state,
        isLevelCreated: true,
        isLevelsAdd: true,
        isLevelsAddFail: false,
      };

    case ADD_MEMBERSHIP_LEVEL_FAIL:
      return {
        ...state,
        error: action.payload,
        isLevelsAdd: false,
        isLevelsAddFail: true,
      };

    case UPDATE_MEMBERSHIP_LEVEL_SUCCESS:
      return {
        ...state,
        // membership_benefit: state.membership_benefits.map((membership_benefit) =>
        //   membership_benefit.id.toString() === action.payload.id.toString()
        //     ? { ...membership_benefit, ...action.payload.data }
        //     : membership_benefit
        // ),
        isLevelsUpdate: true,
        isLevelsUpdateFail: false,
      };

    case UPDATE_MEMBERSHIP_LEVEL_FAIL:
      return {
        ...state,
        error: action.payload,
        isLevelsUpdate: false,
        isLevelsUpdateFail: true,
      };

    case UPDATE_MEMBERSHIP_SITE_CONFIG_SUCCESS:
      return {
        ...state,
        siteconfig: action.payload.data,
        isSiteConfigUpdateSuccess: true,
        isSiteConfigUpdateFail: false,
      };

    case UPDATE_MEMBERSHIP_SITE_CONFIG_FAIL:
      return {
        ...state,
        error: action.payload,
        isSiteConfigUpdateSucess: false,
        isSiteConfigUpdateFail: true,
      };

    case DELETE_MEMBERSHIP_LEVEL_SUCCESS:
      return {
        ...state,
        // membership_benefits: state.membership_benefits.filter(
        //   (membership_benefit) => membership_benefit.id.toString() !== action.payload.id.toString()
        // ),
        isLevelsDelete: true,
        isLevelsDeleteFail: false,
      };

    case DELETE_MEMBERSHIP_LEVEL_FAIL:
      return {
        ...state,
        error: action.payload,
        isLevelsDelete: false,
        isLevelsDeleteFail: true,
      };

    //
    case ADD_MEMBERSHIP_TYPE_SUCCESS:
      return {
        ...state,
        isMembershipTypeCreated: true,
        isMembershipTypeAdd: true,
        isMembershipTypeAddFail: false,
      };

    case ADD_MEMBERSHIP_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
        isMembershipTypeAdd: false,
        isMembershipTypeAddFail: true,
      };

    case UPDATE_MEMBERSHIP_TYPE_SUCCESS:
      return {
        ...state,
        // membership_type: state.membership_types.map((membership_type) =>
        //   membership_type.id.toString() === action.payload.membership_types.id.toString()
        //     ? { ...membership_type, ...action.payload.data }
        //     : membership_type
        // ),
        isMembershipTypeUpdate: true,
        isMembershipTypeUpdateFail: false,
      };

    case UPDATE_MEMBERSHIP_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
        isMembershipTypeUpdate: false,
        isMembershipTypeUpdateFail: true,
      };

    case DELETE_MEMBERSHIP_TYPE_SUCCESS:
      return {
        ...state,
        // membership_types: state.membership_types.filter(
        //   (membership_type) => membership_type.id.toString() !== action.payload.membership_types.id.toString()
        // ),
        isMembershipTypeDelete: true,
        isMembershipTypeDeleteFail: false,
      };

    case DELETE_MEMBERSHIP_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
        isMembershipTypeDelete: false,
        isMembershipTypeDeleteFail: true,
      };
    case IS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case IS_LOADED:
      return {
        ...state,
        loading: false,
      };
    case RESET_MEMBERSHIP_FLAG:
      return {
        ...state,
        loading: false,
        isLevelsAdd: false,
        isLevelsAddFail: false,
        isLevelsDelete: false,
        isLevelsDeleteFail: false,
        isLevelsUpdate: false,
        isLevelsUpdateFail: false,
        isBenefitsAdd: false,
        isBenefitsAddFail: false,
        isBenefitsDelete: false,
        isBenefitsDeleteFail: false,
        isBenefitsUpdate: false,
        isBenefitsUpdateFail: false,
        isMemberAdd: false,
        isMemberAddFail: false,
        isMemberDelete: false,
        isMemberDeleteFail: false,
        isMemberUpdate: false,
        isMemberUpdateFail: false,
        isMembershipAdd: false,
        isMembershipAddFail: false,
        isMembershipDelete: false,
        isMembershipDeleteFail: false,
        isMembershipRenew: false,
        isMembershipRenewFail: false,
        isMembershipUpdate: false,
        isMembershipUpdateFail: false,
        isMembershipTypeCreated: false,
        isMembershipTypeAdd: false,
        isMembershipTypeAddFail: false,
        isMembershipTypeDelete: false,
        isMembershipTypeDeleteFail: false,
        isMembershipTypeUpdate: false,
        isMembershipTypeUpdateFail: false,
        isSiteConfigUpdate: false,
        isSiteConfigSuccess: false,
        isSiteConfigUpdateSuccess: false,
        isSiteConfigUpdateFail: false,
        error: null,
      };

    default:
      return { ...state };
  }
};

export default Membership;
