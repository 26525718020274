import React from "react";
import ReactApexChart from "react-apexcharts";

const ProjectsOverviewCharts = ({ monthlyDonors, monthlyDonationAmount, activeDonorsPerMonth }) => {
  /*
    nd, rv, ad represent monthly donor count, 
    monthly donation, and active donors per month as retrieved from 
    the database
  */
  const nd = monthlyDonors;
  const rv = monthlyDonationAmount;
  const ad = activeDonorsPerMonth;

  const linechartcustomerColors = ["#405189", "#f7b84b", "#0ab39c"];
  const series = [
    {
      name: "No. of Donations",
      type: "bar",
      data: [
        (nd && nd[1]) || 0,
        (nd && nd[2]) || 0,
        (nd && nd[3]) || 0,
        (nd && nd[4]) || 0,
        (nd && nd[5]) || 0,
        (nd && nd[6]) || 0,
        (nd && nd[7]) || 0,
        (nd && nd[8]) || 0,
        (nd && nd[9]) || 0,
        (nd && nd[10]) || 0,
        (nd && nd[11]) || 0,
        (nd && nd[12]) || 0,
      ],
      // yAxisIndex: 1
    },
    {
      name: "Total Revenue",
      type: "area",
      data: [
        (rv && rv[1] / 1000) || 0,
        (rv && rv[2] / 1000) || 0,
        (rv && rv[3] / 1000) || 0,
        (rv && rv[4] / 1000) || 0,
        (rv && rv[5] / 1000) || 0,
        (rv && rv[6] / 1000) || 0,
        (rv && rv[7] / 1000) || 0,
        (rv && rv[8] / 1000) || 0,
        (rv && rv[9] / 1000) || 0,
        (rv && rv[10] / 1000) || 0,
        (rv && rv[11] / 1000) || 0,
        (rv && rv[12] / 1000) || 0,
      ],
      // yAxisIndex: 0
    },
    {
      name: "Active Donors",
      type: "bar",
      data: [
        (ad && ad[1]) || 0,
        (ad && ad[2]) || 0,
        (ad && ad[3]) || 0,
        (ad && ad[4]) || 0,
        (ad && ad[5]) || 0,
        (ad && ad[6]) || 0,
        (ad && ad[7]) || 0,
        (ad && ad[8]) || 0,
        (ad && ad[9]) || 0,
        (ad && ad[10]) || 0,
        (ad && ad[11]) || 0,
        (ad && ad[12]) || 0,
      ],
      // yAxisIndex: 1
    },
  ];

  const options = {
    chart: {
      height: "350",
      type: "line",
      toolbar: {
        show: true,
      },
    },
    stroke: {
      curve: "smooth",
      dashArray: [0, 5, 0],
      width: [0, 1, 0],
    },
    fill: {
      opacity: [1, 0.1, 1],
    },
    markers: {
      size: [0, 4, 0],
      strokeWidth: 2,
      hover: {
        size: 4,
      },
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: [
      {
        seriesName: "No. of Donations",
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: true,
          color: "#008FFB",
        },
        labels: {
          style: {
            colors: "#008FFB",
          },
        },
        title: {
          text: "Number of Donors",
          style: {
            color: "#008FFB",
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      {
        seriesName: "Total Revenue",
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#FEB019",
        },
        labels: {
          style: {
            colors: "#FEB019",
          },
        },
        title: {
          text: "Total Revenue (thousand)",
          style: {
            color: "#FEB019",
          },
        },
      },
      {
        seriesName: "Active Donors",
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#00E396",
        },
        labels: {
          style: {
            colors: "#00E396",
          },
        },
        title: {
          text: "Active Donors",
          style: {
            color: "#00E396",
          },
        },
      },
    ],
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 0,
        right: -2,
        bottom: 15,
        left: 10,
      },
    },
    legend: {
      show: true,
      horizontalAlign: "center",
      offsetX: 0,
      offsetY: -5,
      markers: {
        width: 9,
        height: 9,
        radius: 6,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
        barHeight: "70%",
      },
    },
    colors: linechartcustomerColors,
    tooltip: {
      shared: true,
      y: [
        {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0);
            }
            return y;
          },
        },
        {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return "$" + y.toFixed(1) + "k";
            }
            return y;
          },
        },
        {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0);
            }
            return y;
          },
        },
      ],
    },
  };
  return (
    <React.Fragment>
      <ReactApexChart options={options} series={series} type="line" height="374" className="apex-charts" />
    </React.Fragment>
  );
};

const TeamMembersCharts = ({ seriesData, chartsColor }) => {
  // const series=  isApexSeriesData.series,
  const series = [seriesData];

  const options = {
    chart: {
      type: "radialBar",
      width: 36,
      height: 36,
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "50%",
        },
        track: {
          margin: 1,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
    colors: [chartsColor],
  };
  return (
    <React.Fragment>
      <ReactApexChart options={options} series={[...series]} type="radialBar" height="36" className="apex-charts" />
    </React.Fragment>
  );
};

const PrjectsStatusCharts = () => {
  const donutchartProjectsStatusColors = ["#0ab39c", "#405189", "#f7b84b", "#f06548"];
  const series = [125, 42, 58, 89];
  var options = {
    labels: ["Completed", "In Progress", "Yet to Start", "Cancelled"],
    chart: {
      type: "donut",
      height: 230,
    },
    plotOptions: {
      pie: {
        size: 100,
        offsetX: 0,
        offsetY: 0,
        donut: {
          size: "90%",
          labels: {
            show: false,
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      lineCap: "round",
      width: 0,
    },
    colors: donutchartProjectsStatusColors,
  };
  return (
    <React.Fragment>
      <ReactApexChart options={options} series={series} type="donut" height="230" className="apex-charts" />
    </React.Fragment>
  );
};

export { ProjectsOverviewCharts, TeamMembersCharts, PrjectsStatusCharts };
