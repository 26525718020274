import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { casesWidgets } from "../../../common/data";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Row,
  TabContent,
  Table,
  TabPane,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from "reactstrap";
import classnames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import axios from "axios";
import { api } from "../../../config";
import { Priority } from "../../cases/ListView/CaseCol";
import { Status } from "../../cases/ListView/CaseCol";
import { Status as NoteStatus } from "../../notes/ListView/NoteCol";
import { useParams } from "react-router-dom";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { deleteCase, deleteNote } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const document = [
  {
    id: 1,
    icon: "ri-file-zip-fill",
    iconBackgroundClass: "primary",
    fileName: "Artboard-documents.zip",
    fileType: "Zip File",
    fileSize: "4.57 MB",
    updatedDate: "12 Dec 2021",
  },
  {
    id: 2,
    icon: "ri-file-pdf-fill",
    iconBackgroundClass: "danger",
    fileName: "Bank Management System",
    fileType: "PDF File",
    fileSize: "8.89 MB",
    updatedDate: "24 Nov 2021",
  },
  {
    id: 3,
    icon: "ri-video-line",
    iconBackgroundClass: "secondary",
    fileName: "Tour-video.mp4",
    fileType: "MP4 File",
    fileSize: "14.62 MB",
    updatedDate: "19 Nov 2021",
  },
  {
    id: 4,
    icon: "ri-file-excel-fill",
    iconBackgroundClass: "secondary",
    fileName: "Tour-video.mp4",
    fileType: "XSL File",
    fileSize: "2.38 KB",
    updatedDate: "14 Nov 2021",
  },
  {
    id: 5,
    icon: "ri-folder-line",
    iconBackgroundClass: "info",
    fileName: "Project Screenshots Collection",
    fileType: "Folder File",
    fileSize: "87.24 MB",
    updatedDate: "08 Nov 2021",
  },
  {
    id: 6,
    icon: "ri-image-2-fill",
    iconBackgroundClass: "danger",
    fileName: "Velzon-logo.png",
    fileType: "PNG File",
    fileSize: "879 KB",
    updatedDate: "02 Nov 2021",
  },
];

const DocumentsTab = ({ stats }) => {
  const [cases, setCases] = useState([]);

  return (
    <Card>
      <CardBody>
        <div className="d-flex align-items-center mb-4">
          <h5 className="card-title flex-grow-1 mb-0">Documents</h5>
          <div className="flex-shrink-0">
            <Input className="form-control d-none" type="file" id="formFile" />
            <Label htmlFor="formFile" className="btn btn-danger">
              <i className="ri-upload-2-fill me-1 align-bottom"></i> Upload File
            </Label>
          </div>
        </div>
        <Row>
          <Col lg={12}>
            <div className="table-responsive">
              <Table className="table-borderless align-middle mb-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col">File Name</th>
                    <th scope="col">Type</th>
                    <th scope="col">Size</th>
                    <th scope="col">Upload Date</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {(document || []).map((item, key) => (
                    <tr key={key}>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="avatar-sm">
                            <div
                              className={`avatar-title bg-soft-${item.iconBackgroundClass} text-${item.iconBackgroundClass} rounded fs-20`}
                            >
                              <i className={item.icon}></i>
                            </div>
                          </div>
                          <div className="ms-3 flex-grow-1">
                            <h6 className="fs-15 mb-0">
                              <Link to="#">{item.fileName}</Link>
                            </h6>
                          </div>
                        </div>
                      </td>
                      <td>{item.fileType}</td>
                      <td>{item.fileSize}</td>
                      <td>{item.updatedDate}</td>
                      <td>
                        <UncontrolledDropdown direction="start">
                          <DropdownToggle
                            tag="a"
                            className="btn btn-light btn-icon"
                            id="dropdownMenuLink15"
                            role="button"
                          >
                            <i className="ri-equalizer-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem>
                              <i className="ri-eye-fill me-2 align-middle text-muted" />
                              View
                            </DropdownItem>
                            <DropdownItem>
                              <i className="ri-download-2-fill me-2 align-middle text-muted" />
                              Download
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem>
                              <i className="ri-delete-bin-5-line me-2 align-middle text-muted" />
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div className="text-center mt-3">
              <Link to="#" className="text-success ">
                <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                Load more{" "}
              </Link>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default DocumentsTab;
