import {
  GET_SERVICE,
  GET_SERVICES_LIST,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  UPDATE_SERVICE,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_FAIL,
  DELETE_SERVICE,
  DELETE_SERVICE_SUCCESS,
  DELETE_SERVICE_FAIL,
  ADD_NEW_SERVICE,
  ADD_NEW_SERVICE_SUCCESS,
  ADD_NEW_SERVICE_FAIL,
  RESET_SERVICE_FLAG,
} from "./actionType";

const INIT_STATE = {
  servicesList: null,
};

const Services = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SERVICES_LIST:
          return {
            ...state,
            servicesList: action.payload.data.services,
            page: action.payload.data.page,
            isServiceSuccess: true,
          };
        case GET_SERVICE:
          return {
            ...state,
            service: action.payload.data,
            isServiceSuccess: true,
            isServiceCreated: false,
          };
        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_SERVICES_LIST:
        case GET_SERVICE:
          return {
            ...state,
            error: action.payload.error,
            isServiceCreated: false,
            isServiceSuccess: false,
          };
        default:
          return { ...state };
      }

    case ADD_NEW_SERVICE_SUCCESS:
      return {
        ...state,
        error: false,
        isServiceCreated: true,
        isServiceAdd: true,
        isCaseAddFail: false,
      };
    case ADD_NEW_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        isServiceAdd: false,
        isServiceAddFail: true,
      };
    case UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        isServiceUpdate: true,
        isServiceUpdateFail: false,
        error: false,
      };
    case UPDATE_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        isServiceUpdate: false,
        isServiceUpdateFail: true,
      };
    case DELETE_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        isServiceDelete: false,
        isServiceDeleteFail: true,
      };
    case DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        // servicesList: state.servicesList.filter((service) => service.id.toString() !== action.payload.team.toString()),
        isServiceDelete: true,
        isServiceDeleteFail: false,
      };
    case RESET_SERVICE_FLAG:
      return {
        ...state,
        isServiceAdd: false,
        isServiceAddFail: false,
        isServiceCreated: false,
        isServiceDelete: false,
        isServiceDeleteFail: false,
        isServiceUpdate: false,
        isServiceUpdateFail: false,
        errors: false,
      };
    default:
      return { ...state };
  }
};

export default Services;
