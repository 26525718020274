import React, { useEffect, useMemo, useState, useCallback } from "react";

import { Input, Card, CardBody, Col, Container, Row, CardHeader } from "reactstrap";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";

//Import actions
import { getDonationOrgsByPattern } from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

// Formik

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OrgTimePattern = () => {
  const dispatch = useDispatch();
  const [currentPattern, setCurrentPattern] = useState("weekly");

  const { count, weekly, loading, error, page } = useSelector((state) => ({
    casestStatsList: state.Statistics.casesPriorityStats,
    count: state.Statistics.count,
    weekly: state.Statistics.weekly,

    loading: state.Statistics.loading,
  }));

  useEffect(() => {
    dispatch(getDonationOrgsByPattern({ type: currentPattern }));
  }, []);

  const servicesColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
      },

      {
        Header: "date",
        accessor: "date",
        filterable: false,
        Cell: (cellProps) => {
          let data = cellProps.cell.value;

          if (!data) return "--";

          return data?.split("T")[0];
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        filterable: false,
      },
    ],
    []
  );

  const handler = useCallback(
    debounce((value) => {
      dispatch(getDonationOrgsByPattern({ search: value, type: currentPattern }));
    }, 500),
    []
  );

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    handler(value);
  };

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(getDonationOrgsByPattern({ offset, limit, type: currentPattern }));
    },
    [dispatch]
  );

  const fetchPattern = (type) => {
    if (!type) return;
    setCurrentPattern(type);
    dispatch(getDonationOrgsByPattern({ type }));
  };

  document.title = "Cases stats - statistics | SimplyNP";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Organization stats" pageTitle="Organization stats" />
          <Row>
            <Col>
              <Card>
                <CardHeader className="border-0 align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">pattern - {currentPattern}</h4>
                  <div className="d-flex gap-1">
                    <button
                      onClick={() => fetchPattern("weekly")}
                      type="button"
                      className="btn btn-soft-secondary btn-sm"
                    >
                      weekly
                    </button>
                    <button
                      onClick={() => fetchPattern("monthly")}
                      type="button"
                      className="btn btn-soft-secondary btn-sm"
                    >
                      monthly
                    </button>
                    <button
                      onClick={() => fetchPattern("quarterlly")}
                      type="button"
                      className="btn btn-soft-secondary btn-sm"
                    >
                      quarterlly
                    </button>
                    <button
                      onClick={() => fetchPattern("yearly")}
                      type="button"
                      className="btn btn-soft-secondary btn-sm"
                    >
                      yearly
                    </button>
                  </div>
                </CardHeader>
                <CardBody>
                  <CardHeader>
                    <Row className="g-3">
                      <Col md={4}>
                        <div className="search-box">
                          <Input
                            onChange={(e) => {
                              handleFieldSearch(e);
                            }}
                            type="text"
                            className="form-control search"
                            placeholder="Search for organization..."
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>

                  <div className="text-center py-5">
                    <TableContainer
                      columns={servicesColumns}
                      data={weekly || []}
                      isGlobalFilter={false}
                      isAddUserList={false}
                      customFetchData={fetchData}
                      customTotalSize={count}
                      customPageSize={10}
                      className="custom-header-css"
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light"
                    />
                  </div>
                </CardBody>
              </Card>

              <Card>
                <ToastContainer limit={1} closeButton={false} />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OrgTimePattern;
