// let checkIfFieldsIsValid = (fields) => {
//   let fieldKeys = Object.keys(fields);
//   let errOject = {};
//   let fieldIsValid = true;

//   for (let v of fieldKeys) {
//     let value = fields[v];

//     if (!value) {
//       fieldIsValid = false;
//       errOject[v] = v.replaceAll("_", " ") + " field is required";
//     }
//   }

//   return { fieldIsValid, errOject };
// };

export const checkIfFieldsIsValid = (fields) => {
  let fieldKeys = Object.keys(fields);
  let errOject = {};
  let fieldIsValid = true;

  for (let v of fieldKeys) {
    let value = fields[v];

    if (!value) {
      fieldIsValid = false;
      errOject[v] = v.replaceAll("_", " ") + " field is required";
    }
  }

  return { fieldIsValid, errOject };
};
