import React from "react";
import { Container } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import CreateOrgForm from "./FormComponents/CreateOrgForm";

const UpdateOrg = () => {
  document.title = "Update Organization | Velzon - React Admin & Dashboard Template";

  return (
    // <div className="page-content">
    <CreateOrgForm updateOrg title="Update Org" />
    // </div>
  );
};

export default UpdateOrg;
