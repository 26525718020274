import { call, put, takeEvery } from "redux-saga/effects";

//Account Redux states
import {
  postActivateUser,
  postLogin,
  postLogout,
  postRefreshToken,
  postRegister,
} from "../../../helpers/backend_helper";

import {
  activateUserFailed,
  activateUserSuccessful,
  activationError,
  apiError,
  loginFailed,
  loginSuccess,
  logoutUserSuccess,
  refreshTokenFailed,
  refreshTokenSuccess,
  registerUserFailed,
  registerUserSuccessful,
  registrationError,
  profileSuccess,
} from "../../actions";
import { ACTIVATE_USER, LOGIN_USER, LOGOUT_USER, REFRESH_TOKEN, REGISTER_USER } from "./actionTypes";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, user);

    if (response.status === 200) {
      sessionStorage.setItem("__logged_in_user_data__", response.data.access_token);
      sessionStorage.setItem("__subscription_status__", response.data.user.org?.subscription?.status);
      yield put(loginSuccess(response));
      yield put(profileSuccess(response));
    } else {
      yield put(loginFailed(response));
    }
  } catch (error) {
    yield put(loginFailed(error));
  }
}

function* logoutUser() {
  try {
    sessionStorage.removeItem("__logged_in_user_data__");
    sessionStorage.removeItem("__subscription_status__");

    const r = call(postLogout);
    // TODO: dismiss refresh token from backend
    yield put(logoutUserSuccess(LOGOUT_USER, true));
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

// ****
// DEPRECATED FUNCTION
// ****
/*
function* getUserOrganisationWorker({ payload: { history } }) {
  try {
    if (sessionStorage.getItem("authUser")) {
      // We Need to Fetch the Organisation the User belongs to
      const r = yield call(getOrganisationList);
      if (r.error) {
        yield put(loginFailed(r));
      } else {
        yield put(loginSuccess(r));
        sessionStorage.setItem("org", JSON.stringify(r));
        const key = "authUser";
        const jsonString = sessionStorage.getItem(key);
        const dataObject = JSON.parse(jsonString);
        const data = dataObject.data;

        if (data.is_account_new) {
          history.replace("/settings-services");
        } else {
          // Replace "/dashboard" with the desired default location
          history.replace("/dashboard");
        }
      }
    }
  } catch (error) {
    yield put(loginFailed(error));
  }
}
   */

// Is user register successfull then direct plot user in redux.
function* activateUser({ payload: data }) {
  try {
    const response = yield call(postActivateUser, data);

    sessionStorage.setItem("__logged_in_user_data__", response.data.access_token);

    if (response.error) {
      if (response.status === 400) {
        yield put(activateUserFailed(response));
      }

      if (response.status === 500) {
        yield put(activationError(response));
      }
    } else {
      if (response.status === 200) {
        yield put(profileSuccess(response));
        yield put(activateUserSuccessful(response));
      }
    }
  } catch (error) {
    yield put(activationError(error));
    //console.log("ACTIVATE ERROR: ", error);
  }
}

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  try {
    const response = yield call(postRegister, {
      email: user.email,
      password: user.password,
      username: user.first_name,
      selected_plan: user.selected_plan,
    });

    if (response.error) {
      if (response.status === 400) {
        yield put(registerUserFailed(response));
      }

      if (response.status === 500) {
        yield put(registrationError(response));
      }
    } else {
      if (response.status === 201) {
        yield put(registerUserSuccessful(response));
      }
    }
  } catch (error) {
    yield put(registrationError(error));
  }
}

function* refreshUserToken() {
  try {
    const response = yield call(postRefreshToken);
    yield put(refreshTokenSuccess(response));
  } catch (error) {
    yield put(refreshTokenFailed(error));
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
}

export function* watchActivateUser() {
  yield takeEvery(ACTIVATE_USER, activateUser);
}

function* authSaga() {
  //yield all([fork(watchUserRegister), fork(watchActivateUser)]);
  yield takeEvery(REGISTER_USER, registerUser);
  yield takeEvery(ACTIVATE_USER, activateUser);
  yield takeEvery(REFRESH_TOKEN, refreshUserToken);
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  //yield takeEvery(GET_USER_ORG, getUserOrganisationWorker);
}

export default authSaga;
