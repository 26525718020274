export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_CLIENTS_LIST = "GET_CLIENTS_LIST";
export const GET_CLIENT = "GET_CLIENT";

export const ADD_NEW_CLIENT = "ADD_NEW_CLIENT";
export const ADD_NEW_CLIENT_SUCCESS = "ADD_NEW_CLIENT_SUCCESS";
export const ADD_NEW_CLIENT_FAIL = "ADD_NEW_CLIENT_FAIL";

export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAIL = "UPDATE_CLIENT_FAIL";

export const DELETE_CLIENT = "DELETE_CLIENT";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAIL = "DELETE_CLIENT_FAIL";

export const CREATE_CLIENT_COMMENT = "CREATE_CLIENT_COMMENT";
export const CREATE_CLIENT_COMMENT_SUCCESS = "CREATE_CLIENT_COMMENT_SUCCESS";
export const CREATE_CLIENT_COMMENT_FAIL = "CREATE_CLIENT_COMMENT_FAIL";
export const GET_COMMENT_LIST = "GET_COMMENT_LIST";

export const RESET_CLIENT_FLAG = "RESET_CLIENT_FLAG";
