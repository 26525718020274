import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useFormik } from "formik";
import * as Yup from "yup";

import BootstrapTheme from "@fullcalendar/bootstrap";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import SimpleBar from "simplebar-react";
// import bootstrap5Plugin from "@fullcalendar/bootstrap5";
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-icons/font/bootstrap-icons.css'

import interactionPlugin, { Draggable } from "@fullcalendar/interaction";

//redux
import { useDispatch, useSelector } from "react-redux";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import { handleValidDateAlt } from "../../Utility/";

//Simple bar
import listPlugin from "@fullcalendar/list";

import {
  getEventsInDateRange,
  addNewEvent as onAddNewEvent,
  deleteEvent as onDeleteEvent,
  getEvents as onGetEvents,
  getTeamsList as onGetTeams,
  getUsersList as onGetUsers,
  updateEvent as onUpdateEvent,
  resetCalendar,
} from "../../store/actions";

import AsyncSelect from "react-select/async";
import { eventStatusColors } from "../../common/data/calender";
import DateField from "../../Components/Common/Fields/DateField";
import MultiSelectField from "../../Components/Common/Fields/MultiSelectField";
import TextField from "../../Components/Common/Fields/TextField";
import TimeField from "../../Components/Common/Fields/TimeField";
import { getTeams, getUsers } from "../../helpers/backend_helper";
import UpcommingEvents from "./UpcommingEvents";

const Calender = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [event, setEvent] = useState(null);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState(0);
  const [selectedNewDay, setSelectedNewDay] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const dateRangeRef = useRef(null);

  const {
    events,
    categories,
    crmusers: usersList,
    teamsList,
    isEventAdd,
    isEventDeleted,
    isEventUpdated,
    upcommingEvents,
    isEventAddFail,
    isEventDeletedFail,
    isEventUpdatedFail,
    error,
  } = useSelector((state) => ({
    error: state.Calendar.error,

    events: state.Calendar.events,
    upcommingEvents: state.Calendar.upcommingevents,
    categories: state.Calendar.categories,
    crmusers: state.Users.usersList,
    teamsList: state.Teams.teamsList,
    isEventAddFail: state.Calendar.isEventAddFail,
    isEventDeletedFail: state.Calendar.isEventDeletedFail,
    isEventUpdatedFail: state.Calendar.isEventUpdatedFail,
    isEventAdd: state.Calendar.isEventAdd,
    isEventDeleted: state.Calendar.isEventDeleted,
    isEventUpdated: state.Calendar.isEventUpdated,
  }));

  const [users, setUsers] = useState(null);
  const [teams, setTeams] = useState(null);

  const [handleDateClicked, setIsHandleDateClicked] = useState(false);
  const [recurringDays, setRecurringDays] = useState([]);

  useEffect(() => {
    // dispatch(onGetEvents());
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    });
  }, [dispatch, isEventAdd, isEventUpdated, upcommingEvents]);

  useEffect(() => {
    if (!usersList) dispatch(onGetUsers());
    if (!teamsList) dispatch(onGetTeams());

    if (event) {
      const assigned_to = event?.assigned_to;
      const teams = event?.teams;

      setUsers(assigned_to);
      setTeams(teams);
    }
  }, [dispatch, usersList, teamsList, event]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setEvent(null);
      setUsers(null);
      setTeams(null);
      setIsEdit(false);
      setIsCreate(true);
      setIsHandleDateClicked(false);
      validation.resetForm();
    } else {
      setModal(true);
    }
  };

  /**
   * Handling date click on calendar
   */
  const handleDateClick = (arg) => {
    const date = arg.date;
    setIsHandleDateClicked(true);

    // set the clicked date as the start date for the event and toggle the modal
    validation.setFieldValue("start_date", handleValidDateAlt(date));
    validation.setFieldValue("end_date", handleValidDateAlt(date));

    toggle();
  };

  const handleDatesSelected = (dates) => {
    validation.setFieldValue("start_date", handleValidDateAlt(dates.start));
    validation.setFieldValue("end_date", handleValidDateAlt(dates.end));
    toggle();
  };

  const handleDatesSelectedCleared = (dates) => {
    validation.setFieldValue("start_date", "");
    validation.setFieldValue("end_date", "");
  };

  const str_dt = function formatDate(date) {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var d = new Date(date),
      month = "" + monthNames[d.getMonth()],
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day + " " + month, year].join(",");
  };

  const date_r = function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  };

  const ToastSuccess = (message) => {
    toast.success(message, { position: toast.POSITION_TOP_RIGHT });
    dispatch(resetCalendar());
  };

  const ToastError = (message) => {
    toast.error(message, { position: toast.POSITION_TOP_RIGHT });
    dispatch(resetCalendar());
  };
  /**
   * Handling click on event on calendar
   */
  const handleEventClick = (arg) => {
    const event = arg?.event;
    const { start, end } = event._instance.range;

    const st_date = start;
    const ed_date = end;
    const r_date =
      ed_date == null || ed_date.toLocaleDateString() === st_date.toLocaleDateString()
        ? str_dt(st_date)
        : str_dt(st_date) + " to " + str_dt(ed_date);
    const er_date =
      ed_date == null || ed_date.toLocaleDateString() === st_date.toLocaleDateString()
        ? date_r(st_date)
        : date_r(st_date) + " to " + date_r(ed_date);

    let recurring_days = event._def.extendedProps.recurring_days;
    if (recurring_days) {
      const r = recurring_days.map((r) => ({
        label: r,
        value: r,
      }));

      setRecurringDays(r);
    }

    setEvent({
      id: event?.id,
      title: event?.title,
      start_date: start,
      end_date: end,
      start_time: event?._def.extendedProps.start_time,
      end_time: event?._def.extendedProps.end_time,

      event_type: event?._def.extendedProps.event_type,
      status: event?._def.extendedProps.status,

      className: event?.classNames,
      category: event?.classNames[0],

      location: event?._def.extendedProps.location,
      description: event?._def.extendedProps.description,
      recurring_days: event?._def.extendedProps.recurring_days,
      teams: event?._def.extendedProps.teams,
      assigned_to: event?._def.extendedProps.assigned_to,

      defaultDate: er_date,
      datetag: r_date,
    });

    setIsEdit(true);
    toggle();
  };
  /**
   * On delete event
   */

  const handleDeleteEvent = () => {
    dispatch(onDeleteEvent(event));
    setDeleteModal(false);
    toggle();
  };

  const eventDataTransform = (e) => ({
    id: e.id,
    title: e.name,
    end: new Date([e.end_date?.split("-"), e.end_time?.split()]),
    start: new Date([e.start_date?.split("-"), e.start_time?.split()]),
    allDay: false,
    // start: e.start_date,
    // end: e.end_date,
    // endTime: e.end_time,
    // startTime: e.start_time,
    color: eventStatusColors[e.status],
    extendedProps: { ...e },
  });

  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: event?.id || "",
      name: event?.title || "",
      category: event?.category || "",
      status: event?.status || "Planned",
      event_type: event?.event_type || "Non-Recurring",
      start_time: event?.start_time || "",
      end_time: event?.end_time || "",
      start_date: handleValidDateAlt(event?.start_date) || "",
      end_date: handleValidDateAlt(event?.end_date) || "",
      recurring_days: event?.recurring_days || "",
      teams: /*(event?.teams) || */ "",
      assigned_to: /*(event?.assigned_to) || */ "",
      description: event?.description || "",
      location: event?.location || "Online",
      tag: event?.tag || "",
      // event_date: "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Please enter your event name"),
      status: Yup.string().required("Please select your status"),
      category: Yup.string().notRequired("Please select your category"),
      event_type: Yup.string().required("Please enter the event type"),
      start_time: Yup.string().required("Please enter the starting time"),
      end_time: Yup.string().required("Please enter the end time"),
      start_date: Yup.string().required("Please enter the start date"),
      end_date: Yup.string().required("Please enter the end date"),
      recurring_days: Yup.mixed().notRequired("Please enter the recurring days"),
      teams: Yup.mixed().notRequired("Please select a teams"),
      assigned_to: Yup.mixed().notRequired("Please Enter Your Event Name"),
      location: Yup.string().notRequired("Please enter a location"),
      description: Yup.string().notRequired("Please enter a description"),
      tags: Yup.string().notRequired(),
    }),

    onSubmit: (values) => {
      let event = values;

      if (isEdit) {
        dispatch(onUpdateEvent(event));
      } else {
        dispatch(onAddNewEvent(event));
      }

      setSelectedDay(null);
      setSelectedNewDay(null);
    },
  });

  if (isEventAdd || isEventUpdated) {
    dispatch(onGetEvents());
    toggle();
    validation.resetForm();

    setTimeout(() => {
      dispatch(resetCalendar());
    }, 3000);
  }

  const submitOtherEvent = () => {
    document.getElementById("form-event").classList.remove("view-event");

    // document.getElementById("event-name").classList.replace("d-none", "d-block");
    document.getElementById("event-type").classList.replace("d-none", "d-block");
    document.getElementById("event-status").classList.replace("d-none", "d-block");
    document.getElementById("event-start_time").parentNode.classList.remove("d-none");
    // document.getElementById("event-end_time").parentNode.classList.remove("d-none");
    // document.getElementById("event-start_date").parentNode.classList.remove("d-none");
    // document.getElementById("event-start-date-tag").classList.replace("d-block", "d-none");
    // document.getElementById("event-recurring_days").classList.replace("d-none", "d-block");
    // document.getElementById("event-date_range").classList.replace("d-none", "d-block");
    document.getElementById("event-teams").classList.replace("d-none", "d-block");
    document.getElementById("event-assigned_to").classList.replace("d-none", "d-block");
    document.getElementById("event-location").classList.replace("d-none", "d-block");
    document.getElementById("event-description").classList.replace("d-none", "d-block");
    // document.getElementById("event-location-tag").classList.replace("d-block", "d-none");
    // document.getElementById("event-description-tag").classList.replace("d-block", "d-none");
    // document.getElementById("edit-event-btn").parentNode.classList.add("d-none");
    document.getElementById("btn-save-event").removeAttribute("hidden");
  };

  /**
   * On category darg event
   */
  const onDrag = (event) => {
    // event.preventDefault();
    // TODO edit start and end date
    // TODO open modal with updated values
  };

  /**
   * On calendar drop event
   */
  const onDrop = (event) => {
    // handleEventClick(event);
    // const date = event["date"];
    // const day = date.getDate();
    // const month = date.getMonth();
    // const year = date.getFullYear();
    // const currectDate = new Date();
    // const currentHour = currectDate.getHours();
    // const currentMin = currectDate.getMinutes();
    // const currentSec = currectDate.getSeconds();
    // const modifiedDate = new Date(year, month, day, currentHour, currentMin, currentSec);
    // const draggedEl = event.draggedEl;
    // const draggedElclass = draggedEl.className;
    // if (draggedEl.classList.contains("external-event") && draggedElclass.indexOf("fc-event-draggable") === -1) {
    //   const modifiedData = {
    //     id: Math.floor(Math.random() * 1000),
    //     title: draggedEl.innerText,
    //     start: modifiedDate,
    //     className: draggedEl.className,
    //   };
    //   dispatch(onAddNewEvent(modifiedData));
    // }
  };

  /**
   * On Clear Date Range
   */
  function clearDateRange() {
    dateRangeRef.current.flatpickr.clear();
    validation.setFieldValue("start_date", "");
    validation.setFieldValue("end_date", "");
    setIsHandleDateClicked(false);
  }

  const loadUsersOptions = (inputValue) => {
    return new Promise((resolve) => {
      const orgList = getUsers({ search: inputValue }).then(({ users: { users } }) => {
        return users;
      });
      resolve(orgList);
    });
  };

  const loadTeamsOptions = (inputValue) => {
    return new Promise((resolve) => {
      const teamsPromise = getTeams({ search: inputValue }).then(({ teams }) => {
        return teams;
      });
      resolve(teamsPromise);
    });
  };

  document.title = "Calendar | Simply - React Admin & Dashboard";
  return (
    <React.Fragment>
      <DeleteModal show={deleteModal} onDeleteClick={handleDeleteEvent} onCloseClick={() => setDeleteModal(false)} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Calendar" pageTitle="Apps" />
          <Row>
            <Col xs={12}>
              <Row>
                <Col xl={3}>
                  <Card className="card-h-100">
                    <CardBody>
                      <button className="btn btn-primary w-100" id="btn-new-event" onClick={toggle}>
                        <i className="mdi mdi-plus"></i> Create New Event
                      </button>

                      <div id="external-events">
                        <br />
                        <p className="text-muted">Drag and drop your event or click in the calendar</p>
                        {categories &&
                          categories.map((category) => (
                            <div
                              className={`bg-soft-${category.type} external-event fc-event text-${category.type}`}
                              key={"cat-" + category.id}
                              draggable
                              onDrag={(event) => {
                                onDrag(event, category);
                              }}
                            >
                              <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2" />
                              {category.title}
                            </div>
                          ))}
                      </div>
                    </CardBody>
                  </Card>
                  <div>
                    {upcommingEvents ? (
                      <>
                        <h5 className="mb-1">Upcoming Events</h5>
                        <p className="text-muted">Don't miss scheduled events</p>
                      </>
                    ) : (
                      <h5 className="mb-1">No Upcomming Events</h5>
                    )}
                    <SimpleBar className="pe-2 me-n1 mb-3" style={{ height: "400px" }}>
                      {upcommingEvents ? (
                        <div id="upcoming-event-list">
                          {upcommingEvents?.map((event, key) => (
                            <React.Fragment key={key}>
                              <UpcommingEvents event={event} />
                            </React.Fragment>
                          ))}
                        </div>
                      ) : null}
                    </SimpleBar>
                  </div>
                </Col>

                <Col xl={9}>
                  <Card className="card-h-100">
                    <CardBody>
                      <FullCalendar
                        plugins={[BootstrapTheme, dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                        initialView="dayGridMonth"
                        slotDuration={"00:15:00"}
                        handleWindowResize={true}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                        }}
                        events={events}
                        eventDataTransform={eventDataTransform}
                        datesSet={(e) => {
                          //fetch dates based on start and end dates
                          const start = handleValidDateAlt(e.start.toLocaleDateString());
                          const end = handleValidDateAlt(e.end.toLocaleDateString());
                          dispatch(getEventsInDateRange(start, end));
                        }}
                        // customButtons={{
                        //   prev: { text: "Previous", click: (e) =>  },
                        //   next: { text: "Next", click: (e) => (e) },
                        // }}
                        editable={true}
                        droppable={true}
                        selectable={true}
                        nowIndicator={true}
                        dateClick={(e) => {
                          validation.setFieldValue("start_date", handleValidDateAlt(e.dateStr));
                          validation.setFieldValue("end_date", handleValidDateAlt(e.dateStr));
                        }}
                        select={handleDatesSelected}
                        // unselect={handleDatesSelectedCleared}
                        drop={onDrop}
                        eventClick={handleEventClick}
                        // eventDragStart={onDrag}
                        // eventDragStop={onDrop}
                        eventContent={(eventInfo) => {
                          // const { title, start, end? } = eventInfo?.event;
                          const title = eventInfo?.event.title;
                          const start = eventInfo?.event.start;
                          const end = eventInfo?.event.end;

                          return (
                            <div>
                              <div>{title}</div>
                              {/* <div>{`${start?.toLocaleTimeString()} - ${end?.toLocaleTimeString()}`}</div> */}
                            </div>
                          );
                        }}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <div style={{ clear: "both" }}></div>

              <Modal isOpen={modal} id="event-modal" centered>
                <ModalHeader toggle={toggle} tag="h5" className="p-3 bg-soft-info modal-title">
                  {!!isEdit ? "Edit Event" : "Add Event"}
                </ModalHeader>
                <ModalBody>
                  <Form
                    className={!!isEdit ? "needs-validation view-event" : "needs-validation"}
                    name="event-form"
                    id="form-event"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    {!!isEdit ? (
                      <div className="text-end">
                        <Link
                          to="#"
                          className="btn btn-sm btn-soft-primary"
                          id="edit-event-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            submitOtherEvent();
                            return false;
                          }}
                        >
                          Edit
                        </Link>
                      </div>
                    ) : null}

                    <div className="event-details">
                      <div className="d-flex mb-2">
                        <div className="flex-grow-1 d-flex align-items-center">
                          <div className="flex-shrink-0 me-3">
                            <i className="ri-book-line text-muted fs-16"></i>
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="d-block fw-semibold mb-0" id="event-start-date-tag">
                              {event ? event.title : ""}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex mb-2">
                        <div className="flex-grow-1 d-flex align-items-center">
                          <div className="flex-shrink-0 me-3">
                            <i className="ri-calendar-event-line text-muted fs-16"></i>
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="d-block fw-semibold mb-0" id="event-start-date-tag">
                              {event ? event.datetag : ""}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center mb-2">
                        <div className="flex-shrink-0 me-3">
                          <i className="ri-map-pin-line text-muted fs-16"></i>
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="d-block fw-semibold mb-0">
                            {" "}
                            <span id="event-location-tag">
                              {event && event.location !== undefined ? event.location : "No Location"}
                            </span>
                          </h6>
                        </div>
                      </div>
                      <div className="d-flex mb-3">
                        <div className="flex-shrink-0 me-3">
                          <i className="ri-discuss-line text-muted fs-16"></i>
                        </div>
                        <div className="flex-grow-1">
                          <p className="d-block text-muted mb-0" id="event-description-tag">
                            {event && event.description !== undefined ? event.description : "No Description"}
                          </p>
                        </div>
                      </div>
                    </div>

                    <Row className="event-form">
                      <TextField
                        mb="mb-3"
                        value={validation.values.name}
                        onChange={validation.handleChange}
                        validation={validation}
                        onBlur={validation.handleBlur}
                        placeholder="Enter event name"
                        label="Event name"
                        name="name"
                        errorMessage={error && error.errors}
                      />

                      <Col xs={12}>
                        <Row>
                          {/*<Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Type</Label>
                              <Input
                                className={!!isEdit ? "form-select d-none" : "form-select d-block"}
                                name="event_type"
                                id="event-type"
                                type="select"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.event_type || ""}
                              >
                                <option value="Recurring">Recurring</option>
                                <option value="Non-Recurring">Non-Recurring</option>
                              </Input>
                              {validation.touched.event_type && validation.errors.event_type ? (
                                <FormFeedback event_type="invalid">{validation.errors.event_type}</FormFeedback>
                              ) : null}
                            </div>
                          </Col> */}
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Status</Label>
                              <Input
                                className={!!isEdit ? "form-select d-none" : "form-select d-block"}
                                name="status"
                                id="event-status"
                                type="select"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.status || ""}
                              >
                                <option value="Planned">Planned</option>
                                <option value="Held">Held</option>,<option value="Not Held">Not Held</option>
                                <option value="Not Started">Not Started</option>
                                <option value="Started">Started</option>
                                <option value="Completed">Completed</option>,<option value="Canceled">Canceled</option>
                                <option value="Deferred">Deferred</option>,
                              </Input>
                              {validation.touched.status && validation.errors.status ? (
                                <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      {validation.values.event_type === "Recurring" ? (
                        <MultiSelectField
                          mb="mb-3"
                          defaultValue={recurringDays}
                          onChange={validation.handleChange}
                          validation={validation}
                          onBlur={validation.handleBlur}
                          placeholder="Select recurring days..."
                          label="Recurring Days"
                          name="recurring_days"
                          // errorMessage={fieldErrors}
                          optional={true}
                          options={[
                            { label: "Monday", value: "Monday" },
                            { label: "Tuesday", value: "Tuesday" },
                            { label: "Wednesday", value: "Wednesday" },
                            { label: "Thurday", value: "Thursday" },
                            { label: "Friday", value: "Friday" },
                            { label: "Saturday", value: "Saturday" },
                            { label: "Sunday", value: "Sunday" },
                          ]}
                          errorMessage={error && error.errors}
                        />
                      ) : null}
                      {/*  */}

                      {/*  */}

                      <Col xs={12}>
                        <Row className="">
                          <TimeField
                            mb="mb-3"
                            md="6"
                            value={validation.values.start_time}
                            onChange={validation.handleChange}
                            validation={validation}
                            onBlur={validation.handleBlur}
                            label="Start time"
                            name="start_time"
                            errorMessage={error && error.errors}
                          />

                          <TimeField
                            mb="mb-3"
                            md="6"
                            value={validation.values.end_time}
                            onChange={validation.handleChange}
                            validation={validation}
                            onBlur={validation.handleBlur}
                            label="End time"
                            name="end_time"
                            errorMessage={error && error.errors}
                          />
                        </Row>
                      </Col>

                      {/* <Col xs={12}>
                        <div className="mb-3">
                          <Label>Event Date</Label>
                          <div className={!!isEdit ? "input-group d-none" : "input-group"}>
                            <Flatpickr
                              className="border-right border-transparent form-control bg-white"
                              id="event-start_date"
                              name="start_date"
                              placeholder="Select Date Range"
                              ref={dateRangeRef}
                              value={(() => {
                                const { start_date, end_date } = validation.values;
                                const s = (moment(start_date).isValid() && new Date(start_date)) || "";
                                const e = (moment(end_date).isValid() && new Date(end_date)) || "";
                                return [s, e];
                              })()}
                              options={{
                                mode: "range",
                                dateFormat: "d M Y",
                                enableTime: false,
                              }}
                              onChange={(date) => {
                                const s =
                                  (date[0]  && handleValidDateAlt(date[0])) || "";
                                const e =
                                  (date[1] && handleValidDateAlt(date[1])) || "";


                                if (handleDateClicked) {
                                  let previousDate = validation.values.start_date;
                                  let currentDate = s;

                                  let isPreviousDateGreaterThanCurrentDate = e > currentDate;

                                  if (isPreviousDateGreaterThanCurrentDate) {
                                    e && validation.setFieldValue("end_date", e);
                                  }
                                  return;
                                }

                                validation.setFieldValue("start_date", s);
                                e && validation.setFieldValue("end_date", e || s);

                                if (s > e) {
                                  validation.setFieldValue("start_date", e);
                                  validation.setFieldValue("end_date", s);
                                }
                              }}
                            />
                            <span className="input-group-text bg-white cursor-pointer" onClick={() => clearDateRange()}>
                              <i className="ri-close-circle-line"></i>
                            </span>
                            <span className="input-group-text">
                              <i className="ri-calendar-event-line"></i>
                            </span>
                          </div>
                        </div>
                        {validation.touched.end_date && validation.errors.end_date ? (
                          <FormFeedback type="invalid">{validation.errors.end_date}</FormFeedback>
                        ) : null}

                        {error && error.errors && error.errors["end_date"] ? error.errors["end_date"] : ""}
                      </Col> */}

                      <DateField
                        mb="mb-3"
                        md="6"
                        value={validation.values.start_date || ""}
                        onChange={validation.handleChange}
                        validation={validation}
                        onBlur={validation.handleBlur}
                        label="Event start date"
                        name="start_date"
                        errorMessage={error && error.errors}
                      />

                      <DateField
                        mb="mb-3"
                        md="6"
                        value={validation.values.end_date || ""}
                        onChange={validation.handleChange}
                        validation={validation}
                        onBlur={validation.handleBlur}
                        label="Event end date"
                        name="end_date"
                        errorMessage={error && error.errors}
                      />

                      <Col xs={12}>
                        <div className="mb-3">
                          <Label htmlFor="event-teams">Teams</Label>
                          <div>
                            <AsyncSelect
                              name="teams"
                              id="event-teams"
                              isMulti
                              isClearable
                              placeholder="Select Teams"
                              loadOptions={loadTeamsOptions}
                              onChange={(e) => validation.setFieldValue("teams", JSON.stringify(e.map((e) => e?.id)))}
                              onBlur={validation.handleBlur}
                              getOptionValue={(option) => option?.id}
                              getOptionLabel={(option) => option?.name}
                              defaultValue={teams}
                              key={teams}
                              defaultOptions={teamsList}
                            />
                            {validation.touched.teams && validation.errors.teams ? (
                              <FormFeedback type="invalid">{validation.errors.teams}</FormFeedback>
                            ) : null}
                          </div>
                        </div>
                      </Col>

                      <Col xs={12}>
                        <div className="mb-3">
                          <Label htmlFor="event-assigned_to">Assign To</Label>
                          <div>
                            <AsyncSelect
                              name="assigned_to"
                              id="event-assigned_to"
                              isMulti
                              isClearable
                              loadOptions={loadUsersOptions}
                              placeholder="Assign to users"
                              onChange={(e) =>
                                validation.setFieldValue("assigned_to", JSON.stringify(e.map((e) => e?.id)))
                              }
                              onBlur={validation.handleBlur}
                              getOptionValue={(option) => option?.id}
                              getOptionLabel={(option) =>
                                `${option?.user_details.first_name} ${option?.user_details.last_name}`
                              }
                              defaultValue={users}
                              key={users}
                              defaultOptions={usersList}
                            />
                            {validation.touched.assigned_to && validation.errors.assigned_to ? (
                              <FormFeedback type="invalid">{validation.errors.assigned_to}</FormFeedback>
                            ) : null}
                          </div>
                        </div>
                      </Col>

                      <Col xs={12}>
                        <div className="mb-3">
                          <Label className="form-label">Location</Label>
                          <Input
                            type="text"
                            className={"form-control d-block"}
                            id="event-location"
                            name="location"
                            placeholder="Enter a location"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.location}
                            invalid={validation.touched.location && validation.errors.location ? true : false}
                          />
                          {validation.touched.location && validation.errors.location ? (
                            <FormFeedback type="invalid">{validation.errors.location}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col xs={12}>
                        <div className="mb-3">
                          <Label className="form-label">Description</Label>
                          <Input
                            type="textarea"
                            className={"form-control d-block"}
                            id="event-description"
                            name="description"
                            placeholder="Enter a description"
                            rows="3"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description}
                            invalid={validation.touched.description && validation.errors.description ? true : false}
                          />
                          {validation.touched.description && validation.errors.description ? (
                            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <div className="hstack gap-2 justify-content-end">
                      {!!isEdit && (
                        <button
                          type="button"
                          className="btn btn-soft-danger"
                          id="btn-delete-event"
                          onClick={() => setDeleteModal(true)}
                        >
                          <i className="ri-close-line align-bottom"></i> Delete
                        </button>
                      )}
                      <button type="submit" className="btn btn-success" id="btn-save-event">
                        {!!isEdit ? "Edit Event" : "Add Event"}
                      </button>
                    </div>
                  </Form>
                </ModalBody>
              </Modal>

              {/* {isEventAdd ? (
                <MsgToast msg="Event Added Successfull" color="success" icon="ri-checkbox-circle-line" />
              ) : null}
              {isEventUpdated ? (
                <MsgToast msg="Event Updated Successfull" color="success" icon="ri-checkbox-circle-line" />
              ) : null}
              {isEventDeleted ? (
                <MsgToast msg="Event Delete Successfull" color="success" icon="ri-checkbox-circle-line" />
              ) : null}
              {isEventAddFail ? <MsgToast msg="Event Added Fail" color="danger" icon="ri-error-warning-line" /> : null}
              {isEventUpdatedFail ? (
                <MsgToast msg="Event Updated Fail" color="danger" icon="ri-error-warning-line" />
              ) : null}
              {isEventDeletedFail ? (
                <MsgToast msg="Event Delete Fail" color="danger" icon="ri-error-warning-line" />
              ) : null} */}

              {isEventAdd && ToastSuccess("Event Added Successfull")}
              {isEventUpdated && ToastSuccess("Event Updated Successfull")}
              {isEventDeleted && ToastSuccess("Event Deleted Successfull")}

              {isEventAddFail && ToastError("Event Added Failed")}
              {isEventUpdatedFail && ToastError("Event Updated Failed")}
              {isEventDeletedFail && ToastError("Event Deleted Failed")}

              <ToastContainer limit={1} closeButton={true} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Calender.propTypes = {
  events: PropTypes.any,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default Calender;
