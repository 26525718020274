import {
  CREATE_BILLING,
  CREATE_BILLING_ERROR,
  DELETE_BILLING,
  CREATE_BILLING_FAILED,
  RESET_BILLING_FLAG,
  CREATE_BILLING_SUCCESSFUL,
  GET_BILLING_SUCCESS,
  DELETE_BILLING_SUCCESSFUL,
  UPDATE_BILLING_SUCCESSFUL,
  UPDATE_BILLING,
  UPDATE_BILLING_FAIL,
  GET_BILLING,
  GET_BILLING_FAIL,
  ADD_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD_SUCCESS,
  ADD_PAYMENT_METHOD_FAIL,
  GET_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD_FAIL,
  REMOVE_PAYMENT_METHOD,
  REMOVE_PAYMENT_METHOD_SUCCESS,
  REMOVE_PAYMENT_METHOD_FAIL,
  UPDATE_PAYMENT_METHOD,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_FAIL,
} from "./actionTypes";

export const addPaymentMethod = (data) => ({
  type: ADD_PAYMENT_METHOD,
  payload: data,
});

export const addPaymentMethodSuccess = (data) => ({
  type: ADD_PAYMENT_METHOD_SUCCESS,
  payload: data,
});

export const addPaymentMethodFail = (data) => ({
  type: ADD_PAYMENT_METHOD_FAIL,
  payload: data,
});

export const getPaymentMethod = (data) => ({
  type: GET_PAYMENT_METHOD,
  payload: data,
});

export const getPaymentMethodSuccess = (data) => ({
  type: GET_PAYMENT_METHOD_SUCCESS,
  payload: data,
});

export const getPaymentMethodFail = (data) => ({
  type: GET_PAYMENT_METHOD_FAIL,
  payload: data,
});

export const updatePaymentMethod = (data) => ({
  type: UPDATE_PAYMENT_METHOD,
  payload: data,
});

export const updatePaymentMethodSuccess = (data) => ({
  type: UPDATE_PAYMENT_METHOD_SUCCESS,
  payload: data,
});

export const updatePaymentMethodFail = (data) => ({
  type: UPDATE_PAYMENT_METHOD_FAIL,
  payload: data,
});

export const removePaymentMethod = (data) => ({
  type: REMOVE_PAYMENT_METHOD,
  payload: data,
});

export const removePaymentMethodSuccess = (data) => ({
  type: REMOVE_PAYMENT_METHOD_SUCCESS,
  payload: data,
});

export const removePaymentMethodFail = (data) => ({
  type: REMOVE_PAYMENT_METHOD_FAIL,
  payload: data,
});

export const getBillingSuccess = (data) => ({
  type: GET_BILLING_SUCCESS,
  payload: data,
});

export const getBillingFail = (data) => ({
  type: GET_BILLING_FAIL,
  payload: data,
});

export const getBilling = (data) => {
  return {
    type: GET_BILLING,
    payload: { data },
  };
};

export const createBilling = (data) => {
  return {
    type: CREATE_BILLING,
    payload: { data },
  };
};

export const createBillingSuccessful = (data) => {
  return {
    type: CREATE_BILLING_SUCCESSFUL,
    payload: data,
  };
};

export const updateBillingSuccessful = (data) => {
  return {
    type: UPDATE_BILLING_SUCCESSFUL,
    payload: data,
  };
};

export const createBillingFailed = (error) => {
  return {
    type: CREATE_BILLING_FAILED,
    payload: error.errors,
  };
};

export const createBillingError = (error) => {
  return {
    type: CREATE_BILLING_ERROR,
    payload: error,
  };
};

export const deleteBillingSuccess = (error) => {
  return {
    type: DELETE_BILLING_SUCCESSFUL,
  };
};

export const deleteBilling = (billingId) => {
  return {
    type: DELETE_BILLING,
    payload: billingId,
  };
};

export const updateBilling = (data) => {
  return {
    type: UPDATE_BILLING,
    payload: data,
  };
};

export const updateBillingSuccess = (BILLING) => ({
  type: UPDATE_BILLING_SUCCESSFUL,
  payload: BILLING,
});

export const updateBillingFail = (error) => ({
  type: UPDATE_BILLING_FAIL,
  payload: error,
});

export const resetBillingFlag = () => {
  return {
    type: RESET_BILLING_FLAG,
  };
};
