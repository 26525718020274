import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// Login Redux States
import {
  profileChangePasswordSuccess,
  profileError,
  profileSuccess,
  editProfileSuccess,
  profileChangePasswordFail,
} from "./actions";
import { EDIT_PROFILE, GET_PROFILE } from "./actionTypes";
//Include Both Helper File with needed methods
import { updateProfile, getProfile, profileChangePassword } from "../../../helpers/backend_helper";
import { PROFILE_CHANGE_PASSWORD } from "../../../helpers/url_helper";

function* fetchProfile() {
  try {
    const response = yield call(getProfile);
    yield put(profileSuccess(response));
  } catch (error) {
    yield put(profileError(error));
  }
}

function* editProfile({ payload: { user } }) {
  try {
    const response = yield call(updateProfile, user.get("id"), user);
    yield put(editProfileSuccess(response));
    // yield put(profileSuccess({ user, ...response }));
  } catch (error) {
    //console.log("ERROR: ", error);
    yield put(profileError(error));
  }
}

function* changePassword({ payload: { passwords } }) {
  try {
    const response = yield call(profileChangePassword, passwords);

    if (response.status === 200) {
      yield put(profileChangePasswordSuccess(response));
    }

    if (response.status === 400) {
      yield put(profileChangePasswordFail(response.errors));
    }
  } catch (error) {
    // console.log(error.response);
    // console.log(error);
    // yield put(profileError(error));
  }
}

export function* watchGetProfile() {
  yield takeEvery(GET_PROFILE, fetchProfile);
}

export function* watchEditProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile);
}

export function* watchChangePassword() {
  yield takeEvery(PROFILE_CHANGE_PASSWORD, changePassword);
}

function* ProfileSaga() {
  yield all([fork(watchEditProfile), fork(watchChangePassword), fork(watchGetProfile)]);
}

export default ProfileSaga;
