import React from "react";
import { Redirect } from "react-router-dom";

//Dashboard
import Dashboard from "../apps/dashboard";
// import DashboardEcommerce from "../pages/DashboardEcommerce";

//login
import ForgetPasswordPage from "../apps/auth/ForgetPassword";
import Login from "../apps/auth/Login";
import Logout from "../apps/auth/Logout";
import Register from "../apps/auth/Register";
import RegistrationAccepted from "../apps/auth/RegistrationAccepted";
import ResetUserPassword from "../apps/auth/ResetUserPassword";

import createOrginzation from "../apps/auth/createorgnization";

// Terms of service
import Terms from "../apps/tos";

// Contacts
import ContactDetails from "../apps/donors/contacts/ContactDetails";
import ContactList from "../apps/donors/contacts/ContactList";

// Cases
import CasesOverview from "../apps/cases/CasesOverview";
import CreateCase from "../apps/cases/CreateCase/";
import Cases from "../apps/cases/ListView";
import CaseDetailsPage from "../apps/cases/ListView/DetailsPage";
import updateCase from "../apps/cases/UpdateCase/";

// Clients
import ClientsList from "../apps/clients/ClientsList";
import CreateClient from "../apps/clients/CreateClient";
import DetailsPage from "../apps/clients/DetailsPage";
import UpdateClient from "../apps/clients/UpdateClient";

// Volunteers
import CreateVolunteer from "../apps/volunteers/CreateVolunteer";
import UpdateVolunteer from "../apps/volunteers/UpdateVolunteer";
import VolunteersList from "../apps/volunteers/VolunteersList";

// Users
import UsersList from "../apps/users/UsersList";
import creatUser from "../apps/users/createUser";
import updateUser from "../apps/users/updateUser";

// Service
import ServiceList from "../apps/settings/services/ServicesList";

// Account settings
import AccountSettings from "../apps/settings/accounts/AccountsSettings";
import Plans from "../apps/settings/accounts/plans/Plans.js";

// Donors
import CreateContact from "../apps/donors/contacts/create/create";
import UpdateContact from "../apps/donors/contacts/create/update";
import DonorCreateLeads from "../apps/donors/leads/CreateLead";
import DonorUpdateLeads from "../apps/donors/leads/UpdateLead";
import DonorLeads from "../apps/donors/leads/leads";
import DonorCreateOrg from "../apps/donors/organization/CreateOrganization";
import DonorOrgs from "../apps/donors/organization/OrganizationList";
import DonorUpdateOrg from "../apps/donors/organization/UpdateOrg";

// donations
import DonorCreateDonations from "../apps/donors/donations/CreateDonation";
import DonorUpdateDonations from "../apps/donors/donations/UpdateDonation";
import DonorDonations from "../apps/donors/donations/donations";

// Notes
import CreateCaseNote from "../apps/notes/CreateNote/";
import ListCaseNote from "../apps/notes/ListView/";
import UpdateCaseNote from "../apps/notes/UpdateNote/";

// Apps
import MailInbox from "../apps/email/EmailInbox/";

import TasksList from "../apps/Tasks/TaskList/";

//Campaigns
import CampaignList from "../apps/campaigns/CampaignList";

import Calendar from "../apps/Calendar/";
import Chat from "../apps/Chat/";
// activate user
import ActivateUser from "../apps/auth/ActivateUser";

// Reactivateuser user
import Reactivateuser from "../apps/auth/Reactivateuser";

// Teams
import TeamsList from "../apps/users/TeamsList";

// Report
import CreateReportForm from "../apps/reporting/CreateReportForm";
import ReportingList from "../apps/reporting/ReportingList";

// Membership
import CreateMembers from "../apps/membership/members/CreateMember";
import MembersList from "../apps/membership/members/MembersList";
import CreateMembership from "../apps/membership/memberships/CreateMembership";
import MembershipCheckout from "../apps/membership/memberships/MembershipCheckout";
import Membership from "../apps/membership/memberships/MembershipsList";
import MembershipBenefits from "../apps/membership/settings/benefits/MembershipBenefits";
import MembershipLevels from "../apps/membership/settings/levels/MembershipLevels";
import CreateMembershipTypes from "../apps/membership/settings/types/CreateMembershipTypes";
import MembershipTypes from "../apps/membership/settings/types/MembershipTypes";

// STATISTICS

import MembershipSiteConfig from "../apps/membership/settings/site/MembershipSiteConfig";
import ServicesStats from "../apps/statistics/ServicesStats";
import CasesStats from "../apps/statistics/clients/CasestStats";
import ClientStats from "../apps/statistics/clients/clientstats";

import DonationContactsStats from "../apps/statistics/DonorReport/Contacts";
import DonationOrganizationStats from "../apps/statistics/DonorReport/Organization";

import CampaignsContactsStats from "../apps/statistics/DonorReport/Campaigns";
import DonorOrgTimePattern from "../apps/statistics/DonorReport/OrgTimePattern";

// ExecutiveReports //
// demograpic
import BirthYear from "../apps/statistics/ExecutiveReports/BirthYear";
import Gender from "../apps/statistics/ExecutiveReports/Gender";
import Language from "../apps/statistics/ExecutiveReports/Language";
import Location from "../apps/statistics/ExecutiveReports/Location";

// checkout
import CreatePlan from "../apps/Checkout/CreatePlan";
import UpdatePlan from "../apps/Checkout/UpdatePlan";
import Offlinepage from "../apps/auth/Offlinepage";
import SettingsProfile from "../apps/settings/accounts/Profile/Settings";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/index", component: Dashboard },

  // Apps
  { path: "/apps-mailbox", component: MailInbox },

  { path: "/tasks", component: TasksList },

  { path: "/campaigns", component: CampaignList },

  { path: "/calendar", component: Calendar },

  { path: "/chat", component: Chat },

  // User Profile
  //{ path: "/profile", component: UserProfile },

  // Case Notes
  { path: "/case-notes", component: ListCaseNote },
  { path: "/case-notes-new", component: CreateCaseNote },
  { path: "/case-notes/:id", component: UpdateCaseNote },

  // Cases
  { path: "/cases", component: Cases },
  { path: "/cases-new", component: CreateCase },
  { path: "/cases/:id", component: CasesOverview },
  { path: "/updatecase/:id", component: updateCase },
  { path: "/case-details-page/:id", component: CaseDetailsPage },

  // Clients
  { path: "/clients-overview", component: ClientsList },
  { path: "/clients-list", component: ClientsList },
  { path: "/clients-new", component: CreateClient },
  { path: "/clients-update/:id", component: UpdateClient },
  { path: "/clients-search", component: ClientsList },
  { path: "/clients-details-page/:id", component: DetailsPage },

  // Volunteers
  { path: "/volunteer-overview", component: VolunteersList },
  { path: "/volunteer-list", component: VolunteersList },
  { path: "/volunteer-new", component: CreateVolunteer },
  { path: "/volunteer-update/:id", component: UpdateVolunteer },
  // { path: "/volunteer-search", component: VolunteersList },

  // Donors Sections
  // { path: "/donor-overview", component: DonorOverview },
  { path: "/donor-contacts", component: ContactList },
  { path: "/donor-contacts-details", component: ContactDetails },
  { path: "/donor-contacts-create", component: CreateContact },
  { path: "/donor-contacts-edit/:id", component: UpdateContact },

  { path: "/apps-crm-leads", component: DonorLeads },
  { path: "/apps-crm-leads/create-lead", component: DonorCreateLeads },
  { path: "/apps-crm-leads/:id", component: DonorUpdateLeads },

  { path: "/donor-createorg", component: DonorCreateOrg },
  { path: "/donor-orgs", component: DonorOrgs },
  { path: "/donor-updateorg/:id", component: DonorUpdateOrg },

  { path: "/donor-donations", component: DonorDonations },
  { path: "/donor-donations-create", component: DonorCreateDonations },
  { path: "/donor-donations-update/:id", component: DonorUpdateDonations },

  // Users
  { path: "/settings-users", component: UsersList },
  { path: "/settings-create-user", component: creatUser },
  { path: "/settings-update-user/:id", component: updateUser },
  { path: "/settings-services", component: ServiceList },
  { path: "/settings-teams", component: TeamsList },
  { path: "/settings-accounts", component: AccountSettings },
  { path: "/settings-accounts/:id", component: AccountSettings },
  { path: "/settings/profile", component: SettingsProfile },

  { path: "/accounts/plans", component: Plans },

  // Teams
  { path: "/settings-teams", component: TeamsList },

  // Report
  // { path: "/report-new", component: createReport },
  // { path: "/report-update/:id", component: updateReport },
  // { path: "/report-list", component: ReportsList },
  { path: "/report-list", component: ReportingList },
  { path: "/report-new/:type", component: CreateReportForm },

  { path: "/report-update/:id", component: CreateReportForm },

  // { path: "/membership-overview", component: MembershipOverview },
  { path: "/members", component: MembersList },
  { path: "/members-create", component: CreateMembers },
  { path: "/members-update/:id", component: CreateMembers },
  { path: "/memberships", component: Membership },
  { path: "/memberships-create", component: CreateMembership },
  { path: "/memberships-update/:id", component: CreateMembership },
  { path: "/memberships-create/:id/:action", component: CreateMembership },
  { path: "/memberships/:id/checkout", component: MembershipCheckout },
  { path: "/membership-types", component: MembershipTypes },
  { path: "/membership-types-create", component: CreateMembershipTypes },
  { path: "/membership-types-update/:id", component: CreateMembershipTypes },
  { path: "/membership-benefits", component: MembershipBenefits },
  { path: "/membership-levels", component: MembershipLevels },
  { path: "/membership-site-settings", component: MembershipSiteConfig },

  // client stats

  { path: "/client-stats", component: ClientStats },
  { path: "/cases-stats", component: CasesStats },
  { path: "/services-stats", component: ServicesStats },

  // donor stats
  { path: "/donations-organization", component: DonationOrganizationStats },
  { path: "/donations-contacts", component: DonationContactsStats },
  { path: "/donations-campaigns", component: CampaignsContactsStats },
  { path: "/donations-org-pattern", component: DonorOrgTimePattern },

  // demographic reports
  { path: "/demographics-birth-year", component: BirthYear },
  { path: "/demographics-gender", component: Gender },
  { path: "/demographics-language", component: Language },
  { path: "/demographics-country", component: Location },

  // checkout
  // { path: "/checkout/plan-create/:type", component: CreatePlan },
  // { path: "/checkout/plan-update/:type", component: UpdatePlan },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const publicRoutes = [
  // Authentication Page

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPasswordPage },
  { path: "/reset-password/:uid/:token/:delta_hours", component: ResetUserPassword },
  { path: "/register/:plan", component: Register },
  { path: "/registration-accepted", component: RegistrationAccepted },

  { path: "/activate-user/:uid/:token/:activation_key", component: ActivateUser },

  { path: "/resend-activation-link", component: Reactivateuser },

  { path: "/terms", component: Terms },

  //{ path: "/apps-members-portal", component: MembershipPortal },
];

const checkoutRoutes = [
  // checkout
  { path: "/createorgnization", component: createOrginzation },
  { path: "/subscription-inactive", component: Offlinepage },
  { path: "/checkout/plan-create/:type", component: CreatePlan },
  { path: "/checkout/plan-update/:type", component: UpdatePlan },
];

export { authProtectedRoutes, checkoutRoutes, publicRoutes };
