import React, { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import ParticlesAuth from "./ParticlesAuth";

//redux
import { useDispatch, useSelector } from "react-redux";

import { Link, withRouter } from "react-router-dom";

// Formik validation
import { useFormik } from "formik";
import * as Yup from "yup";

import logoLight from "../../assets/images/logo-light.png";
import { loginUser, resetLoginFlag } from "../../store/actions";
import { createSNPCustomerPortalSession } from "../../helpers/backend_helper";

const Login = ({ location, history }) => {
  const dispatch = useDispatch();

  const [alert, setAlert] = useState({});
  const [isError, setIsError] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const pathname = location?.state?.from?.pathname || "/dashboard";
  const { user, error, errorObj, success, response } = useSelector((state) => ({
    error: state.Account.error,
    errorObj: state.Account.errorObj,
    user: state.Account.user,
    success: state.Account.success,
    response: state.Account.response,
  }));

  const togglePasswordVisiblity = () => setPasswordShown(passwordShown ? false : true);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, history));
    },
  });

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetLoginFlag());
      validation.setSubmitting(false);
    }, 3000);
    setIsError(false);
  }, [dispatch, error, success]);

  const goToDashboard = useCallback(() => {
    history.push({ pathname });
  }, [history, pathname]);

  const goToCheckout = useCallback(() => {
    const selected_plan = user?.user?.user_details?.selected_plan || "snp_standard";
    history.push({ pathname: `/checkout/plan-create/${selected_plan}` });
    //history.push({ pathname: `/checkout/plan-create/snp_standard` });
  }, [history, user]);

  const goToCreateOrg = useCallback(() => {
    history.push({ pathname: "/createorgnization" });
  }, [history]);

  const goToChoosePlan = useCallback(() => {
    history.push({ pathname: "/choose-plan" });
  }, [history]);

  const goToCustomerPortal = useCallback(async () => {
    setAlert({
      status: true,
      title: "Redirecting, Please Wait!",
      message: "Loading...",
      class: "spinner-border spinner-border-lg",
      textClass: "text-primary",
      //back_url: "/accounts/plans",
    });
    try {
      const { url } = await createSNPCustomerPortalSession();
      setAlert({ status: false });
      window.location = url;
    } catch (error) {
      //console.log(error);
    }
  }, []);

  useEffect(() => {
    if (success) {
      if (user) {
        if (user?.org?.subscription?.status === "active") {
          /* we have an active-subscription*/
          goToDashboard();
        } else if (
          (user?.org?.subscription && user?.org?.subscription?.status !== "active") ||
          (!user?.user?.customer && user?.org && !user?.org?.subscription && !user?.user?.user_details?.selected_plan)
        ) {
          // TODO: alert customer to pay within 24hrs
          const selected_plan = user?.user?.user_details?.selected_plan;
          const current_customer = user?.user?.customer;
          setAlert({
            status: true,
            title: "Sorry!",
            message: "Your Subscription is In-Complete",
            //class: "ri-information-line",
            class: "ri-error-warning-line ri-3xl",
            textClass: "text-primary",
            //back_url: "/accounts/plans",
            //// redirect user to the customer portal
            back_url: current_customer ? () => goToCustomerPortal() : null,
            dismissable: true,
          });
        } else if (
          (user?.user?.customer != null || user?.user?.user_details.selected_plan) &&
          user?.org &&
          !user?.org?.subscription
        ) {
          /* we dont have an active-subscription*/
          goToCheckout();
        } else {
          //we didn't have an active-subscription
          // goToCreateOrg();
          //} else if (!user?.user?.customer && !user?.org?.subscription) {
        }
      } else {
        // TODO: redirect to login / activate
        //console.log("ERROR: ", error);
      }
    }
  }, [success, errorObj, user, goToDashboard, goToCheckout, goToCreateOrg, goToCustomerPortal]);

  document.title = "SignIn | SimplyNP";
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Modal isOpen={alert?.status} centered toggle={() => setAlert({ ...alert, status: !alert?.status })}>
            <ModalBody>
              <div className="text-center pt-4 pb-2">
                <div className="mb-4">
                  <div className="avatar-lg mx-auto my-2">
                    <div className={`avatar-title bg-light ${alert.textClass} display-1 rounded-circle`}>
                      <span className="d-flex align-items-center">
                        <span className={`${alert?.class} flex-shrink-0`} role="status">
                          <span className="visually-hidden">Loading...</span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <h5>{alert?.title}</h5>
                <p className="text-muted">{alert?.message}</p>
                <button hidden={!alert?.back_url} className="btn btn-soft-primary mt-2" onClick={alert?.back_url}>
                  Manage
                </button>
              </div>
            </ModalBody>
          </Modal>
          {/***/}
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="40" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">The better way to manage your NON-Profit</p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">Sign in to continue to SimplyNP.</p>
                    </div>

                    {errorObj && errorObj.status === 404 ? (
                      <Alert color="danger">
                        <div>{errorObj.message || errorObj.error}</div>
                      </Alert>
                    ) : errorObj && errorObj.status === 401 ? (
                      <Alert color="danger">
                        <div>
                          Account Inactive <a href="/resend-activation-link">click here</a> to activate your account
                        </div>
                      </Alert>
                    ) : null}

                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        <div className="mb-3">
                          <Label htmlFor="username" className="form-label">
                            Username
                          </Label>
                          <Input
                            name="username"
                            className="form-control"
                            placeholder="Enter username"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.username || ""}
                            invalid={validation.touched.username && validation.errors.username ? true : false}
                          />
                          {validation.touched.username && validation.errors.username ? (
                            <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <div className="float-end">
                            <Link to="/forgot-password" className="text-muted">
                              Forgot password?
                            </Link>
                          </div>
                          <Label className="form-label" htmlFor="password-input">
                            Password
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="password"
                              value={validation.values.password || ""}
                              type={passwordShown ? "text" : "password"}
                              className="form-control pe-5"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={validation.touched.password && validation.errors.password ? true : false}
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                            >
                              <i onClick={togglePasswordVisiblity} className="ri-eye-fill align-middle"></i>
                            </button>
                          </div>
                        </div>

                        <div className="form-check">
                          <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                          <Label className="form-check-label" htmlFor="auth-remember-check">
                            Remember me
                          </Label>
                        </div>

                        <div className="mt-4">
                          <Button
                            color="success"
                            disabled={validation.isSubmitting}
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            {!validation.isSubmitting ? "Sign In" : "Signing In"}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Don't have an account ?{" "}
                    <a
                      href="https://simplynp.com/pricing/"
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      Signup
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
