import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";
import { deleteCase, getCasesList, resetCaseFlag } from "../../../store/actions";

import { CasesId, CreateDate, DueDate, Priority, Status, Title } from "./CaseCol";

import Widgets from "./Widgets";

import DeleteModal from "../../../Components/Common/DeleteModal";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { debounce } from "lodash";
import Loader from "../../../Components/Common/Loader";
import MsgToast from "../../../Components/Common/MsgToast";
import CrmFilter from "./CrmFilter";

const CasesData = () => {
  const dispatch = useDispatch();

  const { casesList, isCaseSuccess, error, page, stats, isCaseDelete, isCaseDeleteFail, isLoading } = useSelector(
    (state) => ({
      casesList: state.Cases.casesList,
      isCaseCreated: state.Cases.isCaseCreated,
      isCaseSuccess: state.Cases.isCaseSuccess,
      error: state.Cases.error,
      page: state.Cases.page,
      stats: state.Cases.stats,
      isCaseDelete: state.Cases.isCaseDelete,
      isCaseDeleteFail: state.Cases.isCaseDeleteFail,
      isLoading: state.Cases.isLoading,
    })
  );

  const [isInfoDetails, setIsInfoDetails] = useState(false);
  const [caseData, setCase] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetCaseFlag());
    }, 3000);
  }, [dispatch, isCaseSuccess, error, isCaseDelete, isCaseDeleteFail]);

  // Delete Data
  const onClickDelete = (caseData) => {
    setCase(caseData);
    setDeleteModal(true);
  };

  const handleDeleteCase = () => {
    if (caseData) {
      dispatch(deleteCase(caseData));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    if (!casesList) {
      dispatch(getCasesList());
    }
  }, [dispatch, casesList]);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(getCasesList({ offset, limit }));
    },
    [dispatch]
  );

  const handler = useCallback(
    debounce((value) => {
      dispatch(getCasesList({ search: value }));
    }, 1500),
    []
  );

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    setSearch(value);
    handler(value);
  };

  const toggleInfo = () => {
    setIsInfoDetails(!isInfoDetails);
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <Input type="checkbox" />;
        },
      },
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
        Cell: (cellProps) => {
          return <CasesId {...cellProps} />;
        },
      },
      {
        Header: "Title",
        accessor: "title",
        filterable: false,
        Cell: (cellProps) => {
          return <Title {...cellProps} />;
        },
      },
      {
        Header: "Client",
        Cell: (cellProps) => {
          const client = cellProps.row.original?.client;
          if (!client) return "";

          const first_name = client.first_name;
          const last_name = client.last_name;
          const client_id = client.id;

          return (
            <ul className="list-inline hstack gap-3 mb-0">
              <li className="list-inline-item edit" title="Add Note">
                <Link
                  to={{
                    pathname: "/clients-details-page/" + client_id,
                    state: { caseItem: cellProps.row.original, clientItem: cellProps.row.original.client },
                  }}
                  className="d-inline-block"
                >
                  {first_name} {last_name}
                </Link>
              </li>
            </ul>
          );
        },
      },

      {
        Header: "Create Date",
        accessor: "created_on",
        filterable: false,
        Cell: (cellProps) => {
          return <CreateDate {...cellProps} />;
        },
      },
      {
        Header: "Closed Date",
        accessor: "due_date",
        filterable: false,
        Cell: (cellProps) => {
          let data = cellProps.cell.value;

          if (!data) return "--";

          return <DueDate {...cellProps} />;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "Priority",
        accessor: "priority",
        filterable: false,
        Cell: (cellProps) => {
          return <Priority {...cellProps} />;
        },
      },
      {
        Header: "Actions",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-3 mb-0">
              <li className="list-inline-item edit" title="view details">
                <Link
                  to={{ pathname: "/case-details-page/" + cellProps.row.original.id }}
                  className="text-muted d-inline-block"
                >
                  <i className="ri-eye-fill me-2 align-middle text-muted" />
                  View
                </Link>
              </li>
              <li className="list-inline-item edit" title="Add Note">
                <Link
                  to={{
                    pathname: "/case-notes-new",
                    state: { caseItem: cellProps.row.original, clientItem: cellProps.row.original.client },
                  }}
                  className="text-muted d-inline-block"
                >
                  <i className="ri-sticky-note-line fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item edit">
                <UncontrolledDropdown>
                  <DropdownToggle href="#" className="btn-soft-secondary btn-sm dropdown" tag="button">
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <Link to={"/updatecase/" + cellProps.row.original.id} className="text-muted">
                      <DropdownItem className="dropdown-item remove-item-btn text-muted">
                        <i className="ri-edit-2-line align-middle me-2 fs-16 text-muted"></i> Edit
                      </DropdownItem>
                    </Link>
                    <DropdownItem
                      className="dropdown-item remove-item-btn text-muted"
                      onClick={() => {
                        const CaseData = cellProps.row.original;
                        onClickDelete(CaseData);
                      }}
                    >
                      <i className="ri-delete-bin-fill align-middle me-2 text-muted"></i> Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Row>
        <Widgets stats={stats} />
      </Row>
      <Row>
        <DeleteModal show={deleteModal} onDeleteClick={handleDeleteCase} onCloseClick={() => setDeleteModal(false)} />
        <Col lg={12}>
          <Card>
            <CardHeader className="border-0">
              <div className="d-flex align-items-center">
                <h5 className="card-title mb-0 flex-grow-1">Cases</h5>
                <Col xxl={1}>
                  <button type="button" className="btn btn-info" onClick={toggleInfo}>
                    <i className="ri-filter-3-line align-bottom me-1"></i> Fliters
                  </button>
                </Col>
              </div>
            </CardHeader>
            <CardBody className="border border-dashed border-end-0 border-start-0">
              <form>
                <Row className="g-3">
                  <Col xxl={5} sm={8}>
                    <div className="search-box">
                      <Input
                        onChange={(e) => {
                          handleFieldSearch(e);
                        }}
                        type="text"
                        className="form-control search bg-light border-light"
                        placeholder="Search for Case details or something..."
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </Col>
                </Row>
              </form>
            </CardBody>

            <CardBody>
              {casesList ? (
                <TableContainer
                  columns={columns}
                  data={casesList || []}
                  isGlobalFilter={false}
                  isAddUserList={false}
                  customFetchData={fetchData}
                  customTotalSize={page.count}
                  customPageSize={10}
                  className="custom-header-css"
                  divClass="table-responsive table-card mb-4"
                  tableClass="align-middle table-nowrap mb-0"
                  theadClass=""
                  thClass=""
                />
              ) : (
                <Loader error={error} />
              )}

              {isCaseDelete ? (
                <MsgToast msg="Case Deleted Successfusslly" color="success" icon="ri-checkbox-circle-line" />
              ) : null}
              {isCaseDeleteFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
              <ToastContainer limit={1} closeButton={false} />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <CrmFilter show={isInfoDetails} onCloseClick={() => setIsInfoDetails(false)} />
    </React.Fragment>
  );
};

export default CasesData;
