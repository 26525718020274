import React, { useEffect, useMemo, useState, useCallback } from "react";

import { Input, Card, CardBody, Col, Container, Row, CardHeader } from "reactstrap";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import OffCanvasFilter from "./OffCanvasFilter";
import ReportTableContainer from "../../../Components/Common/ReportTableContainer";

//Import actions
import { getCasesStats, getDemographicsReportsGroupBy } from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

// Formik

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { SimpleRadar } from "../../../Components/Common/RadarChart";

const Contacts = () => {
  const dispatch = useDispatch();

  const [toggleFilterCanvas, setToggleFilterCanvas] = useState(false);

  const { stats_list, loading } = useSelector((state) => ({
    stats_list: state.Statistics.stats_list,
  }));

  useEffect(() => {
    dispatch(getDemographicsReportsGroupBy({ group_by: "language" }));
  }, []);

  // Column
  const servicesColumns = useMemo(
    () => [
      {
        Header: "language",
        accessor: "language",
        filterable: false,
        Cell: (data) => {
          let obj = data.row.original;
          if (!obj.language) {
            return "N/A";
          }

          return <>{obj.language}</>;
        },
      },
      {
        Header: "Count",
        accessor: "count",
        filterable: false,
      },
    ],
    []
  );

  const handler = useCallback(
    debounce((value) => {
      dispatch(getDemographicsReportsGroupBy({ group_by: "language", search: value }));
    }, 500),
    []
  );

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    // setSearch(value);
    handler(value);
  };

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(getCasesStats({ offset, limit }));
    },
    [dispatch]
  );

  let getChartData = (data = []) => {
    if (!data.length) return;
    let labels = [];
    let series = [];

    data.forEach((obj) => {
      labels.push(Object.values(obj)[0]);
      series.push(Object.values(obj)[1]);
    });

    return { labels, series };
  };

  let servicesChart = getChartData(stats_list);

  document.title = "Demographics report | SimplyNP";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Demographics" pageTitle="Demographics" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <div className="text-center py-0">
                      <h5>Clients grouped by language</h5>

                      {/* <ColumnChart data={servicesChart} /> */}

                      <SimpleRadar data={servicesChart} />
                    </div>
                  </Row>
                </CardBody>
                <CardBody>
                  <CardHeader>
                    <Row className="g-3">
                      <Col md={5}>
                        <div className="search-box">
                          <Input
                            onChange={(e) => {
                              handleFieldSearch(e);
                            }}
                            type="text"
                            className="form-control search"
                            placeholder="Search language eg: french"
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>

                  <div className="text-center py-5">
                    <ReportTableContainer
                      tableClass="table-bordered"
                      divClass=" table-responsive"
                      columns={servicesColumns}
                      data={stats_list || []}
                      customFetchData={() => []}
                    />
                  </div>
                </CardBody>
              </Card>

              <Card>
                <ToastContainer limit={1} closeButton={false} />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <OffCanvasFilter show={toggleFilterCanvas} onCloseClick={() => setToggleFilterCanvas(false)} />
    </React.Fragment>
  );
};

export default Contacts;
