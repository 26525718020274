import { APIClient } from "./api_helper";
import EAPIClient from "./api_helper_w_errors";

import * as url from "./url_helper";

const api = new APIClient();
const e_api = new EAPIClient();

// Gets the logged in user data from local session storage
export const getLoggedInUser = () => {
  // TODO: MAKE REQUEST TO PROFILE BACKEND WITH TOKEN
  return null;
};

//is user is logged in
export const isUserAuthenticated = () => getLoggedInUser() !== null;

// Auth URL Methods
export const postRegister = (data) => api.create(url.REGISTER, data);
export const postLogin = (data) => api.create(url.LOGIN, data);
export const postLogout = () => api.create(url.LOGOUT);
export const postForgotPassword = (data) => api.create(url.FORGORT_PASSWORD, data);
//export const postRefreshToken = () => refreshApi.get(url.REFRESH_TOKEN);
export const postRefreshToken = () => api.get(url.REFRESH_TOKEN);
export const postActivateUser = (params) =>
  api.get(url.ACTIVATE_USER.replace(/uid|token|activation_key/g, (m) => params[m]));
export const postResetPassword = (params, data) =>
  api.create(
    url.RESET_PASSWORD.replace(/uid|token/g, (m) => params[m]),
    data
  );
export const postGoogleAuth = (data) => api.create(url.GOOGLE_CREATE, data);

// Cases URL Methods
export const getCases = (params) => api.get(url.GET_CASES_LIST, params);
export const addCases = (data) => api.create(url.CREATE_CASES, data);
export const deleteCasesAttachment = (attachmentID) =>
  api.delete(url.DELETE_CASES_ATTACHMENT.replace("{id}", attachmentID));
export const updateCasesComment = (commentID, data) =>
  api.update(url.UPDATE_CASES_COMMENT.replace("{id}", commentID), data);
export const deleteCasesComment = (commentID) => api.delete(url.DELETE_CASES_COMMENT.replace("{id}", commentID));
export const getCase = (caseID) => api.get(url.GET_CASE.replace("{id}", caseID));
export const addCase = (caseID, data) => api.create(url.CREATE_CASE.replace("{id}", caseID), data);
export const updateCase = (caseID, data) => api.update(url.UPDATE_CASE.replace("{id}", caseID), data);
export const deleteCase = (caseID) => api.delete(url.DELETE_CASE.replace("{id}", caseID));
export const getClientCases = (id, params) => api.get(url.GET_CLIENT_CASES.replace("{id}", id), params);

// Donor Contacts URL Methods
export const getContacts = (params) => api.get(url.GET_CONTACTS_LIST, params);
export const createContacts = (data) => api.create(url.CREATE_CONTACTS, data);
export const deleteContactAttahment = (attachmentID) =>
  api.delete(url.DELETE_CONTACT_ATTACHMENT.replace("{id}", attachmentID));
export const updateContactComment = (commentID, data) =>
  api.update(url.UPDATE_CONTACT_COMMENT.replace("{id}", commentID), data);
export const deleteContactComment = (commentID) => api.delete(url.DELETE_CONTACT_COMMENT.replace("{id}", commentID));
export const getContact = (id) => api.get(url.GET_CONTACT.replace("{id}", id));
export const createContact = (id, data) => api.create(url.CREATE_CONTACT.replace("{id}", id), data);
export const updateContact = (id, data) => api.update(url.UPDATE_CONTACT.replace("{id}", id), data);
export const deleteContact = (id) => api.delete(url.DELETE_CONTACT.replace("{id}", id));

// Members
export const getMembers = (params) => api.get(url.GET_MEMBERS_LIST, params);
export const createMembers = (data) => api.create(url.CREATE_MEMBERS, data);
export const deleteMemberAttahment = (attachmentID) =>
  api.delete(url.DELETE_MEMBER_ATTACHMENT.replace("{id}", attachmentID));
export const updateMemberComment = (commentID, data) =>
  api.update(url.UPDATE_MEMBER_COMMENT.replace("{id}", commentID), data);
export const deleteMemberComment = (commentID) => api.delete(url.DELETE_MEMBER_COMMENT.replace("{id}", commentID));
export const getMember = (id) => api.get(url.GET_MEMBER.replace("{id}", id));
export const createMember = (id, data) => api.create(url.CREATE_MEMBER.replace("{id}", id), data);
export const updateMember = (id, data) => api.update(url.UPDATE_MEMBER.replace("{id}", id), data);
export const deleteMember = (id) => api.delete(url.DELETE_MEMBER.replace("{id}", id));

// Membership
export const getMemberships = (params) => api.get(url.GET_MEMBERSHIP_LIST, params);
export const createMemberships = (data) => api.create(url.CREATE_MEMBERSHIPS, data);
export const deleteMembershipAttahment = (attachmentID) =>
  api.delete(url.DELETE_MEMBERSHIP_ATTACHMENT.replace("{id}", attachmentID));
export const updateMembershipComment = (commentID, data) =>
  api.update(url.UPDATE_MEMBERSHIP_COMMENT.replace("{id}", commentID), data);
export const deleteMembershipComment = (commentID) =>
  api.delete(url.DELETE_MEMBERSHIP_COMMENT.replace("{id}", commentID));
export const getMembership = (id) => api.get(url.GET_MEMBERSHIP.replace("{id}", id));
export const createMembership = (id, data) => api.create(url.CREATE_MEMBERSHIP.replace("{id}", id), data);
export const updateMembership = (id, data) => api.update(url.UPDATE_MEMBERSHIP.replace("{id}", id), data);
export const renewMembership = (id, data) => api.create(url.RENEW_MEMBERSHIP.replace("{id}", id), data);
export const deleteMembership = (id) => api.delete(url.DELETE_MEMBERSHIP.replace("{id}", id));
// use error allowed axios object
export const createMembershipPaymentIntent = (data) =>
  e_api.create(url.CREATE_MEMBERSHIP_PAYMENT_INTENT.replace("{id}", data.membership), data);
export const getPaymentPublishableKey = () => api.get(url.GET_PAYMENT_PUBLISHABLE_KEY);

// Membership Levels
export const getMembershipLevels = (params) => api.get(url.GET_MEMBERSHIP_LEVELS_LIST, params);
export const createMembershipLevels = (data) => api.create(url.CREATE_MEMBERSHIP_LEVELS, data);
export const getMembershipLevel = (id) => api.get(url.GET_MEMBERSHIP_LEVEL.replace("{id}", id));
export const createMembershipLevel = (id, data) => api.create(url.CREATE_MEMBERSHIP_LEVEL.replace("{id}", id), data);
export const updateMembershipLevel = (id, data) => api.update(url.UPDATE_MEMBERSHIP_LEVEL.replace("{id}", id), data);
export const deleteMembershipLevel = (id) => api.delete(url.DELETE_MEMBERSHIP_LEVEL.replace("{id}", id));

// Membership Benefits
export const getMembershipBenefits = (params) => api.get(url.GET_MEMBERSHIP_BENEFITS_LIST, params);
export const createMembershipBenefits = (data) => api.create(url.CREATE_MEMBERSHIP_BENEFITS, data);
export const getMembershipBenefit = (id) => api.get(url.GET_MEMBERSHIP_BENEFIT.replace("{id}", id));
export const createMembershipBenefit = (id, data) =>
  api.create(url.CREATE_MEMBERSHIP_BENEFIT.replace("{id}", id), data);
export const updateMembershipBenefit = (id, data) =>
  api.update(url.UPDATE_MEMBERSHIP_BENEFIT.replace("{id}", id), data);
export const deleteMembershipBenefit = (id) => api.delete(url.DELETE_MEMBERSHIP_BENEFIT.replace("{id}", id));

// Membership Types
export const getMembershipTypes = (params) => api.get(url.GET_MEMBERSHIP_TYPES_LIST, params);
export const createMembershipTypes = (data) => api.create(url.CREATE_MEMBERSHIP_TYPES, data);
export const deleteMembershipTypeAttahment = (attachmentID) =>
  api.delete(url.DELETE_MEMBERSHIP_TYPES_ATTACHMENT.replace("{id}", attachmentID));
export const updateMembershipTypeComment = (commentID, data) =>
  api.update(url.UPDATE_MEMBERSHIP_TYPES_COMMENT.replace("{id}", commentID), data);
export const deleteMembershipTypeComment = (commentID) =>
  api.delete(url.DELETE_MEMBERSHIP_TYPES_COMMENT.replace("{id}", commentID));
export const getMembershipType = (id) => api.get(url.GET_MEMBERSHIP_TYPE.replace("{id}", id));
export const createMembershipType = (id, data) => api.create(url.CREATE_MEMBERSHIP_TYPE.replace("{id}", id), data);
export const updateMembershipType = (id, data) => api.update(url.UPDATE_MEMBERSHIP_TYPE.replace("{id}", id), data);
export const deleteMembershipType = (id) => api.delete(url.DELETE_MEMBERSHIP_TYPE.replace("{id}", id));

// Membership Types
export const getMembershipSiteConfig = () => api.get(url.GET_MEMBERSHIP_SITE_CONFIG);
export const updateMembershipSiteConfig = (data) => api.update(url.UPDATE_MEMBERSHIP_SITE_CONFIG, data);

// Donor Leads URL Methods
export const addNewLead = (data) => api.create(url.CREATE_LEADS, data);
export const postLeads = (data) => api.create(url.CREATE_LEADS, data);
export const getLeads = (params) => api.get(url.GET_LEADS_LIST, params);
export const deleteLead = (leadID) => api.delete(url.DELETE_LEAD.replace("{id}", leadID));
export const updateLead = (id, data) => api.update(url.UPDATE_LEAD.replace("{id}", id), data);

// Donor orgs/account
export const createOrganization = (data) => api.create(url.CREATE_ACCOUNTS, data);
export const getOrganizationList = (params) => api.get(url.GET_ACCOUNTS_LIST, params);
export const deleteOrganization = (accID) => api.delete(url.DELETE_ACCOUNT.replace("{id}", accID));
export const updateOrganization = (id, data) => api.update(url.UPDATE_ACCOUNT.replace("{id}", id), data);

// Donor donations
export const createDonation = (data) => api.create(url.CREATE_DONATION, data);
export const getDonationList = (params) => api.get(url.GET_DONATION_LIST, params);
export const deleteDonation = (id) => api.delete(url.DELETE_DONATION.replace("{id}", id));
export const updateDonation = (id, data) => api.update(url.UPDATE_DONATION.replace("{id}", id), data);

// Organisation URL Methods
export const getOrganisationList = () => api.get(url.GET_ORG_LIST);
// export const createOrganisations = (data) => api.create(url.CREATE_ORG, data);
export const postOrganisations = (data) => api.create(url.CREATE_ORG, data);
export const updateOrg = (id, data) => api.update(url.UPDATE_ORG.replace("{id}", id), data);
export const updateOrgAddress = (id, data) => api.update(url.UPDATE_ORG_ADDRESS.replace("{id}", id), data);
export const createOrgAddress = (data) => api.create(url.CREATE_ORG_ADDRESS, data);

// Users URL methods
export const getUsers = (params) => api.get(url.GET_USERS_LIST, params);
export const createUser = (data) => api.create(url.CREATE_USERS, data);
export const getTeamsAndUsers = () => api.get(url.GET_TEAMS_AND_USERS_LIST);
export const getUser = (id) => api.get(url.GET_USER.replace("{id}"));
export const updateUser = (id, data) => api.update(url.UPDATE_USER.replace("{id}", id), data);
export const deleteUser = (id) => api.delete(url.DELETE_USER.replace("{id}", id));
export const updateUserStatus = (id, data) => api.update(url.UPDATE_USER_STATUS.replace("{id}", id), data);

// Clients URL Methods
export const getClients = (params) => api.get(url.GET_CLIENTS_LIST, params);
export const createClients = (data) => api.create(url.CREATE_CLIENTS, data);
export const createClientComment = (id, data) => api.create(url.CREATE_CLIENT_COMMENT.replace("{id}", id), data);
export const deleteClientAttahment = (attachmentID) =>
  api.delete(url.DELETE_CLIENT_ATTACHMENT.replace("{id}", attachmentID));
export const updateClientComment = (commentID, data) =>
  api.update(url.UPDATE_CLIENT_COMMENT.replace("{id}", commentID), data);
export const deleteClientComment = (commentID) => api.delete(url.DELETE_CLIENT_COMMENT.replace("{id}", commentID));
export const getClient = (id) => api.get(url.GET_CLIENT.replace("{id}", id));
export const createClient = (id, data) => api.create(url.CREATE_CLIENT.replace("{id}", id), data);
export const updateClient = (id, data) => api.update(url.UPDATE_CLIENT.replace("{id}", id), data);
export const deleteClient = (id) => api.delete(url.DELETE_CLIENT.replace("{id}", id));
export const getClientDetails = (id) => api.get(url.GET_CLIENT_DETAILS.replace("{id}", id));

// Volunteers URL Methods
export const getVolunteers = (params) => api.get(url.GET_VOLUNTEERS_LIST, params);
export const createVolunteers = (data) => api.create(url.CREATE_VOLUNTEERS, data);
export const deleteVolunteerAttahment = (attachmentID) =>
  api.delete(url.DELETE_VOLUNTEER_ATTACHMENT.replace("{id}", attachmentID));
export const updateVolunteerComment = (commentID, data) =>
  api.update(url.UPDATE_VOLUNTEER_COMMENT.replace("{id}", commentID), data);
export const deleteVolunteerComment = (commentID) =>
  api.delete(url.DELETE_VOLUNTEER_COMMENT.replace("{id}", commentID));
export const getVolunteer = (id) => api.get(url.GET_VOLUNTEER.replace("{id}", id));
export const createVolunteer = (id, data) => api.create(url.CREATE_VOLUNTEER.replace("{id}", id), data);
export const updateVolunteer = (id, data) => api.update(url.UPDATE_VOLUNTEER.replace("{id}", id), data);
export const deleteVolunteer = (id) => api.delete(url.DELETE_VOLUNTEER.replace("{id}", id));

// reports
export const runReports = (report) => api.create(url.RUN_REPORTS, report);
export const getReportsList = (params) => api.get(url.GET_REPORTS_LIST, params);
export const createReport = (data) => api.create(url.CREATE_REPORTS, data);
export const updateReport = (id, data) => api.update(url.UPDATE_REPORT.replace("{id}", id), data);
export const deleteReport = (id) => api.delete(url.DELETE_REPORT.replace("{id}", id));
// reports export
export const exportReport = (params) => api.get(url.EXPORT_REPORT, params);

// Teams URL Methods
export const getTeams = (params) => api.get(url.GET_TEAMS_LIST, params);
export const getTeam = (id) => api.get(url.GET_TEAM.replace("{id}", id));
export const createTeam = (data) => api.create(url.CREATE_TEAMS, data);
export const updateTeam = (id, data) => api.update(url.UPDATE_TEAM.replace("{id}", id), data);
export const deleteTeam = (id) => api.delete(url.DELETE_TEAM.replace("{id}", id));

// Notes URL Methods
export const getNotes = (params) => api.get(url.GET_NOTES_LIST, params);
export const getNote = (id) => api.get(url.GET_NOTE.replace("{id}", id));
export const createNotes = (data) => api.create(url.CREATE_NOTES, data);
export const updateNote = (id, data) => api.update(url.UPDATE_NOTE.replace("{id}", id), data);
export const updateNoteComment = (id, data) => api.update(url.UPDATE_NOTE_COMMENT.replace("{id}", id), data);
export const deleteNoteComment = (id) => api.update(url.DELETE_NOTE_COMMENT.replace("{id}", id));
export const deleteNote = (id) => api.delete(url.DELETE_NOTE.replace("{id}", id));
export const deleteNoteAttachment = (id) => api.delete(url.DELETE_NOTE_ATTACHMENT.replace("{id}"));
export const getClientNotes = (id, params) => api.get(url.GET_CLIENT_NOTES.replace("{id}", id), params);
export const getCaseNotes = (id, params) => api.get(url.GET_CASE_NOTES.replace("{id}", id), params);

// Service URL Methods
export const getServices = (params) => api.get(url.GET_SERVICES_LIST, params);
export const getService = (id) => api.get(url.GET_SERVICE.replace("{id}", id));
export const createService = (data) => api.create(url.CREATE_SERVICE, data);
export const updateService = (id, data) => api.update(url.UPDATE_SERVICE.replace("{id}", id), data);
export const deleteService = (id) => api.delete(url.DELETE_SERVICE.replace("{id}", id));

// ACTIVATE USER
export const activateUser = (url, data) => api.get(url, data);

// Campaigns URL Methods
export const getCampaigns = (params) => api.get(url.GET_CAMPAIGNS_LIST, params);
export const getCampaign = (id) => api.get(url.GET_CAMPAIGN.replace("{id}", id));
export const createCampaigns = (data) => api.create(url.CREATE_CAMPAIGNS, data);
export const updateCampaign = (id, data) => api.update(url.UPDATE_CAMPAIGN.replace("{id}", id), data);
export const updateCampaignComment = (id, data) => api.update(url.UPDATE_CAMPAIGN_COMMENT.replace("{id}", id), data);
export const deleteCampaignComment = (id) => api.update(url.DELETE_CAMPAIGN_COMMENT.replace("{id}", id));
export const deleteCampaign = (id) => api.delete(url.DELETE_CAMPAIGN.replace("{id}", id));
export const deleteCampaignAttachment = (id) => api.delete(url.DELETE_CAMPAIGN_ATTACHMENT.replace("{id}"));

// Tasks URL Methods
export const getTasks = (params) => api.get(url.GET_TASKS_LIST, params);
export const getTask = (id) => api.get(url.GET_TASK.replace("{id}", id));
export const createTasks = (data) => api.create(url.CREATE_TASKS, data);
export const updateTask = (id, data) => api.update(url.UPDATE_TASK.replace("{id}", id), data);
export const updateTaskComment = (id, data) => api.update(url.UPDATE_TASK_COMMENT.replace("{id}", id), data);
export const deleteTaskComment = (id) => api.update(url.DELETE_TASK_COMMENT.replace("{id}", id));
export const deleteTask = (id) => api.delete(url.DELETE_TASK.replace("{id}", id));
export const deleteTaskAttachment = (id) => api.delete(url.DELETE_TASK_ATTACHMENT.replace("{id}"));

//  URL Methods
export const getEvents = (params) => api.get(url.GET_EVENT_LIST, params);
export const getEventsIn = (start, end) =>
  api.get(url.GET_EVENT_LIST_IN_RANGE.replace("{start}", start).replace("{end}", end));
export const getEvent = (id) => api.get(url.GET_EVENT.replace("{id}", id));
export const getCategories = (id) => api.get(url.GET_EVENT.replace("{id}", id));
export const getUpCommingEvent = (id) => api.get(url.GET_EVENT.replace("{id}", id));
export const createEvents = (data) => api.create(url.CREATE_EVENTS, data);
export const updateEvent = (id, data) => api.update(url.UPDATE_EVENT.replace("{id}", id), data);
export const updateEventComment = (id, data) => api.update(url.UPDATE_EVENT_COMMENT.replace("{id}", id), data);
export const deleteEventComment = (id) => api.update(url.DELETE_EVENT_COMMENT.replace("{id}", id));
export const deleteEvent = (id) => api.delete(url.DELETE_EVENT.replace("{id}", id));
export const deleteEventAttachment = (id) => api.delete(url.DELETE_EVENT_ATTACHMENT.replace("{id}"));

// Profile Methods
export const getProfile = () => api.get(url.GET_PROFILE);
export const updateProfile = (id, user) => api.update(url.UPDATE_PROFILE.replace("{id}", id), user);
export const profileChangePassword = (passwords) => api.create(url.PROFILE_CHANGE_PASSWORD, passwords);

// Billing
export const getBilling = () => api.get(url.GET_BILLING);
export const createBilling = (data) => api.create(url.CREATE_BILLING, data);
export const updateBilling = (id, data) => api.update(url.UPDATE_BILLING, data);

//export const confirmPayment = (data) => api.create(url.CONFIRM_PAYMENT_METHOD, data);

export const getPaymentMethod = () => api.get(url.GET_PAYMENT_METHOD);
export const removePaymentMethod = (accID) => api.delete(url.DELETE_PAYMENT_METHOD);
export const updatePaymentMethod = (data) => api.update(url.UPDATE_PAYMENT_METHOD, data);
export const addPaymentMethod = (data) => api.create(url.ADD_PAYMENT_METHOD, data);

export const getPlan = (params) => api.get(url.GET_PLAN, params);

// Billing X Subscription Mgmt
export const retrieveSNPConnectLoginLink = (data) => api.update(url.RETRIEVE_SIMPLYNP_CONNECT_LOGIN_LINK, data);
export const retrieveSNPConnectOnboardingLink = (data) => api.create(url.CONNECT_ONBOARDING_LINK, data);
export const createSNPStripeConnect = (data) => api.create(url.CREATE_SIMPLYNP_STRIPE_CONNECT, data);
export const getSNPStripeKey = () => api.get(url.GET_PUBLISHABLE_KEY);
export const getSNPStripeKeyAndProducts = () => api.get(url.GET_PUBLISHABLE_KEY_AND_PRODUCTS);
export const createSimplyNPCheckoutSession = (data) => api.create(url.SIMPLYNP_SUBSCRIPTION_CHECKOUT_SESSION, data);
export const createSNPCustomerPortalSession = () => api.create(url.SIMPLYNP_CUSTOMER_PORTAL_SESSION);

export const createSimplyNPSubscription = (data) => api.create(url.CREATE_SIMPLYNP_SUBSCRIPTION, data);
export const updateSimplyNPSubscription = (data) => api.update(url.UPDATE_SIMPLYNP_SUBSCRIPTION, data);
export const deleteSimplyNPSubscription = (data) => api.delete(url.CANCEL_SIMPLYNP_SUBSCRIPTION, data);

// Client stats
export const getClientStats = (params) => api.get(url.GET_CLIENTS_STATS, params);
export const getCasesStats = (params) => api.get(url.GET_CASES_STATS, params);
export const getServicesStats = (params) => api.get(url.GET_SERVICES_STATS, params);
export const getDonationsOrgStats = (params) => api.get(url.GET_DONATIONS_ORG_STATS, params);

export const getDonationsContactsStats = (params) => api.get(url.GET_DONATIONS_CONTACTS_STATS, params);

export const getDonationsCampaignsStats = (params) => api.get(url.GET_DONATIONS_CAMPAIGNS_STATS, params);
export const getDemographicsReportsGroupBy = (params) => api.get(url.GET_DEMOGRAPHIC_REPORTS_GROUPBY, params);
export const getDonationOrgsByPattern = (params) => api.get(url.GET_DONATIONS_OGS_BY_PATTERN_STATS, params);
