import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  CampaignApiResponseError,
  CampaignApiResponseSuccess,
  addCampaignFail,
  addCampaignSuccess,
  deleteCampaignFail,
  deleteCampaignSuccess,
  getCampaignList as getCampaignListAction,
  updateCampaignFail,
  updateCampaignSuccess,
} from "./action";
import { ADD_NEW_CAMPAIGN, DELETE_CAMPAIGN, GET_CAMPAIGN_LIST, UPDATE_CAMPAIGN } from "./actionType";
//Include Both Helper File with needed methods
import {
  createCampaigns,
  deleteCampaign,
  getCampaigns as getCampaignListApi,
  updateCampaign,
} from "../../helpers/backend_helper";

function* getCampaignList({ payload: { params } }) {
  try {
    const response = yield call(getCampaignListApi, params);
    yield put(
      CampaignApiResponseSuccess(GET_CAMPAIGN_LIST, {
        campaigns: response.campaigns,
        stats: response.campaign_stats,
        page: { offset: response.offset, count: response.campaigns_count },
      })
    );
  } catch (error) {
    yield put(CampaignApiResponseError(GET_CAMPAIGN_LIST, error));
  }
}

function* onAddNewCampaign({ payload: campaign }) {
  try {
    const response = yield call(createCampaigns, campaign);
    yield put(addCampaignSuccess(response));
    yield put(getCampaignListAction());
  } catch (error) {
    yield put(addCampaignFail(error));
  }
}

function* onDeleteCampaign({ payload: campaign }) {
  try {
    const response = yield call(deleteCampaign, campaign.id);
    yield put(deleteCampaignSuccess({ campaign, ...response }));
    yield put(getCampaignListAction());
  } catch (error) {
    yield put(deleteCampaignFail(error));
  }
}

function* onUpdateCampaign({ payload: campaign }) {
  try {
    const response = yield call(updateCampaign, campaign.id, campaign);
    yield put(updateCampaignSuccess(response));
    yield put(getCampaignListAction());
  } catch (error) {
    yield put(updateCampaignFail(error));
  }
}

export function* watchGetCampaignList() {
  yield takeEvery(GET_CAMPAIGN_LIST, getCampaignList);
}

export function* watchAddNewCampaign() {
  yield takeEvery(ADD_NEW_CAMPAIGN, onAddNewCampaign);
}

export function* watchUpdateCampaign() {
  yield takeEvery(UPDATE_CAMPAIGN, onUpdateCampaign);
}

export function* watchDeleteCampaign() {
  yield takeEvery(DELETE_CAMPAIGN, onDeleteCampaign);
}

function* campaignSaga() {
  yield all([
    fork(watchGetCampaignList),
    fork(watchAddNewCampaign),
    fork(watchUpdateCampaign),
    fork(watchDeleteCampaign),
  ]);
}

export default campaignSaga;

