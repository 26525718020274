import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteModal from "../../../Components/Common/DeleteModal";
import OffCanvasFilter from "./OffCanvasFilter";

//Import actions
import {
  deleteMember as onDeleteMember,
  getMembers as onGetMembers,
  resetMembershipFlag,
} from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";

// Formik

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Components/Common/Loader";
import MsgToast from "../../../Components/Common/MsgToast";
// import Widgets from "./Widgets";

const MembersList = () => {
  const dispatch = useDispatch();

  const [member, setMember] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [sortBy, setsortBy] = useState(null);
  const [search, setSearch] = useState("");
  const [toggleFilterCanvas, setToggleFilterCanvas] = useState(false);

  const {
    membersList,
    isMemberSuccess,
    isMemberDelete,
    isMemberDeleteFail,
    isMemberUpdate,
    isMemberUpdateFail,
    loading,
    error,
    page,
    isMemberAdd,
    isMemberAddFail,
  } = useSelector((state) => ({
    membersList: state.Membership.members,
    error: state.Membership.error,
    page: state.Membership.page,
    crmusers: state.Profile.usersList,
    isMemberAdd: state.Membership.isMemberAdd,
    isMemberAddFail: state.Membership.isMemberAddFail,
    isMemberDelete: state.Membership.isMemberDelete,
    isMemberDeleteFail: state.Membership.isMemberDeleteFail,
    isMemberUpdate: state.Membership.isMemberUpdate,
    isMemberUpdateFail: state.Membership.isMemberUpdateFail,
    loading: state.Membership.loading,
  }));

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetMembershipFlag());
    }, 3000);
  }, [
    dispatch,
    isMemberSuccess,
    error,
    isMemberAdd,
    isMemberAddFail,
    isMemberDelete,
    isMemberDeleteFail,
    isMemberUpdate,
    isMemberUpdateFail,
  ]);

  useEffect(() => {
    if (!membersList) {
      dispatch(onGetMembers());
    }
  }, [dispatch, membersList]);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(onGetMembers({ offset, limit }));
    },
    [dispatch]
  );

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return (
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="checkAll" value="option1" />
            </div>
          );
        },
      },
      {
        Header: "Salutation",
        accessor: "salutation",
        filterable: false,
      },
      {
        Header: "Full Name",
        accessor: "first_name",
        filterable: false,
        Cell: (member) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {member.row.original?.profile_pic ? (
                  <img src={member.row.original.profile_pic} alt="" className="avatar-xxs rounded-circle" />
                ) : (
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                      {member.row.original?.first_name.charAt(0).toUpperCase()}
                      {member.row.original?.last_name.charAt(0).toUpperCase()}
                    </div>
                  </div>
                )}
              </div>
              <div className="flex-grow-1 ms-2 name">
                {member.row.original?.first_name} {member.row.original?.last_name}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: false,
      },
      {
        Header: "Mobile Number",
        accessor: "phone",
        filterable: false,
      },
      {
        Header: "Date Of Birth",
        accessor: "date_of_birth",
      },
      {
        Header: "Joined",
        accessor: "created_on_arrow",
        filterable: true,
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: (cellProps) => {
          const status = cellProps.row.original?.status;

          return (
            <React.Fragment>
              {status === "active" ? (
                <span className="badge badge-soft-success text-uppercase">{status}</span>
              ) : status === "in-active" ? (
                <span className="badge badge-soft-danger text-uppercase">{status}</span>
              ) : null}
            </React.Fragment>
          );
        },
      },
      {
        Header: "Actions",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-3 mb-0">
              <li className="list-inline-item edit" title="view details">
                <Link
                  to={{ pathname: "/members-details-page/" + cellProps.row.original.id }}
                  className="text-muted d-inline-block"
                >
                  <i className="ri-eye-fill me-2 align-middle text-muted" />
                </Link>
              </li>
              <li className="list-inline-item edit" title="Edit">
                <Link to={"/members-update/" + cellProps.row.original.id} className="text-muted d-inline-block">
                  <i className="ri-edit-2-line fs-16"></i>
                </Link>
              </li>

              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle href="#" className="btn-soft-secondary btn-sm dropdown" tag="button">
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    {/* <Link to={`//${cellProps.row.original.id}`}>
                      <DropdownItem
                        className="dropdown-item edit-item-btn"
                        // onClick={() => {
                        //   const contactData = cellProps.row.original;
                        //   handleContactClick(contactData);
                        // }}
                      >
                        <i className="ri-recycle-fill align-bottom me-2 text-muted"></i>Renew
                      </DropdownItem>
                    </Link> */}

                    <DropdownItem
                      className="dropdown-item remove-item-btn text-muted"
                      onClick={() => {
                        const memberData = cellProps.row.original;
                        onClickDelete(memberData);
                      }}
                    >
                      <i className="ri-delete-bin-fill align-middle me-2 text-muted"></i> Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const handlesortBy = (sortBy) => {
    setsortBy(sortBy);
  };

  const sortbyname = [
    {
      options: [
        { label: "Owner", value: "Owner" },
        { label: "Company", value: "Company" },
        { label: "Location", value: "Location" },
      ],
    },
  ];

  const toggleInfo = () => setToggleFilterCanvas(!toggleFilterCanvas);

  // Delete Data
  const handleDeleteMember = () => {
    if (member) {
      dispatch(onDeleteMember(member));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (member) => {
    setMember(member);
    setDeleteModal(true);
  };

  const handler = useCallback(
    debounce((value) => {
      dispatch(onGetMembers({ search: value }));
    }, 3000),
    []
  );

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    setSearch(value);
    handler(value);
  };

  document.title = "Members - Membership Management | SimplyNP";

  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal show={deleteModal} onDeleteClick={handleDeleteMember} onCloseClick={() => setDeleteModal(false)} />
        <Container fluid>
          <BreadCrumb title="List Members" pageTitle="Members" />
          <Row>{/* <Widgets stats={stats} /> */}</Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-1">
                      <Link to="/members-create">
                        <button className="btn btn-info add-btn">
                          <i className="ri-add-fill me-1 align-bottom"></i> Add Member
                        </button>
                      </Link>
                    </div>
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                        <button
                          className="btn btn-soft-danger"
                          // onClick="deleteMultiple()"
                        >
                          <i className="ri-delete-bin-2-line"></i>
                        </button>
                        <button type="button" className="btn btn-info" onClick={toggleInfo}>
                          <i className="ri-filter-3-line align-bottom me-1"></i> Filters
                        </button>
                        <button className="btn btn-soft-success">Import</button>

                        <UncontrolledDropdown>
                          <DropdownToggle href="#" className="btn btn-soft-info" tag="button">
                            <i className="ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" href="#">
                              All
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Week
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Month
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Year
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col>
              <Card id="memberList">
                <CardHeader>
                  <Row className="g-3">
                    <Col md={4}>
                      <div className="search-box">
                        <Input
                          onChange={(e) => {
                            handleFieldSearch(e);
                          }}
                          type="text"
                          className="form-control search"
                          placeholder="Search for member..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    <div className="col-md-auto ms-auto">
                      <div className="d-flex align-items-center gap-2">
                        <span className="text-muted">Sort by: </span>
                        <Select
                          className="mb-0"
                          value={sortBy}
                          onChange={() => {
                            handlesortBy();
                          }}
                          options={sortbyname}
                          id="choices-single-default"
                        ></Select>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    {membersList ? (
                      <TableContainer
                        columns={columns}
                        data={membersList || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        customFetchData={fetchData}
                        customTotalSize={page.count}
                        customPageSize={10}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        // handleMemberClick={handleMemberClicks}
                      />
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>

                  {/* {fetchingMemberList ?  : ""} */}

                  {isMemberDelete ? (
                    <MsgToast msg="Member Deleted Successfully" color="success" icon="ri-checkbox-circle-line" />
                  ) : null}

                  {isMemberDeleteFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}

                  <ToastContainer limit={1} closeButton={false} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <OffCanvasFilter show={toggleFilterCanvas} onCloseClick={() => setToggleFilterCanvas(false)} />
    </React.Fragment>
  );
};

export default MembersList;
