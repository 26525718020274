import {
  GET_CONTACT_BY_ID,
  GET_CONTACTS,
  GET_ORGANIZATIONS,
  GET_DEALS,
  GET_LEADS,
  GET_USERS,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  UPDATE_ORGANIZATIONS,
  UPDATE_ORGANIZATIONS_SUCCESS,
  UPDATE_ORGANIZATIONS_FAIL,
  ADD_NEW_ORGANIZATIONS,
  ADD_ORGANIZATIONS_SUCCESS,
  ADD_ORGANIZATIONS_FAIL,
  DELETE_ORGANIZATIONS,
  DELETE_ORGANIZATIONS_SUCCESS,
  DELETE_ORGANIZATIONS_FAIL,
  UPDATE_CONTACT,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAIL,
  ADD_NEW_CONTACT,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_FAIL,
  DELETE_CONTACT,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAIL,
  UPDATE_LEAD,
  UPDATE_LEAD_SUCCESS,
  UPDATE_LEAD_FAIL,
  ADD_NEW_LEAD,
  ADD_LEAD_SUCCESS,
  ADD_LEAD_FAIL,
  DELETE_LEAD,
  DELETE_LEAD_SUCCESS,
  DELETE_LEAD_FAIL,
  RESET_CRM_FLAG,
  REMOVE_CONTACT_FROM_STORE,
} from "./actionType";

// common success
export const crmApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const crmApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const removeContactFromStore = () => ({
  type: REMOVE_CONTACT_FROM_STORE,
});

export const getContactByID = (contactID) => ({
  type: GET_CONTACT_BY_ID,
  payload: contactID,
});

export const getContacts = (data) => ({
  type: GET_CONTACTS,
  payload: data,
});

export const getOrganizations = (data) => ({
  type: GET_ORGANIZATIONS,
  payload: data,
});

export const getDeals = () => ({
  type: GET_DEALS,
});

export const getLeads = (data) => ({
  type: GET_LEADS,
  payload: data,
});

export const getUsers = () => ({
  type: GET_USERS,
});

export const updateOrganizations = (organizations) => ({
  type: UPDATE_ORGANIZATIONS,
  payload: organizations,
});

export const updateOrganizationsSuccess = (organizations) => ({
  type: UPDATE_ORGANIZATIONS_SUCCESS,
  payload: organizations,
});

export const updateOrganizationsFail = (error) => ({
  type: UPDATE_ORGANIZATIONS_FAIL,
  payload: error,
});

export const addNewOrganizations = (organizations) => ({
  type: ADD_NEW_ORGANIZATIONS,
  payload: organizations,
});

export const addOrganizationsSuccess = (organizations) => ({
  type: ADD_ORGANIZATIONS_SUCCESS,
  payload: organizations,
});

export const addOrganizationsFail = (error) => ({
  type: ADD_ORGANIZATIONS_FAIL,
  payload: error,
});

export const deleteOrganizations = (organizations) => ({
  type: DELETE_ORGANIZATIONS,
  payload: organizations,
});

export const deleteOrganizationsSuccess = (organizations) => ({
  type: DELETE_ORGANIZATIONS_SUCCESS,
  payload: organizations,
});

export const deleteOrganizationsFail = (error) => ({
  type: DELETE_ORGANIZATIONS_FAIL,
  payload: error,
});

export const updateContact = (contact) => ({
  type: UPDATE_CONTACT,
  payload: contact,
});

export const updateContactSuccess = (contact) => ({
  type: UPDATE_CONTACT_SUCCESS,
  payload: contact,
});

export const updateContactFail = (error) => ({
  type: UPDATE_CONTACT_FAIL,
  payload: error,
});

export const addNewContact = (contact) => ({
  type: ADD_NEW_CONTACT,
  payload: contact,
});

export const addContactSuccess = (contact) => ({
  type: ADD_CONTACT_SUCCESS,
  payload: contact,
});

export const addContactFail = (error) => ({
  type: ADD_CONTACT_FAIL,
  payload: error,
});

export const deleteContact = (contact) => ({
  type: DELETE_CONTACT,
  payload: contact,
});

export const deleteContactSuccess = (contact) => ({
  type: DELETE_CONTACT_SUCCESS,
  payload: contact,
});

export const deleteContactFail = (error) => ({
  type: DELETE_CONTACT_FAIL,
  payload: error,
});

export const updateLead = (lead) => ({
  type: UPDATE_LEAD,
  payload: lead,
});

export const updateLeadSuccess = (lead) => ({
  type: UPDATE_LEAD_SUCCESS,
  payload: lead,
});

export const updateLeadFail = (error) => ({
  type: UPDATE_LEAD_FAIL,
  payload: error,
});

export const addNewLead = (lead) => ({
  type: ADD_NEW_LEAD,
  payload: lead,
});

export const addLeadSuccess = (lead) => ({
  type: ADD_LEAD_SUCCESS,
  payload: lead,
});

export const addLeadFail = (error) => ({
  type: ADD_LEAD_FAIL,
  payload: error,
});

export const deleteLead = (lead) => ({
  type: DELETE_LEAD,
  payload: lead,
});

export const deleteLeadSuccess = (lead) => ({
  type: DELETE_LEAD_SUCCESS,
  payload: lead,
});

export const deleteLeadFail = (error) => ({
  type: DELETE_LEAD_FAIL,
  payload: error,
});

export const resetCrmFlag = () => {
  return {
    type: RESET_CRM_FLAG,
  };
};
