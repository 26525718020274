import React from "react";
import CreateeClientForm from "../FormComponent/index";

const CreateCase = () => {
  document.title = "Create Lead | Velzon - React Admin & Dashboard Template";

  return <CreateeClientForm title="Create CLient" createClient />;
};

export default CreateCase;
