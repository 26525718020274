import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import logoLight from "../../assets/images/logo-light.png";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from "react-router-dom";
import CheckoutCard from "../../Components/Common/CheckoutCard";
import { getSNPStripeKeyAndProducts } from "../../helpers/backend_helper";
import ParticlesAuth from "../auth/ParticlesAuth";

export const features = {
  standard: {
    id: 1,
    type: "basic",
    description: "Good for Startups",
    features: "All SimplyNP Features",
    name: "Simply-NP Standard",
    support: "Email Support",
    users: "Up to 10 Users",
    storage: "Storage Space of 10Gb",
    donors: "Donor Contacts: Up to 500",
    membership: "Membership Contacts: Up to 500",
    ribbon: false,
    planButtonClassname: "soft-success",
    btntxt: "Subscribe",
    lookup_keys: ["snp_standard", "snp_standard_yearly"],
    PRICE_ID: "price_1NADsmELOzUraBEwKUiyCfnV",
  },
  pro: {
    id: 1,
    type: "basic",
    name: "Simply-NP Pro",
    description: "Good for Growing Organizations",
    features: "All SimplyNP Features",
    support: "Email Support and Phone Support",
    users: "Up to 25 Users",
    storage: "Storage Space of 25Gb",
    donors: "Donor Contacts: Up to 1,000",
    membership: "Membership Contacts: Up to 1000",
    ribbon: false,
    lookup_keys: ["snp_pro", "snp_pro_yearly"],
    planButtonClassname: "soft-success",
    btntxt: "Subscribe",
    lookup_key: "",
    PRICE_ID: "price_1NADsmELOzUraBEwKUiyCfnV",
  },
  premium: {
    id: 1,
    type: "basic",
    name: "Simply-NP Premium",
    description: "Recommended for Larger Organizations",
    features: "All SimplyNP Features",
    support: "Priority Email and Phone Support",
    users: "Up to 50 Users",
    storage: "Storage Space of 50Gb",
    donors: "Donor Contacts: Up to 5,000",
    membership: "Membership Contacts: Up to 5000",
    lookup_keys: ["snp_premium", "snp_premium_yearly"],
    ribbon: false,
    planButtonClassname: "soft-success",
    btntxt: "Subscribe",
    PRICE_ID: "price_1NADsmELOzUraBEwKUiyCfnV",
  },
};

const Checkout = ({ isCreatePlan, isUpdatePlan }) => {
  const { type } = useParams();
  const dispatch = useDispatch();
  const [currentPlanId, setCurrentPlanId] = useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [selectedPlanId, setSelectedPlanId] = React.useState("");
  const [plan, setPlan] = React.useState(null);
  const [price, setPrice] = React.useState(null);
  const [plans, setPlans] = useState([]);
  const [featureList, setFeatureList] = useState([]);

  const toggle = React.useCallback(() => {
    if (showModal) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  }, [showModal]);

  const [stripePromise, setStripePromise] = useState(null);
  useEffect(() => {
    getSNPStripeKeyAndProducts().then(({ publishable_key, products: plans }) => {
      if (publishable_key) setStripePromise(loadStripe(publishable_key));
      //const products_list = JSON.parse(plans);
      const products_list = plans;
      setPlans(products_list);
      const current_plan = products_list.find((product) => {
        return product.prices.find((price) => {
          if (price.lookup_key === type) {
            //console.log("PRICE: ", price);
            setPrice(price);
            return true;
          }
          return false;
        });
      });
      setPlan(current_plan);

      for (let key in features) {
        if (features[key].lookup_keys.includes(type)) {
          setFeatureList(features[key]);
        }
      }
    });
  }, []);

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="40" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">The better way to manage your NON-Profit</p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={12} xl={12}>
                <Elements stripe={stripePromise}>
                  <CheckoutCard
                    plan={plan}
                    products={plans}
                    featureList={featureList}
                    selectedPlanId={type}
                    //isCreatePlan={isCreatePlan}
                    isUpdatePlan={isUpdatePlan}
                  />
                </Elements>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default Checkout;
