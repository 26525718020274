import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import { Link } from "react-router-dom";
import Select from "react-select";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import MsgToast from "../../../Components/Common/MsgToast";
import TableContainer from "../../../Components/Common/TableContainer";

import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../../Components/Common/DeleteModal";

import { deleteOrganizations, getOrganizations, resetCrmFlag } from "../../../store/actions";
import CrmFilter from "./CrmFilter";

const OrganizationList = () => {
  const dispatch = useDispatch();
  const [modalDelete, setmodalDelete] = useState(false);
  const togDelete = () => setmodalDelete(!modalDelete);
  const [org, setOrg] = useState({});

  const { isLoading, count, error, organizationList, isOrganizationsDelete, isOrganizationsDeleteFail } = useSelector(
    (state) => ({
      organizationList: state.Crm.organizations,
      isOrganizationsDelete: state.Crm.isOrganizationsDelete,
      isOrganizationsDeleteFail: state.Crm.isOrganizationsDeleteFail,
      error: state.Crm.error,
      count: state.Crm.count,
      isLoading: state.Crm.isLoading,
    })
  );

  // Get Data
  useEffect(() => {
    dispatch(getOrganizations({ limit: 10 }));
    setTimeout(() => {
      dispatch(resetCrmFlag());
    }, 3000);
  }, [dispatch, isOrganizationsDelete, isOrganizationsDeleteFail]);

  const [sortBy, setsortBy] = useState(null);
  const handlesortBy = (sortBy) => setsortBy(sortBy);
  const sortbyname = [
    {
      options: [
        { label: "Owner", value: "Owner" },
        { label: "Company", value: "Company" },
        { label: "Location", value: "Location" },
      ],
    },
  ];

  const handleOrgClick = () => {
    if (org) {
      dispatch(deleteOrganizations(org));
      setmodalDelete(false);
    }
  };

  const [isInfoDetails, setIsInfoDetails] = useState(false);

  const onClickDelete = (org) => {
    setOrg(org);
    setmodalDelete(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return (
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="checkAll" value="option1" />
            </div>
          );
        },
      },
      {
        Header: "Organization Name",
        accessor: "account_name",
        filterable: true,
      },
      {
        Header: "Industry",
        accessor: "industry",
        filterable: false,
      },
      {
        Header: "Phone",
        accessor: "phone",
        filterable: false,
      },
      {
        Header: "Type",
        accessor: "type",
        filterable: false,
      },

      {
        Header: "Status",
        Cell: (cellProps) => {
          const value = cellProps.row.original.status;
          return (
            <React.Fragment>
              {value === "active" ? (
                <span className="badge badge-soft-success text-uppercase">{value}</span>
              ) : value === "inactive" ? (
                <span className="badge badge-soft-danger text-uppercase">{value}</span>
              ) : null}
            </React.Fragment>
          );
        },
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit" title="Call">
                <Link to="#" className="text-muted d-inline-block">
                  <i className="ri-phone-line fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item edit" title="Message">
                <Link to="#" className="text-muted d-inline-block">
                  <i className="ri-question-answer-line fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle href="#" className="btn-soft-secondary btn-sm dropdown" tag="button">
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <Link
                      to={{
                        pathname: `/donor-updateorg/${cellProps.row.original.id}`,
                      }}
                    >
                      <DropdownItem
                        className="dropdown-item edit-item-btn"
                        // onClick={() => { const clientData = cellProps.row.original; handleClientClick(clientData); }}
                      >
                        <i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Edit
                      </DropdownItem>
                    </Link>
                    <DropdownItem
                      className="dropdown-item remove-item-btn"
                      onClick={() => {
                        const clientData = cellProps.row.original;
                        onClickDelete(clientData);
                      }}
                    >
                      <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;
      dispatch(getOrganizations({ offset, limit }));
    },
    [dispatch]
  );

  const handler = useCallback(
    debounce((value) => {
      dispatch(getOrganizations({ search: value }));
    }, 1000),
    []
  );

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    handler(value);
  };
  const toggleInfo = () => {
    setIsInfoDetails(!isInfoDetails);
  };

  document.title = "Organizations | Velzon - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
        <BreadCrumb title="organization" pageTitle="organization" />
        <DeleteModal show={modalDelete} onDeleteClick={handleOrgClick} onCloseClick={() => setmodalDelete(false)} />
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-1">
                      <Link to="/donor-createorg">
                        <button
                          className="btn btn-info add-btn"
                          // onClick={history.push("/donor-createorg")}
                        >
                          <i className="ri-add-fill me-1 align-bottom"></i> Add Organization
                        </button>
                      </Link>
                    </div>
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                        <button
                          className="btn btn-soft-danger"
                          // onClick="deleteMultiple()"
                        >
                          <i className="ri-delete-bin-2-line"></i>
                        </button>
                        <div className="flex-shrink-0">
                          <button type="button" className="btn btn-info" onClick={toggleInfo}>
                            <i className="ri-filter-3-line align-bottom me-1"></i> Fliters
                          </button>
                        </div>
                        <button className="btn btn-soft-success">Import</button>

                        <UncontrolledDropdown>
                          <DropdownToggle href="#" className="btn btn-soft-info" tag="button">
                            <i className="ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" href="#">
                              All
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Week
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Month
                            </DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">
                              Last Year
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardHeader>
                  <Row className="g-3">
                    <Col md={4}>
                      <div className="search-box">
                        <Input
                          onChange={(e) => {
                            handleFieldSearch(e);
                          }}
                          type="text"
                          className="form-control search"
                          placeholder="Search for organization..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    <div className="col-md-auto ms-auto">
                      <div className="d-flex align-items-center gap-2">
                        <span className="text-muted">Sort by: </span>
                        <Select
                          className="mb-0"
                          value={sortBy}
                          onChange={() => {
                            handlesortBy();
                          }}
                          options={sortbyname}
                          id="choices-single-default"
                        ></Select>
                      </div>
                    </div>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div>
                    {organizationList ? (
                      <TableContainer
                        columns={columns}
                        data={organizationList || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        customPageSize={10}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        customFetchData={fetchData}
                        customTotalSize={count}
                      />
                    ) : (
                      <Loader error={error} />
                    )}
                  </div>
                </CardBody>

                {isOrganizationsDelete ? (
                  <MsgToast msg="Organization deleted successfully" color="success" icon="ri-checkbox-circle-line" />
                ) : null}

                {isOrganizationsDeleteFail ? <MsgToast msg={error} color="danger" icon="ri-close-circle-line" /> : null}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CrmFilter show={isInfoDetails} onCloseClick={() => setIsInfoDetails(false)} />
    </React.Fragment>
  );
};

export default OrganizationList;
