import {
  ACTIVATE_USER,
  ACTIVATE_USER_SUCCESSFUL,
  ACTIVATE_USER_FAILED,
  RESET_ACTIVATE_FLAG,
  ACTIVATE_ERROR,
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGIN_ERROR,
  RESET_LOGIN_FLAG,
  LOGIN_FAILED,
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  RESET_REGISTER_FLAG,
  REGISTRATION_ERROR,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILED,
} from "./actionTypes";

const INITIAL_STATE = {
  registrationError: null,
  message: "",
  loading: false,
  user: null,
  org: null,
  success: false,
  error: false,
};

const Account = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIVATE_USER:
      return {
        ...state,
        loading: true,
        message: null,
      };

    case ACTIVATE_USER_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        access_token: action.payload?.data.access_token,
        //user: action.payload?.data?.user,
        user: action.payload?.data,
        is_account_completed: action.payload?.data.user ? true : false,
        activationSuccess: true,
        activationError: false,
        registrationError: null,
        message: action.payload.message,
      };

    case ACTIVATE_ERROR:
      return {
        ...state,
        user: null,
        loading: false,
        activationSuccess: false,
        activationError: true,
        error: true,
        message: action.payload.message,
      };

    case ACTIVATE_USER_FAILED:
      return {
        ...state,
        user: null,
        loading: false,
        activationSuccess: false,
        activationError: true,
        registrationError: true,
        error: true,
        message: action.payload.message,
      };

    case RESET_ACTIVATE_FLAG:
      return {
        ...state,
        success: false,
        error: false,
        message: null,
        loading: false,
        activationSuccess: false,
        activationError: false,
        registrationError: null,
      };

    case LOGIN_USER:
      return {
        ...state,
        loading: true,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        access_token: action.payload?.data.access_token,
        user: action.payload?.data,
        org: action.payload?.data?.org,
      };

    case LOGIN_FAILED:
      return {
        ...state,
        success: false,
        error: true,
        errorObj: action.payload,
      };

    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        success: true,
        access_token: action.payload?.data.access_token,
        user: action.payload?.data.user,
        org: action.payload?.data.profile_org_list.map((p) => p.org),
      };

    case REFRESH_TOKEN_FAILED:
      return {
        ...state,
        success: false,
        error: true,
        errorObj: action.payload,
      };

    case LOGOUT_USER:
      return {
        ...state,
        isUserLogout: false,
      };

    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        isUserLogout: true,
        access_token: null,
        org: null,
        user: null,
      };

    case LOGIN_ERROR:
      return {
        ...state,
        error: action.payload.data,
        loading: false,
        isUserLogout: false,
      };

    case RESET_LOGIN_FLAG:
      return {
        ...state,
        error: null,
        success: false,
        message: null,
        errorObj: null,
      };

    case REGISTER_USER:
      return {
        ...state,
        loading: true,
        registrationError: null,
        errorMessage: null,
        nonFieldError: null,
        passwordError: null,
        err: null,
      };

    case REGISTER_USER_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        user: action.payload,
        success: true,
        registrationError: null,
      };

    case REGISTRATION_ERROR:
      return {
        ...state,
        user: null,
        loading: false,
        error: true,
      };

    case REGISTER_USER_FAILED:
      return {
        ...state,
        user: null,
        loading: false,
        registrationError: true,
        error: false,
        nonFieldError: action.payload.errors.non_field_errors,
        passwordError: action.payload.errors.password,
        errorMessage: action.payload.message,
        err: action.payload,
      };

    case RESET_REGISTER_FLAG:
      return {
        ...state,
        success: false,
        error: false,
      };

    default:
      return { ...state };
  }
};

export default Account;
