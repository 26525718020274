import React, { useState } from "react";
import { Offcanvas, OffcanvasHeader, OffcanvasBody, Label, Form, Input, Row, Col } from "reactstrap";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import actions
import {
  getClientsList as onGetClients,
  deleteClient as onDeleteClient,
  resetClientFlag,
  getCasesList,
} from "../../store/actions";
//redux
import * as Yup from "yup";
import DateField from "../../Components/Common/Fields/DateField";
import countries from "../../common/data/countries.json";
import TextField from "../../Components/Common/Fields/TextField.js";
import SelectField from "../../Components/Common/Fields/SelectField.js";

const CrmFilter = ({ show, onCloseClick }) => {
  const dispatch = useDispatch();

  const [selectCountry, setselectCountry] = useState(null);

  const handleselectCountry = (selectCountry) => {
    setselectCountry(selectCountry);
  };

  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      country: selectCountry || "",
    },

    validationSchema: Yup.object({
      salutation: Yup.string().notRequired("Please Enter A Salutation"),
      first_name: Yup.string().notRequired("Please Enter A First Name"),
      last_name: Yup.string().notRequired("Please Enter A Last Name"),
      date_of_birth: Yup.date().notRequired("Please enter date of birth"),
      gender: Yup.string().notRequired(),
      language: Yup.string().notRequired("Please Enter A Language"),
    }),

    onSubmit: (client) => {
      dispatch(onGetClients(client));
    },
  });

  return (
    <Offcanvas direction="end" isOpen={show} id="offcanvasExample" toggle={onCloseClick}>
      <OffcanvasHeader className="bg-light" toggle={onCloseClick}>
        clients Fliters
      </OffcanvasHeader>
      <PerfectScrollbar>
        <Form
          className="d-flex flex-column justify-content-end h-100"
          onSubmit={(e) => {
            e.preventDefault();
            form.handleSubmit();
            return false;
          }}
          onReset={() => {
            form.handleReset();
            form.handleSubmit();
          }}
        >
          <OffcanvasBody>
            <div className="mb-4">
              <DateField
                mb="mb-3"
                md="12"
                value={form.values.date_of_birth || ""}
                onChange={form.handleChange}
                validation={form}
                onBlur={form.handleBlur}
                label="Date of birth"
                name="date_of_birth"
              />
            </div>
            <SelectField
              md="12"
              mb="mb-3"
              onChange={form.handleChange}
              validation={form}
              onBlur={form.handleBlur}
              placeholder="Select a salutation..."
              label="salutation"
              name="salutation"
              optional={true}
              options={[
                { label: "Mr", value: "Mr" },
                { label: "Mrs", value: "Mrs" },
              ]}
            />

            <SelectField
              md="12"
              mb="mb-3"
              onChange={form.handleChange}
              validation={form}
              onBlur={form.handleBlur}
              placeholder="Select a country..."
              label="country"
              name="country"
              optional={true}
              options={countries}
            />

            <TextField
              md="12"
              mb="mb-3"
              value={form.values.first_name || ""}
              onChange={form.handleChange}
              validation={form}
              onBlur={form.handleBlur}
              placeholder="Enter first name"
              label="first name"
              name="first_name"
            />

            <TextField
              md="12"
              mb="mb-3"
              value={form.values.last_name || ""}
              onChange={form.handleChange}
              validation={form}
              onBlur={form.handleBlur}
              placeholder="Enter last name"
              label="Last name"
              name="last_name"
            />

            <TextField
              md="12"
              mb="mb-3"
              value={form.values.email || ""}
              onChange={form.handleChange}
              validation={form}
              onBlur={form.handleBlur}
              placeholder="Enter email"
              label="Enter email"
              name="email"
            />
          </OffcanvasBody>
          <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
            <button type="reset" className="btn btn-light w-100" onClick={onCloseClick}>
              Clear Filter
            </button>
            <button type="submit" className="btn btn-success w-100" onClick={onCloseClick}>
              Filters
            </button>
          </div>
        </Form>
      </PerfectScrollbar>
    </Offcanvas>
  );
};

export default CrmFilter;
