import React, { useEffect, useMemo, useState, useCallback } from "react";

import { Input, Card, CardBody, Col, Container, Row, CardHeader } from "reactstrap";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import OffCanvasFilter from "./OffCanvasFilter";
import ReportTableContainer from "../../../Components/Common/ReportTableContainer";

//Import actions
import { getDemographicsReportsGroupBy } from "../../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
import { debounce, forEach } from "lodash";

import countries from "../../../common/data/countries.json";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { SimpleRadar } from "../../../Components/Common/RadarChart";
import { object } from "yup";

const Contacts = () => {
  const dispatch = useDispatch();

  const [toggleFilterCanvas, setToggleFilterCanvas] = useState(false);

  const { stats_list, loading } = useSelector((state) => ({
    casestStatsList: state.Statistics.casesPriorityStats,
    services_count: state.Statistics.services_count,
    stats_list: state.Statistics.stats_list,
    currentReport: state.Reporting.currentReport,

    loading: state.Membership.loading,
  }));

  stats_list?.forEach((listObj) => {
    countries.forEach((country) => {
      if (listObj.country === country.value) {
        listObj.country = country.label;
        listObj.countryAbrv = country.value;
      }
    });
  });

  useEffect(() => {
    dispatch(getDemographicsReportsGroupBy({ group_by: "country" }));
  }, []);

  // Column
  const servicesColumns = useMemo(
    () => [
      {
        Header: "Country",
        accessor: "country",
        filterable: false,
        Cell: (data) => {
          let obj = data.row.original;
          if (!obj.country) {
            return "N/A";
          }

          return <>{obj.country}</>;
        },
      },

      {
        Header: "country Abrv",
        accessor: "countryAbrv",
        filterable: false,
      },
      {
        Header: "Count",
        accessor: "count",
        filterable: false,
      },
    ],
    []
  );

  const handler = useCallback(
    debounce((value) => {
      dispatch(getDemographicsReportsGroupBy({ group_by: "country", search: value }));
    }, 500),
    []
  );

  const handleFieldSearch = (e) => {
    let value = e.target.value;
    // setSearch(value);
    handler(value);
  };

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const limit = pageSize;
      const offset = limit * pageIndex;

      getDemographicsReportsGroupBy({
        group_by: "country",
        date_field: "created_on",
        columns: ["country", "__count__"],
        offset: 0,
        limit: 30,
      });
    },
    [dispatch]
  );

  let getChartData = (data = []) => {
    if (!data.length) return;
    let labels = [];
    let series = [];

    data.forEach((obj) => {
      //labels.push(Object.values(obj)[0]);
      //series.push(Object.values(obj)[1]);

      labels.push(obj["countryAbrv"]);
      series.push(obj["count"]);
    });

    return { labels, series };
  };

  let servicesChart = getChartData(stats_list);

  document.title = "Demographics report | SimplyNP";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Demographics" pageTitle="Demographics" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <div className="text-center py-0">
                      <h5>Clients grouped by country</h5>

                      <SimpleRadar data={servicesChart} />
                    </div>
                  </Row>
                </CardBody>
                <CardBody>
                  <CardHeader>
                    <Row className="g-3">
                      <Col md={5}>
                        <div className="search-box">
                          <Input
                            onChange={(e) => {
                              handleFieldSearch(e);
                            }}
                            type="text"
                            className="form-control search"
                            placeholder="Search country by Abrev. eg: au"
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>

                  <div className="text-center py-5">
                    <ReportTableContainer
                      columns={servicesColumns}
                      data={stats_list || []}
                      isGlobalFilter={false}
                      isAddUserList={false}
                      customFetchData={fetchData}
                      customTotalSize={39}
                      customPageSize={10}
                      className="custom-header-css"
                      divClass="table-responsive table-card mb-3"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light"
                    />
                  </div>
                </CardBody>
              </Card>

              <Card>
                {/* {fetchingMemberList ?  : ""} */}

                <ToastContainer limit={1} closeButton={false} />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <OffCanvasFilter show={toggleFilterCanvas} onCloseClick={() => setToggleFilterCanvas(false)} />
    </React.Fragment>
  );
};

export default Contacts;
